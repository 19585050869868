import { Popover as UiPopover } from '@material-ui/core';
import cn from 'classnames';
import Button from 'components/form/Button';
import styles from 'components/form/Select/BigWhiteSelect/index.module.css';
import { DropdownButtonProps } from 'components/form/Select/DropdownButton';
import selectStyles from 'components/form/Select/index.module.css';
import { PopoverProps } from 'components/form/Select/Popover';
import Icon from 'components/Icon';
import MultiSelect, { MultiSelectProps } from '..';

function DropdownButtonWhite(props: DropdownButtonProps) {
	const { onClick, open, children, dataTest } = props;

	return (
		<Button
			className={cn(styles.buttonWhite, open && styles.open)}
			color="ghost"
			onClick={onClick}
			data-test={dataTest}
		>
			<span>{children}</span>
			<span className={open ? selectStyles.activeIcon : selectStyles.icon}>
				<Icon name="ChevronDown/Regular" />
			</span>
		</Button>
	);
}

function PopoverWide(props: PopoverProps) {
	const { anchorEl, onClose, children } = props;

	return (
		<UiPopover
			classes={{ paper: cn(selectStyles.popover, styles.popoverWide) }}
			anchorEl={anchorEl}
			open={!!anchorEl}
			onClose={onClose}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'left',
			}}
			data-test="select-popover"
		>
			{children}
		</UiPopover>
	);
}

function BigWhiteSelect<T>(props: MultiSelectProps<T>) {
	return (
		<MultiSelect
			{...props}
			render={{
				dropdownButton: DropdownButtonWhite,
				popover: PopoverWide,
				...props.render,
			}}
		/>
	);
}

export default BigWhiteSelect;
