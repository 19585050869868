import { createEffect } from 'effector';
import { getPolicyList } from 'models/policies/api';
import { getDataTypesExtended } from './api';
import { DataTypesExtendedItem } from './dto';

/* API */
const getDataTypesExtendedFx = createEffect<void, DataTypesExtendedItem[]>();

/* IMPLEMENTATION */
getDataTypesExtendedFx.use(async function () {
	const [{ data_types: dataTypes }, { policies }] = await Promise.all([
		getDataTypesExtended(),
		getPolicyList(),
	]);

	return dataTypes.map((dataType) => {
		const policiesCount = policies.filter((policy) =>
			policy.data_types.includes(dataType.id)
		).length;
		const isUsed = dataType.assets + dataType.buckets > 0;

		return {
			...dataType,
			isUsed,
			isGroup: dataType.child_ids.length > 0,
			policies: policiesCount,
			last_seen: isUsed ? dataType.last_seen : 0,
		};
	});
});

export { getDataTypesExtendedFx };
