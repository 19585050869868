import { AssetJson } from 'models/assets/dto';
import { AssetGroup } from 'models/assetsGroups/dto';
import { DataTypeItem } from 'models/dataTypes/dto';
import { EventParams, EventFromParam } from 'models/eventsV2/model';
import { KafkaTopicItem } from 'models/kafkaTopics/dto';
import { S3BucketItem } from 'models/s3Buckets/dto';
import { ProtocolValue } from './ProtocolFilter';

type FilterValues = {
	assets: AssetJson['name'][];
	clusters: AssetJson['cluster_id'][];
	clustersInternal: AssetJson['cluster_id'][];
	clustersExternal: AssetJson['cluster_id'][];
	dataTypes: DataTypeItem['id'][];
	eventType: EventParams['type'];
	eventStart: EventFromParam;
	groups: AssetGroup['id'][];
	namespaces: AssetJson['namespace'][];
	namespacesEndpoints: AssetJson['namespace'][];
	regions: S3BucketItem['region'][];
	labelKeys: AssetJson['labels'][number]['key'][];
	labelValues: AssetJson['labels'][number]['value'][];
	kafkaInstances: KafkaTopicItem['instance_id'][];
	protocol: ProtocolValue;
};

type FilterType = keyof FilterValues;

const defaultValues: FilterValues = {
	assets: [],
	clusters: [],
	clustersInternal: [],
	clustersExternal: [],
	dataTypes: [],
	eventType: '',
	eventStart: '90',
	groups: [],
	namespaces: [],
	namespacesEndpoints: [],
	regions: [],
	labelKeys: [],
	labelValues: [],
	kafkaInstances: [],
	protocol: '',
};

type FilterPropsBase = {
	fixed?: boolean;
	resetFilter?: () => void;
	defaultOpen?: boolean;
	onClose?: () => void;
};

export { defaultValues };
export type { FilterType, FilterPropsBase, FilterValues };
