import Chip, { CustomChipsProps } from 'components/Chip';

interface Props {
	sensitivity: string;
	className?: string;
}

interface SettingSensitivity {
	[name: string]: CustomChipsProps['theme'];
}

export const settingSensitivity: SettingSensitivity = {
	FP: 'neutral',
	High: 'danger',
	Low: 'success',
	Medium: 'warning',
	'N/A': 'neutral',
};

function SensitivityChip({ sensitivity, ...rest }: Props) {
	return (
		<Chip
			{...rest}
			label={sensitivity}
			size="small"
			color="secondary"
			theme={settingSensitivity[sensitivity]}
		/>
	);
}

export default SensitivityChip;
