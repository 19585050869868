// It is important that `commonErrorHandler` is imported before `sentry`. Otherwise,
// promise rejections that we process are still reported as errors by Sentry.
// eslint-disable-next-line sort-imports
import 'services/api/commonErrorHandler';

import 'assets/styles/index.pcss';
import ReactDOM from 'react-dom';
import { initGtm } from 'modules/gtm';
import { initSentry } from 'modules/sentry';
import { unregister } from 'modules/serviceWorker';
import App from './App';

unregister();
initSentry();
initGtm();

ReactDOM.render(<App />, document.getElementById('root'));
