import cn from 'classnames';
import { ReactNode } from 'react';
import styles from './index.module.css';

interface Props {
	children: ReactNode;
}

function Guest({ children }: Props) {
	return <div className={cn(styles.background, styles.container)}>{children}</div>;
}

export default Guest;
