import { KafkaMessageSchemaListItem, KafkaMessageSchemaList, KafkaMessageSchema } from './dto';

const kafkaMessageSchemaListItemExample: KafkaMessageSchemaListItem = {
	id: 14,
	topic_id: 2,
	name: 'Prod schema 1',
	type: 'json',
	data_types: [4],
	sensitivity: 'Low',
	last_checked: 1708944827384,
};

const kafkaMessageSchemaExample: KafkaMessageSchema = {
	id: 14,
	topic_id: 2,
	topic_name: 'Prod topic 1',
	name: 'Prod schema 1',
	type: 'json',
	data_types: [4],
	sensitivity: 'Low',
	last_checked: 1708944827384,
};

const kafkaMessageSchemaListExample: KafkaMessageSchemaList = {
	schemas: [kafkaMessageSchemaListItemExample],
	total: 1,
};

export { kafkaMessageSchemaExample, kafkaMessageSchemaListExample };
