import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react';
import { ReactNode } from 'react';
import Alert, { AlertSeverity } from 'components/Alert';
import styles from './index.module.css';

type Props = {
	children: ReactNode;
};

function JsonErrorBoundary(props: Props) {
	const fallback = (
		<div className={styles.wrapper}>
			<Alert severity={AlertSeverity.error} className={styles.alert}>
				Encountered errors while parsing JSON payload.
			</Alert>
		</div>
	);

	return <SentryErrorBoundary fallback={fallback}>{props.children}</SentryErrorBoundary>;
}

export default JsonErrorBoundary;
