import { DateFormat, dayjs } from 'services/dayjs';

function formatWithToday(timeRaw: number) {
	const today = dayjs().startOf('day');
	const time = dayjs(timeRaw);

	const dayFormatted = time.isSame(today, 'day')
		? 'Today'
		: time.format(DateFormat.dateWithWeekday);
	const timeFormatted = time.format(DateFormat.fullTime);

	return `${dayFormatted} - ${timeFormatted}`;
}

export { formatWithToday };
