import { del, get, post } from 'api/Api';
import checkResponse from 'services/api/checkResponse';
import { NewPIIMarkJson, PIIMarkJson, PIIMarksJson } from './dto';
import { piiMarkJsonExampleFP, piiMarkJsonExampleFN, piiMarksJsonExample } from './examples';

async function getPIIMarksById(endpoint_id: NewPIIMarkJson['endpoint_id']) {
	return get<PIIMarksJson>(`v3/pii-mark?endpoint_id=${endpoint_id}`).then(
		checkResponse(piiMarksJsonExample)
	);
}

async function setPIIMark(body: NewPIIMarkJson) {
	return post<PIIMarkJson>('v3/pii-mark', body).then(
		checkResponse(piiMarkJsonExampleFP, piiMarkJsonExampleFN)
	);
}

async function deletePIIMark(id: PIIMarkJson['id']) {
	return del(`v3/pii-mark/${id}`);
}

export { getPIIMarksById, setPIIMark, deletePIIMark };
