import { TSSOSettings } from './dto';

const oidcSettingsExample: TSSOSettings = {
	oidc: {
		is_enabled: true,
		client_id: '65f3da',
		client_secret:
			'bh3GHG43GDGHV67vcscnjkU81bjjBHK213NMHvbdwjjhjndjj32JHk3458BGJKMN346FGB907HNknnT5e322hnbnjGHJNNMK7w9',
		issuer: 'issuer',
		email_domains: ['acmelogistics.com', 'acmetravel.com'],
		manage_users: false,
	},
};

const adfsSettingsExample: TSSOSettings = {
	adfs: {
		is_enabled: true,
		client_id: 'cc1780',
		client_secret:
			'K213NMHvbdwjjhjndjj32JHk3458BGJKMN346FGB907HNknnT5e322hnbnjGHJNNMK7w9bh3GHG43GDGHV67vcscnjkU81bjjBH7HNknnT5e3',
		authorization_endpoint: 'https://auth.acmelogistics.com',
		token_endpoint: 'https://token.acmelogistics.com',
		email_domains: ['acmelogistics.com', 'acmetravel.com'],
		manage_users: false,
	},
};

export { oidcSettingsExample, adfsSettingsExample };
