import { createStore } from 'effector';
import { ReleaseJson } from './dto';
import { getReleaseFx } from './effects';

const releaseStore = createStore<ReleaseJson>({
	dim: {
		detection_tool_version: '',
		digger_version: '',
		interceptor_version: '',
		diff_with_actual_release: 0,
		last_event_date: 0,
	},
	dar: {
		crawler_version: '',
		detection_tool_version: '',
		diff_with_actual_release: 0,
		last_event_date: 0,
	},
});

releaseStore.on(getReleaseFx.doneData, (state: ReleaseJson, data: ReleaseJson) => data);

export { releaseStore };
