import { post } from 'api/Api';
import Alert, { AlertSeverity } from 'components/Alert';
import { ConfirmModal, getConfirmation } from 'components/ConfirmModal';
import Button from 'components/form/Button';
import { enqueueSnackbar } from 'components/Snackbar';
import Typo from 'components/typography/Typo';
import styles from './index.module.css';

async function purgeAllEventsData() {
	return post<void>('v2/admin/purge');
}

function Admin() {
	async function onPurge() {
		if (!(await confirmPurge())) {
			return;
		}
		try {
			await purgeAllEventsData();

			enqueueSnackbar('Events data purge started. This process may take a few minutes.');
		} catch (e) {
			console.log(e);
			enqueueSnackbar('Something went wrong');
		}
	}

	async function confirmPurge() {
		const confirmResolveModal = (
			<ConfirmModal
				title="Purge all events data"
				confirmProps={{
					children: 'Purge',
				}}
				disableUntilEntered="confirm"
			>
				Purge all events data: including services and endpoints list, log events, violations, etc.
				<br />
				<br />
				Enter{' '}
				<Typo variant="D/Regular/Body-S-code" color="secondary" component="span">
					confirm
				</Typo>{' '}
				to confirm the purge.
			</ConfirmModal>
		);

		return await getConfirmation(confirmResolveModal);
	}

	return (
		<div>
			<Alert className={styles.alert} severity={AlertSeverity.error} header="Dangerous actions">
				Actions on this page are irreversible and potentially disruptive.
			</Alert>
			<Typo className={styles.header}>
				Purge all events data: including services and endpoints list, log events, violations, etc.
			</Typo>
			<Button theme="danger" onClick={onPurge}>
				Purge
			</Button>
		</div>
	);
}

export default Admin;
