import { getDataTypesAsApiParam } from 'models/dataTypes/helpers';
import { ApiParams } from 'services/api';
import { createTableModel } from '../modelUtils/tableModelFactory';
import { getS3Clusters } from './api';
import { S3ClusterItem, S3ClusterTableParams } from './dto';

export function fetchS3ClusterApi(s3ClusterParamsRaw: ApiParams, signal?: AbortSignal) {
	const { id, 'data-types': piiTypes, ...apiParams } = s3ClusterParamsRaw;

	apiParams['data-types'] = getDataTypesAsApiParam(piiTypes);

	return getS3Clusters(Number(id), apiParams, signal).then((payload) => {
		return { data: payload.clusters, total: payload.total };
	});
}

export function createS3ClusterModel(
	initialParams: S3ClusterTableParams,
	fetchFxOnInit: boolean = true
) {
	return createTableModel<S3ClusterItem, S3ClusterTableParams>(
		initialParams,
		fetchS3ClusterApi,
		fetchFxOnInit
	);
}

//
// Instantiate models with initial params, but do not fetch data from server.
//

const initialS3ClusterParams: S3ClusterTableParams = {
	id: 0,
	limit: 0,
	offset: 0,
	'data-types': [],
	sort: { orderBy: 'sensitivity', order: 'desc' },
};

export const s3ClusterModel = createS3ClusterModel(initialS3ClusterParams, false);
