import Tooltip, { ITooltipProps } from 'components/Tooltip';
import Link from 'components/typography/Link';
import Typo, { CustomTypoProps } from 'components/typography/Typo';
import { PATHS } from 'services/router.ts';

interface NonAvailable extends Omit<CustomTypoProps, 'children'> {}

export function AutomaticDiscoveryNonAvailableData(props: NonAvailable) {
	return (
		<Typo variant="D/Regular/Body-S" {...props}>
			N/A
		</Typo>
	);
}

interface Props extends Omit<ITooltipProps, 'title'> {}

export function AutomaticDiscoveryTooltip({ children, ...rest }: Props) {
	return (
		<Tooltip
			{...rest}
			placement="top"
			interactive
			title={
				<>
					Automatically discovered resource with no
					<br />
					access provided to detect sensitive data.
					<br />
					To provide access,{' '}
					<Link
						onClick={function cancelClickOnRow(event) {
							event.stopPropagation();
						}}
						to={PATHS.SENSORS_DAR}
					>
						set up additional Sensor.
					</Link>
				</>
			}
		>
			{children}
		</Tooltip>
	);
}
