import { TOrder, TSensitivity } from 'models/common/dto';

// This utility does, mainly, three things:
//
//	1. DRY
//	2. Sensitivity sorting by weights (not by string comparison)
//	3. Implicit secondary sorting by 'name', then 'id' fields, if they exist
//
//
// If we do not do #3 (secondary sorting), the following BAD case would happen:
//											BAD								GOOD
//		name	sensitivity⌄			name	sensitivity⌃			name	sensitivity⌃
//
//		Email	High					Phone	Low						Phone	Low
//  	Gender	High					Email	High					Gender	High
//  	Phone	Low						Gender	High					Email	High
//
// Notice how reverse sorting by sensitivity in BAD still keeps relative order of
// Email and Gender, while intuitively we expect the whole table to be reversed.

const sensitivityWeights: Record<TSensitivity, number> = {
	High: 3,
	Medium: 2,
	Low: 1,
	'N/A': 0,
};

type WithSensitivityNameId = {
	sensitivity?: TSensitivity;
	name?: string;
	id?: number;
} & object;

function compareSensitivity(a: WithSensitivityNameId, b: WithSensitivityNameId) {
	if (!a.sensitivity || !b.sensitivity) return 0;

	return sensitivityWeights[a.sensitivity] - sensitivityWeights[b.sensitivity];
}
function compareName(a: WithSensitivityNameId, b: WithSensitivityNameId) {
	if (typeof a.name !== 'string' || typeof b.name !== 'string') return 0;

	return a.name.localeCompare(b.name);
}
function compareId(a: WithSensitivityNameId, b: WithSensitivityNameId) {
	if (typeof a.id !== 'number' || typeof b.id !== 'number') return 0;

	return a.id - b.id;
}

function getComparator<T extends WithSensitivityNameId>(field: keyof T, direction: TOrder) {
	const dir = direction === 'asc' ? 1 : -1;

	return function comparator(a: T, b: T): number {
		let result = 0;

		if (field === 'sensitivity') {
			result = compareSensitivity(a, b);
		} else {
			const aVal = a[field];
			const bVal = b[field];

			if (typeof aVal === 'number' && typeof bVal === 'number') {
				result = aVal - bVal;
			}
			if (typeof aVal === 'string' && typeof bVal === 'string') {
				result = aVal.localeCompare(bVal);
			}
			if (Array.isArray(aVal) && Array.isArray(bVal)) {
				result = aVal.length - bVal.length;
			}
		}

		if (result === 0 && field !== 'name') result = compareName(a, b);

		if (result === 0) result = compareId(a, b);

		return result * dir;
	};
}

function composeComparators<T>(...comparators: ((a: T, b: T) => number)[]) {
	return function compositeComparator(a: T, b: T): number {
		for (const comparator of comparators) {
			const result = comparator(a, b);
			if (result !== 0) return result;
		}

		return 0;
	};
}

export { getComparator, composeComparators };
