import { Fragment } from 'react';
import { generatePath } from 'react-router';
import CopyToClipboard from 'components/CopyToClipboard';
import Button from 'components/form/Button';
import Icon from 'components/Icon';
import SensitivityChip from 'components/SensitivityChip';
import Tooltip from 'components/Tooltip';
import { RouterLink } from 'components/typography/Link';
import Typo from 'components/typography/Typo';
import { getAssetNameByType as getAssetNameByTypeText } from 'models/assets/model';
import { getDatabaseTableSample } from 'models/databases/api';
import { dataTypesById } from 'models/dataTypes/store';
import { methodOrProtocol } from 'models/endpointsV2/dto';
import {
	DARNewDataTypeEventJson,
	DIMNewDataTypeEventJson,
	NewDataTypeEventJson,
} from 'models/eventsV2/dto';
import { getKafkaMessageSchema } from 'models/kafkaMessageSchemas/api';
import { getS3ClusterFileWithSamples } from 'models/s3BucketCluster/api';
import { getSample } from 'models/samplesV2/api';
import { PATHS } from 'services/router';
import { eventTitleAsText } from '../EventRow';
import JiraButton from '../JiraButton';
import { formatWithToday } from '../utils';
import Asset from './fields/Asset';
import { SampleExistsIcon } from './fields/SampleExistsIcon';
import { ViewOnDataMapButton } from './fields/ViewOnDataMapButton';
import { ViewSampleButton } from './fields/ViewSampleButton';
import styles from './index.module.pcss';

type Props = { event: NewDataTypeEventJson };

// For more logical word-break, we break e.g. json paths at `.` character with <wbr> tag
function breakAtDots(str: string) {
	const result = str.split('.').flatMap((part, i, arr) => {
		return i < arr.length - 1 ? (
			[<Fragment key={i}>{`${part}.`}</Fragment>, <wbr key={`${i}wbr`} />]
		) : (
			<Fragment key={i}>{part}</Fragment>
		);
	});

	return result;
}

function DIMDataTypeEvent({ event }: { event: DIMNewDataTypeEventJson }) {
	const linkHere = `${location.origin}${PATHS.EVENTS}?id=${event.id}`;
	const sendingAsset = event.direction === 'request' ? event.requestor : event.responder;
	const sendingAssetNameText = getAssetNameByTypeText(sendingAsset.type, sendingAsset.name);
	const receivingAsset = event.direction === 'request' ? event.responder : event.requestor;
	const receivingAssetNameText = getAssetNameByTypeText(receivingAsset.type, receivingAsset.name);
	const eventRegardingAsset = event.direction === 'request' ? event.requestor : event.responder;
	const interactsWithAsset = event.direction === 'request' ? event.responder : event.requestor;

	const pathToSample = `${PATHS.SAMPLE_V2}?id=${event.endpoint_id}&direction=${event.direction}`;
	const pathToEvidence = generatePath(PATHS.EVENT_SAMPLE, { eventId: event.id });
	const pathToDataMap = `${PATHS.DATA_MAP}?entityId=${eventRegardingAsset.id}&type=${eventRegardingAsset.type}&interacts-with=${interactsWithAsset.id}`;

	const jsonPathFormatted = breakAtDots(event.json_path.replace(/^\$(\.|\[)/, ''));

	const formattedLocal = formatWithToday(event.created_at);
	const titleAsText = eventTitleAsText(event);
	const jiraSummary = `Soveren issue: ${titleAsText}`;
	const jiraDescription = `*${titleAsText}*

*Event*: #${event.id}
*Time*: ${formattedLocal}
*Sensitivity*: ${event.sensitivity}
*Category*: New data type
*Data types*: ${event.data_types.map((dtId) => dataTypesById.getState()[dtId].name).join(', ')}
*Sending*: ${sendingAssetNameText}${sendingAsset.namespace ? ` • ${sendingAsset.namespace}` : ''}
*Receiving*: ${receivingAssetNameText}${
		receivingAsset.namespace ? ` • ${receivingAsset.namespace}` : ''
	}
*Endpoint*: [${methodOrProtocol(event)}] ${event.hostname}${event.url}
*JSON path*: ${event.json_path.replace(/^\$(\.|\[)/, '')}
*Detected in*: ${event.direction}

See on Soveren dashboard: ${linkHere}`;

	return (
		<>
			<div className={styles.detailsGrid}>
				<Typo variant="D/Regular/Meta-CAP" color="secondary">
					ID
				</Typo>
				<Typo variant="D/Regular/Body-S">
					{event.id}{' '}
					<Tooltip
						title="Copy event link"
						placement="right"
						className={styles.copyToClipboardWrapper}
					>
						<CopyToClipboard
							value={linkHere}
							successText="Event link copied to clipboard"
							className={styles.copyToClipboard}
						/>
					</Tooltip>
				</Typo>

				<Typo variant="D/Regular/Meta-CAP" color="secondary">
					Sensitivity
				</Typo>
				<SensitivityChip sensitivity={event.sensitivity} data-test="event-details-sensitivity" />

				<Typo variant="D/Regular/Meta-CAP" color="secondary">
					Category
				</Typo>
				<Typo variant="D/Regular/Body-S">New data type</Typo>

				<Typo variant="D/Regular/Meta-CAP" color="secondary">
					Sending
				</Typo>
				<Asset asset={sendingAsset} dataTest="event-details-sending" />

				<Typo variant="D/Regular/Meta-CAP" color="secondary">
					Receiving
				</Typo>
				<Asset asset={receivingAsset} dataTest="event-details-receiving" />

				<Typo variant="D/Regular/Meta-CAP" color="secondary">
					Endpoint
				</Typo>
				<RouterLink
					inherit
					to={pathToSample}
					data-test="event-details-endpoint"
					state={{
						sample: {
							method: event.method,
							protocol: event.protocol,
							host: event.hostname,
							url: event.url,
						},
						asset: sendingAsset,
					}}
				>
					{event.hostname}
					{event.url}
					<SampleExistsIcon getSampleFn={getSample} sampleId={event.endpoint_id} />
					<br />
					<Typo variant="D/Regular/Meta" color="secondary">
						{methodOrProtocol(event)}
					</Typo>
				</RouterLink>

				<Typo variant="D/Regular/Meta-CAP" color="secondary">
					JSON path
				</Typo>
				<RouterLink inherit to={pathToSample}>
					{jsonPathFormatted}
				</RouterLink>

				<Typo variant="D/Regular/Meta-CAP" color="secondary">
					Detected in
				</Typo>
				<RouterLink inherit to={pathToSample}>
					{event.direction}
				</RouterLink>
			</div>

			<div className={styles.links}>
				<JiraButton summary={jiraSummary} description={jiraDescription} />

				<Button
					size="small"
					color="tertiary"
					endIcon={<Icon name="Open/Regular" size={20} />}
					href={pathToEvidence}
					target="_blank"
				>
					View sample
				</Button>

				<RouterLink to={pathToDataMap} data-test="data-map-link">
					<Button size="small" color="tertiary">
						View on data map
					</Button>
				</RouterLink>
			</div>
		</>
	);
}

function DatabaseDataTypeEvent({ event }: { event: DARNewDataTypeEventJson }) {
	const linkHere = `${location.origin}${PATHS.EVENTS}?id=${event.id}`;

	// const pathToSample = `${PATHS.SAMPLE_V2}?id=${event.endpoint_id}&direction=${event.direction}`;
	const pathToEvidence = generatePath(PATHS.EVENT_SAMPLE, { eventId: event.id });
	const pathToDataMap = `${PATHS.DATA_MAP}?entityId=${event.database?.database_id}&type=sql_db_database`;

	const titleAsText = eventTitleAsText(event);
	const formattedLocal = formatWithToday(event.created_at);
	const jiraSummary = `Soveren issue: ${titleAsText}`;
	const jiraDescription = `*${titleAsText}*

*Event*: #${event.id}
*Time*: ${formattedLocal}
*Sensitivity*: ${event.sensitivity}
*Category*: New data type
*Object type*: Database
*Object name*: ${event.database?.instance_name}
*Table*: ${event.database?.table_name}

See on Soveren dashboard: ${linkHere}`;

	return (
		<>
			<div className={styles.detailsGrid}>
				<Typo variant="D/Regular/Meta-CAP" color="secondary">
					ID
				</Typo>
				<Typo
					variant="D/Regular/Body-S"
					data-test="event-details-id"
					className={styles.horizontalAlignment}
				>
					{event.id}{' '}
					<Tooltip title="Copy event link" placement="right">
						<CopyToClipboard
							value={linkHere}
							successText="Event link copied to clipboard"
							className={styles.copyToClipboard}
						/>
					</Tooltip>
				</Typo>

				<Typo variant="D/Regular/Meta-CAP" color="secondary">
					Sensitivity
				</Typo>
				<SensitivityChip sensitivity={event.sensitivity} data-test="event-details-sensitivity" />

				<Typo variant="D/Regular/Meta-CAP" color="secondary">
					Category
				</Typo>
				<Typo variant="D/Regular/Body-S" data-test="event-details-object-type">
					New data type
				</Typo>

				<Typo variant="D/Regular/Meta-CAP" color="secondary">
					Object type
				</Typo>
				<Typo variant="D/Regular/Body-S" data-test="event-details-object-type">
					Database
				</Typo>

				<Typo color="secondary" variant="D/Regular/Meta-CAP">
					Object name
				</Typo>
				<RouterLink
					data-test="event-details-database-name"
					inherit
					to={generatePath(PATHS.DATABASES_ITEM, {
						id: event.database?.database_id,
					})}
				>
					{event.database?.database_name}
				</RouterLink>

				<Typo color="secondary" variant="D/Regular/Meta-CAP">
					Table
				</Typo>
				<RouterLink
					data-test="event-details-database-table"
					inherit
					to={generatePath(PATHS.DATABASES_TABLE_SAMPLE, {
						sampleId: event.database?.table_id,
					})}
					state={{
						instance_name: event.database?.instance_name,
						table_name: event.database?.table_name,
						type: event.database?.type,
					}}
				>
					{event.database?.table_name}
					<SampleExistsIcon
						getSampleFn={getDatabaseTableSample}
						sampleId={Number(event.database?.table_id)}
					/>
				</RouterLink>
			</div>

			<div className={styles.links}>
				<JiraButton summary={jiraSummary} description={jiraDescription} />
				<ViewSampleButton path={pathToEvidence} />
				<ViewOnDataMapButton path={pathToDataMap} />
			</div>
		</>
	);
}

function S3BucketDataTypeEvent({ event }: { event: DARNewDataTypeEventJson }) {
	const linkHere = `${location.origin}${PATHS.EVENTS}?id=${event.id}`;

	// const pathToSample = `${PATHS.SAMPLE_V2}?id=${event.endpoint_id}&direction=${event.direction}`;
	const pathToEvidence = generatePath(PATHS.EVENT_SAMPLE, { eventId: event.id });
	const pathToDataMap = `${PATHS.DATA_MAP}?entityId=${event.s3_bucket?.id}&type=s3_bucket`;

	const titleAsText = eventTitleAsText(event);
	const formattedLocal = formatWithToday(event.created_at);
	const jiraSummary = `Soveren issue: ${titleAsText}`;
	const jiraDescription = `*${titleAsText}*

*Event*: #${event.id}
*Time*: ${formattedLocal}
*Sensitivity*: ${event.sensitivity}
*Category*: New data type
*Object type*: S3 bucket
*Object name*: ${event.s3_bucket?.name}
*File*: ${event.s3_bucket?.cluster_name}

See on Soveren dashboard: ${linkHere}`;

	return (
		<>
			<div className={styles.detailsGrid}>
				<Typo variant="D/Regular/Meta-CAP" color="secondary">
					ID
				</Typo>
				<Typo
					variant="D/Regular/Body-S"
					data-test="event-details-id"
					className={styles.horizontalAlignment}
				>
					{event.id}{' '}
					<Tooltip title="Copy event link" placement="right">
						<CopyToClipboard
							value={linkHere}
							successText="Event link copied to clipboard"
							className={styles.copyToClipboard}
						/>
					</Tooltip>
				</Typo>

				<Typo variant="D/Regular/Meta-CAP" color="secondary">
					Sensitivity
				</Typo>
				<SensitivityChip sensitivity={event.sensitivity} data-test="event-details-sensitivity" />

				<Typo variant="D/Regular/Meta-CAP" color="secondary">
					Category
				</Typo>
				<Typo variant="D/Regular/Body-S" data-test="event-details-object-type">
					New data type
				</Typo>

				<Typo variant="D/Regular/Meta-CAP" color="secondary">
					Object type
				</Typo>
				<Typo variant="D/Regular/Body-S" data-test="event-details-object-type">
					S3 bucket
				</Typo>

				<Typo color="secondary" variant="D/Regular/Meta-CAP">
					Object name
				</Typo>
				<RouterLink
					data-test="event-details-bucket-name"
					inherit
					to={generatePath(PATHS.S3_BUCKETS_ITEM, {
						id: event.s3_bucket?.id,
					})}
				>
					{event.s3_bucket?.name}
				</RouterLink>

				<Typo color="secondary" variant="D/Regular/Meta-CAP">
					File
				</Typo>
				<RouterLink
					data-test="event-details-bucket-file"
					inherit
					to={generatePath(PATHS.S3_CLUSTER_ITEM, {
						id: event.s3_bucket?.id,
						clusterId: event.s3_bucket?.cluster_id,
					})}
					state={{
						name: event.s3_bucket?.cluster_name,
					}}
				>
					{event.s3_bucket?.cluster_name}
					<SampleExistsIcon
						getSampleFn={getS3ClusterFileWithSamples}
						sampleId={Number(event.s3_bucket?.cluster_id)}
					/>
				</RouterLink>
			</div>

			<div className={styles.links}>
				<JiraButton summary={jiraSummary} description={jiraDescription} />
				<ViewSampleButton path={pathToEvidence} />
				<ViewOnDataMapButton path={pathToDataMap} />
			</div>
		</>
	);
}

function KafkaDataTypeEvent({ event }: { event: DARNewDataTypeEventJson }) {
	const linkHere = `${location.origin}${PATHS.EVENTS}?id=${event.id}`;

	// const pathToSample = `${PATHS.SAMPLE_V2}?id=${event.endpoint_id}&direction=${event.direction}`;
	const pathToEvidence = generatePath(PATHS.EVENT_SAMPLE, { eventId: event.id });
	const pathToDataMap = `${PATHS.DATA_MAP}?entityId=${event.kafka?.instance_id}&type=kafka_instance`;

	const titleAsText = eventTitleAsText(event);
	const formattedLocal = formatWithToday(event.created_at);
	const jiraSummary = `Soveren issue: ${titleAsText}`;
	const jiraDescription = `*${titleAsText}*

*Event*: #${event.id}
*Time*: ${formattedLocal}
*Sensitivity*: ${event.sensitivity}
*Category*: New data type
*Object type*: Kafka topic
*Object name*: ${event.kafka?.instance_name}
*Message scheme*: ${event.kafka?.scheme_name}

See on Soveren dashboard: ${linkHere}`;

	return (
		<>
			<div className={styles.detailsGrid}>
				<Typo variant="D/Regular/Meta-CAP" color="secondary">
					ID
				</Typo>
				<Typo
					variant="D/Regular/Body-S"
					data-test="event-details-id"
					className={styles.horizontalAlignment}
				>
					{event.id}{' '}
					<Tooltip title="Copy event link" placement="right">
						<CopyToClipboard
							value={linkHere}
							successText="Event link copied to clipboard"
							className={styles.copyToClipboard}
						/>
					</Tooltip>
				</Typo>

				<Typo variant="D/Regular/Meta-CAP" color="secondary">
					Sensitivity
				</Typo>
				<SensitivityChip sensitivity={event.sensitivity} data-test="event-details-sensitivity" />

				<Typo variant="D/Regular/Meta-CAP" color="secondary">
					Category
				</Typo>
				<Typo variant="D/Regular/Body-S" data-test="event-details-object-type">
					New data type
				</Typo>

				<Typo variant="D/Regular/Meta-CAP" color="secondary">
					Object type
				</Typo>
				<Typo variant="D/Regular/Body-S" data-test="event-details-object-type">
					Kafka topic
				</Typo>

				<Typo color="secondary" variant="D/Regular/Meta-CAP">
					Object name
				</Typo>
				<RouterLink
					data-test="event-details-kafka-topic"
					inherit
					to={generatePath(PATHS.KAFKA_TOPICS_ITEM, {
						id: event.kafka?.topic_id,
					})}
				>
					{event.kafka?.topic_name}
				</RouterLink>

				<Typo color="secondary" variant="D/Regular/Meta-CAP">
					Message scheme
				</Typo>
				<RouterLink
					data-test="event-details-kafka-schema"
					inherit
					to={generatePath(PATHS.KAFKA_MESSAGE_SCHEMA_ITEM, {
						topicId: event.kafka?.topic_id,
						schemaId: event.kafka?.scheme_id,
					})}
					state={{
						name: event.kafka?.scheme_name,
					}}
				>
					{event.kafka?.scheme_name}
					<SampleExistsIcon
						getSampleFn={getKafkaMessageSchema}
						sampleId={Number(event.kafka?.scheme_id)}
					/>
				</RouterLink>
			</div>

			<div className={styles.links}>
				<JiraButton summary={jiraSummary} description={jiraDescription} />
				<ViewSampleButton path={pathToEvidence} />
				<ViewOnDataMapButton path={pathToDataMap} />
			</div>
		</>
	);
}

export function DataTypeEvent({ event }: Props) {
	function isService(_event: NewDataTypeEventJson): _event is DIMNewDataTypeEventJson {
		// @ts-ignore
		return _event.object_type === 'Service' || !!_event.url; // TODO remove second condition when backend is in master
	}

	if (isService(event)) {
		return <DIMDataTypeEvent event={event} />;
	} else if (event.database) {
		return <DatabaseDataTypeEvent event={event} />;
	} else if (event.s3_bucket) {
		return <S3BucketDataTypeEvent event={event} />;
	} else if (event.kafka) {
		return <KafkaDataTypeEvent event={event} />;
	}

	return <>{event.object_type}</>;
}
