import { useMemo } from 'react';
import { SamplePageBody } from 'models/sample/dto';
import { SampleViewer } from 'views/common/SampleViewer';
import { ndJsonToYamlDocuments } from './yamlParser';

type Props = Pick<SamplePageBody, 'sample' | 'data_fields'>;

function YAMLViewer({ sample, data_fields }: Props) {
	const yamlDocuments = useMemo(
		() => ndJsonToYamlDocuments(sample, data_fields),
		[sample, data_fields]
	);

	return <SampleViewer documents={yamlDocuments} />;
}

export { YAMLViewer };
