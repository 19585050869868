import { default as UiMenuItem, MenuItemProps } from '@material-ui/core/MenuItem';
import cn from 'classnames';
import { ForwardedRef, forwardRef } from 'react';
import styles from './index.module.pcss';

// https://github.com/mui-org/material-ui/issues/16245
interface IMenuItem extends MenuItemProps<'li', { button?: true }> {
	size?: 'medium' | 'small' | 'extraSmall';
	theme?: 'default' | 'danger';
	'data-test'?: string;
}

const MenuItem = forwardRef(function MenuItem(
	{ theme = 'default', size = 'medium', className, ...params }: IMenuItem,
	ref: ForwardedRef<HTMLLIElement>
) {
	return (
		<UiMenuItem
			data-test="menu-item"
			ref={ref}
			classes={{
				root: cn(styles.root, className, styles[theme], styles[size]),
				selected: styles.selected,
			}}
			{...params}
		/>
	);
});

export default MenuItem;
