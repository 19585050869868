import { useEffect, useState } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import Accordion from 'components/Accordion';
import AccordionDetails from 'components/AccordionDetails';
import AccordionSummary from 'components/AccordionSummary';
import Badge from 'components/Badge';
import Button from 'components/form/Button';
import Icon from 'components/Icon';
import { PiiTypeList } from 'components/PiiType';
import Preloader from 'components/Preloader';
import { enqueueSnackbar } from 'components/Snackbar';
import GridBody from 'components/table/GridBody';
import GridCell from 'components/table/GridCell';
import GridHead from 'components/table/GridHead';
import GridRow from 'components/table/GridRow';
import GridTable from 'components/table/GridTable';
import Typo from 'components/typography/Typo';
import { getAssetsExtended } from 'models/assetsExtended/api';
import { TAssetsItem } from 'models/assetsExtended/dto';
import { getAssetGroupCounters } from 'models/assetsGroups/api';
import { AssetGroup, AssetGroupCounters } from 'models/assetsGroups/dto';
import { getAssetGroupDataFlows } from 'models/assetsGroupsDataFlows/api';
import { AssetGroupDataFlowTableItem } from 'models/assetsGroupsDataFlows/dto';
import { PATHS } from 'services/router';
import { getAssetNameByType } from 'views/common/AssetNameWithIcon';
import DataFlowIcon from 'views/SuperAssets/DataFlowIcon';
import { unselectInteractsWith } from '../../model/store';
import commonStyles from '../index.module.css';
import styles from './index.module.pcss';

type Props = {
	groupId: AssetGroup['id'];
};

function Details(props: Props) {
	const { groupId } = props;

	const history = useHistory();

	const [counters, setCounters] = useState<AssetGroupCounters | null>(null);
	const [dataflows, setDataflows] = useState<AssetGroupDataFlowTableItem[]>([]);
	const [assets, setAssets] = useState<TAssetsItem[]>([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setLoading(true);

		Promise.all([
			getAssetGroupDataFlows(groupId, { limit: 100 }),
			getAssetGroupCounters(groupId),
			getAssetsExtended({
				group: groupId,
				is_external: false,
				sort: 'sensitivity:desc',
				limit: 100,
				offset: 0,
			}),
		])
			.then(([df, c, a]) => {
				setDataflows(df.dataflows);
				setCounters(c);
				setAssets(a.assets);
			})
			.catch(async (e) => {
				if (e.response.status === 422 || e.response.status === 400) {
					const errorBody = await e.response.json();
					enqueueSnackbar(errorBody.message);
				} else {
					throw e;
				}
			})
			.finally(() => {
				setLoading(false);
			});

		return unselectInteractsWith;
	}, [groupId]);

	return (
		<div className={commonStyles.detailsContainer}>
			<Preloader isLoading={loading}>
				<div className={commonStyles.scrollContainer}>
					<Accordion defaultExpanded={true} className={commonStyles.sidePaddings}>
						<AccordionSummary className={commonStyles.sidePaddings}>
							Data flows <Badge className={commonStyles.badge} badgeContent={counters?.dataflows} />
						</AccordionSummary>

						<AccordionDetails>
							<GridTable className={commonStyles.table}>
								<GridHead>
									<GridRow className={styles.dataflowRowContainer} border>
										<GridCell head className={styles.gridCellHead}>
											Flow
										</GridCell>
										<GridCell head className={styles.gridCellHead}>
											INTERACTIONS
										</GridCell>
										<GridCell head className={styles.gridCellHead}>
											DATA TYPES
										</GridCell>
									</GridRow>
								</GridHead>

								<GridBody className={styles.tableBody}>
									{dataflows.map((dataflow, i) => {
										const { flow_direction, data_types, name, interacts_with } = dataflow;

										return (
											<GridRow
												key={i}
												className={styles.dataflowRowContainer}
												onClick={() => {
													const path = generatePath(PATHS.ASSET_GROUP_ITEM, {
														id: groupId,
														tab: 'data-flows',
														details_with: interacts_with,
														flow_direction,
													});
													history.push(path);
												}}
												border
												hover
											>
												<GridCell className={styles.flowDirection}>
													<DataFlowIcon
														direction={flow_direction}
														className={styles.dataflowIcon}
													/>
												</GridCell>
												<GridCell className={styles.gapCell}>
													<Typo variant="D/Medium/Meta">{name}</Typo>
												</GridCell>
												<GridCell>
													<PiiTypeList noHighlight data={data_types} size="extraSmall" />
												</GridCell>
											</GridRow>
										);
									})}
								</GridBody>

								<Button
									color="tertiary"
									size="small"
									endIcon={<Icon name="Open/Regular" size={20} />}
									fullWidth
									href={generatePath(PATHS.ASSET_GROUP_ITEM, {
										id: groupId,
										tab: 'data-flows',
									})}
									target="_blank"
									className={styles.detailedInfo}
								>
									View detailed info
								</Button>
							</GridTable>
						</AccordionDetails>
					</Accordion>

					<Accordion className={commonStyles.sidePaddings}>
						<AccordionSummary className={commonStyles.sidePaddings}>
							Assets <Badge className={commonStyles.badge} badgeContent={counters?.assets} />
						</AccordionSummary>
						<AccordionDetails>
							<GridTable className={commonStyles.table}>
								<GridHead>
									<GridRow className={styles.assetRowContainer} border>
										<GridCell head className={styles.gridCellHead}>
											Asset
										</GridCell>
										<GridCell head className={styles.gridCellHead}>
											DATA TYPES
										</GridCell>
									</GridRow>
								</GridHead>

								<GridBody className={styles.tableBody}>
									{assets.map((asset, i) => {
										const { data_types, id, name, type, namespace, k8s_types } = asset;
										const displayName = getAssetNameByType(type, name);
										const k8sLabel = k8s_types.join(', ');

										return (
											<GridRow
												key={i}
												className={styles.assetRowContainer}
												onClick={() => {
													const path = generatePath(PATHS.SERVICE_ITEM, {
														id,
														tab: 'info',
													});
													history.push(path);
												}}
												border
												hover
											>
												<GridCell className={styles.gapCell}>
													<Typo variant="D/Medium/Meta">{displayName}</Typo>

													<Typo variant="D/Regular/Meta" color="secondary">
														{[k8sLabel, namespace].join(' • ')}
													</Typo>
												</GridCell>
												<GridCell>
													<PiiTypeList noHighlight data={data_types} size="extraSmall" />
												</GridCell>
											</GridRow>
										);
									})}
								</GridBody>

								<Button
									color="tertiary"
									size="small"
									endIcon={<Icon name="Open/Regular" size={20} />}
									fullWidth
									href={generatePath(PATHS.ASSET_GROUP_ITEM, {
										id: groupId,
										tab: 'assets',
									})}
									target="_blank"
									className={styles.detailedInfo}
								>
									View detailed info
								</Button>
							</GridTable>
						</AccordionDetails>
					</Accordion>
				</div>
			</Preloader>
		</div>
	);
}

export default Details;
