import { get } from 'api/Api';
import checkResponse from 'services/api/checkResponse';
import { CountersJson } from './dto';
import { countersJsonExample } from './examples';

async function getCounters(onlyWithPii?: boolean, signal?: AbortSignal) {
	const params = onlyWithPii ? { only_with_pii: true } : undefined;
	const config = signal ? { signal } : {};

	return get<CountersJson>('v2/collected_group/counters', params, config).then(
		checkResponse(countersJsonExample)
	);
}

export { getCounters };
