import { get } from 'api/Api';
import { S3Sample, S3CSVSample } from 'models/s3Sample/dto';
import { ApiParams } from 'services/api';
import checkResponse from 'services/api/checkResponse';
import { s3JsonSampleExample } from './examples';

async function getS3Sample(id: S3CSVSample['id'], apiParams?: ApiParams, signal?: AbortSignal) {
	const config = signal ? { signal } : {};

	return get<S3Sample>(`v1/s3/object-samples/${id}`, apiParams, config).then(
		checkResponse(s3JsonSampleExample)
	);
}

export { getS3Sample };
