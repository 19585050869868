import cn from 'classnames';
import { ReactNode } from 'react';
import styles from './index.module.pcss';

interface Props {
	children: ReactNode;
	className?: string;
	dataTest?: string;
}

function GridTable({ children, className, dataTest }: Props) {
	return (
		<div className={cn(className, styles.table)} data-test={dataTest}>
			{children}
		</div>
	);
}

export default GridTable;
