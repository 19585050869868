import { generatePath } from 'react-router';
import Button from 'components/form/Button';
import Icon from 'components/Icon';
import Preloader from 'components/Preloader';
import { RouterLink } from 'components/typography/Link';
import Typo from 'components/typography/Typo';
import { PATHS } from 'services/router';
import styles from './index.module.css';

interface Props {
	onClick: () => void;
	loading: boolean;
}

function NoPoliciesBlock({ loading, onClick }: Props) {
	return (
		<div className={styles.container}>
			<Typo variant="D/Medium/Body" className={styles.header}>
				{loading ? 'Generating Policies...' : 'No Policies'}
			</Typo>

			<Preloader isLoading={loading} size={24}>
				<Typo color="secondary" variant="D/Regular/Body-S" className={styles.description}>
					You can create a new Policy from scratch manually or auto-generate
					<br />
					Policies. In this case, we will automatically create Policies based on
					<br />
					your data and then you can change them manually.
				</Typo>

				<Button
					data-test="policy-generate-button"
					className={styles.button}
					color="primary"
					onClick={onClick}
					startIcon={<Icon name="MagicWand/Regular" />}
				>
					Autogenerate Policies
				</Button>

				<RouterLink to={generatePath(PATHS.POLICY_ITEM, { id: 'new' })} className={styles.button}>
					<Button
						data-test="policy-create-button"
						className={styles.button}
						color="tertiary"
						startIcon={<Icon name="Add/Regular" />}
						component="span"
					>
						Create Policy
					</Button>
				</RouterLink>
			</Preloader>
		</div>
	);
}

export default NoPoliciesBlock;
