import { AccordionDetails as UiAccordionDetails, AccordionDetailsProps } from '@material-ui/core';
import styles from './index.module.pcss';

function AccordionDetails(props: AccordionDetailsProps) {
	return (
		<UiAccordionDetails
			classes={{
				root: styles.root,
			}}
			{...props}
		/>
	);
}

export default AccordionDetails;
