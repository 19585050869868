import Button from 'components/form/Button';
import { RouterLink } from 'components/typography/Link';
import { PATHS } from 'services/router';
import styles from './index.module.pcss';

type Props = {
	isDAR?: boolean;
};

function NewGatewayButton(props: Props) {
	const { isDAR } = props;

	return (
		<RouterLink to={isDAR ? PATHS.SENSORS_DAR : PATHS.SENSORS} className={styles.button}>
			<Button color="tertiary">Manage sensors</Button>
		</RouterLink>
	);
}

export default NewGatewayButton;
