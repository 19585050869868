import { useState } from 'react';
import Button from 'components/form/Button';
import { goBackByDefault } from 'services/history';
import { PATHS } from 'services/router';
import styles from './index.module.css';

export function DataTypeFormFooter({
	isNew = false,
	isError = false,
	saveForm,
}: {
	isNew: boolean;
	isError: boolean;
	saveForm: () => void;
}) {
	const [isLoading, setIsLoading] = useState(false);

	return (
		<>
			<Button
				color="secondary"
				size="medium"
				onClick={function cancelButtonHandler() {
					goBackByDefault(PATHS.DATA_TYPES_LIST);
				}}
				data-test="data-type-form-cancel"
			>
				Cancel
			</Button>

			<Button
				data-test="data-type-form-create-save"
				type="submit"
				color="primary"
				size="medium"
				onClick={async function saveButtonHandler() {
					setIsLoading(true);
					await saveForm();
					setIsLoading(false);
				}}
				disabled={isLoading || isError}
				className={styles.saveButton}
			>
				{isNew ? 'Create' : 'Save'}
			</Button>
		</>
	);
}
