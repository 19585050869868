import { createStore } from 'effector';
import { SensorDARItem, SensorDARList } from './dto';
import {
	getSensorsDARFx,
	createSensorDARFx,
	updateSensorDARFx,
	deleteSensorDARFx,
} from './effects';

const sensorsDARStore = createStore<SensorDARList>([]);

// Subscribe to events/effects

sensorsDARStore.on(getSensorsDARFx.doneData, (state: SensorDARList, data: SensorDARList) => data);

sensorsDARStore.on(createSensorDARFx.doneData, (state: SensorDARList, data: SensorDARItem) => {
	return state.concat(data);
});

sensorsDARStore.on(updateSensorDARFx.doneData, (state: SensorDARList, data: SensorDARItem) =>
	state.map((sensor) => (sensor.id === data.id ? data : sensor))
);

sensorsDARStore.on(deleteSensorDARFx.doneData, (state: SensorDARList, data: SensorDARItem['id']) =>
	state.filter((sensor) => sensor.id !== data)
);

// Public API

const sensorsDARList = sensorsDARStore.map((sensors) => sensors);
const sensorsDARNames = sensorsDARStore.map(
	(sensors) => new Set(sensors.map((sensor) => sensor.name))
);
// Later other stores may be implemented

export { sensorsDARList, sensorsDARNames };
