import cn from 'classnames';
import Icon from 'components/Icon';
import { DataFlowDetailsTable } from 'models/assetDataFlowDetailsV2/dto';
import { TDataDirectionType } from 'models/common/dto';
import styles from './index.module.pcss';

interface Props {
	direction: TDataDirectionType;
	flowDirection?: DataFlowDetailsTable['flow_direction'];
	className?: string;
}

function DataFlowIcon({ direction, flowDirection, className }: Props) {
	return (
		<div className={cn(className, styles.container)}>
			<Icon
				name={direction === 'both' || direction === 'none' ? 'arrowSwapBoth' : 'arrowSwapForward'}
				size={24}
				className={cn({
					[styles.iconSender]: direction === 'sender',
					[styles.iconReceiver]: direction === 'receiver',
					[styles.iconBoth]: direction === 'both',
					[styles.iconNone]: direction === 'none',
				})}
			/>

			{flowDirection && (
				<Icon
					name="arrowSwapActive"
					size={24}
					className={cn(styles.iconActive, {
						[styles.iconIncoming]: flowDirection === 'incoming',
					})}
				/>
			)}
		</div>
	);
}

export default DataFlowIcon;
