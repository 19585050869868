import { Fragment, useMemo, useState } from 'react';
import Button from 'components/form/Button';
import Icon from 'components/Icon';
import Typo from 'components/typography/Typo';
import { AssetRuleItem, AssetRulesGroup } from 'models/assets/dto';
import styles from './index.module.css';
import RuleRow from './RuleRow';

/*
	HELPERS
*/
type TransformedRuleItem = { id: number; value: string }; // Unique id is necessary for React key management; using array index will lead to bugs when inserting/deleting items.

type TransformedRule = {
	id: number; // Unique id is necessary, see above comment.
	ips: TransformedRuleItem[];
	userAgents: TransformedRuleItem[];
};

function nextId() {
	return nextId.val++;
}
nextId.val = 1;

function mapRulesToTransformed(rules: AssetRulesGroup): TransformedRule[] {
	// Called once, on init

	return rules.map((rule) => {
		const result: TransformedRule = { id: nextId(), ips: [], userAgents: [] };

		for (const ruleGroup of rule) {
			const transformedValues: TransformedRuleItem[] = ruleGroup.values.map((value) => ({
				id: nextId(),
				value,
			}));

			if (ruleGroup.type === 'ip') {
				result.ips = result.ips.concat(transformedValues);
			} else if (ruleGroup.type === 'header' && ruleGroup.key === 'User-Agent') {
				result.userAgents = result.userAgents.concat(transformedValues);
			} else {
				throw new Error(`Unknown rule type, ${JSON.stringify(ruleGroup)}`);
			}
		}

		return result;
	});
}

function mapTransformedToRules(transformedRules: TransformedRule[]): AssetRulesGroup {
	// Called on each change on form

	return transformedRules.map((transformedRule) => {
		const result: AssetRuleItem[] = [];

		if (transformedRule.ips.length > 0) {
			result.push({
				type: 'ip',
				key: '',
				values: transformedRule.ips.map((transformedValue) => transformedValue.value),
			});
		}

		if (transformedRule.userAgents.length > 0) {
			result.push({
				type: 'header',
				key: 'User-Agent',
				values: transformedRule.userAgents.map((transformedValue) => transformedValue.value),
			});
		}

		return result;
	});
}

/*
	MAIN COMPONENT

	Uncontrolled by design.
*/
type Props = {
	initialRules: AssetRulesGroup;
	onChange: (rules: AssetRulesGroup) => void;
};

function RuleBuilder(props: Props) {
	const { initialRules, onChange } = props;

	const initialRulesTransformed = useMemo(() => {
		return mapRulesToTransformed(initialRules);
	}, []); // no dependencies, must only be called once on mount.

	const [rulesTransformed, _setRulesTransformed] =
		useState<TransformedRule[]>(initialRulesTransformed);

	function setRulesTransformed(newRules: TransformedRule[]) {
		_setRulesTransformed(newRules);
		onChange(mapTransformedToRules(newRules));
	}

	function onChangeIPs(oldRule: TransformedRule) {
		return function (items: TransformedRuleItem[]) {
			onChangeValues({ ...oldRule, ips: items });
		};
	}

	function onChangeUserAgents(oldRule: TransformedRule) {
		return function (items: TransformedRuleItem[]) {
			onChangeValues({ ...oldRule, userAgents: items });
		};
	}

	function onChangeValues(newRule: TransformedRule) {
		const shouldDelete = newRule.ips.length === 0 && newRule.userAgents.length === 0;

		const newRules = shouldDelete
			? rulesTransformed.filter((tr) => tr.id !== newRule.id)
			: rulesTransformed.map((tr) => (tr.id !== newRule.id ? tr : newRule));

		setRulesTransformed(newRules);
	}

	function addIPRule() {
		const result = rulesTransformed.concat({
			id: nextId(),
			ips: [{ id: nextId(), value: '' }],
			userAgents: [],
		});

		setRulesTransformed(result);
	}

	function addIPRuleToExisting(oldRule: TransformedRule) {
		const result = rulesTransformed.map((tr) =>
			tr === oldRule
				? {
						...tr,
						ips: [{ id: nextId(), value: '' }],
				  }
				: tr
		);

		setRulesTransformed(result);
	}

	function addUserAgentRule() {
		const result = rulesTransformed.concat({
			id: nextId(),
			ips: [],
			userAgents: [{ id: nextId(), value: '' }],
		});

		setRulesTransformed(result);
	}

	function addUserAgentRuleToExisting(oldRule: TransformedRule) {
		const result = rulesTransformed.map((tr) =>
			tr === oldRule
				? {
						...tr,
						userAgents: [{ id: nextId(), value: '' }],
				  }
				: tr
		);

		setRulesTransformed(result);
	}

	return (
		<>
			<div className={styles.rulesGrid}>
				{rulesTransformed.map((transformedRule, i) => (
					<Fragment key={transformedRule.id}>
						<Typo variant="D/Medium/Body-S">{i === 0 ? 'add' : 'or'}</Typo>

						<div className={styles.ruleContainer} data-test="asset-rules-list">
							{transformedRule.ips.length > 0 && (
								<RuleRow
									type="ip"
									items={transformedRule.ips}
									onChange={onChangeIPs(transformedRule)}
								/>
							)}

							{transformedRule.userAgents.length > 0 && (
								<RuleRow
									type="userAgent"
									items={transformedRule.userAgents}
									onChange={onChangeUserAgents(transformedRule)}
									prefix={transformedRule.ips.length > 0 ? 'or' : undefined}
								/>
							)}

							{transformedRule.ips.length === 0 && (
								<Button
									size="extraSmall"
									color="transparent"
									onClick={() => addIPRuleToExisting(transformedRule)}
									className={styles.grayButton}
									data-test="asset-rules-add-ip-button"
								>
									<Icon name="Add/Regular" size={18} className={styles.buttonIcon} /> Add IP/IP
									range
								</Button>
							)}

							{transformedRule.userAgents.length === 0 && (
								<Button
									size="extraSmall"
									color="transparent"
									onClick={() => addUserAgentRuleToExisting(transformedRule)}
									className={styles.grayButton}
									data-test="asset-rules-add-agent-button"
								>
									<Icon name="Add/Regular" size={18} className={styles.buttonIcon} /> Add user agent
								</Button>
							)}
						</div>
					</Fragment>
				))}
			</div>

			<div className={styles.ruleButtonList}>
				<Button
					color="tertiary"
					size="small"
					className={styles.ruleButton}
					data-test="asset-info-add-ip-button"
					onClick={addIPRule}
				>
					<Icon name="Add/Regular" size={18} className={styles.buttonIcon} />
					Add IP/IP range
				</Button>

				<Button
					color="tertiary"
					size="small"
					className={styles.ruleButton}
					data-test="asset-info-add-user-agent-button"
					onClick={addUserAgentRule}
				>
					<Icon name="Add/Regular" size={18} className={styles.buttonIcon} />
					Add user agent
				</Button>
			</div>
		</>
	);
}

export type { TransformedRuleItem };
export { nextId };
export default RuleBuilder;
