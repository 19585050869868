import { useCallback, useMemo } from 'react';
import Select from 'components/form/Select';
import DropdownButton from 'components/form/Select/DropdownButton';
import { FilterPropsBase } from '../model';

type Value = '3' | '7' | '30' | '90';

const options: { id: Value; name: string }[] = [
	{ id: '3', name: 'Last 3 days' },
	{ id: '7', name: 'Last 7 days' },
	{ id: '30', name: 'Last 30 days' },
	{ id: '90', name: 'Last 90 days' },
];

type Props = FilterPropsBase & {
	value: Value;
	onChange: (newValue: Value) => void;
};

function EventStartFilter({ fixed, onChange, onClose, resetFilter, value }: Props) {
	const selected = useMemo(
		() => options.find((option) => !!value && option.id === value),
		[value]
	)!;

	const dropdownButton = useCallback(
		(dropdownProps) => <DropdownButton {...dropdownProps} fixed={fixed} onClose={resetFilter} />,
		[fixed, resetFilter]
	);

	return (
		<Select
			options={options}
			value={selected}
			onChange={(data) => onChange(data.id)}
			onClose={onClose}
			label={selected.name}
			dataTest="select-event-start"
			render={{ dropdownButton }}
		/>
	);
}

export default EventStartFilter;
