import { get, post } from 'api/Api';
import { ApiParams } from 'services/api';
import checkResponse from 'services/api/checkResponse';
import {
	DatabaseDetails,
	DatabaseInstanceList,
	DatabaseItem,
	DatabaseList,
	DatabaseTableList,
	DatabaseTableSample,
} from './dto';
import {
	databaseDetailsExample,
	databaseInstanceListExample,
	databaseListExample,
	databaseTableExample,
	databaseTableListExample,
} from './examples';

const API_URL = 'v1/sql-db';

export async function getDatabaseInstanceList() {
	return get<DatabaseInstanceList>(`${API_URL}/instances`).then(
		checkResponse(databaseInstanceListExample)
	);
}
export async function getDatabaseList(apiParams: ApiParams, signal?: AbortSignal) {
	const config = signal ? { signal } : {};

	return get<DatabaseList>(`${API_URL}/databases`, apiParams, config).then(
		checkResponse(databaseListExample)
	);
}

export async function getDatabaseDetails(id: DatabaseItem['id']) {
	return get<DatabaseDetails>(`${API_URL}/databases/${id}`).then(
		checkResponse(databaseDetailsExample)
	);
}

export async function updateDatabaseDetails({ id, ...payload }: DatabaseDetails) {
	return post<DatabaseDetails>(`${API_URL}/databases/${id}`, payload).then(
		checkResponse(databaseDetailsExample)
	);
}

export async function getDatabaseTableList(apiParams: ApiParams, signal?: AbortSignal) {
	const config = signal ? { signal } : {};

	return get<DatabaseTableList>(`${API_URL}/tables`, apiParams, config).then(
		checkResponse(databaseTableListExample)
	);
}

export async function getDatabaseTableSample(
	id: DatabaseTableSample['table_id'],
	signal?: AbortSignal
) {
	const config = signal ? { signal } : {};

	return get<DatabaseTableSample>(`${API_URL}/samples/${id}`, undefined, config).then(
		checkResponse(databaseTableExample)
	);
}
