import { IHeadCell } from 'components/table/EnhancedTableHead';
import { DatabaseTableItem } from 'models/databases/dto';

const tableConfig: IHeadCell<DatabaseTableItem>[] = [
	{
		id: 'name',
		numeric: false,
		label: 'Name',
		sortable: true,
	},
	// {
	// 	id: 'rows_count',
	// 	numeric: true,
	// 	label: 'Est. Rows',
	// 	sortable: true,
	// },
	{
		id: 'size',
		numeric: true,
		label: 'size',
		sortable: true,
	},
	{
		id: 'sensitivity',
		numeric: false,
		label: 'Sensitivity',
		sortable: true,
	},
	{
		id: 'data_types',
		numeric: false,
		label: 'Data types',
		sortable: false,
	},
	{
		id: 'last_checked',
		numeric: false,
		label: 'Last scan',
		sortable: true,
		reversedSort: true,
	},
];

export default tableConfig;
