import { MenuList } from '@material-ui/core';
import cn from 'classnames';
import { useStore } from 'effector-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { CheckboxBase } from 'components/Checkbox';
import { FilterPropsBase } from 'components/FilterLine/model';
import MultiSelect from 'components/form/MultiSelect';
import multiSelectStyles from 'components/form/MultiSelect/index.module.css';
import { OptionListProps } from 'components/form/MultiSelect/OptionList';
import selectStyles from 'components/form/Select/index.module.css';
import Icon from 'components/Icon';
import MenuItem from 'components/MenuItem';
import PiiType from 'components/PiiType';
import Typo from 'components/typography/Typo';
import { DataTypeItem, DataTypeStructured } from 'models/dataTypes/dto';
import { getDataTypesFx } from 'models/dataTypes/effects';
import {
	dataTypesPartsByIds,
	getDataTypeCheckboxState,
	selectDataType,
} from 'models/dataTypes/helpers';
import { dataTypesStructuredStore } from 'models/dataTypes/store';
import { onceFx } from 'models/modelUtils/onceFx';
import styles from './index.module.css';

type Props = FilterPropsBase & {
	value: DataTypeItem['id'][];
	onChange: (newValue: DataTypeItem['id'][]) => void;
	showUnused?: boolean;
};

function PolicyDataTypes(props: Props) {
	const { defaultOpen, fixed, onClose, value, onChange, resetFilter, showUnused } = props;

	const dataTypesStructured = useStore(dataTypesStructuredStore);

	useEffect(() => {
		onceFx(getDataTypesFx);
	}, []);

	const options = useMemo(
		() =>
			dataTypesStructured
				.map((dtGroup) => [dtGroup, dtGroup.child_items])
				.flat(2)
				.filter((dt) => (showUnused ? true : dt.isUsed)),
		[dataTypesStructured]
	);

	const selected = options.filter((dt) => value.includes(dt.id));

	function handleChange(selectedDataTypes: DataTypeStructured[]) {
		onChange(selectedDataTypes.map((dt) => dt.id));
	}

	const dropdownButton = useCallback(
		(dropdownProps) => (
			<Typo
				component="div"
				variant="D/Medium/Body-S"
				className={styles.dropdownButton}
				{...dropdownProps}
				dataTest="policy-add-data-type"
			>
				<Icon name="Add/Filled" size={16} />
				Add data type
			</Typo>
		),
		[fixed, resetFilter]
	);

	const selectedWithGroups = useMemo(() => {
		const dataTypesParts = dataTypesPartsByIds(selected.map(({ id }) => id));

		return [...dataTypesParts.groups, ...dataTypesParts.outOfGroup];
	}, [selected]);

	return (
		<div className={styles.dataTypeSelector} data-test="policy-data-types-container">
			{selectedWithGroups.map((dt) => (
				<PiiType type={dt.id} key={dt.id} />
			))}
			<div className={cn(styles.controls, selected.length > 1 && styles.controlsMargin)}>
				<MultiSelect
					defaultOpen={defaultOpen}
					hasApplyButton
					options={options}
					value={selected}
					onChange={handleChange}
					onClose={onClose}
					label=""
					render={{
						dropdownButton,
						optionList: DataTypeOptionList,
					}}
				/>
				<Typo
					variant="D/Medium/Body-S"
					color="secondary"
					className={styles.resetButton}
					onClick={() => onChange([])}
					dataTest="policy-data-types-reset"
				>
					Reset
				</Typo>
			</div>
		</div>
	);
}

function DataTypeOptionList(props: OptionListProps<DataTypeStructured>) {
	const {
		options,
		value: externalValue,
		onChange: externalOnChange,
		onClose,
		renderApplyButton,
	} = props;

	const [internalValue, setInternalValue] = useState(externalValue);
	const [internalClickedOption, setInternalClickedOption] = useState<DataTypeStructured | null>(
		null
	);

	function onOptionClick(clickedOption: DataTypeStructured) {
		const newValue = selectDataType(clickedOption, options, internalValue);

		setInternalValue(newValue);
		setInternalClickedOption(clickedOption);
	}

	function onApply() {
		externalOnChange(internalValue, internalClickedOption);
		onClose();
	}

	const checkboxState = getDataTypeCheckboxState(options, internalValue);

	return (
		<MenuList className={cn(selectStyles.menuList, styles.menuList)}>
			<div className={cn(selectStyles.scrollContainer, multiSelectStyles.scrollContainer)}>
				{options.length > 0 ? (
					options.map((option) => {
						const { checked = false, halfPressed = false } = checkboxState[option.id] || {};

						return (
							<MenuItem size="small" onClick={() => onOptionClick(option)} key={option.id}>
								<CheckboxBase
									checked={checked}
									halfPressed={halfPressed}
									className={option.isGroup ? styles.checkbox : styles.checkboxOffset}
								/>
								{option.name}
							</MenuItem>
						);
					})
				) : (
					<Typo variant="D/Regular/Body-S" color="secondary" className={selectStyles.noOptions}>
						No items
					</Typo>
				)}
			</div>

			{renderApplyButton({ onClick: onApply })}
		</MenuList>
	);
}

export { PolicyDataTypes };
