import Typo from 'components/typography/Typo';

type Obj = { [key: string | number | symbol]: unknown }; // helper

// Helper typeGuards
function isPrimarySecondary(value: unknown): value is { primary: string; secondary: string } {
	if (typeof value !== 'object' || value === null) return false;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const labelObj: Obj = value as any;

	return typeof labelObj.primary === 'string' && typeof labelObj.secondary === 'string';
}

type LabelProps = {
	label: string | { primary: string; secondary: string };
};

// Common overrides: text styles.
function Label(props: LabelProps) {
	const { label } = props;
	if (typeof label === 'string')
		return (
			<Typo variant="D/Medium/Body-S" component="span">
				{label}
			</Typo>
		);

	if (isPrimarySecondary(label)) {
		return label.secondary ? (
			<>
				<Typo variant="D/Medium/Body-S" component="span">
					{`${label.primary}: `}
				</Typo>
				<Typo variant="D/Regular/Body-S" component="span">
					{label.secondary}
				</Typo>
			</>
		) : (
			<Typo variant="D/Medium/Body-S" component="span">
				{label.primary}
			</Typo>
		);
	}

	return <>{label}</>;
}

export default Label;
export type { LabelProps };
