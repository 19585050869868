import { AssetDetailJson, AssetJson, AssetsJson } from './dto';

const assets: AssetJson[] = [
	{
		id: 3679106,
		is_external: false,
		k8s_types: ['ClusterIP'],
		groups: [20230302],
		cluster_id: 1,
		name: 'stats-dashboard',
		namespace: 'smat',
		type: 'internal',
		description: 'Description',
		owner: 'Owner',
		created_at: 1638267989332,
		updated_at: 1638267989332,
		is_deleted: true,
		labels: [
			{
				key: 'prometheus.io/scrape',
				value: 'true',
			},
		],
	},
	{
		id: 3691119,
		is_external: false,
		k8s_types: ['ClusterIP'],
		groups: [20230302],
		cluster_id: 1,
		name: 'alertmanager',
		namespace: 'prometheus',
		type: 'internal',
		description: 'Description',
		owner: 'Owner',
		created_at: 1638267989332,
		updated_at: 1638267989332,
		is_deleted: true,
		labels: [],
	},
	{
		id: 3689715,
		is_external: false,
		k8s_types: ['LoadBalancer'],
		groups: [20230302],
		cluster_id: 1,
		name: 'whoami',
		namespace: 'default',
		type: 'internal',
		description: 'Description',
		owner: 'Owner',
		created_at: 1638267989332,
		updated_at: 1638267989332,
		is_deleted: true,
		labels: [],
	},
	{
		id: 3678186,
		is_external: false,
		k8s_types: ['ClusterIP', 'LoadBalancer'],
		groups: [20230302],
		cluster_id: 1,
		name: 'ingress-nginx-controller',
		namespace: 'ingress-nginx',
		type: 'internal',
		description: 'Description',
		owner: 'Owner',
		created_at: 1638267989332,
		updated_at: 1638267989332,
		is_deleted: true,
		labels: [],
	},
];

const assetsJsonExample: AssetsJson = {
	assets,
	total: assets.length,
	// total_filtered: 2, // Optional - so should not be in example, otherwise errors will be triggered.
};

const externalAssetIP: AssetDetailJson = {
	id: 0,
	is_external: true,
	type: 'custom',
	name: 'string',
	namespace: 'string',
	k8s_types: ['ClusterIP'],
	groups: [20230302],
	cluster_id: 1,
	description: 'Description',
	owner: 'Owner',
	created_at: 1638267989332,
	updated_at: 1638267989332,
	is_deleted: true,
	rules: [
		[
			{
				type: 'ip',
				key: '',
				values: ['117.54.158.194'],
			},
		],
	],
	labels: [
		{
			key: 'prometheus.io/scrape',
			value: 'true',
		},
	],
};

const internalAsset: AssetDetailJson = {
	id: 0,
	is_external: false,
	type: 'custom',
	name: 'string',
	namespace: 'string',
	k8s_types: ['ClusterIP'],
	groups: [20230302],
	cluster_id: 1,
	description: 'Description',
	owner: 'Owner',
	created_at: 1638267989332,
	updated_at: 1638267989332,
	is_deleted: false,
	rules: [
		[
			{
				type: 'header',
				key: 'User-Agent',
				values: ['DodoPizza/8585 CFNetwork/[ver] Darw'],
			},
		],
	],
	labels: [
		{
			key: 'prometheus.io/scrape',
			value: 'true',
		},
	],
};

const externalAssetUserAgent: AssetDetailJson = {
	id: 0,
	is_external: true,
	type: 'custom',
	name: 'string',
	namespace: 'string',
	k8s_types: ['ClusterIP'],
	groups: [20230302],
	cluster_id: 1,
	description: 'Description',
	owner: 'Owner',
	created_at: 1638267989332,
	updated_at: 1638267989332,
	is_deleted: true,
	rules: [
		[
			{
				type: 'header',
				key: 'User-Agent',
				values: ['DodoPizza/8585 CFNetwork/[ver] Darw'],
			},
		],
	],
	labels: [
		{
			key: 'prometheus.io/scrape',
			value: 'true',
		},
	],
};

export { assetsJsonExample, externalAssetIP, externalAssetUserAgent, internalAsset };
