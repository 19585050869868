import { createTableModel } from 'models/modelUtils/tableModelFactory';
import { ApiParams } from 'services/api';
import { getAssetIPs, getAssetUserAgents, getAssetRecipients } from './api';
import {
	AssetIP,
	IPTableParams,
	AssetUserAgent,
	UserAgentTableParams,
	AssetRecipient,
	RecipientTableParams,
} from './dto';

//
// IPs
//

function fetchIPApi(apiParamsRaw: ApiParams, signal?: AbortSignal) {
	const { asset, ...apiParams } = apiParamsRaw;

	return getAssetIPs(Number(asset), apiParams, signal).then((payload) => {
		return {
			data: payload.ips,
			total: payload.total,
			total_filtered: payload.total_filtered,
		};
	});
}

function createAssetIPsModel(initialParams: IPTableParams, fetchFxOnInit: boolean = true) {
	return createTableModel<AssetIP, IPTableParams>(initialParams, fetchIPApi, fetchFxOnInit);
}

//
// User Agents
//

function fetchUserAgentApi(apiParamsRaw: ApiParams, signal?: AbortSignal) {
	const { asset, ...apiParams } = apiParamsRaw;

	return getAssetUserAgents(Number(asset), apiParams, signal).then((payload) => {
		return {
			data: payload.user_agents,
			total: payload.total,
			total_filtered: payload.total_filtered,
		};
	});
}

function createAssetUserAgentsModel(initialParams: IPTableParams, fetchFxOnInit: boolean = true) {
	return createTableModel<AssetUserAgent, IPTableParams>(
		initialParams,
		fetchUserAgentApi,
		fetchFxOnInit
	);
}

//
// Recipients
//

function fetchRecipientApi(apiParamsRaw: ApiParams, signal?: AbortSignal) {
	const { asset, ...apiParams } = apiParamsRaw;

	return getAssetRecipients(Number(asset), apiParams, signal).then((payload) => {
		return {
			data: payload.recipients,
			total: payload.total,
			total_filtered: payload.total_filtered,
		};
	});
}

function createAssetRecipientsModel(
	initialParams: RecipientTableParams,
	fetchFxOnInit: boolean = true
) {
	return createTableModel<AssetRecipient, RecipientTableParams>(
		initialParams,
		fetchRecipientApi,
		fetchFxOnInit
	);
}

//
// Instantiate models with initial params, but do not fetch data from server.
//

const initialIPParams: IPTableParams = {
	limit: 0,
	offset: 0,
	asset: 0,
	search: '',
	sort: { orderBy: 'value', order: 'asc' },
};

const initialUserAgentParams: UserAgentTableParams = {
	limit: 0,
	offset: 0,
	asset: 0,
	search: '',
	sort: { orderBy: 'value', order: 'asc' },
};

const initialRecipientParams: RecipientTableParams = {
	limit: 0,
	offset: 0,
	asset: 0,
	search: '',
	invert: false,
	sort: { orderBy: 'user_agent', order: 'asc' },
};

const assetIPsModel = createAssetIPsModel(initialIPParams, false);
const assetUserAgentsModel = createAssetUserAgentsModel(initialUserAgentParams, false);
const assetRecipientsModel = createAssetRecipientsModel(initialRecipientParams, false);

export { assetIPsModel, assetUserAgentsModel, assetRecipientsModel };
