import cn from 'classnames';
import { useStore } from 'effector-react';
import debounce from 'lodash.debounce';
import {
	createContext,
	MutableRefObject,
	ReactNode,
	useCallback,
	useEffect,
	useLayoutEffect,
	MouseEvent,
	useMemo,
	useRef,
	useState,
} from 'react';
import { MapInteractionCSS } from 'react-map-interaction';
import Icon from 'components/Icon';
import Tooltip from 'components/Tooltip';
import Typo from 'components/typography/Typo';
import { CUSTOM_SCALE, DEBOUNCE_VALUES, TILE_SIZE } from '../index';
import { mapControlsStore } from '../model/store';
import { fitToScreenValues, translationBoundsValues } from './helpers';
import styles from './index.module.pcss';

let preventClickOnTranslate = false;
const startingPoint = {
	x: 0,
	y: 0,
};

const MIN_SCALE = 0.0333;
const MAX_SCALE = 1;
const DELTA_FOR_PREVENTING = 5;
const mouseDown = (e: MouseEvent<HTMLElement>) => {
	startingPoint.x = e.clientX;
	startingPoint.y = e.clientY;
};

const mouseUp = (e: MouseEvent<HTMLElement>) => {
	const moveDelta = Math.abs(startingPoint.x - e.clientX) + Math.abs(startingPoint.y - e.clientY);

	if (moveDelta > DELTA_FOR_PREVENTING) {
		preventClickOnTranslate = true;
		setTimeout(() => (preventClickOnTranslate = false), 100);
	}
};

const ZoomActions: { scrollToElement?: (top: number, left: number) => void } = {};

type ZoomContextType = {
	scale: number;
	scaleStep: 300 | 250 | 200 | 150 | 100 | 50;
	translate: { x: number; y: number };
};
const initialZoomContext: ZoomContextType = {
	scale: 100,
	scaleStep: 100,
	translate: { x: 0, y: 0 },
};
const ZoomContext = createContext<ZoomContextType>(initialZoomContext);

const DEFAULT_TRANSLATION_BOUNDS = {
	xMin: -Infinity,
	yMin: -Infinity,
	xMax: Infinity,
	yMax: Infinity,
};

const CURTAIN_WIDTH = 400; // same as .curtainOpen in css file

let scaleStored = {
	scale: 1,
	translation: {
		x: 0,
		y: 0,
	},
};
let scaleChanged = false;

type Props = {
	children: ReactNode;
	curtainOpen: boolean;
	onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
	preventClickWhenDrag?: boolean;
	className?: string;
	contentRect: { x: number; y: number; width: number; height: number };
	fitToScreenRef?: MutableRefObject<Function>;
};

function ZoomWrapper(props: Props) {
	const { children, curtainOpen, onClick, preventClickWhenDrag, className, contentRect } = props;

	const containerRef = useRef<HTMLDivElement>(null);

	const [scaleParams, setScaleParams] = useState(scaleStored);
	const [translationBounds, setTranslationBounds] = useState(DEFAULT_TRANSLATION_BOUNDS);

	const [currentZoomContext, setCurrentZoomContext] = useState<ZoomContextType>(initialZoomContext);

	const { showAssetSensitivity } = useStore(mapControlsStore);

	useEffect(() => {
		if (!scaleChanged) fitToScreen();
	}, []);

	const [noTransform, setNoTransform] = useState(false);

	useEffect(() => {
		if (scaleParams !== scaleStored) {
			scaleStored = scaleParams;
			scaleChanged = true;
		}
	}, [scaleParams]);

	const getContainerSize = useCallback(() => {
		if (!containerRef.current) return { width: 1000, height: 1000 };

		const curtainOffset = curtainOpen ? CURTAIN_WIDTH : 0;

		return {
			width: containerRef.current.offsetWidth - curtainOffset,
			height: containerRef.current.offsetHeight,
		};
	}, [containerRef, curtainOpen]);

	useEffect(() => {
		ZoomActions.scrollToElement = (top: number, left: number) => {
			const { width, height } = getContainerSize();

			const nextScale = 0.334;
			const nextTranslation = {
				x: width / 2 - left * nextScale - TILE_SIZE,
				y: height / 2 - top * nextScale - TILE_SIZE,
			};

			setScaleParams({
				scale: nextScale,
				translation: nextTranslation,
			});
		};

		return () => {
			ZoomActions.scrollToElement = undefined;
		};
	}, [getContainerSize]);

	const fitToScreen = useCallback(() => {
		setScaleParams(
			fitToScreenValues({
				scale: scaleParams.scale,
				parentRect: getContainerSize(),
				contentRect,
			})
		);
	}, [scaleParams.scale, contentRect, getContainerSize]);

	if (props.fitToScreenRef) {
		props.fitToScreenRef.current = fitToScreen;
	}

	useLayoutEffect(() => {
		const container = getContainerSize();

		setTranslationBounds(
			translationBoundsValues({
				parentRect: container,
				contentRect,
				scale: scaleParams.scale,
			})
		);
	}, [scaleParams.scale, contentRect, getContainerSize]);

	const scaleStep = useMemo(() => {
		const { scale } = scaleParams;
		const customScale = scale * CUSTOM_SCALE;

		switch (true) {
			case customScale > 2.5:
				return 300;
			case customScale <= 2.5 && customScale > 2:
				return 250;
			case customScale <= 2.0 && customScale > 1.5:
				return 200;
			case customScale <= 1.5 && customScale > 1:
				return 150;
			case customScale <= 1 && customScale > 0.7:
				return 100;
			default:
				return 50;
		}
	}, [scaleParams.scale]);

	const setCurrentZoomContextDebounced = useMemo(
		() => debounce(setCurrentZoomContext, DEBOUNCE_VALUES.fast),
		[setCurrentZoomContext]
	);

	useEffect(() => {
		const {
			scale,
			translation: { x, y },
		} = scaleParams;

		setCurrentZoomContextDebounced({
			scale,
			scaleStep,
			translate: { x, y },
		});
	}, [scaleParams.scale, scaleParams.translation.x, scaleParams.translation.y]);

	const debounced = useMemo(
		() =>
			debounce(() => {
				setNoTransform(true);

				setTimeout(() => setNoTransform(false), 100);
			}, 500),
		[setNoTransform]
	);

	useEffect(() => {
		debounced();
	}, [scaleStep, showAssetSensitivity, debounced]);

	useLayoutEffect(() => {
		// MapInteractionCss set disable=true to zoom control buttons under max scale value
		// And because of this doesn't work click on FitToScreen.
		if (scaleParams.scale === MAX_SCALE) {
			for (const zoomButton of document.querySelectorAll(`.${styles.btnClass}`)) {
				zoomButton.removeAttribute('disabled');
			}
		}
	}, [scaleParams.scale]);

	const onClickPrevent = (e: React.MouseEvent<HTMLDivElement>) => {
		if (preventClickOnTranslate) {
			e.stopPropagation();
			return;
		}
	};

	const onClickHandler = (e: React.MouseEvent<HTMLDivElement>) => {
		const preventClickOnZoomButtons = (e.target as Element).closest(`.${styles.btnClass}`);

		if (preventClickOnZoomButtons) {
			return;
		}

		onClick?.(e);
	};

	return (
		<div
			onClick={onClickHandler}
			onClickCapture={preventClickWhenDrag ? onClickPrevent : undefined}
			className={cn({ [styles.noTransform]: noTransform }, styles.container, className)}
			ref={containerRef}
			onMouseDown={mouseDown}
			onMouseUp={mouseUp}
		>
			<MapInteractionCSS
				value={scaleParams}
				onChange={setScaleParams}
				minScale={MIN_SCALE}
				maxScale={MAX_SCALE}
				showControls={true}
				translationBounds={translationBounds}
				controlsClass={styles.mapInteractionControls}
				btnClass={styles.btnClass}
				minusBtnContents={
					<>
						<div
							className={cn(
								styles.zoomButton,
								scaleParams.scale <= MIN_SCALE && styles.zoomButtonDisabled
							)}
							data-test="data-map-zoom-minus-button"
						>
							<Icon name="minus" size={24} />
						</div>
						<div
							className={styles.zoomValue}
							onClickCapture={(e) => {
								e.stopPropagation();
							}}
							data-test="data-map-zoom-value"
						>
							<Typo variant="D/Regular/Body-S">
								{(scaleParams.scale * 100 * CUSTOM_SCALE).toFixed(0)}%
							</Typo>
						</div>
					</>
				}
				plusBtnContents={
					<>
						<div
							className={cn(
								styles.zoomButton,
								styles.zoomButtonPlus,
								scaleParams.scale >= MAX_SCALE && styles.zoomButtonDisabled
							)}
							data-test="data-map-zoom-plus-button"
						>
							<Icon name="Add/Regular" size={24} />
						</div>

						<div className={styles.buttonSeparator} />

						<div
							className={cn(styles.zoomButton, styles.zoomButtonFitToScreen)}
							onClickCapture={(e) => {
								e.stopPropagation();
								fitToScreen();
							}}
							data-test="data-map-zoom-fit-to-screen"
						>
							<Tooltip title="Fit to screen">
								<Icon name="FitWidth/Regular" size={24} />
							</Tooltip>
						</div>
					</>
				}
			>
				<ZoomContext.Provider value={currentZoomContext}>{children}</ZoomContext.Provider>
			</MapInteractionCSS>
		</div>
	);
}

export type { ZoomContextType };
export { ZoomContext, ZoomActions };
export default ZoomWrapper;
