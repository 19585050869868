import { useStore } from 'effector-react';
import { useEffect } from 'react';
import FilterLine from 'components/FilterLine';
import FilterResult from 'components/FilterResult';
import LoadMoreButton from 'components/LoadMoreButton';
import NoContent from 'components/NoContent';
import {
	clearPiiFilter,
	piiFilterToArray,
	piiFilterWithGroupsStore,
} from 'components/PiiGlobalFilterV2/model';
import Preloader from 'components/Preloader';
import ResetFilters from 'components/ResetFilters';
import EnhancedTableHead, { TOrder } from 'components/table/EnhancedTableHead';
import GridBody from 'components/table/GridBody';
import GridTable from 'components/table/GridTable';
import Typo from 'components/typography/Typo';
import { DatabaseItem, DatabaseTableItem } from 'models/databases/dto';
import { databaseTablesModel } from 'models/databases/model';
import { kafkaTopicSamplesModel } from 'models/kafkaTopics/model';
import { PageParamsConfig, usePageParams } from 'services/pageParams';
import tableConfig from './config';
import styles from './index.module.css';
import { TableRow } from './TableRow';

const pageConfig = {
	'data-types': {
		type: 'numberArray',
		persistence: 'session', // TODO implement 'global' in actuality
	},
	nonempty: {
		type: 'boolean',
		persistence: 'session', // TODO implement 'global' in actuality
	},
	sort: {
		type: 'sort',
		persistence: 'session',
	},
} satisfies PageParamsConfig;

type Props = {
	databaseId: DatabaseItem['id'];
};

export function TablesTable({ databaseId }: Props) {
	const state = useStore(databaseTablesModel.store);
	const piiFilterWithGroups = useStore(piiFilterWithGroupsStore);
	const [pageParams, setPageParams] = usePageParams(pageConfig, 'databaseTables');

	useEffect(() => {
		setPageParams({
			...pageParams,
			'data-types': piiFilterWithGroups.dataTypes,
			nonempty: piiFilterWithGroups.nonEmpty,
		});
	}, [piiFilterWithGroups]);

	useEffect(() => {
		const { sort } = pageParams;

		const dataTypes = piiFilterToArray(piiFilterWithGroups);
		const paramsForFx = {
			database_ids: [databaseId],
			sort: { orderBy: sort.value as keyof DatabaseTableItem, order: sort.operator },
			'data-types': dataTypes,
		};

		databaseTablesModel.fetchFx(paramsForFx);
	}, [pageParams]);

	// Clean up model on unmount
	useEffect(() => {
		return () => {
			databaseTablesModel.resetFx();
		};
	}, []);

	function onSortUpdate(property: keyof DatabaseTableItem) {
		const { sort } = pageParams;

		const operator: TOrder = sort.value === property && sort.operator === 'asc' ? 'desc' : 'asc';
		const newParams = { sort: { operator, value: property } };

		setPageParams({ ...pageParams, ...newParams });
	}

	function onResetFilters() {
		const newParams = {
			'data-types': [],
		};

		setPageParams({ ...pageParams, ...newParams });
		clearPiiFilter();
	}
	const { status, data, total, total_filtered, hasMoreData } = state;

	const hasFilter = pageParams['data-types'].length > 0;

	return (
		<>
			<FilterLine
				config={['dataTypes']}
				values={{
					dataTypes: pageParams['data-types'],
				}}
				onChange={() => {}}
			/>

			<FilterResult
				entityLabel="table"
				loading={status === 'loading'}
				total={total}
				totalFiltered={total_filtered}
			/>

			<GridTable dataTest="kafka-samples-table">
				<EnhancedTableHead
					config={tableConfig}
					order={pageParams.sort.operator}
					orderBy={pageParams.sort.value}
					onRequestSort={onSortUpdate}
					rowClassname={styles.rowContainer}
				/>

				<Preloader isLoading={status === 'initial' || status === 'loading'}>
					<GridBody data-test="kafka-samples-list">
						<>
							{data.length ? (
								data.map((table) => <TableRow table={table} key={table.id} />)
							) : hasFilter ? (
								<ResetFilters onReset={onResetFilters} />
							) : (
								<NoContent type="databaseTables" className={styles.rowContainer}>
									<div className={styles.emptyContainer}>
										<Typo variant="D/Regular/Body-S" color="secondary">
											No available analytics.
										</Typo>
									</div>
								</NoContent>
							)}

							<LoadMoreButton
								show={hasMoreData}
								loading={status === 'loadingMore'}
								request={kafkaTopicSamplesModel.fetchMoreFx}
							/>
						</>
					</GridBody>
				</Preloader>
			</GridTable>
		</>
	);
}
