import { createStore } from 'effector';
import { AssetGroupList } from './dto';
import { getAssetGroupsFx } from './effects';

const assetGroupStore = createStore<AssetGroupList>({
	groups: [],
	total: 0,
});

assetGroupStore.on(
	getAssetGroupsFx.doneData,
	(state: AssetGroupList, data: AssetGroupList) => data
);

const assetGroupsList = assetGroupStore.map(({ groups }) =>
	groups.sort((a, b) => a.name.localeCompare(b.name))
);

const assetGroupsMap = assetGroupStore.map(({ groups }) => {
	return Object.fromEntries(groups.map((group) => [group.id, group]));
});

export { assetGroupsList, assetGroupsMap };
