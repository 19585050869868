import { useStore } from 'effector-react';
import { useEffect } from 'react';
import FilterResult from 'components/FilterResult';
import LoadMoreButton from 'components/LoadMoreButton';
import NewGatewayBlock from 'components/NewGatewayBlock';
import NoContent from 'components/NoContent';
import Preloader from 'components/Preloader';
import ResetFilters from 'components/ResetFilters';
import EnhancedTableHead, { TOrder } from 'components/table/EnhancedTableHead';
import GridBody from 'components/table/GridBody';
import GridTable from 'components/table/GridTable';
import { S3BucketItem, S3BucketDataFlowsTableItem } from 'models/s3Buckets/dto';
import { bucketDataFlowModel } from 'models/s3Buckets/model';
import { PageParamsConfig, usePageParams } from 'services/pageParams';
import { S3BucketHeader } from '../S3BucketHeader';
import tableConfig from './config';
import styles from './index.module.css';
import { TableRow } from './TableRow';

const pageConfig = {
	sort: {
		type: 'sort',
		persistence: 'session',
	},
	name: {
		type: 'string',
		persistence: 'session',
	},
} satisfies PageParamsConfig;

type Props = { bucket: S3BucketItem };

function S3BucketConnections(props: Props) {
	const { bucket } = props;

	const state = useStore(bucketDataFlowModel.store);

	const [pageParams, setPageParams] = usePageParams(pageConfig, 'bucketConnections');

	useEffect(() => {
		const { sort, name } = pageParams;

		const paramsForFx = {
			sort: { orderBy: sort.value as keyof S3BucketDataFlowsTableItem, order: sort.operator },
			bucketId: bucket.id,
			name,
		};

		bucketDataFlowModel.fetchFx(paramsForFx);
	}, [pageParams]);

	// Clean up model on unmount
	useEffect(() => {
		return () => {
			bucketDataFlowModel.resetFx();
		};
	}, []);

	// It's a workaround to set exact sort value instead of default('sensitivity')
	useEffect(() => {
		setPageParams({ ...pageParams, sort: { value: 'last_seen', operator: 'desc' } });
	}, []);

	// That's Table filter, not filters in Filter line.
	function onFilterUpdate(property: keyof S3BucketDataFlowsTableItem, filterText: string) {
		setPageParams({ ...pageParams, name: filterText });
	}

	function onSortUpdate(property: keyof S3BucketDataFlowsTableItem) {
		const { sort } = state.params;

		const operator: TOrder = sort.orderBy === property && sort.order === 'asc' ? 'desc' : 'asc';
		const newParams = { sort: { operator, value: property } };

		setPageParams({ ...pageParams, ...newParams });
	}

	function onResetFilters() {
		const newParams = {
			name: '',
		};

		setPageParams({ ...pageParams, ...newParams });
	}

	const { status, data, hasMoreData, params, total_filtered, total } = state;
	const hasFilter = params.name !== '';

	return (
		<>
			<S3BucketHeader tab="connections" bucket={bucket} />

			<FilterResult
				entityLabel="connection"
				loading={status === 'loading'}
				total={total}
				totalFiltered={total_filtered}
			/>

			<GridTable data-test="bucket-connection-table">
				<EnhancedTableHead
					config={tableConfig}
					order={params.sort.order}
					orderBy={params.sort.orderBy}
					filterBy={{ interacts_with: params.name }}
					onRequestSort={onSortUpdate}
					onRequestFilter={onFilterUpdate}
					rowClassname={styles.rowContainer}
				/>

				<Preloader isLoading={status === 'initial' || status === 'loading'}>
					<GridBody data-test="bucket-connection-table-list">
						<>
							{data.length ? (
								data.map((dataFlow) => (
									<TableRow dataFlow={dataFlow} key={dataFlow.interacts_with} />
								))
							) : hasFilter ? (
								<ResetFilters onReset={onResetFilters} />
							) : (
								<NoContent type="s3BucketConnectionsTable" className={styles.rowContainer}>
									<NewGatewayBlock />
								</NoContent>
							)}

							<LoadMoreButton
								show={hasMoreData}
								loading={status === 'loadingMore'}
								request={bucketDataFlowModel.fetchMoreFx}
							/>
						</>
					</GridBody>
				</Preloader>
			</GridTable>
		</>
	);
}

export { S3BucketConnections };
