import { ConfirmModal, getConfirmation } from 'components/ConfirmModal';
import styles from './index.module.css';

interface IDeleteModal {
	groupName: string;
}

export const DeleteModal = async ({ groupName }: IDeleteModal) => {
	const confirmDeletionModal = (
		<ConfirmModal
			title={`Delete ${groupName} group`}
			confirmProps={{
				children: 'Delete',
			}}
		>
			<div className={styles.description}>
				Do you want to permanently delete this group?
				<br />
				This action cannot be undone.
			</div>
		</ConfirmModal>
	);

	return await getConfirmation(confirmDeletionModal);
};
