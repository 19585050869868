import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import Preloader from 'components/Preloader';
import { getS3Sample } from 'models/s3Sample/api';
import { S3Sample } from 'models/s3Sample/dto';
import { CSVViewer } from 'views/common/SampleViewer/CSVViewer';
import { JSONViewer } from 'views/common/SampleViewer/JSONViewer';
import { LogViewer } from 'views/common/SampleViewer/LogViewer';
import { XLSViewer } from 'views/common/SampleViewer/XLSViewer';
import { YAMLViewer } from 'views/common/SampleViewer/YAMLViewer';
import styles from './index.module.css';
import { S3SampleHeader } from './S3SampleHeader';

function SampleViewers() {
	const [sample, setSample] = useState<S3Sample | null>(null);
	const [isLoading, setLoading] = useState(false);

	const params: {
		sampleId: string;
	} = useParams();

	useEffect(() => {
		setLoading(true);

		getS3Sample(Number(params.sampleId)).then((response) => {
			setSample(response);
			setLoading(false);
		});
	}, []);

	const Viewer = useMemo(() => {
		const samples = sample?.samples;
		if (!samples) return null;

		switch (sample?.type) {
			case 'csv':
			case 'parquet':
				return <CSVViewer {...samples[0]} />;
			case 'log':
				return <LogViewer {...samples[0]} />;
			case 'json':
				return <JSONViewer {...samples[0]} />;
			case 'yaml':
				return <YAMLViewer {...samples[0]} />;
			case 'spreadsheet':
				return <XLSViewer {...sample} />;
			default:
				return null;
		}
	}, [sample]);

	return (
		<Preloader isLoading={isLoading}>
			<S3SampleHeader sample={sample} />

			<div className={styles.wrapper}>{Viewer}</div>
		</Preloader>
	);
}

export { SampleViewers };
