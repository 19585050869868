import { useStore } from 'effector-react';
import { useEffect, useMemo, useState } from 'react';
import Preloader from 'components/Preloader';
import { enqueueSnackbar } from 'components/Snackbar';
import GridBody from 'components/table/GridBody';
import GridCell from 'components/table/GridCell';
import GridHead from 'components/table/GridHead';
import GridRow from 'components/table/GridRow';
import GridTable from 'components/table/GridTable';
import { getClientListFx } from 'models/clients/effects';
import { clientList } from 'models/clients/store';
import { APIError } from 'services/api/httpRequest';
import ClientRow from '../ClientItem';
import styles from './index.module.pcss';

export default function ClientList() {
	const clients = useStore(clientList);

	const [isLoading, setLoading] = useState(true);
	const [activeId, setActiveId] = useState<null | number>(null);

	// If there are more than active admin
	const isCanBlocked = useMemo(
		() => clients.filter(({ status, role }) => status === 'active' && role === 'admin').length > 1,
		[clients]
	);

	useEffect(() => {
		// Adding param { abortSignal: true } is for switching between tabs
		getClientListFx({ abortSignal: true })
			.catch(async (error) => {
				if (error instanceof APIError && error.response.status === 400) {
					const errorBody = await error.response.json();
					enqueueSnackbar(errorBody.message);
				} else {
					throw error;
				}
			})
			.finally(() => {
				setLoading(false);
			});
	}, []); // Fetch clients from server, once.

	return (
		<div className={styles.tableWrapper}>
			<GridTable className={styles.table} dataTest="client-table">
				<GridHead>
					<GridRow head className={styles.rowContainer}>
						<GridCell head>Name</GridCell>
						<GridCell head>Role</GridCell>
						<GridCell head>Status</GridCell>
						<GridCell head>Last activity</GridCell>
						<GridCell head>{null}</GridCell>
					</GridRow>
				</GridHead>

				<Preloader isLoading={isLoading}>
					<GridBody>
						{clients.map((client) => (
							<ClientRow
								key={client.client_id}
								client={client}
								activeId={activeId}
								setActiveId={setActiveId}
								isCanBlocked={isCanBlocked}
							/>
						))}
					</GridBody>
				</Preloader>
			</GridTable>
		</div>
	);
}
