import cn from 'classnames';
import { MouseEvent, MouseEventHandler } from 'react';
import Icon, { IconProps } from 'components/Icon';
import Loader from '../Loader';
import styles from './index.module.css';

interface ButtonIconProps {
	active?: boolean;
	className?: string;
	color?: keyof typeof IconColors;
	dataTest?: string;
	disabled?: boolean;
	icon: IconProps['name'];
	loading?: boolean;
	onClick?: MouseEventHandler<HTMLButtonElement>;
	size?: 'M' | 'S' | 'XS';
}

enum IconSize {
	M = 24,
	S = 20,
	XS = 16,
}

enum IconColors {
	'icon/primary' = 'primary',
	'icon/primary-inverted' = 'primaryInverted',
	'icon/secondary' = 'secondary',
	'icon/accent-primary' = 'accentPrimary',
	'icon/danger-primary' = 'dangerPrimary',
	'icon/warning-primary' = 'warningPrimary',
	'icon/success-primary' = 'successPrimary',
	'icon/accent-secondary' = 'accentSecondary',
	'icon/danger-secondary' = 'dangerSecondary',
	'icon/warning-secondary' = 'warningSecondary',
	'icon/success-secondary' = 'successSecondary',
}

function ButtonIcon({
	active,
	className,
	color = 'icon/secondary',
	dataTest,
	disabled,
	icon,
	loading,
	size = 'S',
	onClick,
}: Readonly<ButtonIconProps>) {
	function handleClick(event: MouseEvent<HTMLButtonElement>) {
		// After every click button becomes unfocused.
		event.currentTarget.blur();

		if (onClick) {
			onClick(event);
		}
	}

	return (
		<button
			className={cn(
				styles.container,
				styles[size],
				{ [styles.active]: active, [styles.loading]: loading, [styles.disabled]: disabled },
				className
			)}
			type="button"
			onClick={handleClick}
			disabled={disabled}
			data-test={dataTest}
		>
			{loading ? (
				<Loader className={cn(styles.loader, styles[IconColors[color]])} size={IconSize[size]} />
			) : (
				<Icon
					name={icon}
					size={IconSize[size]}
					className={cn(styles.icon, styles[IconColors[color]])}
				/>
			)}
		</button>
	);
}

export type { ButtonIconProps };
export { IconColors };
export default ButtonIcon;
