import { createStore } from 'effector';
import { DataTypeItem } from './dto';
import { getDataTypesFx } from './effects';
import { getDataTypesStructured } from './helpers';

const dataTypesStore = createStore<DataTypeItem[]>([]);

dataTypesStore.on(getDataTypesFx.doneData, (state: DataTypeItem[], data: DataTypeItem[]) => data);

const dataTypesById = dataTypesStore.map((dataTypes) => {
	return dataTypes.reduce(
		(acc, dataType) => {
			acc[dataType.id] = dataType;

			return acc;
		},
		{} as { [key: DataTypeItem['id']]: DataTypeItem }
	);
});

const dataTypesLoaded = dataTypesStore.map((dataTypes, oldDataTypesStore) => {
	return oldDataTypesStore !== undefined;
}); // Magical

const dataTypesStructuredStore = dataTypesStore.map(getDataTypesStructured);

export { dataTypesStore, dataTypesById, dataTypesLoaded, dataTypesStructuredStore };
