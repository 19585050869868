import { generatePath } from 'react-router';
import Button, { IButtonProps } from 'components/form/Button';
import Icon from 'components/Icon';
import { RouterLink } from 'components/typography/Link';
import { PATHS } from 'services/router';

export function CreateButton({ size = 'medium' }: { size?: IButtonProps['size'] }) {
	return (
		<RouterLink to={generatePath(PATHS.ACCOUNT_AWS_CREATE, { id: 'new' })}>
			<Button
				size={size}
				data-test="aws-account-create-button"
				startIcon={<Icon name="Add/Regular" size={size === 'medium' ? 24 : 20} />}
			>
				New AWS connection
			</Button>
		</RouterLink>
	);
}
