import { IHeadCell } from 'components/table/EnhancedTableHead';
import { KafkaConsumerGroupsMemberItem } from 'models/kafkaTopics/dto';

const tableConfig: IHeadCell<KafkaConsumerGroupsMemberItem>[] = [
	{
		id: 'kafka_client_id',
		numeric: false,
		label: 'Assigned member',
		sortable: true,
	},
	{
		id: 'consumer_group_name',
		numeric: false,
		label: 'Consumer group',
		sortable: true,
	},
	{
		id: 'host',
		numeric: false,
		label: 'Host',
		sortable: true,
	},
];

export default tableConfig;
