import { get } from 'api/Api';
import { getAssetGroups } from 'models/assetsGroups/api';
import { AssetGroup, AssetGroupListItem } from 'models/assetsGroups/dto';
import { ApiParams } from 'services/api';
import checkResponse from 'services/api/checkResponse';
import {
	AssetGroupDataFlowJson,
	AssetGroupDataFlowTableItem,
	AssetGroupDataFlowsJson,
} from './dto';
import { assetGroupDataFlowListExample } from './examples';

async function getAssetGroupDataFlows(id: AssetGroup['id'], apiParams: ApiParams) {
	const data = await get<AssetGroupDataFlowsJson>(`v2/groups/${id}/dataflows`, apiParams).then(
		checkResponse(assetGroupDataFlowListExample)
	);

	const groupList = await getAssetGroups();

	return {
		...data,
		dataflows: enrichWithAssetGroupData(data.dataflows, groupList.groups, id),
	};
}

function enrichWithAssetGroupData(
	dataflows: AssetGroupDataFlowJson[],
	groups: AssetGroupListItem[],
	groupId: AssetGroup['id']
): AssetGroupDataFlowTableItem[] {
	return dataflows.map((dataflow) => {
		const group = groups.find((g) => g.id === dataflow.interacts_with);

		if (!group) throw new Error(`Asset group '${dataflow.interacts_with}' not found`);

		return {
			...dataflow,
			groupId,
			name: group.name,
		};
	});
}

export { getAssetGroupDataFlows };
