import { get } from 'api/Api';
import { AssetJson } from 'models/assets/dto';
import checkResponse from 'services/api/checkResponse';
import { AssetCountersJson } from './dto';
import { assetCountersJsonExample } from './examples';

async function getAssetCounters(id: AssetJson['id'], onlyWithPii?: boolean, signal?: AbortSignal) {
	const params = onlyWithPii ? { only_with_pii: true } : undefined;
	const config = signal ? { signal } : {};

	return get<AssetCountersJson>(`v2/assets-extended/${id}/counters`, params, config).then(
		checkResponse(assetCountersJsonExample)
	);
}

export { getAssetCounters };
