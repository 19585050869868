const PATHS = {
	SIGN_IN: '/sign-in',
	SIGN_UP: '/sign-up',
	CONNECT_AWS: '/connect-aws',
	ACCEPT_INVITE: '/accept-invite',
	CHANGE_PASSWORD: '/change-password',
	RESET_PASSWORD: '/reset-password',
	SSO_FAILED: '/sso-failed',
	// WARNING: AWS paths below are used at the back-end side too (saassy/auth/service/awsmarketplace/awsmarketplace.go)
	AWS_NON_EXISTENT_SUBSCRIPTION: '/aws-non-existent-subscription',
	// This path is used when client try to do Sign UP by AWS marketplace and there some problems with AWS services
	AWS_SOMETHING_WRONG: '/aws-something-wrong',

	OVERVIEW: '/overview',
	EVENTS: '/activity-log',
	EVENT_SAMPLE: '/activity-log/:eventId/sample',

	// ServiceCatalog
	SERVICE_CATALOG: '/service-catalog',
	SERVICES: '/service-catalog/services',
	EXTERNAL_CONNECTIONS: '/service-catalog/external-connections',
	CUSTOM_EXTERNAL_CONNECTIONS_BUILDER: '/service-catalog/external-connections/new',
	SERVICE_ITEM: '/service-catalog/services/:id/:tab?/:details_with?/:flow_direction?',

	// API Endpoints
	API_ENDPOINTS: '/api-endpoints',
	SAMPLE_V2: '/api-endpoints/sample',

	// Data stores
	DATA_STORAGES: '/data-storages',
	S3_BUCKETS: '/data-storages/s3-buckets',
	S3_BUCKETS_ITEM: '/data-storages/s3-buckets/:id/:tab?',
	S3_CLUSTER_ITEM: '/data-storages/s3-buckets/:id/clusters/:clusterId',
	S3_FILE_SAMPLE: '/data-storages/s3-buckets/sample/:sampleId',

	KAFKA_INSTANCES: '/data-storages/kafka/instances',
	KAFKA_INSTANCES_ITEM: '/data-storages/kafka/instances/:id',
	KAFKA_TOPICS: '/data-storages/kafka/topics',
	KAFKA_TOPICS_ITEM: '/data-storages/kafka/topics/:id/:tab?',
	KAFKA_MESSAGE_SCHEMA_ITEM: '/data-storages/kafka/topics/:topicId/schemas/:schemaId',
	KAFKA_TOPIC_FILE_SAMPLE: '/data-storages/kafka/sample/:sampleId',

	DATABASES: '/data-storages/databases',
	DATABASES_ITEM: '/data-storages/databases/:id/:tab?',
	DATABASES_TABLE_SAMPLE: '/data-storages/databases/sample/:sampleId',

	DATA_TYPES_LIST: '/data-types',
	DATA_TYPES_ITEM: '/data-types/:id',
	ASSET_GROUP_LIST: '/asset-groups',
	ASSET_GROUP_ITEM: '/asset-groups/:id/:tab?/:details_with?/:flow_direction?',
	INTEGRATIONS: '/integrations',
	INTEGRATIONS_SLACK: '/integrations/slack',
	INTEGRATIONS_JIRA: '/integrations/jira',
	INTEGRATIONS_WEBHOOK: '/integrations/webhook',
	INTEGRATIONS_EXTERNAL_API: '/integrations/external-api',
	INTEGRATIONS_EXTERNAL_API_CREATE_TOKEN: '/integrations/external-api/new',

	// DataMap
	DATA_MAP: '/data-map',

	// Policy
	POLICY_LIST: '/policies',
	POLICY_ITEM: '/policies/:id',

	// Team
	TEAM: '/team/:tab?',

	// Sensors
	SENSORS: '/infrastructure-access',
	SENSORS_DIM: '/infrastructure-access/data-in-motion',
	SENSORS_DIM_CREATE: '/infrastructure-access/data-in-motion/new',
	SENSORS_DAR: '/infrastructure-access/data-at-rest',
	SENSORS_DAR_CREATE: '/infrastructure-access/data-at-rest/new',
	ACCOUNT_AWS: '/infrastructure-access/aws',
	ACCOUNT_AWS_CREATE: '/infrastructure-access/aws/:id',

	// Admin
	ADMIN: '/admin',

	// OLD - we keep them because events keep old fashioned links
	// Data catalog
	ASSETS: '/data-catalog',
	ASSET_ITEM: '/data-catalog/assets/:id/:tab?/:details_with?/:flow_direction?',
	SAMPLE: '/data-catalog/sample',
};

export { PATHS };
