import { useStore } from 'effector-react';
import { useEffect } from 'react';
import FilterLine from 'components/FilterLine';
import FilterResult from 'components/FilterResult';
import LoadMoreButton from 'components/LoadMoreButton';
import NewGatewayButton from 'components/NewGatewayBlock/NewGatewayButton';
import NoContent from 'components/NoContent';
import {
	clearPiiFilter,
	piiFilterToArray,
	piiFilterWithGroupsStore,
} from 'components/PiiGlobalFilterV2/model';
import Preloader from 'components/Preloader';
import ResetFilters from 'components/ResetFilters';
import EnhancedTableHead, { TOrder } from 'components/table/EnhancedTableHead';
import GridBody from 'components/table/GridBody';
import GridTable from 'components/table/GridTable';
import Typo from 'components/typography/Typo';
import { dataStoragesSteps } from 'layouts/AuthorizedWithLeftMenu/Breadcrumbs';
import Header from 'layouts/AuthorizedWithLeftMenu/Header';
import { KafkaTopicTableItem } from 'models/kafkaTopics/dto';
import { kafkaTopicsModel } from 'models/kafkaTopics/model';
import { PageParamsConfig, usePageParams } from 'services/pageParams';
import { UniversalSearch } from 'views/common/UniversalSearch';
import DataStorage from 'views/DataStorages';
import tableConfig from './config';
import styles from './index.module.css';
import { TopicTableRow } from './TopicTableRow';

const pageConfig = {
	'instance-ids': {
		type: 'numberArray',
		persistence: 'session',
	},
	'data-types': {
		type: 'numberArray',
		persistence: 'session', // TODO implement 'global' in actuality
	},
	nonempty: {
		type: 'boolean',
		persistence: 'session', // TODO implement 'global' in actuality
	},
	search: {
		type: 'string',
		persistence: 'session',
	},
	sort: {
		type: 'sort',
		persistence: 'session',
	},
} satisfies PageParamsConfig;

function TopicsTable() {
	const state = useStore(kafkaTopicsModel.store);
	const piiFilterWithGroups = useStore(piiFilterWithGroupsStore);

	const [pageParams, setPageParams] = usePageParams(pageConfig, 'kafkaTopics');

	useEffect(() => {
		setPageParams({
			...pageParams,
			'data-types': piiFilterWithGroups.dataTypes,
			nonempty: piiFilterWithGroups.nonEmpty,
		});
	}, [piiFilterWithGroups]);

	useEffect(() => {
		const { sort, 'instance-ids': instance_ids, search } = pageParams;

		const dataTypes = piiFilterToArray(piiFilterWithGroups);
		const paramsForFx = {
			sort: { orderBy: sort.value as keyof KafkaTopicTableItem, order: sort.operator },
			instance_ids,
			search,
			'data-types': dataTypes,
		};

		kafkaTopicsModel.fetchFx(paramsForFx);
	}, [pageParams]);

	// Clean up model on unmount
	useEffect(() => {
		return () => {
			kafkaTopicsModel.resetFx();
		};
	}, []);

	// It's a workaround to set exact sort value instead of default('sensitivity')
	useEffect(() => {
		setPageParams({ ...pageParams, sort: { value: 'name', operator: 'desc' } });
	}, []);

	function onSortUpdate(property: keyof KafkaTopicTableItem) {
		const { sort } = pageParams;

		const operator: TOrder = sort.value === property && sort.operator === 'asc' ? 'desc' : 'asc';
		const newParams = { sort: { operator, value: property } };

		setPageParams({ ...pageParams, ...newParams });
	}

	function onFilterLineUpdate(filterLineParams: Partial<typeof pageParams>) {
		setPageParams({ ...pageParams, ...filterLineParams });
	}

	function onUniversalSearchUpdate(search: string) {
		setPageParams({ ...pageParams, search });
	}

	function onResetFilters() {
		const newParams = {
			search: '',
			'data-types': [],
			'instance-ids': [],
		};

		setPageParams({ ...pageParams, ...newParams });
		clearPiiFilter();
	}

	const { status, data, total, total_filtered, hasMoreData } = state;

	const hasFilter =
		pageParams.search !== '' ||
		pageParams['data-types'].length > 0 ||
		pageParams['instance-ids'].length > 0;

	return (
		<DataStorage>
			<Header
				showSensitiveSwitcher
				breadcrumbProps={{
					steps: dataStoragesSteps,
					finalStep: 'Kafka topics',
				}}
			/>

			<UniversalSearch value={pageParams.search} onChange={onUniversalSearchUpdate} />

			<FilterLine
				config={['dataTypes', 'kafkaInstances']}
				values={{
					dataTypes: pageParams['data-types'],
					kafkaInstances: pageParams['instance-ids'],
				}}
				onChange={(newValues) => {
					onFilterLineUpdate({
						'instance-ids': newValues.kafkaInstances,
					});
				}}
			/>

			<FilterResult
				entityLabel="kafka topic"
				loading={status === 'loading'}
				total={total}
				totalFiltered={total_filtered}
			/>

			<GridTable dataTest="kafka-topics-table">
				<EnhancedTableHead
					config={tableConfig}
					order={pageParams.sort.operator}
					orderBy={pageParams.sort.value}
					onRequestSort={onSortUpdate}
					rowClassname={styles.rowContainer}
				/>

				<Preloader isLoading={status === 'initial' || status === 'loading'}>
					<GridBody data-test="kafka-topics-list">
						<>
							{data.length ? (
								data.map((topic) => (
									<TopicTableRow topic={topic} search={pageParams.search} key={topic.id} />
								))
							) : hasFilter ? (
								<ResetFilters onReset={onResetFilters} />
							) : (
								<NoContent type="kafkaInstance" className={styles.rowContainer}>
									<div className={styles.emptyContainer}>
										<Typo variant="D/Regular/Body-S" color="secondary">
											No available analytics or no Data-at-rest Sensors set up yet.
										</Typo>

										<NewGatewayButton isDAR />
									</div>
								</NoContent>
							)}

							<LoadMoreButton
								show={hasMoreData}
								loading={status === 'loadingMore'}
								request={kafkaTopicsModel.fetchMoreFx}
							/>
						</>
					</GridBody>
				</Preloader>
			</GridTable>
		</DataStorage>
	);
}

export { TopicsTable };
