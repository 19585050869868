import cn from 'classnames';
import { useStore } from 'effector-react';
import { ReactElement } from 'react';
import { generatePath } from 'react-router';
import Chip, { CustomChipsProps } from 'components/Chip';
import Tooltip from 'components/Tooltip';
import { RouterLink } from 'components/typography/Link';
import { assetGroupsMap } from 'models/assetsGroups/store';
import { PATHS } from 'services/router';
import styles from './index.module.css';

type AssetGroupChipProps = {
	groupId: number;
	noTooltip?: boolean;
	noLink?: boolean;
	size?: CustomChipsProps['size'];
};

function AssetGroupChip(props: AssetGroupChipProps) {
	const { groupId, noTooltip = false, noLink = false, size = 'small' } = props;

	const groupsMap = useStore(assetGroupsMap);
	const group = groupsMap[groupId];

	const label = group ? group.name : groupId;
	const chip = (
		<Chip
			theme="assetGroup"
			label={label}
			size={size}
			className={styles.groupChip}
			classes={{ label: styles.ellipses }}
			data-test="asset-group-chip"
		/>
	);
	return noTooltip ? (
		<RouterLink to={generatePath(PATHS.ASSET_GROUP_ITEM, { id: groupId })}>{chip}</RouterLink>
	) : noLink ? (
		<Tooltip title={label}>{chip}</Tooltip>
	) : (
		<Tooltip title={label}>
			<RouterLink to={generatePath(PATHS.ASSET_GROUP_ITEM, { id: groupId })}>{chip}</RouterLink>
		</Tooltip>
	);
}

type AssetGroupChipListProps = {
	groups: number[];
	emptyTemplate?: string | ReactElement;
	className?: string;
};

function AssetGroupChipList(props: AssetGroupChipListProps) {
	const { groups, emptyTemplate = '', className } = props;

	const renderedGroups = groups.map((groupId) => {
		return <AssetGroupChip key={groupId} groupId={groupId} />;
	});

	return (
		<div className={cn(styles.groupList, className)} data-test="asset-group-chips">
			{renderedGroups.length > 0 ? renderedGroups : emptyTemplate}
		</div>
	);
}

export default AssetGroupChip;
export { AssetGroupChipList };
