import cn from 'classnames';
import { useStore } from 'effector-react';
import { createRef, useLayoutEffect, useState } from 'react';
import commonStyles from 'assets/styles/index.module.pcss';
import Icon from 'components/Icon';
import NewGatewayButton from 'components/NewGatewayBlock/NewGatewayButton';
import Preloader from 'components/Preloader';
import GridBody from 'components/table/GridBody';
import GridCell from 'components/table/GridCell';
import GridHead from 'components/table/GridHead';
import GridRow from 'components/table/GridRow';
import GridTable from 'components/table/GridTable';
import { RouterLink } from 'components/typography/Link';
import Typo from 'components/typography/Typo';
import { DiscoveredDataTypesTableItem } from 'models/discoveredDataTypesV2/dto';
import { activityStore } from 'models/recentActivity/store';
import { toLocaleString } from 'services/numbers';
import { PATHS } from 'services/router';
import styles from './index.module.css';

type IRowData = DiscoveredDataTypesTableItem & { color: string };
export type ITableData = IRowData[];

export const DataTypesTable = ({
	tableData,
	isLoading,
}: {
	tableData: ITableData;
	isLoading: boolean;
}) => {
	const [maxWidth, setMaxWidth] = useState(0);
	const ref = createRef<HTMLDivElement>();
	const maxPercent = tableData[0]?.data_fields?.percent;
	const hasTraffic = useStore(activityStore);

	useLayoutEffect(() => {
		setMaxWidth(Number(ref?.current?.offsetWidth) || 1);
	}, [ref]);

	return (
		<Preloader isLoading={isLoading}>
			{tableData.length === 0 && (
				<div className={styles.emptyContainer}>
					<Typo variant="D/Regular/Body-S" color="secondary">
						No available analytics or no Agents set up yet.
					</Typo>

					<NewGatewayButton />
				</div>
			)}

			{tableData.length !== 0 && (
				<GridTable dataTest="gateways-table" className={styles.table}>
					<GridHead>
						<GridRow className={styles.rowContainer}>
							<GridCell
								className={cn(styles.cellHead, styles.cellWithChartLine)}
								head
								align="right"
							>
								<Typo color="secondary" variant="D/Medium/Meta-S-CAP">
									Data Fields
								</Typo>
							</GridCell>

							<GridCell className={styles.cellHead} head>
								<Typo color="secondary" variant="D/Medium/Meta-S-CAP">
									Data Types
								</Typo>
							</GridCell>

							<GridCell className={styles.cellHead} head align="right">
								<Typo color="secondary" variant="D/Medium/Meta-S-CAP">
									Endpoints
								</Typo>
							</GridCell>

							<GridCell head>{null}</GridCell>
						</GridRow>
					</GridHead>

					<GridBody className={cn({ [commonStyles.skeletonMode]: !hasTraffic }, styles.body)}>
						{tableData.map(
							(
								{
									data_type,
									display_name,
									custom,
									data_fields: { count, percent },
									endpoints,
									color,
								},
								index
							) => {
								const charLinePercent =
									index === 0 ? '100%' : `${(percent / maxPercent) * maxWidth}px`;

								return (
									<GridRow className={styles.rowContainer} key={data_type}>
										<GridCell
											align="right"
											className={cn(styles.cell, styles.cellWithChartLine)}
											dataTest="table-data-fields"
										>
											<div className={styles.chartLineContainer}>
												<div className={styles.countWithPercent}>
													<Typo variant="D/Medium/Body-S" component="span">
														{toLocaleString(count)}
													</Typo>

													<Typo
														variant="D/Regular/Body-S"
														color="secondary"
														className={styles.percent}
														component="span"
													>
														({percent}%)
													</Typo>
												</div>

												<div
													ref={index === 0 ? ref : undefined}
													className={cn(commonStyles.skeletonBackground, styles.chartLine)}
													style={{
														background: color,
														width: charLinePercent,
													}}
												/>
											</div>
										</GridCell>

										<GridCell className={styles.cell} dataTest="table-data-type">
											<Typo variant="D/Regular/Body-S" className={styles.name}>
												{custom && (
													<Icon name={'Tag/Filled'} size={16} className={styles.customIcon} />
												)}
												{display_name}
											</Typo>
										</GridCell>

										<GridCell align="right" className={styles.cell} dataTest="table-endpoint">
											<Typo variant="D/Medium/Body-S">{toLocaleString(endpoints)}</Typo>
										</GridCell>

										<GridCell align="right" className={styles.cell}>
											{data_type !== 0 && (
												<Typo variant="D/Medium/Body-S" color="secondary">
													<RouterLink inherit to={`${PATHS.API_ENDPOINTS}?pii-types=${data_type}`}>
														View endpoints
													</RouterLink>
												</Typo>
											)}
										</GridCell>
									</GridRow>
								);
							}
						)}
					</GridBody>
				</GridTable>
			)}
		</Preloader>
	);
};
