import { createEvent } from 'effector';

/*
	When we drop session?
	- User logs out manually (store/client/effects)
	- We receive 401 on any request (error handling)
	- We fail to refresh access token for whatever reason (error handling)

	What we do when we drop session?
	- Clear app storage (store/client)
	- Clear secureRequest internal state (secureRequest.ts)
	- Clear pii filter in localStorage (components/PiiGlobalFilter)
	- Clear false-posivite visibility state in localStorage (views/Sample/FPVisibilityToggler/model.ts)
	- Clear Isometric map state in localStorage (views/DataMap/IsometricMap/model/store.ts)
	- Unblock history if blocked (services/history)

	TODO SMAT-2874 maybe there's a better place for it, but I haven't found it yet.
*/

const dropSession = createEvent();

export default dropSession;
