import { useMemo } from 'react';
import { SamplePageBody } from 'models/sample/dto';
import { SampleViewer } from 'views/common/SampleViewer';
import { logToDocuments } from './logParser';

// type Props = Pick<Sample, 'sample' | 'data_fields'>;
type Props = Pick<SamplePageBody, 'sample' | 'data_fields'>;

function LogViewer({ sample, data_fields }: Props) {
	const logDocuments = useMemo(() => logToDocuments(sample, data_fields), [sample, data_fields]);

	return <SampleViewer documents={logDocuments} dontGroupDetectionsInTable lineWrap />;
}

export { LogViewer };
