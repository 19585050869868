import cn from 'classnames';
import { useStore } from 'effector-react';
import { useHistory } from 'react-router-dom';
import { PiiTypeList } from 'components/PiiType';
import SensitivityChip from 'components/SensitivityChip';
import GridCell from 'components/table/GridCell';
import GridRow from 'components/table/GridRow';
import Typo from 'components/typography/Typo';
import { DataCombinationsItem } from 'models/dataCombinationsV2/dto';
import { dataTypesById } from 'models/dataTypes/store';
import { toLocaleString } from 'services/numbers';
import { PATHS } from 'services/router';
import styles from './index.module.css';

function DataCombinationRow({
	data_types,
	sensitivity,
	endpoints,
	endpoints_percent,
}: DataCombinationsItem) {
	const dataTypesMap = useStore(dataTypesById);
	const history = useHistory();

	function clickHandler() {
		const piiTypes = data_types.map((dt) => dataTypesMap[dt].alias);

		history.push(`${PATHS.API_ENDPOINTS}?pii-types=${piiTypes.join(',')}`);
	}

	return (
		<GridRow hover className={cn(styles.rowContainer)} onClick={clickHandler}>
			<GridCell align="right" className={styles.cell}>
				<Typo variant="D/Medium/Body-S" component="span">
					{toLocaleString(endpoints)}
				</Typo>

				<Typo
					variant="D/Regular/Body-S"
					component="span"
					className={styles.percent}
					color="secondary"
				>
					({Math.round(endpoints_percent * 10) / 10}%)
				</Typo>
			</GridCell>

			<GridCell className={styles.cell}>
				<SensitivityChip sensitivity={sensitivity} />
			</GridCell>

			<GridCell className={styles.cell}>
				<PiiTypeList data={data_types} noHighlight />
			</GridCell>
		</GridRow>
	);
}

export default DataCombinationRow;
