import cn from 'classnames';
import { useCallback } from 'react';
import { generatePath } from 'react-router';
import { Link } from 'react-router-dom';
import Typo from 'components/typography/Typo';
import { AssetGroup } from 'models/assetsGroups/dto';
import { PATHS } from 'services/router';
import { LS_ASSET_GROUP_DUPLICATE_KEY } from '../index';
import styles from './index.module.css';

export const DuplicateButton = ({ group }: { group: AssetGroup }) => {
	const duplicateGroup = useCallback(() => {
		localStorage.setItem(LS_ASSET_GROUP_DUPLICATE_KEY, JSON.stringify(group));
	}, [group]);

	return (
		<Typo
			variant="D/Medium/Body-S"
			className={cn(styles.button, styles.duplicateGroup)}
			onClick={duplicateGroup}
			data-test="group-form-duplicate-button"
		>
			<Link
				target="_blank"
				rel="noopener"
				to={{ pathname: generatePath(PATHS.ASSET_GROUP_ITEM, { id: 'new' }) }}
			>
				Duplicate group
			</Link>
		</Typo>
	);
};
