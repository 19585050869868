enum DataTest {
	'manual-data-type-container' = 'manual-data-type-container',
	'manual-data-type-chip' = 'manual-data-type-chip',
	'manual-data-type-chip-menu' = 'manual-data-type-chip-menu',
	'manual-data-type-chip-remove-button' = 'manual-data-type-chip-remove-button',
	'manual-data-type-m-sign' = 'manual-data-type-m-sign',
	'manual-data-type-add-button' = 'manual-data-type-add-button',
}

export default DataTest;
