import { AssetGroupChipList } from 'components/AssetGroupChip';
import Typo from 'components/typography/Typo';
import { AssetDetailJson } from 'models/assets/dto';
import styles from './index.module.css';

type Props = {
	asset: AssetDetailJson;
};

function AssetGroups({ asset }: Props) {
	return (
		<div className={styles.container}>
			<div className={styles.labelList}>
				<Typo variant="D/Medium/H100-Header" className={styles.name}>
					Groups
				</Typo>

				<div className={styles.groupList}>
					{asset.groups.length > 0 ? (
						<AssetGroupChipList groups={asset.groups} />
					) : (
						<Typo variant="D/Regular/Body-S" color="secondary">
							No groups.
						</Typo>
					)}
				</div>
			</div>
		</div>
	);
}

export default AssetGroups;
