/**
 * Got from here https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
 */
function validateEmail(email: string) {
	const re =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(email);
}

function validateUrl(url: string) {
	try {
		const { protocol } = new URL(url);

		return ['http:', 'https:'].includes(protocol);
	} catch (e) {
		return false;
	}
}

function validatePattern(pattern: string, value: string) {
	return new RegExp(pattern, 'g').test(value);
}

function validateIPV4(ip: string): boolean {
	try {
		/**
		 * Got from here  https://github.com/vinaykumarbu/ip-address-validator/blob/main/index.js#L3
		 */
		const re =
			'(?:25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]\\d|\\d)(?:\\.(?:25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]\\d|\\d)){3}';

		return new RegExp(`^${re}$`).test(ip.toString());
	} catch (e) {
		return false;
	}
}

function validateIPRange(ip: string, range: string): boolean {
	if (!(validateIPV4(ip) && validateIPV4(range))) return false;

	const ipArray = ip.split('.');
	const rangeArray = range.split('.');
	let result = false;

	for (let i = 0; i < ip.length; i++) {
		if (Number(rangeArray[i]) > Number(ipArray[i])) {
			result = true;
		} else if (Number(rangeArray[i]) === Number(ipArray[i])) {
			continue;
		}

		break;
	}

	return result;
}

function validateIP(ip: string): boolean {
	const ipList = ip.split('-');

	switch (ipList.length) {
		case 1: {
			return validateIPV4(ipList[0].trim());
		}
		case 2: {
			return validateIPRange(ipList[0].trim(), ipList[1].trim());
		}
		default: {
			return false;
		}
	}
}

export { validateEmail, validateUrl, validatePattern, validateIP };
