import { AccordionSummary as UiAccordionSummary, AccordionSummaryProps } from '@material-ui/core';
import cn from 'classnames';
import Icon from 'components/Icon';
import styles from './index.module.pcss';

function AccordionSummary({ classes, ...props }: AccordionSummaryProps) {
	return (
		<UiAccordionSummary
			expandIcon={<Icon name="ChevronDown/Regular" size={20} dataTest="accordion-expand-btn" />}
			classes={{
				root: cn(styles.root, classes?.root),
				expandIcon: cn(styles.expandIcon, classes?.expandIcon),
				content: cn(styles.content, classes?.content),
				expanded: cn(styles.expanded, classes?.expanded),
			}}
			{...props}
		/>
	);
}

export default AccordionSummary;
