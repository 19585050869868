import { useStore } from 'effector-react';
import { TSensitivityWithFP } from 'models/common/dto';
import { DataTypeItem } from './dto';
import { dataTypesById } from './store';

const DEFAULT_SENSITIVITY: TSensitivityWithFP = 'FP';
const FP_SENSITIVITY: TSensitivityWithFP = 'FP';

export const falsePositivePiiType = 0;

export function useSensitivityData(type: DataTypeItem['id'], isFP: boolean) {
	const dataTypesByIdStore = useStore(dataTypesById);
	const dataType = dataTypesByIdStore[type];

	const sensitivityLevel = isFP ? FP_SENSITIVITY : dataType?.sensitivity || DEFAULT_SENSITIVITY;
	const piiTypeName = dataType?.name || String(type);

	return { sensitivityLevel, piiTypeName, custom: dataType.custom };
}
