import cn from 'classnames';
import Button, { IButtonProps } from 'components/form/Button';
import styles from './index.module.css';

interface Props extends IButtonProps {
	show: boolean;
	loading: boolean;
	request: () => void;
	className?: string;
}

function LoadMoreButton(props: Props) {
	const { show, loading, request, className, ...rest } = props;

	if (!show) {
		return null;
	}

	return (
		<Button
			data-test="loadmorebutton"
			className={cn(className, styles.button)}
			loading={loading}
			onClick={request}
			size="small"
			color="tertiary"
			{...rest}
		>
			Show more
		</Button>
	);
}

export default LoadMoreButton;
