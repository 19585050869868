import cn from 'classnames';
import { useStore } from 'effector-react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useImmer } from 'use-immer';
import Alert, { AlertSeverity } from 'components/Alert';
import Checkbox from 'components/Checkbox';
import { CurtainOverlay } from 'components/Curtain';
import TextField from 'components/form/TextField';
import Icon from 'components/Icon';
import { enqueueSnackbar } from 'components/Snackbar';
import { RouterLink } from 'components/typography/Link';
import Typo from 'components/typography/Typo';
import { getAssetNameByType } from 'models/assets/model';
import { assetsList } from 'models/assets/store';
import { internalAssetsModel } from 'models/assetsExtended/model';
import { dataTypesPartsByIds } from 'models/dataTypes/helpers';
import { PolicyItem } from 'models/policies/dto';
import {
	createPolicyFx,
	deletePolicyFx,
	togglePolicyTrackingFx,
	updatePolicyFx,
} from 'models/policies/effects';
import { APIError } from 'services/api/httpRequest';
import { getPreviousPath } from 'services/history';
import { PATHS } from 'services/router';
import { Section } from 'views/common/Section';
import { DeleteModal } from '../DeleteModal';
import styles from './index.module.css';
import { PolicyDataTypes } from './PolicyDataTypes';
import { PolicyFormFooter } from './PolicyFormFooter';
import { PolicyFormHeader } from './PolicyFormHeader';
import PolicyRules, { EMPTY_RULE } from './PolicyRules';
import { RuleCurtain, RuleCurtainProps } from './RuleCurtain';
import ThirdPartyMultiSelect from './ThirdPartyMultiSelect';

type Props = {
	data: PolicyItem;
};

const ALREADY_EXIST_REGEXP = /name/;
const DATA_TYPES_CANNOT_BE_EMPTY_REGEXP = /Data types/;

const LS_POLICY_ALERT_KEY = 'is-policy-alert-hidden';
export const LS_POLICY_DUPLICATE_KEY = 'policy-duplicate';

export const PolicyItemForm = ({ data }: Props) => {
	const [displayAlertCloseButton, setDisplayAlertCloseButton] = useState(false);
	const [displayAlert, setDisplayAlert] = useState(false);
	const [displayRuleCurtain, setDisplayRuleCurtain] = useState(false);
	const [formData, setFormData] = useImmer(data);
	const [policyNameError, setPolicyNameError] = useState('');
	const [policyDataTypesError, setPolicyDataTypesError] = useState(false);
	const [foundNamespaces, setFoundNamespaces] = useState<RuleCurtainProps['namespaces']>([]);
	const assetsListStore = useStore(assetsList);

	const internalAssets = useStore(internalAssetsModel.store);
	const externalAssets = useMemo(
		() =>
			assetsListStore
				.filter((asset) => asset.type !== 'internal' && asset.is_deleted !== true)
				.map((asset) => ({ ...asset, name: getAssetNameByType(asset.type, asset.name) })),
		[assetsListStore]
	);

	const selectedExternalAssets = useMemo(() => {
		return externalAssets.filter((asset) => formData.allowed_external_assets.includes(asset.id));
	}, [formData, externalAssets]);

	useEffect(() => {
		internalAssetsModel.fetchFx({});
	}, []);

	const dataTypesParts = useMemo(() => {
		return dataTypesPartsByIds(formData.data_types);
	}, [formData.data_types]);

	useEffect(() => {
		const curtainData = internalAssets.data
			.filter(({ data_types }) =>
				data_types.some((dtId) =>
					[...dataTypesParts.dataTypesSource].map(({ id }) => id).includes(dtId)
				)
			)
			.reduce(
				(acc, asset) => {
					const namespace = acc.find(({ title }) => title === asset.namespace);

					if (namespace) {
						namespace.assets.push({ id: asset.name, title: asset.name });
					} else {
						acc.push({
							title: asset.namespace,
							assets: [{ id: asset.name, title: asset.name }],
						});
					}

					return acc;
				},
				[] as RuleCurtainProps['namespaces']
			);

		setFoundNamespaces(curtainData);
	}, [internalAssets.data, formData.data_types]);

	const topRef = useRef<HTMLDivElement>(null);
	const detailsSectionRef = useRef<HTMLDivElement>(null);
	const dataTypesSectionRef = useRef<HTMLDivElement>(null);
	const scrollToTop = (ref: React.RefObject<HTMLDivElement>) => {
		if (ref.current) {
			ref.current.scrollIntoView({ behavior: 'smooth' });
		}
	};

	useEffect(() => {
		setFormData(data);

		const isPolicyAlertDisplayed = localStorage.getItem(LS_POLICY_ALERT_KEY) !== 'true';
		setDisplayAlert(isPolicyAlertDisplayed);
	}, [data]);

	const history = useHistory();

	const isNew = useMemo(() => formData.id === 0, [data.id]);

	const onAlertClose = useCallback(() => {
		localStorage.setItem(LS_POLICY_ALERT_KEY, 'true');
		setDisplayAlert(false);
	}, []);

	const setName = useCallback((name) => {
		setFormData((draft) => {
			draft.name = name;
		});

		if (name.length > 255) {
			setPolicyNameError('Maximum Name length is 255');
		} else {
			setPolicyNameError('');
		}
	}, []);

	const setDescription = useCallback((description) => {
		setFormData((draft) => {
			draft.description = description;
		});
	}, []);

	const setDataTypes = useCallback((dataTypes: number[]) => {
		setFormData((draft) => {
			draft.data_types = dataTypes;
		});
	}, []);

	const addRule = useCallback((event) => {
		event.preventDefault();
		setFormData((draft) => {
			draft.rules.push([EMPTY_RULE]);
		});
	}, []);

	const addRules = useCallback((rules) => {
		setFormData((draft) => {
			draft.rules.push(...rules);
		});
	}, []);

	const onRulesChange = useCallback(
		function onRulesChange(mutatorFn) {
			setFormData((draft) => {
				const partialState = draft.rules;
				const possibleResult = mutatorFn(partialState);
				if (possibleResult !== undefined) draft.rules = possibleResult;

				// Support case when we delete all rules
				draft.rules = draft.rules.filter((complexRule) => complexRule.length !== 0);
			});
		},
		[formData]
	);

	const setAllowExternalTransfer = useCallback((allow_external_transfer) => {
		setFormData((draft) => {
			draft.allow_external_transfer = allow_external_transfer;
		});
	}, []);

	const setAllowUnencryptedS3Buckets = useCallback((allow_unencrypted_s3_buckets) => {
		setFormData((draft) => {
			draft.allow_unencrypted_s3_buckets = allow_unencrypted_s3_buckets;
		});
	}, []);

	const setAllowPublicS3Buckets = useCallback((allow_public_s3_buckets) => {
		setFormData((draft) => {
			draft.allow_public_s3_buckets = allow_public_s3_buckets;
		});
	}, []);

	const setExternalAssets = useCallback((assets) => {
		setFormData((draft) => {
			draft.allowed_external_assets = assets.map((asset: { id: number }) => asset.id);
		});
	}, []);
	// allow_encrypted_network_only
	const setAllowEncryptedNetworkOnly = useCallback((allow_encrypted_network_only) => {
		setFormData((draft) => {
			draft.allow_encrypted_network_only = allow_encrypted_network_only;
		});
	}, []);

	const savePolicy = async () => {
		if (formData.name.length > 255) {
			enqueueSnackbar('Maximum Name length is 255');
			return;
		}

		const saveHandler = isNew ? createPolicyFx : updatePolicyFx;

		try {
			await saveHandler({
				...formData,
				data_types: [...dataTypesParts.groups, ...dataTypesParts.outOfGroup].map(({ id }) => id),
			});
		} catch (error) {
			if (error instanceof APIError && error.response.status === 400) {
				const { message } = await error.response.json();

				switch (true) {
					case ALREADY_EXIST_REGEXP.test(message):
						scrollToTop(detailsSectionRef);
						setPolicyNameError(message);
						break;

					case DATA_TYPES_CANNOT_BE_EMPTY_REGEXP.test(message):
						scrollToTop(dataTypesSectionRef);
						setPolicyDataTypesError(true);
						enqueueSnackbar(message);
						break;

					default:
						scrollToTop(topRef);
						enqueueSnackbar(message);
				}

				return;
			}

			throw error;
		}

		history.push(PATHS.POLICY_LIST);
		enqueueSnackbar('Policy has been saved');
	};

	const deletePolicy = async () => {
		await DeleteModal({
			policyName: data.name,
			onConfirm: async () => {
				try {
					await deletePolicyFx(formData.id);
					enqueueSnackbar('Policy has been deleted');

					if (getPreviousPath() === PATHS.POLICY_LIST) {
						history.goBack();
					} else {
						history.replace(PATHS.POLICY_LIST);
					}
				} catch (error) {
					if (error instanceof APIError) {
						const { message } = await error.response.json();

						enqueueSnackbar(message);
					}
				}
			},
		});
	};

	const switchTracking = async () => {
		setFormData((draft) => {
			draft.is_active = !draft.is_active;

			togglePolicyTrackingFx({ id: draft.id, is_active: draft.is_active }).catch(() => {
				enqueueSnackbar('Something went wrong');
			});
		});
	};

	const duplicatePolicy = useCallback(() => {
		localStorage.setItem(LS_POLICY_DUPLICATE_KEY, JSON.stringify(formData));
	}, [formData]);

	return (
		<div className={styles.container} ref={topRef}>
			<PolicyFormHeader
				isNew={isNew}
				deletePolicy={deletePolicy}
				duplicatePolicy={duplicatePolicy}
				tracking={formData.is_active}
				switchTracking={switchTracking}
				name={data.name}
			/>

			<Section ref={detailsSectionRef}>
				<Typo variant="D/Medium/H100-Header">Policy details</Typo>
				<div className={styles.name}>
					<TextField
						dataTest="policy-name-input"
						placeholder="Name"
						label="Name"
						value={formData.name}
						onChange={(e) => setName(e.target.value)}
						helperText={policyNameError}
						error={!!policyNameError}
						optional={false}
					/>
				</div>
				<div className={styles.description}>
					<TextField
						dataTest="policy-description-input"
						helperText={null}
						variant="outlined"
						placeholder="Policy description"
						multiline={true}
						value={formData.description}
						onChange={(e) => setDescription(e.target.value)}
					/>
				</div>
			</Section>

			<Section error={policyDataTypesError} ref={dataTypesSectionRef}>
				<Typo variant="D/Medium/H100-Header">Policy data types</Typo>
				<Typo variant="D/Regular/Body-S" className={styles.sectionDescription}>
					Data types to which the policy will apply.
				</Typo>

				<PolicyDataTypes
					fixed
					showUnused
					value={dataTypesParts.dataTypesSource.map(({ id }) => id)}
					onChange={(dataTypes) => {
						setDataTypes(dataTypes);
						setPolicyDataTypesError(false);
					}}
				/>

				<Typo
					dataTest="policy-namespaces-count"
					variant="D/Medium/Body-S"
					className={cn(styles.namespaceFound, foundNamespaces.length === 0 && styles.disabled)}
					onClick={() => setDisplayRuleCurtain(!displayRuleCurtain)}
				>
					Found in {foundNamespaces.length} namespace{foundNamespaces.length !== 1 && 's'}
				</Typo>
			</Section>

			<Section>
				<Typo variant="D/Medium/H100-Header">Service access</Typo>
				<Typo variant="D/Regular/Body-S" className={styles.sectionDescription}>
					Services that will be allowed to access selected data types.
				</Typo>

				<PolicyRules rules={formData.rules} onChange={onRulesChange} />

				<Typo variant="D/Medium/Body-S" className={styles.addRuleButton}>
					<RouterLink onClick={addRule} to="#" data-test="policy-add-rule-button">
						<Icon name="Add/Regular" size={20} />
						Add rule
					</RouterLink>
				</Typo>
			</Section>

			<Section>
				<Typo variant="D/Medium/H100-Header" className={styles.sectionTitle}>
					Third party sharing
				</Typo>

				<Checkbox
					className={styles.checkboxInput}
					size="M"
					label="Notify if the selected data types are shared with third parties by this set of services"
					checked={!formData.allow_external_transfer}
					onChange={() => setAllowExternalTransfer(!formData.allow_external_transfer)}
					dataTest="policy-3rd-party-checkbox"
				/>

				<ThirdPartyMultiSelect
					value={selectedExternalAssets}
					options={externalAssets}
					onChange={setExternalAssets}
					label={selectedExternalAssets.length ? 'Third party: ' : 'No third party'}
				/>
			</Section>

			<Section>
				<Typo variant="D/Medium/H100-Header" className={styles.sectionTitle}>
					S3 bucket access
				</Typo>

				<Checkbox
					className={styles.checkboxInput}
					size="M"
					label="Selected data types should not be stored in unencrypted S3 buckets"
					checked={!formData.allow_unencrypted_s3_buckets}
					onChange={() => setAllowUnencryptedS3Buckets(!formData.allow_unencrypted_s3_buckets)}
					dataTest="policy-s3-unencrypted-checkbox"
				/>

				<Checkbox
					size="M"
					label="Selected data types should not be stored in public S3 buckets"
					checked={!formData.allow_public_s3_buckets}
					onChange={() => setAllowPublicS3Buckets(!formData.allow_public_s3_buckets)}
					dataTest="policy-s3-public-checkbox"
				/>
			</Section>

			<Section>
				<Typo variant="D/Medium/H100-Header" className={styles.sectionTitle}>
					Data flow monitoring
				</Typo>

				<Checkbox
					className={styles.checkboxInput}
					size="M"
					label="Notify if the selected data types are shared in unencrypted traffic"
					checked={formData.allow_encrypted_network_only}
					onChange={() => setAllowEncryptedNetworkOnly(!formData.allow_encrypted_network_only)}
					dataTest="policy-encrypted_network_only-checkbox"
				/>
			</Section>

			{displayAlert && (
				<Section className={styles.plainSection}>
					<Alert
						severity={AlertSeverity.info}
						onClick={displayAlertCloseButton ? onAlertClose : undefined}
						onMouseOver={() => {
							setDisplayAlertCloseButton(true);
						}}
						onMouseLeave={() => {
							setDisplayAlertCloseButton(false);
						}}
						className={styles.infoAlert}
						header="Only new traffic will be tracked"
					>
						<Typo variant="D/Regular/Body-S">
							After saving, only future API requests will be checked for violations. Potential
							violations based on previous data requests will not be shown.
						</Typo>
					</Alert>
				</Section>
			)}

			<Section className={styles.plainSection}>
				<PolicyFormFooter isNew={isNew} isError={!!policyNameError} saveForm={savePolicy} />
			</Section>

			<CurtainOverlay
				open={displayRuleCurtain}
				onClose={() => setDisplayRuleCurtain(false)}
				rightPart={
					<RuleCurtain
						dataTypes={[...dataTypesParts.groups, ...dataTypesParts.outOfGroup].map(({ id }) => id)}
						namespaces={foundNamespaces}
						onChange={(rules) => {
							addRules(rules);
							setDisplayRuleCurtain(false);
						}}
					/>
				}
			/>
		</div>
	);
};
