import { useStore } from 'effector-react';
import { DataTypeFilter } from 'components/FilterLine/DataTypeFilter';
import { DataTypeItem } from 'models/dataTypes/dto';
import { piiFilterStore, setPiiFilter } from './model';

type Props = {
	defaultOpen?: boolean;
	fixed?: boolean;
	onClose?: () => void;
	resetFilter?: () => void;
};

function PiiGlobalFilter({ fixed, resetFilter, onClose, defaultOpen }: Readonly<Props>) {
	const { dataTypes } = useStore(piiFilterStore);

	function onChange(selectedDataTypes: DataTypeItem['id'][]) {
		setPiiFilter({ dataTypes: selectedDataTypes });
	}

	return (
		<DataTypeFilter
			defaultOpen={defaultOpen}
			fixed={fixed}
			onClose={onClose}
			resetFilter={resetFilter}
			value={dataTypes}
			onChange={onChange}
		/>
	);
}

export default PiiGlobalFilter;
