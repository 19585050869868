import AssetChip from 'components/AssetChip';
import Typo from 'components/typography/Typo';
import { methodOrProtocol } from 'models/endpointsV2/dto';
import { EventWithDIMSample } from 'models/eventsV2/dto';
import { dayjs, format } from 'services/dayjs';
import { getStringDividedByDot } from 'services/strings';
import { getAssetNameByType } from 'views/common/AssetNameWithIcon';
import styles from './index.module.css';

type Props = {
	event: EventWithDIMSample | null;
};

function HeaderDIM(props: Props) {
	const { event } = props;

	if (!event) return null;

	const asset = event.event_regarding === 'requestor' ? event.requestor : event.responder;
	const createdAt = format(dayjs(event.created_at).utc());

	return (
		<div className={styles.headerContainer}>
			<Typo
				variant="D/Medium/H400-Page-Title"
				className={styles.overflow}
				dataTest="endpoint-header-title"
			>
				{event.hostname}
				{event.url}
			</Typo>

			<div className={styles.subheader} data-test="endpoint-header-info">
				<Typo variant="D/Regular/Body-S" color="secondary">
					{methodOrProtocol(event)}
				</Typo>

				<AssetChip
					label={getAssetNameByType(asset.type, asset.name, 12)}
					isExternal={asset.is_external}
					size="extraSmall"
					noTooltip
				/>

				<Typo variant="D/Regular/Body-S" color="secondary" className={styles.flex}>
					{getStringDividedByDot(asset.cluster_name, asset.namespace, createdAt)}
				</Typo>
			</div>
		</div>
	);
}

export { HeaderDIM };
