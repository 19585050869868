import { useMemo } from 'react';
import { SamplePageBody } from 'models/sample/dto';
import { SampleViewer, ViewerSize } from 'views/common/SampleViewer';
import { jsonToJsonDocuments } from './jsonParser';

type Props = Pick<SamplePageBody, 'sample' | 'data_fields'> & {
	size?: ViewerSize;
};

function JSONViewer({ sample, data_fields, size }: Props) {
	const jsonDocuments = useMemo(
		() => jsonToJsonDocuments(sample, data_fields),
		[sample, data_fields]
	);

	return <SampleViewer documents={jsonDocuments} size={size} />;
}

export { JSONViewer };
