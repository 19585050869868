import { getDataTypesAsApiParam } from 'models/dataTypes/helpers';
import { createTableModel, Store } from 'models/modelUtils/tableModelFactory';
import { ApiParams } from 'services/api';
import { getAssetsExtended } from './api';
import { TAssetsItem, TAssetsRequest } from './dto';

type AssetsStore = Store<TAssetsItem, TAssetsRequest>;

function fetchDataApi(apiParamsRaw: ApiParams, signal?: AbortSignal) {
	const { 'data-types': piiTypes, ...apiParams } = apiParamsRaw;

	apiParams['data-types'] = getDataTypesAsApiParam(piiTypes);

	return getAssetsExtended(apiParams, signal).then((payload) => {
		return {
			data: payload.assets,
			total: payload.total,
			total_filtered: payload.total_filtered,
		};
	});
}

function createAssetsModel(initialParams: TAssetsRequest, fetchFxOnInit: boolean = true) {
	return createTableModel<TAssetsItem, TAssetsRequest>(initialParams, fetchDataApi, fetchFxOnInit);
}

//
// Instantiate model with initial params, but do not fetch data from server.
//

const initialQueryParam = {
	search: '',
	'data-types': [],
	name: '',
	namespaces: [],
	label_keys: [],
	label_values: [],
	group: 0,
	groups: [],
	cluster_ids: [],
};

const initialParams: TAssetsRequest = {
	...initialQueryParam,
	limit: 0,
	offset: 0,
	is_external: false,
	sort: { orderBy: 'sensitivity', order: 'desc' },
	total_by_group: false,
};

const internalAssetsModel = createAssetsModel({ ...initialParams, is_external: false }, false);
const externalAssetsModel = createAssetsModel({ ...initialParams, is_external: true }, false);

export { internalAssetsModel, externalAssetsModel, initialQueryParam };
export type { AssetsStore };
