import { createStore } from 'effector';
import { getDataTypesStructured } from 'models/dataTypes/helpers';
import { DataTypesExtendedItem } from './dto';
import { getDataTypesExtendedFx } from './effects';

const dataTypesStore = createStore<DataTypesExtendedItem[]>([]);

dataTypesStore.on(
	getDataTypesExtendedFx.doneData,
	(state: DataTypesExtendedItem[], data: DataTypesExtendedItem[]) => data
);

const dataTypesStructuredStore = dataTypesStore.map((dataTypes) => {
	const structured = getDataTypesStructured(dataTypes);

	return structured.map((group) => ({
		...group,
		known: !group.child_items.some((dt) => !dt.known && dt.isUsed),
	}));
});

export { dataTypesStore, dataTypesStructuredStore };
