import { IHeadCell } from 'components/table/EnhancedTableHead';
import { S3BucketItem } from 'models/s3Buckets/dto';

const tableConfig: IHeadCell<S3BucketItem>[] = [
	{
		id: 'name',
		numeric: false,
		label: 'Name',
		sortable: true,
	},
	{
		id: 'region',
		numeric: false,
		label: 'Region',
		sortable: true,
	},
	{
		id: 'public_status',
		numeric: false,
		label: 'Access rights',
		sortable: false,
	},
	{
		id: 'sensitivity',
		numeric: false,
		label: 'Sensitivity',
		sortable: true,
	},
	{
		id: 'data_types',
		numeric: false,
		label: 'Data types',
		sortable: false,
	},
	{
		id: 'created_at',
		numeric: false,
		label: 'Created',
		sortable: true,
	},
	{
		id: 'last_checked',
		numeric: false,
		label: 'Last checked',
		sortable: true,
		reversedSort: true,
	},
	{
		id: 'action',
		numeric: false,
		label: '',
		sortable: false,
	},
];

export default tableConfig;
