import { Children, PureComponent, ReactElement, ReactNode, useEffect, useState } from 'react';
import BaseModal, { BaseModalProps, BaseModalTheme } from 'components/BaseModal';
import Button, { IButtonProps } from 'components/form/Button';
import TextField from 'components/form/TextField';
import styles from './index.module.css';

interface ICancelProps {
	resolveValue?: boolean;
	children?: string;
}

interface ConfirmModalFooterProps {
	onClose?: BaseModalProps['onClose'];
	onCancel?: () => void;
	cancelProps?: IButtonProps & ICancelProps;
	hideCancel?: boolean;
	children: ReactNode;
}

function ConfirmModalFooter({
	hideCancel,
	onClose,
	onCancel,
	cancelProps,
	children,
}: ConfirmModalFooterProps) {
	return (
		<div className={styles.footer}>
			{!hideCancel && (
				<Button
					onClick={onCancel || onClose}
					color="ghost"
					size="small"
					autoFocus
					{...cancelProps}
					data-test="confirm-cancel-button"
				>
					{cancelProps?.children || 'Cancel'}
				</Button>
			)}

			{children}
		</div>
	);
}

// We govern open/closed state of the modal inside container - no use to pass it.
interface ConfirmModalProps extends Omit<BaseModalProps, 'open'>, ConfirmModalFooterProps {
	open?: boolean;
	onConfirm?: () => void;
	onCancel?: () => void;
	confirmProps?: IButtonProps;
	disableUntilEntered?: string;
}

function ConfirmModal({
	children,
	onClose,
	onConfirm,
	onCancel,
	theme = BaseModalTheme.danger,
	open = false,
	confirmProps,
	cancelProps,
	hideCancel,
	disableUntilEntered,
	placeholder,
	...props
}: ConfirmModalProps) {
	const [inputValue, setInputValue] = useState<string>('');

	useEffect(() => {
		setInputValue('');
	}, [disableUntilEntered]);

	return (
		<BaseModal onClose={onClose} theme={theme} open={open} {...props}>
			{children}

			{disableUntilEntered && (
				<TextField
					value={inputValue}
					onChange={(e) => setInputValue(e.target.value)}
					size="small"
					helperText={null}
					classes={{ root: styles.confirmationInput }}
					autoComplete="off"
					data-test="confirm-input"
					placeholder={placeholder}
				/>
			)}

			<ConfirmModalFooter
				onClose={onClose}
				onCancel={onCancel}
				cancelProps={cancelProps}
				hideCancel={hideCancel}
			>
				<Button
					onClick={onConfirm}
					data-test="confirm-ok-button"
					size="small"
					theme={theme === BaseModalTheme.danger ? 'danger' : undefined}
					disabled={disableUntilEntered !== undefined && disableUntilEntered !== inputValue}
					{...confirmProps}
				/>
			</ConfirmModalFooter>
		</BaseModal>
	);
}

let containerSingleton: ConfirmModalContainer | null = null;

type ConfirmModalContainerState = {
	modalProps: ConfirmModalProps | null;
	resolve: ((result: boolean) => void) | null;
	reject: ((reason: string) => void) | null;
	open: boolean;
};

class ConfirmModalContainer extends PureComponent<{}, ConfirmModalContainerState> {
	state: ConfirmModalContainerState = {
		modalProps: null,
		resolve: null,
		reject: null,
		open: false,
	};

	placeholderModalProps: ConfirmModalProps = {
		children: 'Please confirm action',
		title: 'Confirmation required',
	};

	componentDidMount() {
		if (containerSingleton) {
			console.warn('Unexpected error. May indicate memory leak.');
		}

		containerSingleton = this;
	}

	componentWillUnmount() {
		this.state.reject?.('ConfirmModal unmounted');

		containerSingleton = null;
	}

	enqueue(
		modalElement: ReactElement<ConfirmModalProps>,
		resolve: (result: boolean) => void,
		reject: (reason: string) => void
	) {
		const modalProps = Children.only(modalElement).props;

		if (this.state.open) {
			console.warn('Another confirmation modal was opened - rejecting it.');
			this.state.reject?.('ConfirmModal unmounted');
		}

		this.setState({ modalProps, resolve, reject, open: true });
	}

	onConfirm = () => {
		const { modalProps, resolve } = this.state;

		resolve?.(true);
		modalProps?.onConfirm?.();
		this.setState({ resolve: null, reject: null, open: false });
	};

	onClose = () => {
		const { modalProps, resolve } = this.state;

		resolve?.(false);
		modalProps?.onClose?.();
		this.setState({ resolve: null, reject: null, open: false });
	};

	onCancel = () => {
		const { modalProps, resolve } = this.state;

		resolve?.(modalProps?.cancelProps?.resolveValue || false);
		modalProps?.onCancel?.();
		this.setState({ resolve: null, reject: null, open: false });
	};

	render() {
		const { children } = this.props;
		const { modalProps = {}, open } = this.state;

		return (
			<>
				{children}

				<ConfirmModal
					{...this.placeholderModalProps}
					{...modalProps}
					open={open}
					onConfirm={this.onConfirm}
					onClose={this.onClose}
					onCancel={this.onCancel}
				/>
			</>
		);
	}
}

async function getConfirmation(modalElement: ReactElement<ConfirmModalProps>) {
	return new Promise(function (resolve, reject) {
		if (!containerSingleton) {
			throw new Error('ConfirmModalContainer is not mounted');
		}

		containerSingleton.enqueue(modalElement, resolve, reject);
	});
}

export { ConfirmModal, ConfirmModalContainer, getConfirmation };
// Maybe export ConfirmModalFooter later, if needed
