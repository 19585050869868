import cn from 'classnames';
import { useStore } from 'effector-react';
import isEqual from 'lodash.isequal';
import { Fragment, useEffect, useState } from 'react';
import commonStyles from 'assets/styles/index.module.pcss';
import { CurtainOverlay } from 'components/Curtain';
import FilterLine from 'components/FilterLine';
import LoadMoreButton from 'components/LoadMoreButton';
import NewGatewayBlock from 'components/NewGatewayBlock';
import {
	piiFilterStore,
	clearPiiFilter,
	piiFilterToArray,
	piiFilterWithGroupsStore,
} from 'components/PiiGlobalFilterV2/model';
import Preloader from 'components/Preloader';
import ResetFilters from 'components/ResetFilters';
import Header from 'layouts/AuthorizedWithLeftMenu/Header';
import { EventJson } from 'models/eventsV2/dto';
import { eventsJsonExample } from 'models/eventsV2/examples';
import { EventParams, eventsModel, EventFromParam } from 'models/eventsV2/model';
import { getReleaseFx } from 'models/release/effects';
import { DateFormat, dayjs } from 'services/dayjs';
import { PageParamsConfig, usePageParams } from 'services/pageParams';
import EventDrawer from './EventDrawer';
import EventRow from './EventRow';
import styles from './index.module.css';

const pageConfig = {
	id: {
		type: 'number',
		persistence: 'session',
	},
	type: {
		type: 'string',
		persistence: 'session',
	},
	from: {
		type: 'string',
		persistence: 'session',
	},
	'data-types': {
		type: 'numberArray',
		persistence: 'session', // TODO implement 'global' in actuality
	},
	'cluster-ids': {
		type: 'numberArray',
		persistence: 'session',
	},
	namespaces: {
		type: 'stringArray',
		persistence: 'session',
	},
} satisfies PageParamsConfig;

type Filter = {
	type: EventParams['type'];
	from: EventFromParam;
	'data-types': number[]; // TODO
	'cluster-ids': EventParams['cluster_ids'];
	namespaces: EventParams['namespaces'];
};

const initialFilter: Filter = {
	type: '',
	from: '90',
	'data-types': [] as number[], // TODO
	'cluster-ids': [] as number[], // TODO
	namespaces: [],
};

type DailyEvents = [string, EventJson[]];

function Events() {
	const state = useStore(eventsModel.store);
	const piiFilterWithGroups = useStore(piiFilterWithGroupsStore);
	const [pageParams, setPageParams] = usePageParams(pageConfig, 'activityLog');

	useEffect(() => {
		setPageParams({
			...pageParams,
			'data-types': piiFilterWithGroups.dataTypes,
		});
	}, [piiFilterWithGroups]);

	useEffect(() => {
		const { type, from, 'cluster-ids': clusterIds, namespaces } = pageParams;

		const dataTypes = piiFilterToArray(piiFilterWithGroups);

		const paramsForFx: Partial<EventParams> = {
			type: type as EventParams['type'],
			from: dayjs()
				.subtract(Number(from || initialFilter.from), 'day')
				.startOf('day')
				.valueOf(),
			'data-type-ids': dataTypes,
			cluster_ids: clusterIds,
			namespaces,
		};

		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const { sort, ...prevParams } = state.params;
		if (isEqual(paramsForFx, prevParams)) return;

		eventsModel.fetchFx(paramsForFx);
		getReleaseFx();
	}, [pageParams]);

	// Clean up model on unmount
	useEffect(() => {
		return () => {
			eventsModel.resetFx();
		};
	}, []);

	function onFilterLineUpdate(filterLineParams: Partial<typeof pageParams>) {
		setPageParams({ ...pageParams, ...filterLineParams });
	}

	function onResetFilters() {
		setPageParams({ ...pageParams, ...initialFilter });
		clearPiiFilter();
	}

	const { status, data: realData, hasMoreData, params } = state;
	const hasFilter =
		(params['data-type-ids'].length > 0 && params['data-type-ids'][0] !== 'nonempty') ||
		params.type !== '' ||
		pageParams.from !== initialFilter.from ||
		params.cluster_ids.length > 0 ||
		params.namespaces.length > 0;
	const [isCurtainOpen, setCurtainOpenState] = useState(false);
	const piiFilter = useStore(piiFilterStore);

	let data = realData;
	let isSkeletonData = false;
	if (realData.length === 0) {
		data = eventsJsonExample.log_events;
		isSkeletonData = true;
	}

	function onSelectEvent(eventId: number) {
		setPageParams({
			...pageParams,
			id: eventId,
		});
	}

	const eventsMap = data.reduce((acc, event) => {
		const eventDay = dayjs(event.created_at).startOf('day').valueOf();
		const eventsInDay = acc.get(eventDay) || [];
		eventsInDay.push(event);
		acc.set(eventDay, eventsInDay);

		return acc;
	}, new Map<number, EventJson[]>());

	const today = dayjs().startOf('day');

	const eventsFormattedList = [...eventsMap.entries()].map(
		(dailyEvents: [number, EventJson[]]): DailyEvents => {
			const day = dayjs(dailyEvents[0]);
			const dayFormatted = day.isSame(today) ? 'Today' : day.format(DateFormat.dateWithWeekday);

			return [dayFormatted, dailyEvents[1]];
		}
	);

	const rightCurtainPart = <EventDrawer id={pageParams.id} openCurtain={setCurtainOpenState} />;

	return (
		<>
			<Header breadcrumbProps={{ finalStep: 'Activity log' }} />

			<FilterLine
				config={['eventStart', 'dataTypes', 'clusters', 'namespaces', 'eventType']}
				values={{
					eventStart: (pageParams.from || initialFilter.from) as Filter['from'],
					dataTypes: pageParams['data-types'],
					clusters: pageParams['cluster-ids'],
					namespaces: pageParams.namespaces,
					eventType: pageParams.type as Filter['type'],
				}}
				onChange={(newValues) => {
					onFilterLineUpdate({
						from: newValues.eventStart,
						'cluster-ids': newValues.clusters,
						namespaces: newValues.namespaces,
						type: newValues.eventType,
					});
				}}
			/>

			<div className={styles.container}>
				<Preloader isLoading={status === 'loading'}>
					{realData.length === 0 && hasFilter ? (
						<ResetFilters onReset={onResetFilters} />
					) : (
						<div className={cn(styles.content, isSkeletonData && commonStyles.skeletonMode)}>
							{eventsFormattedList.map((dailyEvents: DailyEvents) => {
								const [dayFormatted, eventsInDay] = dailyEvents;

								return (
									<Fragment key={dayFormatted}>
										<h3 className={styles.header}>{dayFormatted}</h3>

										{eventsInDay.map((event: EventJson) => (
											<EventRow
												filterIds={piiFilter.dataTypes}
												key={event.id}
												event={event}
												selected={pageParams.id === event.id}
												onSelect={onSelectEvent}
											/>
										))}
									</Fragment>
								);
							})}

							<LoadMoreButton
								show={hasMoreData}
								loading={status === 'loadingMore'}
								request={eventsModel.fetchMoreFx}
								className={styles.loadMore}
							/>
						</div>
					)}
				</Preloader>
			</div>

			<CurtainOverlay
				open={isCurtainOpen}
				onClose={() => {
					onSelectEvent(0);
					setCurtainOpenState(false);
				}}
				classes={{
					rightPartOpen: styles.curtain,
				}}
				rightPart={rightCurtainPart}
			/>

			<div className={styles.wideContent}>
				{!hasFilter && isSkeletonData && status !== 'loading' && <NewGatewayBlock />}
			</div>
		</>
	);
}

export default Events;
