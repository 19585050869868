import { del, get, post } from 'api/Api';
import checkResponse from 'services/api/checkResponse';
import { AccountAWSItem, AccountAWSList, AccountAWSCreate, AccountAWSTest } from './dto';
import {
	offlineAccountJsonExample,
	onlineAccountJsonExample,
	accountsAWSExample,
	draftAccountsExist,
	negativeAccountsAWSTestExample,
	positiveAccountsAWSTestExample,
} from './examples';

async function getAccountsAWS() {
	return get<AccountAWSList>('v1/discovery/account/aws').then(checkResponse(accountsAWSExample));
}
async function getAccountAWS(id: number) {
	return get<AccountAWSItem>(`v1/discovery/account/aws/${id}`).then(
		checkResponse(onlineAccountJsonExample)
	);
}

async function createDraftAccountAWS() {
	return post<AccountAWSItem>('v1/discovery/account/aws').then(checkResponse(draftAccountsExist));
}

// If payload has option `regenerate_external_id` then we update external id ONLY
// If payload has option `role_arn` then we create account
async function createAccountAWS({ id, ...payload }: AccountAWSCreate) {
	return post<AccountAWSItem>(`v1/discovery/account/aws/${id}`, payload).then(
		checkResponse(offlineAccountJsonExample, onlineAccountJsonExample)
	);
}

async function deleteAccountAWS(id: number) {
	return del(`v1/discovery/account/aws/${id}`).then(() => id);
}

async function testAWSConfiguration(payload: { id: number; role_arn: string }) {
	const { id, role_arn } = payload;

	return post<AccountAWSTest>(`v1/spotter/discovery-accounts/${id}/check-connection`, {
		role_arn,
	}).then(checkResponse(negativeAccountsAWSTestExample, positiveAccountsAWSTestExample));
}

export {
	createDraftAccountAWS,
	getAccountsAWS,
	getAccountAWS,
	createAccountAWS,
	deleteAccountAWS,
	testAWSConfiguration,
};
