import Big from 'big.js';
import { useStore } from 'effector-react';
import { useEffect, useState } from 'react';
import { RouterLink } from 'components/typography/Link';
import Typo from 'components/typography/Typo';
import { getDiscoveredDataTypes } from 'models/discoveredDataTypesV2/api';
import { DiscoveredDataTypesTableItem } from 'models/discoveredDataTypesV2/dto';
import { activityStore } from 'models/recentActivity/store';
import { PATHS } from 'services/router';
import { WidgetBlock, WidgetHeader } from '../WidgetParts';
import { DataTypesTable, ITableData } from './DataTypesTable';
import { discoveredDataTypeColors } from './discoveredDataTypeColors';
import emptyData from './emptyData';
import styles from './index.module.css';

const DATA_TYPES_LIMIT = 6;
const prepareDataTypes = (dataTypes: DiscoveredDataTypesTableItem[]): ITableData => {
	let othersPercent = 0;
	const sumOfCount = dataTypes.reduce((acc, { data_fields: { count } }) => acc + count, 0);

	const tableData: ITableData = [];

	if (dataTypes.length === 0) {
		return tableData;
	}

	const maxDisplayedDataTypes =
		dataTypes.length > DATA_TYPES_LIMIT ? DATA_TYPES_LIMIT - 1 : DATA_TYPES_LIMIT;
	dataTypes
		.slice(0, maxDisplayedDataTypes)
		.forEach(({ data_type, display_name, custom, alias, data_fields: { count }, endpoints }) => {
			const formattedPercent = new Big(count * 100)
				.div(sumOfCount)
				.round(1, Big.roundHalfEven)
				.toNumber();
			othersPercent = new Big(othersPercent).plus(formattedPercent).toNumber();

			tableData.push({
				data_type,
				display_name,
				custom,
				alias,
				data_fields: {
					count,
					percent: formattedPercent,
				},
				endpoints,
				color: discoveredDataTypeColors[alias] || discoveredDataTypeColors['UNKNOWN_DATA_TYPE'],
			});
		});

	if (dataTypes.length > DATA_TYPES_LIMIT) {
		tableData.push({
			data_type: 0,
			display_name: 'Others',
			custom: false,
			alias: 'OTHER',
			data_fields: {
				count: 0,
				percent: new Big(100).minus(othersPercent).toNumber(),
			},
			endpoints: 0,
			color: discoveredDataTypeColors['OTHERS_DATA_TYPE'],
		});

		dataTypes.slice(DATA_TYPES_LIMIT - 1).forEach(({ data_fields: { count }, endpoints }) => {
			const othersIndex = tableData.length - 1;

			tableData[othersIndex].data_fields.count += count;
			tableData[othersIndex].endpoints += endpoints;
		});
	} else {
		const restWhenRound = new Big(100).minus(othersPercent).round(1, Big.roundHalfUp).toNumber();

		tableData[tableData.length - 1].data_fields.percent = new Big(
			tableData[tableData.length - 1].data_fields.percent
		)
			.plus(restWhenRound)
			.toNumber();

		tableData.sort(
			({ data_fields: { percent: percentA } }, { data_fields: { percent: percentB } }) =>
				percentB - percentA
		);
	}

	return tableData;
};
const preparedEmptyData = prepareDataTypes(emptyData);

export const DiscoveredDataTypes = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [tableData, setTableData] = useState<ITableData>(preparedEmptyData);

	const hasTraffic = useStore(activityStore);

	useEffect(() => {
		if (hasTraffic) {
			setIsLoading(true);
			getDiscoveredDataTypes('all').then(({ data_types }) => {
				setTableData(prepareDataTypes(data_types));
				setIsLoading(false);
			});
		}
	}, [hasTraffic]);

	return (
		<>
			<WidgetHeader header="Discovered data types" />

			<WidgetBlock>
				<div className={styles.widgetHeader}>
					<Typo variant="D/Medium/Body-S" color="secondary" className={styles.seeAllLink}>
						<RouterLink inherit to={PATHS.DATA_TYPES_LIST} setPageParams>
							See all
						</RouterLink>
					</Typo>
				</div>

				<DataTypesTable tableData={tableData} isLoading={isLoading} />
			</WidgetBlock>
		</>
	);
};
