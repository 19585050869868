import { createEffect } from 'effector';
import { ApiParams } from 'services/api';
import { getS3BucketItem, getS3Buckets, getS3BucketStatus, updateS3Bucket } from './api';
import { S3BucketItem, S3BucketList, S3BucketStatus } from './dto';

/* API */

let ac = new AbortController();

export const getS3BucketsFx = createEffect<ApiParams, S3BucketList>();
export const getS3BucketByIdFx = createEffect((id: S3BucketItem['id']) => {
	// Max one request at a time. Since API is parametrized - just cancel previous request(s).
	// It is necessary when data map has saved active bucked
	ac.abort();
	ac = new AbortController();

	return getS3BucketItem(id, ac.signal);
});
export const updateS3BucketFx = createEffect<S3BucketItem, S3BucketItem>();
export const getS3BucketStatusFx = createEffect<
	{ id: S3BucketItem['id']; nonEmpty: boolean },
	S3BucketStatus
>();
/* IMPLEMENTATION */

// Simple direct-to-api effects

getS3BucketsFx.use(getS3Buckets);
updateS3BucketFx.use(updateS3Bucket);
getS3BucketStatusFx.use(getS3BucketStatus);
