import cn from 'classnames';
import { useStore } from 'effector-react';
import { ReactNode, useMemo } from 'react';
import commonStyles from 'assets/styles/index.module.pcss';
// import { clearPiiFilter } from 'components/PiiGlobalFilter/model';
import { clearPiiFilter } from 'components/PiiGlobalFilterV2/model';
import GridBody from 'components/table/GridBody';
import GridTable from 'components/table/GridTable';
import { RouterLink } from 'components/typography/Link';
import Typo from 'components/typography/Typo';
import { activityStore } from 'models/recentActivity/store';
import { WidgetBlock } from '../WidgetParts';
import { getEmptyRows } from './EmptyRow';
import styles from './index.module.pcss';

interface Props {
	title: string;
	dataLength: number;
	children: ReactNode;
	seeAllLink: string;
	dataTest?: string;
}

function TopAsset({ title, dataLength, children, seeAllLink, dataTest }: Props) {
	const emptyRows = useMemo(() => getEmptyRows(dataLength), [dataLength]);
	const hasTraffic = useStore(activityStore);

	function clearGlobalFilters() {
		// TODO this can be removed when Global filters are implemented with pageParams
		clearPiiFilter();
	}

	return (
		<WidgetBlock className={styles.block}>
			<div className={styles.subHeader}>
				<Typo variant="D/Medium/Body-S">{title}</Typo>

				<Typo variant="D/Medium/Body-S" color="secondary">
					<RouterLink inherit to={seeAllLink} onClick={clearGlobalFilters} setPageParams>
						See all
					</RouterLink>
				</Typo>
			</div>

			<GridTable
				className={cn({ [commonStyles.skeletonMode]: !hasTraffic }, styles.table)}
				data-test={dataTest}
			>
				<GridBody className={styles.bodyTable} data-test={dataTest}>
					{children}

					{emptyRows}
				</GridBody>
			</GridTable>
		</WidgetBlock>
	);
}

export default TopAsset;
