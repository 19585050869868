import { createBrowserHistory } from 'history';
import dropSession from 'services/dropSession';

const STORAGE_KEY = 'svrn-router-history';

const history = createBrowserHistory({
	// empty callback to block the default browser prompt
	getUserConfirmation() {},
});

// If opened in new tab, or pasted url in browser and pressed enter:
let firstPageLoad = true;
// If came to current location with Forward/Back buttons (or corresponding js API):
let implicitNavigation = false;

const routerHistoryJson = sessionStorage.getItem(STORAGE_KEY);

const routerHistory = routerHistoryJson
	? JSON.parse(routerHistoryJson)
	: {
			items: [
				{
					pathname: history.location.pathname,
					search: history.location.search,
					key: history.location.key,
					state: history.location.state,
				},
			],
			cursor: 0,
	  };

history.listen((location, action) => {
	firstPageLoad = false;

	switch (action) {
		case 'PUSH': {
			const loc = {
				pathname: location.pathname,
				search: location.search,
				key: location.key,
				state: location.state,
			};

			routerHistory.items = routerHistory.items.slice(0, routerHistory.cursor + 1).concat(loc);
			routerHistory.cursor = routerHistory.items.length - 1;

			implicitNavigation = false;

			break;
		}
		case 'REPLACE': {
			const loc = {
				pathname: location.pathname,
				search: location.search,
				key: location.key,
				state: location.state,
			};

			routerHistory.items[routerHistory.cursor] = loc;

			break;
		}
		case 'POP': {
			const newCursor = routerHistory.items.findIndex(
				(item: { key: unknown }) => item.key === location.key
			);

			if (newCursor === -1) {
				console.error(
					'history.ts: cursor not found on POP history action. Will probably lead to inconsistent history.'
				);
				break;
			}

			routerHistory.cursor = newCursor;

			implicitNavigation = true;

			break;
		}
	}

	sessionStorage.setItem(STORAGE_KEY, JSON.stringify(routerHistory));
});

//
// Unblock history if session is dropped, in particular to enable force-redirects (to sign-in page etc).
//

dropSession.watch(() => history.block(() => {}));

//
// Helper functions
//

function getPreviousPath() {
	const prevIdx = routerHistory.cursor - 1;
	if (prevIdx < 0) {
		return null;
	}

	return routerHistory.items[prevIdx].pathname;
}

function isFirstPageLoad() {
	return firstPageLoad;
}

function isImplicitNavigation() {
	return implicitNavigation;
}

function getHistoryDiff(historyKey?: string) {
	const index = routerHistory.items.findIndex(({ key }: { key: string }) => key === historyKey);

	return index !== -1 ? routerHistory.cursor - index : null;
}

function goBackByDefault(path: string) {
	if (getPreviousPath() === path) {
		history.goBack();
	} else {
		history.replace(path);
	}
}

export default history;
export { isFirstPageLoad, isImplicitNavigation, getPreviousPath, getHistoryDiff, goBackByDefault };
