import { Redirect, Route, Switch } from 'react-router-dom';
import { PATHS } from 'services/router';
import { AccountCreate } from 'views/Sensors/AWS/AccountCreate';
import { AccountList } from 'views/Sensors/AWS/AccountList';
import { CreateSensor } from 'views/Sensors/CreateSensor';
import { SensorsDAR } from 'views/Sensors/DataAtRest';
import { SensorsDIM } from 'views/Sensors/DataInMotion';

function SensorsRoute() {
	return (
		<Switch>
			<Route path={PATHS.SENSORS_DIM} component={SensorsDIM} exact />
			<Route path={PATHS.SENSORS_DIM_CREATE} component={CreateSensor} />

			<Route path={PATHS.SENSORS_DAR} component={SensorsDAR} exact />
			<Route path={PATHS.SENSORS_DAR_CREATE} component={CreateSensor} />

			<Route path={PATHS.ACCOUNT_AWS} component={AccountList} exact />
			<Route path={PATHS.ACCOUNT_AWS_CREATE} component={AccountCreate} />

			<Redirect to={PATHS.SENSORS_DIM} />
		</Switch>
	);
}

export default SensorsRoute;
