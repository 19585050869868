import { get } from 'api/Api';
import { ApiParams } from 'services/api';
import checkResponse from 'services/api/checkResponse';
import { TAssets } from './dto';
import { assetsExtendedJsonExample } from './examples';

async function getAssetsExtended(apiParams: ApiParams, signal?: AbortSignal) {
	const config = signal ? { signal } : {};

	return get<TAssets>('v2/assets-extended', apiParams, config).then(
		checkResponse(assetsExtendedJsonExample)
	);
}

export { getAssetsExtended };
