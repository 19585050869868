import cn from 'classnames';
import { ReactNode } from 'react';
import { DataTypeItem } from 'models/dataTypes/dto';
import { ViewerSize } from '../';
import styles from './index.module.css';
import { SensitivityString } from './SensitivityString';

function hasIntersectionsInSorted(detections: Detection[]) {
	for (let i = 1; i < detections.length; i++) {
		const { column: col1, len: len1 } = detections[i - 1];
		const { column: col2 } = detections[i];

		if (col1 + len1 > col2) return true;
	}

	return false;
}

type Detection = {
	dataType: DataTypeItem['id'];
	column: number;
	len: number;
};

type Props = {
	lineData: {
		line: string;
		detections: Detection[];
	};
	lineWrap?: boolean;
	size?: ViewerSize;
};

function Line(props: Props) {
	const {
		lineData: { line, detections },
		lineWrap = false,
		size = 'M',
	} = props;
	const preserveWhitespaceClass = cn(!lineWrap && styles.preserveWhitespace);

	if (detections.length === 0) {
		return (
			<div>
				<span className={preserveWhitespaceClass}>{line}</span>
			</div>
		);
	}

	const sortedDetections = [...detections].sort((a, b) => a.column - b.column);

	if (hasIntersectionsInSorted(sortedDetections)) {
		console.error('Intersections in line detections');
		return (
			<div>
				<span className={preserveWhitespaceClass}>{line}</span>
			</div>
		);
	}

	let lineCursor = 0;

	const result = sortedDetections.reduce((acc, detection, i) => {
		const { column, len } = detection;

		const leftPart = line.slice(lineCursor, column);
		const middlePart = line.slice(column, column + len);
		lineCursor = column + len;

		acc.push(
			<span className={preserveWhitespaceClass} key={i * 2}>
				{leftPart}
			</span>
		);
		acc.push(
			<span className={preserveWhitespaceClass} key={i * 2 + 1}>
				<SensitivityString dataType={detection.dataType} size={size}>
					{middlePart}
				</SensitivityString>
			</span>
		);

		return acc;
	}, [] as ReactNode[]);

	if (lineCursor < line.length) {
		result.push(
			<span className={preserveWhitespaceClass} key={-1}>
				{line.slice(lineCursor)}
			</span>
		);
	}

	return <div>{result}</div>;
}

export type { Detection };
export { Line };
