import {
	DatabaseDetails,
	DatabaseInstanceItem,
	DatabaseInstanceList,
	DatabaseItem,
	DatabaseList,
	DatabaseTableItem,
	DatabaseTableList,
	DatabaseTableSample,
} from './dto';

const databaseInstanceItemExample: DatabaseInstanceItem = {
	id: 5,
	cluster_id: 2,
	name: 'Production',
	description: 'Description',
	owner: 'Owner',
	region: 'eu-west-1',
	type: 'postgreSQL',
	created_at: 1649289600000,
	last_checked: 1638267989332,
	databases_count: 2,
	size: 697451079,
	has_tls: true,
	sensitivity: 'High',
	data_types: [1, 2],
	status: '',
	is_crawler_attached: true,
};

const databaseInstanceListExample: DatabaseInstanceList = {
	instances: [databaseInstanceItemExample],
	total: 1,
};

const databaseItemExample: DatabaseItem = {
	id: 15,
	name: 'Database',
	last_checked: 1638267989332,
	tables_count: 38,
	size: 697451079,
	sensitivity: 'High',
	data_types: [3, 4],
	instance_id: 5,
	status: 'unsupported_format',
};

const databaseListExample: DatabaseList = {
	databases: [databaseItemExample],
	total: 1,
};

const databaseDetailsExample: DatabaseDetails = {
	...databaseItemExample,
	description: 'Description',
	owner: 'Owner',
};

const databaseTableItemExample: DatabaseTableItem = {
	id: 115,
	name: 'Database',
	rows_count: 123748,
	size: 697451079,
	sensitivity: 'High',
	data_types: [3, 4],
	last_checked: 1638267989332,
	status: 'permission_denied',
};

const databaseTableListExample: DatabaseTableList = {
	tables: [databaseTableItemExample],
	total: 1,
};

const databaseTableExample: DatabaseTableSample = {
	database_id: 377,
	database_name: 'admin',
	instance_id: 76,
	instance_name: 'svrn',
	last_checked: 1714396108546,
	samples: [
		{
			name: '',
			sample:
				'Index,Email,Name,Info\n1,****@****.****,****-****@****.****,**** **** ****\n,****,,\n',
			data_fields: [
				{
					data_type: 1,
					locator_column: 0,
					locator_line: 1,
					locator_path: 'Index',
				},
				{
					data_type: 4,
					locator_column: 1,
					locator_line: 1,
					locator_path: 'Email',
				},
			],
		},
	],
	table_id: 3824,
	table_name: 'customer_schema',
	type: 'postgres',
};

export {
	databaseInstanceItemExample,
	databaseInstanceListExample,
	databaseListExample,
	databaseDetailsExample,
	databaseTableListExample,
	databaseTableExample,
};
