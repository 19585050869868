import { get } from 'api/Api';
import { getAssetsFx } from 'models/assets/effects';
import { assetsMap } from 'models/assets/store';
import { getAssetGroupsFx } from 'models/assetsGroups/effects';
import { assetGroupsMap } from 'models/assetsGroups/store';
import checkResponse from 'services/api/checkResponse';
import {
	DataFlowDetailsJson,
	DataFlowDetailsParams,
	DataFlowDetailsQueryParam,
	DataFlowDetailsTableItem,
} from './dto';
import { dataFlowDetailsJsonExample } from './examples';

async function getDataFlowDetails({
	group1Id,
	group2Id,
	dataTypes,
	flowDirection,
	streamDirection,
	offset,
	limit,
}: DataFlowDetailsParams) {
	const queryParam: DataFlowDetailsQueryParam = {
		limit,
		offset,
		direction: streamDirection,
	};

	// If there's no data types, we don't send 'data-types' query param
	if (dataTypes.length > 0) {
		queryParam['data-types'] = String(dataTypes);
	}

	return get<DataFlowDetailsJson>(
		`v2/groups/${group1Id}/dataflows/${group2Id}/${flowDirection}`,
		queryParam
	)
		.then(checkResponse(dataFlowDetailsJsonExample))
		.then(async (data) => {
			await Promise.all([getAssetsFx, getAssetGroupsFx]);

			const assets = assetsMap.getState();
			const groups = assetGroupsMap.getState();
			const group1 = groups[group1Id];
			const group2 = groups[group2Id];

			return {
				...data,
				group1_data: group1,
				group2_data: group2,
				flow_direction: flowDirection,
				streams: data.streams
					.map((stream): DataFlowDetailsTableItem => {
						return {
							...stream,
							src_asset_data: assets[stream.src_asset],
							dst_asset_data: assets[stream.dst_asset],
						};
					})
					// Our limit param higher for 1 for checking is there more data or not.
					// That's why we send data less for 1 than limit
					.slice(0, limit - 1),
				more_data: data.streams.length === limit,
			};
		});
}

export { getDataFlowDetails };
