import { AssetEventJson, DIMNewDataTypeEventJson, DiscoveryCompleteJson, EventsJson } from './dto';

const assetEventJsonExample: AssetEventJson = {
	id: 1,
	type: 'new_internal_sender',
	created_at: 1649289600000,
	sensitivity: 'High',
	data_types: [3, 4],

	endpoint_id: 12277264, // For soveren-agent-testing@soveren.io
	hostname: 'loadtest.svrn.dev',
	url: '/api/MjE3NDYK/[lang]/eng/alfa',
	method: 'POST',
	protocol: 'http',
	event_regarding: 'requestor',
	direction: 'request',
	object_type: 'Service',
	requestor: {
		id: 7235861,
		type: 'internal',
		name: 'go-echo',
		namespace: 'soveren-agent-testing',
		is_external: false,
		groups: [20230302],
		cluster_name: 'Agent',
	},
	responder: {
		id: 7242089,
		type: 'robots',
		name: 'robots',
		namespace: '',
		is_external: true,
		groups: [20230303],
		cluster_name: 'Agent',
	},
	is_mesh_network: true,
	is_encrypted: false,
};

const anotherAssetEventJsonExample: AssetEventJson = {
	id: 2,
	type: 'updated_external_receiver',
	created_at: 1649289600000,
	sensitivity: 'High',
	data_types: [3, 4],

	endpoint_id: 12277264, // For soveren-agent-testing@soveren.io
	hostname: 'loadtest.svrn.dev',
	url: '/api/MjE3NDYK/[lang]/eng/alfa',
	method: 'POST',
	protocol: 'http',
	event_regarding: 'responder',
	direction: 'request',
	object_type: 'Service',
	requestor: {
		id: 7235861,
		type: 'internal',
		name: 'go-echo',
		namespace: 'soveren-agent-testing',
		is_external: false,
		groups: [20230302],
		cluster_name: 'Agent',
	},
	responder: {
		id: 7242089,
		type: 'robots',
		name: 'robots',
		namespace: '',
		is_external: true,
		groups: [20230303],
		cluster_name: 'Agent',
	},
	is_mesh_network: true,
	is_encrypted: false,
};

const dataTypeEventJsonExample: DIMNewDataTypeEventJson = {
	id: 9925146,
	type: 'new_data_type',
	created_at: 1654707653934,
	sensitivity: 'Medium',
	data_types: [3],
	custom_assets: [{ id: 100, name: 'svrn' }],
	object_type: 'Service',
	event_regarding: 'requestor',
	endpoint_id: 12277264, // For soveren-agent-testing@soveren.io
	hostname: 'loadtest.svrn.dev',
	url: '/api/MjE3NDYK/[lang]/eng/alfa',
	method: 'POST',
	protocol: 'http',
	json_path: '$.phoneNumber',
	direction: 'request',
	requestor: {
		id: 7235861,
		type: 'internal',
		name: 'go-echo',
		namespace: 'soveren-agent-testing',
		is_external: false,
		groups: [20230302],
		cluster_name: 'Agent',
	},
	responder: {
		id: 7242089,
		type: 'robots',
		name: 'robots',
		namespace: '',
		is_external: true,
		groups: [20230303],
		cluster_name: 'Agent',
	},
	is_mesh_network: true,
	is_encrypted: false,
};

const simpleEventJsonExample: DiscoveryCompleteJson = {
	id: 9925140,
	type: 'discovery_complete',
	created_at: 1654707653934,
};

const eventsJsonExample: EventsJson = {
	log_events: [
		dataTypeEventJsonExample,
		assetEventJsonExample,
		anotherAssetEventJsonExample,
		simpleEventJsonExample,
	],
	total_filtered: 3,
};

export {
	assetEventJsonExample,
	anotherAssetEventJsonExample,
	dataTypeEventJsonExample,
	eventsJsonExample,
};
