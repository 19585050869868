import { getJsonLocation } from 'views/common/SampleViewer/helpers/jsonPath';

const memo = {
	jsonString: '{}',
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	json: {} as any,
};

export function isMissing(jsonString: string | undefined, path: string) {
	if (typeof jsonString === 'undefined') return true;

	const jsonLocation = getJsonLocation(path);

	try {
		if (jsonString !== memo.jsonString) {
			memo.json = JSON.parse(jsonString);
			memo.jsonString = jsonString; // Order is important in case of parse error
		}

		let subjson = memo.json;

		for (const part of jsonLocation) {
			if (part in subjson) {
				subjson = subjson[part];
			} else {
				return true;
			}
		}
	} catch (e) {
		// Expected e.g. when path is `$.name.first`, and json is `{ name: "***" }`
		console.info(e);
	}

	return false;
}

export { getJsonLocation };
