import { get } from 'api/Api';
import checkResponse from 'services/api/checkResponse';
import { S3RegionList } from './dto';
import { s3RegionListExample } from './examples';

const API_URL = 'v1/s3/aws-regions';

export async function getS3Regions() {
	return get<S3RegionList>(API_URL).then(checkResponse(s3RegionListExample));
}
