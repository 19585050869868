import { useStore } from 'effector-react';
import { useEffect, useMemo, useState } from 'react';
import { generatePath } from 'react-router';
import { useHistory, useParams } from 'react-router-dom';
import Badge from 'components/Badge';
import Icon from 'components/Icon';
import Preloader from 'components/Preloader';
import { TabLink, TabPanel, Tabs } from 'components/Tabs';
import Typo from 'components/typography/Typo';
import { databasesSteps, dataStoragesSteps } from 'layouts/AuthorizedWithLeftMenu/Breadcrumbs';
import Header from 'layouts/AuthorizedWithLeftMenu/Header';
import { getDatabaseDetails } from 'models/databases/api';
import { DatabaseDetails } from 'models/databases/dto';
import { databaseInstancesById } from 'models/databases/store';
import { toLocaleString } from 'services/numbers';
import { PATHS } from 'services/router';
import { AutomaticDiscoveryTooltip } from 'views/common/AutomaticDiscovery';
import styles from './index.module.css';
import { Summary } from './Summary';
import { TablesTable } from './TablesTable';

function DatabaseError() {
	const params: {
		id: string;
	} = useParams();

	return (
		<>
			<Header
				breadcrumbProps={{
					steps: dataStoragesSteps,
					finalStep: 'Not found',
				}}
				titleBlock="Error has occurred while loading database info"
			/>

			<Typo variant="D/Regular/Body-S" color="secondary">
				Database with id={params.id} not found or was deleted.
			</Typo>
		</>
	);
}

function DatabaseItemWrapper() {
	const [db, setDB] = useState<DatabaseDetails | null>(null);
	const [isLoading, setLoading] = useState(true);

	const params: { id: string } = useParams();

	useEffect(() => {
		getDatabaseDetails(Number(params.id))
			.then(setDB)
			.finally(() => setLoading(false));
	}, [params.id]);

	return (
		<Preloader isLoading={isLoading}>{db ? <DatabaseItem db={db} /> : <DatabaseError />}</Preloader>
	);
}

const tabLabels = {
	summary: 'Summary',
	tables: 'Tables',
};

function DatabaseItem({ db }: { db: DatabaseDetails }) {
	const history = useHistory();
	const params: {
		tab?: 'summary';
	} = useParams();
	const databaseInstances = useStore(databaseInstancesById);
	const instance = useMemo(
		() => databaseInstances[db.instance_id],
		[db.instance_id, databaseInstances]
	);

	const tabLinks = useMemo(
		() => ({
			summary: generatePath(PATHS.DATABASES_ITEM, {
				id: db.id,
				tab: 'summary',
			}),
			tables: generatePath(PATHS.DATABASES_ITEM, {
				id: db.id,
				tab: 'tables',
			}),
		}),
		[db.id]
	);

	// Set default tab, if not specified.
	useEffect(function () {
		if (!params.tab) {
			const path = generatePath(PATHS.DATABASES_ITEM, {
				...params,
				tab: 'summary',
			});
			history.replace(path + history.location.search);
		}
	}, []);

	const isScanned = !!db.last_checked;

	return (
		<>
			<Header
				showSensitiveSwitcher
				breadcrumbProps={{
					steps: [
						...databasesSteps,
						{
							path: generatePath(PATHS.DATABASES_ITEM, {
								id: db.id,
								tab: 'summary',
							}),
							name: db.name,
						},
					],
					finalStep: tabLabels[params.tab || 'summary'],
				}}
				titleBlock={
					<div className={styles.headerContainer}>
						<Typo variant="D/Medium/H400-Page-Title" className={styles.title}>
							{!instance.is_crawler_attached && (
								<Icon name="Radar/Filled" size={24} className={styles.iconDiscovery} />
							)}
							{db.name}
						</Typo>

						<Typo variant="D/Regular/Body-S" color="secondary">
							{instance.name}
						</Typo>
					</div>
				}
			/>

			<Tabs value={tabLinks[params.tab || 'summary']}>
				<TabLink value={tabLinks['summary']} label={tabLabels['summary']} />

				{instance.is_crawler_attached ? (
					<TabLink
						value={tabLinks['tables']}
						label={
							<>
								{tabLabels['tables']}
								<Badge
									badgeContent={isScanned ? toLocaleString(db.tables_count) : 'N/A'}
									data-test="database-tables-count"
								/>
							</>
						}
					/>
				) : (
					<AutomaticDiscoveryTooltip>
						<TabLink
							disabled
							value={tabLinks['tables']}
							label={
								<>
									{tabLabels['tables']}
									<Badge badgeContent="N/A" data-test="database-tables-count" />
								</>
							}
						/>
					</AutomaticDiscoveryTooltip>
				)}
			</Tabs>

			{/* TABS CONTENT */}
			<TabPanel value={params.tab} index="summary">
				<Summary db={db} instance={instance} />
			</TabPanel>

			<TabPanel value={params.tab} index="tables">
				<TablesTable databaseId={db.id} />
			</TabPanel>
		</>
	);
}

export { DatabaseItemWrapper as DatabaseItem };
