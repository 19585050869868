const DASH_SIGN = '—';

function getCapitalNotationString(keyName: string) {
	const stringWithoutDash = keyName.toLowerCase().replace('_', ' ');

	return stringWithoutDash.charAt(0).toUpperCase() + stringWithoutDash.slice(1);
}

function pluralize(word: string, count: number) {
	return count === 1 ? word : `${word}s`;
}

function cutAndInsertDots(word: string, length: number) {
	if (word.length <= length) {
		return word;
	}

	return `${word.slice(0, Math.ceil(length / 2 - 1))}…${word.slice(-1 * Math.floor(length / 2))}`;
}

type TParam = (string | string[] | undefined)[];
const dividerDot = ' • ';

function getStringDividedByDot(...params: TParam): string {
	const preparedParam: TParam = [];

	params.forEach((p) => {
		if (!p || !p.length) return;

		if (Array.isArray(p)) {
			preparedParam.push(p.join(', '));
		} else {
			preparedParam.push(p);
		}
	});

	return preparedParam.join(dividerDot);
}

export {
	getCapitalNotationString,
	pluralize,
	getStringDividedByDot,
	dividerDot,
	cutAndInsertDots,
	DASH_SIGN,
};
