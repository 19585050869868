import cn from 'classnames';
import Chip from 'components/Chip';
import { settingSensitivity } from 'components/SensitivityChip';
import { DataTypeItem } from 'models/dataTypes/dto';
import { useSensitivityData } from 'models/dataTypes/hooks';
import { ViewerSize } from '../../';
import styles from './index.module.css';

type Props = {
	dataType: DataTypeItem['id'];
	children: string;
	onClick?: () => void; // TODO
	isFP?: boolean;
	isCorrected?: boolean; // TODO
	size?: ViewerSize;
};

function SensitivityString(props: Props) {
	const { dataType, children, isFP = false, size = 'M' } = props;
	const { sensitivityLevel, piiTypeName } = useSensitivityData(dataType, isFP);

	return (
		<span
			className={cn(styles[sensitivityLevel], styles.container, styles[size])}
			data-test="sensitivity-string-container"
		>
			<span className={styles.string} data-test={dataType}>
				{children}
			</span>

			<Chip
				size={size === 'S' ? 'XXS' : 'extraSmall'}
				className={styles.chip}
				label={piiTypeName}
				theme={settingSensitivity[sensitivityLevel]}
				data-test={`sensitivity-chip-${sensitivityLevel}`}
			/>
		</span>
	);
}

export { SensitivityString };
