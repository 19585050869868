import Icon from 'components/Icon';
import styles from './index.module.css';

const TLS_ICON_SIZE = 40;
const OFFSET_X = 28;
const OFFSET_Y = 32;

type Props = {
	x: number;
	y: number;
	background: string;
};

const TLSIcon = ({ x, y, background }: Props) => {
	return (
		<div
			className={styles.tlsIconWrapper}
			style={{
				left: x - OFFSET_X,
				top: y - OFFSET_Y,
				background,
			}}
		>
			<Icon name="lock" size={TLS_ICON_SIZE} className={styles.tlsIcon} />
		</div>
	);
};

export { TLSIcon };
