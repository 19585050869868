import { createEffect } from 'effector';
import { getDatabaseInstanceList } from './api';
import { DatabaseInstanceList } from './dto';

/* API */

export const getDatabaseInstancesFx = createEffect<void, DatabaseInstanceList>();

/* IMPLEMENTATION */
getDatabaseInstancesFx.use(getDatabaseInstanceList);
