import { GatewayJson, GatewaysJson } from './dto';

const offlineGatewayJsonExample: GatewayJson = {
	id: 1,
	name: 'New gateway (1)',
	cluster_name: 'New gateway (1)',
	status: 'error',
	text: 'Last message from Detector at 2022-02-17 11:27:46 UTC',
	token: '00000000-0000-0000-0000-000000000000',
	versions: {
		digger: 'v1.2.3',
		interceptor: 'v4.5.6',
		detectionTool: 'N/A',
	},
	created_at: 1698351687739,
	updated_at: 1698351687739,
	is_deleted: false,
};

const onlineGatewayJsonExample: GatewayJson = {
	id: 10,
	name: 'boxy_e2e',
	cluster_name: 'boxy_e2e',
	status: 'ok',
	token: '00000000-0000-0000-0000-000000000000',
	versions: {
		digger: 'N/A',
		interceptor: 'N/A',
		detectionTool: 'v7.8.9',
	},
	created_at: 1698351687739,
	updated_at: 1698351687739,
	is_deleted: false,
};

const gatewaysJsonExample: GatewaysJson = [offlineGatewayJsonExample, onlineGatewayJsonExample];

export { offlineGatewayJsonExample, onlineGatewayJsonExample, gatewaysJsonExample };
