import cn from 'classnames';
import { ReactNode } from 'react';
import rowStyles from 'components/table/GridRow/index.module.pcss';
import styles from './index.module.pcss';

interface Props {
	align?: CellAlign;
	children: ReactNode;
	className?: string;
	dataTest?: string;
	head?: boolean;
	verticalAlign?: CellVerticalAlign;
	withoutBlur?: boolean;
}

export type CellAlign = 'left' | 'center' | 'right' | 'justify';
export type CellVerticalAlign = 'top' | 'middle';

function GridCell({
	children,
	head,
	className,
	dataTest,
	align = 'left',
	verticalAlign = 'top',
	withoutBlur,
}: Props) {
	return (
		<div
			className={cn(
				className,
				rowStyles.cell,
				styles.cell,
				withoutBlur && styles.cellWithoutBlur,
				styles[align],
				styles[verticalAlign],
				{
					[styles.head]: head,
				}
			)}
			data-test={dataTest}
		>
			{children}
		</div>
	);
}

export default GridCell;
