import cn from 'classnames';
import Typo from 'components/typography/Typo';
import { DataFlowDetailsTable } from 'models/assetDataFlowDetailsV2/dto';
import { AssetGroupListItem } from 'models/assetsGroups/dto';
import { TDataDirectionType } from 'models/common/dto';
import DataFlowIcon from 'views/SuperAssets/DataFlowIcon';
import mainStyles from '../index.module.css';
import DataTest from './index.dataTest';
import styles from './index.module.css';

interface Props {
	group1: AssetGroupListItem;
	group2: AssetGroupListItem;
	direction: TDataDirectionType;
	disabled: boolean;
	flowDirection: DataFlowDetailsTable['flow_direction'];
	changeDirection: (direction: DataFlowDetailsTable['flow_direction']) => void;
}

function DataFlowDetailsHeader({
	group1,
	group2,
	direction,
	flowDirection,
	changeDirection,
	disabled,
}: Props) {
	return (
		<div className={styles.container}>
			<div className={styles.flex}>
				<div className={styles.groupContainer}>
					<Typo variant="D/Medium/H100-Header" className={styles.overflow}>
						{group1.name}
					</Typo>
					<Typo variant="D/Regular/Meta" color="secondary">
						GROUP 1
					</Typo>
				</div>
				<DataFlowIcon direction={direction} flowDirection={flowDirection} className={styles.icon} />
				<div className={styles.groupContainer}>
					<Typo variant="D/Medium/H100-Header" className={styles.overflow}>
						{group2.name}
					</Typo>
					<Typo variant="D/Regular/Meta" color="secondary">
						GROUP 2
					</Typo>
				</div>
			</div>

			<Typo variant="D/Medium/Body-S" className={cn({ [mainStyles.disabled]: disabled })}>
				<a
					onClick={() => changeDirection(flowDirection === 'outgoing' ? 'incoming' : 'outgoing')}
					data-test={DataTest['data-flow-switch-direction']}
				>
					Switch direction
				</a>
			</Typo>
		</div>
	);
}

export default DataFlowDetailsHeader;
