import cn from 'classnames';
import { generatePath } from 'react-router';
import { Link } from 'react-router-dom';
import Label from 'components/Label';
import Switch from 'components/Switch';
import Typo from 'components/typography/Typo';
import { policiesSteps } from 'layouts/AuthorizedWithLeftMenu/Breadcrumbs';
import Header from 'layouts/AuthorizedWithLeftMenu/Header';
import { PATHS } from 'services/router';
import styles from './index.module.css';

export const PolicyFormHeader = ({
	isNew,
	deletePolicy,
	duplicatePolicy,
	tracking,
	switchTracking,
	name,
}: {
	isNew: boolean;
	deletePolicy: () => void;
	duplicatePolicy: () => void;
	tracking: boolean;
	switchTracking: () => void;
	name: string;
}) => {
	return (
		<Header
			breadcrumbProps={{
				steps: policiesSteps,
				finalStep: isNew ? 'New policy' : name,
			}}
			titleBlock={`Policy ${isNew ? 'creator' : 'editor'}`}
			topRightBlock={
				!isNew ? (
					<div>
						<div className={styles.buttons}>
							<Label dataTest="policy-form-violation-tracking" className={styles.switcher}>
								<Switch checked={tracking} onChange={switchTracking} />
								Violation tracking
							</Label>
						</div>
						<div className={styles.buttons}>
							<Typo
								variant="D/Medium/Body-S"
								className={cn(styles.button, styles.duplicatePolicy)}
								onClick={duplicatePolicy}
								data-test="policy-form-duplicate-button"
							>
								<Link
									target="_blank"
									rel="noopener"
									to={{ pathname: generatePath(PATHS.POLICY_ITEM, { id: 'new' }) }}
								>
									Duplicate policy
								</Link>
							</Typo>

							<Typo
								variant="D/Medium/Body-S"
								className={cn(styles.button, styles.deletePolicy)}
								onClick={deletePolicy}
								data-test="policy-form-delete-button"
							>
								Delete policy
							</Typo>
						</div>
					</div>
				) : null
			}
		/>
	);
};
