import { createStore } from 'effector';
import { S3BucketItem, S3BucketList, S3BucketStatus } from './dto';
import {
	getS3BucketByIdFx,
	getS3BucketsFx,
	getS3BucketStatusFx,
	updateS3BucketFx,
} from './effects';

// S3 Bucket Store

const s3BucketsStore = createStore<S3BucketList>({
	buckets: [],
	total: 0,
});

s3BucketsStore.on(getS3BucketsFx.doneData, (state: S3BucketList, data: S3BucketList) => data);

s3BucketsStore.on(
	[updateS3BucketFx.doneData, getS3BucketByIdFx.doneData],
	(state: S3BucketList, data: S3BucketItem) => {
		return {
			...state,
			buckets: state.buckets.find((bucket) => bucket.id === data.id)
				? state.buckets.map((bucket) => (bucket.id === data.id ? data : bucket))
				: [...state.buckets, data],
		};
	}
);

const s3BucketsList = s3BucketsStore.map(({ buckets }) =>
	buckets.sort((a, b) => a.name.localeCompare(b.name))
);

const s3BucketsById = s3BucketsStore.map(({ buckets }) => {
	return Object.fromEntries(buckets.map((bucket) => [bucket.id, bucket]));
});

// S3 Bucket Status Store

const s3BucketsStatusStore = createStore<{ [key: S3BucketItem['id']]: S3BucketStatus }>({});
s3BucketsStatusStore.on(getS3BucketStatusFx.doneData, (state, data) => ({
	...state,
	[data.id]: data,
}));

// S3 Data Flows Store

export { s3BucketsList, s3BucketsById, s3BucketsStatusStore };
