import { generatePath } from 'react-router';
import Typo from 'components/typography/Typo';
import { databasesSteps } from 'layouts/AuthorizedWithLeftMenu/Breadcrumbs';
import Header from 'layouts/AuthorizedWithLeftMenu/Header';
import { DatabaseInstanceBase, DatabaseTableSample } from 'models/databases/dto';
import { PATHS } from 'services/router';
import { getStringDividedByDot } from 'services/strings';
import styles from './index.module.css';

type Props = {
	sample: Pick<DatabaseTableSample, 'database_id' | 'database_name' | 'table_name'> | null;
	instance: DatabaseInstanceBase | null;
};

function DatabaseSampleHeader({ sample, instance }: Props) {
	if (!sample) return null;

	const { table_name, database_id, database_name } = sample;

	return (
		<Header
			showSensitiveSwitcher
			breadcrumbProps={{
				steps: [
					...databasesSteps,
					{
						path: generatePath(PATHS.DATABASES_ITEM, {
							id: database_id,
							tab: 'summary',
						}),
						name: database_name,
					},
					{
						path: generatePath(PATHS.DATABASES_ITEM, {
							id: database_id,
							tab: 'tables',
						}),
						name: 'Tables',
					},
				],
				finalStep: table_name,
			}}
			titleBlock={
				<div className={styles.headerContainer}>
					<Typo variant="D/Medium/H400-Page-Title">{table_name}</Typo>

					<Typo variant="D/Regular/Body-S" color="secondary">
						{instance && getStringDividedByDot(instance?.name, instance.type)}
					</Typo>
				</div>
			}
		/>
	);
}

export { DatabaseSampleHeader };
