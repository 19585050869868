import { useStore } from 'effector-react';
import { useEffect } from 'react';
import FilterLine from 'components/FilterLine';
import FilterResult from 'components/FilterResult';
import Icon from 'components/Icon';
import LoadMoreButton from 'components/LoadMoreButton';
import {
	clearPiiFilter,
	piiFilterToArray,
	piiFilterWithGroupsStore,
} from 'components/PiiGlobalFilterV2/model';
import Preloader from 'components/Preloader';
import ResetFilters from 'components/ResetFilters';
import EnhancedTableHead, { TOrder } from 'components/table/EnhancedTableHead';
import GridBody from 'components/table/GridBody';
import GridTable from 'components/table/GridTable';
import Typo from 'components/typography/Typo';
import { S3ClusterItem } from 'models/s3BucketCluster/dto';
import { s3ClusterModel } from 'models/s3BucketCluster/model';
import { S3BucketItem } from 'models/s3Buckets/dto';
import { s3BucketsStatusStore } from 'models/s3Buckets/store';
import { PageParamsConfig, usePageParams } from 'services/pageParams';
import { S3BucketHeader } from '../S3BucketHeader';
import { DirectoryClusterItem } from './DirectoryClusterItem';
import { FileClusterItem } from './FileClusterItem';
import styles from './index.module.css';
import tableConfig from './tableConfig';

const pageConfig = {
	sort: {
		type: 'sort',
		persistence: 'session',
	},
	'data-types': {
		type: 'numberArray',
		persistence: 'session', // TODO implement 'global' in actuality
	},
	nonempty: {
		type: 'boolean',
		persistence: 'session', // TODO implement 'global' in actuality
	},
} satisfies PageParamsConfig;

type Props = { bucket: S3BucketItem };

function S3BucketFiles(props: Props) {
	const { bucket } = props;

	const filesStore = useStore(s3ClusterModel.store);
	const piiFilterWithGroups = useStore(piiFilterWithGroupsStore);

	const [pageParams, setPageParams] = usePageParams(pageConfig, 's3BucketClusters');

	useEffect(() => {
		setPageParams({
			...pageParams,
			'data-types': piiFilterWithGroups.dataTypes,
			nonempty: piiFilterWithGroups.nonEmpty,
		});
	}, [piiFilterWithGroups]);

	const s3BucketsStatus = useStore(s3BucketsStatusStore);
	const bucketStatus = s3BucketsStatus[bucket.id] || { id: -1, objects_count: 0, is_scanned: true };

	useEffect(() => {
		const { sort } = pageParams;

		const dataTypes = piiFilterToArray(piiFilterWithGroups);
		const paramsForFx = {
			id: bucket.id,
			sort: { orderBy: sort.value as keyof S3ClusterItem, order: sort.operator },
			'data-types': dataTypes,
		};

		s3ClusterModel.fetchFx(paramsForFx);
	}, [pageParams, bucket.id]);

	// Clean up model on unmount
	useEffect(() => {
		return () => {
			s3ClusterModel.resetFx();
		};
	}, []);

	function onSortUpdate(property: keyof S3ClusterItem) {
		const { sort } = filesStore.params;

		const operator: TOrder = sort.orderBy === property && sort.order === 'asc' ? 'desc' : 'asc';
		const newParams = { sort: { operator, value: property } };

		setPageParams({ ...pageParams, ...newParams });
	}

	function onResetFilters() {
		const newParams = {
			'data-types': [],
		};

		setPageParams({ ...pageParams, ...newParams });
		clearPiiFilter();
	}

	const { status, data, hasMoreData, total, total_filtered, params } = filesStore;
	const hasFilter = params['data-types'].length > 0 && params['data-types'][0] !== 'nonempty';

	const showNoFiles = bucketStatus.is_scanned && !hasFilter && data.length === 0;
	const showResetFilter = bucketStatus.is_scanned && hasFilter && data.length === 0;
	const showInProgress = !bucketStatus.is_scanned;

	return (
		<>
			<S3BucketHeader tab="files" bucket={bucket} />

			<FilterLine
				config={['dataTypes']}
				values={{
					dataTypes: pageParams['data-types'],
				}}
				onChange={() => {}}
			/>

			<FilterResult
				entityLabel="cluster"
				loading={status === 'loading'}
				total={total}
				totalFiltered={total_filtered}
			/>

			<GridTable dataTest="s3-bucket-objects-table">
				<EnhancedTableHead
					config={tableConfig}
					order={params.sort.order}
					orderBy={params.sort.orderBy}
					onRequestSort={onSortUpdate}
					rowClassname={styles.rowContainer}
				/>

				<Preloader isLoading={status === 'initial' || status === 'loading'}>
					<GridBody data-test="s3-bucket-objects-list">
						{showNoFiles && (
							<div className={styles.message}>
								<Typo variant="D/Regular/Body-S" color="secondary">
									No Files {pageParams.nonempty ? 'with sensitive data ' : ''}yet.
								</Typo>
							</div>
						)}

						{showResetFilter && <ResetFilters onReset={onResetFilters} />}

						{showInProgress && (
							<div className={styles.message}>
								<Typo variant="D/Regular/Body-S" color="secondary" className={styles.isScanned}>
									<Icon name="Clock/Filled" size={20} /> We are scanning files. This can take up to
									7 days.
								</Typo>
							</div>
						)}

						{data.map((cluster) =>
							cluster.type === 'directory' ? (
								<DirectoryClusterItem cluster={cluster} bucketId={bucket.id} key={cluster.id} />
							) : (
								<FileClusterItem cluster={cluster} bucketId={bucket.id} key={cluster.id} />
							)
						)}

						<LoadMoreButton
							show={hasMoreData}
							loading={status === 'loadingMore'}
							request={s3ClusterModel.fetchMoreFx}
						/>
					</GridBody>
				</Preloader>
			</GridTable>
		</>
	);
}

export { S3BucketFiles };
