import { useStore } from 'effector-react';
import { useEffect } from 'react';
import FilterLine from 'components/FilterLine';
import FilterResult from 'components/FilterResult';
import LoadMoreButton from 'components/LoadMoreButton';
import NewGatewayBlock from 'components/NewGatewayBlock';
import NoContent from 'components/NoContent';
import {
	clearPiiFilter,
	piiFilterToArray,
	piiFilterWithGroupsStore,
} from 'components/PiiGlobalFilterV2/model';
import Preloader from 'components/Preloader';
import ResetFilters from 'components/ResetFilters';
import EnhancedTableHead, { TOrder } from 'components/table/EnhancedTableHead';
import GridBody from 'components/table/GridBody';
import GridTable from 'components/table/GridTable';
import { AssetConnectionsTableItem } from 'models/assetDataFlows/dto';
import { assetConnectionModel } from 'models/assetDataFlows/model';
import { PageParamsConfig, usePageParams } from 'services/pageParams';
import tableConfig from './config';
import styles from './index.module.css';
import { TableRow } from './TableRow';

const pageConfig = {
	sort: {
		type: 'sort',
		persistence: 'session',
	},
	name: {
		type: 'string',
		persistence: 'session',
	},
	'data-types': {
		type: 'numberArray',
		persistence: 'session', // TODO implement 'global' in actuality
	},
	nonempty: {
		type: 'boolean',
		persistence: 'session', // TODO implement 'global' in actuality
	},
} satisfies PageParamsConfig;

type Props = { assetId: number };

function AssetConnections(props: Props) {
	const state = useStore(assetConnectionModel.store);
	const piiFilterWithGroups = useStore(piiFilterWithGroupsStore);

	const [pageParams, setPageParams] = usePageParams(pageConfig, 'assetConnections');

	useEffect(() => {
		setPageParams({
			...pageParams,
			'data-types': piiFilterWithGroups.dataTypes,
			nonempty: piiFilterWithGroups.nonEmpty,
		});
	}, [piiFilterWithGroups]);

	useEffect(() => {
		const { sort, name } = pageParams;

		const dataTypes = piiFilterToArray(piiFilterWithGroups);
		const paramsForFx = {
			sort: { orderBy: sort.value as keyof AssetConnectionsTableItem, order: sort.operator },
			assetId: props.assetId,
			name,
			'data-types': dataTypes,
		};

		assetConnectionModel.fetchFx(paramsForFx);
	}, [pageParams]);

	// Clean up model on unmount
	useEffect(() => {
		return () => {
			assetConnectionModel.resetFx();
		};
	}, []);

	// That's Table filter, not filters in Filter line.
	function onFilterUpdate(property: keyof AssetConnectionsTableItem, filterText: string) {
		setPageParams({ ...pageParams, name: filterText });
	}

	function onSortUpdate(property: keyof AssetConnectionsTableItem) {
		const { sort } = state.params;

		const operator: TOrder = sort.orderBy === property && sort.order === 'asc' ? 'desc' : 'asc';
		const newParams = { sort: { operator, value: property } };

		setPageParams({ ...pageParams, ...newParams });
	}

	function onResetFilters() {
		const newParams = {
			name: '',
			'data-types': [],
		};

		setPageParams({ ...pageParams, ...newParams });
		clearPiiFilter();
	}

	const { status, data, hasMoreData, params, total_filtered, total } = state;
	const hasFilter =
		(params['data-types'].length > 0 && params['data-types'][0] !== 'nonempty') ||
		pageParams.name !== '';

	return (
		<>
			<FilterLine
				config={['dataTypes']}
				values={{
					dataTypes: pageParams['data-types'],
				}}
				onChange={() => {}}
			/>

			<FilterResult
				entityLabel="connection"
				loading={status === 'loading'}
				total={total}
				totalFiltered={total_filtered}
			/>

			<GridTable data-test="asset-connection-table">
				<EnhancedTableHead
					config={tableConfig}
					order={params.sort.order}
					orderBy={params.sort.orderBy}
					filterBy={{ name: params.name }}
					onRequestSort={onSortUpdate}
					onRequestFilter={onFilterUpdate}
					rowClassname={styles.rowContainer}
				/>

				<Preloader isLoading={status === 'initial' || status === 'loading'}>
					<GridBody data-test="asset-connection-table-list">
						<>
							{data.length ? (
								data.map((assetConnection) => {
									return (
										<TableRow
											assetConnection={assetConnection}
											key={assetConnection.interacts_with}
										/>
									);
								})
							) : hasFilter ? (
								<ResetFilters onReset={onResetFilters} />
							) : (
								<NoContent type="s3Bucket" className={styles.rowContainer}>
									<NewGatewayBlock />
								</NoContent>
							)}

							<LoadMoreButton
								show={hasMoreData}
								loading={status === 'loadingMore'}
								request={assetConnectionModel.fetchMoreFx}
							/>
						</>
					</GridBody>
				</Preloader>
			</GridTable>
		</>
	);
}

export { AssetConnections };
