import { createEffect } from 'effector';
import { createGateway, deleteGateway, getGateways, updateGatewayName } from './api';
import { GatewayJson, GatewaysJson, GatewayUpdateJson } from './dto';

/* API */

const getGatewaysFx = createEffect<void, GatewaysJson>();
const createGatewayFx = createEffect<GatewayUpdateJson, GatewayJson>();
const updateGatewayNameFx = createEffect<GatewayJson, GatewayJson>();
const deleteGatewayFx = createEffect<GatewayJson['id'], GatewayJson['id']>();

/* IMPLEMENTATION */

// Simple direct-to-api effects

getGatewaysFx.use(getGateways);
createGatewayFx.use(createGateway);
updateGatewayNameFx.use(updateGatewayName);
deleteGatewayFx.use(deleteGateway);

export { getGatewaysFx, createGatewayFx, updateGatewayNameFx, deleteGatewayFx };
