import cn from 'classnames';
import { useStore } from 'effector-react';
import { useEffect, useState } from 'react';
import commonStyles from 'assets/styles/index.module.pcss';
import Preloader from 'components/Preloader';
import GridBody from 'components/table/GridBody';
import GridCell from 'components/table/GridCell';
import GridHead from 'components/table/GridHead';
import GridRow from 'components/table/GridRow';
import GridTable from 'components/table/GridTable';
import Typo from 'components/typography/Typo';
import { getDataCombinations } from 'models/dataCombinationsV2/api';
import { DataCombinationsItem } from 'models/dataCombinationsV2/dto';
import { dataTypesLoaded } from 'models/dataTypes/store';
import { activityStore } from 'models/recentActivity/store';
import { WidgetBlock, WidgetHeader } from '../WidgetParts';
import DataCombinationRow from './DataCombinationRow';
import emptyData from './emptyData';
import styles from './index.module.css';

function DataCombinations() {
	const [isLoading, setLoading] = useState(true);
	const [dataCombinations, setDataCombinations] = useState<DataCombinationsItem[]>([]);
	const hasTraffic = useStore(activityStore);
	const dtIsLoaded = useStore(dataTypesLoaded);

	useEffect(() => {
		(async () => {
			if (dtIsLoaded) {
				if (hasTraffic) {
					getDataCombinations().then((res) => {
						setDataCombinations(res.data_combinations.slice(0, 5));
					});
				} else {
					setDataCombinations(emptyData);
				}
			}

			setLoading(false);
		})();
	}, [hasTraffic, dtIsLoaded]);

	return (
		<Preloader isLoading={isLoading}>
			<WidgetHeader header="Most common data type combinations" />
			<WidgetBlock>
				<GridTable className={styles.table}>
					<GridHead>
						<GridRow className={cn(styles.headRow, styles.rowContainer)}>
							<GridCell align="right">
								<Typo variant="D/Medium/Meta-S-CAP" color="secondary">
									Endpoints
								</Typo>
							</GridCell>

							<GridCell>
								<Typo variant="D/Medium/Meta-S-CAP" color="secondary">
									Sensitivity
								</Typo>
							</GridCell>

							<GridCell>
								<Typo variant="D/Medium/Meta-S-CAP" color="secondary">
									Combination
								</Typo>
							</GridCell>
						</GridRow>
					</GridHead>

					<GridBody
						className={cn({ [commonStyles.skeletonMode]: !hasTraffic }, styles.body)}
						data-test="pii-types-list"
					>
						{dataCombinations.map((dataCombination, index) => (
							<DataCombinationRow key={index} {...dataCombination} />
						))}
					</GridBody>
				</GridTable>
			</WidgetBlock>
		</Preloader>
	);
}

export default DataCombinations;
