import Typo from 'components/typography/Typo';
import { endpointsSteps } from 'layouts/AuthorizedWithLeftMenu/Breadcrumbs';
import Header, { HeaderProps } from 'layouts/AuthorizedWithLeftMenu/Header';
import { TitleBlock, TitleBlockProps } from '../SampleHeader';
import styles from './index.module.css';

interface StateProps {
	state: TitleBlockProps | null;
	id: string;
}

function WashedSample({ state, id }: StateProps) {
	let titleBlock: HeaderProps['titleBlock'] = `Data sample with ID ${id}`;

	if (state) {
		titleBlock = <TitleBlock asset={state.asset} sample={state.sample} />;
	}

	return (
		<>
			<Header
				breadcrumbProps={{
					steps: [...endpointsSteps],
					finalStep: 'Sample',
				}}
				titleBlock={titleBlock}
			/>
			<Typo variant="D/Regular/Body-S" color="secondary" className={styles.noSample}>
				No current data sample available, as there has been no
				<br /> traffic to this API endpoint in the last 7 days.
			</Typo>
		</>
	);
}
export { WashedSample };
