import classnames from 'classnames';
import { GatewayJson } from 'models/gateways/dto';
import styles from './index.module.css';
import StatusSvg from './status.svg';

interface Props {
	status: GatewayJson['status'] | 'draft';
	hoverable?: boolean;
}

const iconStyles = {
	ok: styles.success,
	error: styles.failure,
	draft: styles.draft,
};

function GatewayStatusIcon(props: Props) {
	const { status, hoverable = true } = props;
	const iconStyle = iconStyles[status];

	return (
		<div className={classnames(styles.icon, iconStyle, hoverable && styles.hoverable)}>
			<StatusSvg />
		</div>
	);
}

export default GatewayStatusIcon;
