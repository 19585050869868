import cn from 'classnames';
import { MouseEvent, useState } from 'react';
import ButtonIcon from 'components/ButtonIcon';
import Chip from 'components/Chip';
import Icon from 'components/Icon';
import Menu from 'components/Menu';
import MenuItem from 'components/MenuItem';
import { settingSensitivity } from 'components/SensitivityChip';
import Tooltip from 'components/Tooltip';
import { useSensitivityData } from 'models/dataTypes/hooks';
import { PIIMarkJson } from 'models/piiMarks/dto';
import DataTest from './index.dataTest';
import styles from './index.module.pcss';

interface Props {
	piiTypes: PIIMarkJson[];
	onRemove: (id: number) => void;
	onAdd: () => void;
	className?: string;
}

interface ManualChipProps {
	piiType: PIIMarkJson['manual_data_type'];
	onRemove: () => void;
	setActivity: (val: boolean) => void;
}

function ManualChip({ piiType, onRemove, setActivity }: ManualChipProps) {
	const [isChipActive, setChipActivity] = useState(false);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const handleClick = (event: MouseEvent<HTMLDivElement>) => {
		event.preventDefault();
		event.stopPropagation();
		setActivity(true);
		setChipActivity(true);
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setActivity(false);
		setChipActivity(false);
		setAnchorEl(null);
	};

	function handleMenuClick() {
		onRemove();
		handleClose();
	}

	const { piiTypeName, sensitivityLevel } = useSensitivityData(piiType, false);

	return (
		<>
			<Chip
				key={piiType}
				onClick={handleClick}
				size="extraSmall"
				className={cn(styles.chip, { ['activeChip']: anchorEl })}
				label={
					<>
						{piiTypeName}{' '}
						<Icon
							size={16}
							name="triangleDown"
							className={cn(styles.icon, { [styles.activeIcon]: isChipActive })}
						/>
					</>
				}
				theme={settingSensitivity[sensitivityLevel]}
				data-test={DataTest['manual-data-type-chip']}
			/>

			<Menu
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={handleClose}
				className={styles.menuContainer}
				data-test={DataTest['manual-data-type-chip-menu']}
			>
				<MenuItem
					size="small"
					onClick={handleMenuClick}
					data-test={DataTest['manual-data-type-chip-remove-button']}
				>
					Remove data type
				</MenuItem>
			</Menu>
		</>
	);
}

function ManualDataType({ piiTypes, onAdd, onRemove, className }: Props) {
	const [isActive, setActivity] = useState(false);

	return (
		<div
			className={cn(
				styles.container,
				{ [styles.activeContainer]: isActive },
				{ [styles.visibleContainer]: piiTypes.length !== 0 },
				className
			)}
			data-test={DataTest['manual-data-type-container']}
		>
			{piiTypes.length !== 0 && (
				<Tooltip title="Manually assigned" placement="top">
					<span className={styles.mSign} data-test={DataTest['manual-data-type-m-sign']}>
						M
					</span>
				</Tooltip>
			)}

			{piiTypes.map((piiType) => {
				return (
					<ManualChip
						piiType={piiType.manual_data_type}
						key={piiType.id}
						onRemove={() => onRemove(piiType.id)}
						setActivity={setActivity}
					/>
				);
			})}

			<Tooltip title="Assign data type" placement="top">
				<ButtonIcon
					className={styles.plusButton}
					dataTest={DataTest['manual-data-type-add-button']}
					icon="Add/Regular"
					onClick={onAdd}
					size="XS"
				/>
			</Tooltip>
		</div>
	);
}

export default ManualDataType;
