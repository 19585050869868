import {
	AssetDataFlowJson,
	AssetDataFlowsJson,
	AssetConnectionJson,
	AssetConnectionsJson,
} from './dto';

const dataflows: AssetDataFlowJson[] = [
	{
		flow_direction: 'receiver',
		interacts_with: 3,
		sensitivity: 'Medium',
		data_types: [2, 3],
		last_seen: 1661558386500,
		type: 'asset',
		is_encrypted: false,
		is_mesh_network: false,
	},
	{
		flow_direction: 'both',
		interacts_with: 11,
		sensitivity: 'High',
		data_types: [2, 3, 4, 5, 6, 7, 10],
		last_seen: 1661558186500,
		type: 'asset',
		is_encrypted: false,
		is_mesh_network: false,
	},
	{
		flow_direction: 'receiver',
		interacts_with: 1,
		sensitivity: 'Medium',
		data_types: [2, 3],
		last_seen: 1661558286500,
		type: 'asset',
		is_encrypted: false,
		is_mesh_network: false,
	},
];

const assetDataFlowsJsonExample: AssetDataFlowsJson = {
	dataflows,
	total: dataflows.length,
	// total_filtered: 2, // Optional
};

const connections: AssetConnectionJson[] = [
	{
		flow_direction: 'receiver',
		interacts_with: 3,
		sensitivity: 'Medium',
		data_types: [2, 3],
		last_seen: 1661558386500,
		type: 's3_bucket',
		entity_name: 'Bucket with logs',
		is_encrypted: false,
		is_mesh_network: false,
	},
	{
		flow_direction: 'both',
		interacts_with: 11,
		sensitivity: 'High',
		data_types: [2, 3, 4, 5, 6, 7, 10],
		last_seen: 1661558186500,
		type: 's3_bucket',
		entity_name: 'test-bucket',
		is_encrypted: false,
		is_mesh_network: false,
	},
	{
		flow_direction: 'receiver',
		interacts_with: 1,
		sensitivity: 'Medium',
		data_types: [2, 3],
		last_seen: 1661558286500,
		type: 's3_bucket',
		entity_name: 'test-bucket',
		is_encrypted: false,
		is_mesh_network: false,
	},
];

const assetConnectionsJsonExample: AssetConnectionsJson = {
	dataflows: connections,
	total: dataflows.length,
	// total_filtered: 2, // Optional
};

export { assetDataFlowsJsonExample, assetConnectionsJsonExample };
