import cn from 'classnames';
import { ReactElement, ReactNode } from 'react';
import GridCell, { CellAlign } from '../table/GridCell';
import GridRow from '../table/GridRow';
import styles from './index.module.pcss';

interface IProps {
	type:
		| 'awsAccount'
		| 'internalAssetData'
		| 'externalAssetData'
		| 'dataFlowData'
		| 'tableData'
		| 'endpointsV2'
		| 'endpointsV2OneAsset'
		| 'gateway'
		| 's3Bucket'
		| 's3BucketFilesTable'
		| 'csvSample'
		| 's3BucketConnectionsTable'
		| 'kafkaInstance'
		| 'kafkaConsumerGroupsMembers'
		| 'kafkaMessageSchemas'
		| 'kafkaMessageSchemaSamples'
		| 'databases'
		| 'databaseTables'
		| 'databaseSample';
	children?: ReactNode;
	className?: string;
}

interface INoContent {
	min: number;
	max: number;
	className?: string;
}

interface INoContentItem extends INoContent {
	align?: CellAlign;
}

function randomWidth(min: number, max: number): number {
	return min + Math.random() * (max - min);
}

const contentWidthRules = {
	name: {
		min: 95,
		max: 120,
	},
	api: {
		min: 80,
		max: 100,
	},
	asset: {
		min: 50,
		max: 120,
	},
	count: {
		min: 18,
		max: 30,
		align: 'right' as 'right',
	},
	group: {
		min: 38,
		max: 50,
	},
	sensitivity: {
		min: 58,
		max: 70,
	},
	sensitivityNumber: {
		min: 15,
		max: 15,
	},
	sensitivityChip: {
		min: 55,
		max: 100,
	},
	lastSeen: {
		min: 50,
		max: 60,
	},
	gatewayName: {
		min: 260,
		max: 328,
	},
	gatewayToken: {
		min: 303,
		max: 303,
	},
};

function NoContentForText({ min, max, className }: INoContent): ReactElement {
	return (
		<span
			className={cn(className, styles.cellContent, styles.textContent)}
			style={{ width: randomWidth(min, max) }}
		/>
	);
}

function NoContentForChip({ min, max }: INoContent): ReactElement {
	return (
		<span
			className={cn(styles.cellContent, styles.chipContent)}
			style={{ width: randomWidth(min, max) }}
		/>
	);
}

function NoContentItem({ align = 'left', ...rest }: INoContentItem): ReactElement {
	return (
		<GridCell align={align}>
			<NoContentForText {...rest} />
		</GridCell>
	);
}

function ChipList(): ReactElement {
	return (
		<GridCell>
			<div className={styles.rowContainer}>
				<NoContentForChip {...contentWidthRules.sensitivityChip} />
				<NoContentForChip {...contentWidthRules.sensitivityChip} />
				<NoContentForChip {...contentWidthRules.sensitivityChip} />
			</div>
		</GridCell>
	);
}

const templateList = {
	awsAccount: [
		<NoContentItem key="arn" {...contentWidthRules.gatewayName} />,
		<NoContentItem key="update_at" {...contentWidthRules.lastSeen} />,
	],
	internalAssetData: [
		<NoContentItem key="name" {...contentWidthRules.name} />,
		<NoContentItem key="endpoints" {...contentWidthRules.count} />,
		<NoContentItem key="interactsWith" {...contentWidthRules.count} />,
		<NoContentItem key="sensitivity" {...contentWidthRules.sensitivity} />,
		<ChipList key="sensitivityChipList" />,
		<NoContentItem key="last-seen" {...contentWidthRules.lastSeen} />,
	],
	externalAssetData: [
		<NoContentItem key="name" {...contentWidthRules.name} />,
		<NoContentItem key="interactsWith" {...contentWidthRules.count} />,
		<NoContentItem key="sensitivity" {...contentWidthRules.sensitivity} />,
		<ChipList key="sensitivityChipList" />,
		<NoContentItem key="last-seen" {...contentWidthRules.lastSeen} />,
	],
	dataFlowData: [
		<NoContentItem key="flowDirection" {...contentWidthRules.sensitivityNumber} />,
		<NoContentItem key="api" {...contentWidthRules.api} />,
		<NoContentItem key="sensitivity" {...contentWidthRules.sensitivity} />,
		<ChipList key="sensitivityChipList" />,
		<NoContentItem key="last-seen" {...contentWidthRules.lastSeen} />,
	],
	tableData: [
		<NoContentItem key="name" {...contentWidthRules.name} />,
		<NoContentItem key="api" {...contentWidthRules.api} />,
		<NoContentItem key="group" {...contentWidthRules.group} />,
		<NoContentItem key="sensitivity" {...contentWidthRules.sensitivity} />,
		<ChipList key="sensitivityChipList" />,
		<NoContentItem key="last-seen" {...contentWidthRules.lastSeen} />,
	],
	endpointsV2: [
		<NoContentItem key="name" {...contentWidthRules.name} />,
		<NoContentItem key="api" {...contentWidthRules.api} />,
		<NoContentItem key="asset" {...contentWidthRules.asset} />,
		<NoContentItem key="sensitivity" {...contentWidthRules.sensitivity} />,
		<ChipList key="sensitivityChipList" />,
		<NoContentItem key="last-seen" {...contentWidthRules.lastSeen} />,
	],
	endpointsV2OneAsset: [
		<NoContentItem key="name" {...contentWidthRules.name} />,
		<NoContentItem key="api" {...contentWidthRules.api} />,
		<NoContentItem key="sensitivity" {...contentWidthRules.sensitivity} />,
		<ChipList key="sensitivityChipList" />,
		<NoContentItem key="last-seen" {...contentWidthRules.lastSeen} />,
	],
	gateway: [
		<NoContentItem key="gatewayName" {...contentWidthRules.gatewayName} />,
		<NoContentItem
			key="gatewayToken"
			{...contentWidthRules.gatewayToken}
			className={styles.gatewayToken}
		/>,
	],
	s3Bucket: [
		<NoContentItem key="gatewayName" {...contentWidthRules.gatewayName} />,
		<NoContentItem key="name" {...contentWidthRules.name} />,
		<NoContentItem key="created" {...contentWidthRules.lastSeen} />,
		<NoContentItem key="last-seen" {...contentWidthRules.lastSeen} />,
	],
	s3BucketFilesTable: [
		<NoContentItem key="gatewayName" {...contentWidthRules.gatewayName} />,
		<NoContentItem key="type" {...contentWidthRules.count} />,
		<NoContentItem key="size" {...contentWidthRules.count} />,
		<ChipList key="sensitivityChipList" />,
		<NoContentItem key="created" {...contentWidthRules.lastSeen} />,
		<NoContentItem key="last_scan" {...contentWidthRules.lastSeen} />,
	],
	s3BucketConnectionsTable: [
		<NoContentItem key="name" {...contentWidthRules.api} />,
		<NoContentItem key="namespace" {...contentWidthRules.name} />,
		<NoContentItem key="last-seen" {...contentWidthRules.lastSeen} />,
	],
	kafkaInstance: [
		<NoContentItem key="name" {...contentWidthRules.name} />,
		<NoContentItem key="topics" {...contentWidthRules.count} />,
		<NoContentItem key="messages" {...contentWidthRules.count} />,
		<NoContentItem key="created" {...contentWidthRules.lastSeen} />,
		<ChipList key="sensitivityChipList" />,
	],
	kafkaMessageSchemas: [
		<NoContentItem key="name" {...contentWidthRules.name} />,
		<NoContentItem key="type" {...contentWidthRules.count} />,
		<NoContentItem key="sensetivity" {...contentWidthRules.sensitivity} />,
		<ChipList key="sensitivityChipList" />,
		<NoContentItem key="lastSeen" {...contentWidthRules.lastSeen} />,
	],
	kafkaMessageSchemaSamples: [
		<NoContentItem key="offset" {...contentWidthRules.count} align="right" />,
		<NoContentItem key="partition" {...contentWidthRules.count} align="right" />,
		<NoContentItem key="timestamp" {...contentWidthRules.gatewayToken} />,
		<NoContentItem key="sensetivity" {...contentWidthRules.sensitivity} />,
		<ChipList key="sensitivityChipList" />,
		<NoContentItem key="lastChecked" {...contentWidthRules.lastSeen} />,
	],
	kafkaConsumerGroupsMembers: [
		<NoContentItem key="name" {...contentWidthRules.name} />,
		<NoContentItem key="cluster" {...contentWidthRules.name} />,
		<NoContentItem key="host" {...contentWidthRules.name} />,
	],
	databases: [
		<NoContentItem key="name" {...contentWidthRules.name} />,
		/* <NoContentItem key="region" {...contentWidthRules.api} />, TODO uncomment when regions are implemented for dbs */
		<NoContentItem key="tablesCount" {...contentWidthRules.count} />,
		<NoContentItem key="size" {...contentWidthRules.count} />,
		<NoContentItem key="sensitivity" {...contentWidthRules.sensitivityChip} />,
		<ChipList key="sensitivityChipList" />,
		<NoContentItem key="instance" {...contentWidthRules.lastSeen} />,
		<NoContentItem key="last_scan" {...contentWidthRules.lastSeen} />,
	],
	databaseTables: [
		<NoContentItem key="name" {...contentWidthRules.name} />,
		<NoContentItem key="size" {...contentWidthRules.count} />,
		<NoContentItem key="sensitivity" {...contentWidthRules.sensitivityChip} />,
		<ChipList key="sensitivityChipList" />,
		<NoContentItem key="last_scan" {...contentWidthRules.lastSeen} />,
	],
	databaseSample: [
		<NoContentItem key="name" {...contentWidthRules.name} />,
		<NoContentItem key="sensitivity" {...contentWidthRules.sensitivityChip} />,
		<NoContentItem key="last_scan" {...contentWidthRules.lastSeen} />,
	],
	csvSample: [
		<NoContentItem key="tablesCount" {...contentWidthRules.sensitivityNumber} />,
		<NoContentItem key="name" {...contentWidthRules.name} />,
		<NoContentItem key="sensitivity" {...contentWidthRules.sensitivityChip} />,
		<NoContentItem key="last_scan" {...contentWidthRules.lastSeen} />,
	],
};

function NoContent({ type, className, children }: IProps) {
	const template = templateList[type];
	const templateTable = [template, template, template];

	return (
		<>
			<div className={styles.container} data-test="no-content-rows">
				{templateTable.map((row, rowIndex) => {
					return (
						<GridRow key={`noContentRow-${rowIndex}`} className={className}>
							{row}
						</GridRow>
					);
				})}

				<div className={styles.blur} />
			</div>

			{children}
		</>
	);
}

export default NoContent;
