import { InputAdornment } from '@material-ui/core';
import { KeyboardEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Button from 'components/form/Button';
import MultiSelect, { MultiSelectProps } from 'components/form/MultiSelect';
import ApplyButton from 'components/form/MultiSelect/ApplyButton';
import Select, { Option } from 'components/form/Select';
import { DropdownButtonProps } from 'components/form/Select/DropdownButton';
import selectStyles from 'components/form/Select/index.module.css';
import { LabelProps } from 'components/form/Select/Label';
import TextField from 'components/form/TextField';
import Icon from 'components/Icon';
import Tooltip from 'components/Tooltip';
import Typo from 'components/typography/Typo';
import { PolicyRuleItem } from 'models/policies/dto';
import ruleStyles from '../LabelRule/index.module.pcss';

function DropdownButton(props: DropdownButtonProps) {
	const { onClick, children } = props;

	return (
		<span className={ruleStyles.ruleType}>
			<Button
				className={ruleStyles.ruleTypeButton}
				color="ghost"
				onClick={onClick}
				data-test="policy-rule-builder-add-item"
			>
				{children}
			</Button>
		</span>
	);
}

function Label(props: LabelProps) {
	const { label } = props;

	return typeof label === 'string' ? (
		<Typo variant="D/Medium/Body-S" color="secondary">
			{label}
		</Typo>
	) : (
		<>
			<Typo variant="D/Medium/Body-S" color="secondary">
				{label.primary}
			</Typo>
			<Typo variant="D/Medium/Body-S">{label.secondary}</Typo>
		</>
	);
}

type RuleBuilderSelectProps = {
	options: string[];
	value: string;
	onChange: (value: string) => void;
	label: { primary: string; secondary: string };
};

function RuleBuilderSelect(props: RuleBuilderSelectProps) {
	const { options, value, onChange, label } = props;

	return (
		<Select
			hasSearch
			options={options}
			value={value}
			onChange={onChange}
			label={label}
			render={{
				label: Label,
				dropdownButton: DropdownButton,
			}}
		/>
	);
}

type RuleBuilderMultiSelectProps = {
	options: string[];
	value: string[];
	onChange: (value: string[], clickedOption: string | null) => void;
	label: string;
};

function RuleBuilderMultiSelect(props: RuleBuilderMultiSelectProps) {
	const { options, value, onChange, label } = props;

	const closeRef = useRef(() => {});

	function closeSelect() {
		closeRef.current();
	}

	const Search = useCallback(
		function Search({ value: searchString, onChange: setSearchString }) {
			function addCustomOption() {
				if (!value.find((name) => name === searchString)) {
					onChange(value.concat(searchString), null);
					closeSelect();
				}
			}

			function onKeyDown(e: KeyboardEvent<HTMLElement>) {
				// This is to prevent MenuList from focusing on list item, when you
				// enter something in Search input field.
				e.stopPropagation();

				if (e.key === 'Enter') {
					e.preventDefault();
					addCustomOption();
				}
			}

			const endAdornment = searchString ? (
				<Tooltip title="Add custom option" placement="top">
					<InputAdornment onClick={addCustomOption} position="end" className={ruleStyles.addIcon}>
						<Icon name="AddCircle/Regular" size={20} />
					</InputAdornment>
				</Tooltip>
			) : (
				<InputAdornment position="end">
					<Icon name="search" size={20} />
				</InputAdornment>
			);

			return (
				<TextField
					data-test="policy-rule-builder-search-input"
					autoFocus
					value={searchString}
					onChange={(e) => {
						setSearchString(e.target.value);
					}}
					placeholder="Search"
					size="small"
					helperText={null}
					onKeyDown={onKeyDown}
					className={selectStyles.searchInput}
					InputProps={{ endAdornment }}
				/>
			);
		},
		[value, onChange]
	);

	return (
		<MultiSelect
			hasSearch
			hasApplyButton
			allOption="any"
			options={options}
			value={value}
			onChange={onChange}
			closeRef={closeRef}
			label={label}
			render={{
				label: Label,
				dropdownButton: DropdownButton,
				search: Search,
			}}
		/>
	);
}

type RuleBuilderComplexSelectProps = {
	options: Option[];
	value: PolicyRuleItem;
	onChange: (mutatorFn: (draft: PolicyRuleItem) => void) => void;
	label: string;
};

function RuleBuilderComplexSelect(props: RuleBuilderComplexSelectProps) {
	const { options, value, onChange, label } = props;

	const [internalValue, setInternalValue] = useState(value);

	useEffect(() => setInternalValue(value), [value]);

	const anyOption = options[0];
	const noneOption = options[1];

	const selectedValues = useMemo(() => {
		if (internalValue.operator === 'is_set') {
			return [anyOption];
		} else if (internalValue.operator === 'is_not_set') {
			return [noneOption];
		} else {
			return internalValue.values.map((val) => {
				const fromDropdown = options.find((item) => item.id === val);
				return fromDropdown || { id: val, name: val };
			});
		}
	}, [options, internalValue]);

	function onInternalChange(
		newValues: typeof options,
		clickedOption: (typeof options)[number] | null
	) {
		const result = { ...internalValue };

		if (clickedOption === anyOption) {
			result.values = [];
			result.operator = 'is_set';
		} else if (clickedOption === noneOption) {
			result.values = [];
			result.operator = 'is_not_set';
		} else {
			result.values = newValues
				.filter((item) => item.id !== -1 && item.id !== -2)
				.map((item) => item.id as string);
			result.operator = result.values.length === 0 ? 'is_set' : 'is';
		}

		setInternalValue(result);
	}

	const closeRef = useRef(() => {});

	function closeSelect() {
		closeRef.current();
	}

	function onClickApply() {
		onChange((draft) => {
			draft.values = internalValue.values;
			draft.operator = internalValue.operator;
		});
		closeSelect();
	}

	const Search = useCallback(
		function Search({ value: searchString, onChange: setSearchString }) {
			function addCustomOption() {
				if (!selectedValues.find(({ id }) => id === searchString)) {
					// console.log(selectedValues);
					onChange((draft) => {
						draft.values = selectedValues
							.concat({ id: searchString, name: searchString })
							.filter((item) => item.id !== -1 && item.id !== -2)
							.map((item) => item.id as string);
						draft.operator = 'is';
					});
					closeSelect();
				}
			}

			function onKeyDown(e: KeyboardEvent<HTMLElement>) {
				// This is to prevent MenuList from focusing on list item, when you
				// enter something in Search input field.
				e.stopPropagation();

				if (e.key === 'Enter') {
					e.preventDefault();
					addCustomOption();
				}
			}

			const endAdornment = searchString ? (
				<Tooltip title="Add custom option" placement="top">
					<InputAdornment onClick={addCustomOption} position="end" className={ruleStyles.addIcon}>
						<Icon name="AddCircle/Regular" size={20} />
					</InputAdornment>
				</Tooltip>
			) : (
				<InputAdornment position="end">
					<Icon name="search" size={20} />
				</InputAdornment>
			);

			return (
				<TextField
					autoFocus
					value={searchString}
					onChange={(e) => {
						setSearchString(e.target.value);
					}}
					placeholder="Search"
					size="small"
					helperText={null}
					onKeyDown={onKeyDown}
					className={selectStyles.searchInput}
					InputProps={{ endAdornment }}
				/>
			);
		},
		[selectedValues, onChange]
	);

	return (
		<MultiSelect
			hasSearch
			options={options}
			value={selectedValues}
			onChange={onInternalChange}
			dividerAfter={[noneOption]}
			closeRef={closeRef}
			label={label}
			render={{
				label: Label,
				dropdownButton: DropdownButton,
				search: Search,
				applyButton: () => ApplyButton({ onClick: onClickApply }),
			}}
		/>
	);
}

/**
 * AssetGroupMultiSelect draw multiselect with asset groups
 * Requires options and value as Option[] since asset group has "id" and "name".
 * Uses "search" method from standard MultiSelect.
 */
function AssetGroupOrClusterMultiSelect<T>(props: MultiSelectProps<T>) {
	const { options, value, onChange, label } = props;

	return (
		<MultiSelect
			hasSearch
			hasApplyButton
			allOption="any"
			options={options}
			value={value}
			onChange={onChange}
			label={label}
			render={{
				label: Label,
				dropdownButton: DropdownButton,
			}}
		/>
	);
}

export {
	RuleBuilderSelect,
	RuleBuilderMultiSelect,
	RuleBuilderComplexSelect,
	AssetGroupOrClusterMultiSelect,
};
