import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import Alert, { AlertSeverity } from 'components/Alert';
import Button from 'components/form/Button';
import PasswordField from 'components/form/PasswordField';
import Preloader from 'components/Preloader';
import Typo from 'components/typography/Typo';
import { getInviteInfoFx } from 'models/clients/effects';
import { restorePasswordFx } from 'models/session/effects';
import { APIError } from 'services/api/httpRequest';
import signUpStyles from '../SignUp/index.module.pcss';
import styles from './index.module.pcss';

type FormValues = {
	password: string;
	repeat_password: string;
};

function ResetPassword() {
	const token = new URLSearchParams(useLocation().search).get('token') || '';
	const [email, setEmail] = useState('');
	const [isLoading, setLoading] = useState(true);
	const [tokenError, setTokenError] = useState(false);
	const [passwordError, setPasswordError] = useState('');
	const [submitError, setSubmitError] = useState('');

	const { control, handleSubmit, watch } = useForm<FormValues>({
		defaultValues: {
			password: '',
			repeat_password: '',
		},

		mode: 'onBlur', // "onChange"
	});

	// When values of field are changed we clear the error
	const watchFields = watch(['password', 'repeat_password']);
	useEffect(() => {
		const subscription = watch(() => {
			setPasswordError('');
		});
		return () => subscription.unsubscribe();
	}, [watchFields]);

	useEffect(() => {
		getInviteInfoFx({ token })
			.then((info) => {
				setEmail(info.email);
			})
			.catch((error) => {
				if (error instanceof APIError && error.response.status === 400) {
					setTokenError(true);
				} else {
					throw error;
				}
			})
			.finally(() => {
				setLoading(false);
			});
	}, []);

	async function onSubmit({ repeat_password, password }: FormValues) {
		if (password !== repeat_password) {
			setPasswordError("Passwords don't match");
		} else {
			try {
				await restorePasswordFx({
					token,
					password,
				});
			} catch (error) {
				if (
					error instanceof APIError &&
					(error.response.status === 422 || error.response.status === 400)
				) {
					const { message } = await error.response.json();
					setSubmitError(message);
				} else {
					throw error;
				}
			}
		}
	}

	return (
		<div className={styles.container}>
			<div className={styles.wrapper} data-test="reset-password-form">
				<Preloader isLoading={isLoading}>
					{tokenError ? (
						<>
							<Typo
								variant="D/Medium/H500-Greeting-Title"
								className={styles.title}
								data-test="reset-password-invalid-link"
							>
								Invalid link!
							</Typo>

							<Typo className={styles.subTitle}>
								The link is incorrect, has expired, or has already
								<br />
								been used. Check the link or contact your
								<br />
								administrator.
							</Typo>
						</>
					) : (
						<>
							<Typo variant="D/Medium/H500-Greeting-Title" className={styles.title}>
								Password reset
							</Typo>

							<Typo className={styles.subTitle} color="secondary">
								Create a new password for your account
								<br />
								<Typo component="span" variant="D/Medium/Body">
									{email}
								</Typo>
							</Typo>

							<form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
								<PasswordField
									control={control}
									label="New password"
									name="password"
									autoComplete="off"
									required
									fullWidth
									size="large"
									dataTest="new-password-input"
								/>

								<PasswordField
									control={control}
									label="Repeat new password"
									name="repeat_password"
									autoComplete="off"
									required
									fullWidth
									error={Boolean(passwordError)}
									helperText={passwordError}
									size="large"
									dataTest="repeat-password-input"
								/>

								<Button
									className={styles.passwordResetButton}
									type="submit"
									data-test="reset-password-button"
								>
									Reset password
								</Button>

								{submitError && !isLoading && (
									<Alert className={signUpStyles.errorMessage} severity={AlertSeverity.error}>
										{submitError}
									</Alert>
								)}
							</form>
						</>
					)}
				</Preloader>
			</div>
		</div>
	);
}

export default ResetPassword;
