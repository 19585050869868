import ExternalLink from 'components/typography/ExternalLink';
import Typo from 'components/typography/Typo';
import signUpStyles from '../SignUp/index.module.pcss';

export function AWSSomethingWrong() {
	return (
		<div className={signUpStyles.container}>
			<div className={signUpStyles.wrapper}>
				<Typo variant="D/Medium/H500-Greeting-Title" className={signUpStyles.title}>
					Service unavailable
				</Typo>

				<Typo variant="D/Medium/Body-S" color="secondary" className={signUpStyles.form}>
					We are currently experiencing issues with AWS services. Please try again later through{' '}
					<ExternalLink href="https://aws.amazon.com/marketplace/pp/prodview-sekgnv6g3ekgi">
						AWS Marketplace
					</ExternalLink>
					. <br />
					<br />
					If the issue persists, please contact the Soveren support team at{' '}
					<a href="mailto:support@soveren.io">support@soveren.io.</a>
				</Typo>
			</div>
		</div>
	);
}
