import { useStore } from 'effector-react';
import { useCallback, useEffect, useMemo } from 'react';
import MultiSelect from 'components/form/MultiSelect';
import DropdownButton from 'components/form/Select/DropdownButton';
import { AssetJson } from 'models/assets/dto';
import { getAssetsFx } from 'models/assets/effects';
import { assetsList } from 'models/assets/store';
import { onceFx } from 'models/modelUtils/onceFx';
import { FilterPropsBase } from '../model';

type Option = { id: AssetJson['namespace']; name: AssetJson['namespace'] };

const EXCLUDED_ASSET_TYPES = ['endusers', 'robots', 'other_in', 'custom'];

type Props = FilterPropsBase & {
	value: AssetJson['namespace'][];
	onChange: (newValue: AssetJson['namespace'][]) => void;
	excludedAssetTypes?: boolean;
};

function NamespaceFilter({
	defaultOpen,
	excludedAssetTypes = false,
	fixed,
	onChange,
	onClose,
	resetFilter,
	value,
}: Props) {
	const assetsListStore = useStore(assetsList);

	useEffect(() => {
		onceFx(getAssetsFx);
	}, []);

	const groupedValues = useMemo(() => {
		const result: { [key: string]: Option } = {};

		for (const asset of assetsListStore) {
			// The following asset types can not contain endpoints
			if (excludedAssetTypes && EXCLUDED_ASSET_TYPES.includes(asset.type)) {
				continue;
			}

			if (!excludedAssetTypes && asset.is_external) {
				continue;
			}

			result[asset.namespace] = result[asset.namespace] || {
				id: asset.namespace,
				name: asset.namespace,
			};
		}

		return Object.values(result);
	}, [assetsListStore]);

	const values = useMemo(() => {
		return groupedValues.filter((val) => value.includes(val.name));
	}, [value, groupedValues]);

	function handleChange(options: Option[]) {
		onChange(options.map((option) => option.name));
	}

	const dropdownButton = useCallback(
		(dropdownProps) => <DropdownButton {...dropdownProps} fixed={fixed} onClose={resetFilter} />,
		[fixed, resetFilter]
	);

	return (
		<MultiSelect
			defaultOpen={defaultOpen}
			label={{ primary: 'Namespaces', secondary: `${values.length || ''}` }}
			options={groupedValues}
			value={values}
			onChange={handleChange}
			onClose={onClose}
			render={{ dropdownButton }}
			hasSearch
			hasApplyButton
		/>
	);
}

export default NamespaceFilter;
