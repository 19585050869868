import { IHeadCell } from 'components/table/EnhancedTableHead';
import { CSVSampleTable } from 'models/sample/dto';

const dataBaseTableConfig: IHeadCell<CSVSampleTable>[] = [
	{
		id: 'column_name',
		numeric: false,
		label: 'Column name',
		sortable: true,
	},
	{
		id: 'data_type',
		numeric: false,
		label: 'Data types',
		sortable: false,
	},
	{
		id: 'value',
		numeric: false,
		label: 'Value',
		sortable: false,
	},
];

export { dataBaseTableConfig };
