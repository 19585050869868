import { S3RegionItem, S3RegionList } from './dto';

export const s3RegionItemsExample: S3RegionItem[] = [
	{
		keyword: 'us-west-1',
		description: 'US West (N. California)',
		is_used: true,
	},
	{
		keyword: 'us-east-1',
		description: 'US East (N. Virginia)',
		is_used: true,
	},
];

export const s3RegionListExample: S3RegionList = {
	regions: s3RegionItemsExample,
};
