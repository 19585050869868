import { get, post } from 'api/Api';
import checkResponse from 'services/api/checkResponse';
import {
	ClusterGeoLocationItem,
	ClusterGeoLocationList,
	ClusterGeoLocationRegionList,
} from './dto';
import {
	clusterGeoLocationItemExample,
	clusterGeoLocationListExample,
	clusterGeoLocationRegionListExample,
} from './examples';

const API_URL = 'v1/cluster-geo-locations';

export async function getClusterGeoLocationRegions() {
	return get<ClusterGeoLocationRegionList>(`${API_URL}/regions`).then(
		checkResponse(clusterGeoLocationRegionListExample)
	);
}

export async function getClusterGeoLocations() {
	return get<ClusterGeoLocationList>(API_URL).then(checkResponse(clusterGeoLocationListExample));
}

export async function postClusterGeoLocationManualRegion({
	manualRegion,
	tokenId,
}: {
	manualRegion: ClusterGeoLocationItem['manual_region'];
	tokenId: ClusterGeoLocationItem['token_id'];
}) {
	return post<ClusterGeoLocationItem>(`${API_URL}/${tokenId}`, {
		manual_region: manualRegion,
	}).then(checkResponse(clusterGeoLocationItemExample));
}
