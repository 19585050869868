import { useStore } from 'effector-react';
import { useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Loader from 'components/Loader';
import AuthorizedWithLeftMenu from 'layouts/AuthorizedWithLeftMenu';
import { withErrorBoundary } from 'layouts/AuthorizedWithLeftMenu/LayoutMainErrorBoundary';
import GuestLayout from 'layouts/Guest'; // TODO SMAT-2872 inconsistent naming (imported with word Layout)
import { getSessionFx } from 'models/session/effects';
import { isAuthorizedStore } from 'models/session/store';
import { PATHS } from 'services/router'; // TODO SMAT-2872 move me in current directory
import Admin from 'views/Admin';
import ChangePassword from 'views/ChangePassword';
import { ConnectAWS } from 'views/ConnectAWS';
import DataMap from 'views/DataMap';
import { EventSample } from 'views/EventSample';
import EventsV2 from 'views/EventsV2';
import Integrations from 'views/Integrations';
import IntegrationsExternalAPI from 'views/Integrations/ExternalAPI/Settings';
import IntegrationsExternalAPICreateToken from 'views/Integrations/ExternalAPI/Settings/CreateToken';
import IntegrationsJira from 'views/Integrations/Jira/Settings';
import IntegrationsSlack from 'views/Integrations/Slack/Settings';
import IntegrationsWebhook from 'views/Integrations/Webhook/Settings';
import OverviewV2 from 'views/OverviewV2';
import ResetPassword from 'views/ResetPassword';
import SignIn from 'views/SignIn';
import { AWSNonExistentSubscription } from 'views/SignIn/AWSNonExistentSubscription';
import { AWSSomethingWrong } from 'views/SignIn/AWSSomethingWrong';
import SignUp from 'views/SignUp';
import SignUpWithToken from 'views/SignUpWithToken';
import SSOFailed from 'views/SSOFailed';
import AssetItem from 'views/SuperAssets/AssetItem';
import { S3BucketClusterView } from 'views/SuperAssets/S3Buckets/S3BucketClusterView';
import { S3BucketItemViewWrapper } from 'views/SuperAssets/S3Buckets/S3BucketItemView';
import { SampleViewers } from 'views/SuperAssets/S3Buckets/SampleViewers';
import Team from 'views/Team';
import { ApiEndpointsRoute } from './ApiEndpointsRoute';
import AssetGroupsRoute from './AssetsGroupsRoute';
import { DataStorageRouter } from './DataStorageRouter';
import { DataTypesRoute } from './DataTypesRoute.tsx';
import styles from './index.module.css';
import OldDataCatalog from './OldDataCatalog';
import PolicyRoute from './PolicyRoute';
import SensorsRoute from './SensorsRoute';
import { ServiceCatalog } from './ServiceCatalog';

//
// This component, besides defining top-level routes, manages fetching user session
// from server on app initialization. It then redirects to relevant routes based on
// authorized/non-authorized status.
//
// It also handles storing user path for cases when session is dropped (e.g. after
// successful sign-in, we need to go back where we were before losing session).
//

const STORAGE_KEY = 'svrn-prevPath';

function loadPathFromLocalStorage() {
	return localStorage.getItem(STORAGE_KEY);
}

function savePathInLocalStorage() {
	if (
		location.pathname === '/' ||
		location.pathname.startsWith(PATHS.SIGN_IN) ||
		location.pathname.startsWith(PATHS.SIGN_UP) ||
		location.pathname.startsWith(PATHS.RESET_PASSWORD) ||
		location.pathname.startsWith(PATHS.ACCEPT_INVITE)
	) {
		return;
	}

	localStorage.setItem(STORAGE_KEY, location.pathname + location.search);
}

// Save path once on app init, before any redirects happen.
savePathInLocalStorage();

// Save path when browser tab closes
window.addEventListener('beforeunload', savePathInLocalStorage);

function MainRouter() {
	const isAuthorized = useStore(isAuthorizedStore);
	const [firstTime, setFirstTime] = useState(true);
	const [previousIsAuthorized, setPreviousIsAuthorized] = useState(isAuthorized);
	const authorizationChanged = isAuthorized !== previousIsAuthorized;

	useEffect(() => setFirstTime(false), []);

	useEffect(function storePathOnUnmount() {
		return savePathInLocalStorage;
	}, []);

	useEffect(() => {
		setPreviousIsAuthorized(isAuthorized);
	}, [isAuthorized]);

	if (!isAuthorized) {
		if (authorizationChanged) savePathInLocalStorage(); // Save on logout, before redirect happens

		return (
			<GuestLayout>
				<Switch>
					<Route path={PATHS.SIGN_IN} component={SignIn} />
					<Route path={PATHS.SIGN_UP} component={SignUp} />
					<Route path={PATHS.CONNECT_AWS} component={ConnectAWS} />
					<Route path={PATHS.ACCEPT_INVITE} component={SignUpWithToken} />
					<Route path={PATHS.RESET_PASSWORD} component={ResetPassword} />
					<Route path={PATHS.SSO_FAILED} component={SSOFailed} />
					<Route
						path={PATHS.AWS_NON_EXISTENT_SUBSCRIPTION}
						component={AWSNonExistentSubscription}
					/>
					<Route path={PATHS.AWS_SOMETHING_WRONG} component={AWSSomethingWrong} />

					<Redirect to={PATHS.SIGN_IN} />
				</Switch>
			</GuestLayout>
		);
	} else {
		if (authorizationChanged || firstTime) {
			// Redirect once if path exists in store
			const prevPath = loadPathFromLocalStorage();

			if (prevPath) return <Redirect to={prevPath} />;
		}

		return (
			<AuthorizedWithLeftMenu>
				<div className={styles.layout}>
					<Switch>
						<Route path={PATHS.OVERVIEW} component={withErrorBoundary(OverviewV2)} />
						<Route path={PATHS.DATA_MAP} component={withErrorBoundary(DataMap)} exact />
						<Route path={PATHS.SERVICE_CATALOG} component={withErrorBoundary(ServiceCatalog)} />
						<Route path={PATHS.API_ENDPOINTS} component={withErrorBoundary(ApiEndpointsRoute)} />
						<Route path={PATHS.DATA_STORAGES} component={withErrorBoundary(DataStorageRouter)} />
						<Route path={PATHS.ASSET_GROUP_LIST} component={withErrorBoundary(AssetGroupsRoute)} />
						<Route path={PATHS.INTEGRATIONS} component={withErrorBoundary(Integrations)} exact />
						<Route
							path={PATHS.INTEGRATIONS_SLACK}
							component={withErrorBoundary(IntegrationsSlack)}
						/>
						<Route path={PATHS.INTEGRATIONS_JIRA} component={withErrorBoundary(IntegrationsJira)} />
						<Route
							path={PATHS.INTEGRATIONS_WEBHOOK}
							component={withErrorBoundary(IntegrationsWebhook)}
						/>
						<Route
							path={PATHS.INTEGRATIONS_EXTERNAL_API_CREATE_TOKEN}
							component={withErrorBoundary(IntegrationsExternalAPICreateToken)}
						/>
						<Route
							path={PATHS.INTEGRATIONS_EXTERNAL_API}
							component={withErrorBoundary(IntegrationsExternalAPI)}
						/>
						<Route
							path={PATHS.S3_CLUSTER_ITEM}
							component={withErrorBoundary(S3BucketClusterView)}
						/>
						{/* Samples' viewers */}
						<Route path={PATHS.S3_FILE_SAMPLE} component={withErrorBoundary(SampleViewers)} />
						{/**/}
						<Route
							path={PATHS.S3_BUCKETS_ITEM}
							component={withErrorBoundary(S3BucketItemViewWrapper)}
						/>
						<Route path={PATHS.SERVICE_ITEM} component={withErrorBoundary(AssetItem)} />
						<Route path={PATHS.EVENT_SAMPLE} component={withErrorBoundary(EventSample)} />
						<Route path={PATHS.EVENTS} component={withErrorBoundary(EventsV2)} />
						<Route path={PATHS.DATA_TYPES_LIST} component={withErrorBoundary(DataTypesRoute)} />
						<Route path={PATHS.SENSORS} component={withErrorBoundary(SensorsRoute)} />
						<Route path={PATHS.CHANGE_PASSWORD} component={withErrorBoundary(ChangePassword)} />
						<Route path={PATHS.ADMIN} component={withErrorBoundary(Admin)} />
						<Route path={PATHS.POLICY_LIST} component={withErrorBoundary(PolicyRoute)} />
						<Route path={PATHS.TEAM} component={withErrorBoundary(Team)} />
						<Route path={PATHS.ASSETS} component={withErrorBoundary(OldDataCatalog)} />
						<Redirect to={PATHS.OVERVIEW} />
					</Switch>
				</div>
			</AuthorizedWithLeftMenu>
		);
	}
}

//
// Wrapper for loading is required so that we do not concern ourselves with 'indeterminite'
// authorization status in <MainRouter />
//
function MainRouterWrapper() {
	const [isLoading, setLoading] = useState(true);

	// For SSO back-redirect, take (stale but otherwise valid) token from url. So that /session returns refreshed token.
	const intentionallyStaleToken =
		new URLSearchParams(window.location.search).get('access_token') || '';
	if (intentionallyStaleToken) {
		localStorage.setItem('Token', intentionallyStaleToken);
	}

	useEffect(() => {
		getSessionFx().finally(() => setLoading(false)); // getSessionFx() updates '$isAuthorized' store as well
	}, []);

	return isLoading ? <Loader className={styles.loader} /> : <MainRouter />;
}

export default MainRouterWrapper;
