import { useStore } from 'effector-react';
import { useEffect } from 'react';
import FilterLine from 'components/FilterLine';
import FilterResult from 'components/FilterResult';
import LoadMoreButton from 'components/LoadMoreButton';
import NoContent from 'components/NoContent';
import {
	clearPiiFilter,
	piiFilterToArray,
	piiFilterWithGroupsStore,
} from 'components/PiiGlobalFilterV2/model';
import Preloader from 'components/Preloader';
import ResetFilters from 'components/ResetFilters';
import EnhancedTableHead, { TOrder } from 'components/table/EnhancedTableHead';
import GridBody from 'components/table/GridBody';
import GridTable from 'components/table/GridTable';
import Typo from 'components/typography/Typo';
import { KafkaMessageSchema } from 'models/kafkaMessageSchemas/dto';
import { KafkaTopicSampleItem } from 'models/kafkaTopics/dto';
import { kafkaTopicSamplesModel } from 'models/kafkaTopics/model';
import { PageParamsConfig, usePageParams } from 'services/pageParams';
import tableConfig from './config';
import styles from './index.module.css';
import { SampleRow } from './SampleRow';

const pageConfig = {
	'data-types': {
		type: 'numberArray',
		persistence: 'session', // TODO implement 'global' in actuality
	},
	nonempty: {
		type: 'boolean',
		persistence: 'session', // TODO implement 'global' in actuality
	},
	sort: {
		type: 'sort',
		persistence: 'session',
	},
} satisfies PageParamsConfig;

type Props = {
	schemaId: KafkaMessageSchema['id'];
};

function SamplesTable({ schemaId }: Props) {
	const state = useStore(kafkaTopicSamplesModel.store);
	const piiFilterWithGroups = useStore(piiFilterWithGroupsStore);
	const [pageParams, setPageParams] = usePageParams(pageConfig, 'kafkaTopicSamples');

	useEffect(() => {
		setPageParams({
			...pageParams,
			'data-types': piiFilterWithGroups.dataTypes,
			nonempty: piiFilterWithGroups.nonEmpty,
		});
	}, [piiFilterWithGroups]);

	useEffect(() => {
		const { sort } = pageParams;

		const dataTypes = piiFilterToArray(piiFilterWithGroups);
		const paramsForFx = {
			sort: { orderBy: sort.value as keyof KafkaTopicSampleItem, order: sort.operator },
			'data-types': dataTypes,
		};

		kafkaTopicSamplesModel.fetchFx({ id: schemaId, ...paramsForFx });
	}, [pageParams]);

	// Clean up model on unmount
	useEffect(() => {
		return () => {
			kafkaTopicSamplesModel.resetFx();
		};
	}, []);

	// It's a workaround to set exact sort value instead of default('sensitivity')
	useEffect(() => {
		setPageParams({ ...pageParams, sort: { value: 'offset', operator: 'desc' } });
	}, []);

	function onSortUpdate(property: keyof KafkaTopicSampleItem) {
		const { sort } = pageParams;

		const operator: TOrder = sort.value === property && sort.operator === 'asc' ? 'desc' : 'asc';
		const newParams = { sort: { operator, value: property } };

		setPageParams({ ...pageParams, ...newParams });
	}

	function onResetFilters() {
		const newParams = {
			'data-types': [],
		};

		setPageParams({ ...pageParams, ...newParams });
		clearPiiFilter();
	}
	const { status, data, total, total_filtered, hasMoreData } = state;

	const hasFilter = pageParams['data-types'].length > 0;

	return (
		<div>
			<Typo variant="D/Medium/H100-Header">Data samples</Typo>

			<FilterLine
				config={['dataTypes']}
				values={{
					dataTypes: pageParams['data-types'],
				}}
				onChange={() => {}}
			/>

			<FilterResult
				entityLabel="data sample"
				loading={status === 'loading'}
				total={total}
				totalFiltered={total_filtered}
			/>

			<GridTable dataTest="kafka-samples-table">
				<EnhancedTableHead
					config={tableConfig}
					order={pageParams.sort.operator}
					orderBy={pageParams.sort.value}
					onRequestSort={onSortUpdate}
					rowClassname={styles.rowContainer}
				/>

				<Preloader isLoading={status === 'initial' || status === 'loading'}>
					<GridBody data-test="kafka-samples-list">
						<>
							{data.length ? (
								data.map((sample) => <SampleRow sample={sample} key={sample.id} />)
							) : hasFilter ? (
								<ResetFilters onReset={onResetFilters} />
							) : (
								<NoContent type="kafkaMessageSchemaSamples" className={styles.rowContainer}>
									<div className={styles.emptyContainer}>
										<Typo variant="D/Regular/Body-S" color="secondary">
											No available analytics.
										</Typo>
									</div>
								</NoContent>
							)}

							<LoadMoreButton
								show={hasMoreData}
								loading={status === 'loadingMore'}
								request={kafkaTopicSamplesModel.fetchMoreFx}
							/>
						</>
					</GridBody>
				</Preloader>
			</GridTable>
		</div>
	);
}

export { SamplesTable };
