import dayjs from 'dayjs';
import { DateFormat, format as dayjsFormat } from './dayjs';

const shortNamingEntries = Object.entries({
	seconds: 'sec',
	'a minute': '1 min',
	minutes: 'min',
	'an hour': '1 hr',
	hours: 'hr',
	'a day': '1 d',
	days: 'd',
	'a month': '1 mo',
	months: 'mo',
	'a year': '1 yr',
	years: 'yr',
});

export interface ReturnProps {
	diff: string;
	date: string;
	isFuture?: boolean;
}

interface Props {
	timestamp: number;
	format?: DateFormat;
	short?: boolean;
}

export function lastSeen({ timestamp, short = false }: Props): ReturnProps {
	if (timestamp === 0) {
		return {
			diff: '',
			date: '',
		};
	}

	const lastSeenDate = dayjs(timestamp);
	const isFuture = timestamp > new Date().getTime();
	let diff = lastSeenDate.toNow(true);

	if (short) {
		shortNamingEntries.forEach(([longNaming, shortNaming]) => {
			diff = diff.replace(longNaming, shortNaming);
		});
	}

	return {
		diff: isFuture ? `in ${diff}` : `${diff} ago`,
		date: dayjsFormat(lastSeenDate.utc(), DateFormat.utc),
		isFuture,
	};
}
