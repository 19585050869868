import { del, get, post } from 'api/Api';
import checkResponse from 'services/api/checkResponse';
import { SlackSettings, SlackChannels } from './dto';
import { slackSettingsExample, slackChannelsExample } from './examples';

async function createSlackIntegration(code: string) {
	return post('v1/slack-integration/oauth2-code', { code });
}

async function deleteSlackIntegration() {
	return del('v1/slack-integration');
}

async function getSlackSettings() {
	return get<SlackSettings>('v1/slack-integration').then(
		checkResponse<SlackSettings>(slackSettingsExample)
	);
}

async function updateSlackSettings(payload: Omit<SlackSettings, 'has_token'>) {
	return post<SlackSettings>('v1/slack-integration', payload).then(
		checkResponse<SlackSettings>(slackSettingsExample)
	);
}

async function getSlackChannels() {
	return get<SlackChannels>('v1/slack-integration/channels').then(
		checkResponse<SlackChannels>(slackChannelsExample)
	);
}

export {
	createSlackIntegration,
	deleteSlackIntegration,
	getSlackSettings,
	getSlackChannels,
	updateSlackSettings,
};
