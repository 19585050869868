import { useStore } from 'effector-react';
import { useCallback, useEffect, useMemo } from 'react';
import MultiSelect from 'components/form/MultiSelect';
import DropdownButton from 'components/form/Select/DropdownButton';
import { KafkaInstanceItem } from 'models/kafkaInstances/dto';
import { getKafkaInstancesFx } from 'models/kafkaInstances/effects';
import { kafkaInstancesList } from 'models/kafkaInstances/store';
import { onceFx } from 'models/modelUtils/onceFx';
import { FilterPropsBase } from '../model';

type Option = { name: KafkaInstanceItem['name']; id: KafkaInstanceItem['id'] };

type Props = FilterPropsBase & {
	value: KafkaInstanceItem['id'][];
	onChange: (newValue: KafkaInstanceItem['id'][]) => void;
};

function KafkaInstanceFilter({ defaultOpen, fixed, onChange, onClose, resetFilter, value }: Props) {
	const instances = useStore(kafkaInstancesList);

	useEffect(() => {
		onceFx(getKafkaInstancesFx);
	}, []);

	const groupedValues = useMemo(() => {
		return instances.map(({ id, name }) => ({ id, name }));
	}, [instances]);

	const values = useMemo(() => {
		return groupedValues.filter((_value) => value.includes(_value.id));
	}, [value, groupedValues]);

	const handleChange = (options: Option[]) => {
		onChange(options.map((option) => option.id));
	};

	const dropdownButton = useCallback(
		(dropdownProps) => <DropdownButton {...dropdownProps} fixed={fixed} onClose={resetFilter} />,
		[fixed, resetFilter]
	);

	return (
		<MultiSelect
			defaultOpen={defaultOpen}
			label={{ primary: 'Clusters', secondary: `${values.length || ''}` }}
			options={groupedValues}
			value={values}
			onChange={handleChange}
			onClose={onClose}
			render={{ dropdownButton }}
			hasSearch
			hasApplyButton
		/>
	);
}

export default KafkaInstanceFilter;
