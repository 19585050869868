import Typo, { CustomTypoProps } from '../Typo';
import styles from './index.module.css';

type MultiHighlightedProps = {
	children: string;
	search: string;
	onlyFirst?: boolean;
};

export const TypoHighlighted = (
	props: MultiHighlightedProps & Omit<CustomTypoProps, 'children'>
) => {
	const { children, search, onlyFirst = false, component = 'span', ...rest } = props;
	let temp = children;

	// Approach from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#escaping
	const searchEscaped = search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
	const searchRegExp = new RegExp(searchEscaped, 'i');

	const result: string[] = [];

	while (temp) {
		const foundIdx = temp.search(searchRegExp);

		// last
		if (foundIdx === -1 || (onlyFirst && result.length !== 0) || !search) {
			result.push(temp);
			break;
		}

		// simple
		result.push(temp.slice(0, foundIdx));
		// highlighted
		result.push(temp.slice(foundIdx, foundIdx + search.length));

		temp = temp.slice(foundIdx + search.length);
	}

	return (
		<Typo component={component} {...rest}>
			{result.map((part, index) =>
				index % 2 ? (
					<strong key={index} className={styles.highlight}>
						{part}
					</strong>
				) : (
					part
				)
			)}
		</Typo>
	);
};
