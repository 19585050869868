import cn from 'classnames';
import { generatePath } from 'react-router';
import Button from 'components/form/Button';
import GatewayStatusIcon from 'components/GatewayStatusIcon';
import { SecretToken } from 'components/SecretToken';
import secretTokenStyles from 'components/SecretToken/index.module.pcss';
import GridCell from 'components/table/GridCell';
import GridRow from 'components/table/GridRow';
import { RouterLink } from 'components/typography/Link';
import { AccountAWSItem } from 'models/sensors/aws/dto';
import { PATHS } from 'services/router';
import accountStyles from '../index.module.css';
import { DraftDeleteButton } from './DraftDeleteButton';
import styles from './index.module.css';

type Props = {
	account: AccountAWSItem;
};

export function DraftItem({ account }: Props) {
	const { id, external_id } = account;

	return (
		<GridRow
			className={cn(accountStyles.rowContainer, styles.rowContainer)}
			hover
			border
			data-test="aws-account-item"
		>
			<GridCell className={accountStyles.statusContainer} verticalAlign="middle">
				<GatewayStatusIcon status="draft" hoverable={false} />

				<SecretToken
					canBeCopied
					canBeHidden={false}
					value={external_id}
					InputProps={{ classes: { root: secretTokenStyles.inRow } }}
					helperText={null}
					className={styles.externalId}
				/>
			</GridCell>

			<GridCell verticalAlign="middle">
				<RouterLink to={generatePath(PATHS.ACCOUNT_AWS_CREATE, { id })}>
					<Button size="extraSmall" color="tertiary">
						Continue configuration
					</Button>
				</RouterLink>
			</GridCell>

			<GridCell verticalAlign="middle" className={accountStyles.deleteButton}>
				<DraftDeleteButton id={id} name={external_id} />
			</GridCell>
		</GridRow>
	);
}
