/*

import { assetEventJsonExample, eventsJsonExample } from '../../models/eventsV2/examples';

import {
	regularEventJsonExample,
	violationEventJsonExample,
	eventsJsonExample,
	eventSummaryJsonExample,
} from '../../models/events/examples';

const dataTypeMock = regularEventJsonExample;
const dataFieldMock = { ...dataTypeMock, id: 2, type: 'data_field' };
const endpointMock = { ...dataTypeMock, id: 3, type: 'endpoint' };
const dataUserMock = { ...dataTypeMock, id: 4, type: 'data_user' };
const dataTypeNewestMock = { ...dataTypeMock, id: 5, created_at: new Date().getTime() };

const violationMock = violationEventJsonExample;

const allEventsMock = {
	...eventsJsonExample,
	log_events: [
		dataTypeNewestMock,
		dataTypeMock,
		dataFieldMock,
		endpointMock,
		dataUserMock,
		violationMock,
	],
};
*/

// import { assetCountersJsonExample } from '../../models/assetCounters/examples';
// import { assetEndpointsJsonExample } from '../../models/assetEndpoints/examples';
// import { dataCombinationsExample } from '../../models/dataCombinationsV2/examples';
// import { discoveredDataTypesExample } from '../../models/discoveredDataTypesV2/examples';
// import { dataFlowDetailsJsonExample } from '../../models/superAssetsDataFlowDetails/examples';
// import { dataFlowDetailsJsonExample } from '../../models/superAssetsDataFlowDetails/examples';
// import { dataTypesExtendedJsonExample } from '../../models/dataTypesExtended/examples';
// import { IPsLargeJsonExample } from '../../models/ips/examples';
// import { oidcSettingsExample, adfsSettingsExample } from '../../models/sso/examples';
// import { s3SampleLogExample } from '../../models/s3Sample/examples.ts';

const mock = [
	{
		url: 'v1/part_of_url_1',
		direction: 'request',
		method: 'GET',
	},
	{
		url: 'v1/part_of_url_2',
		direction: 'response',
		method: 'POST',
		body: {
			mock_body_key: 'mock_body_value',
		},
	},
	/*
	{
		url: 'v1/sso/settings',
		direction: 'response',
		method: 'GET',
		body: oidcSettingsExample,
	},
	{
		url: 'v1/sso/settings',
		direction: 'response',
		method: 'POST',
		body: adfsSettingsExample,
	},
	{
		url: 'v1/clients/email-check',
		direction: 'response',
		method: 'POST',
		body: {
			is_sso: false,
			sso_login_url: 'fake-string',
			login_denied: true,
			denial_reason: 'fake-reason',
		},
	},
	*/
	// {
	// 	url: 'v2/groups',
	// 	direction: 'response',
	// 	method: 'GET',
	// 	body: assetGroupListExample,
	// },
	/*
	{
		url: 'v1/groups/295/flows/',
		direction: 'response',
		method: 'GET',
		body: streamsJsonExample,
	},
	{
		url: 'v1/groups/295/flows',
		direction: 'response',
		method: 'GET',
		body: servicesWithFlowJsonExample,
	},
	{
		url: 'v1/log-events-summary',
		direction: 'response',
		method: 'GET',
		body: eventSummaryJsonExample,
	},
	{
		url: 'v2/log-events/',
		direction: 'response',
		method: 'GET',
		body: assetEventJsonExample,
	},
	{
		url: 'v2/log-events',
		direction: 'response',
		method: 'GET',
		body: eventsJsonExample,
	},
	{
		url: 'v1/recent-activity',
		direction: 'response',
		method: 'GET',
		body: {
			api_calls: {
				weekly: 12234,
				weekly_delta: -1234,
				daily: [
					{ date: 1648080000000, value: 234 },
					{ date: 1648166400000, value: 444 },
					{ date: 1648252800000, value: 22 },
					{ date: 1648339200000, value: 33 },
					{ date: 1648425600000, value: 222 },
					{ date: 1648512000000, value: 111 },
					{ date: 1648598400000, value: 44 },
				],
			},
			data_types: {
				weekly: 1234,
				weekly_delta: 123,
				daily: [
					{
						date: 0,
						value: 0,
					},
					{
						date: 1,
						value: 1,
					},
				],
			},
			data_fields: {
				weekly: 1234,
				weekly_delta: 123,
				daily: [
					{ date: 1648080000000, value: 123 },
					{ date: 1648166400000, value: 666 },
					{ date: 1648252800000, value: 55 },
					{ date: 1648339200000, value: 12 },
					{ date: 1648425600000, value: 444 },
					{ date: 1648512000000, value: 222 },
					{ date: 1648598400000, value: 77 },
				],
			},
			hosts: {
				weekly: 1234,
				weekly_delta: 123,
				daily: [
					{ date: 1648252800000, value: 3123 },
					{ date: 1648339200000, value: 87840 },
					{ date: 1648425600000, value: 75867 },
					{ date: 1648512000000, value: 340 },
					{ date: 1648598400000, value: 23645 },
				],
			},
			endpoints: {
				weekly: 1234,
				weekly_delta: 123,
				daily: [
					{
						date: 0,
						value: 0,
					},
					{
						date: 1,
						value: 1,
					},
					{
						date: 2,
						value: 2,
					},
					{
						date: 3,
						value: 3,
					},
					{
						date: 4,
						value: 4,
					},
				],
			},
			data_users: {
				weekly: 1234,
				weekly_delta: 123,
				daily: [
					{
						date: 0,
						value: 0,
					},
					{
						date: 1,
						value: 1,
					},
					{
						date: 2,
						value: 2,
					},
					{
						date: 3,
						value: 3,
					},
					{
						date: 4,
						value: 4,
					},
					{
						date: 5,
						value: 5,
					},
				],
			},
		},
	},

	{
		url: 'v1/ips',
		direction: 'response',
		method: 'GET',
		body: IPsLargeJsonExample,
	},

	{
		url: 'v2/collected_group/counters',
		direction: 'response',
		method: 'GET',
		body: countersJsonExample,
	},

	{
		url: 'v2/data-map',
		direction: 'response',
		method: 'GET',
		body: dataMapJsonExample,
	},

	{
		url: 'v2/assets-extended/', // // 'v2/assets-extended/<id>/counters'
		direction: 'response',
		method: 'GET',
		body: assetCountersJsonExample,
	},

	{
		url: 'v2/assets-extended',
		direction: 'response',
		method: 'GET',
		body: assetsExtendedJsonExample,
	},

	{
		url: 'v2/assets/', // 'v2/assets/<id>/dataflows'
		direction: 'response',
		method: 'GET',
		body: assetDataFlowsJsonExample,
	},

	{
		url: 'v2/assets',
		direction: 'response',
		method: 'GET',
		body: assetsJsonExample,
	},

	{
		url: 'v2/sample',
		direction: 'response',
		method: 'GET',
		body: sampleJsonExample,
	},

	{
		url: 'v2/data-types-extended',
		direction: 'response',
		method: 'GET',
		body: dataTypesExtendedJsonExample,
	},

	{
		url: 'v2/data-combinations',
		direction: 'response',
		method: 'GET',
		body: dataCombinationsExample,
	},

	{
		url: 'v2/discovered-data-types',
		direction: 'response',
		method: 'GET',
		body: discoveredDataTypesExample,
	},
*/

	/* {
		url: 'v1/s3/buckets/1/top-clusters',
		direction: 'response',
		method: 'GET',
		body: s3ClusterListExample,
	},
	{
		url: 'v1/s3/clusters/',
		direction: 'response',
		method: 'GET',
		body: s3ClusterFileWithSamplesExample,
	},
	{
		url: 'v1/s3/buckets/1/status',
		direction: 'response',
		method: 'GET',
		body: s3BucketStatusExample,
	},
	{
		url: 'v1/s3/buckets/1',
		direction: 'response',
		method: 'GET',
		body: s3BucketItemsExample[0],
	},
	{
		url: 'v1/s3/buckets',
		direction: 'response',
		method: 'GET',
		body: s3BucketListExample,
	},
	{
		url: 'v1/s3/aws-regions',
		direction: 'response',
		method: 'GET',
		body: s3RegionListExample,
	}, */

	// {
	// 	url: 'v1/policies',
	// 	direction: 'response',
	// 	method: 'GET',
	// 	body: policyListExample,
	// },

	// {
	// 	url: 'v2/policies',
	// 	direction: 'response',
	// 	method: 'GET',
	// 	body: policyListExample,
	// },
	// {
	// 	url: 'v2/policies/',
	// 	direction: 'response',
	// 	method: 'POST',
	// 	body: policyItemExample,
	// },
	// {
	// 	url: 'v1/s3/s3-clusters/1',
	// 	direction: 'response',
	// 	method: 'GET',
	// 	body: s3ClusterListExample,
	// },
	// {
	// 	url: 'v1/s3/object-samples/csv/1',
	// 	direction: 'response',
	// 	method: 'GET',
	// 	body: s3SampleExample,
	// },
	// {
	// 	url: 'v1/s3/object-samples/1',
	// 	direction: 'response',
	// 	method: 'GET',
	// 	body: s3SampleLogExample,
	// },
];

export default mock;
