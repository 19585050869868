import cn from 'classnames';
import { ReactNode } from 'react';
import ButtonIcon from 'components/ButtonIcon';
import styles from './index.module.pcss';

enum Variants {
	shift = 'shift',
	overlay = 'overlay',
}

type Classes = {
	hideButton?: string;
	border?: string;
	root?: string;
	rightPart?: string;
	rightPartOpen?: string;
	leftPart?: string;
};

interface OverlayProps {
	classes?: Classes;
	open: boolean;
	hideButton?: boolean;
	rightPart: ReactNode;
	onClose?: () => void;
	onOpen?: () => void;
	size?: 'M' | 'S';
}

interface ShiftProps extends OverlayProps {
	leftPart: ReactNode;
}

interface CurtainProps extends OverlayProps {
	variant: keyof typeof Variants;
}

function Curtain({
	classes,
	open = false,
	hideButton = false,
	rightPart,
	onOpen,
	onClose,
	variant,
	size = 'M',
}: CurtainProps) {
	function onCloseHandler() {
		if (!open && onOpen) {
			onOpen();
		} else if (onClose) {
			onClose();
		}
	}

	return (
		<div
			className={cn(
				open ? cn(styles.opened, classes?.rightPartOpen) : styles.closed,
				styles.rightPart,
				classes?.rightPart,
				styles[size],
				{
					[styles.overlay]: variant === 'overlay',
					[styles.shift]: variant === 'shift',
				}
			)}
			data-test="curtain-panel"
		>
			<div className={cn(styles.border, classes?.border)}>
				{!hideButton && (
					<>
						<div className={cn(styles.backing, styles.hideButton)} />

						<ButtonIcon
							icon="ChevronRight/Regular"
							onClick={onCloseHandler}
							className={cn(
								styles.hideButton,
								classes?.hideButton,
								open ? styles.iconClose : styles.iconOpen
							)}
							dataTest="curtain-hide-button"
							size={size === 'S' ? 'XS' : 'S'}
						/>
					</>
				)}
			</div>

			<div className={styles.background}>
				<div
					className={cn(styles.rightPartContent, open ? styles.showContent : styles.hideContent)}
				>
					{rightPart}
				</div>
			</div>
		</div>
	);
}

export const CurtainShift = (props: ShiftProps) => {
	const { classes, leftPart } = props;

	return (
		<div className={cn(styles.container, classes?.root)}>
			<div className={cn(styles.leftPart, classes?.leftPart)}>{leftPart}</div>

			<Curtain variant="shift" {...props} />
		</div>
	);
};

export const CurtainOverlay = (props: OverlayProps) => {
	return <Curtain variant="overlay" {...props} />;
};
