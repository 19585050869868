import { IHeadCell } from 'components/table/EnhancedTableHead';
import { KafkaTopicTableItem } from 'models/kafkaTopics/dto';

const tableConfig: IHeadCell<KafkaTopicTableItem>[] = [
	{
		id: 'name',
		numeric: false,
		label: 'Topic name',
		sortable: true,
	},
	{
		id: 'partitions',
		numeric: true,
		label: 'Partitions',
		sortable: true,
	},
	{
		id: 'messages_count',
		numeric: true,
		label: 'Messages',
		sortable: true,
	},
	{
		id: 'cluster',
		numeric: false,
		label: 'Cluster',
		sortable: false,
	},
	{
		id: 'data_types',
		numeric: false,
		label: 'Data types',
		sortable: false,
	},
	{
		id: 'last_seen',
		numeric: false,
		label: 'Last checked',
		sortable: true,
		reversedSort: true,
	},
];

export default tableConfig;
