import cn from 'classnames';
import { useStore } from 'effector-react';
import { useEffect, useState } from 'react';
import { getGatewaysFx } from 'models/gateways/effects';
import { gatewaysList } from 'models/gateways/store';
import { getSensorsDARFx } from 'models/sensors/dar/effects';
import { sensorsDARList } from 'models/sensors/dar/store';
import styles from './index.module.css';

function SensorsStatus() {
	const sensorsDIM = useStore(gatewaysList)
		.filter((sensor) => !sensor.is_deleted)
		.map(({ status }) => ({ status }));
	const sensorsDAR = useStore(sensorsDARList)
		.filter((sensor) => !sensor.is_deleted)
		.map(({ status }) => ({ status }));

	const [isLoaded, setLoaded] = useState(false);

	useEffect(() => {
		Promise.all([getGatewaysFx(), getSensorsDARFx()]).then(() => setLoaded(true));
	}, []);

	if (!isLoaded) {
		return null;
	}

	const sensors = sensorsDIM.concat(sensorsDAR);

	const allSensors = sensors.length;
	const okSensors = sensors.filter((sensor) => sensor.status === 'ok').length;
	let status: 'warning' | 'error' | 'ok';

	if (allSensors) {
		if (allSensors === okSensors) {
			status = 'ok';
		} else {
			status = 'warning';
		}
	} else {
		status = 'error';
	}

	return <span className={cn(styles.container, styles[status])} />;
}

export default SensorsStatus;
