import { get, post } from 'api/Api';
import { getKafkaInstancesFx } from 'models/kafkaInstances/effects';
import { kafkaInstancesMap } from 'models/kafkaInstances/store';
import { ApiParams } from 'services/api';
import checkResponse from 'services/api/checkResponse';
import {
	KafkaConsumerGroupsMemberList,
	KafkaTopicItem,
	KafkaTopicList,
	KafkaTopicSample,
	KafkaTopicSampleList,
	KafkaTopicStatus,
} from './dto';
import {
	kafkaConsumerGroupsMemberListExample,
	kafkaTopicItemExample,
	kafkaTopicListExample,
	kafkaTopicSampleItem,
	kafkaTopicSampleListExample,
	kafkaTopicStatusExample,
} from './examples';

async function getKafkaTopicList(apiParams: ApiParams, signal?: AbortSignal) {
	const config = signal ? { signal } : {};

	return get<KafkaTopicList>('v1/kafka/topics', apiParams, config)
		.then(checkResponse(kafkaTopicListExample))
		.then(async (resp) => {
			let instances = kafkaInstancesMap.getState();
			const checkInstances = resp.topics.every(({ instance_id }) => instances[instance_id]);

			if (!checkInstances) {
				await getKafkaInstancesFx();
				instances = kafkaInstancesMap.getState();
			}

			return {
				...resp,
				topics: resp.topics.map((topic) => ({
					...topic,
					cluster: instances[topic.instance_id].name,
				})),
			};
		});
}

async function getKafkaTopicItem(id: KafkaTopicItem['id']) {
	return get<KafkaTopicItem>(`v1/kafka/topics/${id}`)
		.then(checkResponse(kafkaTopicItemExample))
		.then(async (topic) => {
			let instances = kafkaInstancesMap.getState();
			const checkInstances = !!instances[topic.instance_id];

			if (!checkInstances) {
				await getKafkaInstancesFx();
				instances = kafkaInstancesMap.getState();
			}

			return {
				...topic,
				cluster: instances[topic.instance_id].name,
			};
		});
}

async function updateKafkaTopic({ id, ...payload }: KafkaTopicItem) {
	return post<KafkaTopicItem>(`v1/kafka/topics/${id}`, payload).then(
		checkResponse(kafkaTopicItemExample)
	);
}

async function getKafkaConsumerGroupsMemberList(id: KafkaTopicItem['id']) {
	return get<KafkaConsumerGroupsMemberList>(`v1/kafka/topics/${id}/consumer_groups_members`).then(
		checkResponse(kafkaConsumerGroupsMemberListExample)
	);
}

async function getKafkaTopicStatus({
	id,
	nonEmpty,
}: {
	id: KafkaTopicItem['id'];
	nonEmpty: boolean;
}) {
	const params = nonEmpty ? { only_with_pii: true } : undefined;

	return await get<KafkaTopicStatus>(`v1/kafka/topics/${id}/status`, params).then(
		checkResponse(kafkaTopicStatusExample)
	);
}

async function getKafkaTopicSampleList(
	id: KafkaTopicItem['id'],
	apiParams: ApiParams,
	signal?: AbortSignal
) {
	const config = signal ? { signal } : {};

	return get<KafkaTopicSampleList>(`v1/kafka/schemas/${id}/samples`, apiParams, config).then(
		checkResponse(kafkaTopicSampleListExample)
	);
}

async function getKafkaTopicSample(id: KafkaTopicItem['id']) {
	return get<KafkaTopicSample>(`v1/kafka/samples/${id}`).then(checkResponse(kafkaTopicSampleItem));
}

export {
	getKafkaTopicList,
	getKafkaTopicItem,
	updateKafkaTopic,
	getKafkaConsumerGroupsMemberList,
	getKafkaTopicStatus,
	getKafkaTopicSampleList,
	getKafkaTopicSample,
};
