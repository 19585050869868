import { get, post } from 'api/Api';
import checkResponse from 'services/api/checkResponse';
import { DataTypesExtendedResponseItem, DataTypesExtendedResponseList } from './dto';
import { dataTypesExtendedJsonExample } from './examples';

function getDataTypesExtended() {
	return get<DataTypesExtendedResponseList>('v2/data-types-extended').then(
		checkResponse(dataTypesExtendedJsonExample)
	);
}

// TODO delete from back-end
function confirmDataTypeViewed(id: DataTypesExtendedResponseItem['id']) {
	return post<unknown>(`v2/data-types-extended/${id}/confirm-viewed`);
}

export { getDataTypesExtended, confirmDataTypeViewed };
