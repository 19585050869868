import { createEffect } from 'effector';
import {
	getAccountsAWS,
	createAccountAWS,
	deleteAccountAWS,
	createDraftAccountAWS,
	getAccountAWS,
	testAWSConfiguration,
} from './api';
import { AccountAWSItem, AccountAWSList, AccountAWSCreate, AccountAWSTest } from './dto';

/* API */

const getAccountsAWSFx = createEffect<void, AccountAWSList>();
const getAccountAWSFx = createEffect<AccountAWSItem['id'], AccountAWSItem>();
const createAccountAWSFx = createEffect<AccountAWSCreate, AccountAWSItem>();
const createDraftAccountAWSFx = createEffect<void, AccountAWSItem>();
const testAWSConfigurationFx = createEffect<
	{ id: AccountAWSItem['id']; role_arn: AccountAWSItem['role_arn'] },
	AccountAWSTest
>();
const deleteAccountAWSFx = createEffect<AccountAWSItem['id'], AccountAWSItem['id']>();

/* IMPLEMENTATION */

// Simple direct-to-api effects

getAccountsAWSFx.use(getAccountsAWS);
getAccountAWSFx.use(getAccountAWS);
createAccountAWSFx.use(createAccountAWS);
createDraftAccountAWSFx.use(createDraftAccountAWS);
testAWSConfigurationFx.use(testAWSConfiguration);
deleteAccountAWSFx.use(deleteAccountAWS);

export {
	getAccountsAWSFx,
	createDraftAccountAWSFx,
	createAccountAWSFx,
	deleteAccountAWSFx,
	testAWSConfigurationFx,
};
