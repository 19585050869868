import { useState } from 'react';
import Button from 'components/form/Button';
import history from 'services/history';
import styles from './index.module.css';

interface Props {
	isNew: boolean;
	isError: boolean;
	saveForm: () => void;
}

export const AssetFormFooter = ({ isNew = false, isError = false, saveForm }: Props) => {
	const [isLoading, setIsLoading] = useState(false);

	return (
		<div className={styles.container}>
			<Button
				onClick={() => history.goBack()}
				color="secondary"
				size="medium"
				data-test="asset-info-cancel-button"
			>
				Cancel
			</Button>

			<Button
				type="submit"
				color="primary"
				size="medium"
				onClick={async () => {
					setIsLoading(true);
					await saveForm();
					setIsLoading(false);
				}}
				disabled={isLoading || isError}
				data-test="asset-info-save-button"
			>
				{isNew ? 'Create' : 'Save'}
			</Button>
		</div>
	);
};
