import { createEffect } from 'effector';
import { ApiParams } from 'services/api';
import {
	deleteClient,
	getClientList,
	getInviteInfo,
	getInviteToken,
	getPasswordToken,
	inviteMember,
	updateClient,
} from './api';
import {
	ClientItem,
	ClientList,
	InviteClientItem,
	InviteInfo,
	TokenItem,
	UpdateClientItem,
} from './dto';

let ac = new AbortController();

type TAbortion = void | { abortSignal?: boolean };

/* API */
const getClientListFx = createEffect<TAbortion, ClientList>();
const inviteClientFx = createEffect<InviteClientItem, ClientItem>();
const getInviteLinkFx = createEffect<ClientItem['client_id'], TokenItem>();
const getPasswordLinkFx = createEffect<ClientItem['client_id'], TokenItem>();
const deleteClientFx = createEffect<ClientItem['client_id'], ClientItem['client_id']>();
const updateClientFx = createEffect<UpdateClientItem, ClientItem>();
const getInviteInfoFx = createEffect<ApiParams, InviteInfo>();

/* IMPLEMENTATION */
// Simple direct-to-api effects
getClientListFx.use((payload: TAbortion) => {
	if (payload?.abortSignal) {
		// Max one request at a time. Since API is parametrized - just cancel previous request(s).
		ac.abort();
		ac = new AbortController();
	}

	return getClientList(ac.signal);
});
inviteClientFx.use(inviteMember);
getInviteLinkFx.use(getInviteToken);
getPasswordLinkFx.use(getPasswordToken);
deleteClientFx.use(deleteClient);
updateClientFx.use(updateClient);
getInviteInfoFx.use(getInviteInfo);

// Watchers
inviteClientFx.doneData.watch(() => getClientListFx());
deleteClientFx.doneData.watch(() => getClientListFx());
updateClientFx.doneData.watch(() => getClientListFx());

export {
	getClientListFx,
	inviteClientFx,
	getInviteLinkFx,
	deleteClientFx,
	updateClientFx,
	getPasswordLinkFx,
	getInviteInfoFx,
};
