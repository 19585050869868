import { createStore } from 'effector';
import { AccountAWSItem, AccountAWSList } from './dto';
import { deleteAccountAWSFx, getAccountsAWSFx } from './effects';

const accountsAWSStore = createStore<AccountAWSList['accounts']>([]);

// Subscribe to events/effects

accountsAWSStore.on(
	getAccountsAWSFx.doneData,
	(state: AccountAWSList['accounts'], data: AccountAWSList) => data.accounts
);

accountsAWSStore.on(
	deleteAccountAWSFx.doneData,
	(state: AccountAWSList['accounts'], id: AccountAWSItem['id']) =>
		state.filter((account) => account.id !== id)
);

// Public API
const accountsAWSList = accountsAWSStore.map((accounts) => accounts);

export { accountsAWSList };
