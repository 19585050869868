import cn from 'classnames';
import { ComponentType, useEffect, useMemo, useRef, useState } from 'react';
import AutoSizer, { Size } from 'react-virtualized-auto-sizer';
import { VariableSizeList, VariableSizeList as List } from 'react-window';
import { ViewerSize } from '../';
import styles from './index.module.pcss';
import { LineWrapper } from './LineWrapper';

/*
	Viewer component does three things:
	- virtualizes lines for performance on large samples, using react-window,
	- displays line numbers and handles line highlights, and
	- displays detection hints (colored circles indicating that this line has detections)
*/

type TMark = {
	// TODO probably should get rid of unnecessary fields here
	dataTypeId: number;
	isCorrected: boolean;
	isFP: boolean;
	isManual: boolean;
};

interface Props<T> {
	data: {
		detectionsForMarks: TMark[];
		lineData: T;
		lineNumber: number;
	}[];
	activeLine: string | null;
	lineTemplate: ComponentType<{
		lineData: T;
		lineWrap?: boolean;
		size?: ViewerSize;
	}>;
	lineWrap?: boolean;
	size?: ViewerSize;
}

function Viewer<T>({ data, activeLine, lineTemplate: Line, lineWrap, size = 'M' }: Props<T>) {
	// Here we add helpers for VariableSizeList
	const initialSize = useMemo(() => new Array(data.length), []);
	const rowHeights = useRef<number[]>(initialSize);
	const listRef = useRef<VariableSizeList>();

	function getRowHeight(index: number) {
		return rowHeights.current[index] || (size === 'S' ? 24 : 32);
	}

	function setRowHeight(index: number, _size: number) {
		if (rowHeights.current[index] === _size) return;

		rowHeights.current[index] = _size;
		listRef.current?.resetAfterIndex(index);
	}

	// Here we work with width of lines' container
	// There are two refs:
	// * listRef - needs for scrolling to item
	// * scrollRef - needs for determine the widest value for every line

	useEffect(() => {
		if (!listRef.current) return;

		if (activeLine) listRef.current.scrollToItem(+activeLine - 1, 'center');
	}, [activeLine, data]);

	const [activeLines, setActiveLines] = useState<number[]>([]);

	function onLineClick(id: number) {
		if (activeLines.includes(id)) {
			setActiveLines(activeLines.filter((lineId) => lineId !== id));
		} else {
			setActiveLines([...activeLines, id]);
		}
	}

	return (
		<div className={cn(styles.jsonContainer, styles[size])}>
			<pre className={styles.pre}>
				<div className={styles.json}>
					<AutoSizer>
						{({ height, width }: Size) => (
							<List
								height={height}
								itemCount={data.length}
								itemSize={getRowHeight}
								ref={(ref) => {
									if (ref) {
										listRef.current = ref;
									}
								}}
								width={width}
								itemData={{
									setRowHeight,
									list: data,
									viewWidth: width,
									activeLine,
									onLineClick,
									activeLines,
								}}
							>
								{(props) => {
									const { lineData } = props.data.list[props.index];

									return (
										<LineWrapper {...props} size={size}>
											<Line lineData={lineData} lineWrap={lineWrap} size={size} />
										</LineWrapper>
									);
								}}
							</List>
						)}
					</AutoSizer>
				</div>
			</pre>
		</div>
	);
}

export { Viewer };
