import { useStore } from 'effector-react';
import { useEffect, useState } from 'react';
import { generatePath } from 'react-router';
import { useHistory } from 'react-router-dom';
import Button from 'components/form/Button';
import Icon from 'components/Icon';
import Preloader from 'components/Preloader';
import Typo from 'components/typography/Typo';
import Header from 'layouts/AuthorizedWithLeftMenu/Header';
import { getDataTypesExtendedFx } from 'models/dataTypesExtended/effects';
import { dataTypesStructuredStore } from 'models/dataTypesExtended/store';
import { PATHS } from 'services/router';
import DataTypesTable from './DataTypesTable';

export function DataTypesList() {
	const [isLoading, setLoading] = useState(true);
	const history = useHistory();
	const tableData = useStore(dataTypesStructuredStore);

	useEffect(() => {
		getDataTypesExtendedFx().then(() => setLoading(false)); // Refresh data each time we get on this page.
	}, []);

	return (
		<>
			<Header
				breadcrumbProps={{ finalStep: 'Data types' }}
				titleBlock={
					<>
						<Typo variant="D/Medium/H400-Page-Title">Data types</Typo>

						<Button
							size="small"
							startIcon={<Icon name="Add/Regular" size={20} />}
							onClick={() => {
								const path = generatePath(PATHS.DATA_TYPES_ITEM, { id: 'new' });

								history.push(path);
							}}
							data-test="data-types-header-create-button"
						>
							New custom data type
						</Button>
					</>
				}
			/>

			<Preloader isLoading={isLoading}>
				<DataTypesTable data={tableData} />
			</Preloader>
		</>
	);
}
