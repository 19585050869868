import { createEffect } from 'effector';
import { getSensorsDAR, createSensorDAR, updateSensorDAR, deleteSensorDAR } from './api';
import { SensorDARItem, SensorDARList, SensorDARCreate } from './dto';

/* API */

const getSensorsDARFx = createEffect<void, SensorDARList>();
const createSensorDARFx = createEffect<SensorDARCreate, SensorDARItem>();
const updateSensorDARFx = createEffect<SensorDARItem, SensorDARItem>();
const deleteSensorDARFx = createEffect<SensorDARItem['id'], SensorDARItem['id']>();

/* IMPLEMENTATION */

// Simple direct-to-api effects

getSensorsDARFx.use(getSensorsDAR);
createSensorDARFx.use(createSensorDAR);
updateSensorDARFx.use(updateSensorDAR);
deleteSensorDARFx.use(deleteSensorDAR);

export { getSensorsDARFx, createSensorDARFx, updateSensorDARFx, deleteSensorDARFx };
