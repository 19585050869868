import { createEvent, createStore } from 'effector';

const LS_SIDE_MENU_KEY = 'isSideMenuCollapsed';

const initialState = JSON.parse(localStorage.getItem(LS_SIDE_MENU_KEY) || 'false');
localStorage.setItem(LS_SIDE_MENU_KEY, JSON.stringify(initialState));

const sideMenuStateStore = createStore<boolean>(initialState);
const setSideMenuState = createEvent<boolean>();

setSideMenuState.watch((isCollapsed: boolean) => {
	localStorage.setItem(LS_SIDE_MENU_KEY, JSON.stringify(isCollapsed));
});

sideMenuStateStore.on(setSideMenuState, (state: boolean, isCollapsed: boolean) => isCollapsed);

export { sideMenuStateStore, setSideMenuState, LS_SIDE_MENU_KEY };
