import Big from 'big.js';
import cn from 'classnames';
import { useEffect, useState } from 'react';
import styles from './index.module.css';

type TDelay = {
	current: number;
	min: number;
	max: number;
	count: number;
	total: number;
	title?: string;
};
type TValuesStack = TDelay[];

const DEFAULT_DELTA = 50;
const DEFAULT_DELAY = {
	current: 0,
	count: 0,
	max: 0,
	min: 0,
	total: 0,
};

let isOn = false;
let delay = { ...DEFAULT_DELAY };
let watchedDelta = DEFAULT_DELTA;
let start = 0;
let previousTimestamp = 0;
const resetValues = () => {
	start = 0;
	previousTimestamp = 0;
	delay = { ...DEFAULT_DELAY };
};

// race condition protection
let animationFrameAdded = false;
function addRequestAnimationFrame() {
	if (animationFrameAdded || !isOn) return;

	animationFrameAdded = true;
	window.requestAnimationFrame((timestamp) => {
		onAnimationFrame(timestamp);
		animationFrameAdded = false;
		addRequestAnimationFrame();
	});
}

function onAnimationFrame(timestamp: number) {
	if (!start) {
		start = timestamp;
	}

	const delta = previousTimestamp ? timestamp - previousTimestamp : -1;

	if (delta >= watchedDelta) {
		const newValue = new Big(delta).round(1).toNumber();

		delay.current = newValue;
		delay.min = delay.min > newValue || delay.min === 0 ? newValue : delay.min;
		delay.max = delay.max < newValue ? newValue : delay.max;
		delay.count++;
		delay.total = new Big(delay.total).plus(newValue).round(1).toNumber();
	}

	previousTimestamp = timestamp;
}

export const PerformanceDashboard = () => {
	const [isOnValue, setIsOnValue] = useState(false);
	const [maximumDelta, setMaximumDelta] = useState(DEFAULT_DELTA);
	const [currentValue, setCurrentValue] = useState<TDelay>(DEFAULT_DELAY);
	const [currentTitle, setCurrentTitle] = useState('');
	const [currentStack, setCurrentStack] = useState<TValuesStack>([]);

	// turn on/off flag by setTimeout instead of setInterval
	const [fakeFlag, setFakeFlag] = useState(false);

	useEffect(() => {
		setTimeout(() => {
			setFakeFlag(!fakeFlag);
			setCurrentValue(delay);
		}, 500);
	}, [fakeFlag]);

	useEffect(() => {
		if (isOnValue) {
			resetValues();
			isOn = true;
			addRequestAnimationFrame();
		} else {
			isOn = false;
		}
	}, [isOnValue]);

	const deltaOnChange = (e: { target: { value: string } }) => {
		const newValue = Number(e.target.value);
		if (Number.isNaN(newValue)) return;

		setMaximumDelta(newValue);
		watchedDelta = newValue;
	};

	return (
		<div className={cn(styles.container, isOnValue && styles.isOn)}>
			<div className={styles.controls}>
				<div>
					<button
						onClick={() => {
							setIsOnValue(!isOnValue);
						}}
					>
						{isOnValue ? '✅' : '☑️'} ON/OFF
					</button>
				</div>
				<div>
					Delta ≥{' '}
					<input
						className={styles.inputDelta}
						value={maximumDelta}
						pattern="^[0-9]+$"
						onChange={deltaOnChange}
					/>{' '}
					ms
				</div>
				<div className={styles.deltaButtons}>
					set delta to: <button onClick={() => deltaOnChange({ target: { value: '0' } })}>0</button>
					<button onClick={() => deltaOnChange({ target: { value: '20' } })}>20</button>
					<button onClick={() => deltaOnChange({ target: { value: '50' } })}>50</button>
					<button onClick={() => deltaOnChange({ target: { value: '100' } })}>100</button>
					<button onClick={() => deltaOnChange({ target: { value: '300' } })}>300</button>
				</div>
			</div>
			<hr />
			<h4>
				Current <button onClick={resetValues}>Drop</button>
			</h4>
			<div className={cn(styles.resultRow, styles.resultRowHeader)}>
				<div className={styles.current}>current</div>
				<div className={styles.min}>min</div>
				<div className={styles.max}>max:</div>
				<div className={styles.count}>count</div>
				<div className={styles.total}>total</div>
				<div />
			</div>
			<div className={styles.resultRow}>
				<div className={styles.current}>{currentValue.current}</div>
				<div className={styles.min}>{currentValue.min}</div>
				<div className={styles.max}>{currentValue.max}</div>
				<div className={styles.count}>{currentValue.count}</div>
				<div className={styles.total}>{currentValue.total}</div>
				<div>
					<input className={styles.inputTitle} onChange={(e) => setCurrentTitle(e.target.value)} />
					<button
						onClick={() =>
							setCurrentStack([{ ...currentValue, title: currentTitle }, ...currentStack])
						}
					>
						save
					</button>
				</div>
			</div>
			<hr />
			<div className={styles.results}>
				<h4>
					Results <button onClick={() => setCurrentStack([])}>Drop</button>
				</h4>
				{currentStack.length > 0 && (
					<div className={cn(styles.resultRow, styles.resultRowHeader)}>
						<div className={styles.current}>current</div>
						<div className={styles.min}>min</div>
						<div className={styles.max}>max:</div>
						<div className={styles.count}>count</div>
						<div className={styles.total}>total</div>
						<div />
					</div>
				)}
				{currentStack.map(({ current, min, max, count, total, title }, key) => (
					<div key={key} className={styles.resultRow}>
						<div className={styles.current}>{current}</div>
						<div className={styles.min}>{min}</div>
						<div className={styles.max}>{max}</div>
						<div>{count}</div>
						<div>{total}</div>
						<div>{title}</div>
					</div>
				))}
			</div>
		</div>
	);
};
