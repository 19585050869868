import { InputAdornment } from '@material-ui/core';
import { useState } from 'react';
import ButtonIcon from 'components/ButtonIcon';
import TextField, { TextFieldInFormProps, TextFieldProps } from 'components/form/TextField';
import { enqueueSnackbar } from 'components/Snackbar';

interface Props {
	canBeCopied?: boolean;
	canBeHidden?: boolean;
}

function endAdornment(
	showValue: boolean,
	handleShowValue: () => void,
	copyToClipboard: () => void,
	canBeCopied?: boolean,
	canBeHidden?: boolean
) {
	return (
		<InputAdornment position="end">
			{canBeHidden && (
				<ButtonIcon
					color="icon/secondary"
					icon={showValue ? 'Eye/Filled' : 'EyeOff/Filled'}
					onClick={handleShowValue}
					size="S"
				/>
			)}

			{canBeCopied && (
				<ButtonIcon
					icon="CopyCustom/Filled"
					onClick={copyToClipboard}
					size="S"
					data-test="secret-token"
				/>
			)}
		</InputAdornment>
	);
}

function SecretToken<T>({
	canBeCopied = false,
	canBeHidden = true,
	...props
}: (TextFieldProps | TextFieldInFormProps<T>) & Props) {
	const [showValue, setShowValue] = useState(!canBeHidden);
	const handleShowValue = () => setShowValue(!showValue);

	function copyToClipboard() {
		const message = `${props.label || 'Token'} copied`;

		navigator.clipboard.writeText(props.value as string).then(() => {
			enqueueSnackbar(message);
		});
	}

	const inputProps = {
		...props.InputProps,
		type: showValue ? 'text' : 'password',
		endAdornment: endAdornment(
			showValue,
			handleShowValue,
			copyToClipboard,
			canBeCopied,
			canBeHidden
		),
	};

	return <TextField {...props} InputProps={inputProps} readOnly dataTest="secret-token" />;
}

export { SecretToken };
