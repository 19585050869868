import { get, post } from 'api/Api';
import checkResponse from 'services/api/checkResponse';
import { KafkaInstanceItem, KafkaInstanceList } from './dto';
import { kafkaInstanceItemExample, kafkaInstanceListExample } from './examples';

async function getKafkaInstanceList() {
	return get<KafkaInstanceList>('v1/kafka/instances').then(checkResponse(kafkaInstanceListExample));
}

async function getKafkaInstanceItem(id: KafkaInstanceItem['id']) {
	return get<KafkaInstanceItem>(`v1/kafka/instances/${id}`).then(
		checkResponse(kafkaInstanceItemExample)
	);
}

async function updateKafkaInstance({ id, ...payload }: KafkaInstanceItem) {
	return post<KafkaInstanceItem>(`v1/kafka/instances/${id}`, payload).then(
		checkResponse(kafkaInstanceItemExample)
	);
}

export { getKafkaInstanceList, getKafkaInstanceItem, updateKafkaInstance };
