import { InputAdornment } from '@material-ui/core';
import { KeyboardEvent } from 'react';
import TextField from 'components/form/TextField';
import Icon from 'components/Icon';
import styles from './index.module.css';

type SearchProps = {
	value: string;
	onChange: (value: string) => void;
};

// Common overrides: placeholder; icon; helper/error text.
function Search(props: SearchProps) {
	const { value, onChange } = props;

	function preventPropagation(e: KeyboardEvent<HTMLElement>) {
		// This is to prevent MenuList from focusing on list item, when you
		// enter something in Search input field.
		e.stopPropagation();
	}
	return (
		<TextField
			autoFocus
			value={value}
			onChange={(e) => {
				onChange(e.target.value);
			}}
			placeholder="Search"
			size="small"
			helperText={null}
			onKeyDown={preventPropagation}
			className={styles.searchInput}
			InputProps={{
				endAdornment: (
					<InputAdornment position="end">
						<Icon name="search" size={20} />
					</InputAdornment>
				),
			}}
		/>
	);
}

export default Search;
export type { SearchProps };
