import { Redirect, Route, Switch } from 'react-router-dom';
import { PATHS } from 'services/router';
import AssetGroupsItem from 'views/AssetGroups/AssetGroupItem';
import AssetGroupsList from 'views/AssetGroups/AssetGroupList';

function AssetGroupsRoute() {
	return (
		<Switch>
			<Route path={PATHS.ASSET_GROUP_LIST} component={AssetGroupsList} exact />
			<Route path={PATHS.ASSET_GROUP_ITEM} component={AssetGroupsItem} />

			<Redirect to={PATHS.ASSET_GROUP_LIST} />
		</Switch>
	);
}

export default AssetGroupsRoute;
