import { createEffect } from 'effector';
import {
	getClusterGeoLocationRegions,
	getClusterGeoLocations,
	postClusterGeoLocationManualRegion,
} from './api';
import {
	ClusterGeoLocationItem,
	ClusterGeoLocationList,
	ClusterGeoLocationRegionList,
} from './dto';

/* API */

export const getClusterGeoLocationRegionsFx = createEffect<void, ClusterGeoLocationRegionList>();
export const getClusterGeoLocationsFx = createEffect<void, ClusterGeoLocationList>();
export const updateClusterGeoLocationManualRegionFx = createEffect<
	{
		manualRegion: ClusterGeoLocationItem['manual_region'];
		tokenId: ClusterGeoLocationItem['token_id'];
	},
	ClusterGeoLocationItem
>();
/* IMPLEMENTATION */

// Simple direct-to-api effects

getClusterGeoLocationRegionsFx.use(getClusterGeoLocationRegions);
getClusterGeoLocationsFx.use(getClusterGeoLocations);
updateClusterGeoLocationManualRegionFx.use(postClusterGeoLocationManualRegion);
