import { Effect } from 'effector';

function onceFx<T, U>(fx: Effect<void, T, U>): Promise<T> {
	if (!fx.pending.getState()) return fx();

	return new Promise((resolve) => {
		const unwatch = fx.doneData.watch((payload: T) => {
			unwatch();
			resolve(payload);
		});
	});
}

export { onceFx };
