import { get } from 'api/Api';
import checkResponse from 'services/api/checkResponse';
import { RecentActivityJson } from './dto';
import { recentActivityExample } from './examples';

async function getRecentActivity() {
	return get<RecentActivityJson>('v1/recent-activity').then(checkResponse(recentActivityExample));
}

export { getRecentActivity };
