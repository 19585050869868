import { get } from 'api/Api';
import { dataTypesById } from 'models/dataTypes/store';
import checkResponse from 'services/api/checkResponse';
import { DiscoveredDataTypesList } from './dto';
import { discoveredDataTypesExample } from './examples';

async function getDiscoveredDataTypes(host: string) {
	return get<DiscoveredDataTypesList>(
		'v2/discovered-data-types',
		host !== 'all' ? { host } : undefined
	)
		.then(checkResponse(discoveredDataTypesExample))
		.then((discoveredDataTypes) => {
			const dataTypesMap = dataTypesById.getState(); // TODO SMAT-2866 can we do better?

			return {
				...discoveredDataTypes,
				data_types: discoveredDataTypes.data_types.map((dt) => {
					return {
						...dt,
						display_name: dataTypesMap[dt.data_type].name,
						custom: dataTypesMap[dt.data_type].custom,
						alias: dataTypesMap[dt.data_type].alias,
					};
				}),
			};
		});
}

export { getDiscoveredDataTypes };
