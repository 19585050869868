import cn from 'classnames';
import { useStore } from 'effector-react';
import { Fragment, useEffect, useState } from 'react';
import commonStyles from 'assets/styles/index.module.pcss';
import { PiiTypeList } from 'components/PiiType';
import Preloader from 'components/Preloader';
import { RouterLink } from 'components/typography/Link';
import Typo from 'components/typography/Typo';
import { getEvents } from 'models/eventsV2/api';
import { EventJson } from 'models/eventsV2/dto';
import { activityStore } from 'models/recentActivity/store';
import { lastSeen } from 'services/lastSeen';
import { PATHS } from 'services/router';
import { eventTitleAsComponent } from 'views/EventsV2/EventRow';
import { WidgetHeader } from '../WidgetParts';
import emptyData from './emptyData';
import styles from './index.module.pcss';

function EventTitleLong({ event }: { event: EventJson }) {
	const shortTitle = eventTitleAsComponent({ event });

	if (
		event.type === 'new_data_type' ||
		event.type === '3rd_party_policy_violation' ||
		event.type === 'policy_violation' ||
		event.type === 'custom_asset_rule_conflict' ||
		event.type === 'discovery_complete' ||
		event.type === 'dim_release_update' ||
		event.type === 'dim_release_update_critical' ||
		event.type === 'dar_release_update' ||
		event.type === 'dar_release_update_critical'
	)
		return shortTitle;

	const shownDataTypes = event.data_types.slice(0, 3);
	const hiddenDataTypesCount = event.data_types.length - shownDataTypes.length;
	const moreTypes =
		hiddenDataTypesCount > 0 ? (
			<Typo variant="D/Regular/Meta" color="secondary" component="span">
				+{hiddenDataTypesCount} more
			</Typo>
		) : null;

	return (
		<>
			{shortTitle}:{' '}
			<PiiTypeList
				size="extraSmall"
				data={shownDataTypes}
				noHighlight
				className={styles.dataTypesList}
			/>
			{moreTypes}
		</>
	);
}

function EventSummary() {
	const [isLoading, setLoading] = useState(false);
	const [eventSummary, setEventSummary] = useState<EventJson[]>(emptyData);
	const hasTraffic = useStore(activityStore);

	useEffect(() => {
		if (!hasTraffic) return;

		setLoading(true);

		getEvents().then((res) => {
			// @ts-ignore
			const log_events = res.log_events.map((e) => ({ ...e, data_types: e.data_types || [] }));

			log_events.sort((e1, e2) => e2.created_at - e1.created_at);

			setEventSummary(log_events.slice(0, 5));
			setLoading(false);
		});
	}, [hasTraffic]);

	return (
		<div>
			<WidgetHeader header="Last events" />

			<Preloader isLoading={isLoading}>
				<div className={cn(styles.grid, { [commonStyles.skeletonMode]: !hasTraffic })}>
					{eventSummary.map((summary) => (
						<Fragment key={summary.id}>
							<Typo color="secondary" variant="D/Regular/Body-S" className={styles.time}>
								{lastSeen({ timestamp: summary.created_at }).diff}
							</Typo>

							<Typo variant="D/Regular/Body-S" component="div">
								<RouterLink
									inherit
									to={`${PATHS.EVENTS}?id=${summary.id}`}
									className={styles.event}
									data-test="event-summary-row"
								>
									<EventTitleLong event={summary} />
								</RouterLink>
							</Typo>
						</Fragment>
					))}

					<span />

					<Typo
						variant="D/Medium/Body-S"
						color="secondary"
						className={cn(styles.link, commonStyles.skeletonCancel)}
					>
						<RouterLink inherit to={PATHS.EVENTS} className={commonStyles.skeletonCancel}>
							See all events
						</RouterLink>
					</Typo>
				</div>
			</Preloader>
		</div>
	);
}

export default EventSummary;
