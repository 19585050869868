import { ExternalAPIToken, ExternalAPITokenList } from './dto';

const externalAPITokenExample: ExternalAPIToken = {
	id: 1,
	name: 'Service collector token',
	token: '123e4567-e89b-12d3-a456-426655440000',
	created_at: 1695815963882,
	updated_at: 1695815963882,
};

const externalAPITokensExample: ExternalAPITokenList = {
	tokens: [externalAPITokenExample],
	total: 1,
};

export { externalAPITokensExample, externalAPITokenExample };
