import {
	ClientItem,
	ClientList,
	InviteClientItem,
	InviteInfo,
	TokenItem,
	UpdateClientItem,
} from './dto';

export const clientItemExample: ClientItem = {
	client_id: 1,
	full_name: 'John Smith',
	email: 'john.smith@example.com',
	role: 'admin',
	status: 'active',
	last_seen: 1639999999999,
};

export const updateItemExample: UpdateClientItem = {
	id: 65,
	role: 'admin',
};

export const inviteItemExample: InviteClientItem = {
	full_name: 'John Smith',
	email: 'john.smith@example.com',
	role: 'admin',
};

export const tokenItemExample: TokenItem = {
	token: '565de5e1e5854fd4c1d94097f6f4a740f9a5ac7eb16dc635ea6955eb38f38b4b',
};

const clientItem2: ClientItem = {
	...clientItemExample,
	client_id: 2,
	full_name: 'John Snow',
	status: 'invite',
	last_seen: 0,
};

const clientItem3: ClientItem = {
	...clientItemExample,
	client_id: 3,
	full_name: 'Joseph Brown',
	status: 'blocked',
};

const clientItem4: ClientItem = {
	...clientItemExample,
	client_id: 16,
	full_name: '',
	status: 'active',
	email: 'john.smith.jn@example.com',
};

export const clientListExample: ClientList = {
	clients: [clientItemExample, clientItem2, clientItem3, clientItem4],
	total: 4,
};

export const inviteInfoExample: InviteInfo = {
	full_name: 'John Smith',
	email: 'john.smith@example.com',
	created_by: 'admin@example.com',
	is_sso_enabled: false,
};
