import { del, get, patch, post } from 'api/Api';
import { ApiParams } from 'services/api';
import checkResponse from 'services/api/checkResponse';
import {
	ClientItem,
	ClientList,
	InviteClientItem,
	InviteInfo,
	TokenItem,
	UpdateClientItem,
} from './dto';
import {
	clientItemExample,
	clientListExample,
	inviteInfoExample,
	tokenItemExample,
} from './examples';

function getClientList(signal?: AbortSignal) {
	const config = signal ? { signal } : {};

	return get<ClientList>('v1/clients', undefined, config).then(checkResponse(clientListExample));
}

function inviteMember(payload: InviteClientItem) {
	return post<ClientItem>('v1/clients/invite', payload).then(checkResponse(clientItemExample));
}

function updateClient(payload: UpdateClientItem) {
	return patch<ClientItem>(`v1/clients/${payload.id}`, payload).then(
		checkResponse(clientItemExample)
	);
}

function getInviteToken(id: ClientItem['client_id']) {
	return get<TokenItem>(`v1/clients/${id}/invite-token`).then(checkResponse(tokenItemExample));
}

function getPasswordToken(id: ClientItem['client_id']) {
	return post<TokenItem>(`v1/clients/${id}/password-token`).then(checkResponse(tokenItemExample));
}

function deleteClient(id: ClientItem['client_id']) {
	return del(`v1/clients/${id}`).then(() => id);
}

function getInviteInfo(apiParams: ApiParams) {
	return get<InviteInfo>('v1/clients/token-info', apiParams).then(checkResponse(inviteInfoExample));
}

export {
	getClientList,
	getInviteInfo,
	inviteMember,
	getInviteToken,
	getPasswordToken,
	updateClient,
	deleteClient,
};
