import { DataCombinationsItem } from 'models/dataCombinationsV2/dto';

const emptyData: DataCombinationsItem[] = [
	{
		endpoints: 141700,
		endpoints_percent: 30.6,
		data_types: [1, 2],
		sensitivity: 'Low',
	},
	{
		endpoints: 78360,
		endpoints_percent: 16.9,
		data_types: [3, 2, 4],
		sensitivity: 'High',
	},
	{
		endpoints: 68903,
		endpoints_percent: 14.9,
		data_types: [4, 5],
		sensitivity: 'Medium',
	},
	{
		endpoints: 64498,
		endpoints_percent: 13.9,
		data_types: [4],
		sensitivity: 'Medium',
	},
	{
		endpoints: 64440,
		endpoints_percent: 13.9,
		data_types: [2, 6],
		sensitivity: 'Low',
	},
];

export default emptyData;
