import {
	// createTheme,
	// https://github.com/mui/material-ui/issues/13394
	unstable_createMuiStrictModeTheme,
	StylesProvider,
	ThemeProvider,
} from '@material-ui/core/styles';
import { ReactChild } from 'react';

function MDTheme(props: { children: ReactChild }) {
	const theme = unstable_createMuiStrictModeTheme({
		typography: {
			// It's a hardcode and a twin of variable --font-family (frontend-smat-portal/src/assets/styles/variables.pcss)
			fontFamily: [
				'Inter',
				'-apple-system',
				'BlinkMacSystemFont',
				'Segoe UI',
				'Roboto',
				'Oxygen',
				'Ubuntu',
				'Cantarell',
				'Fira Sans',
				'Droid Sans',
				'Helvetica Neue',
				'sans-serif',
			].join(','),
		},
	});

	return (
		<StylesProvider injectFirst>
			<ThemeProvider theme={theme}>{props.children}</ThemeProvider>
		</StylesProvider>
	);
}

export default MDTheme;
