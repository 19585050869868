import { useStore } from 'effector-react';
import { produce } from 'immer';
import { useContext, useEffect, useMemo, useState, Fragment } from 'react';
import { Element } from 'models/dataMapV2/dto';
import { MapAsset, Cube, RagsAndCubesTree } from '../../index';
import { ELEMENT_SIZE } from '../../utils/businessConsts';
import { isPseudoRag } from '../../utils/calculateAbsoluteDimensions';
import { CanvasCube } from '../CanvasCube';
import { TILE_SIZE } from '../index';
import { changeMapControls, mapControlsStore } from '../model/store';
import { ZoomContext } from '../ZoomWrapper';
import { loadCubeImages } from './loadCubeImages';

function getIsoPoint(x: number, y: number) {
	return [x * 0.707107 + y * -0.707107, x * 0.40558 + y * 0.40558];
}

export const HACK_PLACEMENT_X = -120;
export const HACK_PLACEMENT_Y = -20;

type Props = {
	mapAssets: MapAsset[];
	mapAssetsMap: Map<Element['id'], MapAsset>;
	ragsAndCubes: RagsAndCubesTree;
};

function CubesLayer(props: Props) {
	const { mapAssets, mapAssetsMap, ragsAndCubes } = props;
	const { scale, scaleStep, translate } = useContext(ZoomContext);

	const mapControls = useStore(mapControlsStore);
	const { search, filter, selected, showAssetSensitivity } = mapControls;

	const [needToRefresh, setNeedToRefresh] = useState(0);

	// https://issues.chromium.org/issues/328755781
	useEffect(() => {
		const needToRefreshFunc = () => {
			if (document.visibilityState === 'visible') {
				setNeedToRefresh(+new Date());
			}
		};

		document.addEventListener('visibilitychange', needToRefreshFunc);

		return () => {
			document.removeEventListener('visibilitychange', needToRefreshFunc);
		};
	}, []);

	useEffect(() => {
		loadCubeImages();
	}, []);

	const cubes = useMemo(() => {
		let result: Cube[] = [];

		function traverse(rag: RagsAndCubesTree) {
			if (isPseudoRag(rag)) {
				result = result.concat(rag.data);
			} else {
				rag.children.forEach(traverse);
			}
		}

		traverse(ragsAndCubes);

		return result;
	}, [ragsAndCubes]);

	const filteredAssets = useMemo(
		function () {
			let result = mapAssets;

			if (filter.dataTypes.length) {
				result = result.filter((asset) =>
					filter.dataTypes.some((dataType) => asset.dataTypes.includes(dataType))
				);
			}
			if (filter.namespaces.length) {
				result = result.filter((asset) => filter.namespaces.includes(asset.namespace));
			}
			if (filter.labelKeys.length) {
				result = result.filter((asset) =>
					filter.labelKeys.some((labelKey) => asset.labels.some((label) => label.key === labelKey))
				);
			}
			if (filter.labelValues.length) {
				result = result.filter((asset) =>
					filter.labelValues.some((labelValue) =>
						asset.labels.some((label) => label.value === labelValue)
					)
				);
			}
			if (filter.groups.length) {
				result = result.filter((asset) =>
					filter.groups.some((groupId) => asset.groups.includes(groupId))
				);
			}
			if (filter.clusters.length) {
				result = result.filter((asset) => filter.clusters.includes(asset.cluster_id));
			}
			if (filter.regions.length) {
				result = result.filter((asset) => filter.regions.includes(asset.region));
			}
			if (filter.resourceTypes.length) {
				result = result.filter((asset) => {
					const filterEntityType =
						asset.type === 'sql_db_database'
							? 'sql_db_database'
							: asset.type === 'kafka_instance'
							? 'kafka_instance'
							: asset.type === 's3_bucket'
							? 's3_bucket'
							: 'service';

					return filter.resourceTypes.some(({ id }) => id === filterEntityType);
				});
			}

			return new Set(result.map((asset) => asset.elementId));
		},
		[mapAssets, filter]
	);

	function onElementSelect(id: MapAsset['elementId'], elementType: MapAsset['type']) {
		const type =
			elementType === 'sql_db_database'
				? 'sql_db_database'
				: elementType === 'kafka_instance'
				? 'kafka_instance'
				: elementType === 's3_bucket'
				? 's3_bucket'
				: 'asset';

		const newControls = produce(mapControls, (draft) => {
			const currentlySelectedAsset =
				draft.selected?.type === 'asset' || draft.selected?.type === 's3_bucket'
					? draft.selected.id
					: null;

			if (id === currentlySelectedAsset) {
				draft.selected = null;
			} else {
				draft.selected = { type, id };
			}
		});

		changeMapControls(newControls);
	}

	const viewportX = -translate.y / scale;
	const viewportY = -translate.x / scale;
	const viewportX2 = viewportX + window.innerHeight / scale;
	const viewportY2 = viewportY + window.innerWidth / scale;

	return (
		<Fragment key={needToRefresh}>
			{scaleStep !== 50 &&
				cubes.map((cube) => {
					const element = mapAssetsMap.get(cube.elementId)!;

					let assetSelected: boolean;
					switch (selected?.type) {
						case 's3_bucket': {
							assetSelected = element.elementId === selected.id && element.type === 's3_bucket';
							break;
						}
						case 'sql_db_database': {
							assetSelected =
								element.elementId === selected.id && element.type === 'sql_db_database';
							break;
						}
						case 'kafka_instance': {
							assetSelected =
								element.elementId === selected.id && element.type === 'kafka_instance';
							break;
						}
						case 'asset':
							assetSelected = element.elementId === selected.id;
							break;
						default:
							assetSelected = false;
					}
					const assetFilteredOut = !filteredAssets.has(element.elementId);

					const [x, y] = getIsoPoint(
						cube.dimensions.absoluteX * TILE_SIZE,
						cube.dimensions.absoluteY * TILE_SIZE
					);
					const position = {
						top: y + HACK_PLACEMENT_Y,
						left: x + HACK_PLACEMENT_X,
						width: ELEMENT_SIZE * TILE_SIZE,
						height: ELEMENT_SIZE * TILE_SIZE,
					};

					const visible =
						position.left + position.width > viewportY &&
						position.top + position.height > viewportX &&
						position.left < viewportY2 &&
						position.top < viewportX2;

					if (!visible) return null;

					return (
						<CanvasCube
							key={cube.id}
							element={element}
							faded={assetFilteredOut}
							sensitive={showAssetSensitivity}
							selected={assetSelected}
							onSelect={() => onElementSelect(element.elementId, element.type)}
							highlight={search.searchString}
							position={position}
						/>
					);
				})}
		</Fragment>
	);
}

export default CubesLayer;
