import { get } from 'api/Api';
import checkResponse from 'services/api/checkResponse';
import { DataCombinationsList } from './dto';
import { dataCombinationsExample } from './examples';

async function getDataCombinations() {
	return get<DataCombinationsList>('v2/data-combinations').then(
		checkResponse(dataCombinationsExample)
	);
}

export { getDataCombinations };
