import jp from 'jsonpath';
import { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { CurtainShift } from 'components/Curtain';
import Typo from 'components/typography/Typo';
import { DataTypeItem } from 'models/dataTypes/dto';
import { getJsonLocation } from './helpers/jsonPath';
import styles from './index.module.css';
import { Line } from './Line';
import { TableOfTypes, TableOfTypesRow, TableOfTypesGroupRow } from './TableOfTypes';
import { Viewer } from './Viewer';

//
// Types for viewer
//

type Detection = {
	dataType: DataTypeItem['id'];
	jsonPath: string;
	column: number;
	len: number;
};

type ViewerSize = 'M' | 'S';

type DocumentLine = {
	line: string; // without newlines
	detections: Detection[];
};

type Document = DocumentLine[];

//
// Types for table
//

type TableDetection = {
	parentPath: string;
	row: number;
	parameter: number | string;
	dataType: DataTypeItem['id'];
};

type TableDetectionsGroup = {
	parentPath: string;
	detections: TableDetection[];
};

type TableDocument = {
	documentNumber: number;
	detections: TableDetection[];
	groups: TableDetectionsGroup[];
};

//
// Helpers
//

function detectionToMark(detection: Detection) {
	return {
		dataTypeId: detection.dataType,
		isFP: false,
		isManual: false,
		isCorrected: false,
	};
}

//
// Component.
// Deals with curtain, active line; and prepares data for simpler components.
//

type Props = {
	documents: Document[];
	dontGroupDetectionsInTable?: boolean;
	lineWrap?: boolean;
	size?: ViewerSize;
};

function SampleViewer(props: Props) {
	const history = useHistory();
	const location = useLocation();

	const { documents, dontGroupDetectionsInTable = false, lineWrap, size = 'M' } = props;

	const [curtainOpen, setCurtainOpen] = useState(true);
	const [activePath, setActivePath] = useState<string | null>(null);

	useEffect(() => {
		const row = location.hash.match(/^#(\S*)$/)?.[1];

		if (!row) return;

		setActivePath(row);
	}, [location.hash]); // TODO does not work on new browser tab. For DIM samples too.

	function handleDetectionClick(row: number) {
		// TODO: for now this logic depends in size of SampleView
		// Maybe we should remove this `if` block from EventSample for good
		if (size === 'M') {
			const hash = `#${row}`;
			history.push(location.pathname + location.search + hash);
		}

		setActivePath(String(row));
	}

	const viewerData = useMemo(() => {
		const result: {
			detectionsForMarks: ReturnType<typeof detectionToMark>[];
			lineNumber: number;
			lineData: DocumentLine;
		}[] = [];
		let lineNumber = 1;

		for (const doc of documents) {
			for (const lineData of doc) {
				result.push({
					lineData,
					lineNumber: lineNumber++,
					detectionsForMarks: lineData.detections.map(detectionToMark),
				});
			}
		}

		return result;
	}, [documents]);

	const documentDetections = useMemo(() => {
		const result: TableDocument[] = [];

		const documentNumber = 1;
		let lineNumber = 1;

		const resultDoc: TableDocument = {
			documentNumber,
			detections: [],
			groups: [],
		};

		for (const doc of documents) {
			/* --- This is optional implementation that splits detections between documents, part 1.

			const resultDoc: TableDocument = {
				documentNumber: documentNumber++,
				detections: [],
				groups: [],
			}

			*/

			for (const lineData of doc) {
				if (lineData.detections.length > 0) {
					resultDoc.detections = resultDoc.detections.concat(
						lineData.detections.map((detection) => {
							const { dataType, jsonPath } = detection;

							let parameter;
							let parentPath;

							try {
								const pathParts = getJsonLocation(jsonPath);
								parameter = pathParts.pop()!; // Now pathParts is parent location
								parentPath = jp.stringify(['$', ...pathParts]).replace(/^\$\.?/, '');
							} catch {
								parameter = '—';
								parentPath = '';
							}

							return {
								parentPath,
								row: lineNumber,
								parameter,
								dataType,
							};
						})
					);
				}

				lineNumber++;
			}

			/* --- This is optional implementation that splits detections between documents, part 2.

			if (resultDoc.detections.length === 0) continue; // don't push to result

			resultDoc.detections.sort((a, b) => a.parentPath.localeCompare(b.parentPath));

			let parentPath = null;
			for (const tableDetection of resultDoc.detections) {
				if (tableDetection.parentPath !== parentPath) {
					parentPath = tableDetection.parentPath;
					resultDoc.groups.push({ parentPath, detections: [] });
				}

				resultDoc.groups.at(-1)?.detections.push(tableDetection);
			}

			result.push(resultDoc);

			*/
		}

		/* --- This is current implememntation that merges all detections to one document */
		const sortedDetections = [...resultDoc.detections].sort((a, b) =>
			a.parentPath.localeCompare(b.parentPath)
		);
		let parentPath = null;

		for (const tableDetection of sortedDetections) {
			if (tableDetection.parentPath !== parentPath) {
				parentPath = tableDetection.parentPath;
				resultDoc.groups.push({ parentPath, detections: [] });
			}

			resultDoc.groups.at(-1)?.detections.push(tableDetection);
		}

		result.push(resultDoc);
		/* End */

		return result;
	}, [documents]);

	const leftCurtainPart = useMemo(
		() => (
			<Viewer
				data={viewerData}
				activeLine={activePath}
				lineTemplate={Line}
				lineWrap={lineWrap}
				size={size}
			/>
		),
		[viewerData, activePath]
	);

	const rightCurtainPart = useMemo(() => {
		const isMultiple = documentDetections.length > 1;

		return (
			<>
				<Typo
					variant={size === 'S' ? 'D/Medium/Body-S' : 'D/Medium/Body'}
					className={styles.rightPartTitle}
				>
					Fields with sensitive data
				</Typo>

				<TableOfTypes size={size}>
					{documentDetections.map((doc) => {
						const { documentNumber, groups, detections } = doc;

						return (
							<div key={documentNumber}>
								{isMultiple && (
									<Typo
										variant="D/Medium/Body-S"
										className={styles.tableDocumentHeader}
										data-test="document-in-table"
									>
										Document #{documentNumber}
									</Typo>
								)}

								{dontGroupDetectionsInTable
									? detections.map((detection, idx) => {
											const { parentPath, row, parameter, dataType } = detection;

											return (
												<TableOfTypesRow
													key={`${row} ${parameter} ${dataType} ${idx}`}
													row={row}
													parameter={parameter}
													parentPath={parentPath}
													dataType={dataType}
													onClick={handleDetectionClick}
												/>
											);
									  })
									: groups.map((group) => {
											const { parentPath } = group;

											return (
												<div key={parentPath}>
													{parentPath.length > 0 && (
														<TableOfTypesGroupRow label={parentPath} size={size} />
													)}

													{group.detections.map((detection) => {
														const { row, parameter, dataType } = detection;

														return (
															<TableOfTypesRow
																key={`${row} ${parameter}`}
																row={row}
																parameter={parameter}
																dataType={dataType}
																onClick={handleDetectionClick}
																size={size}
															/>
														);
													})}
												</div>
											);
									  })}
							</div>
						);
					})}
				</TableOfTypes>
			</>
		);
	}, [documentDetections]);

	return (
		<div className={styles.wrapper}>
			<CurtainShift
				open={curtainOpen}
				onClose={() => {
					setCurtainOpen(false);
				}}
				onOpen={() => {
					setCurtainOpen(true);
				}}
				classes={{ rightPartOpen: styles.curtain, root: styles[size] }}
				leftPart={leftCurtainPart}
				rightPart={rightCurtainPart}
				size={size}
			/>
		</div>
	);
}

export type { ViewerSize };
export { SampleViewer };
