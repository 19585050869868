import { useStore } from 'effector-react';
import { useState } from 'react';
import { Tab, TabPanel, Tabs } from 'components/Tabs';
import Typo from 'components/typography/Typo';
import { AssetType } from 'models/assets/dto';
import { gatewaysNamesById } from 'models/gateways/store';
import { getStringDividedByDot } from 'services/strings';
import { getAssetNameByType } from 'views/common/AssetNameWithIcon';
import { MapAsset } from '../../../index';
import CloseButton from '../CloseButton';
import commonStyles from '../index.module.css';
import Details from './Details';
import Summary from './Summary';

type Props = {
	asset: MapAsset;
};

function AssetDetails(props: Props) {
	const { asset } = props;

	const gatewaysNamesStore = useStore(gatewaysNamesById);
	const [tab, setTab] = useState<'summary' | 'details'>('summary');

	const displayName = getAssetNameByType(asset.type as AssetType, asset.name);
	const { cluster_id, namespace, k8s_types } = asset;

	return (
		<div className={commonStyles.container} data-test="data-map-details-asset">
			<div className={commonStyles.sidePaddings}>
				<div className={commonStyles.header}>
					<Typo variant="D/Medium/H100-Header">{displayName}</Typo>

					<CloseButton />
				</div>

				<Typo variant="D/Regular/Body-S" color="secondary">
					{getStringDividedByDot(gatewaysNamesStore[cluster_id], namespace, k8s_types)}
				</Typo>

				<Tabs value={tab} onChange={setTab}>
					<Tab value="summary" label="Summary" />
					<Tab value="details" label="Details" />
				</Tabs>
			</div>

			<TabPanel value={tab} index="summary" className={commonStyles.tabPanel}>
				<Summary asset={asset} />
			</TabPanel>

			<TabPanel value={tab} index="details" className={commonStyles.tabPanel}>
				<Details asset={asset} />
			</TabPanel>
		</div>
	);
}

export default AssetDetails;
