import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react';
import { ComponentType, ReactNode } from 'react';
import Alert, { AlertSeverity } from 'components/Alert';
import styles from './index.module.css';

type Props = {
	children: ReactNode;
};

function LayoutMainErrorBoundary(props: Props) {
	const fallback = (
		<Alert severity={AlertSeverity.error} className={styles.alert}>
			Encountered errors while rendering contents.
		</Alert>
	);

	return <SentryErrorBoundary fallback={fallback}>{props.children}</SentryErrorBoundary>;
}

function withErrorBoundary<T extends JSX.IntrinsicAttributes>(Component: ComponentType<T>) {
	return function ComponentWithErrorBoundary(props: T) {
		return (
			<LayoutMainErrorBoundary>
				<Component {...props} />
			</LayoutMainErrorBoundary>
		);
	};
}

export default LayoutMainErrorBoundary;
export { withErrorBoundary };
