import { isPseudoRag, Rag } from './listToTreeByAttributes';

// All numbers (except size) are in 'tiles'. One cube can be 4x4 tiles, gap between cubes is 1 tile, and so on.
type Dimensions = {
	dimensions: {
		x: number;
		y: number;
		absoluteX: number;
		absoluteY: number;
		width: number;
		height: number;
		size: number; // relative size, used for sorting by parent. Has no meaning on cube, only on rag/pseudoRag
	};
};

const initialDimensions: () => Dimensions = () => ({
	dimensions: {
		x: 0,
		y: 0,
		absoluteX: 0,
		absoluteY: 0,
		width: 0,
		height: 0,
		size: 0,
	},
});

function addDimensionPropertyToTree<Element, Level>(
	rag: Rag<Element, Level>
): Rag<Element, Level, Dimensions> {
	if (isPseudoRag(rag)) {
		return {
			...rag,
			...initialDimensions(),
			data: rag.data.map((d) => ({ ...d, ...initialDimensions() })),
		};
	} else {
		return {
			...rag,
			...initialDimensions(),
			children: rag.children.map((c) => addDimensionPropertyToTree(c)),
		};
	}
}

export type { Dimensions };
export default addDimensionPropertyToTree;
