import cn from 'classnames';
import GridCell from 'components/table/GridCell';
import GridRow from 'components/table/GridRow';
import Typo from 'components/typography/Typo';
import { DASH_SIGN } from 'services/strings';
import styles from './index.module.pcss';

export function getEmptyRows(length: number) {
	const rows = [];

	for (let i = 1; i <= 5 - length; i++) {
		rows.push(<EmptyRow key={i} />);
	}

	return rows;
}

function EmptyRow() {
	return (
		<GridRow className={cn(styles.rowContainer, styles.emptyRow)}>
			<GridCell className={styles.cell}>
				<Typo variant="D/Regular/Meta">{DASH_SIGN}</Typo>
			</GridCell>
		</GridRow>
	);
}

export default EmptyRow;
