import cn from 'classnames';
import { useStore } from 'effector-react';
import { MouseEvent } from 'react';
import commonStyles from 'assets/styles/index.module.pcss';
import { CheckboxBase } from 'components/Checkbox';
import { piiFilterStore } from 'components/PiiGlobalFilterV2/model';
import SensitivityCircle from 'components/SensitivityCircle';
import Tooltip from 'components/Tooltip';
import Typo from 'components/typography/Typo';
import { DataTypeItem } from 'models/dataTypes/dto';
import { useSensitivityData } from 'models/dataTypes/hooks';
import { DASH_SIGN } from 'services/strings';
import styles from './index.module.pcss';

interface Props {
	className?: string;
	type: DataTypeItem['id'];
	isFP?: boolean;
	isManual?: boolean;
	dataTest?: string;
	onClick?: (e: MouseEvent<HTMLElement>) => void;
	size?: string;
	highlighted?: boolean;
	custom?: boolean;
}

function PiiType(props: Props) {
	const {
		type,
		className,
		isFP = false,
		isManual = false,
		dataTest,
		onClick,
		size,
		highlighted,
		...rest
	} = props;

	const { sensitivityLevel, piiTypeName, custom } = useSensitivityData(type, isFP);

	return (
		<span
			{...rest}
			className={cn(
				styles.type,
				commonStyles.skeletonBackground,
				className,
				onClick && styles.clickable,
				highlighted && styles.highlighted,
				size === 'extraSmall' && styles.smallChip
			)}
			title={piiTypeName}
			data-test={dataTest}
			onClick={onClick}
		>
			<SensitivityCircle
				sensitivityLevel={sensitivityLevel}
				changed={isFP || isManual}
				custom={custom}
				size={size}
			/>

			<Typo
				variant={size === 'extraSmall' ? 'D/Regular/Meta' : 'D/Regular/Body-S'}
				className={styles.sensitivity}
				component="span"
			>
				{piiTypeName}
			</Typo>
		</span>
	);
}

function MarkedPiiType(props: Props) {
	if (props.isFP || props.isManual) {
		return (
			<Tooltip title={props.isFP ? 'False positive' : 'Manually assigned'}>
				<PiiType {...props} />
			</Tooltip>
		);
	}

	return <PiiType {...props} />;
}

interface CheckedProps extends Props {
	onChange: (dataType: DataTypeItem['id']) => void;
	checked: boolean;
}

function CheckedPiiType({ onChange, className, type, checked, ...props }: CheckedProps) {
	return (
		<div
			className={cn(styles.checkboxContainer, { [styles.checked]: checked })}
			onClick={() => onChange(type)}
		>
			<CheckboxBase size="XS" checked={checked} />
			<PiiType className={cn(className, styles.piiTypeContainer)} type={type} {...props} />
		</div>
	);
}

interface ListProps {
	data: DataTypeItem['id'][];
	noHighlight?: boolean;
	dataTest?: string;
	className?: string;
	chipClassName?: string;
	size?: string;
	filter?: DataTypeItem['id'][];
}

function PiiTypeList(props: ListProps) {
	const { data, noHighlight, dataTest, className, chipClassName, size, filter } = props;
	const piiFilter = useStore(piiFilterStore);

	const filterDataTypes = filter ?? piiFilter.dataTypes;

	return data.length > 0 ? (
		<div className={cn(styles.container, className)} data-test={dataTest}>
			{data.map((type: DataTypeItem['id']) => {
				const highlighted = !noHighlight && filterDataTypes.includes(type);

				return (
					<PiiType
						type={type}
						key={type}
						highlighted={highlighted}
						className={cn(styles.typeItem, chipClassName)}
						size={size}
					/>
				);
			})}
		</div>
	) : (
		<Typo variant="D/Regular/Body-S" dataTest={dataTest} className={className}>
			{DASH_SIGN}
		</Typo>
	);
}

export default PiiType;
export { MarkedPiiType, PiiTypeList, CheckedPiiType };
