import { useMemo } from 'react';
import Button from 'components/form/Button';
import { RouterLink } from 'components/typography/Link';
import Typo from 'components/typography/Typo';
import { PATHS } from 'services/router';
import signUpStyles from '../SignUp/index.module.pcss';

function SSOFailed() {
	const errorMessage = useMemo(() => {
		const params = new URLSearchParams(window.location.search);

		return params.get('message');
	}, []);

	return (
		<div className={signUpStyles.container} data-test="sso-failed-container">
			<div className={signUpStyles.wrapper}>
				<Typo variant="D/Medium/H500-Greeting-Title" className={signUpStyles.title}>
					SSO login failed
				</Typo>

				<p>
					Error occurred while trying to log in to identity provider
					{errorMessage ? `: ${errorMessage}` : ''}.
				</p>

				<RouterLink to={PATHS.SIGN_IN}>
					<Button size="large" className={signUpStyles.formButton}>
						Return to login
					</Button>
				</RouterLink>
			</div>
		</div>
	);
}

export default SSOFailed;
