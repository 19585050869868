import { Redirect, Route, Switch } from 'react-router-dom';
import { PATHS } from 'services/router';
import { DataTypesItem } from 'views/DataTypesV2/DataTypesItem';
import { DataTypesList } from 'views/DataTypesV2/DataTypesList';

export function DataTypesRoute() {
	return (
		<Switch>
			<Route path={PATHS.DATA_TYPES_LIST} component={DataTypesList} exact />
			<Route path={PATHS.DATA_TYPES_ITEM} component={DataTypesItem} />

			<Redirect to={PATHS.DATA_TYPES_LIST} />
		</Switch>
	);
}
