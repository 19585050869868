import { ConfirmModal, getConfirmation } from 'components/ConfirmModal';
import Button from 'components/form/Button';
import { enqueueSnackbar } from 'components/Snackbar';
import Typo from 'components/typography/Typo';
import { AssetJson } from 'models/assets/dto';
import { deleteAssetFx } from 'models/assets/effects';
import { APIError } from 'services/api/httpRequest';
import { goBackByDefault } from 'services/history';
import { PATHS } from 'services/router';
import styles from './index.module.pcss';

interface Props {
	asset: AssetJson;
}

interface IDeleteModal {
	onConfirm: () => void;
	assetName: string;
}

const DeleteModal = async ({ onConfirm, assetName }: IDeleteModal) => {
	const confirmAssetDeletionModal = (
		<ConfirmModal
			title={`Delete ${assetName}`}
			confirmProps={{
				children: 'Delete',
			}}
			onConfirm={onConfirm}
		>
			<Typo className={styles.description} variant="D/Regular/Body-S">
				Do you want to permanently delete this external custom connection?
				<br />
				This action cannot be undone.
			</Typo>
		</ConfirmModal>
	);

	return await getConfirmation(confirmAssetDeletionModal);
};

function AssetDeleteButton({ asset }: Props) {
	const deleteAsset = async () => {
		await DeleteModal({
			assetName: asset.name,
			onConfirm: async () => {
				try {
					await deleteAssetFx(asset.id);
					enqueueSnackbar('Service has been deleted');

					goBackByDefault(PATHS.EXTERNAL_CONNECTIONS);
				} catch (error) {
					if (error instanceof APIError) {
						const { message } = await error.response.json();

						enqueueSnackbar(message);
					}
				}
			},
		});
	};

	return (
		<Button
			data-test="asset-info-delete-button"
			theme="danger"
			color="transparent"
			size="small"
			onClick={deleteAsset}
			className={styles.button}
		>
			Delete connection
		</Button>
	);
}

export default AssetDeleteButton;
export { DeleteModal };
