import { get } from 'api/Api';
import { getAssetsFx } from 'models/assets/effects';
import { assetsMap } from 'models/assets/store';
import { ApiParams } from 'services/api';
import { EndpointsList } from './dto';

async function getEndpoints(apiParams: ApiParams, signal?: AbortSignal) {
	const config = signal ? { signal } : {};

	return get<EndpointsList>('v2/endpoints', apiParams, config).then(async (resp) => {
		let assets = assetsMap.getState();
		const checkAssets = resp.endpoints.every(({ asset }) => assets[asset]);

		if (!checkAssets) {
			await getAssetsFx();
			assets = assetsMap.getState();
		}

		return {
			...resp,
			endpoints: resp.endpoints.map((endpoint) => ({
				...endpoint,
				asset_name: assets[endpoint.asset].name,
				asset_type: assets[endpoint.asset].type,
				asset_is_external: assets[endpoint.asset].is_external,
				namespace: assets[endpoint.asset].namespace,
			})),
		};
	});
}

export { getEndpoints };
