import { get } from 'api/Api';
import { ApiParams } from 'services/api';
import checkResponse from 'services/api/checkResponse';
import { KafkaMessageSchema, KafkaMessageSchemaList } from './dto';
import { kafkaMessageSchemaExample, kafkaMessageSchemaListExample } from './examples';

async function getKafkaMessageSchemasList(
	id: KafkaMessageSchema['id'],
	apiParams: ApiParams,
	signal?: AbortSignal
) {
	const config = signal ? { signal } : {};

	return get<KafkaMessageSchemaList>(`v1/kafka/topics/${id}/schemas`, apiParams, config).then(
		checkResponse(kafkaMessageSchemaListExample)
	);
}

async function getKafkaMessageSchema(schemaId: number, signal?: AbortSignal) {
	const config = signal ? { signal } : {};

	return get<KafkaMessageSchema>(`v1/kafka/schemas/${schemaId}`, undefined, config).then(
		checkResponse(kafkaMessageSchemaExample)
	);
}

export { getKafkaMessageSchemasList, getKafkaMessageSchema };
