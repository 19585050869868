import { del, get, post } from 'api/Api';
import checkResponse from 'services/api/checkResponse';
import { TestWebhook, WebhookSettings, WebhookUpdateSettings } from './dto';
import { testWebhookExample, webhookSettingsExample } from './examples';

async function getWebhookIntegration() {
	return get<WebhookSettings>('v1/webhook-integration').then(checkResponse(webhookSettingsExample));
}

async function updateWebhookSettings(payload: WebhookUpdateSettings) {
	return post<WebhookSettings>('v1/webhook-integration', payload).then(
		checkResponse(webhookSettingsExample)
	);
}

async function deleteWebhookIntegration() {
	return del('v1/webhook-integration');
}

async function testWebhookIntegration() {
	return get<TestWebhook>('v1/webhook-integration/test').then(checkResponse(testWebhookExample));
}

export {
	getWebhookIntegration,
	updateWebhookSettings,
	deleteWebhookIntegration,
	testWebhookIntegration,
};
