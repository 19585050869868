import { createEffect } from 'effector';
import { getKafkaInstanceList } from './api';
import { KafkaInstanceList } from './dto';

/* API */

const getKafkaInstancesFx = createEffect<void, KafkaInstanceList>();

/* IMPLEMENTATION */

// Simple direct-to-api effects

getKafkaInstancesFx.use(getKafkaInstanceList);

export { getKafkaInstancesFx };
