type Point = { x: number; y: number };
type Props = {
	start: Point;
	quadratic: Point;
	end: Point;
};

export const getCurveCenter = ({ start: s, quadratic: q, end: e }: Props): DOMPoint => {
	const syntheticPath = document.createElementNS('http://www.w3.org/2000/svg', 'path');
	syntheticPath.setAttributeNS(null, 'd', `M${s.x} ${s.y} Q${q.x} ${q.y} ${e.x} ${e.y}`);

	const halfOfLength = syntheticPath.getTotalLength() / 2;

	return syntheticPath.getPointAtLength(halfOfLength);
};
