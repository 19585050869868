import { Popover as UiPopover } from '@material-ui/core';
import { ReactNode } from 'react';
import styles from './index.module.css';

type PopoverProps = {
	anchorEl: HTMLElement | null;
	onClose: () => void;
	children: ReactNode;
};

// Common overrides: different backdrop; anchor positioning.
function Popover(props: PopoverProps) {
	const { anchorEl, onClose, children } = props;

	return (
		<UiPopover
			classes={{ paper: styles.popover }}
			anchorEl={anchorEl}
			open={!!anchorEl}
			onClose={onClose}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'left',
			}}
			data-test="select-popover"
		>
			{children}
		</UiPopover>
	);
}

export default Popover;
export type { PopoverProps };
