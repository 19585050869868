import { Tooltip as UiTooltip, TooltipProps } from '@material-ui/core';
import cn from 'classnames';
import { ReactNode } from 'react';
import styles from './index.module.pcss';

interface ITooltipProps extends Omit<TooltipProps, 'children'> {
	theme?: 'light' | 'dark';
	children: ReactNode;
}

function Tooltip({ children, placement = 'bottom', theme = 'light', ...params }: ITooltipProps) {
	return (
		<UiTooltip
			placement={placement}
			arrow
			enterDelay={150}
			classes={{
				tooltip: cn(styles.tooltip, styles[`${theme}Theme`], styles[`${placement}Placement`]),
				arrow: styles.arrow,
				tooltipPlacementBottom: styles.tooltipPlacementBottom,
				tooltipPlacementTop: styles.tooltipPlacementTop,
				tooltipPlacementRight: styles.tooltipPlacementRight,
				tooltipPlacementLeft: styles.tooltipPlacementLeft,
			}}
			{...params}
		>
			{/*
				This span element treated as wrapper for disable elements or for plain function component
				https://github.com/mui/material-ui/issues/31261
				https://mui.com/material-ui/react-tooltip/#disabled-elements
			*/}
			<span className={styles.contentWrapper}>{children}</span>
		</UiTooltip>
	);
}
export type { ITooltipProps };
export default Tooltip;
