import { createStore } from 'effector';
import { GatewayJson, GatewaysJson } from './dto';
import { createGatewayFx, deleteGatewayFx, getGatewaysFx, updateGatewayNameFx } from './effects';

const gatewaysStore = createStore<GatewaysJson>([]);

gatewaysStore.on(getGatewaysFx.doneData, (state: GatewaysJson, data: GatewaysJson) => data);

gatewaysStore.on(createGatewayFx.doneData, (state: GatewaysJson, data: GatewayJson) => {
	return state.concat(data);
});

gatewaysStore.on(updateGatewayNameFx.doneData, (state: GatewaysJson, data: GatewayJson) =>
	state.map((gateway) => (gateway.id === data.id ? data : gateway))
);

gatewaysStore.on(deleteGatewayFx.doneData, (state: GatewaysJson, data: GatewayJson['id']) =>
	state.filter((gateway) => gateway.id !== data)
);

const gatewaysList = gatewaysStore.map((gateways) => gateways);
const gatewaysNames = gatewaysStore.map(
	(gateways) => new Set(gateways.map((gateway) => gateway.name))
);

const gatewaysNamesById = gatewaysStore.map((gateway) => {
	return gateway.reduce(
		(acc, gw) => {
			acc[gw.id] = gw.cluster_name;

			return acc;
		},
		{} as { [key: GatewayJson['id']]: GatewayJson['cluster_name'] }
	);
});

export { gatewaysList, gatewaysNames, gatewaysNamesById };
