import cn from 'classnames';
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import Icon from 'components/Icon';
import { RouterLink } from 'components/typography/Link';
import Typo from 'components/typography/Typo';
import { PATHS } from 'services/router';
import { cutAndInsertDots } from 'services/strings';
import styles from './index.module.css';

interface Step {
	path: string;
	name: string;
}

interface BreadcrumbProps {
	finalStep?: string;
	hideHome?: boolean;
	steps?: Step[];
}

const MAX_LEVEL = 4;
const MAX_STEP_LENGTH = 36;

const accountSteps = [{ path: PATHS.CHANGE_PASSWORD, name: 'Account' }];
const policiesSteps = [{ path: PATHS.POLICY_LIST, name: 'Policies' }];
const dataCatalogSteps = [{ path: PATHS.SERVICE_CATALOG, name: 'Service catalog' }];
const dataStoragesSteps = [{ path: PATHS.DATA_STORAGES, name: 'Data stores' }];
const endpointsSteps = [{ path: PATHS.API_ENDPOINTS, name: 'API endpoints' }];
const internalAssetsSteps = [...dataCatalogSteps, { path: PATHS.SERVICES, name: 'Services' }];
const externalAssetsSteps = [
	...dataCatalogSteps,
	{ path: PATHS.EXTERNAL_CONNECTIONS, name: 'External connections' },
];
const s3BucketsSteps = [...dataStoragesSteps, { path: PATHS.S3_BUCKETS, name: 'S3 buckets' }];

const kafkaTopicsSteps = [...dataStoragesSteps, { path: PATHS.KAFKA_TOPICS, name: 'Kafka topics' }];
const groupsSteps = [{ path: PATHS.ASSET_GROUP_LIST, name: 'Groups' }];
const integrationsSteps = [{ path: PATHS.INTEGRATIONS, name: 'Integrations' }];

const databasesSteps = [...dataStoragesSteps, { path: PATHS.DATABASES, name: 'Databases' }];

function Breadcrumbs({ steps = [], finalStep, hideHome }: BreadcrumbProps) {
	const longPath = steps?.length > MAX_LEVEL;
	let newSteps = [...steps];

	if (longPath) {
		newSteps = newSteps.slice(-1 * MAX_LEVEL);
	}

	const title = [finalStep, newSteps.at?.(-1)?.name, 'Soveren Dashboard']
		.filter((stepName) => !!stepName)
		.join(' | ');

	return (
		<Typo
			className={styles.container}
			variant="D/Regular/Body-S"
			component="div"
			color="secondary"
			dataTest="breadcrumbs-container"
		>
			<Helmet>
				<title>{title}</title>
			</Helmet>

			{!hideHome && (
				<>
					<RouterLink to={PATHS.OVERVIEW} inherit data-test="breadcrumbs-home-step">
						<Icon name="Home/Regular" size={16} />
					</RouterLink>
					<Icon name="ChevronRight/Regular" size={16} />
				</>
			)}
			{longPath && (
				<>
					...
					<Icon name="ChevronRight/Regular" size={16} />
				</>
			)}
			{newSteps.map((step) => {
				let stepName = step.name;

				if (stepName.length > MAX_STEP_LENGTH) {
					stepName = cutAndInsertDots(stepName, MAX_STEP_LENGTH);
				}

				return (
					<Fragment key={`${stepName}-${step.path}`}>
						<RouterLink to={step.path} inherit className={styles.step} data-test="breadcrumbs-step">
							{stepName}
						</RouterLink>

						<Icon name="ChevronRight/Regular" size={16} />
					</Fragment>
				);
			})}

			{finalStep && (
				<span className={cn(styles.lastStep, styles.step)} data-test="breadcrumbs-final-step">
					{longPath ? cutAndInsertDots(finalStep, MAX_STEP_LENGTH) : finalStep}
				</span>
			)}
		</Typo>
	);
}

export {
	Breadcrumbs,
	accountSteps,
	dataCatalogSteps,
	dataStoragesSteps,
	policiesSteps,
	endpointsSteps,
	internalAssetsSteps,
	externalAssetsSteps,
	s3BucketsSteps,
	groupsSteps,
	integrationsSteps,
	kafkaTopicsSteps,
	databasesSteps,
};

export type { BreadcrumbProps };
