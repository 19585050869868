import { CountersJson } from './dto';

const countersJsonExample: CountersJson = {
	endpoints: 51,
	internal_assets: 10,
	external_assets: 1,
	buckets: 42,
	sql_db_databases: 8,
	kafka_instances: 1,
	kafka_topics: 12,
};

export { countersJsonExample };
