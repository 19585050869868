import { DataTypesExtendedResponseList } from './dto';

const dataTypesExtendedJsonExample: DataTypesExtendedResponseList = {
	data_types: [
		// group
		{
			id: 9,
			name: 'PII',
			alias: 'PII',
			known: false,
			custom: false,
			sensitivity: 'High',
			assets: 2678,
			buckets: 0,
			kafka_instances: 4,
			kafka_topics: 5,
			sql_db_databases: 6,
			sql_db_instances: 7,
			sql_db_tables: 8,
			endpoints: 15669,
			last_seen: 1653637900042,
			child_ids: [6, 7, 8],
			is_disabled: false,
		},
		{
			id: 6,
			name: 'Location',
			alias: 'LOCATION',
			known: true,
			custom: false,
			sensitivity: 'High',
			assets: 10,
			buckets: 0,
			kafka_instances: 40,
			kafka_topics: 55,
			sql_db_databases: 63,
			sql_db_instances: 27,
			sql_db_tables: 81,
			endpoints: 17,
			last_seen: 1653636900042,
			child_ids: [],
			is_disabled: false,
		},
		{
			id: 7,
			name: 'Country',
			alias: 'COUNTRY',
			known: true,
			custom: true,
			sensitivity: 'Medium',
			assets: 1,
			buckets: 0,
			kafka_instances: 7,
			kafka_topics: 15,
			sql_db_databases: 26,
			sql_db_instances: 17,
			sql_db_tables: 98,
			endpoints: 8,
			last_seen: 1653635900042,
			child_ids: [],
			is_disabled: false,
		},
		{
			id: 8,
			name: 'Card',
			alias: 'CARD',
			known: false,
			custom: false,
			sensitivity: 'Low',
			assets: 2,
			buckets: 0,
			kafka_instances: 8,
			kafka_topics: 2,
			sql_db_databases: 5,
			sql_db_instances: 10,
			sql_db_tables: 1,
			endpoints: 15669,
			last_seen: 1653637900042,
			child_ids: [],
			is_disabled: false,
		},
		// disabled data-type
		{
			id: 10,
			name: 'CVV',
			alias: 'CVV',
			known: false,
			custom: false,
			sensitivity: 'High',
			assets: 2678,
			buckets: 0,
			kafka_instances: 8,
			kafka_topics: 4,
			sql_db_databases: 5,
			sql_db_instances: 6,
			sql_db_tables: 7,
			endpoints: 15669,
			last_seen: 1653637900042,
			child_ids: [],
			is_disabled: true,
		},
	],
	total: 5,
};

export { dataTypesExtendedJsonExample };
