import { useStore } from 'effector-react';
import { useEffect, useMemo, useState } from 'react';
import { generatePath } from 'react-router';
import { useHistory, useParams } from 'react-router-dom';
import Badge from 'components/Badge';
import { piiFilterStore } from 'components/PiiGlobalFilterV2/model';
import Preloader from 'components/Preloader';
import { TabLink, TabPanel, Tabs } from 'components/Tabs';
import Typo from 'components/typography/Typo';
import { dataCatalogSteps } from 'layouts/AuthorizedWithLeftMenu/Breadcrumbs';
import Header from 'layouts/AuthorizedWithLeftMenu/Header';
import { getAssetCounters } from 'models/assetCounters/api';
import { AssetCountersJson } from 'models/assetCounters/dto';
import { assetRecipientsModel } from 'models/assetDetails/model';
import { getAssetById } from 'models/assets/api';
import { AssetDetailJson } from 'models/assets/dto';
import { updateAssetFx } from 'models/assets/effects';
import { APIError } from 'services/api/httpRequest';
import { toLocaleString } from 'services/numbers';
import { PATHS } from 'services/router';
import { UniversalSearchInLayout } from 'views/common/UniversalSearch';
import Endpoints from 'views/EndpointsV2';
import { AssetConnections } from 'views/SuperAssets/AssetConnections';
import AssetDeleteButton from 'views/SuperAssets/AssetDeleteButton';
import AssetDetails from 'views/SuperAssets/AssetDetails';
import AssetHeader from 'views/SuperAssets/AssetHeader';
import AssetInfo from 'views/SuperAssets/AssetInfo';
import AssetRecipients from 'views/SuperAssets/AssetRecipients';
import DataFlowTable from 'views/SuperAssets/DataFlowTable';

function AssetError() {
	const params: {
		id: string;
	} = useParams();

	return (
		<>
			<Header
				breadcrumbProps={{
					steps: dataCatalogSteps,
					finalStep: 'Not found',
				}}
				titleBlock="Error has occurred while loading service info"
			/>

			<Typo variant="D/Regular/Body-S" color="secondary">
				Asset with id={params.id} not found or was deleted.
			</Typo>
		</>
	);
}

const tabLabels = {
	info: 'Summary',
	'data-flows': 'Data flows',
	connections: 'Connections',
	endpoints: 'Endpoints',
	recipients: 'Recipients',
};

type AssetItemProps = {
	asset: AssetDetailJson;
	counters: AssetCountersJson & { recipients?: number };
	updateAsset: (asset: AssetDetailJson) => Promise<unknown>;
};

function AssetItem(props: AssetItemProps) {
	const { asset, counters, updateAsset } = props;

	const history = useHistory();
	const params: {
		tab?: 'info' | 'data-flows' | 'connections' | 'endpoints' | 'recipients';
	} = useParams();

	const tabLinks = useMemo(
		() => ({
			info: generatePath(PATHS.SERVICE_ITEM, {
				id: asset.id,
				tab: 'info',
			}),
			'data-flows': generatePath(PATHS.SERVICE_ITEM, {
				id: asset.id,
				tab: 'data-flows',
			}),
			connections: generatePath(PATHS.SERVICE_ITEM, {
				id: asset.id,
				tab: 'connections',
			}),
			endpoints: generatePath(PATHS.SERVICE_ITEM, {
				id: asset.id,
				tab: 'endpoints',
			}),
			recipients: generatePath(PATHS.SERVICE_ITEM, {
				id: asset.id,
				tab: 'recipients',
			}),
		}),
		[asset.id]
	);

	// Set default tab, if not specified.
	useEffect(function () {
		if (!params.tab) {
			const path = generatePath(PATHS.SERVICE_ITEM, {
				...params,
				tab: 'info',
			});
			history.replace(path + history.location.search);
		}
	}, []);

	const isOtherIn = asset.type === 'other_in';

	return (
		<>
			<AssetHeader asset={asset} tabLabel={tabLabels[params.tab || 'info']} />

			<Tabs value={tabLinks[params.tab || 'info']}>
				<TabLink value={tabLinks['info']} replace label={tabLabels['info']} />

				<TabLink
					value={tabLinks['data-flows']}
					replace
					label={
						<>
							Data flows
							<Badge badgeContent={toLocaleString(counters.dataflows)} />
						</>
					}
				/>

				<TabLink
					value={tabLinks['connections']}
					replace
					label={
						<>
							Connections
							<Badge badgeContent={toLocaleString(counters.storage_connections)} />
						</>
					}
				/>

				{asset.type === 'internal' && (
					<TabLink
						value={tabLinks['endpoints']}
						replace
						label={
							<>
								{tabLabels['endpoints']}
								<Badge badgeContent={toLocaleString(counters.endpoints)} />
							</>
						}
					/>
				)}

				{asset.type !== 'internal' && (
					<TabLink
						value={tabLinks['recipients']}
						replace
						label={
							<>
								{tabLabels['recipients']}
								{counters.recipients !== undefined && (
									<Badge badgeContent={toLocaleString(counters.recipients)} />
								)}
							</>
						}
					/>
				)}

				{asset.type === 'custom' && <AssetDeleteButton asset={asset} />}

				{params.tab === 'endpoints' && <UniversalSearchInLayout />}
			</Tabs>

			<TabPanel value={params.tab} index="info">
				<AssetInfo asset={asset} updateAsset={updateAsset} />
			</TabPanel>

			<TabPanel value={params.tab} index="data-flows">
				<DataFlowTable assetId={asset.id} />
			</TabPanel>

			<TabPanel value={params.tab} index="connections">
				<AssetConnections assetId={asset.id} />
			</TabPanel>

			{asset.type === 'internal' && (
				<TabPanel value={params.tab} index="endpoints">
					<Endpoints asset={asset.id} totalByAsset={true} />
				</TabPanel>
			)}

			{asset.type !== 'internal' && (
				<TabPanel value={params.tab} index="recipients">
					{isOtherIn ? <AssetRecipients asset={asset} /> : <AssetDetails asset={asset} />}
				</TabPanel>
			)}
		</>
	);
}

// get asset from store
// if asset exists - loading false, else - request assets, and if not exist - fail
// counters?..
const initialCounters = { dataflows: 0, storage_connections: 0, endpoints: 0 };

function AssetItemLoader() {
	const recipientStore = useStore(assetRecipientsModel.store);

	const [isLoading, setLoading] = useState(true);
	const [asset, setAsset] = useState<AssetDetailJson | null>(null);
	const [counters, setCounters] = useState<AssetCountersJson>(initialCounters);
	const { nonEmpty } = useStore(piiFilterStore);

	const params: {
		id: string;
	} = useParams();

	useEffect(() => {
		getAssetById(Number(params.id))
			.then((assetPayload: AssetDetailJson) => {
				setAsset(assetPayload);

				if (assetPayload.type === 'other_in') {
					assetRecipientsModel.fetchFx({
						asset: assetPayload.id,
						search: '',
						invert: false,
					});
				}

				return getAssetCounters(assetPayload.id, nonEmpty);
			})
			.then((countersPayload) => {
				setCounters(countersPayload);
				setLoading(false);
			})
			.catch((error) => {
				if (error instanceof APIError && error.response.status === 404) {
					// It's okay, will be handled graccefully
					setLoading(false);
				} else throw error;
			});
	}, [params.id, nonEmpty]);

	function updateAsset(payload: AssetDetailJson) {
		return updateAssetFx(payload).then((data) => setAsset(data));
	}

	const extendedCounters = useMemo(() => {
		if (asset?.type !== 'other_in') return counters;

		return { ...counters, recipients: recipientStore.total };
	}, [counters, asset, recipientStore.total]);

	return (
		<>
			<Header isLoading={isLoading} />

			<Preloader isLoading={isLoading}>
				{asset === null || asset.is_deleted ? (
					<AssetError />
				) : (
					<AssetItem asset={asset} counters={extendedCounters} updateAsset={updateAsset} />
				)}
			</Preloader>
		</>
	);
}

export default AssetItemLoader;
