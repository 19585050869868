import { useStore } from 'effector-react';
import Label from 'components/Label';
import Switch from 'components/Switch';
import styles from './index.module.css';
import { fpVisibilityStore, setFPVisibility } from './model';

function FPVisibilityToggler() {
	const fpVisibility = useStore(fpVisibilityStore);

	return (
		<Label className={styles.container} dataTest="false-positives-btn">
			<Switch checked={fpVisibility} onChange={({ target }) => setFPVisibility(target.checked)} />
			False positives
		</Label>
	);
}

export default FPVisibilityToggler;
