import ButtonIcon from 'components/ButtonIcon';
import { enqueueSnackbar } from 'components/Snackbar';

type Props = {
	value: string;
	successText?: string;
	className?: string;
};

function CopyToClipboard(props: Props) {
	function onClick() {
		navigator.clipboard.writeText(props.value).then(() => {
			enqueueSnackbar(props.successText || 'Copied to clipboard');
		});
	}

	return <ButtonIcon icon="copyLink" onClick={onClick} className={props.className} />;
}

export default CopyToClipboard;
