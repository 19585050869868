import { combine, createEvent, createStore } from 'effector';
import { DataTypeItem, DataTypeRequest } from 'models/dataTypes/dto';
import { dataTypesById, dataTypesStructuredStore } from 'models/dataTypes/store';
import dropSession from 'services/dropSession';

type PiiFilterState = {
	dataTypes: DataTypeItem['id'][];
	nonEmpty: boolean;
	groups: DataTypeItem['id'][];
	dataTypesOutOfGroups: DataTypeItem['id'][];
};

//
// Setting up initial state
//

const defaultState: PiiFilterState = {
	dataTypes: [],
	nonEmpty: true,
	groups: [],
	dataTypesOutOfGroups: [],
};

let initialState = defaultState;

const localStorageRawState = localStorage.getItem('piiFilter');
if (localStorageRawState !== null) {
	initialState = JSON.parse(localStorageRawState);
}

//
// API
//

const piiFilterStore = createStore<PiiFilterState>(initialState);
const setPiiFilter = createEvent<Partial<PiiFilterState>>();
const clearPiiFilter = createEvent();
const clearPiiFilterForDropSession = createEvent();

piiFilterStore.on(setPiiFilter, (state: PiiFilterState, payload: Partial<PiiFilterState>) => {
	return { ...state, ...payload };
});

const piiFilterWithGroupsStore = combine(
	piiFilterStore,
	dataTypesStructuredStore,
	(piiTypes, dataTypesStructured) => {
		const piiTypeIdsInGroupOrGroups: number[] = [];
		const groups = dataTypesStructured.reduce((acc, group) => {
			const isAllGroup = group.child_items.every(({ id }) => piiTypes.dataTypes.includes(id));

			if (isAllGroup) {
				acc.push(group.id);
				piiTypeIdsInGroupOrGroups.push(group.id);
				piiTypeIdsInGroupOrGroups.push(...group.child_items.map(({ id }) => id));
			}

			return acc;
		}, [] as number[]);

		return {
			...piiTypes,
			groups,
			dataTypesOutOfGroups: piiTypes.dataTypes.filter(
				(piiTypeId) => !piiTypeIdsInGroupOrGroups.includes(piiTypeId)
			),
		};
	}
);

piiFilterStore.on(clearPiiFilter, (state) => ({ ...defaultState, nonEmpty: state.nonEmpty }));
piiFilterStore.on(clearPiiFilterForDropSession, () => defaultState);

function piiFilterToArray(state: PiiFilterState) {
	const dataTypesByIdValues = dataTypesById.getState();

	let asArray: DataTypeRequest = state.groups
		.concat(state.dataTypesOutOfGroups)
		.map((dtId) => dataTypesByIdValues[dtId].id);

	if (asArray.length === 0 && state.nonEmpty) {
		asArray = ['nonempty'];
	}

	return asArray;
}

//
// Watchers
//

piiFilterWithGroupsStore.watch((state: PiiFilterState) => {
	localStorage.setItem('piiFilter', JSON.stringify(state));
});
dropSession.watch(clearPiiFilterForDropSession);

const piiFilterLoaded = piiFilterStore.map((piiFilter, oldPiiFilterStore) => {
	return oldPiiFilterStore !== undefined;
}); // Magical

export {
	piiFilterStore,
	piiFilterWithGroupsStore,
	setPiiFilter,
	clearPiiFilter,
	piiFilterToArray,
	piiFilterLoaded,
};
export type { PiiFilterState };
