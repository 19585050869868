import { createStore } from 'effector';
import { ClientItem, ClientList } from './dto';
import { getClientListFx, updateClientFx } from './effects';

export const clientStore = createStore<ClientItem[]>([]);

clientStore.on(getClientListFx.doneData, (state: ClientItem[], data: ClientList) => data.clients);

// Updating is necessary for name jump preventing
// Otherwise name  will be changed just only client list updating
clientStore.on(updateClientFx.doneData, (state: ClientItem[], data: ClientItem) => {
	return state.map((client) => {
		if (client.client_id === data.client_id) {
			return data;
		}

		return client;
	});
});

const clientList = clientStore.map((clients) => clients);

export { clientList };
