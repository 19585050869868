import { CustomDataTypeRulesResponse, DataTypeResponse, DataTypeResponseList } from './dto';

const dataTypeExample: DataTypeResponse = {
	id: 0,
	alias: 'card',
	child_ids: [9],
	custom: false,
	found_in_dar: false,
	found_in_dim: true,
	is_disabled: false,
	name: 'CARD',
	sensitivity: 'High',
};

const dataTypesExample: DataTypeResponseList = {
	data_types: [dataTypeExample],
};

const customDataTypeRulesResponse: CustomDataTypeRulesResponse = {
	rules: [{ key: 'keyRegexp', value: 'valueRegexp' }],
};

export { dataTypeExample, dataTypesExample, customDataTypeRulesResponse };
