import cn from 'classnames';
import { useState } from 'react';
import Alert, { AlertSeverity } from 'components/Alert';
import styles from './index.module.pcss';

function DesktopViewAlert() {
	const [isOpen, setOpen] = useState(true);

	return (
		<Alert
			severity={AlertSeverity.info}
			className={cn(styles.alert, isOpen ? styles.open : styles.close)}
			onClick={() => setOpen(false)}
			header="This view is not optimized for mobile. Use desktop for the best experience."
		/>
	);
}

export default DesktopViewAlert;
