import { IHeadCell } from 'components/table/EnhancedTableHead';
import { TAssetsItem } from 'models/assetsExtended/dto';

const tableConfig: IHeadCell<TAssetsItem>[] = [
	{
		id: 'name',
		numeric: false,
		label: 'Service',
		sortable: true,
		filter: true,
	},
	{
		id: 'interacts_with',
		numeric: true,
		label: 'Interactions',
		sortable: true,
		filter: false,
	},
	{
		id: 'sensitivity',
		numeric: false,
		label: 'Sensitivity',
		sortable: true,
	},
	{
		id: 'data_types',
		numeric: false,
		label: 'Data types',
		sortable: false,
	},
	{
		id: 'last_seen',
		numeric: false,
		label: 'Last checked',
		sortable: true,
		reversedSort: true,
	},
];

export default tableConfig;
