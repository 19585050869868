import cn from 'classnames';
import { useState } from 'react';
import ButtonIcon from 'components/ButtonIcon';
import Button from 'components/form/Button';
import TextField from 'components/form/TextField';
import { enqueueSnackbar } from 'components/Snackbar';
import rowStyles from 'components/table/GridRow/index.module.pcss';
import { updateExternalAPIToken } from 'models/integrations/externalAPI/api';
import { ExternalAPIToken } from 'models/integrations/externalAPI/dto';
import styles from './index.module.css';

type Props = {
	token: ExternalAPIToken;
	refresh: () => void;
};

function TokenName(props: Props) {
	const { token } = props;

	const [savedName, setSavedName] = useState(token.name);
	const [name, setName] = useState(token.name);
	const [isEditing, setEditing] = useState(false);
	const [isLoading, setLoading] = useState(false);

	function cancelEditing() {
		setName(savedName);
		setEditing(false);
	}

	function startEditMode() {
		setEditing(true);
	}

	async function onSave() {
		if (!name) return;

		setLoading(true);

		const payload = { ...token, name };

		try {
			await updateExternalAPIToken(payload);
			setSavedName(name);
			setEditing(false);
		} catch (e) {
			console.error(e);
			enqueueSnackbar('Something went wrong');
			props.refresh();
		}

		setLoading(false);
	}

	return (
		<div className={styles.nameWrapper}>
			<div className={styles.nameContainer}>
				{!isEditing && (
					<>
						<div className={styles.value}>{savedName}</div>

						<ButtonIcon
							className={cn(styles.pencilButton, rowStyles.showWhenFocus)}
							dataTest="token-name-edit"
							icon="pencil"
							onClick={startEditMode}
							size="XS"
						/>
					</>
				)}

				{isEditing && (
					<>
						<TextField
							autoFocus={true}
							className={styles.nameInputContainer}
							optional={false}
							value={name}
							onChange={(e) => setName(e.target.value)}
							helperText={null}
							size="extraSmall"
							disabled={isLoading}
							InputProps={{
								classes: {
									input: styles.nameInput,
								},
							}}
							data-test="token-name-input"
						/>

						<Button
							size="extraSmall"
							color="tertiary"
							onClick={onSave}
							disabled={!name}
							className={cn(styles.editButton, styles.saveButton)}
							loading={isLoading}
							data-test="token-name-save-button"
						>
							Save
						</Button>

						{!isLoading && (
							<Button
								size="extraSmall"
								color="transparent"
								onClick={cancelEditing}
								className={cn(styles.editButton, styles.cancelButton)}
								data-test="token-name-cancel-button"
							>
								Cancel
							</Button>
						)}
					</>
				)}
			</div>
		</div>
	);
}

export default TokenName;
