import { TOrder } from 'models/common/dto';
import { DataTypeRequest } from 'models/dataTypes/dto';
import { getDataTypesAsApiParam } from 'models/dataTypes/helpers';
import { createTableModel } from 'models/modelUtils/tableModelFactory';
import { ApiParams } from 'services/api';
import { getAssetDataFlows, getAssetConnections } from './api';
import { AssetDataFlowsTableItem, AssetConnectionsTableItem } from './dto';

// Data flows

type AssetDataFlowParams = {
	assetId: number;
	name: string;
	namespace: string;
	'data-types': DataTypeRequest;
	sort: {
		orderBy: keyof AssetDataFlowsTableItem;
		order: TOrder;
	};
};

function fetchDataApi(apiParamsRaw: ApiParams, signal?: AbortSignal) {
	const { assetId, 'data-types': dataTypes, ...apiParams } = apiParamsRaw;

	apiParams['data-types'] = getDataTypesAsApiParam(dataTypes);

	return getAssetDataFlows(Number(assetId), apiParams, signal).then((payload) => {
		return {
			data: payload.dataflows,
			total: payload.total,
			total_filtered: payload.total_filtered,
		};
	});
}

function createAssetDataFlowModel(
	initialParams: AssetDataFlowParams,
	fetchFxOnInit: boolean = true
) {
	return createTableModel<AssetDataFlowsTableItem, AssetDataFlowParams>(
		initialParams,
		fetchDataApi,
		fetchFxOnInit
	);
}

//
// Instantiate model with initial params, but do not fetch data from server.
//

const initialParams: AssetDataFlowParams = {
	assetId: -1,
	name: '',
	namespace: '',
	'data-types': [],
	sort: {
		orderBy: 'sensitivity',
		order: 'desc',
	},
};

const assetDataFlowModel = createAssetDataFlowModel(initialParams, false);

// Connections

type AssetConnectionParams = {
	assetId: number;
	name: string;
	'data-types': DataTypeRequest;
	sort: {
		orderBy: keyof AssetConnectionsTableItem;
		order: TOrder;
	};
};

function fetchConnectionDataApi(apiParamsRaw: ApiParams, signal?: AbortSignal) {
	const { assetId, 'data-types': dataTypes, ...apiParams } = apiParamsRaw;

	apiParams['data-types'] = getDataTypesAsApiParam(dataTypes);

	return getAssetConnections(Number(assetId), apiParams, signal).then((payload) => {
		return {
			data: payload.dataflows,
			total: payload.total,
			total_filtered: payload.total_filtered,
		};
	});
}

function createAssetConnectionModel(
	initialConnectionParams: AssetConnectionParams,
	fetchFxOnInit: boolean = true
) {
	return createTableModel<AssetConnectionsTableItem, AssetConnectionParams>(
		initialConnectionParams,
		fetchConnectionDataApi,
		fetchFxOnInit
	);
}

//
// Instantiate model with initial params, but do not fetch data from server.
//

const initialConnectionParams: AssetConnectionParams = {
	assetId: -1,
	name: '',
	'data-types': [],
	sort: {
		orderBy: 'sensitivity',
		order: 'desc',
	},
};

const assetConnectionModel = createAssetConnectionModel(initialConnectionParams, false);

export { assetDataFlowModel, assetConnectionModel };
