import { useMemo, useState } from 'react';
import { PiiTypeList } from 'components/PiiType';
import { enqueueSnackbar } from 'components/Snackbar';
import Tooltip from 'components/Tooltip';
import Typo from 'components/typography/Typo';
import { updateKafkaTopic } from 'models/kafkaTopics/api';
import { KafkaTopicItem, KafkaTopicTableItem } from 'models/kafkaTopics/dto';
import { APIError } from 'services/api/httpRequest';
import { lastSeen } from 'services/lastSeen';
import { toLocaleString } from 'services/numbers';
import { DASH_SIGN } from 'services/strings';
import { EditableInput } from 'views/common/EditableInput';
import styles from './index.module.css';

type Props = {
	topic: KafkaTopicTableItem;
};

function Summary(props: Props) {
	const [topic, setTopic] = useState(props.topic);

	const lastSeenValue = useMemo(
		() => lastSeen({ timestamp: topic.last_seen, short: true }),
		[topic.last_seen]
	);

	const onSave = async (parameter: keyof KafkaTopicItem, value: string) => {
		if (value.length > 200) {
			return 'The description is more than 200 symbols.';
		}

		try {
			const updated = await updateKafkaTopic({ ...topic, [parameter]: value });
			setTopic({ ...topic, ...updated });
		} catch (error) {
			if (error instanceof APIError && error.response.status === 400) {
				const { message } = await error.response.json();
				return message;
			}

			const errorMessage = 'Something went wrong';
			enqueueSnackbar(errorMessage);
			return errorMessage;
		}

		return null;
	};

	const partitionsCount = topic.partitions > 0 ? toLocaleString(topic.partitions) : DASH_SIGN;
	const messagesCount = topic.messages_count > 0 ? toLocaleString(topic.messages_count) : DASH_SIGN;

	return (
		<div className={styles.detailsGrid} data-test="kafka-topic-info-container">
			<Typo variant="D/Regular/Meta-CAP" color="secondary">
				Description
			</Typo>
			<EditableInput
				dataTest="kafka-topic-info-description"
				value={topic.description}
				onSave={(value: string) => onSave('description', value)}
				className={styles.editableInput}
				placeholder="Enter description"
				multiline
			/>

			<Typo variant="D/Regular/Meta-CAP" color="secondary">
				Owner
			</Typo>
			<EditableInput
				value={topic.owner}
				onSave={(value: string) => onSave('owner', value)}
				className={styles.editableInput}
				placeholder="Enter owner’s name"
				dataTest="kafka-topic-info-owner"
			/>

			<Typo variant="D/Regular/Meta-CAP" color="secondary">
				Partitions
			</Typo>
			<Typo
				variant="D/Regular/Body-S"
				className={styles.cellWithButton}
				data-test="kafka-topic-info-partitions"
			>
				{partitionsCount}
			</Typo>

			<Typo variant="D/Regular/Meta-CAP" color="secondary">
				Messages
			</Typo>
			<Typo variant="D/Regular/Body-S" data-test="kafka-topic-info-messages">
				{messagesCount}
			</Typo>

			<Typo variant="D/Regular/Meta-CAP" color="secondary">
				Cluster
			</Typo>
			<Typo variant="D/Regular/Body-S" data-test="kafka-topic-info-cluster">
				{topic.cluster}
			</Typo>

			<Typo variant="D/Regular/Meta-CAP" color="secondary">
				Data types
			</Typo>
			<PiiTypeList data={topic.data_types} noHighlight dataTest="kafka-topic-info-data-types" />

			<Typo variant="D/Regular/Meta-CAP" color="secondary">
				Last checked
			</Typo>
			<Typo variant="D/Regular/Body-S" data-test="kafka-topic-info-last-checked">
				<Tooltip title={lastSeenValue.date} className={styles.detailsValue}>
					{lastSeenValue.diff}
				</Tooltip>
			</Typo>
		</div>
	);
}

export { Summary };
