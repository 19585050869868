import { TAssets } from './dto';

const assetsExtendedJsonExample: TAssets = {
	total: 4,
	// total_filtered: 2, // Optional - so should not be in example, otherwise errors will be triggered.
	assets: [
		{
			cluster_id: 1,
			data_types: [3, 4, 6, 11],
			endpoints: 49,
			id: 21,
			interacts_with: 2,
			is_external: false,
			is_deleted: false,
			k8s_types: ['ClusterIP', 'LoadBalancer'],
			last_seen: 1666859099153,
			name: 'stats',
			namespace: '',
			sensitivity: 'High',
			type: 'internal',
			groups: [20230302],
		},
		{
			cluster_id: 1,
			data_types: [3, 4, 6, 11],
			endpoints: 55,
			id: 6,
			interacts_with: 7,
			is_external: false,
			is_deleted: false,
			k8s_types: ['ClusterIP', 'LoadBalancer'],
			last_seen: 1666854589914,
			name: 'proxy',
			namespace: '',
			sensitivity: 'High',
			type: 'internal',
			groups: [20230302, 20230304],
		},
		{
			cluster_id: 1,
			data_types: [3, 4, 6],
			endpoints: 51,
			id: 3679129,
			interacts_with: 4,
			is_external: false,
			is_deleted: false,
			k8s_types: ['ClusterIP', 'LoadBalancer'],
			last_seen: 1666953060527,
			name: 'proxy',
			namespace: 'smat',
			sensitivity: 'High',
			type: 'internal',
			groups: [20230302],
		},
		{
			cluster_id: 1,
			data_types: [3, 4, 6],
			endpoints: 30,
			id: 3679106,
			interacts_with: 3,
			is_external: false,
			is_deleted: false,
			k8s_types: ['ClusterIP'],
			last_seen: 1666952118396,
			name: 'stats-dashboard',
			namespace: 'smat',
			sensitivity: 'High',
			type: 'internal',
			groups: [20230302],
		},
	],
};

export { assetsExtendedJsonExample };
