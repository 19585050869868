import { useEffect, useState } from 'react';
import Preloader from 'components/Preloader';
import { EventJson } from 'models/eventsV2/dto';
import { getSingleEventFx } from 'models/eventsV2/model';
import EventDetails from '../EventDetails';
import styles from './index.module.pcss';

type Props = {
	id: number;
	openCurtain: (open: boolean) => void;
};

function EventDrawer({ id, openCurtain }: Props) {
	const [isLoading, setLoading] = useState(false);

	const [selectedEvent, setSelectedEvent] = useState<EventJson | null>(null);

	useEffect(() => {
		if (!id) return;

		setLoading(true);
		openCurtain(true);

		getSingleEventFx(id).then((event: EventJson) => {
			setLoading(false);
			setSelectedEvent(event);
		});
	}, [id]);

	return (
		<div className={styles.drawerContent}>
			<Preloader isLoading={isLoading}>
				{selectedEvent ? <EventDetails event={selectedEvent} /> : null}
			</Preloader>
		</div>
	);
}

export default EventDrawer;
