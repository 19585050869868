import { InputAdornment } from '@material-ui/core';
import { MouseEvent, useState } from 'react';
import ButtonIcon from 'components/ButtonIcon';
import TextField, { TextFieldProps, TextFieldInFormProps } from 'components/form/TextField';
import styles from './index.module.css';

function endAdornment(
	showPassword: boolean,
	handleClickShowPassword: () => void,
	disabled?: boolean
) {
	const handleMouseDownPassword = (event: MouseEvent) => {
		event.preventDefault();
		handleClickShowPassword();
	};

	return (
		<InputAdornment position="end" onMouseDown={handleMouseDownPassword}>
			<ButtonIcon
				color="icon/secondary"
				disabled={disabled}
				icon={showPassword ? 'Eye/Filled' : 'EyeOff/Filled'}
				onClick={handleClickShowPassword}
				size="M"
			/>
		</InputAdornment>
	);
}

function PasswordField<T>(props: TextFieldProps | TextFieldInFormProps<T>) {
	const [showPassword, setShowPassword] = useState(false);
	const handleClickShowPassword = () => setShowPassword(!showPassword);
	const inputProps = {
		type: showPassword ? 'text' : 'password',
		endAdornment: endAdornment(showPassword, handleClickShowPassword, props.disabled),
	};

	return (
		<TextField
			InputProps={{ ...inputProps, classes: { adornedEnd: styles.adornedEnd } }}
			{...props}
		/>
	);
}

export default PasswordField;
