import {
	AddCircleRegular,
	AddFilled,
	AddRegular,
	AppsAddIn24Filled,
	AppsAddIn24Regular,
	ArrowExportLtrRegular,
	ArrowExportRtlRegular,
	ArrowUpRegular,
	AutoFitWidthRegular,
	CheckboxChecked16Filled,
	CheckboxChecked20Filled,
	CheckboxChecked24Filled,
	CheckboxIndeterminate16Regular,
	CheckboxIndeterminate20Regular,
	CheckboxIndeterminate24Regular,
	CheckmarkCircle16Filled,
	ChevronDownRegular,
	ChevronRightRegular,
	Clock20Filled,
	Collections24Filled,
	Collections24Regular,
	ContactCardFilled,
	ContactCardRegular,
	DatabaseFilled,
	DatabaseRegular,
	Delete20Filled,
	Dismiss24Regular,
	DismissCircle16Filled,
	DockRowFilled,
	DockRowRegular,
	EyeFilled,
	EyeOffFilled,
	FilterRegular,
	HomeFilled,
	HomeRegular,
	InfoFilled,
	InfoRegular,
	LinkRegular,
	MapFilled,
	MapRegular,
	MoreHorizontalRegular,
	Open20Regular,
	PeopleTeamFilled,
	PeopleTeamRegular,
	PersonFilled,
	PersonRegular,
	PlugDisconnectedFilled,
	PlugDisconnectedRegular,
	ProhibitedRegular,
	PulseFilled,
	PulseRegular,
	RadarFilled,
	ShieldFilled,
	ShieldRegular,
	TagFilled,
	TagRegular,
	LockClosedRegular,
	ErrorCircleFilled,
} from '@fluentui/react-icons';
import cn from 'classnames';
import * as React from 'react';
import { CSSProperties, FunctionComponent, HTMLAttributes, SVGAttributes } from 'react';
import APIEndpointsFilled from 'assets/icons/APIEndpointsFilled.svg';
import APIEndpointsRegular from 'assets/icons/APIEndpointsRegular.svg';
import arrowSwapActiveSVG from 'assets/icons/arrowSwapActive.svg';
import arrowSwapBothSVG from 'assets/icons/arrowSwapBoth.svg';
import arrowSwapForwardSVG from 'assets/icons/arrowSwapForward.svg';
import chartLineSVG from 'assets/icons/chartLine.svg';
import checkSVG from 'assets/icons/check.svg';
import CopyCustomFilledSVG from 'assets/icons/CopyCustomFilled.svg';
import copyLinkSVG from 'assets/icons/copyLink.svg';
import DangerFilledRedSVG from 'assets/icons/DangerFilledRed.svg';
import jiraInvertedSVG from 'assets/icons/jiraInverted.svg';
import linkSVG from 'assets/icons/link.svg';
import LockSVG from 'assets/icons/Lock.svg';
import MagicWandRegularSVG from 'assets/icons/MagicWandRegular.svg';
import MeshNetworkRegular from 'assets/icons/MeshNetworkRegular.svg';
import minusSVG from 'assets/icons/minus.svg';
import pencilSVG from 'assets/icons/pencil.svg';
import searchSVG from 'assets/icons/search.svg';
import SoverenFilledSVG from 'assets/icons/SoverenFilled.svg';
import successSVG from 'assets/icons/success.svg';
import tlsFilledSVG from 'assets/icons/TLSFilled.svg';
import tlsRegularSVG from 'assets/icons/TLSRegular.svg';
import triangleDownSVG from 'assets/icons/triangleDown.svg';
import warningSVG from 'assets/icons/warning.svg';
import styles from './index.module.css';

type TIconMap = Record<IconName, FunctionComponent<HTMLAttributes<SVGElement>>>;
type TFluentIconMap = Record<
	FluentIconName,
	{
		(
			props: SVGAttributes<SVGElement> & {
				primaryFill?: string | undefined;
				className?: string | undefined;
				filled?: boolean | undefined;
				title?: string | undefined;
			}
		): JSX.Element;
		displayName?: string | undefined;
	}
>;

type FluentIconName =
	| 'Add/Regular'
	| 'Add/Filled'
	| 'AddCircle/Regular'
	| 'AppsAddIn/Regular'
	| 'AppsAddIn/Filled'
	| 'ArrowExportRtl/Regular'
	| 'ArrowExportLtr/Regular'
	| 'ArrowUp/Regular'
	| 'Clock/Filled'
	| 'CheckboxChecked16/Filled'
	| 'CheckboxChecked20/Filled'
	| 'CheckboxChecked24/Filled'
	| 'CheckboxIndeterminate16/Regular'
	| 'CheckboxIndeterminate20/Regular'
	| 'CheckboxIndeterminate24/Regular'
	| 'CheckmarkCircle/Filled'
	| 'ChevronRight/Regular'
	| 'ChevronDown/Regular'
	| 'Collections/Filled'
	| 'Collections/Regular'
	| 'ContactCard/Regular'
	| 'ContactCard/Filled'
	| 'Database/Regular'
	| 'Database/Filled'
	| 'Delete/Filled'
	| 'Dismiss/Regular'
	| 'DismissCircle/Filled'
	| 'DockRow/Filled'
	| 'DockRow/Regular'
	| 'Filter/Regular'
	| 'FitWidth/Regular'
	| 'Home/Filled'
	| 'Home/Regular'
	| 'Info/Filled'
	| 'Info/Regular'
	| 'Open/Regular'
	| 'Shield/Filled'
	| 'Shield/Regular'
	| 'Map/Filled'
	| 'Link/Regular'
	| 'LockClosed/Regular'
	| 'Map/Regular'
	| 'More/Regular'
	| 'PeopleTeam/Filled'
	| 'PeopleTeam/Regular'
	| 'Person/Regular'
	| 'Person/Filled'
	| 'PlugDisconnected/Regular'
	| 'PlugDisconnected/Filled'
	| 'Pulse/Filled'
	| 'Prohibited/Regular'
	| 'Pulse/Regular'
	| 'Radar/Filled'
	| 'Eye/Filled'
	| 'EyeOff/Filled'
	| 'Tag/Regular'
	| 'Tag/Filled'
	| 'ErrorCircle/Filled';

type IconName =
	| 'arrowSwapBoth'
	| 'arrowSwapForward'
	| 'arrowSwapActive'
	| 'chartLine'
	| 'check'
	| 'copyLink'
	| 'jiraInverted'
	| 'link'
	| 'lock'
	| 'minus'
	| 'pencil'
	| 'search'
	| 'success'
	| 'triangleDown'
	| 'warning'
	| 'CopyCustom/Filled'
	| 'Danger/FilledRed'
	| 'MagicWand/Regular'
	| 'APIEndpoints/Regular'
	| 'APIEndpoints/Filled'
	| 'Soveren/Filled'
	| 'tlsRegular'
	| 'tlsFilled'
	| 'MeshNetwork/Regular';

interface IconProps {
	name: IconName | FluentIconName;
	size?: 8 | 10 | 12 | 16 | 18 | 20 | 24 | 28 | 32 | 36 | 40 | 44 | 48;
	className?: string;
	color?: string;
	dataTest?: string;
}

const customIconsMap: TIconMap = {
	arrowSwapBoth: arrowSwapBothSVG,
	arrowSwapForward: arrowSwapForwardSVG,
	arrowSwapActive: arrowSwapActiveSVG,
	chartLine: chartLineSVG,
	check: checkSVG,
	copyLink: copyLinkSVG,
	jiraInverted: jiraInvertedSVG,
	link: linkSVG,
	lock: LockSVG,
	minus: minusSVG,
	pencil: pencilSVG,
	search: searchSVG,
	success: successSVG,
	triangleDown: triangleDownSVG,
	warning: warningSVG,
	tlsRegular: tlsRegularSVG,
	tlsFilled: tlsFilledSVG,

	'CopyCustom/Filled': CopyCustomFilledSVG,
	'Danger/FilledRed': DangerFilledRedSVG,
	'MagicWand/Regular': MagicWandRegularSVG,
	'Soveren/Filled': SoverenFilledSVG,
	'APIEndpoints/Regular': APIEndpointsRegular,
	'APIEndpoints/Filled': APIEndpointsFilled,
	'MeshNetwork/Regular': MeshNetworkRegular,
};

const fluentIconsMap: TFluentIconMap = {
	'Add/Regular': AddRegular,
	'Add/Filled': AddFilled,
	'AddCircle/Regular': AddCircleRegular,
	'AppsAddIn/Regular': AppsAddIn24Regular,
	'AppsAddIn/Filled': AppsAddIn24Filled,
	'ArrowExportRtl/Regular': ArrowExportRtlRegular,
	'ArrowExportLtr/Regular': ArrowExportLtrRegular,
	'ArrowUp/Regular': ArrowUpRegular,
	'Clock/Filled': Clock20Filled,
	'CheckboxChecked16/Filled': CheckboxChecked16Filled,
	'CheckboxChecked20/Filled': CheckboxChecked20Filled,
	'CheckboxChecked24/Filled': CheckboxChecked24Filled,
	'CheckboxIndeterminate16/Regular': CheckboxIndeterminate16Regular,
	'CheckboxIndeterminate20/Regular': CheckboxIndeterminate20Regular,
	'CheckboxIndeterminate24/Regular': CheckboxIndeterminate24Regular,
	'CheckmarkCircle/Filled': CheckmarkCircle16Filled,
	'ChevronRight/Regular': ChevronRightRegular,
	'ChevronDown/Regular': ChevronDownRegular,
	'Collections/Regular': Collections24Regular,
	'Collections/Filled': Collections24Filled,
	'Database/Regular': DatabaseRegular,
	'Database/Filled': DatabaseFilled,
	'DockRow/Filled': DockRowFilled,
	'DockRow/Regular': DockRowRegular,
	'Delete/Filled': Delete20Filled,
	'Dismiss/Regular': Dismiss24Regular,
	'DismissCircle/Filled': DismissCircle16Filled,
	'Eye/Filled': EyeFilled,
	'EyeOff/Filled': EyeOffFilled,
	'Filter/Regular': FilterRegular,
	'FitWidth/Regular': AutoFitWidthRegular,
	'Home/Filled': HomeFilled,
	'Home/Regular': HomeRegular,
	'Info/Filled': InfoFilled,
	'Info/Regular': InfoRegular,
	'Open/Regular': Open20Regular,
	'Shield/Filled': ShieldFilled,
	'Shield/Regular': ShieldRegular,
	'Link/Regular': LinkRegular,
	'LockClosed/Regular': LockClosedRegular,
	'Map/Filled': MapFilled,
	'Map/Regular': MapRegular,
	'More/Regular': MoreHorizontalRegular,
	'PeopleTeam/Filled': PeopleTeamFilled,
	'PeopleTeam/Regular': PeopleTeamRegular,
	'Person/Regular': PersonRegular,
	'Person/Filled': PersonFilled,
	'PlugDisconnected/Regular': PlugDisconnectedRegular,
	'PlugDisconnected/Filled': PlugDisconnectedFilled,
	'Prohibited/Regular': ProhibitedRegular,
	'Pulse/Regular': PulseRegular,
	'Pulse/Filled': PulseFilled,
	'Radar/Filled': RadarFilled,
	'ContactCard/Regular': ContactCardRegular,
	'ContactCard/Filled': ContactCardFilled,
	'Tag/Filled': TagFilled,
	'Tag/Regular': TagRegular,
	'ErrorCircle/Filled': ErrorCircleFilled,
};

// #typeGuard: TS check that confirms belonging to a specific icon list
function isFluentIcon(name: IconName | FluentIconName): name is FluentIconName {
	return name in fluentIconsMap;
}

function isCustomIcon(name: IconName | FluentIconName): name is IconName {
	return name in customIconsMap;
}

function Icon({ name, className, size = 24, dataTest }: IconProps) {
	const style = { '--icon-size': `${size}px` } as CSSProperties;
	let IconSVG;

	if (isFluentIcon(name)) {
		IconSVG = fluentIconsMap[name];
	} else if (isCustomIcon(name)) {
		IconSVG = customIconsMap[name];
	} else {
		throw new Error(`Icon name ${name} doesn't exist`);
	}

	return (
		IconSVG && (
			<span className={cn(className, styles.container)} style={style}>
				{<IconSVG data-test={dataTest} />}
			</span>
		)
	);
}

export { customIconsMap, fluentIconsMap };
export type { IconProps };
export default Icon;
