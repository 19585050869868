import { useStore } from 'effector-react';
import { useMemo } from 'react';
import { generatePath } from 'react-router';
import { useHistory } from 'react-router-dom';
import Icon from 'components/Icon';
import { PiiTypeList } from 'components/PiiType';
import SensitivityChip from 'components/SensitivityChip';
import GridCell from 'components/table/GridCell';
import GridRow from 'components/table/GridRow';
import Tooltip from 'components/Tooltip';
import Typo from 'components/typography/Typo';
import { TypoHighlighted } from 'components/typography/TypoHighlighted';
import { DarObjectStatusLabel, DatabaseItem } from 'models/databases/dto';
import { databaseInstancesById } from 'models/databases/store';
import { s3RegionsByKeyword } from 'models/s3Regions/store';
import { lastSeen } from 'services/lastSeen';
import { toFileSize, toLocaleString } from 'services/numbers';
import { PATHS } from 'services/router';
import { DASH_SIGN } from 'services/strings';
import {
	AutomaticDiscoveryNonAvailableData,
	AutomaticDiscoveryTooltip,
} from 'views/common/AutomaticDiscovery';
import styles from './index.module.css';

type Props = {
	db: DatabaseItem;
	search: string;
};

function TableRow({ db, search }: Props) {
	const {
		id,
		name,
		tables_count,
		size,
		sensitivity,
		data_types,
		instance_id,
		last_checked,
		status: errorStatus,
	} = db;
	const databaseInstances = useStore(databaseInstancesById);
	const s3Regions = useStore(s3RegionsByKeyword);
	const instance = useMemo(() => databaseInstances[instance_id], [databaseInstances]);

	const lastCheckedValue = useMemo(
		() => lastSeen({ timestamp: last_checked, short: true }),
		[last_checked]
	);

	const history = useHistory();

	function clickHandler() {
		if (last_checked) {
			const path = generatePath(PATHS.DATABASES_ITEM, { id });
			history.push(path);
		}
	}

	const textColor = last_checked ? 'primary' : 'secondary';

	const errorStatusCell = (
		<Tooltip title={DarObjectStatusLabel[errorStatus]}>
			<Icon name="ErrorCircle/Filled" size={16} className={styles.redIcon} />
		</Tooltip>
	);

	const scanningInProgressCell = (
		<Tooltip title="Scanning in progress">
			<Icon name="Clock/Filled" size={16} className={styles.grayIcon} />
		</Tooltip>
	);

	const lastCheckedCell = (
		<Tooltip title={lastCheckedValue.date}>
			<div>{lastCheckedValue.diff}</div>
		</Tooltip>
	);

	return (
		<GridRow
			className={styles.rowContainer}
			onClick={clickHandler}
			border
			hover={!!last_checked}
			data-test="database-table-row"
		>
			<GridCell dataTest="database-table-item-name">
				<div className={styles.name}>
					{instance.is_crawler_attached ? (
						<TypoHighlighted variant="D/Medium/Body-S" search={search} color={textColor}>
							{name}
						</TypoHighlighted>
					) : (
						<>
							<AutomaticDiscoveryTooltip>
								<Icon name="Radar/Filled" size={16} className={styles.iconDiscovery} />
							</AutomaticDiscoveryTooltip>
							N/A
						</>
					)}
				</div>
			</GridCell>

			<GridCell dataTest="database-table-item-region">
				<TypoHighlighted variant="D/Medium/Body-S" search={search} color={textColor}>
					{s3Regions[instance.region]?.description || instance.region || DASH_SIGN}
				</TypoHighlighted>
			</GridCell>

			<GridCell align="right" dataTest="database-table-item-messages">
				{instance.is_crawler_attached ? (
					<Typo variant="D/Medium/Body-S" color={textColor}>
						{toLocaleString(tables_count)}
					</Typo>
				) : (
					<AutomaticDiscoveryNonAvailableData color="secondary" />
				)}
			</GridCell>

			<GridCell align="right" dataTest="database-table-item-size">
				{instance.is_crawler_attached ? (
					<Typo variant="D/Medium/Body-S" color={textColor}>
						{toFileSize(size)}
					</Typo>
				) : (
					<AutomaticDiscoveryNonAvailableData color="secondary" />
				)}
			</GridCell>

			<GridCell dataTest="database-table-item-sensitivity">
				<SensitivityChip sensitivity={sensitivity} />
			</GridCell>

			<GridCell dataTest="database-table-item-data_types">
				{!instance.is_crawler_attached ? (
					<AutomaticDiscoveryNonAvailableData color="secondary" />
				) : data_types.length ? (
					<PiiTypeList data={data_types} />
				) : (
					<Typo variant="D/Medium/Body-S" color={textColor}>
						{DASH_SIGN}
					</Typo>
				)}
			</GridCell>

			<GridCell dataTest="database-table-item-instance">
				<Typo
					variant="D/Medium/Body-S"
					color={!instance.is_crawler_attached ? 'primary' : textColor}
				>
					<Tooltip title={instance.name}>{instance.name}</Tooltip>
				</Typo>

				<Typo variant="D/Regular/Meta" color="secondary">
					{instance.type}
				</Typo>
			</GridCell>

			<GridCell dataTest="database-table-item-last-checked">
				{instance.is_crawler_attached ? (
					<>
						{errorStatus && errorStatusCell}
						{!errorStatus && !last_checked && scanningInProgressCell}
						{!errorStatus && last_checked && lastCheckedCell}
					</>
				) : (
					<AutomaticDiscoveryNonAvailableData color="secondary" />
				)}
			</GridCell>
		</GridRow>
	);
}

export { TableRow };
