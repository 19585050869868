import { createEvent, createStore } from 'effector';
import { HeaderProps } from './index';

const initialState: HeaderProps = {};

const headerStore = createStore<HeaderProps>(initialState);
const setHeader = createEvent<HeaderProps>();
const clearHeader = createEvent<void>();

headerStore.on(setHeader, (_, newState) => newState);
headerStore.on(clearHeader, () => initialState);

export { headerStore, setHeader, clearHeader };
