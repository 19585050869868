import cn from 'classnames';
import Typo from 'components/typography/Typo';
import { DataFlowDetailsTable } from 'models/assetDataFlowDetailsV2/dto';
import { AssetJson } from 'models/assets/dto';
import { TDataDirectionType } from 'models/common/dto';
import { getAssetNameByType } from 'views/common/AssetNameWithIcon';
import DataFlowIcon from 'views/SuperAssets/DataFlowIcon';
import mainStyles from '../index.module.css';
import styles from './index.module.css';

interface Props {
	asset1: AssetJson;
	asset2: AssetJson;
	direction: TDataDirectionType;
	disabled: boolean;
	flowDirection: DataFlowDetailsTable['flow_direction'];
	changeDirection: (direction: DataFlowDetailsTable['flow_direction']) => void;
}

function DataFlowDetailsHeader({
	asset1,
	asset2,
	direction,
	flowDirection,
	changeDirection,
	disabled,
}: Props) {
	const asset1DisplayName = getAssetNameByType(asset1.type, asset1.name);
	const asset2DisplayName = getAssetNameByType(asset2.type, asset2.name);

	return (
		<div className={styles.container}>
			<Typo variant="D/Medium/H100-Header" component="div">
				{asset1DisplayName}
				<DataFlowIcon direction={direction} flowDirection={flowDirection} className={styles.icon} />
				{asset2DisplayName}
			</Typo>

			<Typo variant="D/Medium/Body-S" className={cn({ [mainStyles.disabled]: disabled })}>
				<a
					onClick={() => changeDirection(flowDirection === 'outgoing' ? 'incoming' : 'outgoing')}
					data-test="data-flow-switch-direction"
				>
					Switch direction
				</a>
			</Typo>
		</div>
	);
}

export default DataFlowDetailsHeader;
