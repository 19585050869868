import cn from 'classnames';
import { ChangeEventHandler } from 'react';
import Label from 'components/Label';
import styles from './index.module.css';

interface Props {
	checked?: boolean;
	className?: string;
	dataTest?: string;
	defaultChecked?: boolean;
	disabled?: boolean;
	error?: boolean;
	label: string;
	name: string;
	onChange?: ChangeEventHandler<HTMLInputElement>;
	value: string;
}

function Radio(props: Props) {
	const { disabled, className, error, dataTest, label, ...rest } = props;

	return (
		<Label
			className={cn(className, { [styles.disabled]: disabled })}
			dataTest={dataTest || 'radio-container'}
		>
			<span className={styles.container}>
				<input type="radio" className={styles.nativeInput} {...rest} disabled={disabled} />
				<span className={cn({ [styles.error]: error }, styles.input)} />
			</span>
			{label}
		</Label>
	);
}

export default Radio;
