import { KafkaInstanceItem, KafkaInstanceList } from './dto';

const kafkaInstanceItemExample: KafkaInstanceItem = {
	id: 14,
	name: 'Prod kafka 1',
	owner: 'John Doe',
	description: 'Production queue for orders',
	region: 'eu-west-1',
	has_tls: true,
	topics_count: 22,
	messages_count: 10482,
	sensitivity: 'Low',
	data_types: [4],
	is_crawler_attached: true,
};

const kafkaInstanceItemExample2: KafkaInstanceItem = {
	id: 15,
	name: 'Stage kafka',
	owner: 'John Doe',
	description: 'Staging env kafka',
	region: 'eu-west-1',
	has_tls: false,
	topics_count: 1,
	messages_count: 24774,
	sensitivity: 'High',
	data_types: [4, 6, 7],
	is_crawler_attached: true,
};

const kafkaInstanceListExample: KafkaInstanceList = {
	instances: [kafkaInstanceItemExample, kafkaInstanceItemExample2],
	total: 2,
};

export { kafkaInstanceItemExample, kafkaInstanceListExample };
