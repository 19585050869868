import Button from 'components/form/Button';
import { RouterLink } from 'components/typography/Link';
import Typo from 'components/typography/Typo';
import { PATHS } from 'services/router';
import integratonsStyles from '../index.module.pcss';
import ExternalAPISVG from '../source/externalAPI.svg';

function ExternalAPI() {
	return (
		<div className={integratonsStyles.block}>
			<div className={integratonsStyles.top}>
				<ExternalAPISVG />
			</div>

			<div className={integratonsStyles.middle}>
				<div className={integratonsStyles.name}>
					<Typo variant="D/Medium/H100-Header">API Tokens</Typo>
					<Typo variant="D/Regular/Body-S" color="secondary">
						by Soveren
					</Typo>
				</div>

				<Typo variant="D/Regular/Body-S" className={integratonsStyles.description}>
					Manage tokens to public API.
				</Typo>
			</div>

			<div className={integratonsStyles.bottom}>
				<RouterLink to={PATHS.INTEGRATIONS_EXTERNAL_API}>
					<Button size="small" color="primary">
						Manage
					</Button>
				</RouterLink>
			</div>
		</div>
	);
}

export default ExternalAPI;
