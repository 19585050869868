import { createEvent, createStore } from 'effector';

type State = {
	visible: boolean;
	date: string;
	apiCalls: number;
	top: number;
	left: number;
};

const chartTooltipStore = createStore<State>({
	visible: false,
	date: '',
	apiCalls: 0,
	top: 0,
	left: 0,
});

const setChartTooltip = createEvent<State>();
const hideChartTooltip = createEvent();

chartTooltipStore.on(setChartTooltip, (state: State, payload: State) => payload);
chartTooltipStore.on(hideChartTooltip, (state: State) => {
	return { ...state, visible: false };
});

export { chartTooltipStore, setChartTooltip, hideChartTooltip };
