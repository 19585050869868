import { AccountAWSItem, AccountAWSList, AccountAWSTest } from './dto';

const offlineAccountJsonExample: AccountAWSItem = {
	id: 1,
	external_id: '1effa299b0944aafa63a503a06bbaa90',
	role_arn: 'arn:aws:iam::123456789012:role/S3Access',
	created_at: 1698351687739,
	last_checked: 1698351687739,
	last_checked_errors: ['Last message at 2024-08-25 15:11:05 UTC'],
};

const onlineAccountJsonExample: AccountAWSItem = {
	id: 10,
	external_id: '1effa299b0944aafa63a503a06bbaa90',
	role_arn: 'arn:aws:iam::123456789012:role/S3Access',
	created_at: 1698351687739,
	last_checked: 1698351687739,
	last_checked_errors: ['Last message at 2024-08-25 15:11:05 UTC'],
};

const draftAccountsExist: AccountAWSItem = {
	id: 100,
	external_id: '1effa299b0944aafa63a503a06bbaa90',
	role_arn: '',
	created_at: 1698351687739,
	last_checked: 1698351687739,
	last_checked_errors: ['Last message at 2024-08-25 15:11:05 UTC'],
};

const accountsAWSExample: AccountAWSList = {
	accounts: [offlineAccountJsonExample, onlineAccountJsonExample, draftAccountsExist],
	total: 3,
};

const negativeAccountsAWSTestExample: AccountAWSTest = {
	connection_errors: [
		'Error text here error text',
		'Here error text here error text',
		'Here error very long description',
	],
};
const positiveAccountsAWSTestExample: AccountAWSTest = {
	connection_errors: [],
};

export {
	offlineAccountJsonExample,
	onlineAccountJsonExample,
	accountsAWSExample,
	draftAccountsExist,
	negativeAccountsAWSTestExample,
	positiveAccountsAWSTestExample,
};
