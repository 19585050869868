import cn from 'classnames';
import Typo from 'components/typography/Typo';
import { AssetDetailJson } from 'models/assets/dto';
import styles from './index.module.css';

type KeyValue = {
	key: string;
	value: string;
};

type Props = {
	asset: AssetDetailJson;
};

function AssetLabels({ asset }: Props) {
	function isKeyValue(value: string | { [key: string]: unknown }): value is KeyValue {
		return !!value && typeof value === 'object' && 'key' in value && 'value' in value;
	}

	return (
		<div className={styles.container}>
			<Typo variant="D/Medium/H100-Header" className={styles.name}>
				Labels
			</Typo>

			<div className={styles.labelList}>
				{asset.labels?.length ? (
					asset.labels.map((label) => {
						const strLabel = isKeyValue(label) ? `${label.key}: ${label.value}` : String(label);

						return (
							<Typo
								key={strLabel}
								variant="D/Medium/Meta"
								className={cn(styles.ellipses, styles.label)}
							>
								{strLabel}
							</Typo>
						);
					})
				) : (
					<Typo variant="D/Regular/Body-S" color="secondary">
						No labels.
					</Typo>
				)}
			</div>
		</div>
	);
}

export default AssetLabels;
