import { ReleaseUpdateJson } from 'models/eventsV2/dto';
import { ReleaseUpdateMessage } from 'views/common/ReleaseUpdateMessage';

type Props = {
	event: ReleaseUpdateJson;
};

function ReleaseUpdateEvent({ event }: Props) {
	const critical = ['dim_release_update_critical', 'dar_release_update_critical'].includes(
		event.type
	);
	const type = ['dim_release_update', 'dim_release_update_critical'].includes(event.type)
		? 'dim'
		: 'dar';

	return <ReleaseUpdateMessage type={type} critical={critical} />;
}

export default ReleaseUpdateEvent;
