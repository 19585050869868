import { generatePath, useHistory } from 'react-router-dom';
import { AssetDetailJson } from 'models/assets/dto';
import { createAssetFx } from 'models/assets/effects';
import { PATHS } from 'services/router';
import AssetInfo from 'views/SuperAssets/AssetInfo';

const EMPTY_ASSET: AssetDetailJson = {
	created_at: 0,
	description: '',
	groups: [],
	cluster_id: 0,
	id: 0,
	is_deleted: false,
	is_external: true,
	k8s_types: [],
	labels: [],
	name: '',
	namespace: '',
	owner: '',
	rules: [],
	type: 'custom',
	updated_at: 0,
};

function AssetCreator() {
	const history = useHistory();

	function updateAsset(payload: AssetDetailJson) {
		return createAssetFx(payload).then(({ id }) => {
			history.replace(generatePath(PATHS.SERVICE_ITEM, { id, tab: 'info' }));
		});
	}

	return <AssetInfo asset={EMPTY_ASSET} updateAsset={updateAsset} />;
}

export default AssetCreator;
