import { Menu as UiMenu, MenuProps } from '@material-ui/core';
import cn from 'classnames';
import styles from './index.module.css';

function Menu({ className, ...params }: MenuProps) {
	return (
		<UiMenu
			getContentAnchorEl={null}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'right',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			{...params}
			classes={{ paper: cn(className, styles.paper) }}
		/>
	);
}

export default Menu;
