import * as Sentry from '@sentry/browser';
import cn from 'classnames';
import { useStore } from 'effector-react';
import { createContext, ReactNode, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Preloader from 'components/Preloader';
import { GTM, SENTRY } from 'consts';
import { getAssetsFx } from 'models/assets/effects';
import { getAssetGroupsFx } from 'models/assetsGroups/effects';
import { getDatabaseInstancesFx } from 'models/databases/effects';
import { getDataTypesFx } from 'models/dataTypes/effects';
import { getKafkaInstancesFx } from 'models/kafkaInstances/effects';
import { getPolicyListFx } from 'models/policies/effects';
import { getS3RegionsFx } from 'models/s3Regions/effects';
import { clientStore } from 'models/session/store';
import { setSideMenuState, sideMenuStateStore } from 'models/sideMenu';
import { PATHS } from 'services/router';
import DesktopViewAlert from './DesktopViewAlert';
import DiscoveryStatus from './DiscoveryStatus';
import { HeaderLayout } from './Header';
import styles from './index.module.pcss';
import SideMenu from './SideMenu';

interface Props {
	children: ReactNode;
}

type LayoutClasses = {
	layout?: string;
	content?: string;
};
type LayoutContextProps = {
	reset: () => void;
	onChange: (value: LayoutClasses) => void;
};
export const LayoutContext = createContext<LayoutContextProps>({
	reset: () => {},
	onChange: () => {},
});

function AuthorizedWithLeftMenu({ children }: Props) {
	const client = useStore(clientStore);
	const collapsed = useStore(sideMenuStateStore);
	const [contextClasses, setContextClasses] = useState<LayoutClasses>({});

	const [isLoading, setLoading] = useState(true);

	const location = useLocation();

	useEffect(() => {
		Promise.all([
			getDataTypesFx(),
			getAssetsFx(),
			getAssetGroupsFx(),
			getPolicyListFx(),
			getS3RegionsFx(),
			getKafkaInstancesFx(),
			getDatabaseInstancesFx(),
		]).then(() => setLoading(false));
	}, []);

	useEffect(() => {
		if (GTM.ENABLED) {
			window?.dataLayer?.push({
				user_id: client.id,
			});
		}
	}, [location]);

	useEffect(
		function sentryUserTrack() {
			if (!SENTRY.ENABLED) {
				return;
			}

			Sentry.configureScope((scope) => scope.setUser({ id: String(client.id) }));

			return () => Sentry.configureScope((scope) => scope.setUser(null));
		},
		[client]
	);

	useEffect(
		function hotjarUserTrack() {
			if (!GTM.ENABLED) {
				return;
			}

			window.hj?.('identify', String(client.id));
			return () => window.hj?.('identify', null);
		},
		[client]
	);

	const contextValue = useMemo(
		() => ({
			onChange: setContextClasses,
			reset: () => setContextClasses({ layout: '', content: '' }),
		}),
		[]
	);

	return (
		<LayoutContext.Provider value={contextValue}>
			<div
				className={cn(styles.layoutContainer, contextClasses.layout)}
				data-test="container-authorized"
			>
				<div className={cn(styles.leftPaneContainer, { [styles.leftPaneCollapsed]: collapsed })}>
					<SideMenu collapsed={collapsed} changeMenu={setSideMenuState} />
				</div>

				<div className={cn(styles.rightPaneContainer)}>
					{!location.pathname.startsWith(PATHS.DATA_MAP) && <HeaderLayout />}

					<DesktopViewAlert />

					{!location.pathname.startsWith(PATHS.DATA_MAP) && (
						<DiscoveryStatus classes={{ container: styles.discoveryBlock }} />
					)}

					<main className={cn(styles.content, contextClasses.content)}>
						<Preloader isLoading={isLoading} className={styles.contentScroll}>
							<div className={styles.contentScroll}>{children}</div>
						</Preloader>
					</main>
				</div>
			</div>
		</LayoutContext.Provider>
	);
}

export default AuthorizedWithLeftMenu;
