import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Checkbox from 'components/Checkbox';
import Button from 'components/form/Button';
import TextField from 'components/form/TextField';
import Preloader from 'components/Preloader';
import Radio from 'components/Radio';
import { SecretToken } from 'components/SecretToken';
import { enqueueSnackbar } from 'components/Snackbar';
import Typo from 'components/typography/Typo';
import { getSSOSetting, updateSSOSettings } from 'models/sso/api';
import { isOIDC, TSSOSettings } from 'models/sso/dto';
import { APIError } from 'services/api/httpRequest';
import styles from './index.module.css';

type FormValues = {
	provider: 'oidc' | 'adfs';

	// common fields for both provider types
	is_enabled: boolean;
	client_id: string;
	client_secret: string;
	email_domains: string[];
	manage_users: boolean;

	// oidc only
	issuer: string;

	// adfs only
	authorization_endpoint: string;
	token_endpoint: string;

	// helper
	domain: string;
};

const initialSettings: FormValues = {
	is_enabled: false,
	provider: 'oidc',
	client_id: '',
	client_secret: '',
	domain: '',
	email_domains: [],
	manage_users: false,
	issuer: '',
	authorization_endpoint: '',
	token_endpoint: '',
};

function settingsToForm(settings: TSSOSettings): FormValues {
	return isOIDC(settings)
		? {
				...initialSettings,
				...settings.oidc,
		  }
		: {
				...initialSettings,
				...settings.adfs,
				provider: 'adfs',
		  };
}

type Props = {
	onConnectChange: (settings: TSSOSettings) => void;
};

function SSOSettings(props: Props) {
	const { onConnectChange } = props;
	const [isLoading, setLoading] = useState(true);
	const [isUpdateLoading, setUpdateLoading] = useState(false);
	const [isEditEnabled, setEditEnabled] = useState(false);
	const [serverError, setServerError] = useState<null | {
		code?: string;
		message?: string;
		details?: string[];
	}>(null);

	const [tmpValues, setTmpValues] = useState<FormValues>(initialSettings);

	const { control, getValues, handleSubmit, reset, watch, setValue, setError } =
		useForm<FormValues>({
			defaultValues: initialSettings,
			mode: 'onBlur',
		});

	useEffect(() => {
		if (isEditEnabled) {
			setTmpValues(getValues());
		}
	}, [isEditEnabled]);

	const provider = watch('provider');
	const domain = watch('domain');
	const email_domains = watch('email_domains');
	const is_enabled = watch('is_enabled');
	const manage_users = watch('manage_users');

	// Load settings from server
	useEffect(() => {
		getSSOSetting()
			.then((settings: TSSOSettings) => {
				reset(settingsToForm(settings));
				setLoading(false);
				onConnectChange(settings);
			})
			.catch(() => {
				enqueueSnackbar('Something went wrong');
			});
	}, []);

	async function onSubmit(values: FormValues) {
		setServerError(null);
		setUpdateLoading(true);

		const payload: TSSOSettings =
			values.provider === 'oidc'
				? {
						oidc: {
							...values,
							is_enabled: true,
						},
				  }
				: {
						adfs: {
							...values,
							is_enabled: true,
						},
				  };

		try {
			const settings = await updateSSOSettings(payload);

			setEditEnabled(false);
			reset(settingsToForm(settings));
			onConnectChange(settings);
			enqueueSnackbar('Settings saved');
		} catch (error) {
			if (error instanceof APIError && error.response.status === 400) {
				const { code, message, details } = await error.response.json();

				if (code === '1041' || code === '1042' || code === '1043') {
					setServerError({ code, message, details });
				} else {
					enqueueSnackbar(message);
				}
			} else {
				throw error;
			}
		} finally {
			setUpdateLoading(false);
		}
	}

	function onCancel() {
		setEditEnabled(false);
		reset(tmpValues);
	}

	function enableEdit() {
		setEditEnabled(true);
	}

	return (
		<Preloader isLoading={isLoading}>
			<form className={styles.container} onSubmit={handleSubmit(onSubmit)}>
				<div className={styles.header}>
					<Typo variant="D/Medium/H100-Header">SSO authentication</Typo>
					{!isEditEnabled && (
						<Typo variant="D/Medium/Body">
							<a onClick={enableEdit} data-test="team-settings-edit">
								Edit
							</a>
						</Typo>
					)}
				</div>

				<Typo variant="D/Regular/Body-S">
					All users in your team will authenticate using SSO (personal login credentials will no
					longer work).
				</Typo>

				<Typo variant="D/Regular/Body-S" className={styles.secondParagraph}>
					You can obtain ID and Secret from your identity provider.
				</Typo>

				{isEditEnabled ? (
					<>
						<TextField
							label="Client ID"
							name="client_id"
							fullWidth
							control={control}
							required
							data-test="team-settings-client-id"
						/>

						<TextField
							label="Client secret"
							name="client_secret"
							fullWidth
							control={control}
							required
							data-test="team-settings-client-secret"
						/>
					</>
				) : (
					<>
						<SecretToken
							label="Client ID"
							name="client_id"
							control={control}
							fullWidth
							data-test="team-settings-client-id"
						/>

						<SecretToken
							label="Client secret"
							name="client_secret"
							control={control}
							fullWidth
							data-test="team-settings-client-secret"
						/>
					</>
				)}

				<Typo variant="D/Medium/H100-Header" className={styles.secondHeader}>
					SSO provider
				</Typo>

				<Controller
					name="provider"
					control={control}
					render={({ field }) => (
						<div className={styles.radioGroup}>
							<Radio
								{...field}
								dataTest="team-settings-oidc"
								disabled={!isEditEnabled}
								value="oidc"
								label="OIDC"
								checked={field.value === 'oidc'}
							/>

							<Radio
								{...field}
								dataTest="team-settings-adfs"
								disabled={!isEditEnabled}
								value="adfs"
								label="ADFS"
								checked={field.value === 'adfs'}
							/>
						</div>
					)}
				/>

				{provider === 'oidc' && (
					<TextField
						label="Issuer"
						name="issuer"
						type="url"
						fullWidth
						control={control}
						required
						readOnly={!isEditEnabled}
						data-test="team-settings-issuer"
					/>
				)}

				{provider === 'adfs' && (
					<TextField
						label="Authorization endpoint"
						name="authorization_endpoint"
						type="url"
						fullWidth
						control={control}
						required
						readOnly={!isEditEnabled}
						data-test="team-settings-authorization-endpoint"
					/>
				)}

				{provider === 'adfs' && (
					<TextField
						label="Token endpoint"
						name="token_endpoint"
						type="url"
						fullWidth
						control={control}
						required
						readOnly={!isEditEnabled}
						data-test="team-settings-token-endpoint"
					/>
				)}

				<div className={styles.domainContainer}>
					<Typo variant="D/Medium/H100-Header">User management</Typo>

					<Typo variant="D/Regular/Body-S" className={styles.domainDescription}>
						If you delegate user management to your identity provider, user access restrictions for
						Soveren will be entirely managed there.
					</Typo>

					<Checkbox
						size="M"
						name="manage_users"
						disabled={!isEditEnabled}
						label="Delegate user management to the identity provider"
						checked={manage_users}
						onChange={() => setValue('manage_users', !manage_users)}
						data-test="team-settings-manage-users"
					/>
				</div>

				<div className={styles.domainContainer}>
					<Typo variant="D/Medium/H100-Header">Email domains</Typo>

					<Typo variant="D/Regular/Body-S" className={styles.domainDescription}>
						Specify email domains to be allowed to authenticate via your SSO server. We need a list
						of all the email domains that your users will be using to access Soveren. Public domains
						(e.g., gmail.com, outlook.com, etc.) are not permitted.
					</Typo>

					<div className={styles.domainGrid}>
						<TextField
							label="Email domain"
							name="domain"
							fullWidth
							control={control}
							optional={false}
							readOnly={!isEditEnabled}
							disabled={!isEditEnabled}
							data-test="team-settings-domain"
						/>

						<Button
							color="tertiary"
							data-test="team-settings-domain-add"
							onClick={() => {
								if (!domain) {
									setError('domain', { type: 'validateUrl' });
									return;
								}

								if (email_domains.includes(domain)) {
									setError('domain', {
										type: 'custom',
										message: 'Duplicate email domain',
									});
									return;
								}

								setValue('domain', '');
								setValue('email_domains', [domain].concat(email_domains), {
									shouldDirty: true,
								});
							}}
							disabled={!isEditEnabled || !domain.trim()}
						>
							Add
						</Button>

						{email_domains.map((field, idx) => {
							const error = (serverError?.details || []).includes(field)
								? serverError?.message
								: null;

							return (
								<>
									<TextField
										label={null}
										fullWidth
										value={field}
										readOnly
										error={!!error}
										helperText={error || ' '}
										dataTest="team-settings-domain-added"
									/>

									<Button
										color="tertiary"
										theme="danger"
										data-test="team-settings-domain-remove"
										onClick={() => {
											setValue(
												'email_domains',
												email_domains.filter((_, i) => i !== idx),
												{ shouldDirty: true }
											);
										}}
										disabled={!isEditEnabled}
									>
										Remove
									</Button>
								</>
							);
						})}
					</div>
				</div>

				<div className={styles.buttons}>
					<Button
						color="secondary"
						onClick={onCancel}
						disabled={!isEditEnabled}
						data-test="team-settings-cancel"
					>
						Cancel
					</Button>

					<Button
						type="submit"
						loading={isUpdateLoading}
						disabled={!isEditEnabled}
						data-test="team-settings-save"
					>
						Save {is_enabled ? '' : 'and enable'}
					</Button>
				</div>
			</form>
		</Preloader>
	);
}

export default SSOSettings;
