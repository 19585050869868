import { useStore } from 'effector-react';
import { generatePath } from 'react-router';
import { useHistory } from 'react-router-dom';
import Icon from 'components/Icon';
import { PiiTypeList } from 'components/PiiType';
import SensitivityChip from 'components/SensitivityChip';
import GridCell from 'components/table/GridCell';
import GridRow from 'components/table/GridRow';
import { TypoHighlighted } from 'components/typography/TypoHighlighted';
import { KafkaInstanceItem } from 'models/kafkaInstances/dto';
import { s3RegionsByKeyword } from 'models/s3Regions/store';
import { toLocaleString } from 'services/numbers';
import { PATHS } from 'services/router';
import { DASH_SIGN } from 'services/strings';
import {
	AutomaticDiscoveryNonAvailableData,
	AutomaticDiscoveryTooltip,
} from 'views/common/AutomaticDiscovery';
import styles from './index.module.css';

type Props = {
	instance: KafkaInstanceItem;
	search: string;
};

function InstanceTableRow(props: Props) {
	const { instance, search } = props;
	const {
		data_types,
		has_tls,
		id,
		is_crawler_attached,
		messages_count,
		name,
		sensitivity,
		topics_count,
		region,
	} = instance;
	const s3Regions = useStore(s3RegionsByKeyword);

	const history = useHistory();

	function clickHandler() {
		const path = generatePath(PATHS.KAFKA_INSTANCES_ITEM, { id });
		history.push(path);
	}

	return (
		<GridRow
			className={styles.rowContainer}
			onClick={clickHandler}
			border
			hover
			data-test="kafka-clusters-table-row"
		>
			<GridCell dataTest="kafka-clusters-table-item-name">
				<div className={styles.name}>
					{!is_crawler_attached && (
						<AutomaticDiscoveryTooltip>
							<Icon name="Radar/Filled" size={16} className={styles.iconDiscovery} />
						</AutomaticDiscoveryTooltip>
					)}

					<TypoHighlighted variant="D/Medium/Body-S" search={search}>
						{name}
					</TypoHighlighted>
				</div>
			</GridCell>

			<GridCell dataTest="kafka-clusters-table-item-region">
				<TypoHighlighted variant="D/Medium/Body-S" search={search}>
					{s3Regions[region]?.description || DASH_SIGN}
				</TypoHighlighted>
			</GridCell>

			<GridCell align="right" dataTest="kafka-clusters-table-item-topics">
				{toLocaleString(topics_count)}
			</GridCell>

			<GridCell align="right" dataTest="kafka-clusters-table-item-messages">
				{toLocaleString(messages_count)}
			</GridCell>

			<GridCell dataTest="kafka-clusters-table-item-tls">{has_tls ? 'Yes' : 'No'}</GridCell>

			<GridCell dataTest="kafka-clusters-table-item-sensitivity">
				<SensitivityChip sensitivity={sensitivity} />
			</GridCell>

			<GridCell dataTest="kafka-clusters-table-item-data_types">
				{is_crawler_attached ? (
					<PiiTypeList data={data_types} />
				) : (
					<AutomaticDiscoveryNonAvailableData color="secondary" />
				)}
			</GridCell>
		</GridRow>
	);
}

export { InstanceTableRow };
