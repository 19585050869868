import { generatePath } from 'react-router';
import { AssetGroupChipList } from 'components/AssetGroupChip';
import Icon from 'components/Icon';
import { RouterLink } from 'components/typography/Link';
import Typo from 'components/typography/Typo';
import { EventAsset } from 'models/eventsV2/dto';
import { PATHS } from 'services/router';
import { dividerDot, getStringDividedByDot } from 'services/strings';
import { getAssetNameByType } from 'views/common/AssetNameWithIcon';
import styles from './index.module.pcss';

type Props = {
	asset: EventAsset;
	showDanger?: boolean;
	dataTest?: string;
};

function getPathToAsset(asset: EventAsset) {
	return generatePath(PATHS.SERVICE_ITEM, { id: asset.id });
}

function Asset(props: Props) {
	const { asset, showDanger = false, dataTest } = props;
	const assetName = getAssetNameByType(asset.type, asset.name);

	return (
		<div>
			<RouterLink inherit to={getPathToAsset(asset)} data-test={dataTest}>
				{assetName}

				{(asset.namespace || asset.cluster_name) && (
					<Typo variant="D/Regular/Meta" color="secondary" component="span">
						{dividerDot}
						{getStringDividedByDot(asset.cluster_name, asset.namespace)}
					</Typo>
				)}

				{!!showDanger && <Icon className={styles.dangerIcon} name="Danger/FilledRed" size={20} />}
			</RouterLink>
			<AssetGroupChipList groups={asset.groups || []} className={styles.groupList} />
		</div>
	);
}

export default Asset;
