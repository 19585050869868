import { ReleaseJson } from './dto';

const releaseJsonExample: ReleaseJson = {
	dim: {
		detection_tool_version: '1.2.3',
		interceptor_version: '1.2.3',
		digger_version: '1.2.3',
		diff_with_actual_release: 0,
		last_event_date: 1111111,
	},
	dar: {
		crawler_version: '1.2.3',
		detection_tool_version: '1.2.3',
		diff_with_actual_release: 0,
		last_event_date: 1111111,
	},
};

export { releaseJsonExample };
