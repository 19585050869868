import { ConfirmModal, getConfirmation } from 'components/ConfirmModal';
import Icon from 'components/Icon';
import Typo from 'components/typography/Typo';

async function confirmSetFalsePositive() {
	return getConfirmation(
		<ConfirmModal
			icon={null}
			title="Mark as false positive"
			confirmProps={{
				children: 'Mark as false positive',
				theme: 'default',
				'data-test': 'mark-btn',
			}}
		>
			<Typo variant="D/Regular/Body-S">
				Mark the parameter as false positive if it was auto-assigned an incorrect data
				<br />
				type. The parameter will be excluded from statistics until you reset it.
			</Typo>
		</ConfirmModal>
	);
}

async function confirmRemoveFalsePositive() {
	return getConfirmation(
		<ConfirmModal
			icon={null}
			title="Unmark as false positive"
			confirmProps={{
				children: 'Unmark as false positive',
				theme: 'default',
			}}
		>
			<Typo variant="D/Regular/Body-S">
				Unmark if false positive was assigned incorrectly.
				<br />
				The parameter will lose its false positive mark.
			</Typo>
		</ConfirmModal>
	);
}

async function confirmRemoveManualDataType() {
	return getConfirmation(
		<ConfirmModal
			icon={<Icon name="Danger/FilledRed" />}
			title="Remove manually assigned data type"
			confirmProps={{
				children: 'Remove',
				theme: 'danger',
			}}
		>
			<Typo variant="D/Regular/Body-S">
				Remove the data type you assigned manually. Auto-assigned data types won’t be affected.
			</Typo>
		</ConfirmModal>
	);
}

export { confirmSetFalsePositive, confirmRemoveFalsePositive, confirmRemoveManualDataType };
