import { get, post } from 'api/Api';
import checkResponse from 'services/api/checkResponse';
import { DiscoveryStatusJson } from './dto';
import { discoveryStatusExample } from './examples';

async function getDiscoveryStatus() {
	return get<DiscoveryStatusJson>('v2/discovery-status').then(
		checkResponse(discoveryStatusExample)
	);
}

async function confirmDiscoveryStatus() {
	// For POST method we send empty body
	return post<DiscoveryStatusJson>('v2/discovery-status', {}).then(
		checkResponse(discoveryStatusExample)
	);
}

export { getDiscoveryStatus, confirmDiscoveryStatus };
