import { ApiParams } from 'services/api';
import { DataTypeStructured } from './dto';
import { dataTypesById, dataTypesStructuredStore } from './store';

function dataTypesPartsByIds(dataTypes: number[]) {
	const dataTypesStructured = dataTypesStructuredStore.getState();
	const dataTypesByIdValues = dataTypesById.getState();

	const groupsSource = dataTypes
		.map((id) => dataTypesByIdValues[id])
		.filter(({ isGroup }) => isGroup);
	const notGroupsSource = dataTypes
		.map((id) => dataTypesByIdValues[id])
		.filter(({ isGroup }) => !isGroup)
		.map(({ id }) => ({ id }));
	const dataTypesSource = notGroupsSource.concat(
		groupsSource.reduce(
			(acc, { child_ids }) => {
				acc.push(...child_ids.map((dtId) => ({ id: dtId })));

				return acc;
			},
			[] as { id: number }[]
		)
	);

	const groupIds: number[] = [];
	const dataTypesInGroupIds: number[] = [];

	dataTypesStructured.forEach(({ child_ids, ...group }) => {
		if (child_ids.every((childId) => dataTypesSource.find(({ id }) => id === childId))) {
			groupIds.push(group.id);
			dataTypesInGroupIds.push(...child_ids);
		}
	});

	return {
		dataTypes: dataTypes.map((dtId) => dataTypesByIdValues[dtId]),
		dataTypesSource,
		groups: groupIds.map((dtId) => dataTypesByIdValues[dtId]),
		outOfGroup: dataTypes
			.filter(
				(dtId) => !dataTypesInGroupIds.includes(dtId) && !groupsSource.find(({ id }) => id === dtId)
			)
			.map((dtId) => dataTypesByIdValues[dtId]),
	};
}

function selectDataType(
	clickedOption: DataTypeStructured,
	options: DataTypeStructured[],
	selected: DataTypeStructured[]
): DataTypeStructured[] {
	let result;

	if (!clickedOption.isGroup) {
		result = selected.includes(clickedOption)
			? selected.filter((dt) => dt !== clickedOption)
			: selected.concat(clickedOption);
	} else {
		const childrenInOptions = clickedOption.child_items.filter((dt) => options.includes(dt));
		const childrenInSelected = clickedOption.child_items.filter((dt) => selected.includes(dt));
		const restInSelected = selected.filter((dt) => !childrenInSelected.includes(dt));

		const shouldRemoveThisGroup = childrenInOptions.length === childrenInSelected.length;

		if (shouldRemoveThisGroup) {
			result = restInSelected;
		} else {
			result = restInSelected.concat(childrenInOptions);
		}
	}

	return result;
}

type CheckboxState = {
	checked: boolean;
	halfPressed: boolean;
};

function getDataTypeCheckboxState(
	options: DataTypeStructured[],
	selected: DataTypeStructured[]
): { [key: DataTypeStructured['id']]: CheckboxState } {
	const result: { [key: DataTypeStructured['id']]: CheckboxState } = {};

	for (const option of options) {
		let checked = selected.includes(option);
		let halfPressed = false;

		if (option.isGroup) {
			const childrenInOptions = option.child_items.filter((dt) => options.includes(dt));
			const childrenInSelected = option.child_items.filter((dt) => selected.includes(dt));

			checked = childrenInSelected.length > 0;
			halfPressed = checked && childrenInSelected.length < childrenInOptions.length;
		}

		result[option.id] = { checked, halfPressed };
	}

	return result;
}

type DataTypeCommon = {
	id: number;
	name: string;
	child_ids: number[];
	isGroup: boolean;
};

type Structured<DT> = DT & {
	child_items: Structured<DT>[];
};

const getDataTypesStructured = <T extends DataTypeCommon>(dataTypes: T[]): Structured<T>[] => {
	const groups = dataTypes.filter(({ isGroup }) => isGroup);
	const dataTypesInGroups: number[] = [];

	const dataTypesWithChildren: Structured<T>[] = groups.map((group) => {
		const child_items = group.child_ids.reduce((acc, childId) => {
			const dataTypeItem = dataTypes.find(({ id }) => id === childId);

			if (dataTypeItem) {
				acc.push({ ...dataTypeItem, child_items: [] });
				dataTypesInGroups.push(dataTypeItem.id);
			}

			return acc;
		}, [] as Structured<T>[]);

		return {
			...group,
			child_items,
		};
	});

	const notGroups: Structured<T>[] = dataTypes
		.filter(({ id, isGroup }) => !dataTypesInGroups.includes(id) && !isGroup)
		.map((dataType) => ({ ...dataType, child_items: [] }));

	return [...dataTypesWithChildren, ...notGroups];
};

function getDataTypesAsApiParam(piiTypes: ApiParams['piiTypes']) {
	return (typeof piiTypes === 'string' ? piiTypes.split(',') : [])
		.filter((dataType) => dataType !== null)
		.join(',');
}

export type { Structured };
export {
	dataTypesPartsByIds,
	selectDataType,
	getDataTypeCheckboxState,
	getDataTypesStructured,
	getDataTypesAsApiParam,
};
