import cn from 'classnames';
import { useStore } from 'effector-react';
import { useMemo } from 'react';
import Chip from 'components/Chip';
import Icon from 'components/Icon';
import { MarkedPiiType } from 'components/PiiType';
import GridBody from 'components/table/GridBody';
import GridCell from 'components/table/GridCell';
import GridHead from 'components/table/GridHead';
import GridRow from 'components/table/GridRow';
import GridTable from 'components/table/GridTable';
import Tooltip from 'components/Tooltip';
import { dataTypesById } from 'models/dataTypes/store';
import { PIIMarkJson } from 'models/piiMarks/dto';
import { SampleDataJson, SampleTypeJson } from 'models/samplesV2/dto';
import { getJsonLocation, isMissing } from '../helpers';
import styles from './index.module.pcss';

interface Props {
	sampleData: SampleDataJson;
	piiMarks: PIIMarkJson[];
	hideFalsePositives: boolean;
	onClickPiiType: (path: string) => void;
}

interface Group {
	parentLocation: unknown[];
	detections: Detection[];
}

interface Detection {
	valueType: string;
	dataType: number; // TODO
	fpId: number | undefined;
	isManual: boolean;
	key: number | string;
	jsonPath: string;
	jsonPathIsMissing: boolean;
}

function prepareParentLocation(location: Group['parentLocation'], key: Detection['key']) {
	if (!location.length) return [];

	const newParts = [location[0]];

	for (let i = 1; i < location.length; i++) {
		const currentItem = location[i];

		if (typeof currentItem === 'number') {
			newParts[newParts.length - 1] += `[${currentItem}]`;
		} else {
			newParts.push(currentItem);
		}
	}

	if (typeof key === 'number') {
		newParts[newParts.length - 1] += '[...]';
	}

	return newParts;
}

function TableOfTypes({
	sampleData: { json, data_fields },
	piiMarks,
	hideFalsePositives,
	onClickPiiType,
}: Props) {
	const dtById = useStore(dataTypesById);

	function getPathInfo(path: SampleTypeJson['json_path']) {
		const jsonLocation = getJsonLocation(path);

		return {
			jsonLocation,
			lastItemOfPath: jsonLocation.pop(), // Now jsonLocation is parent location
			parentPath: JSON.stringify(jsonLocation),
		};
	}

	const detectionsList = useMemo(
		function () {
			const detectionsByJsonPath: {
				[key: string]: {
					parentLocation: unknown[];
					detections: Detection[];
				};
			} = {};

			for (const detection of data_fields) {
				const falsePositive = piiMarks.find(
					(fp) =>
						detection.json_path === fp.json_path && detection.data_type === fp.detected_data_type
				);

				if (hideFalsePositives && !!falsePositive) continue; // Effectively filter out this PII detection from view.

				const { parentPath, lastItemOfPath, jsonLocation } = getPathInfo(detection.json_path);

				if (!detectionsByJsonPath[parentPath]) {
					// Not yet in dictionary.
					detectionsByJsonPath[parentPath] = {
						parentLocation: jsonLocation,
						detections: [],
					};
				}

				const result = {
					jsonPath: detection.json_path,
					jsonPathIsMissing: isMissing(json, detection.json_path),
					dataType: detection.data_type,
					valueType: detection.value_type,
					fpId: falsePositive && falsePositive.id,
					isManual: false,
					key: lastItemOfPath as string | number,
				};

				detectionsByJsonPath[parentPath].detections.push(result);
			}

			for (const mark of piiMarks) {
				const { parentPath, lastItemOfPath, jsonLocation } = getPathInfo(mark.json_path);

				if (!detectionsByJsonPath[parentPath]) {
					// Not yet in dictionary.
					detectionsByJsonPath[parentPath] = {
						parentLocation: jsonLocation,
						detections: [],
					};
				}

				if (mark.detected_data_type === 0) {
					const result = {
						jsonPath: mark.json_path,
						jsonPathIsMissing: isMissing(json, mark.json_path),
						dataType: mark.manual_data_type,
						valueType: 'Manual',
						fpId: undefined,
						isManual: true,
						key: lastItemOfPath as string | number,
					};

					detectionsByJsonPath[parentPath].detections.push(result);
				}
			}

			// To array:
			//	[{
			//		jsonPath: '$.user.name',
			//		parentLocation: "['user', 'name']",
			//		detections: [
			//			{ jsonPath: '$.user.name[1]', jsonPathIsMissing: false, dataType: 2, valueType: 'string', fpId: undefined, isManual: true, key: 1 },
			//			{ jsonPath: '$.user.name[1]', jsonPathIsMissing: false, dataType: 7, valueType: 'number', fpId: 245, isManual: false, key: 1 },
			//		]
			//	}];
			//

			const asArray = Object.values(detectionsByJsonPath);

			asArray.forEach((set) => {
				set.detections.sort((a, b) => {
					return a.jsonPath.localeCompare(b.jsonPath);
				});
			});

			// Put root parentLocation to the top of the table
			const rootEls = asArray.filter((item) => item.parentLocation.length === 0);
			const otherEls = asArray.filter((item) => item.parentLocation.length !== 0);

			return rootEls.concat(otherEls);
		},
		[json, data_fields, piiMarks, hideFalsePositives, dtById]
	);

	return (
		<GridTable className={styles.table} dataTest="table-of-types">
			<GridHead>
				<GridRow head className={cn(styles.headRow, styles.rowContainer)}>
					<GridCell head>Parameter</GridCell>

					<GridCell head>type</GridCell>

					<GridCell head>Data type</GridCell>
				</GridRow>
			</GridHead>

			<GridBody className={styles.body}>
				{detectionsList.map(({ parentLocation, detections }, index) => {
					const preparedParentLocation = prepareParentLocation(parentLocation, detections[0]?.key);
					// For grouping piiTypes with the same key (PARAMETER)
					let groupName: string | number;

					return (
						<div key={index} data-test={JSON.stringify(parentLocation)}>
							{preparedParentLocation.length !== 0 && (
								<GridRow
									className={cn(styles.header, styles.headerRow)}
									data-test="table-of-types-header"
								>
									{preparedParentLocation.map((partOfPath, partIndex) => (
										<Chip
											key={partIndex}
											label={String(partOfPath)}
											size="extraSmall"
											color="secondary"
											theme="neutral"
											data-test="table-of-types-chip"
										/>
									))}
								</GridRow>
							)}

							{detections.map(
								({ valueType, dataType, fpId, isManual, key, jsonPath, jsonPathIsMissing }) => {
									// Grouping piiTypes with the same key (PARAMETER)
									const label = key === groupName ? '' : key;
									groupName = key;

									return (
										<GridRow
											hover={!jsonPathIsMissing}
											key={jsonPath + dataType}
											className={cn(
												styles.rowContainer,
												styles.row,
												jsonPathIsMissing && styles.rowDisabled
											)}
											onClick={() => {
												jsonPathIsMissing || onClickPiiType(jsonPath);
											}}
											data-test="table-of-types-parameter"
										>
											<GridCell className={styles.cell}>
												{label}
												{jsonPathIsMissing && (
													<Tooltip title="You can’t see this path in the JSON sample because it was replaced with a newer version.">
														<span data-test="table-of-types-info-icon">
															<Icon name="Info/Filled" size={18} className={styles.icon} />
														</span>
													</Tooltip>
												)}
											</GridCell>

											<GridCell className={cn(styles.cell, styles.typeCell)}>{valueType}</GridCell>

											<GridCell className={styles.cell}>
												<MarkedPiiType
													type={dataType}
													isFP={!!fpId}
													isManual={isManual}
													className={styles.type}
												/>
											</GridCell>
										</GridRow>
									);
								}
							)}
						</div>
					);
				})}
			</GridBody>
		</GridTable>
	);
}

export default TableOfTypes;
