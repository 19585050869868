import { useStore } from 'effector-react';
import { MouseEvent, useLayoutEffect, useRef } from 'react';
import { cubeImagesStore } from 'models/dataMapV2/cubeImagesStore';
import styles from './index.module.css';
import { cubeByImageIdStylesConfig } from './styleConfig';

interface Props {
	imageId: string;
	onSelect: () => void;
	setHovered: (hovered: boolean) => void;
}

export const CanvasAsset = ({ imageId, onSelect, setHovered }: Props) => {
	const cubeImages = useStore(cubeImagesStore);
	const canvasRef = useRef(null);

	function handleSelect(event: MouseEvent<HTMLCanvasElement>) {
		event.stopPropagation();
		event.preventDefault();

		onSelect();
	}

	useLayoutEffect(() => {
		if (canvasRef && canvasRef.current) {
			const imageById = cubeImages[imageId];
			if (!imageById || !imageById.isLoaded) return;

			const CANVAS_SIZE_W = cubeByImageIdStylesConfig[imageId].width;
			const CANVAS_SIZE_H = cubeByImageIdStylesConfig[imageId].height;

			const current = canvasRef.current as HTMLCanvasElement;
			const ctx = current.getContext('2d');

			if (!ctx) return;

			ctx.clearRect(0, 0, CANVAS_SIZE_W, CANVAS_SIZE_H);

			current.width = CANVAS_SIZE_W;
			current.height = CANVAS_SIZE_H;

			current.style.marginTop = `${cubeByImageIdStylesConfig[imageId].top}px`;
			current.style.marginLeft = `${cubeByImageIdStylesConfig[imageId].left}px`;

			ctx.drawImage(
				imageById.image,
				cubeByImageIdStylesConfig[imageId].width / 2 - (imageById.image.width as number) / 2,
				0
			);
		}
	}, [canvasRef, imageId, cubeImages]);

	return (
		<canvas
			onClick={handleSelect}
			onMouseOver={() => setHovered(true)}
			onMouseLeave={() => setHovered(false)}
			ref={canvasRef}
			className={styles.canvasElement}
			data-test="data-map-cube"
		/>
	);
};
