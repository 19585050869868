import { createStore } from 'effector';
import {
	ClusterGeoLocationItem,
	ClusterGeoLocationList,
	ClusterGeoLocationRegionList,
} from './dto';
import {
	getClusterGeoLocationsFx,
	getClusterGeoLocationRegionsFx,
	updateClusterGeoLocationManualRegionFx,
} from './effects';

// ===== clusterGeoLocationRegionsStore =====

const clusterGeoLocationRegionsStore = createStore<ClusterGeoLocationRegionList>({ regions: [] });

clusterGeoLocationRegionsStore.on(
	getClusterGeoLocationRegionsFx.doneData,
	(state: ClusterGeoLocationRegionList, data: ClusterGeoLocationRegionList) => data
);

export const clusterGeoLocationRegionsList = clusterGeoLocationRegionsStore.map(({ regions }) =>
	regions.sort((a, b) => a.keyword.localeCompare(b.keyword))
);

export const clusterGeoLocationRegionsUsedList = clusterGeoLocationRegionsList.map((regions) =>
	regions.filter(({ is_used }) => is_used)
);

export const clusterGeoLocationRegionsByKeyword = clusterGeoLocationRegionsStore.map(
	({ regions }) => {
		return Object.fromEntries(regions.map((geoLocation) => [geoLocation.keyword, geoLocation]));
	}
);

// ===== clusterGeoLocationsStore =====

const clusterGeoLocationsStore = createStore<ClusterGeoLocationItem[]>([]);

clusterGeoLocationsStore.on(
	getClusterGeoLocationsFx.doneData,
	(state: ClusterGeoLocationItem[], data: ClusterGeoLocationList) => data.geo
);

clusterGeoLocationsStore.on(
	updateClusterGeoLocationManualRegionFx.doneData,
	(state, data: ClusterGeoLocationItem) =>
		state.map((geo) => (geo.token_id === data.token_id ? data : geo))
);

export const clusterGeoLocationsByTokenId = clusterGeoLocationsStore.map((geo) => {
	return Object.fromEntries(geo.map((geoLocation) => [geoLocation.token_id, geoLocation]));
});
