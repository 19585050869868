import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { matchPath } from 'react-router-dom';
import { SENTRY } from 'consts';
import history from 'services/history';
import { PATHS } from 'services/router';

function initSentry() {
	if (SENTRY.ENABLED) {
		// Sentry needs routes, especially important if route contains parameters in path.
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const routes = Object.entries(PATHS).map(([key, path]) => ({ path })); // [{ path: '/sign-in'}, { path: '/sign-up' }, ...]

		Sentry.init({
			dsn: SENTRY.DSN,
			release: SENTRY.RELEASE,
			environment: SENTRY.ENVIRONMENT,

			integrations: [
				new Integrations.BrowserTracing({
					routingInstrumentation: Sentry.reactRouterV5Instrumentation(history, routes, matchPath),
				}),
			],

			// Set tracesSampleRate to 1.0 to capture 100%
			// of transactions for performance monitoring.
			// We recommend adjusting this value in production
			tracesSampleRate: 1.0,
		});
	}
}

export { initSentry };
