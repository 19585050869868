import { AssetJson } from 'models/assets/dto';
import { THttpMethod, TOrder, TSensitivity } from 'models/common/dto';
import { DataTypeItem, DataTypeRequest } from 'models/dataTypes/dto';
import { GatewayJson } from 'models/gateways/dto';

type Protocol = 'http' | 'http2' | 'grpc';

const protocolLabel: Record<Protocol, string> = {
	http: 'HTTP',
	http2: 'HTTP/2',
	grpc: 'gRPC',
};

type EndpointsItem = {
	id: number;
	url: string;
	host: string;
	method: THttpMethod;
	protocol: Protocol;
	asset: AssetJson['id'];
	data_types: DataTypeItem['id'][];
	sensitivity: TSensitivity;
	process_name?: string;
	last_seen: number;
	is_encrypted: boolean;
	is_mesh_network: boolean;
};

type EndpointsTableItem = EndpointsItem & {
	asset_name: AssetJson['name']; // order property
	asset_type: AssetJson['type'];
	asset_is_external: AssetJson['is_external'];
	namespace: AssetJson['namespace'];
};

type EndpointsList = {
	endpoints: EndpointsTableItem[];
	total: number;
	total_filtered?: number;
};

type EndpointsTableParams = {
	asset: AssetJson['id'];
	total_by_asset: boolean;
	hosts: string;
	endpoints: string;
	assets: string; // Part of name
	asset_names: AssetJson['name'][]; // Exact names
	namespaces: string[];
	cluster_ids: GatewayJson['id'][];
	search: string;
	protocol: string;
	'data-types': DataTypeRequest;
	sort: {
		orderBy: keyof EndpointsTableItem;
		order: TOrder;
	};
};

function methodOrProtocol(data: Pick<EndpointsItem, 'method' | 'protocol'>) {
	const { method, protocol } = data;

	return protocol.startsWith('http') ? `[${method}]` : protocolLabel[protocol];
}

export type { Protocol, EndpointsItem, EndpointsTableItem, EndpointsList, EndpointsTableParams };
export { protocolLabel, methodOrProtocol };
