import Icon, { IconProps } from 'components/Icon';
import { AssetType } from 'models/assets/dto';
import { getAssetNameByType } from 'models/assets/model';
import styles from './index.module.css';

type Props = {
	name: string;
	type: AssetType;
	size?: IconProps['size'];
};

function AssetNameWithIcon(props: Props) {
	const { name, type, size = 16 } = props;

	const displayName = getAssetNameByType(type, name);

	if (type !== 'custom') {
		return <>{displayName}</>;
	}

	const words = displayName.split(' ');
	const lastWord = words.pop();
	return (
		<>
			{words.join(' ')}{' '}
			<span className={size > 16 ? styles.noWrapLarge : styles.noWrap}>
				{lastWord}
				<Icon name="Tag/Filled" size={size} className={styles.disableTransition} />
			</span>
		</>
	);
}

function getAssetNameWithIcon(type: AssetType, name: string, size?: IconProps['size']) {
	return <AssetNameWithIcon name={name} type={type} size={size} />;
}

export default AssetNameWithIcon;
export { getAssetNameWithIcon as getAssetNameByType };
