import * as Sentry from '@sentry/react';
import { SENTRY } from 'consts';
import matchesWith, { JsonStruct, MatchError } from 'services/matchesWith';
import { APIJSONMatchError } from './errors';

// Convenience wrapper.
// In prod/dev environments, data falls through, we just log/sentry if JSONs do not match.
// In test environment, failed match causes test to fail by throwing error.
function checkResponse<T>(...exampleJsons: T[]) {
	return function (realJson: T) {
		let matches: MatchError[] = [];

		for (const exampleJson of exampleJsons) {
			try {
				const match = matchesWith(
					realJson as unknown as JsonStruct,
					exampleJson as unknown as JsonStruct,
					['$']
				);

				if (match === true) return realJson;

				matches = matches.concat(match);
			} catch (error) {
				console.error((error as Error).message);
				console.error('checkResponse failed!');
			}
		}

		const matchesMap: {
			[key: string]: {
				actualType: string;
				expectedType?: string;
				expectedTypes?: string[];
			};
		} = {};

		for (const match of matches) {
			const pathStr = match.path.join('.');

			matchesMap[pathStr] = matchesMap[pathStr] || {
				actualType: match.actualType,
				expectedTypes: [],
			};

			matchesMap[pathStr].expectedTypes!.push(match.expectedType);
		}
		for (const pathStr in matchesMap) {
			const types = [...new Set(matchesMap[pathStr].expectedTypes)];

			if (types.length === 1) {
				matchesMap[pathStr].expectedType = types[0];
				delete matchesMap[pathStr].expectedTypes;
			} else {
				matchesMap[pathStr].expectedTypes = types;
			}
		}

		const errorMsg = 'Real JSON does not contain example JSON(s)';
		const error = new APIJSONMatchError(errorMsg);

		if (window.Cypress) {
			console.log(
				'\n\n📌\tPAY ATTENTION!\n\tTest has been failed.\n\tCheck examples or api schema.\n\n\n'
			);
			console.log(errorMsg, realJson, exampleJsons);
			console.log(matchesMap);
			throw error;
		} else if (SENTRY.ENABLED) {
			Sentry.captureException(error, {
				extra: { realJson, exampleJsons, matchesMap },
			});
		} else {
			console.error(errorMsg, realJson, exampleJsons);
			console.log(matchesMap);
		}

		return realJson;
	};
}

export default checkResponse;
