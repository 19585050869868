import { useStore } from 'effector-react';
import { useEffect, useState } from 'react';
import Alert, { AlertSeverity } from 'components/Alert';
import NoContent from 'components/NoContent';
import Preloader from 'components/Preloader';
import GridBody from 'components/table/GridBody';
import GridCell from 'components/table/GridCell';
import GridHead from 'components/table/GridHead';
import GridRow from 'components/table/GridRow';
import GridTable from 'components/table/GridTable';
import { getSensorsDARFx } from 'models/sensors/dar/effects';
import { sensorsDARList } from 'models/sensors/dar/store';
import { NeedHelpBlock } from 'views/common/NeedHelpBlock';
import { ReleaseUpdateAlert } from 'views/common/ReleaseUpdateAlert';
import { SensorsWrapper } from '..';
import styles from './index.module.css';
import { NoSensorBlock } from './NoSensorBlock';
import { SensorItem } from './SensorItem';

function SensorsDAR() {
	const sensors = useStore(sensorsDARList).filter((sensor) => !sensor.is_deleted);
	const [isLoading, setLoading] = useState(false);
	const isContent = sensors.length !== 0;

	useEffect(() => {
		setLoading(true);
		getSensorsDARFx().then(() => {
			setLoading(false);
		});
	}, []); // Fetch sensors from server, once.

	return (
		<SensorsWrapper>
			<Alert
				severity={AlertSeverity.info}
				header="Statuses updated every minute. Reload the page to see status changes."
				className={styles.alert}
				dataTest="sensor-status-info"
			/>

			<ReleaseUpdateAlert type="dar" />

			<GridTable dataTest="gateways-table" className={styles.table}>
				<GridHead>
					<GridRow head className={styles.rowContainer}>
						<GridCell head>Name</GridCell>

						<GridCell className={styles.tokenCell} head>
							Region
						</GridCell>

						<GridCell className={styles.tokenCell} head>
							Token
						</GridCell>
					</GridRow>
				</GridHead>

				<GridBody>
					<Preloader isLoading={isLoading}>
						{isContent ? (
							sensors.map((sensor) => <SensorItem sensor={sensor} key={sensor.id} />)
						) : (
							<NoContent type="gateway" className={styles.rowContainer} />
						)}
					</Preloader>
				</GridBody>
			</GridTable>

			{!isContent && !isLoading && <NoSensorBlock />}

			{isContent && <NeedHelpBlock />}
		</SensorsWrapper>
	);
}

export { SensorsDAR };
