import {
	Tab as UiTab,
	TabProps as UiTabProps,
	Tabs as UiTabs,
	TabsProps as UiTabsProps,
} from '@material-ui/core';
import cn from 'classnames';
import { ChangeEvent, ReactNode } from 'react';
import { Link as ReactRouterLink, matchPath, useLocation } from 'react-router-dom';
import styles from './index.module.pcss';

type TabsProps<T> = Omit<UiTabsProps, 'onChange'> & {
	value: T;
	onChange?: (value: T) => void;
};

function Tabs<T>({ onChange, ...props }: TabsProps<T>) {
	const handleChange = (event: ChangeEvent<{}>, newValue: T) => onChange?.(newValue);

	return (
		<UiTabs
			classes={{
				root: styles.tabsRoot,
				flexContainer: styles.flexContainer,
				indicator: styles.indicator,
			}}
			onChange={handleChange}
			{...props}
		/>
	);
}

function Tab(props: UiTabProps) {
	return (
		<UiTab
			classes={{ root: styles.tabRoot, wrapper: styles.wrapper, selected: styles.selected }}
			disableRipple={true}
			data-test="tab"
			{...props}
		/>
	);
}

type SomeRouterProps = {
	replace?: boolean;
};
type TabLinkProps = UiTabProps & SomeRouterProps;

function TabLink(props: TabLinkProps) {
	// @ts-ignore
	return <Tab {...props} component={ReactRouterLink} to={props.value} />;
}

function useTabLink(patterns: string[]) {
	const { pathname, search } = useLocation();

	let match = null;

	for (let i = 0; i < patterns.length; i++) {
		match = matchPath(patterns[i], [pathname, pathname + search]);

		if (match) break;
	}

	return match?.path || null;
}

interface TabPanelProps {
	className?: string;
	children?: ReactNode;
	index: unknown;
	value: unknown;
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, className, ...rest } = props;

	return (
		<div
			className={cn(styles.tabPanel, className, { [styles.hidden]: value !== index })}
			role="tabpanel"
			id={`tabpanel-${index}`}
			aria-labelledby={`tab-${index}`}
			{...rest}
		>
			{value === index && children}
		</div>
	);
}

export { Tabs, Tab, TabLink, useTabLink, TabPanel };
