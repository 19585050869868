const ELEMENT_SIZE = 5; // tiles per 'cube'
const GAP_BETWEEN_NODES = 2; // tiles between 'rags'
const GAP_BETWEEN_PLATFORMS = 2; // tiles between 'platforms'
const BRANCH_LEFT_PADDING = 1;
const BRANCH_TOP_PADDING = 1;
const BRANCH_RIGHT_PADDING = 1;
const BRANCH_BOTTOM_PADDING = 2;
const BRANCH_BOTTOM_PADDING_PLATFORM = 4;

/*
	isPlatformContent — first level inside the platform
	isPlatform — the platform level
 */
function getNodeConstants(isPlatform: boolean, isPlatformContent: boolean) {
	return {
		GAP_BETWEEN_NODES: isPlatform ? GAP_BETWEEN_PLATFORMS : GAP_BETWEEN_NODES,
		BRANCH_LEFT_PADDING,
		BRANCH_TOP_PADDING,
		BRANCH_RIGHT_PADDING,
		BRANCH_BOTTOM_PADDING: isPlatformContent
			? BRANCH_BOTTOM_PADDING_PLATFORM
			: BRANCH_BOTTOM_PADDING,
	};
}

export { ELEMENT_SIZE, getNodeConstants };
