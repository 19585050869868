import { PolicyRuleList, PolicyRuleItem } from 'models/policies/dto';
import ComplexRule from './ComplexRule';

const EMPTY_RULE: PolicyRuleItem = {
	type: 'namespace',
	operator: 'is',
	key: '',
	values: [],
};

type Props = {
	rules: PolicyRuleList;
	onChange: (mutatorFn: (draft: PolicyRuleItem[][]) => void) => void;
	hideAssetGroups?: boolean;
};

function PolicyRules(props: Props) {
	const { rules, onChange, hideAssetGroups } = props;

	function getOnChange(i: number) {
		return function onComplexRuleChange(mutatorFn: (draft: PolicyRuleItem[]) => void) {
			onChange((draft) => {
				const partialState = draft[i];
				const possibleResult = mutatorFn(partialState);
				if (possibleResult !== undefined) draft[i] = possibleResult;
			});
		};
	}

	return (
		<div>
			{rules.map((ruleArr, i) => (
				<ComplexRule
					key={i}
					prefix={i === 0 ? 'for' : 'or'}
					value={ruleArr}
					onChange={getOnChange(i)}
					hideAssetGroups={hideAssetGroups}
				/>
			))}
		</div>
	);
}

export default PolicyRules;
export { EMPTY_RULE };
