import { useMemo, useState } from 'react';
import { TransformedRule } from '..';
import { RuleBadge } from './RuleBadge';
import RuleInput from './RuleInput';

function validateUA(value: string) {
	try {
		return value.length > 0 && new RegExp(value);
	} catch (err) {
		return false;
	}
}

type Props = {
	type: 'key' | 'value';
	value: TransformedRule['key'] | TransformedRule['value'];
	onChange: (rule: TransformedRule['key'] | TransformedRule['value']) => void;
};

export function RuleRowItem({ type, value, onChange }: Props) {
	const isValueValid = useMemo(
		function isValueValidMemoization() {
			return validateUA(value);
		},
		[value]
	);
	const [editMode, setEditMode] = useState(!isValueValid);

	function handleConfirm() {
		if (isValueValid) {
			setEditMode(false);
		}
	}

	function handleChangeAndConfirm(newValue: string) {
		onChange(newValue);

		if (validateUA(newValue)) {
			setEditMode(false);
		}
	}

	function handleEnableEdit() {
		setEditMode(true);
	}

	const error = !isValueValid ? 'Invalid value' : null;

	return editMode ? (
		<RuleInput
			type={type}
			value={value}
			onChange={onChange}
			onConfirm={handleConfirm}
			onChangeAndConfirm={handleChangeAndConfirm}
			error={error}
		/>
	) : (
		<RuleBadge value={value} onEdit={handleEnableEdit} />
	);
}
