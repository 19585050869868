import Typo from 'components/typography/Typo';
import { kafkaTopicsSteps } from 'layouts/AuthorizedWithLeftMenu/Breadcrumbs';
import Header, { HeaderProps } from 'layouts/AuthorizedWithLeftMenu/Header';
import styles from './index.module.css';

interface WashedSchemaProps {
	state: { name: string } | null;
	id: string;
}

function WashedSchema({ state, id }: WashedSchemaProps) {
	let titleBlock: HeaderProps['titleBlock'] = `Message schema with ID ${id}`;

	if (state) {
		titleBlock = state.name;
	}

	return (
		<>
			<Header
				breadcrumbProps={{
					steps: [...kafkaTopicsSteps],
					finalStep: 'Message schema',
				}}
				titleBlock={titleBlock}
			/>
			<Typo variant="D/Regular/Body-S" color="secondary" className={styles.noSample}>
				No current message schema available, as there has been
				<br /> no traffic in the last 7 days.
			</Typo>
		</>
	);
}

export type { WashedSchemaProps };
export { WashedSchema };
