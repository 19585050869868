import { createEffect } from 'effector';
import { getJiraSettings, updateJiraSettings } from './api';
import { JiraSettings, JiraSettingsUpdate } from './dto';

type CacheParams = { cacheTime: number } | void;

/* API */

const getJiraSettingsFx = createEffect<CacheParams, JiraSettings>();
const updateJiraSettingsFx = createEffect<JiraSettingsUpdate, JiraSettings>();

/* IMPLEMENTATION */

let lastRequestTime = 0;
let lastRequestPromise: Promise<JiraSettings> | null = null;

getJiraSettingsFx.use(function (params?: CacheParams) {
	const now = new Date().getTime();

	if (lastRequestPromise === null || !params || params.cacheTime <= now - lastRequestTime) {
		lastRequestTime = now;
		lastRequestPromise = getJiraSettings();
	}

	return lastRequestPromise;
});

updateJiraSettingsFx.use(updateJiraSettings);

export { getJiraSettingsFx, updateJiraSettingsFx };
