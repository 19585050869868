import { IHeadCell } from 'components/table/EnhancedTableHead';
import { S3BucketDataFlowsTableItem } from 'models/s3Buckets/dto';

const tableConfig: IHeadCell<S3BucketDataFlowsTableItem>[] = [
	{
		id: 'is_encrypted',
		numeric: false,
		label: '',
		sortable: false,
		filter: false,
	},
	{
		id: 'interacts_with',
		numeric: false,
		label: 'Interacts with',
		sortable: true,
		filter: true,
	},
	{
		id: 'namespace',
		numeric: false,
		label: 'Namespace',
		sortable: true,
		filter: false,
	},
	{
		id: 'last_seen',
		numeric: false,
		label: 'Last checked',
		sortable: true,
		reversedSort: true,
	},
];

export default tableConfig;
