import { InputAdornment } from '@material-ui/core';
import cn from 'classnames';
import { ChangeEvent, FocusEvent, KeyboardEvent, ClipboardEvent, useRef, useState } from 'react';
import ButtonIcon from 'components/ButtonIcon';
import TextField from 'components/form/TextField';
import Typo from 'components/typography/Typo';
import styles from './index.module.css';

const SPLITTER = /\t|,/;

type Props = {
	type: 'ip' | 'userAgent';
	value: string;
	onChange: (value: string) => void;
	onConfirm: () => void;
	onChangeAndConfirm: (value: string) => void;
	onCreate: () => void;
	onDelete: () => void;
	onPasteMultiple: (values: string[]) => void;
	error: string | null;
};

function RuleInput(props: Props) {
	const {
		type,
		value,
		onChange,
		onConfirm,
		onChangeAndConfirm,
		onCreate,
		onDelete,
		onPasteMultiple,
		error,
	} = props;

	const inputRef = useRef<HTMLInputElement>(null);

	const [isFocused, setFocused] = useState(false);
	const [savedValue, setSavedValue] = useState(value); // For Escape key.

	function onKeyDown(e: KeyboardEvent<HTMLElement>) {
		// Special keys handling could be made inside parent component. But we need to keep it here for 'defocusing' behaviour, which uses ref to DOM element.

		if (e.key === 'Enter') {
			// After pressing Enter, defocus.
			e.preventDefault();
			inputRef.current?.blur();
		} else if (e.key === ',') {
			// After pressing comma, defocus and (if value is valid) create another empty rule.
			e.preventDefault();
			inputRef.current?.blur();
			onCreate();
		} else if (e.key === 'Escape') {
			// After pressing comma, restore value that was at the moment of focusing, and defocus.
			e.preventDefault();
			inputRef.current?.blur();
			onChangeAndConfirm(savedValue);
		}
	}

	function handleChange(e: ChangeEvent<HTMLInputElement>) {
		onChange(e.target.value);
	}

	function onFocus() {
		setFocused(true);
		setSavedValue(value);
	}

	function onBlur(e: FocusEvent<HTMLInputElement>) {
		if (e.target.parentNode?.contains(e.relatedTarget as Node)) {
			e.preventDefault();
			e.stopPropagation();
			return;
		}

		setFocused(false);
		onConfirm();
	}

	function onPaste(e: ClipboardEvent<HTMLInputElement>) {
		if (type !== 'ip') return;

		const pasted = e.clipboardData.getData('text').trim();

		if (!SPLITTER.test(pasted)) return;

		e.preventDefault();
		const values = pasted.split(SPLITTER).map((val) => val.trim());
		onPasteMultiple(values);
	}

	const dataTest = type === 'ip' ? 'asset-rules-ip-input' : 'asset-rules-agent-input';
	const isIPRange = type === 'ip' && value.includes('-');

	return (
		<>
			<TextField
				dataTest={dataTest}
				size="extraSmall"
				helperText={null}
				error={!isFocused && !!error}
				autoFocus
				value={value}
				onKeyDown={onKeyDown}
				onChange={handleChange}
				InputProps={{
					inputRef,
					onFocus,
					onBlur,
					onPaste,
					endAdornment: (
						<InputAdornment position="end">
							<ButtonIcon
								className={styles.buttonClose}
								icon="DismissCircle/Filled"
								onClick={onDelete}
								size="XS"
							/>
						</InputAdornment>
					),
					classes: {
						root: cn(styles.input, isIPRange && styles.ipRange),
					},
				}}
			/>
			{!isFocused && error && (
				<Typo variant="D/Regular/Meta-S" className={styles.errorText}>
					{error}
				</Typo>
			)}
		</>
	);
}

export default RuleInput;
