import jp from 'jsonpath';

type PathComponent = {
	[key: string]: unknown;
	expression?: {
		type?: string;
		value?: unknown;
	};
};

const unescapeRegex = /\\(['\\])/g;

function unescapeStringLiterals(components: PathComponent[]) {
	// Required due to bug in library implementation - does not unescape string literals, which
	// leads to non-complementary operations of path/stringify:
	//
	// 		var pathExample = "$['\\\\']"
	//		jp.stringify(jp.parse(pathExample)) === pathExample // FALSE

	return components.map((component: PathComponent) => {
		if (
			component?.expression?.type === 'string_literal' &&
			typeof component?.expression?.value === 'string'
		) {
			const unescapedValue = component.expression.value.replace(unescapeRegex, '$1');
			return { ...component, expression: { ...component.expression, value: unescapedValue } };
		} else {
			return component;
		}
	});
}

function getJsonLocation(path: string) {
	const pathAsArray = unescapeStringLiterals(jp.parse(path));
	pathAsArray.shift(); // Remove root node '$'
	return pathAsArray.map((part) => part?.expression?.value as string | number);
}

export { getJsonLocation };
