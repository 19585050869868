import { IHeadCell } from 'components/table/EnhancedTableHead';
import { AssetGroupDataFlowJson } from 'models/assetsGroupsDataFlows/dto';

const tableConfig: IHeadCell<AssetGroupDataFlowJson>[] = [
	{
		id: 'flow_direction',
		numeric: false,
		label: 'Flow',
		sortable: false,
		filter: false,
	},
	{
		id: 'interacts_with',
		numeric: false,
		label: 'Interacts with',
		sortable: true,
		filter: true,
	},
	{
		id: 'sensitivity',
		numeric: false,
		label: 'Sensitivity',
		sortable: true,
	},
	{
		id: 'data_types',
		numeric: false,
		label: 'Discovered Data Types',
		sortable: false,
	},
	{
		id: 'last_seen',
		numeric: false,
		label: 'Last checked',
		sortable: true,
		reversedSort: true,
	},
];

export default tableConfig;
