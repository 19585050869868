import Typo from 'components/typography/Typo';
import { EventWithDARSample, PolicyS3EventJson } from 'models/eventsV2/dto';
import { DateFormat, dayjs } from 'services/dayjs';
import { toLocaleString } from 'services/numbers';
import { getStringDividedByDot } from 'services/strings';
import styles from './index.module.css';

type Props = {
	event: EventWithDARSample | null;
};

export function HeaderDAR({ event }: Props) {
	if (!event) return null;

	let title = null;
	let description = null;
	const createdAt = dayjs(event.created_at).format(DateFormat.timestamp);

	function isPolicyEvent(_event: EventWithDARSample): _event is PolicyS3EventJson {
		return (
			_event.type === 'policy_public_s3_bucket' || _event.type === 'policy_unencrypted_s3_bucket'
		);
	}

	if (isPolicyEvent(event)) {
		title = event.s3_bucket.sample_name;
	} else {
		let objectType = null;

		if (event.s3_bucket) {
			title = event.s3_bucket.sample_name;
			objectType = 'S3 bucket';
		} else if (event.database) {
			title = event.database.table_name;
			objectType = 'Database';
		} else if (event.kafka) {
			title = `offset: ${toLocaleString(event.kafka.offset)}; partition: ${event.kafka.partition}`;
			objectType = 'Kafka topic';
		}

		description = getStringDividedByDot(`Object type: ${objectType}`, createdAt);
	}

	return (
		<div className={styles.headerContainer}>
			<Typo
				variant="D/Medium/H400-Page-Title"
				className={styles.overflow}
				dataTest="endpoint-header-title"
			>
				{title}
			</Typo>

			<div className={styles.subheader} data-test="endpoint-header-info">
				<Typo variant="D/Regular/Body-S" color="secondary">
					{description}
				</Typo>
			</div>
		</div>
	);
}
