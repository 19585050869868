import { getDataTypesAsApiParam } from 'models/dataTypes/helpers';
import { createTableModel } from 'models/modelUtils/tableModelFactory';
import { ApiParams } from 'services/api';
import { getKafkaTopicList, getKafkaTopicSampleList } from './api';
import {
	KafkaTopicSampleItem,
	KafkaTopicSampleTableParams,
	KafkaTopicTableItem,
	KafkaTopicTableParams,
} from './dto';

function fetchDataApi(apiParamsRaw: ApiParams, signal?: AbortSignal) {
	const { 'data-types': piiTypes, ...apiParams } = apiParamsRaw;

	apiParams['data-types'] = getDataTypesAsApiParam(piiTypes);

	return getKafkaTopicList(apiParams, signal).then((payload) => {
		return {
			data: payload.topics,
			total: payload.total,
			total_filtered: payload.total_filtered,
		};
	});
}

function createKafkaTopicsModel(
	initialParams: KafkaTopicTableParams,
	fetchFxOnInit: boolean = true
) {
	return createTableModel<KafkaTopicTableItem, KafkaTopicTableParams>(
		initialParams,
		fetchDataApi,
		fetchFxOnInit
	);
}

//
// Instantiate model with initial params, but do not fetch data from server.
//

const initialParams: KafkaTopicTableParams = {
	instance_ids: [],
	'data-types': [],
	search: '',
	sort: { orderBy: 'name', order: 'desc' },
};

const kafkaTopicsModel = createKafkaTopicsModel(initialParams, false);

function fetchDataSampleApi(apiParamsRaw: ApiParams, signal?: AbortSignal) {
	const { id, 'data-types': piiTypes, ...apiParams } = apiParamsRaw;

	apiParams['data-types'] = getDataTypesAsApiParam(piiTypes);

	return getKafkaTopicSampleList(Number(id), apiParams, signal).then((payload) => {
		return {
			data: payload.samples,
			total: payload.total,
			total_filtered: payload.total_filtered,
		};
	});
}

function createKafkaTopicSamplesModel(
	initialParamsSamples: KafkaTopicSampleTableParams,
	fetchFxOnInit: boolean = true
) {
	return createTableModel<KafkaTopicSampleItem, KafkaTopicSampleTableParams>(
		initialParamsSamples,
		fetchDataSampleApi,
		fetchFxOnInit
	);
}

//
// Instantiate model with initial params, but do not fetch data from server.
//

const initialParamsSamples: KafkaTopicSampleTableParams = {
	id: -1,
	'data-types': [],
	partition: '',
	sort: { orderBy: 'offset', order: 'desc' },
};

const kafkaTopicSamplesModel = createKafkaTopicSamplesModel(initialParamsSamples, false);

export { kafkaTopicsModel, kafkaTopicSamplesModel };
