import { createEffect } from 'effector';
import {
	createPolicy,
	deletePolicy,
	generatePolicies,
	getPolicyItem,
	getPolicyList,
	togglePolicyTracking,
	updatePolicy,
} from './api';
import { PolicyItem, PolicyList } from './dto';

/* API */

const getPolicyListFx = createEffect<void, PolicyList>();
const getPolicyItemFx = createEffect<PolicyItem['id'], PolicyItem>();
const createPolicyFx = createEffect<PolicyItem, PolicyItem>();
const updatePolicyFx = createEffect<PolicyItem, PolicyItem>();
const deletePolicyFx = createEffect<PolicyItem['id'], PolicyItem['id']>();
const togglePolicyTrackingFx = createEffect<
	{ id: PolicyItem['id']; is_active: PolicyItem['is_active'] },
	{ id: PolicyItem['id']; is_active: PolicyItem['is_active'] }
>();
const generatePoliciesFx = createEffect<void, PolicyList>();

/* IMPLEMENTATION */

// Simple direct-to-api effects

getPolicyListFx.use(getPolicyList);
getPolicyItemFx.use(getPolicyItem);
createPolicyFx.use(createPolicy);
updatePolicyFx.use(updatePolicy);
deletePolicyFx.use(deletePolicy);
togglePolicyTrackingFx.use(togglePolicyTracking);
generatePoliciesFx.use(generatePolicies);

export {
	getPolicyListFx,
	getPolicyItemFx,
	createPolicyFx,
	updatePolicyFx,
	deletePolicyFx,
	togglePolicyTrackingFx,
	generatePoliciesFx,
};
