import cn from 'classnames';
import Button from 'components/form/Button';
import Icon from 'components/Icon';
import Typo from 'components/typography/Typo';
import { PATHS } from 'services/router';
import { MapAsset } from '../../../index';
import CloseButton from '../CloseButton';
import commonStyles from '../index.module.css';
import Label from '../Label';

type Props = {
	namespace: MapAsset['namespace'];
};

function NamespaceDetails(props: Props) {
	const { namespace } = props;

	return (
		<div
			className={cn(commonStyles.container, commonStyles.sidePaddings)}
			data-test="data-map-details-namespace"
		>
			<div className={commonStyles.header}>
				<Typo variant="D/Medium/H100-Header">{namespace}</Typo>

				<CloseButton />
			</div>

			<div className={commonStyles.detailsGrid}>
				<Label title="Resource">Namespace</Label>
			</div>

			<Button
				color="tertiary"
				size="small"
				endIcon={<Icon name="Open/Regular" size={20} />}
				fullWidth
				href={`${PATHS.SERVICES}?namespaces=${namespace}`}
				target="_blank"
			>
				View detailed info
			</Button>
		</div>
	);
}

export default NamespaceDetails;
