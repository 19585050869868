import { addImage } from 'models/dataMapV2/cubeImagesStore';
// assets
import assetDefault from './images/asset/assetDefault.png';
import assetDefaultHovered from './images/asset/assetDefaultHovered.png';
import assetDefaultSelected from './images/asset/assetDefaultSelected.png';
import assetHigh from './images/asset/assetHigh.png';
import assetHighHovered from './images/asset/assetHighHovered.png';
import assetHighSelected from './images/asset/assetHighSelected.png';
import assetMedium from './images/asset/assetMedium.png';
import assetMediumHovered from './images/asset/assetMediumHovered.png';
import assetMediumSelected from './images/asset/assetMediumSelected.png';
// database
import databaseDefault from './images/database/default.png';
import databaseDefaultHovered from './images/database/defaultHovered.png';
import databaseDefaultSelected from './images/database/defaultSelected.png';
import databaseHigh from './images/database/high.png';
import databaseHighHovered from './images/database/highHovered.png';
import databaseHighSelected from './images/database/highSelected.png';
import databaseMedium from './images/database/medium.png';
import databaseMediumHovered from './images/database/mediumHovered.png';
import databaseMediumSelected from './images/database/mediumSelected.png';
// honeycombs
import honeycombsDefault from './images/honeycombs/honeycombsDefault.png';
import honeycombsDefaultHovered from './images/honeycombs/honeycombsDefaultHovered.png';
import honeycombsDefaultSelected from './images/honeycombs/honeycombsDefaultSelected.png';
import honeycombsHigh from './images/honeycombs/honeycombsHigh.png';
import honeycombsHighHovered from './images/honeycombs/honeycombsHighHovered.png';
import honeycombsHighSelected from './images/honeycombs/honeycombsHighSelected.png';
import honeycombsMedium from './images/honeycombs/honeycombsMedium.png';
import honeycombsMediumHovered from './images/honeycombs/honeycombsMediumHovered.png';
import honeycombsMediumSelected from './images/honeycombs/honeycombsMediumSelected.png';
// kafka
import kafkaDefault from './images/kafka/kafkaDefault.png';
import kafkaDefaultHovered from './images/kafka/kafkaDefaultHovered.png';
import kafkaDefaultSelected from './images/kafka/kafkaDefaultSelected.png';
import kafkaHigh from './images/kafka/kafkaHigh.png';
import kafkaHighHovered from './images/kafka/kafkaHighHovered.png';
import kafkaHighSelected from './images/kafka/kafkaHighSelected.png';
import kafkaMedium from './images/kafka/kafkaMedium.png';
import kafkaMediumHovered from './images/kafka/kafkaMediumHovered.png';
import kafkaMediumSelected from './images/kafka/kafkaMediumSelected.png';
// label
import labelCustom from './images/labelCustom.png';
// s3
import s3Default from './images/s3/default.png';
import s3DefaultHovered from './images/s3/defaultHovered.png';
import s3DefaultSelected from './images/s3/defaultSelected.png';
import s3High from './images/s3/high.png';
import s3HighHovered from './images/s3/highHovered.png';
import s3HighSelected from './images/s3/highSelected.png';
import s3Medium from './images/s3/medium.png';
import s3MediumHovered from './images/s3/mediumHovered.png';
import s3MediumSelected from './images/s3/mediumSelected.png';
// vm
import vmDefault from './images/vm/default.png';
import vmDefaultHovered from './images/vm/defaultHovered.png';
import vmDefaultSelected from './images/vm/defaultSelected.png';
import vmHigh from './images/vm/high.png';
import vmHighHovered from './images/vm/highHovered.png';
import vmHighSelected from './images/vm/highSelected.png';
import vmMedium from './images/vm/medium.png';
import vmMediumHovered from './images/vm/mediumHovered.png';
import vmMediumSelected from './images/vm/mediumSelected.png';

const cubeImages = [
	{ id: 'labelCustom', src: labelCustom },

	// assets
	{ id: 'assetDefault', src: assetDefault },
	{ id: 'assetDefaultHovered', src: assetDefaultHovered },
	{ id: 'assetDefaultSelected', src: assetDefaultSelected },

	{ id: 'assetMedium', src: assetMedium },
	{ id: 'assetMediumSelected', src: assetMediumSelected },
	{ id: 'assetMediumHovered', src: assetMediumHovered },

	{ id: 'assetHigh', src: assetHigh },
	{ id: 'assetHighSelected', src: assetHighSelected },
	{ id: 'assetHighHovered', src: assetHighHovered },

	// honeycombs
	{ id: 'honeycombsDefault', src: honeycombsDefault },
	{ id: 'honeycombsDefaultHovered', src: honeycombsDefaultHovered },
	{ id: 'honeycombsDefaultSelected', src: honeycombsDefaultSelected },

	{ id: 'honeycombsMedium', src: honeycombsMedium },
	{ id: 'honeycombsMediumSelected', src: honeycombsMediumSelected },
	{ id: 'honeycombsMediumHovered', src: honeycombsMediumHovered },

	{ id: 'honeycombsHigh', src: honeycombsHigh },
	{ id: 'honeycombsHighSelected', src: honeycombsHighSelected },
	{ id: 'honeycombsHighHovered', src: honeycombsHighHovered },

	// s3
	{ id: 's3Default', src: s3Default },
	{ id: 's3DefaultHovered', src: s3DefaultHovered },
	{ id: 's3DefaultSelected', src: s3DefaultSelected },

	{ id: 's3Medium', src: s3Medium },
	{ id: 's3MediumHovered', src: s3MediumHovered },
	{ id: 's3MediumSelected', src: s3MediumSelected },

	{ id: 's3High', src: s3High },
	{ id: 's3HighHovered', src: s3HighHovered },
	{ id: 's3HighSelected', src: s3HighSelected },

	// kafka
	{ id: 'kafkaDefault', src: kafkaDefault },
	{ id: 'kafkaDefaultHovered', src: kafkaDefaultHovered },
	{ id: 'kafkaDefaultSelected', src: kafkaDefaultSelected },

	{ id: 'kafkaMedium', src: kafkaMedium },
	{ id: 'kafkaMediumHovered', src: kafkaMediumHovered },
	{ id: 'kafkaMediumSelected', src: kafkaMediumSelected },

	{ id: 'kafkaHigh', src: kafkaHigh },
	{ id: 'kafkaHighHovered', src: kafkaHighHovered },
	{ id: 'kafkaHighSelected', src: kafkaHighSelected },

	// database
	{ id: 'databaseDefault', src: databaseDefault },
	{ id: 'databaseDefaultHovered', src: databaseDefaultHovered },
	{ id: 'databaseDefaultSelected', src: databaseDefaultSelected },

	{ id: 'databaseMedium', src: databaseMedium },
	{ id: 'databaseMediumHovered', src: databaseMediumHovered },
	{ id: 'databaseMediumSelected', src: databaseMediumSelected },

	{ id: 'databaseHigh', src: databaseHigh },
	{ id: 'databaseHighHovered', src: databaseHighHovered },
	{ id: 'databaseHighSelected', src: databaseHighSelected },

	// vm
	{ id: 'vmDefault', src: vmDefault },
	{ id: 'vmDefaultHovered', src: vmDefaultHovered },
	{ id: 'vmDefaultSelected', src: vmDefaultSelected },

	{ id: 'vmMedium', src: vmMedium },
	{ id: 'vmMediumHovered', src: vmMediumHovered },
	{ id: 'vmMediumSelected', src: vmMediumSelected },

	{ id: 'vmHigh', src: vmHigh },
	{ id: 'vmHighHovered', src: vmHighHovered },
	{ id: 'vmHighSelected', src: vmHighSelected },
];

export const loadCubeImages = () => {
	cubeImages.forEach(({ id, src }) => {
		const image = new Image();
		image.src = src;

		addImage({ id, image });
	});
};
