import { createEffect } from 'effector';
import {
	getAssetGroups,
	getAssetGroup,
	createAssetGroup,
	updateAssetGroup,
	deleteAssetGroup,
} from './api';
import { AssetGroup, AssetGroupList } from './dto';

/* API */

const getAssetGroupsFx = createEffect<void, AssetGroupList>();
const getAssetGroupFx = createEffect<AssetGroup['id'], AssetGroup>();
const createAssetGroupFx = createEffect<AssetGroup, AssetGroup>();
const updateAssetGroupFx = createEffect<AssetGroup, AssetGroup>();
const deleteAssetGroupFx = createEffect<AssetGroup['id'], AssetGroup['id']>();

/* IMPLEMENTATION */

// Simple direct-to-api effects

getAssetGroupsFx.use(getAssetGroups);
getAssetGroupFx.use(getAssetGroup);
createAssetGroupFx.use(createAssetGroup);
updateAssetGroupFx.use(updateAssetGroup);
deleteAssetGroupFx.use(deleteAssetGroup);

export {
	getAssetGroupsFx,
	getAssetGroupFx,
	createAssetGroupFx,
	updateAssetGroupFx,
	deleteAssetGroupFx,
};
