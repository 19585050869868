type OIDCConfig = {
	oidc: {
		is_enabled: boolean;
		client_id: string;
		client_secret: string;
		issuer: string;
		email_domains: string[];
		manage_users: boolean;
	};
};

type ADFSConfig = {
	adfs: {
		is_enabled: boolean;
		client_id: string;
		client_secret: string;
		authorization_endpoint: string;
		token_endpoint: string;
		email_domains: string[];
		manage_users: boolean;
	};
};

type TSSOSettings = OIDCConfig | ADFSConfig;

function isOIDC(settings: TSSOSettings): settings is OIDCConfig {
	return 'oidc' in settings;
}

export type { TSSOSettings, OIDCConfig, ADFSConfig };
export { isOIDC };
