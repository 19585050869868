import { IHeadCell } from 'components/table/EnhancedTableHead';
import { ClusterFileSample } from 'models/s3BucketCluster/dto';

const tableConfig: IHeadCell<ClusterFileSample>[] = [
	{
		id: 'name',
		numeric: false,
		label: 'File name',
		sortable: true,
	},
	{
		id: 'size',
		numeric: true,
		label: 'Size',
		sortable: true,
	},
	{
		id: 'estimated_rows',
		numeric: true,
		label: 'Est. rows',
		sortable: true,
	},
	{
		id: 'data_types',
		numeric: false,
		label: 'Data types',
		sortable: false,
	},
	{
		id: 'created_at',
		numeric: false,
		label: 'Created',
		sortable: true,
	},
	{
		id: 'last_scan_at',
		numeric: false,
		label: 'Last scan',
		sortable: true,
		reversedSort: true,
	},
	{
		id: 'action',
		numeric: false,
		label: '',
		sortable: false,
	},
];

export { tableConfig };
