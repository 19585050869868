import { get, post } from 'api/Api';
import { getAssets } from 'models/assets/api';
import { AssetJson, AssetsJson } from 'models/assets/dto';
import { ApiParams } from 'services/api';
import checkResponse from 'services/api/checkResponse';
import {
	S3BucketItem,
	S3BucketList,
	S3BucketStatus,
	S3BucketDataFlowsList,
	S3BucketDataFlowJson,
	S3BucketDataFlowsTableItem,
} from './dto';
import {
	s3BucketItemsExample,
	s3BucketListExample,
	s3BucketStatusExample,
	s3BucketDataFlowsListExample,
} from './examples';

const API_URL = 'v1/s3/buckets';

async function getS3Buckets(apiParams: ApiParams, signal?: AbortSignal) {
	const config = signal ? { signal } : {};

	return get<S3BucketList>(API_URL, apiParams, config).then(checkResponse(s3BucketListExample));
}

async function getS3BucketItem(id: S3BucketItem['id'], signal?: AbortSignal) {
	const config = signal ? { signal } : {};

	return get<S3BucketItem>(`${API_URL}/${id}`, {}, config).then(
		checkResponse(s3BucketItemsExample[0])
	);
}

async function updateS3Bucket({ id, ...payload }: S3BucketItem) {
	return post<S3BucketItem>(`${API_URL}/${id}`, payload).then(
		checkResponse(s3BucketItemsExample[0])
	);
}

async function getS3BucketStatus({ id, nonEmpty }: { id: S3BucketItem['id']; nonEmpty: boolean }) {
	const params = nonEmpty ? { only_with_pii: true } : undefined;

	return await get<S3BucketStatus>(`${API_URL}/${id}/status`, params).then(
		checkResponse(s3BucketStatusExample)
	);
}

async function getS3BucketDataFlows(
	id: S3BucketItem['id'],
	apiParams: ApiParams,
	signal?: AbortSignal
) {
	const config = signal ? { signal } : {};

	return get<S3BucketDataFlowsList>(`v2/buckets/${id}/dataflows`, apiParams, config)
		.then(checkResponse(s3BucketDataFlowsListExample))
		.then((data: S3BucketDataFlowsList) => {
			return getAssets().then((assetsPayload: AssetsJson) => {
				return {
					...data,
					dataflows: enrichWithAssetData(data.dataflows, assetsPayload.assets),
				};
			});
		});
}

function enrichWithAssetData(
	dataflows: S3BucketDataFlowJson[],
	assets: AssetJson[]
): S3BucketDataFlowsTableItem[] {
	return dataflows.map((dataflow) => {
		const asset = assets.find((a) => a.id === dataflow.interacts_with);

		if (!asset) throw new Error(`Asset '${dataflow.interacts_with}' not found`);

		return {
			...dataflow,
			name: asset.name,
			is_external: asset.is_external,
			type: asset.type,
			k8s_types: asset.k8s_types,
			namespace: asset.namespace,
			groups: asset.groups,
			cluster_id: asset.cluster_id,
		};
	});
}

export { getS3Buckets, getS3BucketItem, updateS3Bucket, getS3BucketStatus, getS3BucketDataFlows };
