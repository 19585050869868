import { createStore } from 'effector';
import { dayjs } from 'services/dayjs';
import { DailyActivity, RecentActivityJson } from './dto';
import { getRecentActivityFx } from './effects';

const DAYS_OF_ACTIVITY = 7;

function generateEmptyActivityDays(days: number): DailyActivity[] {
	const BEGINNING_OF_TODAY = dayjs().startOf('day');

	return new Array(days).fill(true).map((day, dayIndex) => {
		return {
			// It's supposed that base point of time is beginning of today
			// We collect items of activity in the order from past to present
			date: +BEGINNING_OF_TODAY.subtract(DAYS_OF_ACTIVITY - dayIndex - 1, 'day'),
			value: 0,
		};
	});
}

const initialWeaklyActivity = {
	weekly: 0,
	weekly_delta: 0,
	daily: generateEmptyActivityDays(DAYS_OF_ACTIVITY),
};

const initialState: RecentActivityJson = {
	api_calls: initialWeaklyActivity,
	data_types: initialWeaklyActivity,
	data_fields: initialWeaklyActivity,
	hosts: initialWeaklyActivity,
	endpoints: initialWeaklyActivity,
	data_users: initialWeaklyActivity,
};

const recentActivityStore = createStore<RecentActivityJson>(initialState);
const activityStore = recentActivityStore.map((state) => state.api_calls.weekly !== 0);

recentActivityStore.on(
	getRecentActivityFx.doneData,
	(state: RecentActivityJson, data: RecentActivityJson) => {
		Object.entries(data).forEach(([, dailyActivity]) => {
			dailyActivity.daily = [
				...generateEmptyActivityDays(DAYS_OF_ACTIVITY - dailyActivity.daily.length),
				...dailyActivity.daily,
			];
		});

		return data;
	}
);

export { recentActivityStore, activityStore };
