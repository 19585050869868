import { isPseudoRag, Rag, PseudoRag } from './listToTreeByAttributes';

type Parent<T, U, V> = { parent: Rag<T, U, V & Parent<T, U, V>> | null };

function closest<T, Level, V>(
	ragOrCube: Parent<T, Level, V>,
	level: Level
): Rag<T, Level, V> | null {
	if (ragOrCube.parent === null) return null;
	if (ragOrCube.parent.level === level) return ragOrCube.parent;

	return closest(ragOrCube.parent, level);
}

function addParentPropertyToTree<T, U, V>(
	rag: Rag<T, U, V>,
	parent: Rag<T, U, V & Parent<T, U, V>> | null
): Rag<T, U, V & Parent<T, U, V>> {
	if (isPseudoRag(rag)) {
		var pseudoRag: PseudoRag<T, V & Parent<T, U, V>> = Object.assign(rag, {
			parent,
			data: rag.data.map((d) => ({ ...d, parent: rag as typeof pseudoRag })),
		});

		return pseudoRag;
	} else {
		var realRag: Rag<T, U, V & Parent<T, U, V>> = Object.assign(rag, {
			parent,
			children: rag.children.map((c) => addParentPropertyToTree(c, rag as typeof realRag)),
		});

		return realRag;
	}
}

export default addParentPropertyToTree;
export { closest };
