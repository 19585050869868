import Typo from 'components/typography/Typo';
import { databasesSteps } from 'layouts/AuthorizedWithLeftMenu/Breadcrumbs';
import Header, { HeaderProps } from 'layouts/AuthorizedWithLeftMenu/Header';
import { getStringDividedByDot } from 'services/strings';
import styles from './index.module.css';

interface WashedSampleProps {
	state: { instance_name: string; table_name: string; type: string } | null;
	id: string;
}

function WashedSample({ id, state }: WashedSampleProps) {
	let titleBlock: HeaderProps['titleBlock'] = `Database sample with ID ${id}`;

	if (state) {
		titleBlock = (
			<div className={styles.headerContainer}>
				<Typo variant="D/Medium/H400-Page-Title">{state.table_name}</Typo>

				<Typo variant="D/Regular/Body-S" color="secondary">
					{getStringDividedByDot(state.instance_name, state.type)}
				</Typo>
			</div>
		);
	}
	return (
		<>
			<Header
				breadcrumbProps={{
					steps: databasesSteps,
					finalStep: 'Sample',
				}}
				titleBlock={titleBlock}
			/>

			<Typo variant="D/Regular/Body-S" color="secondary" className={styles.noSample}>
				No data available, as the table have not been found during the last scan.
			</Typo>
		</>
	);
}

export type { WashedSampleProps };
export { WashedSample };
