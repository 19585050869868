import cn from 'classnames';
import GatewayStatusIcon from 'components/GatewayStatusIcon';
import { SecretToken } from 'components/SecretToken';
import secretTokenStyles from 'components/SecretToken/index.module.pcss';
import GridRow from 'components/table/GridRow';
import { SensorDARItem } from 'models/sensors/dar/dto';
import { GatewayRegion } from '../../DataInMotion/GatewayRegion';
import DeleteSensor from '../../DeleteSensor';
import sensorStyles from '../index.module.css';
import { SensorName } from '../SensorName';
import styles from './index.module.css';

type Props = {
	sensor: SensorDARItem;
};

function SensorItem(props: Props) {
	const { sensor } = props;

	return (
		<GridRow
			className={cn(sensorStyles.rowContainer, styles.rowContainer)}
			hover
			border
			data-test="gateway-item-row"
		>
			<div className={styles.statusContainer}>
				<GatewayStatusIcon status={sensor.status} hoverable={false} />

				<SensorName sensor={sensor} />
			</div>

			<GatewayRegion sensor={sensor} type="dar" />

			<SecretToken
				canBeCopied
				value={sensor.token}
				InputProps={{
					classes: { root: secretTokenStyles.inRow },
				}}
				helperText={null}
				className={styles.token}
			/>

			<DeleteSensor sensor={sensor} type="DAR" />
		</GridRow>
	);
}

export { SensorItem };
