import { DataCombinationsList } from './dto';

const dataCombinationsExample: DataCombinationsList = {
	total: 2,
	data_combinations: [
		{
			data_types: [1, 2],
			sensitivity: 'High',
			endpoints: 12,
			endpoints_percent: 59.5,
		},
		{
			data_types: [1],
			sensitivity: 'High',
			endpoints: 100,
			endpoints_percent: 30.5,
		},
	],
};

export { dataCombinationsExample };
