import { useStore } from 'effector-react';
import { useEffect, useState } from 'react';
import { generatePath } from 'react-router';
import { useHistory } from 'react-router-dom';
import commonStyles from 'assets/styles/index.module.pcss';
import Button from 'components/form/Button';
import Icon from 'components/Icon';
import EnhancedTableHead from 'components/table/EnhancedTableHead';
import GridBody from 'components/table/GridBody';
import GridTable from 'components/table/GridTable';
import Typo from 'components/typography/Typo';
import Header from 'layouts/AuthorizedWithLeftMenu/Header';
import { getAssetGroupsFx } from 'models/assetsGroups/effects';
import { assetGroupListExample } from 'models/assetsGroups/examples';
import { assetGroupsList } from 'models/assetsGroups/store';
import { PATHS } from 'services/router';
import AssetGroupTableItem from './AssetGroupTableItem';
import tableConfig from './config';
import styles from './index.module.css';

function AssetGroupsList() {
	let skeletonMode = false;
	let assetGroups = useStore(assetGroupsList);
	const history = useHistory();
	const [isLoading, setLoading] = useState(true);

	useEffect(() => {
		getAssetGroupsFx().then(() => setLoading(false));
	}, []);

	if (!isLoading && assetGroups.length === 0) {
		skeletonMode = true;
		assetGroups = assetGroupListExample.groups;
	}

	function noop() {
		return undefined;
	}

	return (
		<div>
			<Header
				breadcrumbProps={{ finalStep: 'Groups' }}
				titleBlock={
					<>
						<Typo variant="D/Medium/H400-Page-Title">Groups</Typo>

						{!skeletonMode && (
							<Button
								data-test="group-header-create-button"
								size="small"
								startIcon={<Icon name="Add/Regular" size={20} />}
								onClick={() => {
									const path = generatePath(PATHS.ASSET_GROUP_ITEM, { id: 'new' });
									history.push(path);
								}}
							>
								New group
							</Button>
						)}
					</>
				}
			/>

			<GridTable dataTest="group-table">
				<EnhancedTableHead
					config={tableConfig}
					onRequestSort={noop}
					onRequestFilter={noop}
					rowClassname={styles.rowContainer}
				/>

				<GridBody className={skeletonMode ? commonStyles.skeletonMode : undefined}>
					{assetGroups.map((group) => (
						<AssetGroupTableItem group={group} key={group.id} />
					))}
				</GridBody>
			</GridTable>

			{skeletonMode && (
				<div className={styles.noGroupContainer}>
					<Typo dataTest="group-no-rows" variant="D/Medium/Body">
						No Groups
					</Typo>
					<Typo color="secondary" variant="D/Regular/Body-S" className={styles.noGroupDescription}>
						You have not set up any Groups yet.
					</Typo>

					<Button
						data-test="group-create-button"
						className={styles.noGroupButton}
						color="primary"
						onClick={() => {
							const path = generatePath(PATHS.ASSET_GROUP_ITEM, { id: 'new' });

							history.push(path);
						}}
					>
						<Icon name="Add/Regular" size={20} className={styles.createIcon} /> New Group
					</Button>
				</div>
			)}
		</div>
	);
}

export default AssetGroupsList;
