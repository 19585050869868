import cn from 'classnames';
import { ReactNode } from 'react';
import Typo from 'components/typography/Typo';
import styles from './index.module.pcss';

interface BlockProps {
	className?: string;
	children: ReactNode;
}

interface ContainerProps {
	className?: string;
	header: ReactNode;
	description?: ReactNode;
}

function WidgetBlock({ className, children }: BlockProps) {
	return <div className={cn(className, styles.block)}>{children}</div>;
}

function WidgetHeader({ header, description, className }: ContainerProps) {
	return (
		<div className={cn(className, styles.widgetHeader)}>
			<Typo variant="D/Medium/H100-Header">{header}</Typo>

			{description && (
				<Typo variant="D/Regular/Body-S" color="secondary">
					{description}
				</Typo>
			)}
		</div>
	);
}

export { WidgetHeader, WidgetBlock };
export default WidgetBlock;
