import { useStore } from 'effector-react';
import { useEffect, useMemo, useState } from 'react';
import FilterLine from 'components/FilterLine';
import Button from 'components/form/Button';
import Select from 'components/form/Select';
import { DropdownButtonProps } from 'components/form/Select/DropdownButton';
import selectStyles from 'components/form/Select/index.module.css';
import Icon from 'components/Icon';
import NewGatewayButton from 'components/NewGatewayBlock/NewGatewayButton';
import NoContent from 'components/NoContent';
import { clearPiiFilter, piiFilterWithGroupsStore } from 'components/PiiGlobalFilterV2/model';
import ResetFilters from 'components/ResetFilters';
import EnhancedTableHead, { TOrder } from 'components/table/EnhancedTableHead';
import GridBody from 'components/table/GridBody';
import GridTable from 'components/table/GridTable';
import Typo from 'components/typography/Typo';
import { getComparator } from 'models/modelUtils/comparator';
import { CSVSampleColumn, CSVSampleTable, Sample } from 'models/sample/dto';
import { PageParamsConfig, usePageParams } from 'services/pageParams';
import { getColumns } from '../CSVViewer/csvParser';
import { SampleColumnItem } from '../CSVViewer/SampleColumnItem';
import { tableConfig } from '../CSVViewer/tableConfig';
import styles from './index.module.css';

const NOOP = () => {};

const pageConfig = {
	sort: {
		type: 'sort',
		persistence: 'session',
	},
	'data-types': {
		type: 'numberArray',
		persistence: 'session', // TODO implement 'global' in actuality
	},
	nonempty: {
		type: 'boolean',
		persistence: 'session', // TODO implement 'global' in actuality
	},
} satisfies PageParamsConfig;

type Props = Pick<Sample, 'samples'>;

function DropdownButton(props: DropdownButtonProps) {
	const { onClick, open, children } = props;

	return (
		<div className={styles.dropdownButtonWrapper}>
			<Button
				color="secondary"
				onClick={onClick}
				data-test="spreadsheet-page-select"
				size="extraSmall"
				className={styles.spreadsheetSelectButton}
			>
				<span>{children}</span>
				<span className={open ? selectStyles.activeIcon : selectStyles.icon}>
					<Icon
						name="ChevronDown/Regular"
						size={20}
						className={styles.spreadsheetSelectButtonIcon}
					/>
				</span>
			</Button>
		</div>
	);
}

function XLSViewer({ samples }: Props) {
	const [pageParams, setPageParams] = usePageParams(pageConfig, 'TableViewSample');
	const piiFilterWithGroups = useStore(piiFilterWithGroupsStore);
	const [spreadsheetPage, setSpreadsheetPage] = useState(samples[0].name);
	const pages = useMemo(() => samples.map(({ name }) => name), [samples]);

	const { sample, data_fields } = spreadsheetPage
		? samples.find(({ name }) => name === spreadsheetPage)!
		: samples[0];

	useEffect(() => {
		setPageParams({
			...pageParams,
			'data-types': piiFilterWithGroups.dataTypes,
			nonempty: piiFilterWithGroups.nonEmpty,
		});
	}, [piiFilterWithGroups]);

	const sortedAndFiltered = useMemo(() => {
		let result = sample ? [...getColumns(sample, data_fields)] : [];

		if (pageParams.nonempty) {
			result = result.filter((column) => column.data_fields.length > 0);
		}

		if (pageParams['data-types'].length > 0) {
			result = result.filter((column) =>
				column.data_fields.some(({ data_type }) => pageParams['data-types'].includes(data_type))
			);
		}
		const orderBy = pageParams.sort.value as keyof CSVSampleColumn;
		const order = pageParams.sort.operator;

		return result.sort(getComparator(orderBy, order));
	}, [pageParams, sample]);

	function onSortUpdate(property: keyof CSVSampleTable) {
		const { sort } = pageParams;

		const operator: TOrder = sort.value === property && sort.operator === 'asc' ? 'desc' : 'asc';
		const newParams = { sort: { operator, value: property } };

		setPageParams({ ...pageParams, ...newParams });
	}

	function onResetFilters() {
		clearPiiFilter();
	}

	const hasFilter = pageParams['data-types'].length > 0 || pageParams.nonempty;

	return (
		<div className={styles.wrapper}>
			<div className={styles.filtersWithPage}>
				<Select
					hasSearch
					options={pages}
					value={spreadsheetPage}
					onChange={(value) => setSpreadsheetPage(value)}
					label={{ primary: 'Sheet', secondary: spreadsheetPage }}
					render={{ dropdownButton: DropdownButton }}
				/>
				<FilterLine
					config={['dataTypes']}
					values={{ dataTypes: pageParams['data-types'] }}
					onChange={NOOP}
				/>
			</div>

			<GridTable dataTest="table-view-sample-table" className={styles.table}>
				<EnhancedTableHead
					config={tableConfig}
					order={pageParams.sort.operator}
					orderBy={pageParams.sort.value}
					onRequestSort={onSortUpdate}
					rowClassname={styles.rowContainer}
				/>

				<GridBody data-test="table-view-sample-list">
					<>
						{sortedAndFiltered.length ? (
							sortedAndFiltered.map((column) => (
								<SampleColumnItem column={column} key={column.column_index} />
							))
						) : hasFilter ? (
							<ResetFilters onReset={onResetFilters} />
						) : (
							<NoContent type={'csvSample'} className={styles.rowContainer}>
								<div className={styles.emptyContainer}>
									<Typo variant="D/Regular/Body-S" color="secondary">
										No available analytics or no Data-at-rest Sensors set up yet.
									</Typo>

									<NewGatewayButton isDAR />
								</div>
							</NoContent>
						)}
					</>
				</GridBody>
			</GridTable>
		</div>
	);
}

export { XLSViewer };
