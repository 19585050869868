import { del, get, post } from 'api/Api';
import checkResponse from 'services/api/checkResponse';
import { AssetGroup, AssetGroupCounters, AssetGroupList } from './dto';
import { assetGroupCountersExample, assetGroupExample, assetGroupListExample } from './examples';

async function getAssetGroups() {
	return get<AssetGroupList>('v2/groups').then(checkResponse(assetGroupListExample));
}

function getAssetGroup(id: AssetGroup['id']) {
	return get<AssetGroup>(`v2/groups/${id}`).then(checkResponse(assetGroupExample));
}

async function getAssetGroupCounters(id: AssetGroup['id'], onlyWithPii?: boolean) {
	const params = onlyWithPii ? { only_with_pii: true } : undefined;

	return get<AssetGroupCounters>(`v2/groups/${id}/counters`, params).then(
		checkResponse(assetGroupCountersExample)
	);
}

async function createAssetGroup(payload: AssetGroup) {
	return post<AssetGroup>('v2/groups', payload).then(checkResponse(assetGroupExample));
}

async function updateAssetGroup({ id, ...payload }: AssetGroup) {
	return post<AssetGroup>(`v2/groups/${id}`, payload).then(checkResponse(assetGroupExample));
}

function deleteAssetGroup(id: AssetGroup['id']) {
	return del(`v2/groups/${id}`).then(() => id);
}

export {
	getAssetGroups,
	getAssetGroup,
	createAssetGroup,
	updateAssetGroup,
	deleteAssetGroup,
	getAssetGroupCounters,
};
