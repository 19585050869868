// Simple packing function for cases when we have 1 rag and 1 pseudorag, and want to
// put pseudorag on top-right of rag. E.g. clusters with their respective external assets.

type Rect = {
	x: number;
	y: number;
	width: number;
	height: number;
};

function packRectanglesTopRight<R extends Rect>(rectangles: R[]) {
	switch (rectangles.length) {
		case 2:
			rectangles[1].x = 0;
			rectangles[1].y = rectangles[0].height;
			break;

		case 1:
			break;

		default:
			console.error('Unexpected amount of rectangles in packRectanglesTopRight', rectangles.length);
	}

	return rectangles;
}

export default packRectanglesTopRight;
