import { Dimensions } from './addDimensionPropertyToTree';
import { ELEMENT_SIZE, getNodeConstants } from './businessConsts';
import { isPseudoRag, Rag, PseudoRag } from './listToTreeByAttributes';
import packRectangles from './packRectangles';
import packRectanglesTopRight from './packRectanglesTopRight';

function arrangeCubesInPseudoRag<T, U extends Dimensions>(pseudoRag: PseudoRag<T, U>) {
	const width = Math.ceil(Math.sqrt(pseudoRag.data.length));
	let height = width;

	// There are cases like 'length == 2' when height can be smaller than width, and
	// still all elements would fit.
	// Checking `- 1` option is enough, `- 2` will always fail due to arithmetics.
	if (pseudoRag.data.length <= width * (width - 1)) {
		height = width - 1;
	}

	pseudoRag.dimensions = {
		...pseudoRag.dimensions,
		size: pseudoRag.data.length, // relative size, used for sorting by parent
		width: width * ELEMENT_SIZE,
		height: height * ELEMENT_SIZE,
	};

	// Arrange cubes in right-to-left, bottom-to-top direction.
	for (const i in pseudoRag.data) {
		const cube = pseudoRag.data[i];
		const cubePositionX = width - (Number(i) % width) - 1;
		const cubePositionY = height - Math.floor(Number(i) / width) - 1;

		cube.dimensions.x = cubePositionX * ELEMENT_SIZE;
		cube.dimensions.y = cubePositionY * ELEMENT_SIZE;
	}
}

function traverse<T, U, V extends Dimensions>(
	branch: Rag<T, U, V>,
	specialLevels: U[],
	platformLevel: string,
	platformContentLevel: string
) {
	if (isPseudoRag(branch)) {
		arrangeCubesInPseudoRag(branch);
		return;
	}

	// only for ts check. branch might be Leaf and have no 'children'
	if (!('children' in branch)) {
		return;
	}

	const nodes = branch.children;

	for (const node of nodes) {
		traverse(node, specialLevels, platformLevel, platformContentLevel);
	}

	// At this point all child nodes have width, height and size.
	// Now we can start calculating x and y offsets.

	const {
		GAP_BETWEEN_NODES,
		BRANCH_TOP_PADDING,
		BRANCH_RIGHT_PADDING,
		BRANCH_BOTTOM_PADDING,
		BRANCH_LEFT_PADDING,
	} = getNodeConstants(branch.level === platformLevel, branch.level === platformContentLevel);

	// Map children rags to rectangle object.
	// Virtually increase child dimensions, to account for gap between them (decreased later in code).
	let rectangles = nodes
		.map((node) => {
			const { width } = node.dimensions;
			return {
				...node.dimensions,
				originalId: node.id,
				width: width + GAP_BETWEEN_NODES,
				height: node.dimensions.height + GAP_BETWEEN_NODES,
			};
		})
		.sort((a, b) => b.size - a.size);

	// The main work is done here
	rectangles = specialLevels.includes(branch.level)
		? packRectanglesTopRight(rectangles)
		: packRectangles(rectangles);

	// At this point we have a structure with some packing of child nodes. Now can add
	// paddings, rotate, assign values etc.

	// Calculate inner width/height of parent, according to children dimensions.
	let branchWidth = 0;
	let branchHeight = 0;
	for (const rect of rectangles) {
		branchWidth = Math.max(branchWidth, rect.x + rect.width);
		branchHeight = Math.max(branchHeight, rect.y + rect.height);
	}

	// Rotate arrangement of children, so that biggest ones are bottom right (not top left).
	/*
		  ┌───┬──┐       ┌──┬───┐
		  │   │  │       ├──┤   │
		  │   │  │ ────► │  │   │
		  │   ├──┤       │  │   │
		  └───┴──┘       └──┴───┘
	*/
	for (const rect of rectangles) {
		rect.x = branchWidth - rect.x - rect.width;
		rect.y = branchHeight - rect.y - rect.height;
	}

	// Decrease child dimensions back, to make gaps betweeen them appear. Remove that gap from branch dimensions, too.
	branchWidth = branchWidth - GAP_BETWEEN_NODES;
	branchHeight = branchHeight - GAP_BETWEEN_NODES;
	for (const rect of rectangles) {
		rect.width = rect.width - GAP_BETWEEN_NODES;
		rect.height = rect.height - GAP_BETWEEN_NODES;
	}

	// Add paddings to branch.
	branchWidth = branchWidth + BRANCH_LEFT_PADDING + BRANCH_RIGHT_PADDING;
	branchHeight = branchHeight + BRANCH_TOP_PADDING + BRANCH_BOTTOM_PADDING;
	for (const rect of rectangles) {
		rect.x = rect.x + BRANCH_LEFT_PADDING;
		rect.y = rect.y + BRANCH_TOP_PADDING;
	}

	// Set branch dimensions.
	branch.dimensions = {
		...branch.dimensions,
		size: branchWidth * branchHeight, // relative size, used for sorting by parent 'traverse()'
		width: branchWidth,
		height: branchHeight,
		x: 0, // Will be rewritten by parent 'traverse()', or remain zero for top-level branch
		y: 0, // Will be rewritten by parent 'traverse()', or remain zero for top-level branch
	};

	// Map calculated rectangle dimensions back to children rags.
	nodes.forEach((node) => {
		const calculatedRectangle = rectangles.find((rect) => rect.originalId === node.id); // Is always present
		node.dimensions.x = calculatedRectangle!.x;
		node.dimensions.y = calculatedRectangle!.y;
	});
}

export default traverse;
