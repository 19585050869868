import { useMemo } from 'react';
import { generatePath } from 'react-router';
import { useHistory } from 'react-router-dom';
import { PiiTypeList } from 'components/PiiType';
import SensitivityChip from 'components/SensitivityChip';
import GridCell from 'components/table/GridCell';
import GridRow from 'components/table/GridRow';
import Tooltip from 'components/Tooltip';
import { KafkaMessageSchemaListItem } from 'models/kafkaMessageSchemas/dto';
import { KafkaTopicTableItem } from 'models/kafkaTopics/dto';
import { fileTypeLabels } from 'models/s3BucketCluster/dto';
import { lastSeen } from 'services/lastSeen';
import { PATHS } from 'services/router';
import styles from './index.module.css';

type Props = {
	topicId: KafkaTopicTableItem['id'];
	schema: KafkaMessageSchemaListItem;
};

function SchemaRow({ topicId, schema }: Props) {
	const { id, name, type, sensitivity, last_checked, data_types } = schema;

	const lastCheckedValue = useMemo(
		() => lastSeen({ timestamp: last_checked, short: true }),
		[last_checked]
	);

	const history = useHistory();
	function clickHandler() {
		history.push(generatePath(PATHS.KAFKA_MESSAGE_SCHEMA_ITEM, { topicId, schemaId: id }));
	}

	const typeLabel = fileTypeLabels[type] || fileTypeLabels['unknown'];

	return (
		<GridRow
			className={styles.rowContainer}
			border
			hover
			onClick={clickHandler}
			data-test="kafka-schema-table-row"
		>
			<GridCell dataTest="kafka-schema-table-item-name">{name}</GridCell>
			<GridCell dataTest="kafka-schema-table-item-type">{typeLabel}</GridCell>
			<GridCell dataTest="kafka-schema-table-item-sensitivity">
				<SensitivityChip sensitivity={sensitivity} />
			</GridCell>
			<GridCell dataTest="kafka-schema-table-item-data-types">
				<PiiTypeList data={data_types} />
			</GridCell>
			<GridCell dataTest="kafka-schema-table-item-last-checked">
				<Tooltip
					title={lastCheckedValue.date}
					data-test="kafka-schema-table-item-last-checked-value"
				>
					{lastCheckedValue.diff}
				</Tooltip>
			</GridCell>
		</GridRow>
	);
}

export { SchemaRow };
