import { useMemo } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import Icon from 'components/Icon';
import GridCell from 'components/table/GridCell';
import GridRow from 'components/table/GridRow';
import Tooltip from 'components/Tooltip';
import Typo from 'components/typography/Typo';
import { S3BucketDataFlowsTableItem } from 'models/s3Buckets/dto';
import { lastSeen } from 'services/lastSeen';
import { PATHS } from 'services/router';
import { DASH_SIGN } from 'services/strings';
import { getAssetNameByType } from 'views/common/AssetNameWithIcon';
import styles from '../index.module.css';

type Props = {
	dataFlow: S3BucketDataFlowsTableItem;
};

function TableRow({ dataFlow }: Props) {
	const history = useHistory();
	const {
		name,
		type,
		k8s_types,
		last_seen,
		namespace,
		interacts_with,
		is_encrypted,
		is_mesh_network,
	} = dataFlow;

	const lastSeenValue = useMemo(() => lastSeen({ timestamp: last_seen, short: true }), [last_seen]);

	const displayName = getAssetNameByType(type, name);
	const k8sLabel = k8s_types?.join(', ');

	function onRowClick() {
		const path = generatePath(PATHS.SERVICE_ITEM, {
			id: interacts_with,
			tab: 'info',
		});
		history.push(path);
	}

	return (
		<GridRow
			className={styles.rowContainer}
			border
			data-test="data-flow-table-row"
			hover
			onClick={onRowClick}
		>
			<GridCell withoutBlur className={styles.icons}>
				{is_encrypted && <Icon name="tlsRegular" dataTest="tls-icon" />}
				{is_mesh_network && <Icon name="MeshNetwork/Regular" dataTest="mesh-network-icon" />}
			</GridCell>

			<GridCell verticalAlign="middle">
				{displayName}
				<Typo variant="D/Medium/Meta" color="secondary">
					{k8sLabel}
				</Typo>
			</GridCell>

			<GridCell verticalAlign="middle">{namespace || DASH_SIGN}</GridCell>

			<GridCell verticalAlign="middle">
				<Tooltip title={lastSeenValue.date}>{lastSeenValue.diff}</Tooltip>
			</GridCell>
		</GridRow>
	);
}

export { TableRow };
