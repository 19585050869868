import cn from 'classnames';
import { MouseEvent, ReactNode, useMemo } from 'react';
import { TSensitivity } from 'models/common/dto';
import { stylesConfig } from '../model/stylesConfig';
import styles from './index.module.css';
import { ragTitleSize } from './utils/ragTitleSize';

/*
	RagsLayer sets frame of reference with 'position: relative'.

   Besides Props, this component is governed by classes set on <RagsLayer />:
	- .scaleStep50 and .scaleStep100
	- .showSensitivity
*/
type Props = {
	text: string;
	textOnCenter?: boolean;
	icon?: ReactNode;
	theme: 'sunset' | 'ice' | 'light-ice' | 'rock' | 'transparent'; // Main color, when sensitivity is not shown.
	sensitivity?: TSensitivity;
	selected: boolean;
	searched: boolean;
	faded: boolean;
	onClick?: (e: MouseEvent) => void; // if has onClick, then 'hoverable' behaviour is also toggled
	scaleStep: number;
	position: {
		top: number;
		left: number;
		width: number;
		height: number;
	};
};

function UniversalRag(props: Props) {
	const {
		text,
		textOnCenter,
		icon,
		theme,
		sensitivity,
		selected,
		searched,
		faded,
		onClick,
		scaleStep,
		position,
	} = props;

	const secondary = !sensitivity
		? 'none'
		: sensitivity === 'High'
		? 'red'
		: sensitivity === 'Medium'
		? 'yellow'
		: 'gray';

	const style = useMemo(() => {
		return { ...position, ...stylesConfig[scaleStep].ragName };
	}, [position.top, position.left, position.width, position.height, scaleStep]);

	const size = useMemo(
		() => ragTitleSize(position.width, position.height),
		[position.width, position.height]
	);

	const borders = useMemo(() => {
		switch (true) {
			case selected && searched:
				return [
					styles.outerBorder,
					styles.outerBorderSearched,
					styles.innerBorder,
					styles.innerBorderSelected,
				];

			case selected:
				return [styles.outerBorder, styles.outerBorderSelected];
			case searched:
				return [styles.outerBorder, styles.outerBorderSearched];

			default:
				return [];
		}
	}, [selected, searched]);

	return (
		<div
			style={style}
			className={cn(
				styles.uniRag,
				styles[`theme-${theme}`],
				styles[`secondary-${secondary}`],
				textOnCenter && styles.textOnCenter,
				faded && styles.faded,
				!!onClick && styles.hoverable,
				...borders
			)}
			onClick={onClick}
			data-test="data-map-rag"
		>
			<div className={cn(styles.textbox, styles[`textbox${size}`])}>
				{icon}
				{text}
			</div>
		</div>
	);
}

export default UniversalRag;
