import { EventJson } from 'models/eventsV2/dto';
import {
	assetEventJsonExample,
	anotherAssetEventJsonExample,
	dataTypeEventJsonExample,
} from 'models/eventsV2/examples';

const emptyData: EventJson[] = [
	assetEventJsonExample,
	anotherAssetEventJsonExample,
	dataTypeEventJsonExample,
	{ ...assetEventJsonExample, id: assetEventJsonExample.id * 10 },
	{ ...dataTypeEventJsonExample, id: dataTypeEventJsonExample.id * 10 },
];

export default emptyData;
