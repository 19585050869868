import cn from 'classnames';
import { useHistory } from 'react-router-dom';
import Icon from 'components/Icon';
import LoadMoreButton from 'components/LoadMoreButton';
import { PiiTypeList } from 'components/PiiType';
import GridBody from 'components/table/GridBody';
import GridCell from 'components/table/GridCell';
import GridHead from 'components/table/GridHead';
import GridRow from 'components/table/GridRow';
import GridTable from 'components/table/GridTable';
import Typo from 'components/typography/Typo';
import { AssetJson } from 'models/assets/dto';
import {
	DataFlowDetailsTable,
	DataFlowDetailsTableItem,
} from 'models/assetsGroupsDataFlowDetails/dto';
import { methodOrProtocol } from 'models/endpointsV2/dto';
import { PATHS } from 'services/router';
import DataTest from './index.dataTest';
import styles from './index.module.css';

type Props = {
	streams: DataFlowDetailsTable['streams'];
	activeDataTypes: DataFlowDetailsTable['data_types'];
	loadMoreData: () => void;
	moreData: boolean;
	moreDataLoading: boolean;
};

export const DataFlowDetailsList = ({
	streams,
	activeDataTypes,
	loadMoreData,
	moreData,
	moreDataLoading,
}: Props) => {
	const history = useHistory();

	return (
		<div className={styles.container}>
			{streams.length === 0 ? (
				<Typo variant="D/Regular/Body-S" color="secondary">
					No results matching these filters.
				</Typo>
			) : (
				<GridTable dataTest={DataTest['data-flow-details-table']}>
					<GridHead>
						<GridRow head className={styles.row}>
							<GridCell head>{''}</GridCell>
							<GridCell head>Group 1 Service</GridCell>
							<GridCell head>Group 2 Service</GridCell>
							<GridCell head>Direction</GridCell>
							<GridCell head>Data Types</GridCell>
						</GridRow>
					</GridHead>

					<GridBody className={styles.tableBody}>
						{streams.map((stream, index) => {
							function onRowClick() {
								history.push(
									`${PATHS.SAMPLE_V2}?id=${stream.endpoint_id}&direction=${stream.direction}`
								);
							}

							return (
								<GridRow
									key={index}
									className={styles.row}
									onClick={onRowClick}
									hover
									border
									data-test={DataTest['data-flow-details-row']}
								>
									<GridCell withoutBlur className={styles.icons}>
										{stream.is_encrypted && <Icon name="tlsRegular" dataTest="tls-icon" />}
										{stream.is_mesh_network && (
											<Icon name="MeshNetwork/Regular" dataTest="mesh-network-icon" />
										)}
									</GridCell>

									<GridCell className={cn(styles.cell, styles.gapCell)}>
										{getAssetDescription(stream.src_asset, stream)}
									</GridCell>

									<GridCell className={cn(styles.cell, styles.gapCell)}>
										{getAssetDescription(stream.dst_asset, stream)}
									</GridCell>

									<GridCell className={cn(styles.cell, styles.gapCell, styles.direction)}>
										{stream.direction}
									</GridCell>

									<GridCell className={cn(styles.cell, styles.gapCell)}>
										<PiiTypeList data={stream.data_types} filter={activeDataTypes} />
									</GridCell>
								</GridRow>
							);
						})}

						<LoadMoreButton show={moreData} loading={moreDataLoading} request={loadMoreData} />
					</GridBody>
				</GridTable>
			)}
		</div>
	);
};

function getAssetDescription(assetId: AssetJson['id'], stream: DataFlowDetailsTableItem) {
	const asset =
		assetId === stream.src_asset_data.id ? stream.src_asset_data : stream.dst_asset_data;

	const showDetails =
		(assetId === stream.dst_asset_data.id && stream.direction === 'request') ||
		(assetId === stream.src_asset_data.id && stream.direction === 'response');

	const k8sLabel = asset.k8s_types?.join(', ');
	const dividerDot = k8sLabel && asset.namespace ? ' • ' : '';

	return (
		<>
			<Typo variant="D/Regular/Body-S" className={styles.overflow}>
				{asset.name}
			</Typo>

			<div className={styles.flex}>
				<Typo variant="D/Regular/Meta" color="secondary">
					{k8sLabel}
				</Typo>
				{asset.namespace && (
					<Typo variant="D/Regular/Meta" color="secondary" className={styles.flex}>
						{dividerDot}
						{asset.namespace}
					</Typo>
				)}
			</div>

			{showDetails && (
				<>
					{stream.url}
					<Typo variant="D/Regular/Meta" color="secondary">
						{methodOrProtocol(stream)}
					</Typo>
				</>
			)}
		</>
	);
}
