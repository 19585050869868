import { CUSTOM_SCALE } from '../../index';

type ClusteringLevel = 'platform' | 'cluster' | 'namespace' | 'cube' | 'label';

const levelNumber: { [key in ClusteringLevel]: number } = {
	platform: 1,
	cluster: 2,
	namespace: 3,
	cube: 4,
	label: 5,
};

const clusteringLevelNumber = (scale: number): number => {
	return scale <= 0.3 / CUSTOM_SCALE
		? levelNumber['platform']
		: scale <= 0.5 / CUSTOM_SCALE
		? levelNumber['cluster']
		: scale <= 0.7 / CUSTOM_SCALE
		? levelNumber['namespace']
		: scale <= 1 / CUSTOM_SCALE
		? levelNumber['cube']
		: levelNumber['label'];
};

export { clusteringLevelNumber, levelNumber };
