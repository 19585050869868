import { get, post } from 'api/Api';
import checkResponse from 'services/api/checkResponse';
import { TSSOSettings } from './dto';
import { oidcSettingsExample, adfsSettingsExample } from './examples';

async function getSSOSetting() {
	return get<TSSOSettings>('v1/sso/settings').then(
		checkResponse(oidcSettingsExample, adfsSettingsExample)
	);
}

async function updateSSOSettings(payload: TSSOSettings) {
	return post<TSSOSettings>('v1/sso/settings', payload).then(
		checkResponse(oidcSettingsExample, adfsSettingsExample)
	);
}

export { getSSOSetting, updateSSOSettings };
