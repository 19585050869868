import { useStore } from 'effector-react';
import { useState } from 'react';
import { Tab, TabPanel, Tabs } from 'components/Tabs';
import Typo from 'components/typography/Typo';
import { kafkaInstancesList } from 'models/kafkaInstances/store';
import { KafkaTopicItem } from 'models/kafkaTopics/dto';
import CloseButton from '../CloseButton';
import commonStyles from '../index.module.css';
import Summary from './Summary';
import Topics from './Topics';

type Props = {
	kafkaInstanceId: KafkaTopicItem['id'];
};

function KafkaDetails({ kafkaInstanceId }: Props) {
	const [tab, setTab] = useState<'summary' | 'topics'>('summary');
	const kafkaList = useStore(kafkaInstancesList);
	const kafkaInstance = kafkaList.find((k) => k.id === kafkaInstanceId);

	if (!kafkaInstance) return null;

	return (
		<div className={commonStyles.container} data-test="data-map-details-kafka">
			<div className={commonStyles.sidePaddings}>
				<div className={commonStyles.header}>
					<Typo variant="D/Medium/H100-Header">{kafkaInstance.name}</Typo>

					<CloseButton />
				</div>

				<Tabs value={tab} onChange={setTab}>
					<Tab value="summary" label="Summary" />
					<Tab value="topics" label="Topics" />
				</Tabs>
			</div>

			<TabPanel value={tab} index="summary" className={commonStyles.tabPanel}>
				<Summary kafkaInstance={kafkaInstance} />
			</TabPanel>

			<TabPanel value={tab} index="topics" className={commonStyles.tabPanel}>
				<Topics kafkaInstanceId={kafkaInstanceId} />
			</TabPanel>
		</div>
	);
}

export default KafkaDetails;
