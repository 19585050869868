import { createStore } from 'effector';
import { S3RegionList } from './dto';
import { getS3RegionsFx } from './effects';

const s3RegionsStore = createStore<S3RegionList>({ regions: [] });

s3RegionsStore.on(getS3RegionsFx.doneData, (state: S3RegionList, data: S3RegionList) => data);

export const s3RegionsList = s3RegionsStore.map(({ regions }) =>
	regions.sort((a, b) => a.keyword.localeCompare(b.keyword))
);

export const s3RegionsUsedList = s3RegionsList.map((regions) =>
	regions.filter(({ is_used }) => is_used)
);

export const s3RegionsByKeyword = s3RegionsStore.map(({ regions }) => {
	return Object.fromEntries(regions.map((region) => [region.keyword, region]));
});
