import { createStore } from 'effector';
import { PolicyItem, PolicyList } from './dto';
import {
	createPolicyFx,
	deletePolicyFx,
	generatePoliciesFx,
	getPolicyItemFx,
	getPolicyListFx,
	togglePolicyTrackingFx,
	updatePolicyFx,
} from './effects';

export const policyStore = createStore<PolicyList>({
	policies: [],
	total: 0,
});

policyStore.on(
	[getPolicyListFx.doneData, generatePoliciesFx.doneData],
	(state: PolicyList, data: PolicyList) => data
);

policyStore.on(getPolicyItemFx.doneData, (state: PolicyList, data: PolicyItem) => {
	const newGroups = state.policies.map((policy) => (policy.id === data.id ? data : policy));

	return { ...state, policies: newGroups, total: newGroups.length };
});

policyStore.on(createPolicyFx.doneData, (state: PolicyList, data: PolicyItem) => {
	const newGroups = state.policies.concat(data);

	return { ...state, policies: newGroups, total: newGroups.length };
});

policyStore.on(updatePolicyFx.doneData, (state: PolicyList, data: PolicyItem) => {
	const newGroups = state.policies.map((policy) => (policy.id === data.id ? data : policy));

	return { ...state, policies: newGroups, total: newGroups.length };
});

policyStore.on(deletePolicyFx.doneData, (state: PolicyList, id: PolicyItem['id']) => {
	const newGroups = state.policies.filter((policy) => policy.id !== id);

	return { ...state, policies: newGroups, total: newGroups.length };
});

policyStore.on(
	togglePolicyTrackingFx.doneData,
	(state: PolicyList, data: { id: PolicyItem['id']; is_active: PolicyItem['is_active'] }) => {
		const newPolicies = state.policies.map((policy) =>
			policy.id === data.id ? { ...policy, is_active: data.is_active } : policy
		);

		return { ...state, policies: newPolicies };
	}
);

export const policiesList = policyStore.map(({ policies }) =>
	policies.sort((a, b) => a.name.localeCompare(b.name))
);

export const policiesLoaded = policyStore.map((policies, oldPolicies) => {
	return oldPolicies !== undefined;
}); // Magical
