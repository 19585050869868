import cn from 'classnames';
import Loader from 'components/Loader';
import NewGatewayButton from 'components/NewGatewayBlock/NewGatewayButton';
import Typo from 'components/typography/Typo';
import styles from './index.module.css';

function LoadingDataMap() {
	return (
		<>
			<Loader size={36} />

			<Typo variant="D/Regular/Body" color="secondary" className={styles.text}>
				Calculating map layout...
			</Typo>
		</>
	);
}

function EmptyDataMap() {
	return (
		<>
			<div className={styles.emoji}>😕</div>

			<Typo variant="D/Regular/Body" color="secondary" className={styles.text}>
				No available analytics or no Agents set up yet.
			</Typo>

			<NewGatewayButton />
		</>
	);
}

const components = {
	loading: LoadingDataMap,
	noData: EmptyDataMap,
};

type Props = {
	loadingState: 'loading' | 'noData';
};

function DataMapPlaceholder(props: Props) {
	const { loadingState } = props;

	const Content = components[loadingState];
	const loading = loadingState === 'loading';

	return (
		<div className={styles.emptyMap} data-test="data-map-placeholder">
			<div className={cn({ [styles.backgroundImg]: !loading }, styles.background)} />

			<div className={cn({ [styles.whiteBackground]: !loading }, styles.content)}>
				<Content />
			</div>
		</div>
	);
}

export default DataMapPlaceholder;
