import Typo from 'components/typography/Typo';
import Header from 'layouts/AuthorizedWithLeftMenu/Header';
import ExternalAPI from './ExternalAPI';
import styles from './index.module.pcss';
import Jira from './Jira';
import Slack from './Slack';
import Webhook from './Webhook';

function Integrations() {
	return (
		<>
			<Header breadcrumbProps={{ finalStep: 'Integrations' }} />

			<Typo variant="D/Medium/H100-Header">Available from Soveren</Typo>

			<div className={styles.container}>
				<Slack />

				<Jira />

				<Webhook />

				<ExternalAPI />
			</div>
		</>
	);
}

export default Integrations;
