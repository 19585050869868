import cn from 'classnames';
import { useStore } from 'effector-react';
import { MouseEvent, useState } from 'react';
import Icon from 'components/Icon';
import Menu from 'components/Menu';
import MenuItem from 'components/MenuItem';
import Tooltip from 'components/Tooltip';
import { RouterLink } from 'components/typography/Link';
import Typo from 'components/typography/Typo';
import { signOutFx } from 'models/session/effects';
import { clientStore } from 'models/session/store';
import { PATHS } from 'services/router';
import styles from '../index.module.pcss';
import sideMeuStyles from '../SideMenu/index.module.pcss';

function UserMenu() {
	const client = useStore(clientStore);

	function logOut() {
		signOutFx();
	}

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [showTooltip, setShowTooltip] = useState(false);

	const handleOpen = (event: MouseEvent<HTMLButtonElement>) => {
		setShowTooltip(false);
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<>
			<Tooltip
				title={`Account: ${client?.email}`}
				placement="left"
				data-test="side-menu-item-icon-tooltip"
				open={showTooltip}
			>
				<Typo
					variant="D/Medium/Body-S"
					className={cn({ [sideMeuStyles.activeMenuItem]: anchorEl }, sideMeuStyles.menuItem)}
					onClick={handleOpen}
					data-test="user-menu-trigger"
					onMouseEnter={() => setShowTooltip(true)}
					onMouseLeave={() => setShowTooltip(false)}
				>
					<Icon
						className={sideMeuStyles.icon}
						name={anchorEl ? 'Person/Filled' : 'Person/Regular'}
						size={20}
					/>

					<span className={sideMeuStyles.label}>{client?.email}</span>
				</Typo>
			</Tooltip>

			<Menu
				className={styles.menuContainer}
				anchorEl={anchorEl}
				open={!!anchorEl}
				onClose={handleClose}
				transformOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				data-test="user-menu"
			>
				<RouterLink to={PATHS.CHANGE_PASSWORD} data-test="user-menu-item-change-password">
					<MenuItem size="small" onClick={handleClose}>
						Change password
					</MenuItem>
				</RouterLink>

				<MenuItem size="small" onClick={logOut} data-test="user-menu-item-log-out">
					Log out
				</MenuItem>
			</Menu>
		</>
	);
}

export default UserMenu;
