import { AssetType, assetTypeLabel } from './dto';

function getAssetNameByType(type: AssetType, name: string) {
	if (type === 'external_namespace' || type === 'internal' || type === 'custom') {
		return name;
	}

	return assetTypeLabel[type];
}

export { getAssetNameByType };
