import lexer from 'json-lexer';

type TokenType = 'openObject' | 'closeObject' | 'openArray' | 'closeArray' | 'scalar' | 'other';

// We assume a single line of well-formed JSON, like `    "name": "John",` or `{`.
// Parser state is `pathParts`.
// Can be full JSON, too, just without line breaks.
function visitJsonValues(
	line: string,
	pathParts: unknown[],
	onValue: (source: string, column: number, pathParts: unknown[]) => void
) {
	const tokens = lexer(line);
	let cursor = 0;

	for (const token of tokens) {
		let tType: TokenType = 'other';

		if (token.type === 'string' || token.type === 'number' || token.type === 'literal') {
			tType = 'scalar';
		} else if (token.type === 'punctuator') {
			switch (token.value) {
				case '{':
					tType = 'openObject';
					break;
				case '}':
					tType = 'closeObject';
					break;
				case '[':
					tType = 'openArray';
					break;
				case ']':
					tType = 'closeArray';
					break;
			}
		}

		switch (tType) {
			case 'openObject':
				pathParts.push(null);
				break;

			case 'openArray':
				pathParts.push(0);
				break;

			case 'closeObject':
			case 'closeArray': {
				pathParts.pop();

				const lastPart = pathParts.at(-1);

				if (typeof lastPart === 'number') {
					pathParts[pathParts.length - 1] = lastPart + 1;
				} else if (typeof lastPart === 'string') {
					pathParts[pathParts.length - 1] = null;
				}
				break;
			}

			case 'scalar': {
				const lastPart = pathParts.at(-1);

				if (typeof lastPart === 'number') {
					onValue(token.raw, cursor, pathParts);
					pathParts[pathParts.length - 1] = lastPart + 1;
				} else if (typeof lastPart === 'string') {
					onValue(token.raw, cursor, pathParts);
					pathParts[pathParts.length - 1] = null;
				} else {
					pathParts[pathParts.length - 1] = token.value;
				}
				break;
			}
		}

		cursor += token.raw.length;
	}
}

export { visitJsonValues };
