import { useState, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import Alert, { AlertSeverity } from 'components/Alert';
import Button from 'components/form/Button';
import PasswordField from 'components/form/PasswordField';
import TextField from 'components/form/TextField';
import Link from 'components/typography/Link';
import Typo from 'components/typography/Typo';
import { AwsCustomer } from 'models/session/dto';
import { signUpAwsFx, signUpFx } from 'models/session/effects';
import { parseURLSearchString } from 'services/api';
import { APIError } from 'services/api/httpRequest';
import { PATHS } from 'services/router';
import styles from './index.module.pcss';

type FormValues = {
	email: string;
	password: string;
};

function SignUp() {
	// Client can be given link to sign-in page with email in query params, for convenience.
	const initialEmail = useMemo(
		() => new URLSearchParams(window.location.search).get('email') || '',
		[]
	);

	const awsAccount = useMemo(() => {
		const { customerAWSAccountId, customerIdentifier, productCode } = parseURLSearchString(
			window.location.search
		);

		return {
			customer_aws_account_id: customerAWSAccountId,
			customer_identifier: customerIdentifier,
			product_code: productCode,
		};
	}, [window.location.search]) as AwsCustomer;

	const isAwsAccount = useMemo(() => {
		return (
			!!awsAccount.customer_aws_account_id &&
			!!awsAccount.customer_identifier &&
			!!awsAccount.product_code
		);
	}, [awsAccount]);

	const { control, handleSubmit } = useForm<FormValues>({
		defaultValues: {
			email: initialEmail,
			password: '',
		},
		mode: 'onBlur', // "onChange"
	});

	const [isLoading, setLoading] = useState(false);
	const [serverError, setServerError] = useState<string | null>(null);

	async function onSubmit({ email, password }: FormValues) {
		setLoading(true);

		try {
			if (isAwsAccount) {
				await signUpAwsFx({ login: email, password, ...awsAccount });
			} else {
				await signUpFx({ login: email, password });
			}
		} catch (error) {
			if (
				error instanceof APIError &&
				(error.response.status === 422 ||
					error.response.status === 401 ||
					error.response.status === 403)
			) {
				const { message } = await error.response.json();
				setServerError(message);
			} else {
				throw error;
			}
		}

		setLoading(false);
	}

	return (
		<div className={styles.container} data-test="container-sign-up">
			<div className={styles.wrapper}>
				<Typo variant="D/Medium/H500-Greeting-Title" className={styles.title}>
					Welcome
				</Typo>

				<form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
					<TextField
						control={control}
						label="Work email"
						name="email"
						type="email"
						data-test="email"
						required
						fullWidth
						size="large"
					/>

					<PasswordField
						control={control}
						label="Password"
						name="password"
						autoComplete="current-password"
						data-test="password"
						required
						fullWidth
						size="large"
					/>
					{isAwsAccount && (
						<TextField
							readOnly
							label="AWS Account Id"
							value={awsAccount.customer_aws_account_id}
							name="email"
							type="text"
							data-test="customerAWSAccountId"
							placeholder=""
							required
							fullWidth
							size="large"
						/>
					)}

					<Button
						type="submit"
						size="large"
						loading={isLoading}
						className={styles.formButton}
						data-test="create-acc-bt"
					>
						Create account
					</Button>

					{serverError && !isLoading && (
						<Alert className={styles.errorMessage} severity={AlertSeverity.error}>
							{serverError}
						</Alert>
					)}
				</form>

				<Typo color="secondary" variant="D/Regular/Meta" className={styles.terms}>
					By creating an account, I agree to the{' '}
					<Link inherit target="_blank" className={styles.termsLink} to="/documents/terms.pdf">
						Terms of service.
					</Link>
				</Typo>

				<Typo variant="D/Medium/Body-S" className={styles.haveAccount}>
					Already have an account?{' '}
					{isAwsAccount ? (
						<Link data-test="connect-link" to={PATHS.CONNECT_AWS + window.location.search}>
							Connect AWS account
						</Link>
					) : (
						<Link data-test="sign-in-link" to={PATHS.SIGN_IN + window.location.search}>
							Sign in
						</Link>
					)}
					.
				</Typo>
			</div>
		</div>
	);
}

export default SignUp;
