import cn from 'classnames';
import { ReactNode } from 'react';
import Typo from 'components/typography/Typo';
import styles from './index.module.css';

interface LabelProps {
	children: ReactNode;
	className?: string;
	dataTest?: string;
	size?: 'M' | 'S' | 'XS';
}

function Label(props: LabelProps) {
	const { children, className, dataTest, size = 'S' } = props;

	return (
		<Typo
			component="label"
			variant={size === 'XS' ? 'D/Regular/Meta' : 'D/Regular/Body-S'}
			className={cn(styles.container, styles[size], className)}
			data-test={dataTest || 'label-container'}
			// This is necessary if the element inside the Label is in the clickable parent,
			// because stopPropagation isn't work with onChange event properly
			onClick={(e) => e.stopPropagation()}
		>
			{children}
		</Typo>
	);
}

export default Label;
