import { RecentActivityJson } from './dto';

const recentActivityExample: RecentActivityJson = {
	api_calls: {
		weekly: 1234,
		weekly_delta: 123,
		daily: [
			{ date: 1648080000000, value: 87834 },
			{ date: 1648166400000, value: 87838 },
			{ date: 1648252800000, value: 87839 },
			{ date: 1648339200000, value: 87840 },
			{ date: 1648425600000, value: 87840 },
			{ date: 1648512000000, value: 87836 },
			{ date: 1648598400000, value: 87840 },
		],
	},
	data_types: {
		weekly: 1234,
		weekly_delta: 123,
		daily: [
			{
				date: 0,
				value: 0,
			},
			{
				date: 1,
				value: 1,
			},
		],
	},
	data_fields: {
		weekly: 1234,
		weekly_delta: 123,
		daily: [
			{
				date: 0,
				value: 0,
			},
			{
				date: 1,
				value: 1,
			},
			{
				date: 2,
				value: 2,
			},
		],
	},
	hosts: {
		weekly: 1234,
		weekly_delta: 123,
		daily: [
			{
				date: 0,
				value: 0,
			},
			{
				date: 1,
				value: 1,
			},
			{
				date: 2,
				value: 2,
			},
			{
				date: 3,
				value: 3,
			},
		],
	},
	endpoints: {
		weekly: 1234,
		weekly_delta: 123,
		daily: [
			{
				date: 0,
				value: 0,
			},
			{
				date: 1,
				value: 1,
			},
			{
				date: 2,
				value: 2,
			},
			{
				date: 3,
				value: 3,
			},
			{
				date: 4,
				value: 4,
			},
		],
	},
	data_users: {
		weekly: 1234,
		weekly_delta: 123,
		daily: [
			{
				date: 0,
				value: 0,
			},
			{
				date: 1,
				value: 1,
			},
			{
				date: 2,
				value: 2,
			},
			{
				date: 3,
				value: 3,
			},
			{
				date: 4,
				value: 4,
			},
			{
				date: 5,
				value: 5,
			},
		],
	},
};

export { recentActivityExample };
