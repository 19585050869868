import { TOrder, TSensitivity } from 'models/common/dto';
import { DataTypeItem, DataTypeRequest } from 'models/dataTypes/dto';
import { Sample } from 'models/sample/dto';

type DatabaseInstanceType = 'postgreSQL' | 'oracle' | 'mysql' | 'mssql' | 'sqlite';
type DarObjectStatus =
	| ''
	| 'unsupported_format'
	| 'read_error'
	| 'general_failure'
	| 'permission_denied';

const DarObjectStatusLabel: { [key in DarObjectStatus]: string } = {
	'': '',
	unsupported_format: 'Unsupported format',
	read_error: 'Read error',
	general_failure: 'General failure',
	permission_denied: 'Permission denied',
};

type DatabaseInstanceBase = {
	id: number;
	name: string;
	type: DatabaseInstanceType;
	is_crawler_attached: boolean;
	region: string;
};

type DatabaseInstanceItem = DatabaseInstanceBase & {
	cluster_id: number;
	description: string;
	owner: string;
	created_at: number;
	last_checked: number;
	databases_count: number;
	size: number;
	has_tls: boolean;
	sensitivity: TSensitivity;
	data_types: DataTypeItem['id'][];
	status: DarObjectStatus;
};

type DatabaseInstanceList = {
	instances: DatabaseInstanceItem[];
	total: number;
};

type DatabaseItem = {
	id: number;
	name: string;
	tables_count: number;
	size: number;
	sensitivity: TSensitivity;
	data_types: DataTypeItem['id'][];
	instance_id: number;
	last_checked: number;
	status: DarObjectStatus;
};

type DatabaseList = {
	databases: DatabaseItem[];
	total: number;
	total_filtered?: number;
};

type DatabaseTableParams = {
	instance_ids: DatabaseInstanceItem['id'][];
	'data-types': DataTypeRequest;
	search: string;
	sort: {
		orderBy: keyof DatabaseItem;
		order: TOrder;
	};
};

type DatabaseDetails = DatabaseItem & {
	owner: string;
	description: string;
};

type DatabaseTableItem = {
	id: number;
	name: string;
	rows_count: number;
	size: number;
	sensitivity: TSensitivity;
	data_types: DataTypeItem['id'][];
	last_checked: number;
	status: DarObjectStatus;
};

type DatabaseTableList = {
	tables: DatabaseTableItem[];
	total: number;
	total_filtered?: number;
};

type DatabaseTablesTableParams = {
	'data-types': DataTypeRequest;
	search: string;
	sort: {
		orderBy: keyof DatabaseTableItem;
		order: TOrder;
	};
};

type DatabaseTableSample = Sample & {
	table_id: number;
	table_name: string;
	instance_id: number; // TODO: possibly redundant
	instance_name: string; // TODO: possibly redundant
	database_id: number;
	database_name: string;
	last_checked: number;
};

export type {
	DatabaseInstanceBase,
	DatabaseInstanceList,
	DatabaseInstanceItem,
	DatabaseItem,
	DatabaseList,
	DatabaseTableParams,
	DatabaseDetails,
	DatabaseTableItem,
	DatabaseTableList,
	DatabaseTablesTableParams,
	DatabaseTableSample,
	DatabaseInstanceType,
};
export { DarObjectStatusLabel };
