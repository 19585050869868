import dropSession from 'services/dropSession';
import { APIError } from './httpRequest';
import { TokenRefreshError } from './secureRequest';

function stopEvent(event: Event) {
	event.preventDefault();
	event.stopPropagation();
	event.stopImmediatePropagation();
}

window.addEventListener('unhandledrejection', (event) => {
	if (event.reason instanceof APIError) {
		const { response } = event.reason;

		if (response.status === 401) {
			dropSession();
			stopEvent(event);
		}
	}

	if (event.reason instanceof TokenRefreshError) {
		dropSession();
		stopEvent(event);
	}
});

export {};
