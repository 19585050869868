import { useCallback, useEffect } from 'react';
import { useImmer } from 'use-immer';
import Accordion from 'components/Accordion';
import AccordionDetails from 'components/AccordionDetails';
import AccordionSummary from 'components/AccordionSummary';
import Badge from 'components/Badge';
import Checkbox from 'components/Checkbox';
import Button from 'components/form/Button';
import PiiType from 'components/PiiType';
import Typo from 'components/typography/Typo';
import { PolicyRuleItem, PolicyRuleList } from 'models/policies/dto';
import { toLocaleString } from 'services/numbers';
import styles from './index.module.css';

export type RuleCurtainProps = {
	dataTypes: number[];
	namespaces: {
		title: string;
		assets: {
			id: string;
			title: string;
			checked?: boolean;
		}[];
	}[];
	onChange: (rules: PolicyRuleItem[][]) => void;
};

export const RuleCurtain = ({ dataTypes, namespaces, onChange }: RuleCurtainProps) => {
	const [formData, setFormData] = useImmer(namespaces);

	useEffect(() => {
		setFormData(namespaces);
	}, [namespaces]);

	const checkAsset = useCallback((namespaceIndex, assetIndex) => {
		setFormData((draft) => {
			draft[namespaceIndex].assets[assetIndex].checked =
				!draft[namespaceIndex].assets[assetIndex].checked;
		});
	}, []);

	const checkAllAssetsInNamespace = useCallback((namespaceIndex, isChecked) => {
		setFormData((draft) => {
			draft[namespaceIndex].assets.forEach((asset) => {
				asset.checked = !isChecked;
			});
		});
	}, []);

	const selectAll = useCallback(() => {
		setFormData((draft) => {
			const isAllSelected = draft.every(({ assets }) => assets.every(({ checked }) => checked));

			draft.forEach(({ assets }) => assets.forEach((asset) => (asset.checked = !isAllSelected)));
		});
	}, []);

	const addRulesToPolicy = useCallback(() => {
		const addedRules = formData.reduce((acc, namespace) => {
			const isAllAssetsSelected = namespace.assets.every(({ checked }) => checked);

			if (isAllAssetsSelected) {
				acc.push([
					{
						type: 'namespace',
						operator: 'is',
						key: '',
						values: [namespace.title],
					},
				]);
			} else {
				const assetValues = namespace.assets
					.filter(({ checked }) => checked)
					.map(({ title }) => title);

				if (assetValues.length > 0) {
					acc.push([
						{ type: 'namespace', operator: 'is', key: '', values: [namespace.title] },
						{
							type: 'asset',
							operator: 'is',
							key: '',
							values: assetValues,
						},
					]);
				}
			}

			return acc;
		}, [] as PolicyRuleList);

		onChange(addedRules);
	}, [formData]);

	return (
		<div className={styles.container}>
			<div className={styles.header}>
				<div className={styles.allowAccessTo}>
					<Typo variant="D/Medium/H100-Header">Allow access to</Typo>
					{dataTypes.map((dtId) => (
						<PiiType
							key={dtId}
							dataTest={'policy-curtain-data-type-selected'}
							className={styles.denySharingElem}
							type={dtId}
						/>
					))}
				</div>
				<div className={styles.selectAllContainer}>
					<Typo
						variant="D/Medium/Body-S"
						className={styles.selectAll}
						onClick={selectAll}
						data-test="policy-curtain-select-all"
					>
						Select all
					</Typo>
				</div>
			</div>
			<div className={styles.content}>
				{formData.map(({ title, assets }, namespaceIndex) => {
					const assetsCheckedAll = assets.length > 0 && assets.every(({ checked }) => checked);
					const assetsCheckedPartial = assets.length > 0 && assets.some(({ checked }) => checked);

					return (
						<Accordion
							key={namespaceIndex}
							isDivider={namespaceIndex !== 0}
							defaultExpanded
							classes={{
								root: namespaceIndex === formData.length - 1 ? styles.accordionLastBlock : '',
							}}
						>
							<AccordionSummary classes={{ content: styles.accordionTitle }}>
								<Checkbox
									dataTest="policy-curtain-namespace-checkbox"
									checked={assetsCheckedAll || assetsCheckedPartial}
									halfPressed={!assetsCheckedAll}
									onChange={() => {
										checkAllAssetsInNamespace(namespaceIndex, assetsCheckedAll);
									}}
									label={
										<Typo variant="D/Medium/Body-S" className={styles.namespaceTitle}>
											{title}
										</Typo>
									}
								/>

								<Badge badgeContent={toLocaleString(assets.length)} />
							</AccordionSummary>

							<AccordionDetails classes={{ root: styles.accordionDetails }}>
								{assets.map((asset, assetIndex) => (
									<div className={styles.assetBlock} key={`${assetIndex}-${asset.checked}`}>
										<Checkbox
											dataTest="policy-curtain-asset-checkbox"
											checked={asset.checked}
											onChange={() => checkAsset(namespaceIndex, assetIndex)}
											className={styles.assetCheckbox}
											label={asset.title}
										/>
									</div>
								))}
							</AccordionDetails>
						</Accordion>
					);
				})}
			</div>

			<div className={styles.footer}>
				<Button
					data-test="policy-curtain-add-button"
					onClick={addRulesToPolicy}
					color="primary"
					size="small"
					disabled={namespaces.length === 0}
				>
					Add to the policy
				</Button>

				<Typo variant="D/Medium/Meta" color="secondary" className={styles.footerButtonDescription}>
					You can always edit
					<br />
					these rules later.
				</Typo>
			</div>
		</div>
	);
};
