import { get } from 'api/Api';
import checkResponse from 'services/api/checkResponse';
import { SampleJson } from './dto';
import { sampleJsonExample } from './examples';

async function getSample(endpointId: number, signal?: AbortSignal) {
	const config = signal ? { signal } : {};

	return get<SampleJson>('v2/sample', { id: endpointId }, config).then(
		checkResponse(sampleJsonExample)
	);
}

export { getSample };
