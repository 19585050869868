import Typo from 'components/typography/Typo';
import { AccountAWSItem } from 'models/sensors/aws/dto';
import styles from './index.module.css';

interface Props {
	name: AccountAWSItem['role_arn'];
	description: string;
}

export function AccountName({ name, description }: Props) {
	return (
		<div>
			<Typo variant="D/Medium/Body-S">{name}</Typo>

			<Typo variant="D/Regular/Meta" color="secondary" className={styles.description}>
				{description}
			</Typo>
		</div>
	);
}
