import { useStore } from 'effector-react';
import { ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router';
import Badge from 'components/Badge';
import { piiFilterStore } from 'components/PiiGlobalFilterV2/model';
import { TabLink, Tabs, useTabLink } from 'components/Tabs';
import { getAssetsFx } from 'models/assets/effects';
import { getAssetGroupsFx } from 'models/assetsGroups/effects';
import { CountersJson } from 'models/countersV2/dto';
import { getCountersFx } from 'models/countersV2/effects';
import { $counters } from 'models/countersV2/store';
import { onceFx } from 'models/modelUtils/onceFx';
import { toLocaleString } from 'services/numbers';
import { PATHS } from 'services/router';
import { UniversalSearchInLayout } from 'views/common/UniversalSearch';
import styles from './index.module.css';

type RouteItem = {
	path: string;
	label: string;
	routeCounter: keyof CountersJson;
	dataTest: string;
};

const routeList: RouteItem[] = [
	{
		path: PATHS.SERVICES,
		label: 'Services',
		routeCounter: 'internal_assets',
		dataTest: 'menu-counter-internal_assets',
	},
	{
		path: PATHS.EXTERNAL_CONNECTIONS,
		label: 'External connections',
		routeCounter: 'external_assets',
		dataTest: 'menu-counter-external_assets',
	},
];

const tabs = routeList.map((routeItem) => routeItem.path);

type Props = {
	children: ReactNode;
};
const SuperAssets = (props: Props) => {
	const { children } = props;

	const location = useLocation();
	const currentTab = useTabLink(tabs);

	const counters = useStore($counters);
	const { nonEmpty } = useStore(piiFilterStore);

	useEffect(() => {
		getCountersFx(nonEmpty);
	}, [location.pathname, nonEmpty]);

	useEffect(() => {
		onceFx(getAssetsFx);
		onceFx(getAssetGroupsFx);
	}, []);

	return (
		<>
			<Tabs value={currentTab}>
				{routeList.map((route) => (
					<TabLink
						label={
							<>
								{route.label}{' '}
								<Badge
									badgeContent={toLocaleString(counters[route.routeCounter])}
									data-test={route.dataTest}
								/>
							</>
						}
						value={route.path}
						key={route.path}
					/>
				))}

				<UniversalSearchInLayout />
			</Tabs>

			<div className={styles.content}>{children}</div>
		</>
	);
};

export { SuperAssets };
