import { ConfirmModal, getConfirmation } from 'components/ConfirmModal';
import styles from './index.module.css';

interface IDeleteModal {
	onConfirm: () => void;
	policyName: string;
}

export const DeleteModal = async ({ onConfirm, policyName }: IDeleteModal) => {
	const confirmDeletionModal = (
		<ConfirmModal
			title={`Delete ${policyName} policy`}
			confirmProps={{
				children: 'Delete',
			}}
			onConfirm={onConfirm}
		>
			<div className={styles.description}>
				If you delete this policy, you won’t receive notifications about data-in-motion anomalies
				that conflict with the rules in this policy.
				<br />
				<br />
				This action cannot be undone.
			</div>
		</ConfirmModal>
	);

	return await getConfirmation(confirmDeletionModal);
};
