import { Redirect, Route, Switch } from 'react-router-dom';
import { PATHS } from 'services/router';
import AssetCreator from 'views/SuperAssets/AssetCreater';
import AssetItem from 'views/SuperAssets/AssetItem';
import ExternalAssets from 'views/SuperAssets/ExternalAssets';
import InternalAssets from 'views/SuperAssets/InternalAssets';

function ServiceCatalog() {
	return (
		<Switch>
			<Route path={PATHS.SERVICES} component={InternalAssets} exact />
			<Route path={PATHS.EXTERNAL_CONNECTIONS} component={ExternalAssets} exact />
			<Route path={PATHS.CUSTOM_EXTERNAL_CONNECTIONS_BUILDER} component={AssetCreator} />
			<Route path={PATHS.SERVICE_ITEM} component={AssetItem} />

			<Redirect to={PATHS.SERVICES} />
		</Switch>
	);
}

export { ServiceCatalog };
