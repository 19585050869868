import { AnchorHTMLAttributes } from 'react';
import ButtonIcon from 'components/ButtonIcon';
import Icon from 'components/Icon';
import linkStyles from 'components/typography/Link/index.module.pcss';

type ExtendedFields = {
	dataTest?: string;
};

function ExternalLink(props: AnchorHTMLAttributes<HTMLAnchorElement> & ExtendedFields) {
	const { children, href, className, dataTest } = props;

	return (
		<a
			className={className}
			href={href}
			rel="nofollow noreferrer"
			target="_blank"
			data-test={dataTest}
		>
			{children}
			<Icon name="link" size={20} className={linkStyles.icon} />
		</a>
	);
}

function ExternalLinkButton(props: AnchorHTMLAttributes<HTMLAnchorElement> & ExtendedFields) {
	const { href, className, dataTest } = props;

	return (
		<a
			className={className}
			href={href}
			rel="nofollow noreferrer"
			target="_blank"
			onClick={(e) => {
				e.stopPropagation();
			}}
			data-test={dataTest}
		>
			<ButtonIcon icon="Open/Regular" size="M" />
		</a>
	);
}

export default ExternalLink;
export { ExternalLinkButton };
