import { get, post } from 'api/Api';
import checkResponse from 'services/api/checkResponse';
import {
	CustomDataTypeRequest,
	CustomDataTypeRulesResponse,
	DataTypeItem,
	DataTypeResponseList,
	DataTypeResponse,
} from './dto';
import { dataTypeExample, customDataTypeRulesResponse, dataTypesExample } from './examples';

async function getDataTypes() {
	return get<DataTypeResponseList>('v1/data-types').then(checkResponse(dataTypesExample));
}
async function setCustomDataType(payload: CustomDataTypeRequest) {
	return post<DataTypeResponse>('v1/data-types', payload).then(checkResponse(dataTypeExample));
}

async function getCustomDataTypeRules(id: DataTypeItem['id']) {
	return get<CustomDataTypeRulesResponse>(`v1/data-types/${id}/rules`).then(
		checkResponse(customDataTypeRulesResponse)
	);
}
async function updateCustomDataTypeRules(
	id: DataTypeItem['id'],
	payload: CustomDataTypeRulesResponse
) {
	return post<CustomDataTypeRulesResponse>(`v1/data-types/${id}/rules`, payload).then(
		checkResponse(customDataTypeRulesResponse)
	);
}

export { getDataTypes, setCustomDataType, getCustomDataTypeRules, updateCustomDataTypeRules };
