import { MouseEvent, MutableRefObject, ReactElement, useEffect, useRef, useState } from 'react';
import { AnyOption } from 'components/form/Select';
import DropdownButton, { DropdownButtonProps } from 'components/form/Select/DropdownButton';
import Label, { LabelProps } from 'components/form/Select/Label';
import Popover, { PopoverProps } from 'components/form/Select/Popover';
import Search, { SearchProps } from 'components/form/Select/Search';
import ApplyButton, { ApplyButtonProps } from './ApplyButton';
import OptionItem, { OptionItemProps } from './OptionItem';
import OptionList, { OptionListProps } from './OptionList';

type MultiSelectProps<T extends AnyOption> = {
	// Required options
	label: string | { primary: string; secondary: string };
	options: T[];
	onChange: (value: T[], clickedOption: T | null) => void;
	value: T[];

	// Options for extra out-of-the-box functionality
	allOption?: boolean | string;
	dataTest?: string;
	defaultOpen?: boolean;
	dividerAfter?: T[];
	closeRef?: MutableRefObject<Function>;
	hasApplyButton?: boolean;
	hasSearch?: boolean;
	onClose?: () => void;

	// Render overrides for individual components inside MultiSelect
	render?: {
		applyButton?: (props: ApplyButtonProps) => ReactElement | null;
		dropdownButton?: (props: DropdownButtonProps) => ReactElement | null;
		label?: (props: LabelProps) => ReactElement | null;
		optionItem?: (props: OptionItemProps<T>) => ReactElement | null;
		optionList?: (props: OptionListProps<T>) => ReactElement | null;
		popover?: (props: PopoverProps) => ReactElement | null;
		search?: (props: SearchProps) => ReactElement | null;
	};
};

function MultiSelect<T extends AnyOption>(props: MultiSelectProps<T>) {
	const {
		allOption = false,
		closeRef,
		defaultOpen,
		dividerAfter = [],
		hasApplyButton = false,
		hasSearch = false,
		label,
		options,
		onChange,
		onClose,
		render = {},
		value,
	} = props;

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const dropdownButtonRef = useRef<HTMLButtonElement>(null);

	useEffect(() => {
		if (defaultOpen) setAnchorEl(dropdownButtonRef.current);
	}, [defaultOpen]);

	function handleOpen(event: MouseEvent<HTMLElement>) {
		setAnchorEl(event.currentTarget);
	}

	function handleClose() {
		setAnchorEl(null);

		onClose?.();
	}

	if (closeRef) {
		closeRef.current = handleClose;
	}

	// Composable parts
	const LabelComponent = render.label || Label;
	const DropdownButtonComponent = render.dropdownButton || DropdownButton;
	const OptionItemComponent = render.optionItem || OptionItem;
	const OptionListComponent = render.optionList || OptionList;
	const SearchComponent = render.search || (hasSearch ? Search : () => null);
	const ApplyButtonComponent = render.applyButton || (hasApplyButton ? ApplyButton : () => null);
	const PopoverComponent = render.popover || Popover;

	return (
		<>
			<DropdownButtonComponent onClick={handleOpen} open={!!anchorEl} buttonRef={dropdownButtonRef}>
				<LabelComponent label={label} />
			</DropdownButtonComponent>

			<PopoverComponent anchorEl={anchorEl} onClose={handleClose}>
				<OptionListComponent
					options={options}
					allOption={allOption}
					value={value}
					onChange={onChange}
					onClose={handleClose}
					hasApplyButton={hasApplyButton}
					dividerAfter={dividerAfter}
					renderSearch={SearchComponent}
					renderOption={OptionItemComponent}
					renderApplyButton={ApplyButtonComponent}
				/>
			</PopoverComponent>
		</>
	);
}

export default MultiSelect;
export type { MultiSelectProps };
