import { createStore } from 'effector';
import { JiraSettings } from './dto';
import { getJiraSettingsFx, updateJiraSettingsFx } from './effects';
import { jiraSettingsExample } from './examples';

const jiraSettingsStore = createStore<JiraSettings>(jiraSettingsExample);

jiraSettingsStore.on(getJiraSettingsFx.doneData, (state: JiraSettings, data: JiraSettings) => data);
jiraSettingsStore.on(
	updateJiraSettingsFx.doneData,
	(state: JiraSettings, data: JiraSettings) => data
);

export { jiraSettingsStore };
