import { del, get, post } from 'api/Api';
import checkResponse from 'services/api/checkResponse';
import { PolicyItem, PolicyList } from './dto';
import { policyItemExample, policyListExample } from './examples';

function getPolicyList() {
	return get<PolicyList>('v2/policies').then(checkResponse(policyListExample));
}

function getPolicyItem(id: PolicyItem['id']) {
	return get<PolicyItem>(`v2/policies/${id}`).then(checkResponse(policyItemExample));
}

function createPolicy(payload: PolicyItem) {
	return post<PolicyItem>('v2/policies', payload).then(checkResponse(policyItemExample));
}

function updatePolicy({ id, ...payload }: PolicyItem) {
	return post<PolicyItem>(`v2/policies/${id}`, payload).then(checkResponse(policyItemExample));
}

function deletePolicy(id: PolicyItem['id']) {
	return del(`v2/policies/${id}`).then(() => id);
}

function togglePolicyTracking({
	id,
	is_active,
}: {
	id: PolicyItem['id'];
	is_active: PolicyItem['is_active'];
}) {
	return post(`v2/policies/${id}/activity`, { is_active }).then(() => ({ id, is_active }));
}

function generatePolicies() {
	return post<PolicyList>('v2/policies/autogenerate').then(checkResponse(policyListExample));
}

export {
	getPolicyList,
	getPolicyItem,
	createPolicy,
	updatePolicy,
	deletePolicy,
	togglePolicyTracking,
	generatePolicies,
};
