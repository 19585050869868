import { createEffect, createEvent, createStore } from 'effector';
import isEqual from 'lodash.isequal';
import { DEFAULT_LIMIT } from 'api/Api';
import { TOrder } from 'models/common/dto';
import { ApiParams } from 'services/api';
import { getComparator } from './comparator';

// TODO SMAT-2868 document design desitions: initial status, partial fx, etc

// Limitations:
//  (?) sort param must be present in params
//

type SortType<DataType> = {
	sort: {
		orderBy: keyof DataType & string; // & string is here so that only string keys are allowed, for interpolation cases
		order: TOrder;
	};
};

type Store<DataType, ParamsType extends SortType<DataType>> = {
	status: 'initial' | 'loading' | 'loadingMore' | 'loaded' | 'error';
	data: DataType[];
	total: number;
	total_filtered?: number;
	hasMoreData: boolean;
	error: null | Error;
	params: ParamsType;
};

type FetchDataApi<DataType> = (
	apiParams: ApiParams,
	signal?: AbortSignal
) => Promise<{ data: DataType[]; total: number; total_filtered?: number }>;

type ExtendedData<DataType> = {
	data: DataType[];
	total: number;
	total_filtered?: number;
	hasMoreData: boolean;
};

function createTableModel<DataType extends object, ParamsType extends SortType<DataType>>(
	initialParams: ParamsType,
	fetchDataApi: FetchDataApi<DataType>,
	fetchFxOnInit: boolean
) {
	//
	// Model state.
	// Consists of store (exposed to outside consumers) and one internal
	// variable: offset for fetchMore action.
	//

	const initialState: Store<DataType, ParamsType> = {
		status: 'initial',
		data: [],
		total: 0,
		hasMoreData: false,
		error: null,
		params: initialParams,
	};

	const store = createStore<Store<DataType, ParamsType>>(initialState);
	let lastOffset = 0;

	// Event for managing store, used internally.
	const setState = createEvent<Partial<Store<DataType, ParamsType>>>();

	// Helpers for managing multiple effect calls, used internally.
	let ac = new AbortController();
	let lastFetchFxPromise: Promise<ExtendedData<DataType>> | null = null;

	//
	// Main effects.
	// Exposed to outside consumers.
	//

	const fetchFx = createEffect<Partial<ParamsType>, ExtendedData<DataType>>();
	const fetchMoreFx = createEffect<void, ExtendedData<DataType> | undefined>();
	const resetFx = createEffect<void, void>();

	fetchFx.use((partialParams) => {
		const storeState = store.getState();
		const params = { ...storeState.params, ...partialParams };

		// Logic for not doing request, or doing request client-side.

		/*	TODO SMAT-2868 this part of code makes force reload of data difficult. Use-case: visit
			view with same params second time.
			Think how to implement force-reload gracefully.

		if (
			paramsNotChanged(params) &&
			lastOffset === 0 &&
			storeState.status !== 'initial' &&
			storeState.status !== 'loadingMore' &&
			!!lastFetchFxPromise
		) {
			return lastFetchFxPromise;
		}
		*/

		if (onlySortChanged(params) && !storeState.hasMoreData && storeState.status === 'loaded') {
			setState({
				params,
			});

			return {
				data: getSorted(storeState.data, params.sort),
				total: storeState.total,
				total_filtered: storeState.total_filtered,
				hasMoreData: false,
			};
		}

		// Principal logic: just fetch data from server.

		lastOffset = 0;
		setState({
			status: 'loading',
			hasMoreData: false,
			error: null,
			params,
		});

		const apiParams = {
			...stringifyAndRemoveEmptyFilters(params),
			sort: `${params.sort.orderBy}:${params.sort.order}`,
			limit: DEFAULT_LIMIT + 1,
			offset: 0,
		};

		ac.abort();
		ac = new AbortController();
		lastFetchFxPromise = fetchDataApi(apiParams, ac.signal).then((payload) => {
			const hasMoreData = payload.data.length === DEFAULT_LIMIT + 1;
			if (hasMoreData) {
				payload.data.pop();
			}

			return { ...payload, hasMoreData };
		});

		return lastFetchFxPromise;
	});

	fetchMoreFx.use(() => {
		const storeState = store.getState();

		// Logic for not doing request, or doing request client-side.

		if (!storeState.hasMoreData) {
			console.log('Tried to load more data, but `hasMoreData` flag in store is false. Ignored.');
			return;
		}

		if (storeState.status === 'initial') {
			console.log('Tried to load more data, but store is in initial status. Ignored.');
			return;
		}

		if (storeState.status === 'loading' || storeState.status === 'loadingMore') {
			console.log('Tried to load more data while already loading. Ignored.');
			return;
		}

		// Principal logic: just fetch data from server.

		setState({ status: 'loadingMore', error: null });

		const apiParams = {
			...stringifyAndRemoveEmptyFilters(storeState.params),
			sort: `${storeState.params.sort.orderBy}:${storeState.params.sort.order}`,
			limit: DEFAULT_LIMIT + 1,
			offset: lastOffset + DEFAULT_LIMIT,
		};

		return fetchDataApi(apiParams, ac.signal).then((payload) => {
			const { data, total, total_filtered } = payload;
			lastOffset += DEFAULT_LIMIT;
			const hasMoreData = payload.data.length === DEFAULT_LIMIT + 1;
			if (hasMoreData) {
				payload.data.pop();
			}

			return { data, total, total_filtered, hasMoreData };
		});
	});

	resetFx.use(() => {
		setState(initialState);
		lastOffset = 0;
		ac.abort();
		ac = new AbortController();
	});

	//
	// Store subscriptions.
	//

	store.on(fetchFx.doneData, (state, payload) => {
		return { ...state, status: 'loaded', ...payload };
	});

	store.on(fetchMoreFx.doneData, (state, payload) => {
		if (!payload) return;

		const { data, ...rest } = payload;

		return { ...state, status: 'loaded', data: [...state.data, ...data], ...rest };
	});

	store.on([fetchFx.failData, fetchMoreFx.failData], (state, error) => {
		// Ignore aborted requests. We abort them ourselves.
		if (error instanceof DOMException && error.name === 'AbortError') {
			return;
		}

		/*
		if (knownHandlableError) {
			return { ...state, status: 'error', error };
		}
		*/

		throw error;
	});

	store.on(setState, (state, partialState) => ({ ...state, ...partialState }));

	//
	// Helper functions, used internally.
	//

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	function paramsNotChanged(newParams: ParamsType) {
		const currentParams = store.getState().params;

		return isEqual(currentParams, newParams);
	}

	function onlySortChanged(newParams: ParamsType) {
		const currentParams = store.getState().params;

		const { sort: newSort, ...newFilters } = newParams;
		const { sort: currentSort, ...currentFilters } = currentParams;

		return isEqual(currentFilters, newFilters) && !isEqual(currentSort, newSort);
	}

	function stringifyAndRemoveEmptyFilters(params: ParamsType) {
		const stringifiedFilters: ApiParams = {};

		for (const key in params) {
			const value = params[key];

			if (key === 'sort') continue; // ignore 'sort' because it is not a filter
			if (typeof value === 'number' && value === 0) continue;
			if (typeof value === 'string' && value === '') continue;
			if (Array.isArray(value) && value.length === 0) continue;

			stringifiedFilters[key] = Array.isArray(value) ? value.join(',') : String(value);
		}

		return stringifiedFilters;
	}

	function getSorted(data: DataType[], { order, orderBy }: ParamsType['sort']) {
		return [...data].sort(getComparator(orderBy, order));
	}

	//
	// Make initial request and return model.
	//

	fetchFxOnInit && fetchFx(initialParams);

	return {
		store,
		fetchFx,
		fetchMoreFx,
		resetFx,
	};
}

export { createTableModel };
export type { Store };
