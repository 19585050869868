import cn from 'classnames';
import { DataTypeItem } from 'models/dataTypes/dto';
import styles from './index.module.pcss';
import Mark from './Mark';

interface Props {
	list: {
		dataTypeId: DataTypeItem['id'];
		isFP: boolean;
		isManual: boolean;
		isCorrected: boolean;
	}[];
	size?: 'S' | 'M';
}

const SHOW_MARK_COUNT = 3;

function MarkList({ list, size = 'M' }: Props) {
	return (
		<span className={cn(styles.container, styles[size])}>
			{list.map(({ dataTypeId, isFP, isManual, isCorrected }, index) => (
				<Mark
					className={cn(styles.mark, styles[`mark${index}`], {
						[styles.hideMark]: index > SHOW_MARK_COUNT - 1,
					})}
					id={dataTypeId}
					isFP={isFP}
					isManual={isManual}
					isCorrected={isCorrected}
					key={`${dataTypeId}-${index}`}
					size={size}
				/>
			))}
		</span>
	);
}

export default MarkList;
