import { get } from 'api/Api';
import checkResponse from 'services/api/checkResponse';
import { DataMapJson } from './dto';
import { dataMapJsonExample } from './examples';
function getDataMap(relatedOnly = false, signal?: AbortSignal) {
	const config = signal ? { signal } : {};

	// relatedOnly is no longer used, but may return in the future
	return get<DataMapJson>('v2/data-map', { 'related-only': relatedOnly }, config).then(
		checkResponse(dataMapJsonExample)
	);
}

export { getDataMap };
