import cn from 'classnames';
import { useHistory } from 'react-router-dom';
import GridCell from 'components/table/GridCell';
import GridRow from 'components/table/GridRow';
import Tooltip from 'components/Tooltip';
import Typo from 'components/typography/Typo';
import { EndpointsItem, methodOrProtocol } from 'models/endpointsV2/dto';
import { PATHS } from 'services/router';
import styles from './index.module.pcss';
import PiiTypeWithTooltip from './PiiTypeWithTooltip';

function EndpointRow(endpoint: EndpointsItem) {
	const { host, data_types, url, id, sensitivity } = endpoint;
	const history = useHistory();

	return (
		<GridRow
			className={cn(styles.rowContainer, styles.endpointsRow)}
			onClick={() => history.push(`${PATHS.SAMPLE_V2}?id=${id}&direction=request`)}
			hover
		>
			<GridCell className={cn(styles.cell, styles.urlCell)}>
				<Tooltip title={url}>
					<Typo variant="D/Regular/Meta">{url}</Typo>
				</Tooltip>

				<Typo variant="D/Regular/Meta" color="secondary" className={styles.method}>
					{methodOrProtocol(endpoint)}
				</Typo>
			</GridCell>

			<GridCell className={styles.cell}>
				<Tooltip title={host}>
					<Typo variant="D/Regular/Meta">{host}</Typo>
				</Tooltip>
			</GridCell>

			<GridCell className={cn(styles.cell, styles.sensitivityCell)}>
				<PiiTypeWithTooltip dataTypes={data_types} sensitivity={sensitivity} />
			</GridCell>
		</GridRow>
	);
}

export default EndpointRow;
