import isEqual from 'lodash.isequal';
import { useMemo, useState } from 'react';
import Button from 'components/form/Button';
import PiiGlobalFilter from 'components/PiiGlobalFilterV2';
import { clearPiiFilter } from 'components/PiiGlobalFilterV2/model';
import AddFilterMenu from './AddFilterMenu';
import AssetFilter from './AssetFilter';
import ClusterFilter from './ClusterFilter';
import EventStartFilter from './EventStartFilter';
import EventTypeFilter from './EventTypeFilter';
import GroupFilter from './GroupFilter';
import styles from './index.module.css';
import KafkaInstanceFilter from './KafkaInstanceFilter';
import LabelKeyFilter from './LabelKeyFilter';
import LabelValueFilter from './LabelValueFilter';
import { FilterPropsBase, FilterType, defaultValues, FilterValues } from './model';
import NamespaceFilter from './NamespaceFilter';
import { ProtocolFilter } from './ProtocolFilter';
import RegionFilter from './RegionFilter';

function isItInitialValue(value: unknown, type: FilterType) {
	if (type === 'dataTypes') {
		return Array.isArray(value) && value.length !== 0 ? value[0] === 'nonempty' : true;
	}

	return isEqual(value, defaultValues[type]);
}

type Props<T extends FilterType> = {
	config: T[];
	values: Pick<FilterValues, T>;
	onChange: (newValues: Pick<FilterValues, T>) => void;
};

function FilterLine<T extends FilterType>(props: Props<T>) {
	const { config, values, onChange } = props;

	const [newFilterType, setNewFilterType] = useState<T | null>(null);

	const [filtersWithValue, filtersWithoutValue] = useMemo(() => {
		return config.reduce(
			function (acc, type, idx) {
				const hasValue = idx === 0 || !isItInitialValue(values[type], type);

				hasValue ? acc[0].push(type) : acc[1].push(type);

				return acc;
			},
			[[], []] as [T[], T[]]
		);
	}, [config, values]);

	const filterSet = useMemo(() => {
		return filtersWithValue.map((type, id) => {
			// Only first filter has to be fixed
			const fixed = id === 0;

			return (
				<FilterItem type={type} fixed={fixed} values={values} onChange={onChange} key={type} />
			);
		});
	}, [filtersWithValue, values]);

	const newFilter = useMemo(() => {
		if (!newFilterType) return null;

		const type = newFilterType;

		return (
			<FilterItem
				type={type}
				fixed={false}
				values={values}
				onChange={onChange}
				defaultOpen
				onClose={() => setNewFilterType(null)}
				key={type}
			/>
		);
	}, [newFilterType, values]);

	function resetAllFilters() {
		const result: Pick<FilterValues, T> = { ...values };
		let isThereDataTypes = false;

		for (const key in result) {
			result[key] = defaultValues[key];
			// This condition exists because PiiFilter has its own store
			if (key === 'dataTypes') {
				isThereDataTypes = true;
			}
		}

		onChange(result);
		// This condition exists because PiiFilter has its own store
		if (isThereDataTypes) {
			clearPiiFilter();
		}
	}

	// We should hide the button only when:
	// - every non-fixed filter was chosen;
	// - the last filter was opened, but not chosen.
	const hideAddFilterButton =
		filtersWithoutValue.length === 0 || (!!newFilterType && filtersWithoutValue.length === 1);

	const showResetButton = useMemo(() => {
		for (const key in values) {
			if (!isItInitialValue(values[key], key)) {
				return true;
			}
		}

		return false;
	}, [values]);

	return (
		<div className={styles.container}>
			<div className={styles.filter}>
				{filterSet}

				{newFilter}

				{!hideAddFilterButton && (
					<AddFilterMenu list={filtersWithoutValue} onChange={setNewFilterType} />
				)}

				{showResetButton && (
					<Button
						size="extraSmall"
						color="ghost"
						onClick={resetAllFilters}
						data-test="reset-filters-button"
					>
						Reset filters
					</Button>
				)}
			</div>
		</div>
	);
}

type FilterItemProps<T extends FilterType, TT extends T> = {
	type: TT;
	values: Pick<FilterValues, T>;
	onChange: (newValues: Pick<FilterValues, T>) => void;
} & FilterPropsBase;

// Helper type, used to ensure that concrete filter components receive and return correct data types.
type FilterDataProps<TT extends FilterType> = {
	value: FilterValues[TT];
	onChange: (newValue: FilterValues[TT]) => void;
	resetFilter: () => void;
	values: Partial<FilterValues>;
};

function FilterItem<T extends FilterType, TT extends T>(props: FilterItemProps<T, TT>) {
	const { type, values, onChange, ...rest } = props;

	const filterDataProps: FilterDataProps<TT> = {
		value: values[type],
		onChange: (newValue: FilterValues[TT]) => {
			onChange({ ...values, [type]: newValue } as unknown as Pick<FilterValues, T>);
		},
		resetFilter: () => {
			onChange({ ...values, [type]: defaultValues[type] } as unknown as Pick<FilterValues, T>);
		},
		values,
	};

	let dataProps;

	switch (props.type) {
		case 'assets':
			dataProps = filterDataProps as unknown as FilterDataProps<'assets'>;
			return <AssetFilter {...dataProps} {...rest} />;

		case 'clusters':
			dataProps = filterDataProps as unknown as FilterDataProps<'clusters'>;
			return <ClusterFilter {...dataProps} {...rest} />;

		case 'clustersInternal':
			dataProps = filterDataProps as unknown as FilterDataProps<'clustersInternal'>;
			return <ClusterFilter {...dataProps} {...rest} assetType="internal" />;

		case 'clustersExternal':
			dataProps = filterDataProps as unknown as FilterDataProps<'clustersExternal'>;
			return <ClusterFilter {...dataProps} {...rest} assetType="external" />;

		case 'dataTypes':
			// A little bit of special case
			return <PiiGlobalFilter resetFilter={clearPiiFilter} {...rest} />;

		case 'eventType':
			dataProps = filterDataProps as unknown as FilterDataProps<'eventType'>;
			return <EventTypeFilter {...dataProps} {...rest} />;

		case 'eventStart':
			dataProps = filterDataProps as unknown as FilterDataProps<'eventStart'>;
			return <EventStartFilter {...dataProps} {...rest} />;

		case 'namespaces':
			dataProps = filterDataProps as unknown as FilterDataProps<'namespaces'>;
			return <NamespaceFilter {...dataProps} {...rest} />;

		case 'namespacesEndpoints':
			dataProps = filterDataProps as unknown as FilterDataProps<'namespacesEndpoints'>;
			return <NamespaceFilter {...dataProps} {...rest} excludedAssetTypes />;

		case 'groups':
			dataProps = filterDataProps as unknown as FilterDataProps<'groups'>;
			return <GroupFilter {...dataProps} {...rest} />;

		case 'regions':
			dataProps = filterDataProps as unknown as FilterDataProps<'regions'>;
			return <RegionFilter {...dataProps} {...rest} />;

		case 'labelKeys':
			dataProps = filterDataProps as unknown as FilterDataProps<'labelKeys'>;
			return <LabelKeyFilter {...dataProps} {...rest} />;

		case 'labelValues':
			dataProps = filterDataProps as unknown as FilterDataProps<'labelValues'>;
			return <LabelValueFilter {...dataProps} {...rest} />;

		case 'kafkaInstances':
			dataProps = filterDataProps as unknown as FilterDataProps<'kafkaInstances'>;
			return <KafkaInstanceFilter {...dataProps} {...rest} />;

		case 'protocol':
			dataProps = filterDataProps as unknown as FilterDataProps<'protocol'>;
			return <ProtocolFilter {...dataProps} {...rest} />;

		default:
			console.error('Unexpected filter type:', type);
			return null;
	}
}

export default FilterLine;
