import Typo from 'components/typography/Typo';
import { TransformedRule } from '..';
import { RuleRowItem } from '../RuleRowItem';
import styles from './index.module.css';

type Props = {
	type: 'key' | 'value';
	value: TransformedRule['key'] | TransformedRule['value'];
	onChange: (value: TransformedRule['key'] | TransformedRule['value']) => void;
	prefix?: string;
};

export function RuleRow({ type, value, onChange, prefix }: Props) {
	return (
		<div className={styles.wrapper} data-test={`asset-rules-rule-dynamic-${type}`}>
			{!!prefix && (
				<Typo component="span" variant="D/Medium/Body-S">
					{prefix}
				</Typo>
			)}

			<div className={styles.multilineRow}>
				<div className={styles.roundedSpan}>
					<Typo variant="D/Medium/Body-S" color="secondary" className={styles.label}>
						{type}: /
					</Typo>
				</div>

				<div className={styles.roundedSpan}>
					<RuleRowItem type={type} value={value} onChange={onChange} />
				</div>
			</div>
		</div>
	);
}
