import { getDataTypesAsApiParam } from 'models/dataTypes/helpers';
import { createTableModel } from 'models/modelUtils/tableModelFactory';
import { ApiParams } from 'services/api';
import { getKafkaMessageSchemasList } from './api';
import { KafkaMessageSchemaListItem, KafkaMessageSchemaTableParams } from './dto';

function fetchDataApi(apiParamsRaw: ApiParams, signal?: AbortSignal) {
	const { id, 'data-types': piiTypes, ...apiParams } = apiParamsRaw;

	apiParams['data-types'] = getDataTypesAsApiParam(piiTypes);

	return getKafkaMessageSchemasList(Number(id), apiParams, signal).then((payload) => {
		return {
			data: payload.schemas,
			total: payload.total,
			total_filtered: payload.total_filtered,
		};
	});
}

function createKafkaMessageSchemasModel(
	initialParams: KafkaMessageSchemaTableParams,
	fetchFxOnInit: boolean = true
) {
	return createTableModel<KafkaMessageSchemaListItem, KafkaMessageSchemaTableParams>(
		initialParams,
		fetchDataApi,
		fetchFxOnInit
	);
}

//
// Instantiate model with initial params, but do not fetch data from server.
//

const initialParams: KafkaMessageSchemaTableParams = {
	id: -1,
	'data-types': [],
	sort: { orderBy: 'name', order: 'desc' },
};

const kafkaMessageSchemasModel = createKafkaMessageSchemasModel(initialParams, false);

export { kafkaMessageSchemasModel };
