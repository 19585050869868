import { useStore } from 'effector-react';
import { useMemo } from 'react';
import { enqueueSnackbar } from 'components/Snackbar';
import Tooltip from 'components/Tooltip';
import Typo from 'components/typography/Typo';
import { S3BucketItem } from 'models/s3Buckets/dto';
import { updateS3BucketFx } from 'models/s3Buckets/effects';
import { s3RegionsByKeyword } from 'models/s3Regions/store';
import { APIError } from 'services/api/httpRequest';
import { DateFormat, dayjs } from 'services/dayjs';
import { lastSeen } from 'services/lastSeen';
import { toFileSize, toLocaleString } from 'services/numbers';
import { EditableInput } from 'views/common/EditableInput';
import { S3BucketPublicStatus } from '../../S3BucketTableRow';
import { S3BucketTagList } from '../../S3BucketTag';
import { S3BucketHeader } from '../S3BucketHeader';
import styles from './index.module.pcss';

interface Props {
	bucket: S3BucketItem;
}

export function S3BucketSummary({ bucket }: Props) {
	const s3Regions = useStore(s3RegionsByKeyword);
	const formattedLocal = dayjs(bucket.created_at).format(DateFormat.date);
	const formattedUTC = dayjs(bucket.created_at).utc().format(DateFormat.utc);
	const lastSeenValue = useMemo(
		() => lastSeen({ timestamp: bucket.last_checked, short: true }),
		[bucket.last_checked]
	);
	const regionDescription = s3Regions[bucket.region]?.description || '';

	const onSave = async (parameter: keyof S3BucketItem, value: string) => {
		if (value.length > 200) {
			return 'The description is more than 200 symbols.';
		}

		try {
			await updateS3BucketFx({ ...bucket, [parameter]: value });
		} catch (error) {
			if (error instanceof APIError && error.response.status === 400) {
				const { message } = await error.response.json();
				return message;
			}

			const errorMessage = 'Something went wrong';
			enqueueSnackbar(errorMessage);
			return errorMessage;
		}

		return null;
	};

	return (
		<>
			<S3BucketHeader tab="summary" bucket={bucket} />

			<div className={styles.container} data-test="s3-bucket-info-container">
				<div className={styles.detailsGrid}>
					<Typo variant="D/Regular/Meta-CAP" color="secondary">
						Description
					</Typo>
					<EditableInput
						dataTest="s3-bucket-info-description"
						value={bucket.description}
						onSave={(value: string) => onSave('description', value)}
						className={styles.editableInput}
						placeholder="Enter description"
						multiline
					/>

					<Typo variant="D/Regular/Meta-CAP" color="secondary">
						Owner
					</Typo>
					<EditableInput
						value={bucket.owner}
						onSave={(value: string) => onSave('owner', value)}
						className={styles.editableInput}
						placeholder="Enter owner’s name"
						dataTest="s3-bucket-info-owner"
					/>

					<Typo variant="D/Regular/Meta-CAP" color="secondary">
						Account ID
					</Typo>
					<Typo variant="D/Regular/Body-S" data-test="s3-bucket-info-account-id">
						{bucket.account_id}
					</Typo>

					<Typo variant="D/Regular/Meta-CAP" color="secondary">
						Region
					</Typo>
					<Typo variant="D/Regular/Body-S" data-test="s3-bucket-info-region">
						{regionDescription}
					</Typo>

					<Typo variant="D/Regular/Meta-CAP" color="secondary">
						Created
					</Typo>
					<Typo variant="D/Regular/Body-S" data-test="s3-bucket-info-created">
						<Tooltip title={formattedUTC} className={styles.detailsValue}>
							<>{formattedLocal}</>
						</Tooltip>
					</Typo>

					<Typo variant="D/Regular/Meta-CAP" color="secondary">
						Files
					</Typo>
					<Typo variant="D/Regular/Body-S" data-test="s3-bucket-info-objects-count">
						{toLocaleString(bucket.objects_count)}
					</Typo>

					<Typo variant="D/Regular/Meta-CAP" color="secondary">
						Storage Used
					</Typo>
					<Typo variant="D/Regular/Body-S" data-test="s3-bucket-info-size">
						{toFileSize(bucket.size)}
					</Typo>

					<Typo variant="D/Regular/Meta-CAP" color="secondary">
						Access Rights
					</Typo>
					<Typo variant="D/Regular/Body-S" data-test="s3-bucket-info-access-rights">
						{S3BucketPublicStatus[bucket.public_status]}
					</Typo>

					<Typo variant="D/Regular/Meta-CAP" color="secondary">
						Encryption
					</Typo>
					<Typo variant="D/Regular/Body-S" data-test="s3-bucket-info-encryption">
						{bucket.is_encrypted ? 'Yes' : 'No'}
					</Typo>

					<Typo variant="D/Regular/Meta-CAP" color="secondary">
						Tags
					</Typo>
					<S3BucketTagList tags={bucket.tags} />

					<Typo variant="D/Regular/Meta-CAP" color="secondary">
						Resource type
					</Typo>
					<Typo
						variant="D/Regular/Body-S"
						className={styles.type}
						data-test="database-detail-instance-type"
					>
						{bucket.type}
					</Typo>

					<Typo variant="D/Regular/Meta-CAP" color="secondary">
						Last checked
					</Typo>
					<Typo variant="D/Regular/Body-S" data-test="s3-bucket-info-last-checked">
						<Tooltip title={lastSeenValue.date} className={styles.detailsValue}>
							<>{lastSeenValue.diff}</>
						</Tooltip>
					</Typo>
				</div>
			</div>
		</>
	);
}
