import cn from 'classnames';
import { useStore } from 'effector-react';
import { MouseEvent, useContext, useLayoutEffect, useRef } from 'react';
import { cubeImagesStore } from 'models/dataMapV2/cubeImagesStore';
import { MapAsset } from '../../index';
import { ZoomContext } from '../ZoomWrapper/index';
import { getCroppedText, drawText, drawRect } from './helpers';
import styles from './index.module.css';
import {
	labelsByScaleStepStyleConfig,
	cubeByImageIdStylesConfig,
	CUBE_CENTER_W_OFFSET,
} from './styleConfig';

interface Props {
	imageId: string;
	type: MapAsset['type'];
	name: MapAsset['name'];
	selected: boolean;
	highlight: string;
	onSelect: () => void;
}

export const CanvasLabel = ({ type, name, selected, onSelect, highlight, imageId }: Props) => {
	const { scaleStep } = useContext(ZoomContext);
	const canvasRef = useRef(null);
	const cubeImages = useStore(cubeImagesStore);

	function handleSelect(event: MouseEvent<HTMLCanvasElement>) {
		event.stopPropagation();
		event.preventDefault();

		onSelect();
	}

	useLayoutEffect(() => {
		if (canvasRef && canvasRef.current) {
			// ============================================================================
			// init
			// ============================================================================
			const ICON_SIZE = type === 'custom' ? labelsByScaleStepStyleConfig[scaleStep].iconSize : 0;
			const ICON_PADDING =
				type === 'custom' ? labelsByScaleStepStyleConfig[scaleStep].iconPadding : 0;
			const ICON_BORDER_RADIUS = [
				labelsByScaleStepStyleConfig[scaleStep].borderRadius,
				0,
				0,
				labelsByScaleStepStyleConfig[scaleStep].borderRadius,
			];

			const LABEL_MAX_WIDTH = labelsByScaleStepStyleConfig[scaleStep].maxWidth - ICON_SIZE;
			const LABEL_PADDING_LEFT = labelsByScaleStepStyleConfig[scaleStep].paddingLeft;
			const LABEL_PADDING_RIGHT = labelsByScaleStepStyleConfig[scaleStep].paddingRight;
			const LABEL_PADDING_TOP = labelsByScaleStepStyleConfig[scaleStep].paddingTop;

			const FONT = `500 ${labelsByScaleStepStyleConfig[scaleStep].fontSize}px Inter`;
			const LABEL_HEIGHT = labelsByScaleStepStyleConfig[scaleStep].height;

			const COLOR_WHITE = '#FFFFFF';
			const COLOR_BLACK = '#000000';
			const COLOR_BLUE = '#1656D9';
			const COLOR_PURPLE = '#E393E7';
			const LABEL_BORDER_RADIUS =
				type === 'custom'
					? [
							0,
							labelsByScaleStepStyleConfig[scaleStep].borderRadius,
							labelsByScaleStepStyleConfig[scaleStep].borderRadius,
							0,
					  ]
					: [labelsByScaleStepStyleConfig[scaleStep].borderRadius];

			const CANVAS_SIZE_W = LABEL_MAX_WIDTH;
			const CANVAS_SIZE_H = LABEL_HEIGHT;
			const CENTER_OF_CANVAS_W = CANVAS_SIZE_W / 2;

			const current = canvasRef.current as HTMLCanvasElement;
			current.width = CANVAS_SIZE_W;
			current.height = CANVAS_SIZE_H;

			// ============================================================================
			// get context and clear
			// ============================================================================
			const ctx = current.getContext('2d');

			if (!ctx) return;

			ctx.clearRect(0, 0, CANVAS_SIZE_W, CANVAS_SIZE_H);

			// ============================================================================
			// calculate label content and width
			// ============================================================================
			ctx.font = FONT;
			const labelWidth = LABEL_MAX_WIDTH - LABEL_PADDING_LEFT - LABEL_PADDING_RIGHT - ICON_SIZE;
			const croppedText = getCroppedText(ctx!, labelWidth, name);
			const { isCropped, textContent } = croppedText;
			let { textContentSize } = croppedText;

			textContentSize += LABEL_PADDING_LEFT + LABEL_PADDING_RIGHT;
			const LABEL_POINT_X = CENTER_OF_CANVAS_W - textContentSize / 2 + ICON_SIZE / 2;

			// ============================================================================
			// label background
			// ============================================================================

			drawRect({
				ctx: ctx!,
				x: LABEL_POINT_X,
				y: 0,
				w: textContentSize,
				h: LABEL_HEIGHT,
				borderRadius: LABEL_BORDER_RADIUS,
				color: selected ? COLOR_BLUE : COLOR_BLACK,
			});

			// ============================================================================
			// label content
			// ============================================================================

			drawText({
				ctx: ctx!,
				color: COLOR_WHITE,
				textAlign: 'center',
				textBaseline: 'top',
				text: isCropped ? `${textContent}...` : textContent,
				x: CENTER_OF_CANVAS_W + ICON_SIZE / 2,
				y: LABEL_PADDING_TOP,
			});

			if (!selected && highlight && name.includes(highlight)) {
				// ============================================================================
				// label highlight
				// ============================================================================

				// find highlight string indexes
				const idx = textContent.toLocaleLowerCase().indexOf(highlight.toLocaleLowerCase());
				const idx2 = name.toLocaleLowerCase().indexOf(highlight.toLocaleLowerCase());

				const first = textContent.slice(0, idx);
				const second = textContent.slice(idx, idx + highlight.length);
				let highlightContent = second;

				let highlightX1: number;
				let highlightWidth: number;

				if (idx === -1 && idx2 === 0) {
					highlightX1 = 0;
					highlightWidth = textContentSize - LABEL_PADDING_RIGHT - LABEL_PADDING_LEFT;
					highlightContent = `${textContent}...`;
				} else if (idx === -1 && idx2 > textContent.length - 1) {
					// highlight dots only
					const dotsWidth = ctx.measureText('...').width;
					highlightX1 = ctx.measureText(textContent).width;
					highlightWidth = dotsWidth;
					highlightContent = '...';
				} else if (idx === -1 && idx <= textContent.length && second === '') {
					const first2 = textContent.slice(0, idx2);
					highlightX1 = ctx.measureText(textContent.slice(0, first2.length)).width;
					highlightWidth = ctx.measureText(`${textContent.slice(idx2)}...`).width;
					highlightContent = `${textContent.slice(idx2)}...`;
				} else {
					highlightX1 = ctx.measureText(textContent.slice(0, first.length)).width;
					highlightWidth = ctx.measureText(
						textContent.slice(first.length, first.length + second.length)
					).width;
				}

				// highlight background
				drawRect({
					ctx: ctx!,
					x: LABEL_POINT_X + highlightX1,
					y: 0,
					w: highlightWidth + LABEL_PADDING_LEFT + LABEL_PADDING_RIGHT,
					h: LABEL_HEIGHT,
					borderRadius: LABEL_BORDER_RADIUS,
					color: COLOR_PURPLE,
				});

				// highlight content
				drawText({
					ctx: ctx!,
					textAlign: 'left',
					textBaseline: 'top',
					text: highlightContent,
					color: COLOR_BLACK,
					x: LABEL_POINT_X + highlightX1 + LABEL_PADDING_LEFT,
					y: LABEL_PADDING_TOP,
				});
			}

			if (type === 'custom') {
				const labelCustomIcon = cubeImages['labelCustom'];
				if (!labelCustomIcon || !labelCustomIcon.isLoaded) return;

				drawRect({
					ctx: ctx!,
					x: LABEL_POINT_X - ICON_SIZE,
					y: 0,
					w: ICON_SIZE + ICON_PADDING,
					h: LABEL_HEIGHT,
					borderRadius: ICON_BORDER_RADIUS,
					color: selected ? COLOR_BLUE : COLOR_BLACK,
				});

				// @ts-ignore
				ctx.drawImage(
					labelCustomIcon.image,
					LABEL_POINT_X - ICON_SIZE,
					ICON_PADDING,
					ICON_SIZE,
					ICON_SIZE
				);
			}
		}
	}, [canvasRef, scaleStep, highlight, selected, cubeImages]);

	if (scaleStep <= 100) return null;

	return (
		<canvas
			ref={canvasRef}
			onClick={handleSelect}
			className={cn(styles.canvasElement, styles.labelCanvas)}
			style={{
				top:
					cubeByImageIdStylesConfig[imageId].labelTop -
					labelsByScaleStepStyleConfig[scaleStep].height,
				left: CUBE_CENTER_W_OFFSET - labelsByScaleStepStyleConfig[scaleStep].maxWidth / 2,
			}}
			data-test="data-map-cube-label"
		/>
	);
};
