import { useEffect, useState } from 'react';
import { generatePath } from 'react-router';
import { useHistory, useParams } from 'react-router-dom';
import Preloader from 'components/Preloader';
import ExternalLink from 'components/typography/ExternalLink';
import Typo from 'components/typography/Typo';
import { s3BucketsSteps } from 'layouts/AuthorizedWithLeftMenu/Breadcrumbs';
import Header from 'layouts/AuthorizedWithLeftMenu/Header';
import { getS3ClusterFileWithSamples } from 'models/s3BucketCluster/api';
import { S3ClusterFileWithSamples } from 'models/s3BucketCluster/dto';
import { getS3BucketItem } from 'models/s3Buckets/api';
import { S3BucketItem } from 'models/s3Buckets/dto';
import { PATHS } from 'services/router';
import styles from './index.module.css';
import { ClusterSampleFiles } from './SampleFiles';
import { ClusterSummary } from './Summary';
import { WashedClusterProps, WashedCluster } from './WashedCluster';

type Props = {
	cluster: S3ClusterFileWithSamples;
	bucket: S3BucketItem;
};

function S3BucketClusterView(props: Props) {
	const { cluster, bucket } = props;

	return (
		<div className={styles.container}>
			<Header
				showSensitiveSwitcher
				breadcrumbProps={{
					steps: [
						...s3BucketsSteps,
						{
							path: generatePath(PATHS.S3_BUCKETS_ITEM, {
								id: bucket.id,
								tab: 'summary',
							}),
							name: bucket.name,
						},
						{
							path: generatePath(PATHS.S3_BUCKETS_ITEM, {
								id: bucket.id,
								tab: 'files',
							}),
							name: 'Files',
						},
					],
					finalStep: cluster.name,
				}}
				titleBlock={
					<div className={styles.headerContainer}>
						<Typo
							variant="D/Medium/H400-Page-Title"
							dataTest="s3-cluster-header"
							className={styles.ellipsisText}
						>
							{cluster.name}
						</Typo>
						{bucket.cloud_console_url && (
							<ExternalLink href={bucket.cloud_console_url} className={styles.link}>
								View in cloud console
							</ExternalLink>
						)}
					</div>
				}
			/>

			<ClusterSummary cluster={cluster} />
			<ClusterSampleFiles samples={cluster.samples} type={cluster.type} />
		</div>
	);
}

function S3BucketClusterViewWrapper() {
	const history = useHistory();
	const { id: bucketId, clusterId: id }: { id: string; clusterId: string } = useParams();

	const [isLoading, setLoading] = useState(true);
	const [cluster, setCluster] = useState<S3ClusterFileWithSamples | undefined>();
	const [bucket, setBucket] = useState<S3BucketItem | undefined>();

	useEffect(() => {
		setLoading(true);

		Promise.all([getS3ClusterFileWithSamples(Number(id)), getS3BucketItem(Number(bucketId))])
			.then(([clusterResponse, bucketResponse]) => {
				setCluster(clusterResponse);
				setBucket(bucketResponse);
			})
			.finally(() => {
				setLoading(false);
			});
	}, [id, bucketId]);

	return (
		<Preloader isLoading={isLoading}>
			{cluster && bucket ? (
				<S3BucketClusterView cluster={cluster} bucket={bucket} />
			) : (
				<WashedCluster
					state={history.location.state as WashedClusterProps['state']}
					id={bucketId}
				/>
			)}
		</Preloader>
	);
}

export { S3BucketClusterViewWrapper as S3BucketClusterView };
