import { useStore } from 'effector-react';
import { useCallback, useEffect, useMemo } from 'react';
import MultiSelect from 'components/form/MultiSelect';
import DropdownButton from 'components/form/Select/DropdownButton';
import { AssetJson } from 'models/assets/dto';
import { getAssetsFx } from 'models/assets/effects';
import { assetsList } from 'models/assets/store';
import { getGatewaysFx } from 'models/gateways/effects';
import { gatewaysNamesById } from 'models/gateways/store';
import { onceFx } from 'models/modelUtils/onceFx';
import { FilterPropsBase } from '../model';

type Option = { name: string; id: AssetJson['cluster_id'] };

type Props = FilterPropsBase & {
	value: AssetJson['cluster_id'][];
	onChange: (newValue: AssetJson['cluster_id'][]) => void;
	assetType?: 'internal' | 'external';
};

function ClusterFilters({
	value,
	fixed,
	onChange,
	assetType,
	onClose,
	resetFilter,
	defaultOpen,
}: Props) {
	const gatewaysNamesStore = useStore(gatewaysNamesById);
	const assetsListStore = useStore(assetsList);

	useEffect(() => {
		onceFx(getAssetsFx);
		onceFx(getGatewaysFx);
	}, []);

	const groupedValues = useMemo(() => {
		const result: {
			[key: string]: Option;
		} = {};

		for (const asset of assetsListStore) {
			if (
				(assetType === 'internal' && asset.is_external) ||
				(assetType === 'external' && !asset.is_external) ||
				asset.cluster_id === 0
			) {
				continue;
			}
			const gatewayName = gatewaysNamesStore[asset.cluster_id];

			result[gatewayName] = result[gatewayName] || {
				name: gatewayName,
				id: asset.cluster_id,
			};
		}

		return Object.values(result).sort((a, b) => a.name.localeCompare(b.name));
	}, [assetsListStore]);

	const values = useMemo(() => {
		return groupedValues.filter((_value) => value.includes(_value.id));
	}, [value, groupedValues]);

	const handleOnChange = (options: Option[]) => {
		onChange(options.map((option) => option.id));
	};

	const dropdownButton = useCallback(
		(dropdownProps) => <DropdownButton {...dropdownProps} fixed={fixed} onClose={resetFilter} />,
		[fixed, resetFilter]
	);

	return (
		<MultiSelect
			defaultOpen={defaultOpen}
			hasSearch
			hasApplyButton
			options={groupedValues}
			value={values}
			onChange={handleOnChange}
			label={{ primary: 'Clusters', secondary: `${values.length || ''}` }}
			onClose={onClose}
			render={{ dropdownButton }}
		/>
	);
}

export default ClusterFilters;
