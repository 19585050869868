import { PIIMarkJson, PIIMarksJson } from './dto';

const piiMarkJsonExampleFP: PIIMarkJson = {
	detected_data_type: 6,
	direction: 'request',
	endpoint_id: 24839751,
	json_path: '$.billingAddress.city',
	manual_data_type: 0,
	id: 240,
};

const piiMarkJsonExampleFN: PIIMarkJson = {
	detected_data_type: 0,
	direction: 'request',
	endpoint_id: 24839751,
	json_path: '$.amount',
	manual_data_type: 1,
	id: 241,
};

const piiMarksJsonExample: PIIMarksJson = {
	pii_marks: [piiMarkJsonExampleFP, piiMarkJsonExampleFN],
	total: 2,
};

export { piiMarkJsonExampleFP, piiMarkJsonExampleFN, piiMarksJsonExample };
