import { Accordion as UiAccordion, AccordionProps } from '@material-ui/core';
import cn from 'classnames';
import styles from './index.module.pcss';

interface IAccordionProps extends AccordionProps {
	isDivider?: boolean;
}

function Accordion({ classes, isDivider = true, ...props }: IAccordionProps) {
	return (
		<UiAccordion
			classes={{
				root: cn(styles.root, classes?.root, { [styles.isDivider]: isDivider }),
				expanded: cn(styles.expanded, classes?.expanded),
			}}
			{...props}
		>
			{props.children}
		</UiAccordion>
	);
}

export default Accordion;
