import { createStore } from 'effector';
import { AssetDetailJson, AssetJson, AssetsJson } from './dto';
import { createAssetFx, getAssetsFx, updateAssetFx } from './effects';

const assetsStore = createStore<AssetsJson>({
	assets: [],
	total: 0,
});

assetsStore.on(getAssetsFx.doneData, (state: AssetsJson, data: AssetsJson) => data);

assetsStore.on(
	[createAssetFx.doneData, updateAssetFx.doneData],
	(state: AssetsJson, data: AssetDetailJson) => {
		return { ...state, assets: state.assets.map((asset) => (asset.id === data.id ? data : asset)) };
	}
);

const assetsList = assetsStore.map(({ assets }) =>
	assets.sort((a, b) => a.name.localeCompare(b.name))
);

const assetsMap = assetsStore.map(({ assets }) => {
	return Object.fromEntries(assets.map((asset) => [asset.id, asset]));
});

const assetNamesMap = assetsStore.map(({ assets }) => {
	return Object.fromEntries(assets.map((asset) => [asset.id, asset.name]));
});

const assetsLoaded = assetsStore.map((assets, oldAssets) => {
	return oldAssets !== undefined;
}); // Magical

const assetIdsPromise = new Promise<AssetJson['id'][]>(function (resolve) {
	const unwatch = assetsLoaded.watch((isLoaded) => {
		if (!isLoaded) return;

		resolve(assetsList.getState().map((asset) => asset.id));
		setTimeout(unwatch); // Watcher called immediately, can throw if unwatch isn't inside timeout
	});
});

const assetNamesPromise = new Promise<AssetJson['name'][]>(function (resolve) {
	const unwatch = assetsLoaded.watch((isLoaded) => {
		if (!isLoaded) return;

		resolve([...new Set(assetsList.getState().map((asset) => asset.name))]);
		setTimeout(unwatch); // Watcher called immediately, can throw if unwatch isn't inside timeout
	});
});

const assetNamespacesPromise = new Promise<AssetJson['namespace'][]>(function (resolve) {
	const unwatch = assetsLoaded.watch((isLoaded) => {
		if (!isLoaded) return;

		resolve([...new Set(assetsList.getState().map((asset) => asset.namespace))]);
		setTimeout(unwatch); // Watcher called immediately, can throw if unwatch isn't inside timeout
	});
});

export {
	assetsList,
	assetsMap,
	assetNamesMap,
	assetIdsPromise,
	assetNamesPromise,
	assetNamespacesPromise,
};
