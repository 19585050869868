import { createEffect } from 'effector';
import { getCounters } from './api';

let ac = new AbortController();

const getCountersFx = createEffect((onlyWithPii: boolean) => {
	// Max one request at a time. Since API is parametrized - just cancel previous request(s).
	ac.abort();
	ac = new AbortController();

	return getCounters(onlyWithPii, ac.signal);
});

export { getCountersFx };
