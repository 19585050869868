import { del, get, post } from 'api/Api';
import checkResponse from 'services/api/checkResponse';
import { AssetDetailJson, AssetsJson } from './dto';
import {
	assetsJsonExample,
	externalAssetIP,
	externalAssetUserAgent,
	internalAsset,
} from './examples';

// import { assetsToApi } from './generator';

async function getAssets() {
	return get<AssetsJson>('v2/assets')
		.then((data) => {
			return data;

			// DATA-GENERATOR
			// return {
			// 	...data,
			// 	assets: [...data.assets, ...assetsToApi()],
			// };
		})
		.then(checkResponse(assetsJsonExample));
}

async function getAssetById(id: AssetDetailJson['id']) {
	return get<AssetDetailJson>(`v2/assets/${id}`).then(
		checkResponse(internalAsset, externalAssetIP)
	);
}

async function createAsset(payload: AssetDetailJson) {
	return post<AssetDetailJson>('v2/assets', payload)
		.then((response) => ({
			// @ts-ignore
			cluster_id: 0,
			...response,
		}))
		.then(checkResponse(externalAssetIP, externalAssetUserAgent));
}

async function updateAsset(payload: AssetDetailJson) {
	return post<AssetDetailJson>(`v2/assets/${payload.id}`, payload).then(
		checkResponse(internalAsset, externalAssetIP)
	);
}

async function deleteAsset(id: AssetDetailJson['id']) {
	return del(`v2/assets/${id}`).then(() => id);
}

export { getAssets, getAssetById, createAsset, updateAsset, deleteAsset };
