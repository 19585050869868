//
// Given array of rectangles R(1)...R(n) sorted by 'importance' (e.g. size), place them one by one on grid, starting from
// top left, so that each placement is 'optimal'.
//
// For each rectangle R(i) in turn, all of its possible placements (that do not intersect with previous
// placements of R(1),R(2),...R(i-1)) are evaluated, and the best is chosen by some scoring function. Then,
// placements for R(i+1) are evaluated, and so on.
//
// Currently, scoring function prefers the smallest square that would contain the packing.
//

type Rect = {
	x: number;
	y: number;
	width: number;
	height: number;
};

function packRectangles<R extends Rect>(rectangles: R[]) {
	const currentPack: R[] = [];
	let maxDimensions = 0;
	const segmentCache: Set<Rect>[][] = [];
	const jumpCache: Map<number, number>[] = [];

	for (const rect of rectangles) {
		let bestCandidate: R | null = null;
		let bestScore = Infinity;

		// `x <= maxDimensions` and not `x < maxDimensions` because we add 1-square padding to
		// try placing to the right / bottom of existing pack.
		outer_loop: for (let x = 0; x <= maxDimensions; x++) {
			jumpCache[x] = jumpCache[x] || new Map();
			const jumpCacheX = jumpCache[x];

			for (let y = 0; y <= maxDimensions; y++) {
				const jump = jumpCacheX.get(y) || 0;
				if (jump > 0) {
					y = jump - 1; // -1 because `continue` will increment by 1
					continue;
				}

				const lastInterferringY = tryToAddRectangle(segmentCache, rect, x, y);

				if (lastInterferringY !== -1) {
					y = lastInterferringY - 1; // -1 because `continue` will increment by 1
					continue;
				}

				const candidateScore = Math.max(x + rect.width, y + rect.height, maxDimensions);

				if (candidateScore < bestScore) {
					bestCandidate = { ...rect, x, y };
					bestScore = candidateScore;
				}

				if (candidateScore === maxDimensions) {
					break outer_loop;
				}
			}
		}

		if (bestCandidate === null) {
			throw new Error('Packing algorithm bug: no candidates found');
		}

		maxDimensions = bestScore;
		addToSegmentCache(segmentCache, bestCandidate);
		addToJumpCache(jumpCache, bestCandidate);

		currentPack.push(bestCandidate);
	}

	return currentPack;
}

const SEGMENT_SIZE = 12; // This is an IMPORTANT optimization parameter
function addToSegmentCache(segmentCache: Set<Rect>[][], rect: Rect) {
	const { x, y, width, height } = rect;

	const startX = Math.floor(x / SEGMENT_SIZE);
	const endX = Math.ceil((x + width) / SEGMENT_SIZE);
	const startY = Math.floor(y / SEGMENT_SIZE);
	const endY = Math.ceil((y + height) / SEGMENT_SIZE);

	for (let xx = startX; xx <= endX; xx++) {
		segmentCache[xx] = segmentCache[xx] || new Array();
		for (let yy = startY; yy <= endY; yy++) {
			segmentCache[xx][yy] = segmentCache[xx][yy] || new Set();
			segmentCache[xx][yy].add(rect);
		}
	}
}

function addToJumpCache(jumpCache: Map<number, number>[], rect: Rect) {
	const { x, y, width, height } = rect;
	for (let xx = x; xx < x + width; xx++) {
		jumpCache[xx] = jumpCache[xx] || new Map();
		jumpCache[xx].set(y, y + height);
	}
}

// Rectangles are allowed to share borders
function rectanglesIntersect<R extends Rect>(
	rect1: R,
	rect2x: number,
	rect2y: number,
	rect2width: number,
	rect2height: number
) {
	const ax1 = rect1.x;
	const ax2 = rect1.x + rect1.width;
	const ay1 = rect1.y;
	const ay2 = rect1.y + rect1.height;

	const bx1 = rect2x;
	const bx2 = rect2x + rect2width;
	const by1 = rect2y;
	const by2 = rect2y + rect2height;

	return bx2 > ax1 && bx1 < ax2 && by2 > ay1 && by1 < ay2;
}

function tryToAddRectangle<R extends Rect>(
	segmentCache: Set<Rect>[][],
	rect: R,
	x: number,
	y: number
): number {
	const { width, height } = rect;

	const startX = Math.floor(x / SEGMENT_SIZE);
	const endX = Math.ceil((x + width) / SEGMENT_SIZE);
	const startY = Math.floor(y / SEGMENT_SIZE);
	const endY = Math.ceil((y + height) / SEGMENT_SIZE);

	for (let xx = startX; xx <= endX; xx++) {
		const segmentX = segmentCache[xx];
		if (!segmentX) continue;

		for (let yy = startY; yy <= endY; yy++) {
			const segmentXY = segmentX[yy];
			if (!segmentXY) continue;

			for (const originalRect of segmentXY) {
				if (rectanglesIntersect(originalRect, x, y, width, height))
					return originalRect.y + originalRect.height;
			}
		}
	}

	return -1;
}

export default packRectangles;
