import Button from 'components/form/Button';
import styles from './index.module.css';

type ApplyButtonProps = {
	onClick: () => void;
};

function ApplyButton(props: ApplyButtonProps) {
	const { onClick } = props;

	return (
		<Button
			data-test="apply-button"
			className={styles.resetButton}
			size="extraSmall"
			onClick={onClick}
			color="tertiary"
		>
			Apply
		</Button>
	);
}

export default ApplyButton;
export type { ApplyButtonProps };
