import cn from 'classnames';
import { ReactNode, useEffect, useRef } from 'react';
import { ListChildComponentProps } from 'react-window';
import MarkList from 'views/SampleV2/JsonView/MarkList';
import { ViewerSize } from '../../';
import styles from './index.module.css';
interface Props extends ListChildComponentProps {
	children: ReactNode;
	size?: ViewerSize;
}

function LineWrapper({ index, style, data, children, size = 'M' }: Props) {
	const rowRef = useRef<HTMLDivElement>(null);
	const { setRowHeight, viewWidth, activeLine, onLineClick, activeLines, list } = data;
	const { lineNumber, detectionsForMarks } = list[index];

	useEffect(() => {
		if (rowRef.current) {
			setRowHeight(index, rowRef.current.clientHeight);
		}
	}, [rowRef, viewWidth]);

	return (
		<div style={style} className={styles[size]}>
			<div
				ref={rowRef}
				className={cn(
					{ [styles.active]: activeLine == lineNumber || activeLines.includes(lineNumber) },
					styles.row
				)}
				onClick={() => onLineClick(lineNumber)}
			>
				<span className={styles.lineNumber}>{lineNumber}</span>

				<MarkList list={detectionsForMarks} size={size} />

				{children}
			</div>
		</div>
	);
}

export { LineWrapper };
