import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Button from 'components/form/Button';
import { PATHS } from 'services/router';
import styles from './index.module.css';

export const Footer = ({
	isNew = false,
	isError = false,
	saveForm,
}: {
	isNew: boolean;
	isError: boolean;
	saveForm: () => void;
}) => {
	const [isLoading, setIsLoading] = useState(false);
	const history = useHistory();

	return (
		<div className={styles.container}>
			<Button
				data-test="group-cancel-button"
				onClick={() => history.push(PATHS.ASSET_GROUP_LIST)}
				color="secondary"
				size="medium"
			>
				Cancel
			</Button>

			<Button
				data-test="group-create-save-button"
				type="submit"
				color="primary"
				size="medium"
				onClick={async () => {
					setIsLoading(true);
					await saveForm();
					setIsLoading(false);
				}}
				disabled={isLoading || isError}
			>
				{isNew ? 'Create' : 'Save'}
			</Button>
		</div>
	);
};
