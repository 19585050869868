import { useState } from 'react';
import { useForm } from 'react-hook-form';
import Alert, { AlertSeverity } from 'components/Alert';
import Button from 'components/form/Button';
import PasswordField from 'components/form/PasswordField';
import { enqueueSnackbar } from 'components/Snackbar';
import Typo from 'components/typography/Typo';
import { accountSteps } from 'layouts/AuthorizedWithLeftMenu/Breadcrumbs';
import Header from 'layouts/AuthorizedWithLeftMenu/Header';
import { changePasswordFx } from 'models/session/effects';
import { APIError } from 'services/api/httpRequest';
import styles from './index.module.css';

interface FormValues {
	old_password: string;
	new_password: string;
}

const INITIAL_VALUES = { old_password: '', new_password: '' };

function ChangePassword() {
	const { control, handleSubmit } = useForm<FormValues>({
		defaultValues: INITIAL_VALUES,
		mode: 'onBlur', // "onChange"
	});

	const [isLoading, setLoading] = useState(false);
	const [serverError, setServerError] = useState<string | null>(null);

	async function onSubmit({ old_password, new_password }: FormValues) {
		setLoading(true);

		try {
			await changePasswordFx({ old_password, new_password });
			enqueueSnackbar('Password changed successfully');
		} catch (error) {
			if (error instanceof APIError && error.response.status === 422) {
				const { message } = await error.response.json();
				setServerError(message);
			} else {
				throw error;
			}
		}

		setLoading(false);
	}

	return (
		<>
			<Header
				breadcrumbProps={{
					steps: accountSteps,
					finalStep: 'Password change',
				}}
				titleBlock={
					<div className={styles.headerContainer}>
						<Typo variant="D/Medium/H400-Page-Title">Password change</Typo>

						<Typo variant="D/Regular/Body-S" color="secondary">
							Create a new password for your account
						</Typo>
					</div>
				}
			/>

			<form
				onSubmit={handleSubmit(onSubmit)}
				className={styles.form}
				data-test="change-password-form"
			>
				<PasswordField
					control={control}
					label="Current password"
					name="old_password"
					autoComplete="current-password"
					required
					fullWidth
					data-test="change-password-current"
				/>

				<PasswordField
					control={control}
					label="New password"
					name="new_password"
					autoComplete="password"
					required
					fullWidth
					data-test="change-password-new"
				/>

				<Button
					type="submit"
					loading={isLoading}
					data-test="change-password-submit-button"
					className={styles.formButton}
				>
					Submit
				</Button>

				{serverError && !isLoading && (
					<Alert
						className={styles.errorMessage}
						severity={AlertSeverity.error}
						data-test="change-password-alert"
					>
						{serverError}
					</Alert>
				)}
			</form>
		</>
	);
}

export default ChangePassword;
