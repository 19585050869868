import { Cube, RagsAndCubesTree } from '../../index';
import { isPseudoRag } from '../../utils/calculateAbsoluteDimensions';

//
// Declaration of overloaded function
//
/* eslint-disable no-redeclare */
function aggregateRags(
	ragsAndCubes: RagsAndCubesTree,
	extractValueFn: (cube: Cube) => boolean
): Map<number, boolean | undefined>;
function aggregateRags<Agg>(
	ragsAndCubes: RagsAndCubesTree,
	extractValueFn: (cube: Cube) => Agg,
	aggregateValueFn: (acc: Agg, current: Agg) => Agg
): Map<number, Agg | undefined>;

//
// Implementation
//
function aggregateRags<Agg>(
	ragsAndCubes: RagsAndCubesTree,
	extractValueFn: (cube: Cube) => Agg,
	aggregateValueFn?: (acc: Agg, current: Agg) => Agg
): Map<number, Agg | undefined> {
	// For booleans, we can omit aggregator function for simplicity. Also, handle empty children/undefined result here.
	function aggregateFn(acc: Agg | undefined, current: Agg | undefined) {
		if (acc === undefined) return current;
		if (current === undefined) return acc;
		return aggregateValueFn ? aggregateValueFn(acc, current) : acc || current;
	}

	const result = new Map<number, Agg | undefined>();

	function traverse(rag: RagsAndCubesTree) {
		let ragResult: Agg | undefined;

		if (isPseudoRag(rag)) {
			ragResult = rag.data.reduce(
				(acc: Agg | undefined, child) => aggregateFn(acc, extractValueFn(child)),
				undefined
			);
		} else {
			ragResult = rag.children.reduce(
				(acc: Agg | undefined, child) => aggregateFn(acc, traverse(child)),
				undefined
			);
		}

		result.set(rag.id, ragResult);
		return ragResult;
	}

	traverse(ragsAndCubes);

	return result;
}

export default aggregateRags;
