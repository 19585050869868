import cn from 'classnames';
import { ReactNode } from 'react';
import styles from './index.module.pcss';

interface Props {
	children: ReactNode;
	className?: string;
}

function GridHead({ children, className }: Props) {
	return <div className={cn(className, styles.head)}>{children}</div>;
}

export default GridHead;
