import { IHeadCell } from 'components/table/EnhancedTableHead';
import { KafkaInstanceItem } from 'models/kafkaInstances/dto';

const tableConfig: IHeadCell<KafkaInstanceItem>[] = [
	{
		id: 'name',
		numeric: false,
		label: 'Name',
		sortable: true,
	},
	{
		id: 'region',
		numeric: false,
		label: 'Region',
		sortable: true,
	},
	{
		id: 'topics_count',
		numeric: true,
		label: 'Topics',
		sortable: true,
	},
	{
		id: 'messages_count',
		numeric: true,
		label: 'Messages',
		sortable: true,
	},
	{
		id: 'has_tls',
		numeric: false,
		label: 'TLS',
		sortable: true,
	},
	{
		id: 'sensitivity',
		numeric: false,
		label: 'Sensitivity',
		sortable: true,
	},
	{
		id: 'data_types',
		numeric: false,
		label: 'Data types',
		sortable: false,
	},
];

export default tableConfig;
