import { get } from 'api/Api';
import { ApiParams } from 'services/api';
import checkResponse from 'services/api/checkResponse';
import { S3ClusterList, S3ClusterSublist, S3ClusterFileWithSamples } from './dto';
import {
	s3ClusterListExample,
	s3ClusterSublistExample,
	s3ClusterFileWithSamplesExample,
} from './examples';

async function getS3Clusters(id: number, apiParams: ApiParams, signal?: AbortSignal) {
	const config = signal ? { signal } : {};

	return get<S3ClusterList>(`v1/s3/buckets/${id}/top-clusters`, apiParams, config).then(
		checkResponse(s3ClusterListExample)
	);
}

async function getS3ClusterFiles(
	bucketId: number,
	clusterId: number,
	apiParams: ApiParams,
	signal?: AbortSignal
) {
	const config = signal ? { signal } : {};

	return get<S3ClusterSublist>(
		`v1/s3/buckets/${bucketId}/sub-clusters/${clusterId}`,
		apiParams,
		config
	).then(checkResponse(s3ClusterSublistExample));
}

async function getS3ClusterFileWithSamples(clusterId: number, signal?: AbortSignal) {
	const config = signal ? { signal } : {};

	return get<S3ClusterFileWithSamples>(`v1/s3/clusters/${clusterId}`, undefined, config).then(
		checkResponse(s3ClusterFileWithSamplesExample)
	);
}

export { getS3Clusters, getS3ClusterFiles, getS3ClusterFileWithSamples };
