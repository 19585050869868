import { CircularProgress, CircularProgressProps } from '@material-ui/core';
import styles from './index.module.css';

enum LoadState {
	ready = 'ready',
	loading = 'loading',
	loaded = 'loaded',
	error = 'error',
}

function Loader(props: CircularProgressProps) {
	return <CircularProgress classes={{ colorPrimary: styles.colorPrimary }} {...props} />;
}

export { LoadState };
export default Loader;
