import cn from 'classnames';
import { ChangeEventHandler, ReactNode } from 'react';
import Label from 'components/Label';
import styles from './index.module.css';

interface BaseProps {
	checked?: boolean;
	className?: string;
	dataTest?: string;
	disabled?: boolean;
	error?: boolean;
	name?: string;
	onChange?: ChangeEventHandler<HTMLInputElement>;
	size?: 'M' | 'S' | 'XS';
	value?: string;
	halfPressed?: boolean;
}

interface Props extends BaseProps {
	label?: ReactNode;
}

function Checkbox(props: Readonly<Props>) {
	const { className, disabled, dataTest, label, size = 'S', ...rest } = props;

	return (
		<Label
			className={cn(className, { [styles.disabled]: disabled })}
			dataTest={dataTest ?? 'checkbox-container'}
			size={size}
		>
			<CheckboxBase disabled={disabled} size={size} {...rest} />
			{label}
		</Label>
	);
}

function CheckboxBase(props: Readonly<BaseProps>) {
	const { className, disabled, dataTest, error, halfPressed, size = 'S', ...rest } = props;

	return (
		<span
			className={cn(styles.container, styles[size], className)}
			data-test={dataTest ?? 'checkbox-container'}
		>
			<input
				type="checkbox"
				className={styles.nativeInput}
				{...rest}
				disabled={disabled}
				readOnly={!props.onChange}
			/>
			<span
				className={cn({ [styles.error]: error, [styles.halfPressed]: halfPressed }, styles.input)}
			/>
		</span>
	);
}

export { CheckboxBase };
export default Checkbox;
