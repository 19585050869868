import { createEffect } from 'effector';
import { getS3Regions } from './api';
import { S3RegionList } from './dto';

/* API */

export const getS3RegionsFx = createEffect<void, S3RegionList>();
/* IMPLEMENTATION */

// Simple direct-to-api effects

getS3RegionsFx.use(getS3Regions);
