import { useStore } from 'effector-react';
import { useCallback, useState } from 'react';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import Button from 'components/form/Button';
import TextField from 'components/form/TextField';
import Preloader from 'components/Preloader';
import Radio from 'components/Radio/index';
import { SecretToken } from 'components/SecretToken';
import { enqueueSnackbar } from 'components/Snackbar';
import ExternalLink from 'components/typography/ExternalLink';
import Typo from 'components/typography/Typo';
import Header from 'layouts/AuthorizedWithLeftMenu/Header';
import { GatewayJson } from 'models/gateways/dto';
import { createGatewayFx } from 'models/gateways/effects';
import { gatewaysNames } from 'models/gateways/store';
import { SensorDARItem } from 'models/sensors/dar/dto';
import { createSensorDARFx } from 'models/sensors/dar/effects';
import { sensorsDARNames } from 'models/sensors/dar/store';
import { APIError } from 'services/api/httpRequest';
import { goBackByDefault } from 'services/history';
import { PATHS } from 'services/router';
import styles from './index.module.css';
import { RegionSelect } from './RegionSelect';

function CreateSensor() {
	const existingDIMNames = useStore(gatewaysNames);
	const existingDARNames = useStore(sensorsDARNames);

	const history = useHistory();
	const location = useLocation();

	const [token, setToken] = useState<GatewayJson['token']>('');
	const [name, setName] = useState<string>('');
	const [region, setRegion] = useState('');
	const [loading, setLoading] = useState(false);

	const isDIM = location.pathname === PATHS.SENSORS_DIM_CREATE;

	const steps = isDIM
		? [
				{ path: PATHS.SENSORS, name: 'Infrastructure access' },
				{ path: PATHS.SENSORS_DIM, name: 'Data-in-motion' },
		  ]
		: [
				{ path: PATHS.SENSORS, name: 'Infrastructure access' },
				{ path: PATHS.SENSORS_DAR, name: 'Data-at-rest' },
		  ];

	function goToDIM() {
		history.replace(PATHS.SENSORS_DIM_CREATE);
	}

	function goToDAR() {
		history.replace(PATHS.SENSORS_DAR_CREATE);
	}

	const createSuccess = useCallback((agent: GatewayJson | SensorDARItem) => {
		setToken(agent.token);
		enqueueSnackbar('New Sensor successfully created');
	}, []);

	async function onCreate() {
		setLoading(true);

		try {
			if (isDIM) {
				await createGatewayFx({ name, region }).then(createSuccess);
			} else {
				await createSensorDARFx({ name, region }).then(createSuccess);
			}
		} catch (error) {
			if (error instanceof APIError && error.response.status === 400) {
				const { message } = await error.response.json();
				enqueueSnackbar(message);

				return;
			}

			throw error;
		} finally {
			setLoading(false);
		}
	}

	const error = (isDIM ? existingDIMNames : existingDARNames).has(name);

	const creationStep = (
		<>
			<Typo variant="D/Medium/H100-Header">What type of Sensor you want to create?</Typo>

			<div className={styles.radioGroup}>
				<Radio
					label="Data-in-motion Sensor"
					name="sensorType"
					value="DIM"
					onChange={goToDIM}
					checked={isDIM}
				/>

				<Radio
					label="Data-at-rest Sensor"
					name="sensorType"
					value="DAR"
					onChange={goToDAR}
					checked={!isDIM}
				/>
			</div>

			<Typo variant="D/Medium/H100-Header">Name the new Soveren Sensor</Typo>

			<Typo variant="D/Regular/Body-S" className={styles.description}>
				Choose a name for the new Sensor. The name should be descriptive enough for you to recognize
				it if you have many of them.
			</Typo>

			<TextField
				data-test="create-gateway-name-input"
				fullWidth
				autoFocus
				label="Sensor name"
				value={name}
				onChange={(e) => setName(e.target.value)}
				error={error}
				helperText={error ? 'Name already exists' : ' '}
				required
			/>

			<RegionSelect value={region} onChange={setRegion} />

			<div className={styles.buttons}>
				<Button
					color="secondary"
					onClick={() => goBackByDefault(PATHS.SENSORS)}
					data-test="create-gateway-cancel-button"
				>
					Cancel
				</Button>

				<Button
					disabled={error || !name}
					onClick={onCreate}
					data-test="create-gateway-create-button"
				>
					Create
				</Button>
			</div>
		</>
	);

	const finalStep = (
		<>
			<Typo variant="D/Medium/H100-Header">Here is the installation token</Typo>

			<Typo variant="D/Regular/Body-S" className={styles.description}>
				You can always find it later in the list of Sensors.
			</Typo>

			<SecretToken canBeCopied canBeHidden={false} value={token} fullWidth />

			<div className={styles.guide}>
				<Typo variant="D/Medium/H100-Header">And installation guide</Typo>

				<Typo variant="D/Regular/Body-S" className={styles.description}>
					Read our <ExternalLink href="https://docs.soveren.io/">documentation</ExternalLink> or
					contact us at <a href="mailto:support@soveren.io">support@soveren.io.</a>
				</Typo>
			</div>

			<div className={styles.buttons}>
				<Button onClick={() => goBackByDefault(PATHS.SENSORS)} data-test="create-gateway-ok-button">
					Got it
				</Button>
			</div>
		</>
	);

	return (
		<div className={styles.container}>
			<Header breadcrumbProps={{ steps, finalStep: 'New Sensor' }} titleBlock="New Sensor" />

			<Preloader isLoading={loading}>{!token ? creationStep : finalStep}</Preloader>
		</div>
	);
}

export { CreateSensor };
