import { ButtonProps, default as UiButton } from '@material-ui/core/Button';
import cn from 'classnames';
import { ElementType, ForwardedRef, forwardRef } from 'react';
import Loader from 'components/Loader';
import styles from './index.module.pcss';

export interface IButtonProps extends Omit<ButtonProps, 'size' | 'color'> {
	component?: ElementType;
	color?: 'primary' | 'secondary' | 'tertiary' | 'transparent' | 'ghost';
	'data-test'?: string;
	size?: 'extraSmall' | 'small' | 'medium' | 'large';
	loading?: boolean;
	theme?: 'default' | 'danger';
	target?: string;
}

const LoaderSize = {
	large: 24,
	medium: 24,
	small: 20,
	extraSmall: 20,
};

const Button = forwardRef(
	(
		{
			className = '',
			color = 'primary',
			disabled = false,
			endIcon,
			loading = false,
			size = 'medium',
			startIcon,
			theme = 'default',
			...props
		}: IButtonProps,
		ref: ForwardedRef<HTMLButtonElement>
	) => {
		const resultClassName = cn(
			className,
			// commonStyles.skeletonCancel,
			styles[`${size}Size`],
			styles[color],
			styles[`${theme}Theme`],
			{
				[styles.loading]: loading,
			}
		);

		return (
			<UiButton
				ref={ref}
				endIcon={endIcon}
				classes={{
					endIcon: styles.endIcon,
					disabled: styles.disabled,
					// label: commonStyles.skeletonCancel,
					root: styles.root,
					startIcon: styles.startIcon,
				}}
				className={resultClassName}
				disabled={disabled || loading}
				startIcon={startIcon}
				{...props}
			>
				{loading ? (
					<Loader color="inherit" size={LoaderSize[size]} />
				) : (
					<>
						{endIcon && <span className={styles.compensation} />}
						{(startIcon || endIcon) && <span className={styles.compensation} />}
						{props.children}
						{(startIcon || endIcon) && <span className={styles.compensation} />}
						{startIcon && <span className={styles.compensation} />}
					</>
				)}
			</UiButton>
		);
	}
);
Button.displayName = 'Button';

export default Button;
