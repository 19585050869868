import { TOrder } from 'models/common/dto';
import { DataTypeRequest } from 'models/dataTypes/dto';
import { getDataTypesAsApiParam } from 'models/dataTypes/helpers';
import { createTableModel, Store } from 'models/modelUtils/tableModelFactory';
import { ApiParams } from 'services/api';
import { getAssetGroupDataFlows } from './api';
import { AssetGroupDataFlowTableItem } from './dto';

type AssetGroupDataFlowParams = {
	groupId: number;
	name: string;
	'pii-types': DataTypeRequest;
	sort: {
		orderBy: keyof AssetGroupDataFlowTableItem;
		order: TOrder;
	};
};

type AssetGroupDataFlowStore = Store<AssetGroupDataFlowTableItem, AssetGroupDataFlowParams>;

function fetchDataApi(apiParamsRaw: ApiParams) {
	const { groupId, 'pii-types': piiTypes, ...apiParams } = apiParamsRaw;

	apiParams['data-types'] = getDataTypesAsApiParam(piiTypes);

	return getAssetGroupDataFlows(Number(groupId), apiParams).then((payload) => {
		return {
			data: payload.dataflows,
			total: payload.total,
			total_filtered: payload.total_filtered,
		};
	});
}

function createAssetGroupDataFlowModel(
	initialParams: AssetGroupDataFlowParams,
	fetchFxOnInit: boolean = true
) {
	return createTableModel<AssetGroupDataFlowTableItem, AssetGroupDataFlowParams>(
		initialParams,
		fetchDataApi,
		fetchFxOnInit
	);
}

//
// Instantiate model with initial params, but do not fetch data from server.
//

const initialParams: AssetGroupDataFlowParams = {
	groupId: 0,
	name: '',
	'pii-types': [],
	sort: {
		orderBy: 'sensitivity',
		order: 'desc',
	},
};

const assetGroupDataFlowModel = createAssetGroupDataFlowModel(initialParams, false);

export { assetGroupDataFlowModel };
export type { AssetGroupDataFlowParams, AssetGroupDataFlowStore };
