import { ApiParams, JsonEncodable } from 'services/api';
import secureRequest from 'services/api/secureRequest';
import { Mockiato } from './mock/Mockiato';

const DEFAULT_LIMIT = 100;

interface IMockPromise {
	json: () => Promise<unknown>;
}

async function parseJSON<T>(response: Promise<Response> | Promise<IMockPromise>) {
	return (await response).json() as Promise<T>;
}

async function requestJSON(
	url: string,
	body?: JsonEncodable,
	params?: ApiParams,
	config?: RequestInit
) {
	const headers = body ? { 'Content-Type': 'application/json', ...config?.headers } : undefined;
	const resultBody = body ? JSON.stringify(body) : undefined;
	let mockBody = undefined;

	if (location.hostname === 'localhost') {
		const mock = Mockiato({ url, method: config?.method });

		if (mock.response) {
			return Promise.resolve({
				json: () => new Promise((res) => res(mock.response?.body)),
			});
		}

		if (mock.request) {
			mockBody = JSON.stringify(mock.request?.body);
		}
	}

	return secureRequest(url, params, {
		...config,
		body: mockBody || resultBody,
		headers,
	});
}

async function get<T>(url: string, params?: ApiParams, config?: RequestInit): Promise<T> {
	return parseJSON<T>(requestJSON(url, undefined, params, { ...config, method: 'GET' }));
}

async function post<T>(
	url: string,
	body?: JsonEncodable,
	params?: ApiParams,
	config?: RequestInit
): Promise<T> {
	return parseJSON<T>(requestJSON(url, body, params, { ...config, method: 'POST' }));
}

async function put<T>(
	url: string,
	body?: JsonEncodable,
	params?: ApiParams,
	config: RequestInit = {}
): Promise<T> {
	return parseJSON<T>(requestJSON(url, body, params, { ...config, method: 'PUT' }));
}

async function patch<T>(
	url: string,
	body?: JsonEncodable,
	params?: ApiParams,
	config: RequestInit = {}
): Promise<T> {
	return parseJSON<T>(requestJSON(url, body, params, { ...config, method: 'PATCH' }));
}

async function del(url: string, params?: ApiParams, config?: RequestInit): Promise<undefined> {
	await requestJSON(url, undefined, params, { ...config, method: 'DELETE' });
	return;
}

export { get, post, patch, put, del, requestJSON, DEFAULT_LIMIT };
