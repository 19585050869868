import { useState } from 'react';
import Button from 'components/form/Button';
import { enqueueSnackbar } from 'components/Snackbar';
import { AccountAWSTest } from 'models/sensors/aws/dto';
import { testAWSConfigurationFx } from 'models/sensors/aws/effects';
import { APIError } from 'services/api/httpRequest';
import { TestMessage } from 'views/common/TestMessage';
import styles from './index.module.css';

const initialStatus: AccountAWSTest = {
	connection_errors: [],
};

interface Props {
	onConfigurationTest: (testStatus: AccountAWSTest) => void;
	role_arn: string;
	id: number;
}

export function TestConfiguration({ id, role_arn, onConfigurationTest }: Readonly<Props>) {
	const [{ connection_errors }, setStatus] = useState<AccountAWSTest>(initialStatus);
	const [isLoading, setLoading] = useState(false);
	const [showMessage, setShowMessage] = useState(false);

	async function onTest() {
		setLoading(true);
		setShowMessage(false);
		setStatus(initialStatus);

		try {
			await testAWSConfigurationFx({ id, role_arn }).then((data) => {
				setStatus(data);
				setShowMessage(true);
				onConfigurationTest(data);
			});
		} catch (error) {
			if (error instanceof APIError) {
				const { status, json } = error.response;
				const { message } =
					status === 401 || status === 400 ? await json() : { message: 'Something went wrong' };
				enqueueSnackbar(message);
			} else {
				throw error;
			}
		} finally {
			setLoading(false);
		}
	}

	return (
		<div className={styles.containerTest}>
			<Button
				color="secondary"
				className={styles.button}
				loading={isLoading}
				onClick={onTest}
				data-test="aws-account-test-button"
			>
				Run test
			</Button>

			{showMessage && <TestMessage errorMessage={connection_errors.join(', ')} />}
		</div>
	);
}
