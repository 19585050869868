import { Switch as UISwitch } from '@mui/base/Switch';
import { ChangeEventHandler } from 'react';
import './index.module.pcss';

export interface SwitchProps {
	checked?: boolean;
	className?: string;
	disabled?: boolean;
	onChange?: ChangeEventHandler<HTMLInputElement>;
	dataTest?: string;
}

function Switch({ dataTest, ...rest }: SwitchProps) {
	return (
		<UISwitch
			// This is necessary if the Switch is in the clickable parent,
			// because stopPropagation isn't work with onChange event properly
			onClick={(e) => e.stopPropagation()}
			data-test={dataTest || 'switch-container'}
			{...rest}
		/>
	);
}

export default Switch;
