import { createEffect } from 'effector';
import dropSession from 'services/dropSession';
import {
	changePassword,
	connectAws,
	getSession,
	restorePassword,
	signIn,
	signOut,
	signUp,
	signUpAws,
	signUpWithToken,
} from './api';
import { SignUpAwsBody, SignUpBody, SignUpWithTokenBody } from './dto';

export const signInFx = createEffect(signIn);

export const changePasswordFx = createEffect(changePassword);

export const restorePasswordFx = createEffect(restorePassword);

export const signUpFx = createEffect(async (params: SignUpBody) => {
	await signUp(params);
	return await signIn(params);
});

export const signUpWithTokenFx = createEffect(async (params: SignUpWithTokenBody) => {
	return await signUpWithToken(params);
});

export const signOutFx = createEffect(async () => {
	await signOut();
	dropSession();
});

export const getSessionFx = createEffect(getSession);

export const signUpAwsFx = createEffect(async (params: SignUpAwsBody) => {
	await signUpAws(params);
	return await signIn({ login: params.login, password: params.password });
});

export const connectAwsFx = createEffect(async (params: SignUpAwsBody) => {
	await connectAws(params);
	return await signIn({ login: params.login, password: params.password });
});
