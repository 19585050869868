/*
	For more details: https://soveren.atlassian.net/wiki/spaces/FRO/pages/3018424350/Canvas+Cube+development+FAQ
 */

const SCALE_RATIO = 3;

export const CUBE_CONTAINER_SIZE = 240;
export const OFFSET_OF_CUBE_CENTER = 17;
export const CUBE_CENTER_W_OFFSET =
	(CUBE_CONTAINER_SIZE * SCALE_RATIO) / 2 + OFFSET_OF_CUBE_CENTER * SCALE_RATIO;

type StyleParamsLabel = {
	arrowOffset: {
		[key: string | 'defaultValue']: number;
	};

	maxWidth: number;
	height: number;

	fontSize: number;
	lineHeight: number;

	paddingTop: number;
	paddingRight: number;
	paddingBottom?: number;
	paddingLeft: number;
	borderRadius: number;

	iconSize: number;
	iconPadding: number;
};

export const labelsByScaleStepStyleConfig: { [scaleStep: number]: StyleParamsLabel } = {
	300: {
		arrowOffset: {
			external_namespace: -32,
			defaultValue: -6,
		},

		maxWidth: 170 * SCALE_RATIO,
		height: 25 * SCALE_RATIO,
		fontSize: 16 * SCALE_RATIO,
		lineHeight: 80,

		paddingTop: 5 * SCALE_RATIO,
		paddingRight: 2 * SCALE_RATIO,
		paddingLeft: 2 * SCALE_RATIO,
		borderRadius: 5 * SCALE_RATIO,

		iconSize: 23 * SCALE_RATIO,
		iconPadding: 2 * SCALE_RATIO,
	},
	250: {
		arrowOffset: {
			external_namespace: -35,
			defaultValue: -10,
		},

		maxWidth: 170 * SCALE_RATIO,
		height: 29 * SCALE_RATIO,
		fontSize: 20 * SCALE_RATIO,
		lineHeight: 80,

		paddingTop: 6 * SCALE_RATIO,
		paddingRight: 2 * SCALE_RATIO,
		paddingLeft: 2 * SCALE_RATIO,
		borderRadius: 4 * SCALE_RATIO,

		iconSize: 26 * SCALE_RATIO,
		iconPadding: 2 * SCALE_RATIO,
	},
	200: {
		arrowOffset: {
			external_namespace: -40,
			defaultValue: -15,
		},

		maxWidth: 170 * SCALE_RATIO,
		height: 35 * SCALE_RATIO,
		fontSize: 24 * SCALE_RATIO,
		lineHeight: 80,

		paddingTop: 8 * SCALE_RATIO,
		paddingRight: 4 * SCALE_RATIO,
		paddingLeft: 4 * SCALE_RATIO,
		borderRadius: 5 * SCALE_RATIO,

		iconSize: 32 * SCALE_RATIO,
		iconPadding: 2 * SCALE_RATIO,
	},
	150: {
		arrowOffset: {
			external_namespace: -45,
			defaultValue: -20,
		},

		maxWidth: 170 * SCALE_RATIO,
		height: 35 * SCALE_RATIO,
		fontSize: 26 * SCALE_RATIO,
		lineHeight: 80,

		paddingTop: 8 * SCALE_RATIO,
		paddingRight: 4 * SCALE_RATIO,
		paddingLeft: 4 * SCALE_RATIO,
		borderRadius: 6 * SCALE_RATIO,

		iconSize: 30 * SCALE_RATIO,
		iconPadding: 3 * SCALE_RATIO,
	},
};

type StyleParamsCube = {
	[key: string]: {
		width: number;
		height: number;
		// "margin" from x0y0
		top: number;
		left: number;

		// "margin-top" from x0y0
		labelTop: number;
	};
};
export const cubeByImageIdStylesConfig: StyleParamsCube = {
	// assets
	assetDefault: { width: 357, height: 359, top: 131, left: 230, labelTop: 120 },
	assetDefaultHovered: { width: 357, height: 359, top: 131, left: 230, labelTop: 120 },
	assetDefaultSelected: { width: 532, height: 414, top: 125, left: 142, labelTop: 120 },
	assetMedium: { width: 357, height: 359, top: 131, left: 230, labelTop: 120 },
	assetMediumHovered: { width: 357, height: 359, top: 131, left: 230, labelTop: 120 },
	assetMediumSelected: { width: 532, height: 414, top: 125, left: 142, labelTop: 120 },
	assetHigh: { width: 357, height: 359, top: 131, left: 230, labelTop: 120 },
	assetHighHovered: { width: 357, height: 359, top: 131, left: 230, labelTop: 120 },
	assetHighSelected: { width: 532, height: 414, top: 125, left: 142, labelTop: 120 },

	// honeycombs
	honeycombsDefault: { width: 493, height: 466, top: 50, left: 176, labelTop: 40 },
	honeycombsDefaultHovered: { width: 493, height: 466, top: 50, left: 176, labelTop: 40 },
	honeycombsDefaultSelected: { width: 676, height: 544, top: 43, left: 83, labelTop: 40 },
	honeycombsMedium: { width: 493, height: 466, top: 50, left: 176, labelTop: 40 },
	honeycombsMediumHovered: { width: 493, height: 466, top: 50, left: 176, labelTop: 40 },
	honeycombsMediumSelected: { width: 676, height: 544, top: 43, left: 83, labelTop: 40 },
	honeycombsHigh: { width: 493, height: 466, top: 50, left: 176, labelTop: 40 },
	honeycombsHighHovered: { width: 493, height: 466, top: 50, left: 176, labelTop: 40 },
	honeycombsHighSelected: { width: 676, height: 544, top: 43, left: 83, labelTop: 40 },

	// s3
	s3Default: { width: 339, height: 402, top: 130, left: 240, labelTop: 120 },
	s3DefaultHovered: { width: 339, height: 402, top: 130, left: 240, labelTop: 120 },
	s3DefaultSelected: { width: 423, height: 453, top: 124, left: 200, labelTop: 120 },
	s3Medium: { width: 339, height: 402, top: 130, left: 240, labelTop: 120 },
	s3MediumHovered: { width: 339, height: 402, top: 130, left: 240, labelTop: 120 },
	s3MediumSelected: { width: 423, height: 453, top: 124, left: 200, labelTop: 120 },
	s3High: { width: 339, height: 402, top: 130, left: 240, labelTop: 120 },
	s3HighHovered: { width: 339, height: 402, top: 130, left: 240, labelTop: 120 },
	s3HighSelected: { width: 423, height: 453, top: 124, left: 200, labelTop: 120 },

	// kafka
	kafkaDefault: { width: 402, height: 402, top: 130, left: 205, labelTop: 120 },
	kafkaDefaultHovered: { width: 402, height: 402, top: 130, left: 205, labelTop: 120 },
	kafkaDefaultSelected: { width: 432, height: 468, top: 124, left: 190, labelTop: 120 },
	kafkaMedium: { width: 402, height: 402, top: 130, left: 205, labelTop: 120 },
	kafkaMediumHovered: { width: 402, height: 402, top: 130, left: 205, labelTop: 120 },
	kafkaMediumSelected: { width: 432, height: 468, top: 124, left: 190, labelTop: 120 },
	kafkaHigh: { width: 402, height: 402, top: 130, left: 205, labelTop: 120 },
	kafkaHighHovered: { width: 402, height: 402, top: 130, left: 205, labelTop: 120 },
	kafkaHighSelected: { width: 432, height: 468, top: 124, left: 190, labelTop: 120 },

	// database
	databaseDefault: { width: 339, height: 402, top: 130, left: 240, labelTop: 120 },
	databaseDefaultHovered: { width: 339, height: 402, top: 130, left: 240, labelTop: 120 },
	databaseDefaultSelected: { width: 497, height: 451, top: 124, left: 161, labelTop: 120 },
	databaseMedium: { width: 339, height: 402, top: 130, left: 240, labelTop: 120 },
	databaseMediumHovered: { width: 339, height: 402, top: 130, left: 240, labelTop: 120 },
	databaseMediumSelected: { width: 497, height: 451, top: 124, left: 161, labelTop: 120 },
	databaseHigh: { width: 339, height: 402, top: 130, left: 240, labelTop: 120 },
	databaseHighHovered: { width: 339, height: 402, top: 130, left: 240, labelTop: 120 },
	databaseHighSelected: { width: 497, height: 451, top: 124, left: 161, labelTop: 120 },

	// vm
	vmDefault: { width: 348, height: 398, top: 130, left: 239, labelTop: 110 },
	vmDefaultHovered: { width: 348, height: 398, top: 130, left: 239, labelTop: 110 },
	vmDefaultSelected: { width: 579, height: 469, top: 123, left: 123, labelTop: 110 },
	vmMedium: { width: 348, height: 398, top: 130, left: 239, labelTop: 110 },
	vmMediumHovered: { width: 348, height: 398, top: 130, left: 239, labelTop: 110 },
	vmMediumSelected: { width: 579, height: 469, top: 123, left: 123, labelTop: 110 },
	vmHigh: { width: 348, height: 398, top: 130, left: 239, labelTop: 110 },
	vmHighHovered: { width: 348, height: 398, top: 130, left: 239, labelTop: 110 },
	vmHighSelected: { width: 579, height: 469, top: 123, left: 123, labelTop: 110 },
};
