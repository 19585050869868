import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Preloader from 'components/Preloader';
import { LayoutContext } from 'layouts/AuthorizedWithLeftMenu';
import Header from 'layouts/AuthorizedWithLeftMenu/Header';
import { getCustomDataTypeRules } from 'models/dataTypes/api';
import {
	CustomDataTypeRequest,
	CustomDataTypeRulesResponse,
	DataTypeResponse,
} from 'models/dataTypes/dto';
import { getDataTypesFx } from 'models/dataTypes/effects';
import { DataTypesForm } from './DataTypeForm';
import styles from './index.module.css';

type DataTypeData = CustomDataTypeRequest & CustomDataTypeRulesResponse & { id: number };

const EMPTY_DATA_TYPE: DataTypeData = {
	id: 0,
	name: '',
	alias: '',
	sensitivity: 'N/A',
	rules: [],
};

export function DataTypesItem() {
	const layoutBackground = useContext(LayoutContext);

	useEffect(function changeLayoutStyles() {
		layoutBackground.onChange({
			layout: styles.layoutBackground,
			content: styles.contentBorder,
		});

		return function resetLayoutStyles() {
			layoutBackground.reset();
		};
	}, []);

	const { id }: { id: string } = useParams();
	const [isLoading, setIsLoading] = useState(true);
	const [dataType, setDataType] = useState<DataTypeData>(EMPTY_DATA_TYPE);
	const [dtState, setDataTypeState] = useState<'new' | 'edit' | 'notExist'>();

	useEffect(
		function setDataTypeBy() {
			if (id === 'new') {
				setDataTypeState('new');
				setIsLoading(false);
				return;
			}

			const dataTypeIdNumber = Number(id) as DataTypeResponse['id'];

			if (Number.isNaN(dataTypeIdNumber)) {
				setDataTypeState('notExist');
				setIsLoading(false);
				return;
			}

			try {
				Promise.all([getDataTypesFx(), getCustomDataTypeRules(dataTypeIdNumber)]).then(
					function setDataTypeData([dataTypeList, { rules }]) {
						const dataTypeFromStore = dataTypeList.find(function findDataTypeById(dt) {
							return dt.id === dataTypeIdNumber;
						})!;

						setDataType({
							id: dataTypeFromStore.id,
							name: dataTypeFromStore.name,
							alias: dataTypeFromStore.alias,
							sensitivity: dataTypeFromStore.sensitivity,
							rules,
						});
						setDataTypeState('edit');
						setIsLoading(false);
					}
				);
			} catch (err) {
				setDataTypeState('notExist');
				setIsLoading(false);
			}
		},
		[id]
	);

	return (
		<>
			<Header isLoading={isLoading} />

			<Preloader isLoading={isLoading}>
				{dtState === 'new' && <DataTypesForm data={EMPTY_DATA_TYPE} />}
				{dtState === 'edit' && <DataTypesForm data={dataType} />}
				{dtState === 'notExist' && <div>Data type does not exist</div>}
			</Preloader>
		</>
	);
}

export type { DataTypeData };
