import { Redirect, Route, Switch } from 'react-router-dom';
import { PATHS } from 'services/router';

function OldDataCatalog() {
	return (
		<Switch>
			<RedirectWithQueryParams from={PATHS.SAMPLE} to={PATHS.SAMPLE_V2} />
			<Redirect from={PATHS.ASSET_ITEM} to={PATHS.SERVICE_ITEM} />
		</Switch>
	);
}

function RedirectWithQueryParams({ from, to }: { from: string; to: string }) {
	return (
		<Route
			path={from}
			component={({ location }: { location: Location }) => (
				<Redirect to={{ ...location, pathname: to }} />
			)}
		/>
	);
}
export default OldDataCatalog;
