import { useStore } from 'effector-react';
import { useLayoutEffect, useRef, useState } from 'react';
import Icon from 'components/Icon';
import Typo from 'components/typography/Typo';
import { toLocaleString } from 'services/numbers';
import styles from './index.module.pcss';
import { chartTooltipStore } from './model';

const TOOLTIP_POINTER_HEIGHT = 22;

function pluralEnding(n: number) {
	return n === 1 ? '' : 's';
}

function ChartTooltip() {
	const { visible, date, apiCalls, top, left } = useStore(chartTooltipStore);
	const [fixedTop, setFixedTop] = useState(0);
	const containerRef = useRef<HTMLDivElement>(null);

	useLayoutEffect(() => {
		setFixedTop(top - TOOLTIP_POINTER_HEIGHT);
	}, [top]);

	const opacity = visible ? 1 : 0;

	return (
		<div
			className={styles.container}
			style={{ top: fixedTop, left, opacity }}
			ref={containerRef}
			data-test="chart-tooltip"
		>
			<Typo color="secondary" variant="D/Regular/Meta">
				{date}
			</Typo>

			<div className={styles.content} data-test="api-calls">
				<Icon name="chartLine" size={18} />

				<Typo variant="D/Medium/Body-S" className={styles.contentText}>
					<span>{toLocaleString(apiCalls)}</span> API call
					{pluralEnding(apiCalls)}
				</Typo>
			</div>
		</div>
	);
}

export { ChartTooltip };
