import { S3CSVSample, S3Sample } from 'models/s3Sample/dto';

const s3CsvSampleExample: S3CSVSample = {
	id: 1,
	bucket_id: 1,
	bucket_name: 's3bucket-1',
	cluster_id: 1,
	cluster_name: 'some-[num].csv',
	path: 'asd/456/qwe/',
	name: 'some-232.csv',
	cloud_console_url: 'https://console.aws.amazon.com/s3/buckets/...',
	columns: [
		{
			column_index: 1,
			column_name: 'Name',
			data_fields: [
				{
					data_type: 1,
					value: '********',
				},
				{
					data_type: 4,
					value: '**********@*****.***',
				},
			],
			value: '********',
		},
		{
			column_index: 2,
			column_name: 'Test',
			data_fields: [
				{
					data_type: 5,
					value: '******** ********',
				},
				{
					data_type: 6,
					value: '********',
				},
			],
			value: '******** ********',
		},
	],
};

const s3JsonSampleExample: S3Sample = {
	id: 2,
	bucket_id: 1,
	bucket_name: 's3bucket-1',
	cluster_id: 1,
	cluster_name: 'some-[num].json',
	path: 'asd/456/qwe/',
	name: 'some-232.json',
	cloud_console_url: 'https://console.aws.amazon.com/s3/buckets/...',
	type: 'json',
	samples: [
		{
			name: '',
			sample: '{ "test": "some string" }',
			data_fields: [
				{
					locator_path: '$.test',
					locator_column: 0,
					locator_line: 0,
					data_type: 12,
				},
			],
		},
	],
};

const s3SampleLogExample: S3Sample = {
	id: 1,
	bucket_id: 15,
	bucket_name: 'hml-new-test-bucket',
	cluster_id: 115,
	cluster_name: 'some-log-[NUM].log',
	path: 'asd/456/qwe/',
	name: 'some-log-12303.ndjson',
	cloud_console_url: 'https://...',
	type: 'log',
	samples: [
		{
			name: '',
			sample: `{"accountVerification":false,"additionalDetails":{"operatorId":"********_**_111***","salesSystemId":"*-**-*1111-***1"},"createToken":{"declineDuplicates":false,"reusable":false},"paymentCard":{"expiryDate":{"expmonth":"11","expyear":"11"},"number":"1111111111111111"},"requestType":"*************************************"}
{"login":"****-***@*******.**","password":"********"}
{"client":{"company_id":1,"email":"****-***@*******.**","id":1,"role":"*****","second_factor_enabled":false,"skip_intro":false,"user_hash":"11111*11***111***1**11*11***11*111*111*1111111*1111*11*11**11111"}}
["****", "*********"]
107.189.10.196 - - [14/Feb/2022:03:48:55 +0000] "POST /HNAP1/ HTTP/1.1" 404 134 "-" "Mozila/5.0"
35.162.122.225 - - [14/Feb/2022:04:11:57 +0000] "GET /.env HTTP/1.1" 404 162 "-" "Mozilla/5.0 (Windows NT 10.0; Win64; x64; rv:58.0) Gecko/20100101 Firefox/58.0"
45.61.172.7 - - [14/Feb/2022:04:16:54 +0000] "GET /.env HTTP/1.1" 404 197 "-" "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/81.0.4044.129 Safari/537.36"
45.61.172.7 - - [14/Feb/2022:04:16:55 +0000] "POST / HTTP/1.1" 405 568 "-" "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/81.0.4044.129 Safari/537.36"
45.137.21.134 - - [14/Feb/2022:04:18:57 +0000] "GET /dispatch.asp?account=another@example.com&unsafe=1 HTTP/1.1" 404 134 "-" "Mozilla/5.0 (iPad; CPU OS 7_1_2 like Mac OS X; en-US) AppleWebKit/531.5.2 (KHTML, like Gecko) Version/4.0.5 Mobile/8B116 Safari/6531.5.2"
23.95.100.141 - - [14/Feb/2022:04:42:23 +0000] "HEAD / HTTP/1.0" 200 0 "-" "-"
217.138.222.101 - - [14/Feb/2022:07:38:40 +0000] "GET /icons/ubuntu-logo.png HTTP/1.1" 404 197 "http://168.119.119.25/" "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/98.0.4758.87 Safari/537.36"
217.138.222.101 - - [14/Feb/2022:07:38:42 +0000] "GET /favicon.ico HTTP/1.1" 404 197 "http://168.119.119.25/" "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/98.0.4758.87 Safari/537.36"
217.138.222.101 - - [14/Feb/2022:07:44:02 +0000] "GET / HTTP/1.1" 304 0 "-" "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/98.0.4758.87 Safari/537.36"
217.138.222.101 - - [14/Feb/2022:07:44:02 +0000] "GET /icons/ubuntu-logo.png HTTP/1.1" 404 197 "http://168.119.119.25/" "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/98.0.4758.87 Safari/537.36"`,
			data_fields: [
				{
					locator_path: '$.paymentCard.expiryDate.expmonth',
					locator_column: 0,
					locator_line: 0,
					data_type: 7,
				},
				{
					locator_path: '$.paymentCard.expiryDate.expyear',
					locator_column: 0,
					locator_line: 0,
					data_type: 7,
				},
				{
					locator_path: '$.paymentCard.number',
					locator_column: 0,
					locator_line: 0,
					data_type: 7,
				},
				{
					locator_path: '$.login',
					locator_column: 0,
					locator_line: 1,
					data_type: 4,
				},
				{
					locator_path: '$.client.email',
					locator_column: 0,
					locator_line: 2,
					data_type: 4,
				},
				{
					locator_path: '$[1]',
					locator_column: 0,
					locator_line: 3,
					data_type: 1,
				},
				{
					data_type: 11,
					locator_column: 10,
					locator_line: 6,
					locator_path: '10-20',
				},
			],
		},
	],
};

const s3SampleYamlExample: S3Sample = {
	cloud_console_url:
		'https://s3.console.aws.amazon.com/s3/object/yaml?prefix=configs%2Ffake-corrector.yaml\u0026region=us-west-1',
	bucket_id: 25,
	bucket_name: 'yaml',
	cluster_id: 102,
	cluster_name: 'fake-corrector.yaml',
	id: 53,
	name: 'fake-corrector.yaml',
	path: 'configs/',
	type: 'yaml',
	samples: [
		{
			name: '',
			sample:
				'{"apiVersion":"****/*1","kind":"**********","metadata":{"name":"*************"},"spec":{"replicas":1,"selector":{"matchLabels":{"app":"*************"}},"template":{"metadata":{"labels":{"app":"*************"}},"spec":{"containers":[{"env":[{"name":"*************_***_******","value":"1.1.1.1:1111"},{"name":"*************_*************_*******","value":"****"},{"name":"*************_*************_*****","value":"11**1*11-1**1-1111-*11*-11**11*11111"},{"name":"*************_******_***********","value":"[{\\"******\\":\\"*****\\",\\"***********\\":\\"******_********_*****\\",\\"*****\\":\\"****@*******.**\\"},{\\"******\\":\\"*****\\",\\"***********\\":\\"******_*******_*****\\",\\"*****\\":\\"*******@*******.**\\"},{\\"******\\":\\"****\\",\\"***********\\":\\"******_****\\",\\"*****\\":\\"1111111111111111\\"},{\\"******\\":\\"*****\\",\\"***********\\":\\"****\\",\\"*****\\":\\"****@*******.**\\"}]"}],"image":"****.**/*********/*************:******","imagePullPolicy":"******","name":"*************-******","ports":[{"containerPort":1111,"name":"****"}]}],"imagePullSecrets":[{"name":"****"}]}}}}\n{"apiVersion":"*1","kind":"*******","metadata":{"name":"*********-**"},"spec":{"ports":[{"name":"*************","port":1111,"targetPort":"****"}],"selector":{"app":"*************"}}}',
			data_fields: [
				{
					data_type: 4,
					locator_column: 0,
					locator_line: 0,
					locator_path: '$.spec.template.spec.containers[0].env[3].value',
				},
				{
					data_type: 4,
					locator_column: 0,
					locator_line: 1,
					locator_path: '$.spec.selector.app',
				},
				{
					data_type: 4,
					locator_column: 0,
					locator_line: 0,
					locator_path: '$.metadata.name',
				},
				{
					data_type: 4,
					locator_column: 0,
					locator_line: 1,
					locator_path: '$.metadata.name',
				},
			],
		},
	],
};

export { s3CsvSampleExample, s3JsonSampleExample, s3SampleLogExample, s3SampleYamlExample };
