import mock from './mock';

interface IMockiato {
	url: string;
	method?: RequestInit['method'];
}

interface IBody {
	body?: object | object[];
}

interface IReturn {
	request: IBody | undefined;
	response: IBody | undefined;
}

export const Mockiato = ({ url, method = 'GET' }: IMockiato): IReturn => {
	const foundMock = mock.filter(
		({ url: jsonUrl, method: jsonMethod }) => url.includes(jsonUrl) && method === jsonMethod
	);

	return {
		request: foundMock.find(({ direction }) => direction === 'request'),
		response: foundMock.find(({ direction }) => direction === 'response'),
	};
};
