type AssetType =
	| 'internal'
	| 'endusers'
	| 'external_namespace'
	| 'robots'
	| 'other_in'
	| 'other_out'
	| 'custom';

const assetTypeLabel: Record<AssetType, string> = {
	internal: 'Internal', // Most likely will not be used. For such assets we show its real name.
	endusers: 'End users',
	external_namespace: 'External namespace',
	robots: 'Robots',
	other_in: 'Other incoming',
	other_out: 'Other outgoing',
	custom: 'Custom',
};

const assetTypes = Object.keys(assetTypeLabel);

type AssetJson = {
	id: number;
	is_external: boolean;
	type: AssetType;
	name: string;
	namespace: string;
	k8s_types: never | string[];
	labels: { key: string; value: string }[];
	description: string;
	owner: string;
	created_at: number;
	updated_at: number;
	is_deleted: boolean;
	groups: number[];
	cluster_id: number;
};

type AssetRuleItem = {
	type: 'ip' | 'header';
	key: string;
	values: string[];
};

type AssetRulesGroup = AssetRuleItem[][];

type AssetDetailJson = {
	rules: AssetRulesGroup;
} & AssetJson;

type AssetsJson = {
	total: number;
	total_filtered?: number;
	assets: AssetJson[];
};

export type { AssetType, AssetJson, AssetsJson, AssetRuleItem, AssetRulesGroup, AssetDetailJson };
export { assetTypeLabel, assetTypes };
