import { useStore } from 'effector-react';
import { useEffect } from 'react';
import FilterLine from 'components/FilterLine';
import FilterResult from 'components/FilterResult';
import LoadMoreButton from 'components/LoadMoreButton';
import NewGatewayButton from 'components/NewGatewayBlock/NewGatewayButton';
import NoContent from 'components/NoContent';
import {
	clearPiiFilter,
	piiFilterToArray,
	piiFilterWithGroupsStore,
} from 'components/PiiGlobalFilterV2/model';
import Preloader from 'components/Preloader';
import ResetFilters from 'components/ResetFilters';
import EnhancedTableHead, { TOrder } from 'components/table/EnhancedTableHead';
import GridBody from 'components/table/GridBody';
import GridTable from 'components/table/GridTable';
import Typo from 'components/typography/Typo';
import { dataStoragesSteps } from 'layouts/AuthorizedWithLeftMenu/Breadcrumbs';
import Header from 'layouts/AuthorizedWithLeftMenu/Header';
import { S3BucketItem } from 'models/s3Buckets/dto';
import { s3BucketModel } from 'models/s3Buckets/model';
import { PageParamsConfig, usePageParams } from 'services/pageParams';
import { UniversalSearch } from 'views/common/UniversalSearch';
import DataStorage from 'views/DataStorages';
import tableConfig from './config';
import styles from './index.module.pcss';
import { S3BucketTableRow } from './S3BucketTableRow';

const pageConfig = {
	sort: {
		type: 'sort',
		persistence: 'session',
	},
	regions: {
		type: 'stringArray',
		persistence: 'session',
	},
	search: {
		type: 'string',
		persistence: 'session',
	},
	'data-types': {
		type: 'numberArray',
		persistence: 'session', // TODO implement 'global' in actuality
	},
	nonempty: {
		type: 'boolean',
		persistence: 'session', // TODO implement 'global' in actuality
	},
} satisfies PageParamsConfig;

function S3BucketsTable() {
	const state = useStore(s3BucketModel.store);
	const [pageParams, setPageParams] = usePageParams(pageConfig, 's3bucket');
	const piiFilterWithGroups = useStore(piiFilterWithGroupsStore);

	useEffect(() => {
		setPageParams({
			...pageParams,
			'data-types': piiFilterWithGroups.dataTypes,
			nonempty: piiFilterWithGroups.nonEmpty,
		});
	}, [piiFilterWithGroups]);

	useEffect(() => {
		const { regions, sort, search } = pageParams;

		const dataTypes = piiFilterToArray(piiFilterWithGroups);

		const paramsForFx = {
			regions,
			'data-types': dataTypes,
			search,
			sort: { orderBy: sort.value as keyof S3BucketItem, order: sort.operator },
		};

		s3BucketModel.fetchFx(paramsForFx);
	}, [pageParams]);

	// Clean up model on unmount
	useEffect(() => {
		return () => {
			s3BucketModel.resetFx();
		};
	}, []);

	// It's a workaround to set exact sort value instead of default('sensitivity')
	useEffect(() => {
		setPageParams({ ...pageParams, sort: { value: 'last_checked', operator: 'desc' } });
	}, []);

	function onSortUpdate(property: keyof S3BucketItem) {
		const { sort } = state.params;

		const operator: TOrder = sort.orderBy === property && sort.order === 'asc' ? 'desc' : 'asc';
		const newParams = { sort: { operator, value: property } };

		setPageParams({ ...pageParams, ...newParams });
	}

	function onFilterLineUpdate(filterLineParams: Partial<typeof pageParams>) {
		setPageParams({ ...pageParams, ...filterLineParams });
	}

	function onUniversalSearchUpdate(search: string) {
		setPageParams({ ...pageParams, search });
	}

	function onResetFilters() {
		const newParams = { regions: [], search: '', 'data-types': [] };

		setPageParams({ ...pageParams, ...newParams });
		clearPiiFilter();
	}

	const { status, data, total, total_filtered, hasMoreData, params } = state;
	const hasFilter =
		params.regions.length > 0 ||
		(params['data-types'].length > 0 && params['data-types'][0] !== 'nonempty') ||
		params.search !== '';

	return (
		<DataStorage>
			<Header
				showSensitiveSwitcher
				titleBlock="Data stores"
				breadcrumbProps={{
					steps: dataStoragesSteps,
					finalStep: 'S3 buckets',
				}}
			/>

			<UniversalSearch value={params.search} onChange={onUniversalSearchUpdate} />

			<FilterLine
				config={['dataTypes', 'regions']}
				values={{
					dataTypes: pageParams['data-types'],
					regions: pageParams['regions'],
				}}
				onChange={(newValues) => {
					onFilterLineUpdate({
						regions: newValues.regions,
					});
				}}
			/>

			<FilterResult
				entityLabel="S3 bucket"
				loading={status === 'loading'}
				total={total}
				totalFiltered={total_filtered}
			/>

			<GridTable dataTest="s3-buckets-table">
				<EnhancedTableHead
					config={tableConfig}
					order={params.sort.order}
					orderBy={params.sort.orderBy}
					onRequestSort={onSortUpdate}
					rowClassname={styles.rowContainer}
				/>

				<Preloader isLoading={status === 'initial' || status === 'loading'}>
					<GridBody data-test="s3-buckets-list">
						<>
							{data.length ? (
								data.map((bucket) => (
									<S3BucketTableRow
										bucket={bucket}
										search={params.search}
										dataTest="s3-buckets-table-row"
										key={bucket.id}
									/>
								))
							) : hasFilter ? (
								<ResetFilters onReset={onResetFilters} />
							) : (
								<NoContent type="s3Bucket" className={styles.rowContainer}>
									<div className={styles.emptyContainer}>
										<Typo variant="D/Regular/Body-S" color="secondary">
											No available analytics or no Data-at-rest Sensors set up yet.
										</Typo>

										<NewGatewayButton isDAR />
									</div>
								</NoContent>
							)}

							<LoadMoreButton
								show={hasMoreData}
								loading={status === 'loadingMore'}
								request={s3BucketModel.fetchMoreFx}
							/>
						</>
					</GridBody>
				</Preloader>
			</GridTable>
		</DataStorage>
	);
}

export default S3BucketsTable;
