import YAML from 'yaml';
import { DataTypeItem } from 'models/dataTypes/dto';
import { SampleDataField } from 'models/sample/dto';
import { getJsonLocation } from 'views/common/SampleViewer/helpers/jsonPath';

// This combination of options should make it so that no scalar value (e.g. long string) is split across multiple lines.
const SINGLE_LINE_VALUES_OPTS = { blockQuote: false, doubleQuotedAsJSON: true, lineWidth: 0 };

type RawDetection = SampleDataField;

type YamlDetection = {
	dataType: DataTypeItem['id'];
	jsonPath: string;
	column: number;
	len: number;
};

type YamlDocumentIR = {
	jsObject: unknown;
	rawDetections: RawDetection[];
};

type YamlLine = {
	line: string; // without newlines
	detections: YamlDetection[];
};

type YamlDocument = YamlLine[];

function ndJsonToYamlDocuments(ndjson: string, detections: RawDetection[]): YamlDocument[] {
	// Parse JSONs
	const yamlDocumentsIR: YamlDocumentIR[] = ndjson.split('\n').map((json) => ({
		jsObject: JSON.parse(json),
		rawDetections: [],
	}));

	// Split detections to relevant yamls
	for (const rawDetection of detections) {
		const doc = yamlDocumentsIR[rawDetection.locator_line];
		if (!doc) throw new Error('Unexpected: detection line number is greater than samples count');

		doc.rawDetections.push(rawDetection);
	}

	const isMultiple = yamlDocumentsIR.length > 1;

	return yamlDocumentsIR.map((doc: YamlDocumentIR) => prepareSingleYaml(doc, isMultiple));
}

function prepareSingleYaml(doc: YamlDocumentIR, isMultiple: boolean): YamlDocument {
	const { jsObject, rawDetections } = doc;

	const separator = isMultiple ? '---\n' : '';
	const yamlString = separator + YAML.stringify(jsObject, undefined, SINGLE_LINE_VALUES_OPTS);

	const lineCounter = new YAML.LineCounter();
	const parser = new YAML.Parser(lineCounter.addNewLine);

	const [cst] = parser.parse(yamlString);
	if (cst.type !== 'document') {
		throw new Error('Yaml sample unexpectedly parsed to something other than document; panic.');
	}

	const detectionsByLine: YamlDetection[][] = []; // Multiple lines; multiple detections per line possible.

	const pathMap = new Map();
	pathMap.set(JSON.stringify([]), []);

	const rawDetectionsWithPathParts = rawDetections.map((rawDetection) => ({
		dataType: rawDetection.data_type,
		jsonPath: rawDetection.locator_path,
		pathPartsStr: JSON.stringify(getJsonLocation(rawDetection.locator_path)),
	}));

	YAML.CST.visit(cst, (item, _path) => {
		const { key, value } = item;

		const path = [..._path];
		const tail = path.pop();
		const parentPath = pathMap.get(JSON.stringify(path));
		const tailPath = YAML.CST.isScalar(key) ? key.source : tail?.[1];
		if (tailPath !== undefined && tail !== undefined) {
			pathMap.set(JSON.stringify(path.concat([tail])), parentPath.concat(tailPath));
		}

		if (!YAML.CST.isScalar(value)) return;

		const currentPathPartsStr = JSON.stringify(parentPath.concat(tailPath));
		const found = rawDetectionsWithPathParts.filter((d) => d.pathPartsStr === currentPathPartsStr);

		if (found.length === 1) {
			if (found.length > 1) {
				console.error(
					'Multiple detections for single yaml path not supported - taking only first detection'
				);
			}
			const pos = lineCounter.linePos(value.offset);
			detectionsByLine[pos.line - 1] = detectionsByLine[pos.line - 1] || [];
			detectionsByLine[pos.line - 1].push({
				dataType: found[0].dataType,
				jsonPath: found[0].jsonPath,
				column: pos.col - 1,
				len: value.source.length,
			});
		}
	});

	const lines = yamlString.split('\n');

	return lines.map((line: string, i: number) => ({
		line,
		detections: detectionsByLine[i] || [],
	}));
}

export { ndJsonToYamlDocuments };
export type { YamlDetection, YamlLine, YamlDocument };
