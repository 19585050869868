import { useStore } from 'effector-react';
import { useMemo } from 'react';
import Button from 'components/form/Button';
import Select from 'components/form/Select';
import { DropdownButtonProps } from 'components/form/Select/DropdownButton';
import selectStyles from 'components/form/Select/index.module.css';
import Icon from 'components/Icon';
import { s3RegionsByKeyword, s3RegionsList } from 'models/s3Regions/store';
import { OTHER_REGION } from '../../DataInMotion/GatewayRegion';
import styles from './index.module.css';

function DropdownButtonRegion(props: DropdownButtonProps) {
	const { onClick, open, children, dataTest } = props;

	return (
		<Button
			className={styles.dropdownButton}
			color="tertiary"
			onClick={onClick}
			data-test={dataTest}
		>
			<span className={styles.dropdownButtonText}>{children}</span>
			<span className={open ? selectStyles.activeIcon : selectStyles.icon}>
				<Icon name="ChevronDown/Regular" />
			</span>
		</Button>
	);
}

type Props = {
	value: string;
	onChange: (value: string) => void;
};

const RegionSelect = ({ value, onChange }: Props) => {
	const regions = useStore(s3RegionsList);
	const s3RegionsByKey = useStore(s3RegionsByKeyword);

	const regionOptions = useMemo(
		() => [
			OTHER_REGION,
			...regions.map(({ keyword, description }) => ({ id: keyword, name: description })),
		],
		[regions]
	);
	const regionLabel = value ? s3RegionsByKey[value].description : OTHER_REGION.name;
	const regionValue = regionOptions.find(({ id }) => id === value)!;

	return (
		<Select
			label={regionLabel}
			options={regionOptions}
			onChange={(newValue) => onChange(newValue.id)}
			value={regionValue}
			data-test="create-gateway-region-input"
			render={{ dropdownButton: DropdownButtonRegion }}
		/>
	);
};

export { RegionSelect };
