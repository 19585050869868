import { useStore } from 'effector-react';
import { useCallback, useEffect, useMemo } from 'react';
import MultiSelect from 'components/form/MultiSelect';
import DropdownButton from 'components/form/Select/DropdownButton';
import { AssetJson } from 'models/assets/dto';
import { getAssetsFx } from 'models/assets/effects';
import { assetsList } from 'models/assets/store';
import { onceFx } from 'models/modelUtils/onceFx';
import { FilterPropsBase } from '../model';

type LabelKey = AssetJson['labels'][number]['key'];

type Option = { id: LabelKey; name: LabelKey };

type Props = FilterPropsBase & {
	value: LabelKey[];
	onChange: (newValue: LabelKey[]) => void;
};

function LabelKeyFilter({ defaultOpen, fixed, onChange, onClose, resetFilter, value }: Props) {
	const assetsListStore = useStore(assetsList);

	useEffect(() => {
		onceFx(getAssetsFx);
	}, []);

	const options = useMemo(() => {
		const result: {
			[key: string]: Option;
		} = {};

		for (const assetItem of assetsListStore) {
			if (assetItem.is_external) continue;

			for (const label of assetItem.labels) {
				result[label.key] = result[label.key] || {
					id: label.key,
					name: label.key,
				};
			}
		}

		return Object.values(result).sort((a, b) => a.name.localeCompare(b.name));
	}, [assetsListStore]);

	const selected = useMemo(() => {
		return options.filter((val) => value.includes(val.name));
	}, [value, options]);

	function handleChange(newSelected: Option[]) {
		onChange(newSelected.map((option) => option.name));
	}

	const dropdownButton = useCallback(
		(dropdownProps) => <DropdownButton {...dropdownProps} fixed={fixed} onClose={resetFilter} />,
		[fixed, resetFilter]
	);

	return (
		<MultiSelect
			defaultOpen={defaultOpen}
			label={{ primary: 'Label keys', secondary: `${selected.length || ''}` }}
			options={options}
			value={selected}
			onChange={handleChange}
			onClose={onClose}
			render={{ dropdownButton }}
			hasSearch
			hasApplyButton
		/>
	);
}

export default LabelKeyFilter;
