import cn from 'classnames';
import { useStore } from 'effector-react';
import { ReactNode, useMemo } from 'react';
import { dataTypesById } from 'models/dataTypes/store';
import { PIIMarkJson } from 'models/piiMarks/dto';
import ManualDataType from '../ManualDataType';
import SensitivityString from '../SensitivityString';
import styles from './index.module.pcss';
import MarkList from './MarkList';
import { Detection, LineDetection } from '.';

type Props = {
	isActive?: boolean;
	line: string;
	lineDetection: LineDetection;
	manuals: PIIMarkJson[];
	setFalsePositive: (dataType: number, jsonPath: string) => void;
	removeFalsePositive: (fpId: number) => void;
	removeManualDataType: (id: number) => void;
	setDataTypeDetections: ({
		dataTypes,
		jsonPath,
	}: {
		dataTypes: number[];
		jsonPath: string;
	}) => void;
	setLineActive: () => void;
};

function JsonFormattedLine({
	isActive = false,
	line,
	lineDetection,
	manuals,
	setFalsePositive,
	removeFalsePositive,
	removeManualDataType,
	setDataTypeDetections,
	setLineActive,
}: Props) {
	const dtById = useStore(dataTypesById);

	const lineLabel = `jsonPath-${lineDetection.jsonPath}`;

	const handleRemoveDataType = (id: number) => {
		removeManualDataType(id);
	};

	const allDetections = useMemo(() => {
		const manualIds = manuals.map((dt) => {
			return { dataTypeId: dt.manual_data_type, isFP: false, isManual: true, isCorrected: false };
		});

		let detectedIds: {
			isFP: boolean;
			dataTypeId: number;
			isManual: boolean;
			isCorrected: boolean;
		}[];

		if (Object.keys(dtById).length !== 0) {
			detectedIds = lineDetection.detections.map((dt) => {
				return {
					dataTypeId: dt.dataType,
					isFP: !!dt.fpId,
					isManual: false,
					isCorrected: dt.isCorrected,
				};
			});
		} else {
			detectedIds = [];
		}

		return detectedIds.concat(manualIds);
	}, [manuals, lineDetection, dtById]);

	if (lineDetection.detections.length === 0) {
		return (
			<div
				id={lineLabel}
				className={cn(styles.rowContainer, { [styles.active]: isActive })}
				onClick={setLineActive}
			>
				<MarkList list={allDetections} />

				<span className={styles.jsonLine}>
					<span className={styles.leftPart}>{line}</span>

					{!!lineDetection.locationString && (
						<ManualDataType
							className={styles.manualType}
							piiTypes={manuals}
							onRemove={handleRemoveDataType}
							onAdd={() =>
								setDataTypeDetections({
									dataTypes: allDetections.map((dt) => dt.dataTypeId),
									jsonPath: lineDetection.jsonPath,
								})
							}
						/>
					)}
				</span>
			</div>
		);
	}

	const leftPart = line.slice(0, lineDetection.column); // Cut off value - we do not need it, will take 'printValue' from detection.
	const withTrailingComma = line.endsWith(',');

	const sensitivityComponents = lineDetection.detections.map(
		({ dataType, printValue, fpId, isCorrected }: Detection) => {
			let value = printValue;
			if (withTrailingComma) value += ',';

			const onClick = fpId
				? () => removeFalsePositive(fpId)
				: () => setFalsePositive(dataType, lineDetection.jsonPath);

			return (
				<SensitivityString
					key={dataType}
					label={value}
					dataType={dataType}
					isFP={!!fpId}
					isCorrected={isCorrected}
					onClick={onClick}
				/>
			);
		}
	);

	const rightPart = sensitivityComponents.reduce((acc, component, i) => {
		if (i > 0) acc.push(' | ');

		acc.push(component);

		return acc;
	}, [] as ReactNode[]);

	return (
		<div
			id={lineLabel}
			data-test={lineLabel}
			className={cn(styles.rowContainer, { [styles.active]: isActive })}
			onClick={setLineActive}
		>
			<MarkList list={allDetections} />

			<span className={styles.jsonLine}>
				<span className={styles.leftPart}>{leftPart}</span>
				{rightPart}

				<ManualDataType
					className={styles.manualType}
					piiTypes={manuals}
					onRemove={handleRemoveDataType}
					onAdd={() =>
						setDataTypeDetections({
							dataTypes: allDetections.map((dt) => dt.dataTypeId),
							jsonPath: lineDetection.jsonPath,
						})
					}
				/>
			</span>
		</div>
	);
}

export default JsonFormattedLine;
