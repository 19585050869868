import cn from 'classnames';
import { PiiTypeList } from 'components/PiiType';
import GridCell from 'components/table/GridCell';
import GridRow from 'components/table/GridRow';
import Typo from 'components/typography/Typo';
import { CSVSampleColumn } from 'models/sample/dto';
import { DASH_SIGN } from 'services/strings';
import styles from './index.module.css';

type Props = {
	column: CSVSampleColumn;
	database?: boolean;
};

function SampleColumnItem({ column, database }: Props) {
	const { column_index, column_name, data_fields, value } = column;
	let dataTypes = <Typo variant="D/Medium/Body-S">{DASH_SIGN}</Typo>;
	let values: JSX.Element = (
		<Typo variant="D/Medium/Body-S" className={styles.value}>
			{value}
		</Typo>
	);

	if (data_fields.length > 0) {
		dataTypes = (
			<PiiTypeList data={data_fields.map((df) => df.data_type)} className={styles.dataTypesCell} />
		);
		values = (
			<>
				{data_fields.map((df, id) => (
					<Typo key={id + df.value} variant="D/Medium/Body-S" className={styles.value}>
						{df.value || DASH_SIGN}
					</Typo>
				))}
			</>
		);
	}

	return (
		<GridRow
			className={database ? styles.databaseRowContainer : styles.rowContainer}
			border
			hover
			data-test="table-view-sample-table-row"
		>
			{!database && (
				<GridCell dataTest="table-view-sample-column-index">
					<Typo variant="D/Medium/Body-S">{column_index}</Typo>
				</GridCell>
			)}

			<GridCell dataTest="table-view-sample-column-name">
				<Typo variant="D/Regular/Body-S">{column_name}</Typo>
			</GridCell>

			<GridCell dataTest="table-view-sample-column-data-types">{dataTypes}</GridCell>

			<GridCell
				dataTest="table-view-sample-column-value"
				className={cn(styles.dataTypesCell, styles.valueCell)}
			>
				{values}
			</GridCell>
		</GridRow>
	);
}

export { SampleColumnItem };
