import { useStore } from 'effector-react';
import jp from 'jsonpath';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import AutoSizer, { Size } from 'react-virtualized-auto-sizer';
import { FixedSizeList, FixedSizeList as List } from 'react-window';
import Loader from 'components/Loader';
import { DataTypeItem } from 'models/dataTypes/dto';
import { dataTypesById } from 'models/dataTypes/store';
import { PIIMarkJson } from 'models/piiMarks/dto';
import { SampleDataJson } from 'models/samplesV2/dto';
import abortableTimeout from 'services/abortableTimeout';
import { getJsonLocation } from '../helpers';
import styles from './index.module.pcss';
import JsonFormattedLine from './JsonFormattedLine';

//
// Helper function.
// When parsing JSON string, transform leaf nodes (literals) to special object. Arrays and
// objects remain as-is.
// Then addJsonPaths() to fill paths. This is to stringify later to different structures.
//

let stringifyWithLocations = false;
const LeafSymbol = Symbol('Leaf');

function reviver(key: string, value: unknown) {
	if (Array.isArray(value) || (typeof value === 'object' && value !== null)) {
		return value;
	}

	return {
		value,
		toJSON() {
			if (stringifyWithLocations) {
				return JSON.stringify(this.locationParts);
			} else {
				return this.value;
			}
		},
		[LeafSymbol]: true,
		locationParts: [],
	} as LeafLiteral;
}

//
// For TypeScript
//

type LeafLiteral = {
	value: string | number | boolean | null;
	toJSON: () => unknown;
	[LeafSymbol]: boolean;
	locationParts: (string | number)[];
};

type LeafLiteralObject = LeafLiteral | LeafLiteralObject[] | { [key: string]: LeafLiteralObject };

function isLeafLiteral(value: unknown): value is LeafLiteral {
	return typeof value === 'object' && value !== null && LeafSymbol in value;
}

//
// Helper function.
// Mutates initial object by adding path to leaf values. Leaf values must be in
// format returned by reviver() function; see above.
//

function addJsonPaths(parentLocationParts: (string | number)[], value: LeafLiteralObject) {
	if (isLeafLiteral(value)) {
		value.locationParts = [...parentLocationParts];
		return; // Recursion terminates here.
	}

	let entries = [];

	if (Array.isArray(value)) {
		entries = [...value.entries()];
	} else if (typeof value === 'object' && value !== null) {
		entries = Object.entries(value);
	} else {
		throw new Error(
			'Unexpected: leaf value does not contain LeafSymbol, probably due to error in using reviver() function.'
		);
	}

	for (const [key, child] of entries) {
		addJsonPaths(parentLocationParts.concat(key), child);
	}
}

//
// Component
//

type Props = {
	activePiiType: string | null;
	sampleData: Required<SampleDataJson>;
	piiMarks: PIIMarkJson[];
	hideFalsePositives: boolean;
	setFalsePositive: (dataType: number, jsonPath: string) => void;
	removeFalsePositive: (fpId: number) => void;
	removeManualDataType: (id: number) => void;
	setDataTypeDetections: ({
		dataTypes,
		jsonPath,
	}: {
		dataTypes: number[];
		jsonPath: string;
	}) => void;
};

type Detection = {
	dataType: DataTypeItem['id'];
	printValue: string;
	fpId?: number;
	isCorrected: boolean;
};

type LineDetection = {
	row: number;
	column: number;
	jsonPath: string;
	locationString: string;
	detections: Detection[];
};

function JsonView({
	activePiiType,
	sampleData,
	piiMarks,
	hideFalsePositives,
	setFalsePositive,
	removeFalsePositive,
	removeManualDataType,
	setDataTypeDetections,
}: Props) {
	const { json, data_fields } = sampleData;
	const listRef = useRef<FixedSizeList>();
	const scrollRef = useRef<HTMLDivElement>(null);
	const [calculateWidth, setCalculateWidth] = useState(0);
	const [listWidth, setListWidth] = useState(0);

	// Here we parse JSON to convenient structures: array of pretty strings and array of
	// strings where JSON location is embedded. Main point: they are stringified so that number
	// of lines, and value positions, are the same (but leaf values differ).
	//
	// ┌─jsonLines─────────────────────┐   ┌─jsonLinesWithLocations───────────────────┐
	// │                               │   │                                          │
	// │ {                             │   │ {                                        │
	// │     "client": {               │   │     "client": {                          │
	// │         "name": "**** *****", │   │         "name": "[\"client\",\"name\"]", │
	// │         "mail": "***@***.**"  │   │         "mail": "[\"client\",\"mail\"]"  │
	// │     }                         │   │     }                                    │
	// │ }                             │   │ }                                        │
	// │                               │   │                                          │
	// └───────────────────────────────┘   └──────────────────────────────────────────┘
	//
	const [jsonLines, jsonLinesWithLocations] = useMemo(
		function () {
			const richJson = JSON.parse(json, reviver);
			addJsonPaths([], richJson); // Mutates richJson.

			stringifyWithLocations = false;
			const lines = JSON.stringify(richJson, undefined, 4).split('\n');

			stringifyWithLocations = true;
			const linesWithLocations = JSON.stringify(richJson, undefined, 4).split('\n');

			return [lines, linesWithLocations];
		},
		[json]
	);

	// Here we create list of detections:
	// - (main point) paths from server are preserved, and linked to our own path format (as JSON array);
	// - if FP should be hidden - filter them out;
	// - FP id is embedded (for deleting false positives case);
	//
	const dtById = useStore(dataTypesById); // TODO remove, looks like not needed anymore
	const detectionsList = useMemo(
		function () {
			const detectionsByJsonPath: {
				[key: string]: {
					locationString: string;
					detections: Omit<Detection, 'row' | 'column'>[]; // TODO ???
				};
			} = {};

			for (const detection of data_fields) {
				const falsePositive = piiMarks.find(
					(fp) =>
						detection.json_path === fp.json_path && detection.data_type === fp.detected_data_type
				);

				if (hideFalsePositives && !!falsePositive) continue; // Effectively filter out this PII detection from view.

				if (!detectionsByJsonPath[detection.json_path]) {
					// Not yet in dictionary.
					detectionsByJsonPath[detection.json_path] = {
						locationString: JSON.stringify(JSON.stringify(getJsonLocation(detection.json_path))),
						detections: [],
					};
				}

				const result = {
					dataType: detection.data_type,
					printValue: detection.print_value,
					fpId: falsePositive && falsePositive.id,
					isCorrected: detection.is_corrected,
				};

				detectionsByJsonPath[detection.json_path].detections.push(result);
			}

			// To array:
			//	[{
			//		jsonPath: '$.user.name',
			//		locationString: "[\"user\",\"name\"]",
			//		detections: [
			//			{ dataType: 2, printValue: '"**** ***"', isCorrected: true },
			//			{ dataType: 7, printValue: '"***@**.**"', fpId: 245, isCorrected: false },
			//		]
			//	}];
			//
			return Object.entries(detectionsByJsonPath).map(
				([jsonPath, { locationString, detections }]) => ({ jsonPath, locationString, detections })
			);
		},
		[data_fields, piiMarks, hideFalsePositives, dtById]
	);

	// Here we associate detections from last step with line numbers, to feed convenient data to our component.
	//
	const detectionsByLine = useMemo(
		function () {
			return jsonLinesWithLocations.reduce(
				(linesAcc: { [key: number]: LineDetection }, line, row) => {
					const lineDetection = detectionsList.reduce(
						(detectionsAcc: LineDetection, detection) => {
							if (detectionsAcc.detections.length !== 0) return detectionsAcc;

							const column = line.lastIndexOf(detection.locationString);

							if (column === -1) return detectionsAcc;

							return { row, column, ...detection };
						},
						{
							row,
							column: -1,
							detections: [],
							jsonPath: '',
							locationString: '',
						}
					);

					if (lineDetection?.column === -1) {
						const locationPartStart = line.indexOf(':');

						let jsonPath = '';
						let locationPart = line.slice(locationPartStart + 1).trim();

						if (locationPart.charAt(locationPart.length - 1) === ',') {
							locationPart = locationPart.slice(0, -1);
						}

						if (
							locationPart === ']' ||
							locationPart === '[' ||
							locationPart === '[]' ||
							locationPart === '}' ||
							locationPart === '{' ||
							locationPart === '{}'
						) {
							// Ignore most common cases, to reduce CPU load on really large JSONs
							locationPart = '';
						} else {
							try {
								jsonPath = jp.stringify(JSON.parse(JSON.parse(locationPart)));
							} catch (e) {
								// If `locationPart` is not parseable, then this line does not have JSON path and is subsequently ignored
								locationPart = '';
							}
						}

						linesAcc[row] = {
							...lineDetection,
							jsonPath,
							locationString: locationPart,
						};
					} else {
						linesAcc[lineDetection.row] = lineDetection;
					}

					return linesAcc;
				},
				{}
			);
		},
		[jsonLinesWithLocations, detectionsList]
	);

	const manualDetections = useMemo(() => {
		const result = [];

		for (const [, detection] of Object.entries(detectionsByLine)) {
			const marks: PIIMarkJson[] = [];

			if (detection.jsonPath) {
				piiMarks.forEach((m) => {
					if (m.json_path === detection.jsonPath && m.detected_data_type === 0) {
						marks.push(m);
					}
				});
			}

			result.push(marks);
		}

		return result;
	}, [piiMarks, detectionsByLine]);

	// Here we work with width of lines' container
	// There are two refs:
	// * listRef - needs for scrolling to item
	// * scrollRef - needs for determine the widest value for every line

	useEffect(() => {
		if (!listRef?.current) return;

		const index = Object.values(detectionsByLine).findIndex(
			({ jsonPath }) => jsonPath === activePiiType
		);
		if (index !== -1) listRef.current.scrollToItem(index, 'center');
	}, [activePiiType]);

	// We have to recalculate container's width when we
	// * hide side panel (listWidth)
	// * define manual type (manualDetections)
	useEffect(findMaxWidth, [listWidth, manualDetections]);

	function findMaxWidth() {
		if (!scrollRef?.current) return;

		const lines = scrollRef.current.children[0];
		let maxWidth = scrollRef.current.scrollWidth;

		[...lines.children].forEach((line) => {
			maxWidth = Math.max(maxWidth, line.scrollWidth);
		});

		setCalculateWidth(maxWidth);
	}

	const [activeLines, setActiveLines] = useState<number[]>([]);

	function onLineActive(id: number) {
		if (activeLines.includes(id)) {
			setActiveLines(activeLines.filter((lineId) => lineId !== id));
		} else {
			setActiveLines([...activeLines, id]);
		}
	}

	const Line = useCallback(
		({ index, style }) => {
			const lineDetection = detectionsByLine[index];
			const manuals = manualDetections[index];

			return (
				<div
					key={index}
					style={{ ...style, width: `${calculateWidth}px` }}
					className={styles.lineContainer}
				>
					<span className={styles.number}>{index + 1}</span>

					<JsonFormattedLine
						isActive={lineDetection?.jsonPath === activePiiType || activeLines.includes(index)}
						line={jsonLines[index]}
						lineDetection={lineDetection}
						manuals={manuals}
						setLineActive={() => onLineActive(index)}
						setFalsePositive={setFalsePositive}
						removeFalsePositive={removeFalsePositive}
						removeManualDataType={removeManualDataType}
						setDataTypeDetections={setDataTypeDetections}
					/>
				</div>
			);
		},
		[jsonLines, detectionsByLine, activePiiType, calculateWidth, activeLines, manualDetections]
	);

	return (
		<div className={styles.jsonContainer}>
			<pre className={styles.pre}>
				<div className={styles.json}>
					<AutoSizer>
						{(size: Size) => {
							return (
								<List
									outerRef={scrollRef}
									height={Number(size.height)}
									itemCount={jsonLines.length}
									itemSize={32}
									width={Number(size.width)}
									ref={(ref) => {
										if (ref) {
											listRef.current = ref;
											setListWidth(Number(size.width));
										}
									}}
									onItemsRendered={findMaxWidth}
								>
									{Line}
								</List>
							);
						}}
					</AutoSizer>
				</div>
			</pre>
		</div>
	);
}

function DeferredJsonView(props: Props) {
	const isLongJson = props.sampleData.json.length > 300_000;

	const [isWaiting, setWaiting] = useState(isLongJson);

	useEffect(function () {
		if (!isLongJson) return;

		const ac = new AbortController();

		abortableTimeout(2000, ac.signal).then(function () {
			setWaiting(false);
		});

		return function () {
			ac.abort();
		};
	}, []);

	if (isWaiting) {
		return (
			<div className={styles.jsonContainer}>
				Large JSON sample (~{(props.sampleData.json.length / 1024).toFixed(1)}kB); may take a while
				to process <Loader size={14} />
			</div>
		);
	}

	return <JsonView {...props} />;
}

export default DeferredJsonView;
export type { Detection, LineDetection };
