import { JiraSettings, JiraProjects, JiraTicketFields } from './dto';

const jiraProjectsExample: JiraProjects = {
	projects: [
		{
			id: '10561',
			key: 'SMAT',
			name: "Soveren's MAT",
			icon: 'https://soveren.atlassian.net/rest/api/2/universal_avatar/view/type/project/avatar/10561?size=xxlarge',
		},
	],
};

const jiraTicketFieldsExample: JiraTicketFields = {
	issue_types: [
		{
			id: '10315',
			name: 'Story',
			icon: 'https://soveren.atlassian.net/rest/api/2/universal_avatar/view/type/issuetype/avatar/10315?size=medium',
		},
	],
	priorities: [
		{
			id: '31337',
			name: 'Medium',
			icon: 'https://hotspot.sammann.ru/medium.svg',
		},
	],
	components: [{ id: '31337', name: 'Design' }],
};

const jiraSettingsExample: JiraSettings = {
	is_enabled: true,
	jira_base_url: 'https://soveren.atlassian.net',
	jira_username: 'test@soveren.io',
	jira_api_token_is_set: false,
	project: {
		id: '10561',
		key: 'SMAT',
		name: "Soveren's MAT",
		icon: 'https://soveren.atlassian.net/rest/api/2/universal_avatar/view/type/project/avatar/10561?size=xxlarge',
	},
	issue_type: {
		id: '10315',
		name: 'Story',
		icon: 'https://soveren.atlassian.net/rest/api/2/universal_avatar/view/type/issuetype/avatar/10315?size=medium',
	},
	priority: {
		id: '31337',
		name: 'Medium',
		icon: 'https://hotspot.sammann.ru/medium.svg',
	},
	components: [{ id: '31337', name: 'Design' }],
};

export { jiraSettingsExample, jiraProjectsExample, jiraTicketFieldsExample };
