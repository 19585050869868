import PiiType from 'components/PiiType';
import SensitivityChip from 'components/SensitivityChip';
import Tooltip from 'components/Tooltip';
import Typo from 'components/typography/Typo';
import { DataTypeItem } from 'models/dataTypes/dto';
import styles from './index.module.pcss';

interface Props {
	dataTypes: DataTypeItem['id'][];
	sensitivity: 'High' | 'Medium' | 'Low' | 'N/A';
}

function PiiTypeWithTooltip({ dataTypes, sensitivity }: Props) {
	return (
		<Tooltip
			title={
				<div className={styles.typeList}>
					<Typo variant="D/Medium/Meta" className={styles.typeListHeader}>
						{dataTypes.length} data {dataTypes.length === 1 ? 'type' : 'types'}
					</Typo>

					{dataTypes.map((dataType) => (
						<PiiType type={dataType} key={dataType} className={styles.typeItem} />
					))}
				</div>
			}
		>
			<SensitivityChip sensitivity={sensitivity} />
		</Tooltip>
	);
}

export default PiiTypeWithTooltip;
