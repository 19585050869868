import { get } from 'api/Api';
import { getAssets } from 'models/assets/api';
import { AssetJson, AssetsJson } from 'models/assets/dto';
import { ApiParams } from 'services/api';
import checkResponse from 'services/api/checkResponse';
import {
	AssetDataFlowJson,
	AssetDataFlowsJson,
	AssetDataFlowsTableItem,
	AssetConnectionsJson,
} from './dto';
import { assetDataFlowsJsonExample, assetConnectionsJsonExample } from './examples';

function enrichWithAssetData(
	dataflows: AssetDataFlowJson[],
	assets: AssetJson[],
	id: AssetJson['id']
): AssetDataFlowsTableItem[] {
	const firstAsset = assets.find((a) => a.id === id);

	if (!firstAsset) throw new Error(`Asset '${id}' not found`);

	return dataflows.map((dataflow) => {
		const asset = assets.find((a) => a.id === dataflow.interacts_with);

		if (!asset) throw new Error(`Asset '${dataflow.interacts_with}' not found`);

		return {
			...dataflow,
			firstAssetId: firstAsset.id,
			name: asset.name,
			is_external: asset.is_external,
			type: asset.type,
			k8s_types: asset.k8s_types,
			namespace: asset.namespace,
			groups: asset.groups,
			cluster_id: asset.cluster_id,
		};
	});
}

async function getAssetDataFlows(id: AssetJson['id'], apiParams: ApiParams, signal?: AbortSignal) {
	const config = signal ? { signal } : {};

	return get<AssetDataFlowsJson>(`v2/assets/${id}/dataflows`, apiParams, config)
		.then(checkResponse(assetDataFlowsJsonExample))
		.then((data: AssetDataFlowsJson) => {
			return getAssets().then((assetsPayload: AssetsJson) => {
				return {
					...data,
					dataflows: enrichWithAssetData(data.dataflows, assetsPayload.assets, id),
				};
			});
		});
}

async function getAssetConnections(
	id: AssetJson['id'],
	apiParams: ApiParams,
	signal?: AbortSignal
) {
	const config = signal ? { signal } : {};

	return get<AssetConnectionsJson>(`v2/assets/${id}/datastorages`, apiParams, config)
		.then(checkResponse(assetConnectionsJsonExample))
		.then((data: AssetConnectionsJson) => {
			return {
				...data,
				dataflows: data.dataflows.map((df) => ({ ...df, name: df.entity_name })),
			};
		});
}

export { getAssetDataFlows, getAssetConnections };
