import { createStore } from 'effector';
import { KafkaInstanceList } from './dto';
import { getKafkaInstancesFx } from './effects';

const kafkaInstancesStore = createStore<KafkaInstanceList>({
	instances: [],
	total: 0,
});

kafkaInstancesStore.on(
	getKafkaInstancesFx.doneData,
	(state: KafkaInstanceList, data: KafkaInstanceList) => data
);

const kafkaInstancesList = kafkaInstancesStore.map(({ instances }) =>
	instances.sort((a, b) => a.name.localeCompare(b.name))
);

const kafkaInstancesMap = kafkaInstancesStore.map(({ instances }) => {
	return Object.fromEntries(instances.map((instance) => [instance.id, instance]));
});

export { kafkaInstancesList, kafkaInstancesMap };
