import { createEffect } from 'effector';
import { getRelease } from './api';
import { ReleaseJson } from './dto';

/* API */

const getReleaseFx = createEffect<void, ReleaseJson>();

/* IMPLEMENTATION */

// Simple direct-to-api effects

getReleaseFx.use(getRelease);

export { getReleaseFx };
