import { CircularProgressProps } from '@material-ui/core';
import cn from 'classnames';
import { ReactNode } from 'react';
import Loader from '../Loader';
import styles from './index.module.css';

interface Props extends CircularProgressProps {
	isLoading: boolean;
	children: ReactNode;
	className?: string;
}

function Preloader({ children, isLoading, className, ...props }: Props) {
	if (!isLoading) {
		return <>{children}</>;
	}

	return (
		<div className={cn(styles.loader, className)}>
			<Loader {...props} />
		</div>
	);
}

export default Preloader;
