import { Redirect, Route, Switch } from 'react-router-dom';
import { PATHS } from 'services/router';
import EndpointsV2 from 'views/EndpointsV2';
import SampleV2 from 'views/SampleV2';

function ApiEndpointsRoute() {
	return (
		<Switch>
			<Route path={PATHS.SAMPLE_V2} component={SampleV2} />
			<Route path={PATHS.API_ENDPOINTS} component={EndpointsV2} />

			<Redirect to={PATHS.API_ENDPOINTS} />
		</Switch>
	);
}

export { ApiEndpointsRoute };
