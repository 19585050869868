import { useEffect, useState } from 'react';
import { generatePath } from 'react-router';
import { useHistory, useParams } from 'react-router-dom';
import Preloader from 'components/Preloader';
import { kafkaTopicsSteps } from 'layouts/AuthorizedWithLeftMenu/Breadcrumbs';
import Header from 'layouts/AuthorizedWithLeftMenu/Header';
import { getKafkaMessageSchema } from 'models/kafkaMessageSchemas/api';
import { KafkaMessageSchema } from 'models/kafkaMessageSchemas/dto';
import { PATHS } from 'services/router';
import { SamplesTable } from 'views/Kafka/TopicItem/SamplesTable';
import styles from './index.module.css';
import { Summary } from './Summary';
import { WashedSchemaProps, WashedSchema } from './WashedSchema';

type Props = {
	schema: KafkaMessageSchema;
};

function MessageSchemaItem({ schema }: Props) {
	return (
		<>
			<Header
				showSensitiveSwitcher
				breadcrumbProps={{
					steps: [
						...kafkaTopicsSteps,
						{
							path: generatePath(PATHS.KAFKA_TOPICS_ITEM, {
								id: schema.topic_id,
								tab: 'summary',
							}),
							name: schema.topic_name,
						},
						{
							path: generatePath(PATHS.KAFKA_TOPICS_ITEM, {
								id: schema.topic_id,
								tab: 'message-schemas',
							}),
							name: 'Message schemas',
						},
					],
					finalStep: schema.name,
				}}
				titleBlock={schema.name}
			/>

			<div className={styles.container}>
				<Summary schema={schema} />

				<SamplesTable schemaId={schema.id} />
			</div>
		</>
	);
}

function MessageSchemaItemWrapper() {
	const history = useHistory();
	const { topicId, schemaId }: { topicId: string; schemaId: string } = useParams();

	const [isLoading, setLoading] = useState(true);
	const [schema, setSchema] = useState<KafkaMessageSchema | undefined>();

	useEffect(() => {
		setLoading(true);

		getKafkaMessageSchema(Number(schemaId))
			.then((data) => {
				setSchema(data);
			})
			.finally(() => {
				setLoading(false);
			});
	}, [topicId, schemaId]);

	return (
		<Preloader isLoading={isLoading}>
			{schema ? (
				<MessageSchemaItem schema={schema} />
			) : (
				<WashedSchema id={schemaId} state={history.location.state as WashedSchemaProps['state']} />
			)}
		</Preloader>
	);
}

export { MessageSchemaItemWrapper as MessageSchemaItem };
