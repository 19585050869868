import { ReactNode } from 'react';
import { FixedSizeList as List } from 'react-window';

type Props = {
	result: ReactNode[];
};

const PARENT_CONTAINER_HEIGHT = 400; // views/DataMap/IsometricMap/TopPane/index.module.css -> .searchDropdown -> height without padding
const ITEM_HEIGHT = 40;
const ITEMS_VIEW_LIMIT = PARENT_CONTAINER_HEIGHT / ITEM_HEIGHT;
const ITEM_WIDTH = 300; // views/DataMap/IsometricMap/TopPane/index.module.css -> .searchDropdownItemText -> width

export const SearchDropdownItems = ({ result }: Props) => {
	return (
		<List
			height={
				result.length >= ITEMS_VIEW_LIMIT
					? ITEM_HEIGHT * ITEMS_VIEW_LIMIT
					: ITEM_HEIGHT * result.length
			}
			itemCount={result.length}
			itemSize={ITEM_HEIGHT}
			width={ITEM_WIDTH}
		>
			{({ index, style }) => <div style={style}>{result[index]}</div>}
		</List>
	);
};
