import { InputAdornment } from '@material-ui/core';
import { useStore } from 'effector-react';
import { ChangeEvent, useEffect } from 'react';
import TextField from 'components/form/TextField';
import Icon from 'components/Icon';
import styles from './index.module.css';
import { searchStore, changeSearch } from './model';

type Props = {
	value: string;
	onChange: (value: string) => void;
};

function UniversalSearch(props: Props) {
	const { value, onChange } = props;

	useEffect(() => {
		const unwatch = changeSearch.watch(onChange);

		return unwatch;
	}, [onChange]);

	useEffect(() => {
		changeSearch(value);
	}, [value]);

	return null;
}

function UniversalSearchInLayout() {
	const value = useStore(searchStore);

	function onChange(e: ChangeEvent<HTMLInputElement>) {
		changeSearch(e.target.value);
	}

	return (
		<TextField
			value={value}
			onChange={onChange}
			size="small"
			placeholder="Search"
			className={styles.search}
			helperText={null}
			InputProps={{
				endAdornment: (
					<InputAdornment position="end">
						<Icon name="search" size={20} />
					</InputAdornment>
				),
			}}
			data-test="universal-search-input"
		/>
	);
}

export { UniversalSearch, UniversalSearchInLayout };
