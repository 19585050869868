import cn from 'classnames';
import { ForwardedRef, forwardRef, ReactNode } from 'react';
import styles from './index.module.css';

type Props = {
	children: ReactNode;
	error?: boolean;
	className?: string;
};

export const Section = forwardRef(function PolicySectionRef(
	props: Props,
	ref: ForwardedRef<HTMLDivElement>
) {
	const { children, error, className } = props;

	return (
		<div className={cn(styles.container, error && styles.error, className)} ref={ref}>
			{children}
		</div>
	);
});
