import { IHeadCell } from 'components/table/EnhancedTableHead';
import { DatabaseItem } from 'models/databases/dto';

const tableConfig: IHeadCell<DatabaseItem>[] = [
	{
		id: 'name',
		numeric: false,
		label: 'Name',
		sortable: true,
	},
	{
		id: 'action', // There must be a 'region', but we take this info from DB instance
		numeric: false,
		label: 'Region',
		sortable: false, // and that's why we turned off sorting
	},
	{
		id: 'tables_count',
		numeric: true,
		label: 'Tables',
		sortable: true,
	},
	{
		id: 'size',
		numeric: true,
		label: 'Size',
		sortable: true,
	},
	{
		id: 'sensitivity',
		numeric: false,
		label: 'Sensitivity',
		sortable: true,
	},
	{
		id: 'data_types',
		numeric: false,
		label: 'Data types',
		sortable: false,
	},
	{
		id: 'instance_id',
		numeric: false,
		label: 'Instance',
		sortable: false,
	},
	{
		id: 'last_checked',
		numeric: false,
		label: 'Last checked',
		sortable: true,
		reversedSort: true,
	},
];

export default tableConfig;
