import { del, get, post } from 'api/Api';
import checkResponse from 'services/api/checkResponse';
import { ExternalAPIToken, ExternalAPITokenList } from './dto';
import { externalAPITokensExample, externalAPITokenExample } from './examples';

async function getExternalAPITokens() {
	return get<ExternalAPITokenList>('v1/public-api/tokens').then(
		checkResponse<ExternalAPITokenList>(externalAPITokensExample)
	);
}

async function createExternalAPIToken(name: ExternalAPIToken['name']) {
	const payload = { name };

	return post<ExternalAPIToken>('v1/public-api/tokens', payload).then(
		checkResponse<ExternalAPIToken>(externalAPITokenExample)
	);
}

async function updateExternalAPIToken(payload: ExternalAPIToken) {
	return post<ExternalAPIToken>(`v1/public-api/tokens/${payload.id}`, payload).then(
		checkResponse<ExternalAPIToken>(externalAPITokenExample)
	);
}

async function deleteExternalAPIToken(id: ExternalAPIToken['id']) {
	return del(`v1/public-api/tokens/${id}`).then(() => id);
}

export {
	getExternalAPITokens,
	createExternalAPIToken,
	updateExternalAPIToken,
	deleteExternalAPIToken,
};
