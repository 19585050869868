import { InputAdornment } from '@material-ui/core';
import cn from 'classnames';
import { MouseEvent, useEffect, useState } from 'react';
import ButtonIcon from 'components/ButtonIcon';
import { Divider } from 'components/Divider';
import Button from 'components/form/Button';
import TextField from 'components/form/TextField';
import Popover from 'components/Popover';
import headerStyles from '../index.module.pcss';
import styles from './index.module.pcss';

interface Props {
	value: string;
	onFilter: (filter: string) => void;
	isActive: boolean;
	isVisible: boolean;
	handleFilter: (isOpen: boolean) => void;
}

function Filter({ onFilter, isActive, isVisible, handleFilter, value }: Props) {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [filterValue, setFilterValue] = useState(value || '');

	useEffect(() => {
		setFilterValue(value);
	}, [value]);

	const onSubmit = (event: MouseEvent<HTMLFormElement>) => {
		event.preventDefault();
		event.stopPropagation();
		onFilter(filterValue);
		handleFilter(false);
		handleClose();
	};

	const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
		handleFilter(true);
	};

	const handleClose = () => {
		setAnchorEl(null);
		handleFilter(false);
	};

	return (
		<>
			<ButtonIcon
				size="XS"
				icon="Filter/Regular"
				className={cn(headerStyles.button, {
					[headerStyles.visibleButton]: isVisible,
					[styles.activeFilter]: Boolean(anchorEl),
					[headerStyles.activeButton]: isActive,
				})}
				color={isActive ? 'icon/accent-primary' : 'icon/secondary'}
				onClick={handleClick}
				dataTest="EnhancedTableHead-filter"
			/>

			<Popover
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={handleClose}
				className={styles.popoverContainer}
			>
				<form className={styles.container} onSubmit={onSubmit}>
					<TextField
						value={filterValue}
						onChange={({ target }) => setFilterValue(target.value)}
						autoFocus
						autoComplete="false"
						placeholder="Enter text"
						className={styles.input}
						helperText={null}
						data-test={'EnhancedTableHead-filter-input'}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<ButtonIcon
										icon="Dismiss/Regular"
										className={cn(styles.eraseIcon, { [styles.showEraseIcon]: filterValue })}
										color={isActive ? 'icon/accent-primary' : 'icon/secondary'}
										onClick={() => setFilterValue('')}
										dataTest="EnhancedTableHead-clear-input"
									/>
								</InputAdornment>
							),
						}}
					/>

					<Divider light />

					<Button
						className={styles.button}
						color="tertiary"
						size="extraSmall"
						type="submit"
						data-test={'EnhancedTableHead-apply'}
					>
						Apply
					</Button>
				</form>
			</Popover>
		</>
	);
}

export default Filter;
