import { StrictMode } from 'react';
import { Router } from 'react-router-dom';
import { ConfirmModalContainer } from 'components/ConfirmModal';
import Snackbar from 'components/Snackbar';
import MDTheme from 'layouts/MDTheme';
import MainRouter from 'router/MainRouter';
import { DataTestInspector } from 'services/DataTestInspector';
import history from 'services/history';
import DebugOverlay, { debugSettings } from 'views/common/DebugOverlay';
import { PerformanceDashboard } from 'views/PerformanceDashboard';

function App() {
	DataTestInspector();

	return (
		<StrictMode>
			<Router history={history}>
				<MDTheme>
					<Snackbar>
						<ConfirmModalContainer>
							<MainRouter />

							<DebugOverlay />
							{debugSettings.FLAG_PERFORMANCE && <PerformanceDashboard />}
						</ConfirmModalContainer>
					</Snackbar>
				</MDTheme>
			</Router>
		</StrictMode>
	);
}

export default App;
