import cn from 'classnames';
import ButtonIcon from 'components/ButtonIcon';
import Icon from 'components/Icon';
import { enqueueSnackbar } from 'components/Snackbar';
import Tooltip from 'components/Tooltip';
import Typo from 'components/typography/Typo';
import { DateFormat, dayjs } from 'services/dayjs';
import styles from './index.module.css';
interface TestMessageProps {
	errorMessage: string;
	lastSeen?: number;
	className?: string;
}

export function TestMessage({ errorMessage, lastSeen, className }: TestMessageProps) {
	function onCopy() {
		navigator.clipboard.writeText(errorMessage).then(() => {
			enqueueSnackbar('Error text was copied');
		});
	}

	return (
		<div className={cn(styles.container, className)}>
			<Icon name={errorMessage ? 'Danger/FilledRed' : 'success'} size={20} />

			<div className={cn(styles.textContainer, { [styles.textContainerRow]: !errorMessage })}>
				<Typo
					variant="D/Medium/Body-S"
					className={cn(errorMessage ? styles.errorText : styles.successText, styles.text)}
					data-test="test-message"
				>
					{errorMessage || '200 OK'}
				</Typo>

				{lastSeen && (
					<Typo
						variant="D/Regular/Body-S"
						className={styles.date}
						component="span"
						color="secondary"
						data-test="test-message-date"
					>
						Last message {dayjs(lastSeen).utc().format(DateFormat.utc)}
					</Typo>
				)}
			</div>

			{errorMessage && (
				<Tooltip title="Copy error text" placement="top">
					<ButtonIcon
						className={styles.copyButton}
						dataTest="test-message-copy"
						icon="CopyCustom/Filled"
						onClick={onCopy}
						size="XS"
					/>
				</Tooltip>
			)}
		</div>
	);
}
