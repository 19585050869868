import { DataTypeItem } from 'models/dataTypes/dto';
import { SampleDataField } from 'models/sample/dto';
import { visitJsonValues } from 'views/common/SampleViewer/helpers/json';
import { getJsonLocation } from 'views/common/SampleViewer/helpers/jsonPath';

type RawDetection = SampleDataField;

type JsonDetection = {
	dataType: DataTypeItem['id'];
	jsonPath: string;
	column: number;
	len: number;
};

type JsonDocumentIR = {
	jsObject: unknown;
	rawDetections: RawDetection[];
};

type JsonLine = {
	line: string; // without newlines
	detections: JsonDetection[];
};

type JsonDocument = JsonLine[];

function jsonToJsonDocuments(json: string, detections: RawDetection[]): JsonDocument[] {
	// Parse JSONs
	const jsonDocumentsIR: JsonDocumentIR[] = [
		{
			jsObject: JSON.parse(json),
			rawDetections: detections,
		},
	];

	return jsonDocumentsIR.map(prepareSingleJson);
}

function prepareSingleJson(doc: JsonDocumentIR): JsonDocument {
	const { jsObject, rawDetections } = doc;

	const prettyLines = JSON.stringify(jsObject, undefined, 4).split('\n');
	const rawDetectionsWithPathParts = rawDetections.map((rawDetection) => ({
		dataType: rawDetection.data_type,
		jsonPath: rawDetection.locator_path,
		pathPartsStr: JSON.stringify(getJsonLocation(rawDetection.locator_path)),
	}));

	const result = [];
	const pathParts: unknown[] = [];

	for (let i = 0; i < prettyLines.length; i++) {
		const line = prettyLines[i];
		const detections: JsonDetection[] = [];

		visitJsonValues(line, pathParts, (source, column, parts) => {
			const currentPathPartsStr = JSON.stringify(parts);
			const found = rawDetectionsWithPathParts.filter(
				(d) => d.pathPartsStr === currentPathPartsStr
			);

			if (found.length === 1) {
				if (found.length > 1) {
					console.warn(
						'Multiple detections per single JSON path not supported - taking only first detection'
					);
				}

				detections.push({
					dataType: found[0].dataType,
					jsonPath: found[0].jsonPath,
					column,
					len: source.length,
				});
			}
		});

		if (detections.length > 1) {
			console.warn('Multiple detections per JSON line are not supported');
		}

		result.push({ line, detections });
	}

	return result;
}

export { jsonToJsonDocuments };
export type { JsonDetection, JsonLine, JsonDocument };
