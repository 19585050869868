import { Fragment, useMemo, useState } from 'react';
import Button from 'components/form/Button';
import NewGatewayBlock from 'components/NewGatewayBlock';
import EnhancedTableHead from 'components/table/EnhancedTableHead';
import GridBody from 'components/table/GridBody';
import GridTable from 'components/table/GridTable';
import { DataTypesExtendedStructured } from 'models/dataTypesExtended/dto';
import { PageParamsConfig, usePageParams } from 'services/pageParams';
import tableConfigV2 from './config';
import DataTypeRowV2 from './DataTypeRowV2';
import stylesV2 from './DataTypeRowV2/index.module.css';
import styles from './index.module.css';
import { sortedDataHandler } from './sortedData';

type Props = {
	data: DataTypesExtendedStructured[];
	className?: string;
};

const pageConfig = {
	sort: {
		type: 'sort',
		persistence: 'session',
	},
} satisfies PageParamsConfig;

function DataTypesTable(props: Props) {
	const { data, className } = props;
	const [showAll, setShowAll] = useState<{ [key: number]: boolean }>({});
	const [collapsed, setCollapsed] = useState<{ [key: number]: boolean }>({});

	const [pageParams, setPageParams] = usePageParams(pageConfig);

	const sortedData = useMemo(
		() =>
			sortedDataHandler({ data, params: pageParams }).map((group) => ({
				...group,
				child_items: sortedDataHandler({ data: group.child_items, params: pageParams }),
			})),
		[data, pageParams]
	);

	if (!data.length) {
		return <NewGatewayBlock />;
	}

	const orderBy = pageParams.sort.value;
	const order = pageParams.sort.operator;

	function handleRequestSort(property: keyof DataTypesExtendedStructured) {
		const isAsc = orderBy === property && order === 'asc';
		const sort: (typeof pageParams)['sort'] = { value: property, operator: isAsc ? 'desc' : 'asc' };
		setPageParams({ sort });
	}

	return (
		<GridTable className={className}>
			<EnhancedTableHead
				order={order}
				orderBy={orderBy}
				onRequestSort={handleRequestSort}
				config={tableConfigV2}
				rowClassname={stylesV2.rowContainer}
			/>

			<GridBody>
				{sortedData.map((item: DataTypesExtendedStructured) => {
					const showUnused = showAll[item.id];
					const hideChildren = collapsed[item.id];

					const filteredDataTypes = hideChildren
						? []
						: showUnused
						? item.child_items
						: item.child_items.filter((dt) => dt.isUsed);

					const showButton =
						!hideChildren && !showUnused && filteredDataTypes.length < item.child_items.length;

					return (
						<Fragment key={item.id}>
							<DataTypeRowV2
								key={item.id}
								data={item}
								collapsed={hideChildren}
								onCollapse={() => setCollapsed({ ...collapsed, [item.id]: !hideChildren })}
							/>

							{filteredDataTypes.map((childItem) => (
								<DataTypeRowV2 key={childItem.id} data={childItem} isChild={true} />
							))}

							{showButton && (
								<div className={styles.showAllButton}>
									<Button
										size="small"
										color="ghost"
										onClick={() => setShowAll({ ...showAll, [item.id]: true })}
										data-test={`show-unused-button-dynamic-${item.alias}`}
									>
										Show unused data types
									</Button>
								</div>
							)}
						</Fragment>
					);
				})}
			</GridBody>
		</GridTable>
	);
}

export default DataTypesTable;
