import { IHeadCell } from 'components/table/EnhancedTableHead';
import { AssetGroupTable } from 'models/assetsGroups/dto';

const tableConfig: IHeadCell<AssetGroupTable>[] = [
	{
		id: 'name',
		numeric: false,
		label: 'Group',
		sortable: false,
		filter: false,
	},
	{
		id: 'assets',
		numeric: true,
		label: 'Services',
		sortable: false,
		filter: false,
	},
	{
		id: 'owner',
		numeric: false,
		label: 'Owner',
		sortable: false,
		filter: false,
	},
	{
		id: 'created_at',
		numeric: false,
		label: 'created',
		sortable: false,
		filter: false,
	},
];

export default tableConfig;
