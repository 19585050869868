export const getCroppedText = (
	ctxWithFont: CanvasRenderingContext2D,
	maxWidth: number,
	text: string
) => {
	let textContent = text;
	let textContentSize = ctxWithFont.measureText(textContent).width;

	while (textContentSize > maxWidth) {
		textContent = textContent.slice(0, textContent.length - 1);
		textContentSize = ctxWithFont.measureText(`${textContent}...`).width;
	}

	return { textContent, textContentSize, isCropped: textContent !== text };
};

export const roundRect = (
	ctx: CanvasRenderingContext2D,
	x: number,
	y: number,
	width: number,
	height: number,
	r: number[]
) => {
	const radius = r.length === 1 ? [r[0], r[0], r[0], r[0]] : r;

	ctx.beginPath();
	ctx.moveTo(x + radius[0], y);
	ctx.lineTo(x + width - radius[1], y);
	ctx.quadraticCurveTo(x + width, y, x + width, y + radius[1]);
	ctx.lineTo(x + width, y + height - radius[2]);
	ctx.quadraticCurveTo(x + width, y + height, x + width - radius[2], y + height);
	ctx.lineTo(x + radius[3], y + height);
	ctx.quadraticCurveTo(x, y + height, x, y + height - radius[3]);
	ctx.lineTo(x, y + radius[0]);
	ctx.quadraticCurveTo(x, y, x + radius[0], y);
	ctx.closePath();
};

export const drawRect = (props: {
	ctx: CanvasRenderingContext2D;
	x: number;
	y: number;
	w: number;
	h: number;
	color: string;
	borderRadius: number[];
}) => {
	props.ctx.beginPath();

	if (!props.ctx.roundRect) {
		// for the old browsers: https://caniuse.com/mdn-api_canvasrenderingcontext2d_roundrect
		roundRect(props.ctx, props.x, props.y, props.w, props.h, props.borderRadius);
	} else {
		props.ctx.roundRect(props.x, props.y, props.w, props.h, props.borderRadius);
	}

	props.ctx.fillStyle = props.color;
	(props.ctx.fill as Function)();
};

export const drawText = (props: {
	ctx: CanvasRenderingContext2D;
	x: number;
	y: number;
	textAlign: CanvasTextAlign;
	textBaseline?: CanvasTextBaseline;
	color: string;
	text: string;
}) => {
	props.ctx.fillStyle = props.color;
	props.ctx.textAlign = props.textAlign;
	props.ctx.textBaseline = props.textBaseline || 'alphabetic';
	props.ctx.fillText(props.text, props.x, props.y);
};
