import isEqual from 'lodash.isequal';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { TOrder } from 'models/common/dto';
import history from 'services/history';

type ParamType = 'string' | 'number' | 'boolean' | 'stringArray' | 'numberArray' | 'sort';

type PageParamsConfigItem = {
	type: ParamType;
	persistence: 'none' | 'session' | 'local' | 'global';
};

type PageParamsConfigArrayItem = PageParamsConfigItem & { key: string };

type PageParamsConfig = { [key: string]: PageParamsConfigItem };

type PageParams<T extends PageParamsConfig> = {
	[Property in keyof T]: T[Property]['type'] extends 'string'
		? string
		: T[Property]['type'] extends 'number'
		? number
		: T[Property]['type'] extends 'boolean'
		? boolean
		: T[Property]['type'] extends 'stringArray'
		? string[]
		: T[Property]['type'] extends 'numberArray'
		? number[]
		: T[Property]['type'] extends 'sort'
		? { value: string; operator: TOrder }
		: never;
};

const defaultValues = {
	string: '',
	number: 0,
	boolean: false,
	stringArray: [] as string[],
	numberArray: [] as number[],
	sort: { value: 'sensitivity', operator: 'desc' },
} satisfies Record<ParamType, unknown>;

type PageParamsWeak = { [key: string]: (typeof defaultValues)[keyof typeof defaultValues] };

// Helpers for case 'copy url then paste in new tab' scenario
const pathnameAtStart = window.location.pathname;
let once = true;

// TODO problem with 'session' logic if inside tab go to page with url params explicitly set

function usePageParamsUnmemoized<PPC extends PageParamsConfig>(
	config: PPC,
	storageKey?: string
): [PageParams<PPC>, (params: PageParams<PPC>) => void] {
	const pageKey = useMemo(() => storageKey ?? location.pathname, []); // Parametrized locations (e.g. 'users/[id]/files') are not supported; use key in such cases.

	const configObject = useMemo(() => config, []); // Ramshakle protection against config change
	const configArray: PageParamsConfigArrayItem[] = useMemo(
		() => Object.entries(configObject).map(([key, configValue]) => ({ ...configValue, key })),
		[]
	);

	const [, update] = useState(0);

	const setPageParamsNoUpdate = useCallback(function setPageParamsNoUpdate(
		newParams: PageParams<PPC>
	) {
		const sess = configArray.filter((pageParam) => pageParam.persistence === 'session');
		const newSession = Object.fromEntries(
			sess.map((pageParam) => [pageParam.key, newParams[pageParam.key]])
		);

		const loc = configArray.filter((pageParam) => pageParam.persistence === 'local');
		const newLocal = Object.fromEntries(
			loc.map((pageParam) => [pageParam.key, newParams[pageParam.key]])
		);

		const glob = configArray.filter((pageParam) => pageParam.persistence === 'global');
		const newGlobal = Object.fromEntries(
			glob.map((pageParam) => [pageParam.key, newParams[pageParam.key]])
		);

		const searchString = stringifyParams(newParams, configObject);

		if (sess.length !== 0) {
			window.sessionStorage.setItem(`svrn-pageParams-${pageKey}`, JSON.stringify(newSession));
		}
		if (loc.length !== 0) {
			window.localStorage.setItem(`svrn-pageParams-${pageKey}`, JSON.stringify(newLocal));
		}
		if (glob.length !== 0) {
			window.localStorage.setItem('svrn-pageParamsGlobal', JSON.stringify(newGlobal));
		}
		if (location.search !== searchString) {
			history.replace(location.pathname + searchString, history.location.state);
		}
	}, []);

	const setPageParams = useCallback(function setPageParams(newParams: PageParams<PPC>) {
		setPageParamsNoUpdate(newParams);
		update((n) => (n + 1) % 1000);
	}, []);

	const searchParams = parseParams(location.search, configObject);
	const forceSet = 'setPageParams' in searchParams;

	const isFirst = once && location.pathname === pathnameAtStart;
	useEffect(() => {
		once = false;
	}, []);

	if (isFirst || forceSet) {
		once = false;

		const localRaw = window.localStorage.getItem(`svrn-pageParams-${pageKey}`) || '{}';
		const local = JSON.parse(localRaw);

		const globalRaw = window.localStorage.getItem('svrn-pageParamsGlobal') || '{}';
		const globalP = JSON.parse(globalRaw);

		const pageParamsArray = configArray.map((pageParam) => {
			const { key } = pageParam;
			const defaultValue = defaultValues[pageParam.type];

			switch (pageParam.persistence) {
				case 'none':
					return { key, value: searchParams[key] ?? defaultValue };

				case 'session':
					return { key, value: searchParams[key] ?? defaultValue };

				case 'local':
					return {
						key,
						value: searchParams[key] ?? (forceSet ? null : local[key]) ?? defaultValue,
					};

				case 'global':
					return {
						key,
						value: searchParams[key] ?? (forceSet ? null : globalP[key]) ?? defaultValue,
					};
			}
		});

		const pageParams = Object.fromEntries(
			pageParamsArray.map(({ key, value }) => [key, value])
		) as PageParams<PPC>;

		setPageParamsNoUpdate(pageParams);

		return [pageParams, setPageParams];
	} else {
		const sessionRaw = window.sessionStorage.getItem(`svrn-pageParams-${pageKey}`) || '{}';
		const session = JSON.parse(sessionRaw);

		const localRaw = window.localStorage.getItem(`svrn-pageParams-${pageKey}`) || '{}';
		const local = JSON.parse(localRaw);

		const globalRaw = window.localStorage.getItem('svrn-pageParamsGlobal') || '{}';
		const globalP = JSON.parse(globalRaw);

		const pageParamsArray = configArray.map((pageParam) => {
			const { key } = pageParam;
			const defaultValue = defaultValues[pageParam.type];

			switch (pageParam.persistence) {
				case 'none':
					return { key, value: searchParams[key] ?? defaultValue };

				case 'session':
					return { key, value: session[key] ?? defaultValue };

				case 'local':
					return { key, value: local[key] ?? defaultValue };

				case 'global':
					return { key, value: globalP[key] ?? defaultValue };
			}
		});

		const pageParams = Object.fromEntries(
			pageParamsArray.map(({ key, value }) => [key, value])
		) as PageParams<PPC>;

		setPageParamsNoUpdate(pageParams);

		return [pageParams, setPageParams];
	}
}

// Helper function
function parseParams(searchString: string, config: PageParamsConfig) {
	const parsedParams = Object.fromEntries(new URLSearchParams(searchString));
	const result = { ...parsedParams } as PageParamsWeak;

	for (const key in config) {
		if (!(key in parsedParams)) continue;

		const { type } = config[key];
		switch (type) {
			case 'string':
				break;
			case 'number':
				result[key] = Number(parsedParams[key]);
				break;
			case 'boolean':
				result[key] = Boolean(parsedParams[key]);
				break;
			case 'stringArray':
				result[key] = parsedParams[key].split(',');
				break;
			case 'numberArray':
				result[key] = parsedParams[key].split(',').map((value) => Number(value));
				break;
			case 'sort': {
				const splitted = parsedParams[key].split(':');
				result[key] = { value: splitted[0], operator: splitted[1] };
				break;
			}
		}
	}

	return result;
}

// Helper function
function stringifyParams<PPC extends PageParamsConfig>(pageParams: PageParams<PPC>, config: PPC) {
	const result = new URLSearchParams();

	for (const key in pageParams) {
		const value = pageParams[key];
		const { type } = config[key];

		// For a pretty browser url, do not write params with default values there.
		if (isEqual(value, defaultValues[type])) {
			continue;
		}

		let stringifiedValue = '';

		switch (type) {
			case 'string':
			case 'number':
			case 'boolean':
				stringifiedValue = String(value);
				break;
			case 'stringArray':
			case 'numberArray': {
				const valueTS = value as unknown[];
				stringifiedValue = valueTS.join(',');
				break;
			}
			case 'sort': {
				const valueTS = value as { value: string; operator: TOrder };
				stringifiedValue = `${valueTS.value}:${valueTS.operator}`;
				break;
			}
		}

		result.set(key, stringifiedValue);
	}

	let searchString = result.toString();
	if (searchString) {
		searchString = `?${searchString}`;
	}

	searchString = searchString.replaceAll('%3A', ':').replaceAll('%2C', ',');

	return searchString;
}

function usePageParams<PPC extends PageParamsConfig>(
	config: PPC,
	storageKey?: string
): [PageParams<PPC>, (params: PageParams<PPC>) => void] {
	const result = usePageParamsUnmemoized(config, storageKey);

	const previous = useRef(result);

	if (!isEqual(result, previous.current)) {
		previous.current = result;
	}

	return previous.current;
}

export { usePageParams };
export type { PageParamsConfig, PageParams };
