import { createEffect } from 'effector';
import { getRecentActivity } from './api';
import { RecentActivityJson } from './dto';

/* API */
const getRecentActivityFx = createEffect<void, RecentActivityJson>();

/* IMPLEMENTATION */
// Simple direct-to-api effects
getRecentActivityFx.use(getRecentActivity);

export { getRecentActivityFx };
