const defaultConfig = {
	rootId: 'root',
	rootInspectorId: 'data-test-inspector',
	debugFlag: 'DATA_TEST_DEBUG',
};

interface IDataTestInspector {
	// DOM container in which be added data-test-inspector action buttons
	rootId?: string;
	// prefix for data-test-inspector id and classes
	rootInspectorId?: string;
	debugFlag?: string;
}

export const DataTestInspector = ({
	rootId = defaultConfig.rootId,
	rootInspectorId = defaultConfig.rootInspectorId,
	debugFlag = defaultConfig.debugFlag,
}: IDataTestInspector = defaultConfig) => {
	const queryParams = new URLSearchParams(window.location.search);
	if (!queryParams.has(debugFlag)) {
		return;
	}

	let prevUrl = '';
	let timeout: NodeJS.Timer;

	// timeout need to not to conflict with app render
	setTimeout(() => {
		init();
		timeout = setInterval(urlChecker, 500);
	}, 1000);

	const selectorClass = `${rootInspectorId}-class`;
	const showDataTestButtonId = `${rootInspectorId}-show-button`;
	const deleteDataTestButtonId = `${rootInspectorId}-delete-button`;

	/*
		Insert data-test-inspector root container in DOM with 2 control buttons
	 */
	const init = () => {
		const root = document.getElementById(rootId);

		if (document.getElementById(showDataTestButtonId) !== null) {
			return;
		}

		if (!root) {
			console.log(`Element #${rootId} does not exist in DOM`);
			return;
		}

		const inspectorDiv = document.createElement('div');
		inspectorDiv.setAttribute('id', rootInspectorId);
		inspectorDiv.setAttribute('style', 'position: fixed; left: 0; top: 0; z-index: 999999');

		const button = document.createElement('button');
		button.setAttribute('id', showDataTestButtonId);
		button.addEventListener('click', displaySelectorsLayer);
		button.innerText = 'Show data-test';

		const deleteButton = document.createElement('button');
		deleteButton.setAttribute('id', deleteDataTestButtonId);
		deleteButton.addEventListener('click', destroy);
		deleteButton.innerText = 'Delete data-test inspector';

		inspectorDiv.appendChild(button);
		inspectorDiv.appendChild(deleteButton);

		root.appendChild(inspectorDiv);
	};

	/*
		Remove all data-test-inspector controls and selector layers
	 */
	const destroy = () => {
		document.getElementById(rootInspectorId)?.remove();
		deleteSelectorsLayer();
		clearInterval(timeout);
	};

	const deleteSelectorsLayer = () => {
		const elems = document.getElementsByClassName(selectorClass);

		while (elems[0]) {
			elems[0]?.parentNode?.removeChild(elems[0]);
		}
	};

	/*
		Checking url to hide selectors layer when url change
	 */
	const urlChecker = () => {
		if (prevUrl !== location.href) {
			prevUrl = location.href;
			deleteSelectorsLayer();
			changeDataTestButtonTitle('Show');
		}
	};

	const getDiv = (innerText: string | null, parentNode: HTMLElement) => {
		const x = parentNode.getBoundingClientRect().left;
		const { top } = parentNode.getBoundingClientRect();
		// need to not to overlap selectors by control buttons
		const y = top < 20 ? 20 : top;

		const delta = innerText && innerText.endsWith('list') ? -8 : 0;

		const style = `
		position: absolute;
		left: ${x + delta}px;
		top: ${y + delta}px;
		color: #fff;
		background: #000;
		z-index: 99999;
		opacity: 0.1;
		padding: 1px 3px;
		cursor: pointer;
	`;
		const div = document.createElement('div');
		div.className = selectorClass;
		div.setAttribute('style', style);
		div.innerText = innerText || 'data-test';

		let parentNodeOutline: string;

		div.addEventListener('mouseenter', () => {
			parentNodeOutline = parentNode.style.outline;
			parentNode.style.outline = '1px solid red';

			div.style.opacity = '0.8';
		});

		div.addEventListener('mouseleave', () => {
			parentNode.style.outline = parentNodeOutline;

			div.style.opacity = '0.1';
		});

		div.addEventListener('click', () => {
			// copying data-test value and displaying inspected node in console
			const dataTestValue = parentNode.getAttribute('data-test');
			navigator.clipboard.writeText(String(dataTestValue)).then(() => {
				showPopup(`<strong>${dataTestValue}</strong> copied`);
			});

			console.log(parentNode);
		});

		return div;
	};

	const changeDataTestButtonTitle = (title: string) => {
		const button = document.getElementById(showDataTestButtonId);

		if (button) {
			button.innerText = `${title} data-test`;
		}
	};

	/*
		Layer with all data-test selectors
	 */
	const displaySelectorsLayer = () => {
		prevUrl = location.href;

		const elems = document.getElementsByClassName(selectorClass);
		if (elems.length) {
			deleteSelectorsLayer();
			changeDataTestButtonTitle('Show');
		} else {
			const root = document.getElementById(rootId);
			const selectors: NodeListOf<HTMLElement> = document.querySelectorAll('[data-test]');

			selectors.forEach((elem) => {
				const dataTest = elem.getAttribute('data-test');

				root?.appendChild(getDiv(dataTest, elem));
			});

			changeDataTestButtonTitle('Hide');
		}
	};

	const showPopup = (textMessage: string) => {
		document.getElementById(`${rootInspectorId}-popup`)?.remove();

		const root = document.getElementById(rootId);
		const popupStyles = `
			position: fixed;
			width: 500px;
			left: 50%;
			top: 50px;
			margin-left: -250px;
			padding: 5px;
			border-radius: 8px;
			background: green;
			color: white;
			text-align: center;
			z-index: 99999999;
			transition: opacity 0.3s linear 1s;
		`;
		const popupDiv = document.createElement('div');
		popupDiv.setAttribute('id', `${rootInspectorId}-popup`);
		popupDiv.setAttribute('style', popupStyles);
		popupDiv.innerHTML = textMessage;

		root?.appendChild(popupDiv);

		setTimeout(() => {
			const popup = document.getElementById(`${rootInspectorId}-popup`);
			if (popup) {
				popup.style.opacity = '0';
			}
		}, 100);
	};
};
