import cn from 'classnames';
import { useStore } from 'effector-react';
import { ReleaseJson } from 'models/release/dto';
import { releaseStore } from 'models/release/store';
import styles from './index.module.pcss';

type SensorType = 'dim' | 'dar';
type DIMVersionsProps = { versions: ReleaseJson['dim'] };
type DARVersionsProps = { versions: ReleaseJson['dar'] };
type MessageProps = { type: SensorType };
type Props = { type: SensorType; critical: boolean };

const helmChartLink = (
	<a rel="nofollow noreferrer" target="_blank" href="https://github.com/soverenio/helm-charts">
		the Soveren Helm repository
	</a>
);
const releaseNotesLinkDIM = (
	<a
		rel="nofollow noreferrer"
		target="_blank"
		href="https://github.com/soverenio/helm-charts/tree/master/charts/soveren-agent/release-notes.md"
	>
		release notes
	</a>
);
const releaseNotesLinkDAR = (
	<a
		rel="nofollow noreferrer"
		target="_blank"
		href="https://github.com/soverenio/helm-charts/tree/master/charts/soveren-dar-sensor/release-notes.md"
	>
		release notes
	</a>
);

const RecommendedUpdateMessage = ({ type }: MessageProps) => (
	<div className={styles.line}>
		This update provides important bug fixes and is recommended for all users. Visit {helmChartLink}{' '}
		and {type === 'dim' ? releaseNotesLinkDIM : releaseNotesLinkDAR} for more info.
	</div>
);

const CriticalUpdateMessage = ({ type }: MessageProps) => (
	<div className={styles.line}>
		This update provides essential bug fixes and is recommended for all users. Please be aware that
		the version of the sensor you currently have is no longer supported. Visit {helmChartLink} and{' '}
		{type === 'dim' ? releaseNotesLinkDIM : releaseNotesLinkDAR} for more info.
	</div>
);

const DIMVersions = ({ versions }: DIMVersionsProps) => {
	return (
		<div className={cn(styles.line, styles.versions)}>
			<div>Digger: {versions.digger_version}</div>
			<div>Interceptor: {versions.interceptor_version}</div>
			<div>Detector: {versions.detection_tool_version}</div>
		</div>
	);
};

const DARVersions = ({ versions }: DARVersionsProps) => {
	return (
		<div className={cn(styles.line, styles.versions)}>
			<div>Crawler: {versions.crawler_version}</div>
			<div>Detector: {versions.detection_tool_version}</div>
		</div>
	);
};

const ReleaseUpdateMessage = ({ type, critical }: Props) => {
	const release = useStore(releaseStore);

	return (
		<>
			<div className={styles.line}>The new package includes:</div>

			{type === 'dim' ? (
				<DIMVersions versions={release.dim} />
			) : (
				<DARVersions versions={release.dar} />
			)}

			{critical ? <CriticalUpdateMessage type={type} /> : <RecommendedUpdateMessage type={type} />}
		</>
	);
};

export { ReleaseUpdateMessage };
