import dayjs, { Dayjs } from 'dayjs';
/* eslint-disable import/default */
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';
import utcPlugin from 'dayjs/plugin/utc';

// https://day.js.org/docs/en/plugin/relative-time
dayjs.extend(relativeTime);
dayjs.extend(utcPlugin);
dayjs.extend(updateLocale);

dayjs.updateLocale('en', {
	relativeTime: {
		...dayjs.Ls.en.relativeTime,
		s: '%d seconds',
	},
});

enum DateFormat {
	cutDate = 'MMM DD',
	date = 'MMM DD, YYYY',
	utc = 'YYYY-MM-DD HH:mm:ss [UTC]',
	cutUtc = 'MMM DD[, at] HH:mm:ss [UTC]',
	dateWithWeekday = 'dddd, MMM DD',
	fullTime = 'HH:mm:ss',
	datePicker = 'DD/MM/YYYY',
	timestamp = 'DD/MM/YYYY, HH:mm:ss',
}

function format(date: dayjs.ConfigType, template = DateFormat.utc) {
	// TODO SMAT-2873 not automatically .utc(), why?
	return dayjs(date).format(template);
}

export { format, dayjs, DateFormat };
export type { Dayjs };
