import cn from 'classnames';
import { useStore } from 'effector-react';
import Icon from 'components/Icon';
import Preloader from 'components/Preloader';
import Typo from 'components/typography/Typo';
import { recentActivityStore } from 'models/recentActivity/store';
import { toLocaleString } from 'services/numbers';
import styles from './index.module.pcss';

function formatWithDeltaSign(n: number) {
	const deltaSign = n >= 0 ? '+ ' : '- ';

	return deltaSign + toLocaleString(Math.abs(n));
}

function TotalNumbers() {
	const { api_calls } = useStore(recentActivityStore);

	return (
		<Preloader isLoading={false}>
			<div className={styles.container}>
				<Icon name="chartLine" size={28} />

				<Typo color="secondary" className={styles.capLabel} variant="D/Medium/Meta-S-CAP">
					API calls (7d)
				</Typo>

				<Typo variant="D/Medium/H500-Greeting-Title" data-test="api-calls-count">
					{toLocaleString(api_calls.weekly)}
				</Typo>

				<Typo
					color="secondary"
					variant="D/Regular/Meta"
					className={cn({ [styles.zeroDelta]: api_calls.weekly_delta === 0 })}
				>
					{formatWithDeltaSign(api_calls.weekly_delta)}
				</Typo>
			</div>
		</Preloader>
	);
}

export default TotalNumbers;
