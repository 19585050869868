//
// Tech debt:
// 		- using 'ReactElement | null' as a return type and wrapping in Fragment
//		- using Obj and typecasting - in typeguards
//		- button color="ghost", but we re-define colors in css. Probably should be its own type?
//		- what to do with Popover in components/Popover
//		- no good fade for Select - blue background of selected option #1 immediately shows, that fade on :before is always present
//			- for MultiSelect fade works better, but still not perfect. In many cases, it does not overlap bottommost visible element
//

import { MouseEvent, MutableRefObject, ReactElement, useEffect, useRef, useState } from 'react';
import DropdownButton, { DropdownButtonProps } from './DropdownButton';
import Label, { LabelProps } from './Label';
import OptionItem, { OptionItemProps } from './OptionItem';
import OptionList, { OptionListProps } from './OptionList';
import Popover, { PopoverProps } from './Popover';
import Search, { SearchProps } from './Search';

//
// Considerations:
//		- how to data-test

type Obj = { [key: string | number | symbol]: unknown }; // helper

type StringOption = string;
type Option = {
	id: string | number;
	name: string; // 'name' was chosen because many DTOs have it already
} & Obj; // any other fields are allowed in options, to make integration easier - in many cases no additional mapping for DTO will be required

type ComplexOption = unknown;
type AnyOption = StringOption | Option | ComplexOption;

// Helper typeGuards
function isStringOption(option: AnyOption): option is StringOption {
	return typeof option === 'string';
}
function isOption(value: AnyOption): value is Option {
	if (typeof value !== 'object' || value === null) return false;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const option: Obj = value as any; // for Typescript

	return (
		(typeof option.id === 'string' || typeof option.id === 'number') &&
		typeof option.name === 'string'
	);
}

type SelectProps<T extends AnyOption> = {
	// Required options
	label: string | { primary: string; secondary: string };
	options: T[];
	onChange: (value: T) => void;
	value: T;

	// Options for extra out-of-the-box functionality
	closeRef?: MutableRefObject<Function>;
	dataTest?: string;
	defaultOpen?: boolean;
	hasSearch?: boolean;
	onClose?: () => void;

	// Render overrides for individual components inside Select
	render?: {
		dropdownButton?: (props: DropdownButtonProps) => ReactElement | null;
		label?: (props: LabelProps) => ReactElement | null;
		optionItem?: (props: OptionItemProps<T>) => ReactElement | null;
		optionList?: (props: OptionListProps<T>) => ReactElement | null;
		popover?: (props: PopoverProps) => ReactElement | null;
		search?: (props: SearchProps) => ReactElement | null;
	};
};

function Select<T extends AnyOption>(props: SelectProps<T>) {
	const {
		closeRef,
		defaultOpen,
		hasSearch = false,
		label,
		options,
		onChange,
		onClose,
		render = {},
		value,
	} = props;

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const dropdownButtonRef = useRef<HTMLButtonElement>(null);

	useEffect(() => {
		if (defaultOpen) setAnchorEl(dropdownButtonRef.current);
	}, [defaultOpen]);

	function handleOpen(event: MouseEvent<HTMLElement>) {
		setAnchorEl(event.currentTarget);
	}

	function handleClose() {
		setAnchorEl(null);

		onClose?.();
	}

	if (closeRef) {
		closeRef.current = handleClose;
	}

	// Composable parts
	const DropdownButtonComponent = render.dropdownButton || DropdownButton;
	const LabelComponent = render.label || Label;
	const OptionItemComponent = render.optionItem || OptionItem;
	const OptionListComponent = render.optionList || OptionList;
	const PopoverComponent = render.popover || Popover;
	const SearchComponent = hasSearch ? render.search || Search : () => null;

	return (
		<>
			<DropdownButtonComponent onClick={handleOpen} open={!!anchorEl} buttonRef={dropdownButtonRef}>
				<LabelComponent label={label} />
			</DropdownButtonComponent>

			<PopoverComponent anchorEl={anchorEl} onClose={handleClose}>
				<OptionListComponent
					onChange={onChange}
					onClose={handleClose}
					options={options}
					renderOption={OptionItemComponent}
					renderSearch={SearchComponent}
					value={value}
				/>
			</PopoverComponent>
		</>
	);
}

export default Select;
export { isStringOption, isOption };
export type { Option, AnyOption, SelectProps };
