import { CheckboxBase } from 'components/Checkbox';
import { isOption, isStringOption, Option } from 'components/form/Select';
import MenuItem from 'components/MenuItem';
import styles from './index.module.css';

type OptionItemProps<T> = {
	option: T | Option; // ` | Option` comes from the need to render allOption somehow. Subject to discussion / possible tech debt
	selected: boolean;
	searchString: string;
	onClick: () => void;
};

// Common overrides: search highlight; custom display e.g. 'Item (found in 90 assets)'
function OptionItem<T>(props: OptionItemProps<T>) {
	const { option, selected, searchString, onClick } = props;

	let optionText = '';

	if (isStringOption(option)) {
		optionText = option;
	} else if (isOption(option)) {
		optionText = option.name;
	} else {
		optionText = JSON.stringify(option);
	}

	const isSearched = searchString
		? optionText.toLocaleLowerCase().includes(searchString.toLocaleLowerCase())
		: true;

	return isSearched ? (
		<MenuItem size="small" onClick={onClick}>
			<CheckboxBase checked={selected} className={styles.checkbox} />
			{optionText}
		</MenuItem>
	) : null;
}

export default OptionItem;
export type { OptionItemProps };
