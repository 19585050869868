import cn from 'classnames';
import { CSSProperties, useMemo } from 'react';
import Typo from 'components/typography/Typo';
import { fileTypeLabels } from 'models/s3BucketCluster/dto';
import { S3BucketStatus } from 'models/s3Buckets/dto';
import styles from './index.module.css';

type Props = {
	progress?: S3BucketStatus['progress'];
	className?: string;
};

function FileTypesBar(props: Props) {
	const { progress = [], className } = props;

	// Logic: from server data
	//   - show all analyzable types
	//   - images (if exist), videos (if exist), docs (if exist)
	//   - if anything else exists, show as Other.
	const processedProgress = useMemo(() => {
		const analyzable = progress
			.filter((section) => section.is_analyzable)
			.sort((a, b) => {
				const progressDelta = a.progress - b.progress;
				if (progressDelta === 0) return a.type.localeCompare(b.type);
				return progressDelta;
			});
		const fixedNonAnalyzable = ['image', 'video', 'document']
			.map((type) => progress.find((section) => section.type === type))
			.filter((section) => !!section) as S3BucketStatus['progress'];

		let result = analyzable.concat(fixedNonAnalyzable);
		if (result.length < progress.length) {
			result = result.concat({ type: 'other', is_analyzable: false, progress: 0 });
		}

		return result;
	}, [progress]);

	if (!props.progress) return null;

	return (
		<div className={cn(styles.container, className)}>
			{processedProgress.map((section) => (
				<FileTypeSection section={section} key={section.type} />
			))}
		</div>
	);
}

type SectionProps = {
	section: S3BucketStatus['progress'][number];
};

function FileTypeSection(props: SectionProps) {
	const { type, is_analyzable, progress } = props.section;

	return (
		<div className={styles.section} data-test="s3-file-type-bar">
			<div
				className={cn(styles.progressBar, styles[type])}
				style={{ '--progress': `${progress}%` } as CSSProperties}
			>
				{is_analyzable && <div className={cn(styles.inProgress, progress < 100 && styles.wave)} />}
			</div>
			<Typo variant="D/Medium/Meta">{fileTypeLabels[type]}</Typo>
		</div>
	);
}

export { FileTypesBar };
