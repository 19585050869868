import deepmerge from 'lodash.merge';
import { ApiParams } from 'services/api';
import dropSession from 'services/dropSession';
import httpRequest, { APIError } from './httpRequest';

/*
	Added value:
		- transparent black box for secure requests
			Sets requests on pause if token goes stale, refreshes token, then
			resumes requests.
			Handles concurrent request cases too.

		- handling access token state

		- normalizing token errors
*/

class TokenRefreshError extends Error {
	constructor(
		public response: Response,
		msg: string
	) {
		super(msg);
	}
}

const _getToken = () => localStorage.getItem('Token') || '';
const _setToken = (token: string | null) => localStorage.setItem('Token', token || '');
const _clearToken = () => localStorage.removeItem('Token');

let _currentTokenPromise: Promise<void> | undefined;

async function getCurrentToken() {
	await _currentTokenPromise;
	return _getToken();
}

async function clearCurrentToken() {
	await _currentTokenPromise;
	return _clearToken();
}

// Explicitly returns nothing.
function refreshCurrentToken() {
	if (_currentTokenPromise) {
		return; // Do nothing - already trying to refresh token from server.
	}

	_currentTokenPromise = httpRequest('v1/token', undefined, { method: 'POST' })
		.then((response) => {
			if (!response.headers.has('Token')) {
				const msg = `Refresh token response does not contain token in header. Code: ${response.status}`;
				throw new TokenRefreshError(response, msg);
			}

			_setToken(response.headers.get('Token'));
		})
		.catch((error) => {
			if (error instanceof APIError) {
				const msg = `Refresh token request failed. Code: ${error.response.status}`;
				throw new TokenRefreshError(error.response, msg);
			}

			throw error;
		})
		.finally(() => {
			// every time refresh token ends - clear promise to unblock secureRequest function
			_currentTokenPromise = undefined;
		});
}

// Make request with token. Re-query token if receive 419 status
async function secureRequest(
	url: string,
	params?: ApiParams,
	config?: RequestInit
): Promise<Response> {
	const token = await getCurrentToken();

	const configWithAuth = deepmerge({ headers: { Authorization: `Bearer ${token}` } }, config);

	try {
		const response = await httpRequest(url, params, configWithAuth);

		// every response headers can set token // TODO SMAT-2875 ask backends if this is still relevant
		if (response.headers.has('Token')) {
			_setToken(response.headers.get('Token'));
		}

		return response;
	} catch (error) {
		if (error instanceof APIError && error.response.status === 419) {
			refreshCurrentToken();

			// try to request again with new token
			return secureRequest(url, params, config);
		}

		throw error;
	}
}

dropSession.watch(clearCurrentToken);

export default secureRequest;
export { TokenRefreshError };
