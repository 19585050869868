import MenuItem from 'components/MenuItem';
import styles from './index.module.css';
import { isStringOption, isOption } from '.';

type OptionItemProps<T> = {
	option: T;
	selected: boolean;
	searchString: string;
	onClick: () => void;
};

// Common overrides: search highlight; custom display e.g. 'Item (found in 90 assets)'
function OptionItem<T>(props: OptionItemProps<T>) {
	const { option, selected, searchString, onClick } = props;

	let optionText = '';

	if (isStringOption(option)) {
		optionText = option;
	} else if (isOption(option)) {
		optionText = option.name;
	} else {
		optionText = JSON.stringify(option);
	}

	const isSearched = searchString
		? optionText.toLocaleLowerCase().includes(searchString.toLocaleLowerCase())
		: true;

	return isSearched ? (
		<MenuItem
			size="small"
			onClick={onClick}
			selected={selected}
			className={styles.paddingForCheckmark}
		>
			{optionText}
		</MenuItem>
	) : null;
}

export default OptionItem;
export type { OptionItemProps };
