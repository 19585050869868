import { CSSProperties } from 'react';
import { ZoomContextType } from '../ZoomWrapper';

const isometricRatio = 1.2;

const ratioList = {
	300: 1,
	250: 1.2,
	200: 1.5,
	150: 2,
	100: 3,
	50: 6,
};

const commonFont = {
	fontWeight: 500,
	fontStyle: 'normal',
	fontVariantNumeric: 'normal',
	letterSpacing: 0,
};

function getIsometricRatio(scaleStep: ZoomContextType['scaleStep']) {
	return ratioList[scaleStep] * isometricRatio;
}

function getFontStyles(fontSize: number, lineHeight: number) {
	return function getScaledFontStyles(
		scaleStep: ZoomContextType['scaleStep'],
		isometric?: boolean
	) {
		const ratio = isometric ? getIsometricRatio(scaleStep) : ratioList[scaleStep];

		return {
			...commonFont,
			fontSize: `${fontSize * ratio}px`,
			lineHeight: `${lineHeight * ratio}px`,
			...getScaledLabelStyles(scaleStep),
		};
	};
}

function getScaledLabelStyles(scaleStep: ZoomContextType['scaleStep']) {
	return {
		padding: `${ratioList[scaleStep]}px ${2 * ratioList[scaleStep]}px`,
		borderRadius: 4 * ratioList[scaleStep],
	};
}

function getScaledLabelHighlightStyles(scaleStep: ZoomContextType['scaleStep']) {
	return {
		...getScaledLabelStyles(scaleStep),
		margin: `${-3 * ratioList[scaleStep]}px ${-2 * ratioList[scaleStep]}px ${
			-1 * ratioList[scaleStep]
		}px`,
		maxWidth: `calc(100% + ${ratioList[scaleStep] * 2 * 2}px)`,
	};
}

const fontBodySMedium = getFontStyles(16, 24);
const fontMetaSMedium = getFontStyles(14, 20);
const fontInterMedium = getFontStyles(10, 12);

const scaleList: ZoomContextType['scaleStep'][] = [300, 250, 200, 150, 100, 50];

type TConfigStyle = {
	cubeLabel: CSSProperties;
	cubeLabelHighlight: CSSProperties;
	ragName: CSSProperties;
	rag: CSSProperties;
};

const stylesConfig: { [key: number]: TConfigStyle } = {
	300: {
		cubeLabel: {
			...fontBodySMedium(300),
			maxWidth: 212 * ratioList[300],
		},
		cubeLabelHighlight: {
			...getScaledLabelHighlightStyles(300),
		},
		ragName: fontBodySMedium(300, true),
		rag: {
			borderRadius: `${9 * ratioList[300]}px`,
			borderWidth: `${2 * ratioList[300]}px`,
		},
	},
	250: {
		cubeLabel: {
			...fontBodySMedium(250),
			maxWidth: 176 * ratioList[250],
		},
		cubeLabelHighlight: {
			...getScaledLabelHighlightStyles(250),
		},
		ragName: fontBodySMedium(250, true),
		rag: {
			borderRadius: `${9 * ratioList[250]}px`,
			borderWidth: `${2 * ratioList[250]}px`,
		},
	},
	200: {
		cubeLabel: {
			...fontBodySMedium(200),
			maxWidth: 142 * ratioList[200],
		},
		cubeLabelHighlight: {
			...getScaledLabelHighlightStyles(200),
		},
		ragName: fontBodySMedium(200, true),
		rag: {
			borderRadius: `${9 * ratioList[200]}px`,
			borderWidth: `${2 * ratioList[200]}px`,
		},
	},
	150: {
		cubeLabel: {
			...fontMetaSMedium(150),
			maxWidth: 108 * ratioList[150],
		},
		cubeLabelHighlight: {
			...getScaledLabelHighlightStyles(150),
		},
		ragName: fontMetaSMedium(150, true),
		rag: {
			borderRadius: `${9 * ratioList[150]}px`,
			borderWidth: `${2 * ratioList[150]}px`,
		},
	},
	100: {
		cubeLabel: {
			...fontInterMedium(100),
			maxWidth: 72 * ratioList[100],
			display: 'none',
		},
		cubeLabelHighlight: {
			...getScaledLabelHighlightStyles(100),
		},
		ragName: fontInterMedium(100, true),
		rag: {
			borderRadius: `${9 * ratioList[100]}px`,
			borderWidth: `${2 * ratioList[100]}px`,
		},
	},
	50: {
		cubeLabel: {
			...fontInterMedium(50),
			maxWidth: 32 * ratioList[50],
			display: 'none',
		},
		cubeLabelHighlight: {
			...getScaledLabelHighlightStyles(50),
		},
		ragName: fontInterMedium(50, true),
		rag: {
			borderRadius: `${9 * ratioList[50]}px`,
			borderWidth: `${2 * ratioList[50]}px`,
		},
	},
};

type TArrowStyle = {
	arrowPosition: number;
	strokeWidth: number;
	strokeWidthHighlighted: number;
	strokeDasharray: string;
	offset: string;
};

const arrowsStylesConfig: { [key: string]: TArrowStyle } = {};

scaleList.forEach(
	(scale) =>
		(arrowsStylesConfig[scale] = {
			arrowPosition: scale <= 100 ? -20 : 12 * ratioList[scale],
			strokeWidth: 3 * ratioList[scale],
			strokeWidthHighlighted: 4 * ratioList[scale],
			strokeDasharray: `${6 * ratioList[scale]} ${8 * ratioList[scale]}`,
			offset: `translate(${-9 * ratioList[scale]}px, ${-9 * ratioList[scale]}px)`,
		})
);

export { stylesConfig, arrowsStylesConfig };
