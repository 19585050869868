import { get } from 'api/Api';
import { ApiParams } from 'services/api';
import checkResponse from 'services/api/checkResponse';
import { AssetIPsJson, AssetUserAgentsJson, AssetRecipientsJson } from './dto';
import {
	assetIPsJsonExample,
	assetUserAgentsJsonExample,
	assetRecipientsJsonExample,
} from './examples';

async function getAssetIPs(id: number, apiParams: ApiParams, signal?: AbortSignal) {
	const config = signal ? { signal } : {};

	return get<AssetIPsJson>(`v2/assets/${id}/ips`, apiParams, config).then(
		checkResponse(assetIPsJsonExample)
	);
}

async function getAssetUserAgents(id: number, apiParams: ApiParams, signal?: AbortSignal) {
	const config = signal ? { signal } : {};

	return get<AssetUserAgentsJson>(`v2/assets/${id}/user-agents`, apiParams, config).then(
		checkResponse(assetUserAgentsJsonExample)
	);
}

async function getAssetRecipients(id: number, apiParams: ApiParams, signal?: AbortSignal) {
	const config = signal ? { signal } : {};

	return get<AssetRecipientsJson>(`v2/assets/${id}/recipients`, apiParams, config).then(
		checkResponse(assetRecipientsJsonExample)
	);
}

export { getAssetIPs, getAssetUserAgents, getAssetRecipients };
