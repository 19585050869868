import { createEffect } from 'effector';
import { getDataTypes } from './api';
import { DataTypeItem } from './dto';

/* API */

export const getDataTypesFx = createEffect<void, DataTypeItem[]>();

/* IMPLEMENTATION */

// Simple direct-to-api effects

getDataTypesFx.use(async function () {
	const { data_types } = await getDataTypes();

	return data_types.map((dt) => {
		const { found_in_dim, found_in_dar, ...dataType } = dt;

		return {
			...dataType,
			isUsed: found_in_dim || found_in_dar,
			isGroup: dataType.child_ids.length > 0,
		};
	});
});
