import { useStore } from 'effector-react';
import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import NonEmptyPiiSwitch from 'components/PiiGlobalFilterV2/NonEmptyPiiSwitch';
import Preloader from 'components/Preloader';
import Typo from 'components/typography/Typo';
import { BreadcrumbProps, Breadcrumbs } from 'layouts/AuthorizedWithLeftMenu/Breadcrumbs';
import styles from './index.module.css';
import { headerStore, setHeader } from './model';

interface HeaderProps {
	breadcrumbProps?: BreadcrumbProps;
	isLoading?: boolean;
	showSensitiveSwitcher?: boolean;
	titleBlock?: null | JSX.Element | string;
	topRightBlock?: null | JSX.Element;
}

type TitleProps = {
	children: HeaderProps['titleBlock'];
};

function Title({ children }: TitleProps) {
	const location = useLocation();
	let pageTitle: HeaderProps['titleBlock'] = useMemo(() => {
		const name = location.pathname.split('/')[1];
		return name.replaceAll('-', ' ').replace(/^\w/, (letter) => letter.toLocaleUpperCase());
	}, [location]);

	if (children) {
		if (typeof children === 'string') {
			pageTitle = children;
		} else {
			return children;
		}
	}

	return (
		<Typo dataTest="header-title" variant="D/Medium/H400-Page-Title">
			{pageTitle}
		</Typo>
	);
}

function HeaderLayout() {
	const {
		breadcrumbProps,
		isLoading = false,
		showSensitiveSwitcher,
		titleBlock,
		topRightBlock,
	} = useStore(headerStore);

	return (
		<div className={styles.container}>
			<Preloader isLoading={isLoading}>
				<div className={styles.leftBlock}>
					<Breadcrumbs {...breadcrumbProps} />

					<div className={styles.titleBlock}>
						<Title>{titleBlock}</Title>
					</div>
				</div>

				<div className={styles.rightBlock}>
					{topRightBlock}

					{showSensitiveSwitcher && <NonEmptyPiiSwitch />}
				</div>
			</Preloader>
		</div>
	);
}

function Header(props: HeaderProps) {
	useEffect(() => {
		setHeader(props);
	}, [props]);

	return null;
}

export type { HeaderProps };
export { HeaderLayout };
export default Header;
