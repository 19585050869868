import { EventSampleObject } from 'models/eventsV2/dto';
import { CSVViewer } from 'views/common/SampleViewer/CSVViewer';
import { JSONViewer } from 'views/common/SampleViewer/JSONViewer';
import { LogViewer } from 'views/common/SampleViewer/LogViewer';
import { YAMLViewer } from 'views/common/SampleViewer/YAMLViewer';
import { EventWithSample, isDIMEvent } from './';

interface Props {
	sample: EventSampleObject;
	event: EventWithSample;
	activePath?: string;
	size?: 'M' | 'S';
}

export function ViewerBlock({ sample, event, size }: Props) {
	if (!isDIMEvent(event) && event.object_type === 'Database') {
		return <CSVViewer {...sample} database />;
	}

	switch (sample.type) {
		case 'csv':
			return <CSVViewer {...sample} />;
		case 'log':
			return <LogViewer {...sample} />;
		case 'json':
			return <JSONViewer {...sample} size={size} />;
		case 'yaml':
			return <YAMLViewer {...sample} />;
		default:
			return null;
	}
}
