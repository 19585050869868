// Non-specific types
type ListElement = {
	[key: string]: unknown; // should be comparable. TODO try smth like 'string | number | boolean | null | undefined'
};

type Branch<BranchFields extends object, Leaf extends object> =
	| Leaf
	| (BranchFields & {
			children: Branch<BranchFields, Leaf>[];
	  });

// Buusiness-specific types
type PseudoRag<Data, ExtraFields> = {
	id: number;
	level: 'pseudoRag';
	data: (Data & ExtraFields)[];
} & ExtraFields;

type RealRagFields<Level, ExtraFields> = {
	id: number;
	level: Level;
	name: string;
} & ExtraFields;

type Rag<Data, Level, ExtraFields = {}> = Branch<
	RealRagFields<Level, ExtraFields>,
	PseudoRag<Data, ExtraFields>
>;

function isPseudoRag<T, U, V>(rag: Rag<T, U, V>): rag is PseudoRag<T, V> {
	return rag.level === 'pseudoRag';
}

const DEFAULT_SORT_PROPERTY_FALLBACK = 'name';

function sortBySeveralProperties<Element extends ListElement>(
	elements: Element[],
	properties: string[]
) {
	return [...elements].sort(function (a, b) {
		for (const property of properties) {
			if (!a[property] && !b[property]) continue;
			if (!a[property]) return 1;
			if (!b[property]) return -1;
			// @ts-ignore "TS2531: Object is possibly 'null'." No, it is not - see above.
			if (a[property] > b[property]) return 1;
			// @ts-ignore "TS2531: Object is possibly 'null'." No, it is not - see above.
			if (a[property] < b[property]) return -1;
		}

		return 0;
	});
}

function findIndexStartingFrom<T>(
	elements: T[],
	startIndex: number,
	testFn: (element: T) => boolean
) {
	for (let i = startIndex; i < elements.length; i++) {
		if (testFn(elements[i])) return i;
	}

	return -1;
}

function listToTreeByAttributes<Element extends ListElement, Level extends string>(
	elements: Element[],
	levels: Level[]
) {
	const sorted = sortBySeveralProperties(
		elements,
		(levels as string[]).concat(DEFAULT_SORT_PROPERTY_FALLBACK)
	);

	let counter = 0;
	function getNextNumber(): number {
		return counter++;
	}

	return {
		id: 0,
		level: '_root' as const,
		name: '',
		children: collect(sorted, levels, getNextNumber),
	};
}

// Elements must be sorted for algorithm to work.
function collect<Element extends ListElement, Level extends string>(
	elements: Element[],
	_levels: Level[],
	getNextNumber: () => number
): Rag<Element, Level>[] {
	const levels = [..._levels];
	const result: Rag<Element, Level>[] = [];

	let level = levels.shift();
	let index = 0;
	let element = elements[index];

	while (level !== undefined) {
		while (element !== undefined) {
			if (!element[level]) break; // Better luck next level!

			const nextIndex = findIndexStartingFrom(
				elements,
				index,
				(el) => el[level as Level] !== element[level as Level]
			); // It's either 'as Level' here, or make 'findIndexStartingFrom()' inline. Reason: TS loses type narrowing once we enter the callback function. It does not know if callback is called synchronously.

			const continuousSlice =
				nextIndex === -1 ? elements.slice(index) : elements.slice(index, nextIndex);

			result.push({
				id: getNextNumber(),
				level,
				name: element[level] as string,
				children: collect(continuousSlice, levels, getNextNumber),
			});

			index += continuousSlice.length;
			element = elements[index];
		}

		level = levels.shift();
	}

	if (element) {
		result.push({
			id: getNextNumber(),
			level: 'pseudoRag' as const,
			data: elements.slice(index),
		});
	}

	return result;
}

export default listToTreeByAttributes;
export { isPseudoRag };
export type { ListElement, Rag, PseudoRag };
