import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useImmer } from 'use-immer';
import Button from 'components/form/Button';
import TextField from 'components/form/TextField';
import Icon from 'components/Icon';
import { enqueueSnackbar } from 'components/Snackbar';
import Typo from 'components/typography/Typo';
import { getAssetsFx } from 'models/assets/effects';
import { AssetGroup } from 'models/assetsGroups/dto';
import { createAssetGroupFx, updateAssetGroupFx } from 'models/assetsGroups/effects';
import { APIError } from 'services/api/httpRequest';
import { PATHS } from 'services/router';
import PolicyRules, { EMPTY_RULE } from 'views/Policy/PolicyItem/PolicyItemForm/PolicyRules';
import { Footer } from './Footer';
import styles from './index.module.css';

type Props = {
	data: AssetGroup;
};

const ALREADY_EXIST_REGEXP = /name/;

const AssetGroupForm = ({ data }: Props) => {
	const [formData, setFormData] = useImmer(data);
	const [groupNameError, setGroupNameError] = useState('');
	const [groupDescriptionError, setGroupDescriptionError] = useState('');
	const [groupOwnerError, setGroupOwnerError] = useState('');

	const scrollRef = useRef<HTMLDivElement>(null);
	const scrollToTop = () => {
		if (scrollRef.current) {
			scrollRef.current.scrollIntoView({ behavior: 'smooth' });
		}
	};

	useEffect(() => {
		getAssetsFx();
	}, []);

	useEffect(() => {
		setFormData(data);
	}, [data]);

	const history = useHistory();

	const isNew = useMemo(() => formData.id === 0, [data.id]);

	const setName = useCallback((name) => {
		setFormData((draft) => {
			draft.name = name;
		});

		if (name.length > 255) {
			setGroupNameError('Maximum Name length is 255');
		} else {
			setGroupNameError('');
		}
	}, []);

	const setDescription = useCallback((description) => {
		setFormData((draft) => {
			draft.description = description;
		});

		if (description.length > 4096) {
			setGroupDescriptionError('Maximum description length is 4096');
		} else {
			setGroupDescriptionError('');
		}
	}, []);

	const setOwner = useCallback((owner) => {
		setFormData((draft) => {
			draft.owner = owner;
		});

		if (owner.length > 255) {
			setGroupOwnerError('Maximum owner length is 255');
		} else {
			setGroupOwnerError('');
		}
	}, []);

	const addRule = useCallback(() => {
		setFormData((draft) => {
			draft.rules.push([EMPTY_RULE]);
		});
	}, []);

	const onRulesChange = useCallback(
		function onRulesChange(mutatorFn) {
			setFormData((draft) => {
				const partialState = draft.rules;
				const possibleResult = mutatorFn(partialState);
				if (possibleResult !== undefined) draft.rules = possibleResult;

				// Support case when we delete all rules
				draft.rules = draft.rules.filter((complexRule) => complexRule.length !== 0);
				if (draft.rules.length === 0) {
					draft.rules.push([EMPTY_RULE]);
				}
			});
		},
		[formData]
	);

	const saveGroup = async () => {
		const saveHandler = isNew ? createAssetGroupFx : updateAssetGroupFx;

		try {
			await saveHandler(formData);
		} catch (error) {
			if (error instanceof APIError && error.response.status === 400) {
				const { message } = await error.response.json();

				if (ALREADY_EXIST_REGEXP.test(message)) {
					setGroupNameError(message);
					scrollToTop();
				} else {
					enqueueSnackbar(message);
				}
				return;
			}

			throw error;
		}

		history.push(PATHS.ASSET_GROUP_LIST);
		enqueueSnackbar('Group has been saved');
	};

	return (
		<div className={styles.container} ref={scrollRef}>
			<div className={styles.textFieldList}>
				<Typo variant="D/Medium/H100-Header">Group details</Typo>

				<TextField
					fullWidth
					dataTest="group-name-input"
					label="Name"
					value={formData.name}
					onChange={(e) => setName(e.target.value)}
					helperText={groupNameError}
					error={!!groupNameError}
					optional={false}
					autoFocus={true}
				/>

				<TextField
					fullWidth
					minRows={2}
					optional={false}
					dataTest="group-description-input"
					label="Description"
					placeholder="Group description"
					multiline={true}
					value={formData.description}
					onChange={(e) => setDescription(e.target.value)}
					helperText={groupDescriptionError}
					error={!!groupDescriptionError}
				/>

				<TextField
					fullWidth
					dataTest="group-owner-input"
					label="Owner"
					value={formData.owner}
					onChange={(e) => setOwner(e.target.value)}
					helperText={groupOwnerError}
					error={!!groupOwnerError}
					optional={false}
				/>
			</div>

			<div className={styles.groupRules}>
				<Typo variant="D/Medium/H100-Header">Services included</Typo>

				<PolicyRules rules={formData.rules} onChange={onRulesChange} hideAssetGroups={true} />

				<Button
					data-test="group-add-rule-button"
					size="small"
					color="transparent"
					onClick={addRule}
					className={styles.addRuleButton}
				>
					<Icon name="Add/Regular" size={20} />
					Add rule
				</Button>
			</div>

			<Footer
				isNew={isNew}
				isError={!!groupNameError || !!groupDescriptionError || !!groupOwnerError}
				saveForm={saveGroup}
			/>
		</div>
	);
};

export default AssetGroupForm;
