import cn from 'classnames';
import GatewayStatusIcon from 'components/GatewayStatusIcon';
import { SecretToken } from 'components/SecretToken';
import secretTokenStyles from 'components/SecretToken/index.module.pcss';
import GridRow from 'components/table/GridRow';
import { GatewayJson } from 'models/gateways/dto';
import GatewayDelete from '../../DeleteSensor';
import GatewayName from '../GatewayName';
import { GatewayRegion } from '../GatewayRegion';
import gatewayStyles from '../index.module.css';
import styles from './index.module.pcss';

type Props = {
	gateway: GatewayJson;
};

function GatewayItem(props: Props) {
	const { gateway } = props;

	return (
		<GridRow
			className={cn(gatewayStyles.rowContainer, styles.rowContainer)}
			hover
			border
			data-test="gateway-item-row"
		>
			<div className={styles.statusContainer}>
				<GatewayStatusIcon status={gateway.status} hoverable={false} />

				<GatewayName gateway={gateway} />
			</div>

			<GatewayRegion sensor={gateway} type="dim" />

			<SecretToken
				canBeCopied
				value={gateway.token}
				InputProps={{ classes: { root: secretTokenStyles.inRow } }}
				helperText={null}
				className={styles.token}
			/>

			<GatewayDelete sensor={gateway} type="DIM" />
		</GridRow>
	);
}

export default GatewayItem;
