import { useStore } from 'effector-react';
import { useCallback, useMemo } from 'react';
import { piiFilterStore } from 'components/PiiGlobalFilterV2/model';
import PiiType from 'components/PiiType';
import Tooltip from 'components/Tooltip';
import Typo from 'components/typography/Typo';
import { DataTypeItem } from 'models/dataTypes/dto';
import styles from './index.module.pcss';

type Props = {
	dataTypes: DataTypeItem['id'][];
	limit?: number;
};

function DataTypesWithMore(props: Props) {
	const { dataTypes, limit = 4 } = props;
	const piiFilter = useStore(piiFilterStore);

	const dataTypesSortedByHighlight = useMemo(() => {
		const highlighted = dataTypes.filter((dt) => piiFilter.dataTypes.includes(dt));
		const notHighlighted = dataTypes.filter((dt) => !piiFilter.dataTypes.includes(dt));

		return highlighted.concat(notHighlighted);
	}, [dataTypes, piiFilter.dataTypes]);

	const chipsInListCount =
		dataTypesSortedByHighlight.length > limit ? limit - 1 : dataTypesSortedByHighlight.length;

	const chipsInList = dataTypesSortedByHighlight.slice(0, chipsInListCount);
	const chipsInTooltip = dataTypesSortedByHighlight.slice(chipsInListCount);

	const renderChip = useCallback(
		(dt: DataTypeItem['id']) => (
			<PiiType
				key={dt}
				size="extraSmall"
				highlighted={piiFilter.dataTypes.includes(dt)}
				className={styles.typeItem}
				type={dt}
			/>
		),
		[piiFilter.dataTypes]
	);

	return (
		<div className={styles.typeContainer}>
			{chipsInList.map(renderChip)}

			{chipsInTooltip.length > 0 && (
				<Tooltip
					placement="top"
					title={<div className={styles.typeTooltip}>{chipsInTooltip.map(renderChip)}</div>}
				>
					<Typo variant="D/Regular/Meta" color="secondary">
						+{chipsInTooltip.length} more
					</Typo>
				</Tooltip>
			)}
		</div>
	);
}

export default DataTypesWithMore;
