import { generatePath, useHistory } from 'react-router-dom';
import GridCell from 'components/table/GridCell';
import GridRow from 'components/table/GridRow';
import Typo from 'components/typography/Typo';
import { AssetGroupTable } from 'models/assetsGroups/dto';
import { dayjs } from 'services/dayjs';
import { PATHS } from 'services/router';
import { DASH_SIGN } from 'services/strings';
import styles from '../index.module.css';

type Props = {
	group: AssetGroupTable;
};

function AssetGroupTableItem({ group }: Props) {
	const { id, name, assets, owner, created_at } = group;
	const history = useHistory();

	function onRowClick() {
		const path = generatePath(PATHS.ASSET_GROUP_ITEM, {
			id,
		});
		history.replace(path + history.location.search);
	}

	return (
		<GridRow
			data-test="group-table-row"
			className={styles.rowContainer}
			onClick={onRowClick}
			hover
			border
		>
			<GridCell className={styles.gapCell} verticalAlign="middle">
				<Typo variant="D/Regular/Body-S">{name}</Typo>
			</GridCell>

			<GridCell align="right" verticalAlign="middle">
				<Typo dataTest="group-row-assets-counter" variant="D/Regular/Body-S">
					{assets}
				</Typo>
			</GridCell>

			<GridCell verticalAlign="middle">
				<Typo variant="D/Regular/Body-S">{owner || DASH_SIGN}</Typo>
			</GridCell>

			<GridCell verticalAlign="middle">
				<Typo variant="D/Regular/Body-S">{dayjs(created_at).format('MMM DD, YYYY')}</Typo>
			</GridCell>
		</GridRow>
	);
}

export default AssetGroupTableItem;
