import Chip from 'components/Chip';
import { S3BucketItem } from 'models/s3Buckets/dto.ts';
import { DASH_SIGN } from 'services/strings';
import styles from './index.module.css';

interface TagProps {
	keyValue: string;
	value: string;
}

function S3BucketTag({ keyValue, value }: TagProps) {
	return (
		<Chip
			color="secondary"
			size="small"
			label={
				<>
					<span className={styles.ellipsisText}>{keyValue}</span>:
					<span className={styles.ellipsisText}>{value}</span>
				</>
			}
		/>
	);
}
function S3BucketTagList({ tags }: { tags: S3BucketItem['tags'] }) {
	return (
		<span data-test="s3-bucket-info-tags" className={styles.list}>
			{tags.length === 0 && DASH_SIGN}

			{tags.map((tag) => (
				<S3BucketTag key={`${tag.key}-${tag.value}`} keyValue={tag.key} value={tag.value} />
			))}
		</span>
	);
}

export { S3BucketTag, S3BucketTagList };
