import { useStore } from 'effector-react';
import { produce } from 'immer';
import ButtonIcon from 'components/ButtonIcon';
import { changeMapControls, mapControlsStore } from '../model/store';

function CloseButton() {
	const mapControls = useStore(mapControlsStore);

	function onClose() {
		const newControls = produce(mapControls, (draft) => {
			draft.selected = null;
		});

		changeMapControls(newControls);
	}

	return <ButtonIcon icon="Dismiss/Regular" onClick={onClose} />;
}

export default CloseButton;
