import cn from 'classnames';
import { useMemo } from 'react';
import ButtonIcon from 'components/ButtonIcon';
import { ConfirmModal, getConfirmation } from 'components/ConfirmModal';
import { SecretToken } from 'components/SecretToken';
import secretTokenStyles from 'components/SecretToken/index.module.pcss';
import { enqueueSnackbar } from 'components/Snackbar';
import GridCell from 'components/table/GridCell';
import GridRow from 'components/table/GridRow';
import rowStyles from 'components/table/GridRow/index.module.pcss';
import Typo from 'components/typography/Typo';
import { deleteExternalAPIToken } from 'models/integrations/externalAPI/api';
import { ExternalAPIToken } from 'models/integrations/externalAPI/dto';
import { DateFormat, dayjs } from 'services/dayjs';
import styles from './index.module.css';
import TokenName from './TokenName';

type Props = {
	token: ExternalAPIToken;
	refresh: () => void;
};

function TokenRow(props: Props) {
	const { token, refresh } = props;

	async function confirm(tokenName: string) {
		const confirmDeletionModal = (
			<ConfirmModal
				title="Delete API token"
				confirmProps={{
					children: 'Delete',
				}}
				disableUntilEntered={tokenName}
			>
				<Typo variant="D/Regular/Body-S" className={styles.text}>
					API token{' '}
					<Typo variant="D/Medium/Body-S" component="span">
						“<span data-test="token-delete-name">{tokenName}</span>”
					</Typo>{' '}
					will be deleted. Data analysis and monitoring will stop.
					<br />
					Enter the API token name to confirm the deletion.
				</Typo>
			</ConfirmModal>
		);

		return await getConfirmation(confirmDeletionModal);
	}

	const onDelete = async () => {
		if (!(await confirm(token.name))) {
			return;
		}

		try {
			await deleteExternalAPIToken(token.id);
		} catch (e) {
			console.error(e);
		}

		enqueueSnackbar('API token deleted');

		refresh();
	};

	const createdAt = useMemo(() => {
		return dayjs(token.created_at).utc().format(DateFormat.utc);
	}, [token.created_at]);

	return (
		<GridRow className={styles.rowContainer} hover border data-test="api-token-row">
			<GridCell>
				<TokenName token={token} refresh={props.refresh} />
				<Typo variant="D/Regular/Meta" color="secondary" className={styles.createdAt}>
					Created {createdAt}
				</Typo>
			</GridCell>

			<GridCell>
				<SecretToken
					canBeCopied
					value={token.token}
					InputProps={{
						classes: { root: cn(styles.tokenCell, secretTokenStyles.inRow) },
					}}
					helperText={null}
				/>
			</GridCell>

			<GridCell className={styles.deleteContainer}>
				<ButtonIcon
					className={rowStyles.showWhenFocus}
					dataTest="token-delete"
					icon="Delete/Filled"
					onClick={onDelete}
					size="M"
				/>
			</GridCell>
		</GridRow>
	);
}

export default TokenRow;
