import { useState } from 'react';
import Button from 'components/form/Button';
import { goBackByDefault } from 'services/history';
import { PATHS } from 'services/router';
import styles from './index.module.css';

export const PolicyFormFooter = ({
	isNew = false,
	isError = false,
	saveForm,
}: {
	isNew: boolean;
	isError: boolean;
	saveForm: () => void;
}) => {
	const [isLoading, setIsLoading] = useState(false);

	return (
		<>
			<Button
				color="secondary"
				size="medium"
				onClick={() => goBackByDefault(PATHS.POLICY_LIST)}
				data-test="policy-form-cancel"
			>
				Cancel
			</Button>

			<Button
				data-test="policy-form-create-save"
				type="submit"
				color="primary"
				size="medium"
				onClick={async () => {
					setIsLoading(true);
					await saveForm();
					setIsLoading(false);
				}}
				disabled={isLoading || isError}
				className={styles.saveButton}
			>
				{isNew ? 'Create and enable' : 'Save'}
			</Button>
		</>
	);
};
