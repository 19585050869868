import { del, get, post } from 'api/Api';
import checkResponse from 'services/api/checkResponse';
import {
	JiraIntegrationCreate,
	JiraSettings,
	JiraSettingsUpdate,
	JiraProjects,
	JiraTicketFields,
} from './dto';
import { jiraSettingsExample, jiraProjectsExample, jiraTicketFieldsExample } from './examples';

async function createJiraIntegration(payload: JiraIntegrationCreate) {
	return post('v1/jira-integration/base', payload); // No response, or meaningful 422 error struct
}

async function deleteJiraIntegration() {
	return del('v1/jira-integration');
}

async function getJiraSettings() {
	return get<JiraSettings>('v1/jira-integration').then(
		checkResponse<JiraSettings>(jiraSettingsExample)
	);
}

async function updateJiraSettings(payload: JiraSettingsUpdate) {
	return post<JiraSettings>('v1/jira-integration/details', payload).then(
		checkResponse<JiraSettings>(jiraSettingsExample)
	);
}

async function getJiraProjects() {
	return get<JiraProjects>('v1/jira-integration/projects').then(
		checkResponse<JiraProjects>(jiraProjectsExample)
	);
}

async function getJiraTicketFields(projectId: string) {
	return get<JiraTicketFields>(`v1/jira-integration/projects/${projectId}/issue-fields`).then(
		checkResponse<JiraTicketFields>(jiraTicketFieldsExample)
	);
}

export {
	createJiraIntegration,
	deleteJiraIntegration,
	getJiraSettings,
	updateJiraSettings,
	getJiraProjects,
	getJiraTicketFields,
};
