import { useStore } from 'effector-react';
import { useCallback, useEffect, useMemo } from 'react';
import MultiSelect from 'components/form/MultiSelect';
import DropdownButton from 'components/form/Select/DropdownButton';
import { AssetJson } from 'models/assets/dto';
import { getAssetsFx } from 'models/assets/effects';
import { assetsList } from 'models/assets/store';
import { onceFx } from 'models/modelUtils/onceFx';
import { FilterPropsBase } from '../model';

type Option = { id: AssetJson['name']; name: AssetJson['name'] };

const EXCLUDED_ASSET_TYPES = ['endusers', 'robots', 'other_in', 'custom'];

type Props = FilterPropsBase & {
	value: AssetJson['name'][];
	onChange: (newValue: AssetJson['name'][]) => void;
};

function AssetFilter({ defaultOpen, fixed, onChange, onClose, resetFilter, value }: Props) {
	const assetsListStore = useStore(assetsList);

	useEffect(() => {
		onceFx(getAssetsFx);
	}, []);

	const groupedItems: Option[] = useMemo(() => {
		const result: {
			[key: string]: Option;
		} = {};

		for (const assetItem of assetsListStore) {
			// The following asset types can not contain endpoints
			if (EXCLUDED_ASSET_TYPES.includes(assetItem.type)) {
				continue;
			}

			result[assetItem.name] = result[assetItem.name] || {
				id: assetItem.name,
				name: assetItem.name,
			};
		}

		return Object.values(result).sort((a, b) => a.name.localeCompare(b.name));
	}, [assetsListStore]);

	const values = useMemo(() => {
		return groupedItems.filter((val) => value.includes(val.name));
	}, [value, groupedItems]);

	function handleChange(options: Option[]) {
		onChange(options.map((option) => option.name));
	}

	const dropdownButton = useCallback(
		(dropdownProps) => <DropdownButton {...dropdownProps} fixed={fixed} onClose={resetFilter} />,
		[fixed, resetFilter]
	);

	return (
		<MultiSelect
			defaultOpen={defaultOpen}
			label={{ primary: 'Services', secondary: `${values.length || ''}` }}
			options={groupedItems}
			value={values}
			onChange={handleChange}
			onClose={onClose}
			render={{ dropdownButton }}
			hasSearch
			hasApplyButton
		/>
	);
}

export default AssetFilter;
