import { generatePath } from 'react-router';
import Typo from 'components/typography/Typo';
import { kafkaTopicsSteps } from 'layouts/AuthorizedWithLeftMenu/Breadcrumbs';
import Header from 'layouts/AuthorizedWithLeftMenu/Header';
import { KafkaTopicSample } from 'models/kafkaTopics/dto';
import { DateFormat, dayjs } from 'services/dayjs';
import { toLocaleString } from 'services/numbers';
import { PATHS } from 'services/router';
import S3BucketHeaderStyles from 'views/SuperAssets/S3Buckets/S3BucketItemView/S3BucketHeader/index.module.pcss';
import styles from './index.module.css';

type Props = {
	sample: Pick<
		KafkaTopicSample,
		| 'topic_id'
		| 'topic_name'
		| 'partition'
		| 'offset'
		| 'message_timestamp'
		| 'schema_id'
		| 'schema_name'
	> | null;
};

function TopicSampleHeader({ sample }: Props) {
	if (!sample) return null;

	const { topic_id, topic_name, partition, offset, message_timestamp, schema_id, schema_name } =
		sample;
	const title = `offset: ${toLocaleString(offset)}; partition: ${partition}`;
	const timestamp = dayjs(message_timestamp).format(DateFormat.timestamp);

	return (
		<Header
			breadcrumbProps={{
				steps: [
					...kafkaTopicsSteps,
					{
						path: generatePath(PATHS.KAFKA_TOPICS_ITEM, {
							id: topic_id,
							tab: 'summary',
						}),
						name: topic_name,
					},
					{
						path: generatePath(PATHS.KAFKA_TOPICS_ITEM, {
							id: topic_id,
							tab: 'message-schemas',
						}),
						name: 'Message schemas',
					},
					{
						path: generatePath(PATHS.KAFKA_MESSAGE_SCHEMA_ITEM, {
							topicId: topic_id,
							schemaId: schema_id,
						}),
						name: schema_name,
					},
				],
				finalStep: title,
			}}
			titleBlock={
				<div>
					<Typo
						variant="D/Medium/H400-Page-Title"
						dataTest="topic-sample-header-name"
						className={S3BucketHeaderStyles.ellipsisText}
					>
						{title}
					</Typo>

					<Typo variant="D/Regular/Body-S" color="secondary" className={styles.timestamp}>
						{timestamp}
					</Typo>
				</div>
			}
		/>
	);
}

export { TopicSampleHeader };
