import styles from './index.module.css';

/* Initialize debug settings */
const queryParams = new URLSearchParams(window.location.search);

const debugSettings = JSON.parse(sessionStorage.getItem('svrn-debug') || '{}');

if (queryParams.has('debug')) {
	debugSettings.enabled = true;
}

if (queryParams.has('FLAG_PERFORMANCE')) {
	debugSettings.FLAG_PERFORMANCE = true;
}

/* TODO SMAT 3804 - remove DEBUG FLAG */
if (queryParams.has('FLAG_AWS_CONNECTIONS')) {
	debugSettings.FLAG_AWS_CONNECTIONS = true;
}

sessionStorage.setItem('svrn-debug', JSON.stringify(debugSettings));

/* Component */

function DebugOverlay() {
	if (!debugSettings.enabled) {
		return null;
	}

	function onClose() {
		sessionStorage.setItem('svrn-debug', '{}');
	}

	return (
		<div className={styles.container}>
			<p>
				Debug info{' '}
				<a href="/" onClick={onClose}>
					[clear all flags]
				</a>
			</p>

			{/* TODO SMAT 3804 - remove DEBUG FLAG */}
			<p>
				Active flags: [{debugSettings.FLAG_AWS_CONNECTIONS && 'FLAG_AWS_CONNECTIONS'}{' '}
				{debugSettings.FLAG_PERFORMANCE && 'FLAG_PERFORMANCE'}]
			</p>
		</div>
	);
}

export { debugSettings };
export default DebugOverlay;
