import cn from 'classnames';
import { ChangeEvent, FocusEvent, KeyboardEvent, useState } from 'react';
import ButtonIcon from 'components/ButtonIcon';
import Button from 'components/form/Button';
import TextField from 'components/form/TextField';
import Loader from 'components/Loader';
import rowStyles from 'components/table/GridRow/index.module.pcss';
import Typo from 'components/typography/Typo';
import styles from './index.module.css';

type Props = {
	className?: string;
	dataTest?: string;
	multiline?: boolean;
	onSave: (modifiedValue: string) => Promise<null | string>; // If string is returned, it's error message
	placeholder?: string;
	value: string;
};

function EditableInput(props: Props) {
	const { className, dataTest, multiline, placeholder, onSave, value } = props;

	const [modifiedValue, setModifiedValue] = useState(value);
	const [showInput, setShowInput] = useState(!value);
	const [showButtons, setShowButtons] = useState(false);
	const [isLoading, setLoading] = useState(false);
	const [error, setError] = useState<null | string>(null);

	function onClickEdit() {
		setModifiedValue(value);
		setShowInput(true);
		setShowButtons(true);
		setError(null);
		// Focusing is handled by autoFocus prop
	}

	function handleInputFocus() {
		setShowButtons(true);
		setError(null);
	}

	function handleKeyDown(e: KeyboardEvent<HTMLInputElement>) {
		if (e.key === 'Enter') {
			handleSave();
		} else if (e.key === 'Escape') {
			onCancel();
		}
	}

	function handleChange(e: ChangeEvent<HTMLInputElement>) {
		setModifiedValue(e.target.value);
	}

	async function handleSave() {
		setLoading(true);
		setShowInput(false);
		setShowButtons(false);

		const maybeError = await onSave(modifiedValue);

		setLoading(false);

		if (maybeError) {
			setError(maybeError);
			setShowInput(true);
			// Do not set buttons to true!
		} else {
			setShowInput(!modifiedValue);
		}
	}

	function onCancel() {
		setShowInput(!value);
		setShowButtons(false);
		setModifiedValue(value);
	}

	function handleContainerBlur(e: FocusEvent<HTMLDivElement>) {
		if (!e.currentTarget.contains(e.relatedTarget as Node)) {
			// https://stackoverflow.com/questions/61164018/typescript-ev-target-and-node-contains-eventtarget-is-not-assignable-to-node

			// Focus has left the container. We can use Tab to go to buttons, but as soon as we click outside (or Tab outside), we cancel edit.
			onCancel();
		}
	}

	return (
		<div
			className={cn(styles.container, className, {
				[styles.multiContainer]: multiline && !showButtons,
			})}
			onBlur={handleContainerBlur}
			data-test={dataTest || 'editable-input-container'}
		>
			{showInput ? (
				<TextField
					value={modifiedValue}
					onChange={handleChange}
					autoFocus={!error && !!value}
					onFocus={handleInputFocus}
					onKeyDown={handleKeyDown}
					optional={false}
					helperText={null}
					error={!!error}
					size="extraSmall"
					data-test="editable-input"
					placeholder={placeholder}
					className={styles.input}
				/>
			) : (
				<Typo
					variant="D/Regular/Body-S"
					className={cn(styles.value, { [styles.multiValue]: multiline })}
				>
					{isLoading ? modifiedValue : value}
				</Typo>
			)}

			{showButtons && (
				<>
					<Button
						size="extraSmall"
						color="tertiary"
						onClick={handleSave}
						className={cn(styles.editButton, styles.saveButton)}
						data-test="editable-input-save-button"
						disabled={modifiedValue === value}
					>
						Save
					</Button>

					<Button
						size="extraSmall"
						color="transparent"
						onClick={onCancel}
						className={cn(styles.editButton, styles.cancelButton)}
						data-test="editable-input-cancel-button"
					>
						Cancel
					</Button>
				</>
			)}

			{!showInput && !isLoading && (
				<ButtonIcon
					className={cn(styles.pencilButton, rowStyles.showWhenFocus)}
					dataTest="editable-input-edit-button"
					icon="pencil"
					onClick={onClickEdit}
					size="XS"
				/>
			)}

			{/* Size is wierd, but this size is size of icon, not whole component */}
			{isLoading && <Loader size={15} className={styles.loader} />}

			{!!error && (
				<Typo variant="D/Regular/Meta-S" className={styles.error}>
					{error}
				</Typo>
			)}
		</div>
	);
}

export { EditableInput };
