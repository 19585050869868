import { useStore } from 'effector-react';
import Label from 'components/Label';
import Switch from 'components/Switch';
import { piiFilterStore, setPiiFilter } from './model';

type Props = {
	className?: string;
};

function NonEmptyPiiSwitch({ className }: Props) {
	const { nonEmpty } = useStore(piiFilterStore);

	return (
		<Label className={className} dataTest="pii-global-filter">
			<Switch
				checked={nonEmpty}
				onChange={({ target }) => setPiiFilter({ nonEmpty: target.checked })}
				dataTest={nonEmpty ? 'pii-global-filter-value-ON' : 'pii-global-filter-value-OFF'}
			/>
			With sensitive data only
		</Label>
	);
}

export default NonEmptyPiiSwitch;
