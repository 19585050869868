type Rect = { width: number; height: number; x: number; y: number };
type Props = {
	scale: number;
	parentRect: Pick<Rect, 'width' | 'height'>;
	contentRect: Rect;
};

const getXZero = (rect: Rect, scale: number) => -rect.x * scale;

/*
	ALGORITHM:
		1. 	Finding the lowest ratio between parent and content containers widths and heights.
				Lowest ratio indicates which side (horizontal or vertical) should be selected to stretch to full screen.

		2. 	Calculating next scale.
				Lowest ratio shows how many times the previous scale is less than the next scale.

		3. 	Calculating next translation.
				- zero for lowest-ratio-side
				- half of different between parent and content containers (unscaled)
 */
const fitToScreenValues = ({ scale, parentRect, contentRect }: Props) => {
	let nextScale: number;
	const nextTranslation = {
		x: 0,
		y: 0,
	};
	// if "zero" value is not equal to 0
	let xZero: number;
	const yZero = 0;

	const contentRectUnscaled = {
		width: contentRect.width * scale,
		height: contentRect.height * scale,
	};

	// 1. Finding the lowest ratio
	const widthRatio = parentRect.width / contentRectUnscaled.width;
	const heightRatio = parentRect.height / contentRectUnscaled.height;
	const lowestRatio = widthRatio > heightRatio ? 'height' : 'width';

	if (lowestRatio === 'width') {
		// 2. Calculating next scale
		nextScale = scale * widthRatio;
		xZero = getXZero(contentRect, nextScale);

		const nextScaleHelperHeight = contentRectUnscaled.height * widthRatio;

		// 3. Calculating next translation
		nextTranslation.y = yZero + (parentRect.height - nextScaleHelperHeight) / 2;
		nextTranslation.x = xZero;
	} else {
		// 2. Calculating next scale
		nextScale = scale * heightRatio;
		xZero = getXZero(contentRect, nextScale);

		const nextScaleHelperWidth = contentRectUnscaled.width * heightRatio;

		// 3. Calculating next translation
		nextTranslation.x = xZero + (parentRect.width - nextScaleHelperWidth) / 2;
		nextTranslation.y = yZero;
	}

	return {
		scale: nextScale,
		translation: nextTranslation,
	};
};

const TRANSLATION_PADDING = 100;
const translationBoundsValues = ({ parentRect, contentRect, scale }: Props) => {
	const content = {
		width: contentRect.width * scale,
		height: contentRect.height * scale,
	};
	const xZero = getXZero(contentRect, scale);
	const yZero = 0;
	const xMin = xZero - content.width + TRANSLATION_PADDING;
	const yMin = yZero - content.height + TRANSLATION_PADDING;
	const xMax = xZero + parentRect.width - TRANSLATION_PADDING;
	const yMax = yZero + parentRect.height - TRANSLATION_PADDING;

	const boundaries = { xMin, yMin, xMax, yMax };

	// padding correction.
	// because we have a TRANSLATION_PADDING, we can have min > max with similar xy values
	if (boundaries.xMin > boundaries.xMax) boundaries.xMax = boundaries.xMin;
	if (boundaries.yMin > boundaries.yMax) boundaries.yMax = boundaries.yMin;

	return boundaries;
};

export { fitToScreenValues, translationBoundsValues };
