type Size = 'S' | 'M' | 'L' | 'XL';

/*
 *	Calculates the best font size for rag, based on the rags size
 *	@return S, M, L or XL
 */
const ragTitleSize = (width: number, height: number): Size => {
	const minimumSide = Math.min(width, height);

	let sizeValue: Size;
	switch (true) {
		case minimumSide > 2000:
			sizeValue = 'XL';
			break;
		case minimumSide > 1000:
			sizeValue = 'L';
			break;
		case minimumSide > 500:
			sizeValue = 'M';
			break;

		default:
			sizeValue = 'S';
	}

	return sizeValue;
};

export { ragTitleSize };
