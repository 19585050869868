import { useEffect, useState } from 'react';
import commonStyles from 'assets/styles/index.module.pcss';
import Button from 'components/form/Button';
import Icon from 'components/Icon';
import Preloader from 'components/Preloader';
import GridBody from 'components/table/GridBody';
import GridCell from 'components/table/GridCell';
import GridHead from 'components/table/GridHead';
import GridRow from 'components/table/GridRow';
import GridTable from 'components/table/GridTable';
import { RouterLink } from 'components/typography/Link';
import Typo from 'components/typography/Typo';
import { integrationsSteps } from 'layouts/AuthorizedWithLeftMenu/Breadcrumbs';
import Header from 'layouts/AuthorizedWithLeftMenu/Header';
import { getExternalAPITokens } from 'models/integrations/externalAPI/api';
import { ExternalAPIToken, ExternalAPITokenList } from 'models/integrations/externalAPI/dto';
import { externalAPITokenExample } from 'models/integrations/externalAPI/examples';
import { PATHS } from 'services/router';
import { NeedHelpBlock } from 'views/common/NeedHelpBlock';
import styles from './index.module.css';
import TokenRow from './TokenRow';

const skeletonContent: ExternalAPIToken[] = [
	{ ...externalAPITokenExample, id: 1, name: 'Demo token' },
	{ ...externalAPITokenExample, id: 2, name: 'Cron-job assets/endpoints scraper' },
	{ ...externalAPITokenExample, id: 3, name: 'Developer access token' },
];

function Settings() {
	const [isLoading, setLoading] = useState(true);
	const [tokens, setTokens] = useState<ExternalAPIToken[]>([]);

	useEffect(() => {
		getExternalAPITokens().then((payload: ExternalAPITokenList) => {
			setTokens(payload.tokens);
			setLoading(false);
		});
	}, []);

	function refresh() {
		setLoading(true);

		getExternalAPITokens().then((payload: ExternalAPITokenList) => {
			setTokens(payload.tokens);
			setLoading(false);
		});
	}

	const hasTokens = tokens.length > 0;

	const tokensOrSkeleton: ExternalAPIToken[] = hasTokens ? tokens : skeletonContent;

	return (
		<>
			<Header
				breadcrumbProps={{
					steps: integrationsSteps,
					finalStep: 'API Tokens',
				}}
				titleBlock={
					<>
						<Typo variant="D/Medium/H400-Page-Title">API Tokens</Typo>

						{hasTokens && (
							<RouterLink to={PATHS.INTEGRATIONS_EXTERNAL_API_CREATE_TOKEN}>
								<Button size="small" data-test="create-api-token-in-header">
									<Icon name="Add/Regular" size={20} className={styles.plusIcon} /> New API Token
								</Button>
							</RouterLink>
						)}
					</>
				}
			/>

			<Preloader isLoading={isLoading}>
				<div className={styles.container}>
					<GridTable dataTest="api-tokens-table" className={styles.table}>
						<GridHead>
							<GridRow head className={styles.rowContainer}>
								<GridCell head>Name</GridCell>

								<GridCell head>Token</GridCell>
							</GridRow>
						</GridHead>

						<GridBody className={hasTokens ? undefined : commonStyles.skeletonMode}>
							{tokensOrSkeleton.map((token) => (
								<TokenRow key={token.id} token={token} refresh={refresh} />
							))}
						</GridBody>
					</GridTable>

					{hasTokens ? (
						<NeedHelpBlock />
					) : (
						<div className={styles.noTokensBlock}>
							<Typo variant="D/Medium/Body">No API tokens</Typo>

							<Typo
								color="secondary"
								variant="D/Regular/Body-S"
								className={styles.noTokensDescription}
							>
								You have not created any API tokens yet.
							</Typo>

							<RouterLink to={PATHS.INTEGRATIONS_EXTERNAL_API_CREATE_TOKEN}>
								<Button data-test="create-api-token">
									<Icon name="Add/Regular" className={styles.plusIcon} /> New API Token
								</Button>
							</RouterLink>
						</div>
					)}
				</div>
			</Preloader>
		</>
	);
}

export default Settings;
