import { IHeadCell } from 'components/table/EnhancedTableHead';
import { PolicyItem } from 'models/policies/dto';

const tableConfig: IHeadCell<PolicyItem>[] = [
	{
		id: 'name',
		numeric: false,
		label: 'Policy',
		sortable: false,
	},
	{
		id: 'data_types',
		numeric: false,
		label: 'Data types',
		sortable: false,
	},
	{
		id: 'created_at',
		numeric: false,
		label: 'Created',
		sortable: true,
	},
	{
		id: 'is_active',
		numeric: true,
		label: 'Violations tracking',
		sortable: false,
	},
];

export default tableConfig;
