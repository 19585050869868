import { TOrder } from 'models/common/dto';
import { getDataTypesAsApiParam } from 'models/dataTypes/helpers';
import { createTableModel } from 'models/modelUtils/tableModelFactory';
import { ApiParams } from 'services/api';
import { getS3Buckets, getS3BucketDataFlows } from './api';
import { S3BucketItem, S3BucketTableParams, S3BucketDataFlowsTableItem } from './dto';

// Buckets store

function fetchDataApi(apiParamsRaw: ApiParams, signal?: AbortSignal) {
	const { 'data-types': piiTypes, ...apiParams } = apiParamsRaw;

	apiParams['data-types'] = getDataTypesAsApiParam(piiTypes);

	return getS3Buckets(apiParams, signal).then((payload) => {
		return {
			data: payload.buckets,
			total: payload.total,
			total_filtered: payload.total_filtered,
		};
	});
}

function createS3BucketsModel(initialParams: S3BucketTableParams, fetchFxOnInit: boolean = true) {
	return createTableModel<S3BucketItem, S3BucketTableParams>(
		initialParams,
		fetchDataApi,
		fetchFxOnInit
	);
}

//
// Instantiate model with initial params, but do not fetch data from server.
//

const initialParams: S3BucketTableParams = {
	regions: [],
	'data-types': [],
	search: '',
	sort: { orderBy: 'last_checked', order: 'desc' },
};

const s3BucketModel = createS3BucketsModel(initialParams, false);

// Data flows store

type S3BucketDataFlowParams = {
	bucketId: number;
	name: string;
	sort: {
		orderBy: keyof S3BucketDataFlowsTableItem;
		order: TOrder;
	};
};

function fetchDataFlowApi(apiParamsRaw: ApiParams, signal?: AbortSignal) {
	const { bucketId, ...apiParams } = apiParamsRaw;

	return getS3BucketDataFlows(Number(bucketId), apiParams, signal).then((payload) => {
		return {
			data: payload.dataflows,
			total: payload.total,
			total_filtered: payload.total_filtered,
		};
	});
}

function createBucketDataFlowModel(
	initialDataFlowParams: S3BucketDataFlowParams,
	fetchFxOnInit: boolean = true
) {
	return createTableModel<S3BucketDataFlowsTableItem, S3BucketDataFlowParams>(
		initialDataFlowParams,
		fetchDataFlowApi,
		fetchFxOnInit
	);
}

//
// Instantiate model with initial params, but do not fetch data from server.
//

const initialDataFlowParams: S3BucketDataFlowParams = {
	bucketId: -1,
	name: '',
	sort: {
		orderBy: 'sensitivity',
		order: 'desc',
	},
};

const bucketDataFlowModel = createBucketDataFlowModel(initialDataFlowParams, false);

export { s3BucketModel, bucketDataFlowModel };
