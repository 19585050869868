import cn from 'classnames';
import { enqueueSnackbar } from 'components/Snackbar';
import Typo from 'components/typography/Typo';
import { AssetGroup } from 'models/assetsGroups/dto';
import { deleteAssetGroupFx } from 'models/assetsGroups/effects';
import { APIError } from 'services/api/httpRequest';
import { goBackByDefault } from 'services/history';
import { PATHS } from 'services/router';
import { DeleteModal } from 'views/AssetGroups/AssetGroupItem/DeleteModal';
import styles from './index.module.css';

export const DeleteButton = ({ group }: { group: AssetGroup }) => {
	const deleteGroup = async () => {
		const confirmed = await DeleteModal({
			groupName: group.name,
		});

		if (!confirmed) {
			return;
		}

		try {
			await deleteAssetGroupFx(group.id);
			enqueueSnackbar('Group has been deleted');

			goBackByDefault(PATHS.ASSET_GROUP_LIST);
		} catch (error) {
			if (error instanceof APIError) {
				const { message } = await error.response.json();

				enqueueSnackbar(message);
			}
		}
	};

	return (
		<Typo
			variant="D/Medium/Body-S"
			className={cn(styles.button, styles.deleteGroup)}
			onClick={deleteGroup}
			data-test={'group-form-delete-button'}
		>
			Delete group
		</Typo>
	);
};
