import GridCell from 'components/table/GridCell';
import GridRow from 'components/table/GridRow';
import { TypoHighlighted } from 'components/typography/TypoHighlighted';
import { KafkaConsumerGroupsMemberItem } from 'models/kafkaTopics/dto';
import styles from './index.module.css';

type Props = {
	consumerGroupsMember: KafkaConsumerGroupsMemberItem;
	search: string;
};

function ConsumerGroupsMemberTableRow(props: Props) {
	const { consumerGroupsMember, search } = props;
	const { kafka_client_id, consumer_group_name, host } = consumerGroupsMember;

	return (
		<GridRow className={styles.rowContainer} border data-test="kafka-members-table-row">
			<GridCell dataTest="kafka-members-table-item-member">
				<TypoHighlighted variant="D/Medium/Body-S" search={search}>
					{kafka_client_id}
				</TypoHighlighted>
			</GridCell>

			<GridCell dataTest="kafka-members-table-item-group">
				<TypoHighlighted variant="D/Medium/Body-S" search={search}>
					{consumer_group_name}
				</TypoHighlighted>
			</GridCell>

			<GridCell dataTest="kafka-members-table-item-host">
				<TypoHighlighted variant="D/Medium/Body-S" search={search}>
					{host}
				</TypoHighlighted>
			</GridCell>
		</GridRow>
	);
}

export { ConsumerGroupsMemberTableRow };
