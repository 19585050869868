import cn from 'classnames';
import Typo from 'components/typography/Typo';
import { toLocaleString } from 'services/numbers';
import styles from './index.module.css';

interface Props {
	entityLabel: string;
	loading: boolean;
	total: number;
	totalFiltered?: number;
}

function FilterLine({ entityLabel, loading, total, totalFiltered }: Readonly<Props>) {
	const title =
		totalFiltered !== undefined
			? `${toLocaleString(totalFiltered)} of ${toLocaleString(total)} ${entityLabel}${
					totalFiltered === 1 ? '' : 's'
			  } match`
			: '';

	return (
		<Typo
			variant="D/Medium/Body-S"
			className={cn(styles.totals, { [styles.loading]: loading, [styles.visible]: !!title })}
		>
			{title}
		</Typo>
	);
}

export default FilterLine;
