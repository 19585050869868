import { generatePath } from 'react-router';
import CopyToClipboard from 'components/CopyToClipboard';
import Button from 'components/form/Button';
import Icon from 'components/Icon';
import SensitivityChip from 'components/SensitivityChip';
import Tooltip from 'components/Tooltip';
import { RouterLink } from 'components/typography/Link';
import { PolicyS3EventJson } from 'models/eventsV2/dto';
import { PATHS } from 'services/router';
import styles from './index.module.pcss';

type Props = { event: PolicyS3EventJson };

// eslint-disable-next-line complexity
const PolicyS3Event = (props: Props) => {
	const { event } = props;

	const linkHere = `${location.origin}${PATHS.EVENTS}?id=${event.id}`;
	// const sendingAsset = event.direction === 'request' ? event.requestor : event.responder;
	// const sendingAssetNameText = getAssetNameByTypeText(sendingAsset.type, sendingAsset.name);
	// const receivingAsset = event.direction === 'request' ? event.responder : event.requestor;
	// const receivingAssetNameText = getAssetNameByTypeText(receivingAsset.type, receivingAsset.name);
	// const eventRegardingAsset =
	// 	event.event_regarding === 'requestor' ? event.requestor : event.responder;
	// const interactsWithAsset =
	// 	event.event_regarding === 'requestor' ? event.responder : event.requestor;

	// const pathToSample = `${PATHS.SAMPLE_V2}?id=${event.endpoint_id}&direction=${event.direction}`;
	const pathToEvidence = generatePath(PATHS.EVENT_SAMPLE, { eventId: event.id });
	const pathToPolicies = `${PATHS.POLICY_LIST}?data-types=${event.data_types.join(',')}`;
	// const pathToDataMap = `${PATHS.DATA_MAP}?entityId=${eventRegardingAsset.id}&type=${eventRegardingAsset.type}&interacts-with=${interactsWithAsset.id}`;

	return (
		<>
			<div className={styles.detailsGrid}>
				<span className={styles.detailsLabel}>Event ID</span>
				<span>
					{event.id}{' '}
					<Tooltip
						title="Copy event link"
						placement="right"
						className={styles.copyToClipboardWrapper}
					>
						<CopyToClipboard
							value={linkHere}
							successText="Event link copied to clipboard"
							className={styles.copyToClipboard}
						/>
					</Tooltip>
				</span>

				<span className={styles.detailsLabel}>Sensitivity</span>
				<SensitivityChip sensitivity={event.sensitivity} data-test="event-details-sensitivity" />

				<span className={styles.detailsLabel}>Category</span>
				<span className={styles.goToPolicies}>
					Policy violation
					<Button
						color="tertiary"
						size="extraSmall"
						endIcon={<Icon name="Open/Regular" size={20} />}
						href={pathToPolicies}
						target="_blank"
						data-test="event-details-policy-link"
					>
						Go to policies
					</Button>
				</span>

				<span className={styles.detailsLabel}>Policy</span>
				<RouterLink
					data-test="event-details-policy"
					inherit
					to={generatePath(PATHS.POLICY_ITEM, { id: event.policy_id })}
				>
					{event.policy_name}
				</RouterLink>

				<span className={styles.detailsLabel}>S3 bucket</span>
				<span data-test="event-details-s3-bucket">{event.s3_bucket.name}</span>

				<span className={styles.detailsLabel}>Region</span>
				<span data-test="event-details-s3-region">{event.s3_bucket.region}</span>
			</div>

			<div className={styles.links}>
				<Button
					size="small"
					color="tertiary"
					endIcon={<Icon name="Open/Regular" size={20} />}
					href={pathToEvidence}
					target="_blank"
					data-test="event-details-sample-link"
				>
					View sample
				</Button>
			</div>
		</>
	);
};

export default PolicyS3Event;
