import { useStore } from 'effector-react';
import { generatePath } from 'react-router';
import Typo from 'components/typography/Typo';
import {
	externalAssetsSteps,
	internalAssetsSteps,
} from 'layouts/AuthorizedWithLeftMenu/Breadcrumbs';
import Header from 'layouts/AuthorizedWithLeftMenu/Header';
import { AssetDetailJson } from 'models/assets/dto';
import { getAssetNameByType } from 'models/assets/model';
import { gatewaysNamesById } from 'models/gateways/store';
import { PATHS } from 'services/router';
import { getStringDividedByDot } from 'services/strings';
import { getAssetNameByType as getAssetNameByTypeIcon } from 'views/common/AssetNameWithIcon';
import styles from './index.module.css';

type Props = {
	asset: AssetDetailJson;
	tabLabel: string;
};

function AssetHeader({ asset, tabLabel }: Props) {
	const gatewaysNamesStore = useStore(gatewaysNamesById);
	const displayName = getAssetNameByTypeIcon(asset.type, asset.name, 24);
	const { namespace, cluster_id } = asset;
	const parentBreadcrumb = asset.type === 'internal' ? internalAssetsSteps : externalAssetsSteps;

	return (
		<Header
			showSensitiveSwitcher={tabLabel === 'Data flows' || tabLabel === 'Endpoints'}
			breadcrumbProps={{
				steps: [
					...parentBreadcrumb,
					{
						path: generatePath(PATHS.SERVICE_ITEM, {
							id: asset.id,
							tab: 'info',
						}),
						name: getAssetNameByType(asset.type, asset.name),
					},
				],
				finalStep: tabLabel,
			}}
			titleBlock={
				<div className={styles.container}>
					<Typo
						variant="D/Medium/H400-Page-Title"
						className={styles.overflow}
						dataTest="asset-header-title"
					>
						{displayName}
					</Typo>

					<Typo
						variant="D/Regular/Body-S"
						color="secondary"
						className={styles.flex}
						dataTest="asset-header-info"
					>
						{getStringDividedByDot(gatewaysNamesStore[cluster_id], namespace, asset.k8s_types)}
					</Typo>
				</div>
			}
		/>
	);
}

export default AssetHeader;
