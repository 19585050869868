import { DataTypeItem } from 'models/dataTypes/dto';
import { SampleDataField } from 'models/sample/dto';
import { visitJsonValues } from 'views/common/SampleViewer/helpers/json';
import { getJsonLocation } from 'views/common/SampleViewer/helpers/jsonPath';

type RawDetection = SampleDataField;

type LogDetection = {
	dataType: DataTypeItem['id'];
	jsonPath: string;
	column: number;
	len: number;
};

type LogIR = {
	line: string;
	rawDetections: RawDetection[];
};

type LogLine = {
	line: string; // without newlines
	detections: LogDetection[];
};

type Document = LogLine[];

function logToDocuments(sample: string, detections: RawDetection[]): Document[] {
	// Collect detections by line
	const detectionsByLine: RawDetection[][] = new Array(101); // Logs are expected to have 100 lines

	for (const detection of detections) {
		detectionsByLine[detection.locator_line] = detectionsByLine[detection.locator_line] || [];
		detectionsByLine[detection.locator_line].push(detection);
	}

	// Parse logs
	let tryToParse = true;
	const lines = sample.split('\n').map((line, i) => {
		const rawDetections = detectionsByLine[i] || [];
		const logIR = { line, rawDetections };

		if (tryToParse) {
			try {
				const jsObject = JSON.parse(line);
				logIR.line = JSON.stringify(jsObject, undefined, '\n').replace(/\n+/gm, ' ');
			} catch {
				tryToParse = false;
			}
		}

		return tryToParse ? prepareJson(logIR) : preparePlaintext(logIR);
	});

	return [lines];
}

function prepareJson(logIR: LogIR): LogLine {
	const { line, rawDetections } = logIR;

	const rawDetectionsWithPathParts = rawDetections.map((rawDetection) => ({
		dataType: rawDetection.data_type,
		jsonPath: rawDetection.locator_path,
		pathPartsStr: JSON.stringify(getJsonLocation(rawDetection.locator_path)),
	}));

	const detections: LogDetection[] = [];

	visitJsonValues(line, [], (source, column, parts) => {
		const currentPathPartsStr = JSON.stringify(parts);
		const found = rawDetectionsWithPathParts.filter((d) => d.pathPartsStr === currentPathPartsStr);

		if (found.length === 1) {
			if (found.length > 1) {
				console.warn(
					'Multiple detections per single JSON path not supported - taking only first detection'
				);
			}

			detections.push({
				dataType: found[0].dataType,
				jsonPath: found[0].jsonPath,
				column,
				len: source.length,
			});
		}
	});

	return { line, detections };
}

function preparePlaintext(logIR: LogIR): LogLine {
	const { line, rawDetections } = logIR;

	const detections: LogDetection[] = rawDetections.map((rawDetection) => {
		const { data_type, locator_column, locator_path } = rawDetection;
		const endColumn = locator_path.split('-')[1];

		return {
			dataType: data_type,
			jsonPath: '', // This is invalid JSON path, and will be handled further in the code.
			column: locator_column,
			len: Number(endColumn) - locator_column,
		};
	});

	return { line, detections };
}

export { logToDocuments };
