import ButtonIcon from 'components/ButtonIcon';
import { ConfirmModal, getConfirmation } from 'components/ConfirmModal';
import { enqueueSnackbar } from 'components/Snackbar';
import rowStyles from 'components/table/GridRow/index.module.pcss';
import Typo from 'components/typography/Typo';
import { GatewayJson } from 'models/gateways/dto';
import { deleteGatewayFx } from 'models/gateways/effects';
import { SensorDARItem } from 'models/sensors/dar/dto';
import { deleteSensorDARFx } from 'models/sensors/dar/effects';
import styles from './index.module.css';

type Props = {
	sensor: GatewayJson | SensorDARItem;
	type: 'DIM' | 'DAR';
};

function GatewayDelete(props: Props) {
	const { sensor, type } = props;

	const isDIM = type === 'DIM';

	const onDelete = async () => {
		if (!(await confirm(sensor.name))) {
			return;
		}

		await (isDIM ? deleteGatewayFx : deleteSensorDARFx)(sensor.id);

		enqueueSnackbar('Sensor deleted');
	};

	async function confirm(gatewayName: string) {
		const confirmDeletionModal = (
			<ConfirmModal
				title="Delete Sensor"
				confirmProps={{
					children: 'Delete',
				}}
				disableUntilEntered={gatewayName}
			>
				<Typo variant="D/Regular/Body-S" className={styles.text}>
					Connection to{' '}
					<Typo variant="D/Medium/Body-S" component="span">
						“<span data-test="gateway-delete-name">{gatewayName}</span>”
					</Typo>{' '}
					will be reset. Data analysis and monitoring will stop.
					<br />
					Enter the Sensor name to confirm the deletion.
				</Typo>
			</ConfirmModal>
		);

		return await getConfirmation(confirmDeletionModal);
	}

	return (
		<div className={styles.container}>
			<ButtonIcon
				size="S"
				icon="Delete/Filled"
				onClick={onDelete}
				className={rowStyles.showWhenFocus}
				dataTest="gateway-delete-btn"
			/>
		</div>
	);
}

export default GatewayDelete;
