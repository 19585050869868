import { getDataTypesAsApiParam } from 'models/dataTypes/helpers';
import { createTableModel } from 'models/modelUtils/tableModelFactory';
import { ApiParams } from 'services/api';
import { getEndpoints } from './api';
import { EndpointsTableItem, EndpointsTableParams } from './dto';

function fetchDataApi(apiParamsRaw: ApiParams, signal?: AbortSignal) {
	const { 'data-types': piiTypes, ...apiParams } = apiParamsRaw;

	apiParams['data-types'] = getDataTypesAsApiParam(piiTypes);

	return getEndpoints(apiParams, signal).then((payload) => {
		return {
			data: payload.endpoints,
			total: payload.total,
			total_filtered: payload.total_filtered,
		};
	});
}

function createEndpointsModel(initialParams: EndpointsTableParams, fetchFxOnInit: boolean = true) {
	return createTableModel<EndpointsTableItem, EndpointsTableParams>(
		initialParams,
		fetchDataApi,
		fetchFxOnInit
	);
}

//
// Instantiate model with initial params, but do not fetch data from server.
//

const initialParams: EndpointsTableParams = {
	asset: 0,
	total_by_asset: false,
	search: '',

	// Filter params
	asset_names: [],
	cluster_ids: [],
	'data-types': [],
	namespaces: [],
	protocol: '',

	// Table params
	assets: '',
	endpoints: '',
	hosts: '',
	sort: { orderBy: 'sensitivity', order: 'desc' },
};

const endpointsModel = createEndpointsModel(initialParams, false);

export { endpointsModel };
