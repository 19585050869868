import { Redirect, Route, Switch } from 'react-router-dom';
import { PATHS } from 'services/router';
import { PolicyItem } from 'views/Policy/PolicyItem';
import { PolicyList } from 'views/Policy/PolicyList';

function PoliciesRoute() {
	return (
		<Switch>
			<Route path={PATHS.POLICY_LIST} component={PolicyList} exact />
			<Route path={PATHS.POLICY_ITEM} component={PolicyItem} />

			<Redirect to={PATHS.POLICY_LIST} />
		</Switch>
	);
}

export default PoliciesRoute;
