import cn from 'classnames';
import { ForwardedRef, forwardRef, ReactNode } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import styles from './index.module.pcss';

interface Props {
	children: ReactNode;
	href?: string;
	inherit?: boolean;
	className?: string;
	to: string;
	state?: object;
	setPageParams?: boolean;
}

type RouterLinkProps = Props & Omit<LinkProps, 'to'>;

const RouterLink = forwardRef(function RouterLink(
	props: RouterLinkProps,
	ref: ForwardedRef<HTMLAnchorElement>
) {
	const { children, inherit, className, to: originalTo, setPageParams, state, ...rest } = props;
	const { pathname, search, hash } = new URL(originalTo, window.location.origin);
	const searchString = new URLSearchParams(search);
	// TODO: move setPageParams to the state
	if (setPageParams) searchString.append('setPageParams', 'true');

	const to = { pathname, search: searchString.toString(), hash, state };
	return (
		<Link className={cn(inherit && styles.linkInherit, className)} {...rest} to={to} ref={ref}>
			{children}
		</Link>
	);
});

export { RouterLink };
export default RouterLink;
