function getAbortError() {
	return new DOMException('The user aborted a request.', 'AbortError');
}

async function abortableTimeout(milliseconds: number, signal?: AbortSignal) {
	return new Promise(function (resolve, reject) {
		setTimeout(resolve, milliseconds);

		if (signal) {
			if (signal.aborted) {
				reject(getAbortError());
			} else {
				signal.addEventListener('abort', () => reject(getAbortError()));
			}
		}
	});
}

export default abortableTimeout;
