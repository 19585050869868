import cn from 'classnames';
import { useState } from 'react';
import ButtonIcon from 'components/ButtonIcon';
import GridCell from 'components/table/GridCell';
import GridHead from 'components/table/GridHead';
import GridRow from 'components/table/GridRow';
import Tooltip from 'components/Tooltip';
import Filter from './Filter';
import styles from './index.module.pcss';

export type TOrder = 'asc' | 'desc';
export type TFilteredBy = { [key: string]: string };

type TAdditionalIds = 'action';

export interface IHeadCell<T> {
	id: (keyof T & string) | TAdditionalIds;
	label: string;
	numeric?: boolean;
	sortable?: boolean;
	reversedSort?: boolean;
	filter?: boolean;
	tooltip?: string;
}

interface EnhancedTableProps<T> {
	config: IHeadCell<T>[];
	onRequestSort?: (property: keyof T) => void;
	onRequestFilter?: (property: keyof T, filter: string) => void;
	order?: TOrder;
	orderBy?: string;
	filterBy?: TFilteredBy;
	rowClassname?: string;
}

function EnhancedTableHead<T>(props: EnhancedTableProps<T>) {
	const [isFilterOpen, setFilterOpen] = useState(false);
	const { order, orderBy, filterBy, onRequestSort, onRequestFilter, config, rowClassname } = props;
	const isDesc = order === 'desc';

	return (
		<GridHead>
			<GridRow head className={cn(rowClassname, styles.row)}>
				{config.map((headCell, id) => {
					const propertyName = headCell.id;

					const isSortActive = orderBy === propertyName;
					const isFilterActive = Boolean(
						filterBy &&
							Object.keys(filterBy).includes(String(propertyName)) &&
							// @ts-ignore TODO: SMAT-2863 try to get rid of it
							filterBy[propertyName]
					);
					const isVisible = isSortActive || isFilterActive || isFilterOpen;

					return (
						<GridCell
							key={String(propertyName + id)}
							align={headCell.numeric ? 'right' : 'left'}
							head
							className={cn(styles.cell, headCell.numeric && styles.numeric)}
						>
							{headCell.tooltip ? (
								<Tooltip title={headCell.tooltip} placement="top" theme="dark">
									<span className={styles.label}>{headCell.label}</span>
								</Tooltip>
							) : (
								<span className={styles.label}>{headCell.label}</span>
							)}

							{headCell.filter && onRequestFilter && (
								<Filter
									// @ts-ignore TODO: SMAT-2863 try to get rid of it
									value={filterBy[propertyName]}
									isActive={isFilterActive}
									isVisible={isVisible}
									onFilter={(filter) => {
										if (propertyName === 'action') {
											return;
										} else {
											onRequestFilter(propertyName, filter);
										}
									}}
									handleFilter={setFilterOpen}
								/>
							)}

							{headCell.sortable && onRequestSort && (
								<ButtonIcon
									className={cn(styles.button, {
										[styles.visibleButton]: isVisible,
										[styles.activeButton]: isSortActive,
										[styles.sortDesc]: isSortActive && isDesc === !headCell.reversedSort,
									})}
									color={isSortActive ? 'icon/accent-primary' : 'icon/secondary'}
									dataTest={`EnhancedTableHead-${headCell.id}`}
									icon="ArrowUp/Regular"
									onClick={() => {
										if (propertyName === 'action') {
											return;
										} else {
											onRequestSort(propertyName);
										}
									}}
									size="XS"
								/>
							)}
						</GridCell>
					);
				})}
			</GridRow>
		</GridHead>
	);
}

export default EnhancedTableHead;
