// Ensures that one json object contains all fields from another json object, and field types match.
// Array in smaller json means union type - any shape from smaller json's array is allowed in larger json's array.

type JsonLiteral = string | number | boolean | null;
type JsonStruct = { [key: string]: JsonStruct } | JsonStruct[] | JsonLiteral;

// TS assertion functions for typing convenience.
function isJsonLiteral(value: JsonStruct): value is JsonLiteral {
	return (
		typeof value === 'string' ||
		typeof value === 'number' ||
		typeof value === 'boolean' ||
		value === null
	);
}
function isJsonObject(value: JsonStruct): value is { [key: string]: JsonStruct } {
	return typeof value === 'object' && value !== null && !Array.isArray(value);
}
function isJsonArray(value: JsonStruct): value is JsonStruct[] {
	return Array.isArray(value);
}
function getType(value: JsonStruct) {
	return value === null ? 'null' : isJsonArray(value) ? 'array' : typeof value;
}

type MatchError = {
	path: (string | number)[];
	actualType: string;
	expectedType: string;
};
// Main logic here
function matchesWith(
	biggerSubjson: JsonStruct,
	smallerSubjson: JsonStruct,
	path: (string | number)[]
): true | MatchError[] {
	if (isJsonLiteral(smallerSubjson)) {
		const smallerType = getType(smallerSubjson);
		const biggerType = getType(biggerSubjson);

		// Recursion terminates here;
		return smallerType === biggerType
			? true
			: [
					{
						path,
						actualType: biggerType,
						expectedType: smallerType,
					},
			  ];
	} else if (isJsonArray(smallerSubjson)) {
		if (!isJsonArray(biggerSubjson))
			return [
				{
					path,
					actualType: getType(biggerSubjson),
					expectedType: 'array',
				},
			];

		if (smallerSubjson.length === 0) {
			throw new Error("Unexpected: JSON example contains empty array (can't interpret that)");
		}

		for (let i = 0; i < biggerSubjson.length; i++) {
			const value = biggerSubjson[i];
			const newPath = path.concat(i);

			const matches: MatchError[][] = [];

			for (let j = 0; j < smallerSubjson.length; j++) {
				const option = smallerSubjson[j];
				const match = matchesWith(value, option, newPath);

				if (match === true) break;

				matches.push(match);
			}

			if (matches.length === smallerSubjson.length) return matches.flat(1);
		}

		return true;
	} else if (isJsonObject(smallerSubjson)) {
		if (!isJsonObject(biggerSubjson))
			return [
				{
					path,
					actualType: getType(biggerSubjson),
					expectedType: 'object',
				},
			];

		const matches: MatchError[][] = [];
		const entries = Object.entries(smallerSubjson);

		for (const entry of entries) {
			const [key, value] = entry;
			const newPath = path.concat(key);

			if (!(key in biggerSubjson)) {
				matches.push([
					{
						path: newPath,
						actualType: 'undefined',
						expectedType: getType(value),
					},
				]);

				continue;
			}

			const match = matchesWith(biggerSubjson[key], value, newPath);

			if (match !== true) matches.push(match);
		}

		return matches.length > 0 ? matches.flat(1) : true;
	}

	throw new Error(
		"Unexpected: arguments to 'matchesWith()' contain unexpected data (e.g. functions or Symbols)"
	);
}

export default matchesWith;
export type { JsonLiteral, JsonStruct, MatchError };
