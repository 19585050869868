import { IHeadCell } from 'components/table/EnhancedTableHead';
import { KafkaMessageSchema } from 'models/kafkaMessageSchemas/dto';

const tableConfig: IHeadCell<KafkaMessageSchema>[] = [
	{
		id: 'name',
		numeric: false,
		label: 'Name',
		sortable: true,
	},
	{
		id: 'type',
		numeric: false,
		label: 'Content type',
		sortable: true,
	},
	{
		id: 'sensitivity',
		numeric: false,
		label: 'Sensitivity',
		sortable: true,
	},
	{
		id: 'data_types',
		numeric: false,
		label: 'Data types',
		sortable: false,
	},
	{
		id: 'last_checked',
		numeric: false,
		label: 'Last seen',
		sortable: true,
	},
];

export default tableConfig;
