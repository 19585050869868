import { IHeadCell } from 'components/table/EnhancedTableHead';
import { DataTypesExtendedStructured } from 'models/dataTypesExtended/dto';

const tableConfig: IHeadCell<DataTypesExtendedStructured>[] = [
	{
		id: 'name',
		numeric: false,
		label: 'Data type',
		sortable: true,
	},
	{
		id: 'sensitivity',
		numeric: false,
		label: 'Sensitivity',
		sortable: true,
	},
	{
		id: 'assets',
		numeric: true,
		label: 'Services',
		sortable: true,
	},
	{
		id: 'endpoints',
		numeric: true,
		label: 'Endpoints',
		sortable: true,
	},
	{
		id: 'kafka_topics',
		numeric: true,
		label: 'Kafka topics',
		sortable: true,
	},
	{
		id: 'sql_db_databases',
		numeric: true,
		label: 'Databases',
		sortable: true,
	},
	{
		id: 'buckets',
		numeric: true,
		label: 'Buckets',
		sortable: true,
	},
	{
		id: 'policies',
		numeric: true,
		label: 'Policies',
		sortable: true,
	},
	{
		id: 'last_seen',
		numeric: true,
		label: 'Last checked',
		sortable: true,
		reversedSort: true,
	},
];

export default tableConfig;
