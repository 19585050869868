import { useMemo, useState } from 'react';
import { generatePath } from 'react-router';
import { useHistory } from 'react-router-dom';
import { PiiTypeList } from 'components/PiiType';
import { enqueueSnackbar } from 'components/Snackbar';
import Switch from 'components/Switch';
import GridCell from 'components/table/GridCell';
import GridRow from 'components/table/GridRow';
import { PolicyItemWithoutRules } from 'models/policies/dto';
import { togglePolicyTrackingFx } from 'models/policies/effects';
import { DateFormat, format } from 'services/dayjs';
import { PATHS } from 'services/router';
import styles from './index.module.css';

type Props = {
	policy: PolicyItemWithoutRules;
	dataTypesFilter: number[];
};

function PolicyRow(props: Props) {
	const { id, name, data_types, created_at, is_active } = props.policy;
	const history = useHistory();
	const [isSwitcherLoading, setSwitcherLoading] = useState(false);

	const createdAt = useMemo(() => format(created_at, DateFormat.date), [created_at]);

	function onRowClick() {
		const path = generatePath(PATHS.POLICY_ITEM, { id });

		history.push(path);
	}

	function onSwitchTracking() {
		setSwitcherLoading(true);

		togglePolicyTrackingFx({ id, is_active: !is_active })
			.catch(() => {
				enqueueSnackbar('Something went wrong');
			})
			.finally(() => setSwitcherLoading(false));
	}

	return (
		<GridRow
			className={styles.rowContainer}
			onClick={onRowClick}
			hover
			border
			data-test="policy-row"
		>
			<GridCell className={styles.flex}>
				{name}
				<span className={styles.editLink} data-test="policy-row-edit-button">
					Edit
				</span>
			</GridCell>

			<GridCell>
				<PiiTypeList data={data_types} dataTest="policy-row-data-types" />
			</GridCell>
			<GridCell>{createdAt}</GridCell>
			<GridCell align="right">
				<Switch
					checked={isSwitcherLoading ? !is_active : is_active}
					onChange={onSwitchTracking}
					disabled={isSwitcherLoading}
					className={styles.switch}
					data-test="policy-row-disable-button"
				/>
			</GridCell>
		</GridRow>
	);
}

export default PolicyRow;
