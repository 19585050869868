export const discoveredDataTypeColors: { [key: string]: string } = {
	BIRTH_DATE: '#F15361',
	CARD: '#F29C09',
	DRIVER_LICENSE: '#309F55',
	EMAIL: '#149FCB',
	GENDER: '#376EDF',
	IBAN: '#9F7DE5',
	LOCATION: '#D661DB',
	PERSON: '#9E4820',
	PHONE: '#DE8E4E',
	SSN: '#379E94',
	PENSION_NUMBER: '#0D33AD',
	CARD_TRACK: '#EA46F2',
	MASKED_CARD_NUMBER: '#7634E1',
	IP_ADDRESS: '#9E3F96',
	CARD_HOLDER: '#CC4B29',
	CARD_VERIFICATION: '#CC9400',
	PASSPORT: '#4A9E28',
	TAX_NUMBER: '#22F2F0',
	IDENTITY_DOCUMENT: '#534CF5',
	CARD_EXPIRATION: '#8668AF',

	OTHERS_DATA_TYPE: '#8691A5',
	UNKNOWN_DATA_TYPE: '#2CC5DE',

	/* RESERVED COLORS:
	DATATYPE: '#DB6B72',
	DATATYPE: '#D4BF0D',
	DATATYPE: '#0ACC8F',
	DATATYPE: '#3AF2CE',
	DATATYPE: '#738AE6',
	DATATYPE: '#B08EDF',
	DATATYPE: '#DFA0E8',

	DATATYPE: '#F26616',
	DATATYPE: '#F2CE16',
	DATATYPE: '#8FCC9E',
	DATATYPE: '#8AF4BB',
	DATATYPE: '#B6C1EC',
	DATATYPE: '#F2865E',
	DATATYPE: '#E6D195',
	DATATYPE: '#A7E667',
	 */
};
