import { useHistory } from 'react-router-dom';
import Icon from 'components/Icon';
import LoadMoreButton from 'components/LoadMoreButton';
import { PiiTypeList } from 'components/PiiType';
import GridBody from 'components/table/GridBody';
import GridCell from 'components/table/GridCell';
import GridHead from 'components/table/GridHead';
import GridRow from 'components/table/GridRow';
import GridTable from 'components/table/GridTable';
import Typo from 'components/typography/Typo';
import { DataFlowDetailsTable } from 'models/assetDataFlowDetailsV2/dto';
import { methodOrProtocol } from 'models/endpointsV2/dto';
import { PATHS } from 'services/router';
import { getAssetNameByType } from 'views/common/AssetNameWithIcon';
import styles from './index.module.css';

type Props = {
	streams: DataFlowDetailsTable['streams'];
	activeDataTypes: DataFlowDetailsTable['data_types'];
	loadMoreData: () => void;
	moreData: boolean;
	moreDataLoading: boolean;
};

export const DataFlowDetailsItem = ({
	streams,
	activeDataTypes,
	loadMoreData,
	moreData,
	moreDataLoading,
}: Props) => {
	const history = useHistory();

	return (
		<div className={styles.container}>
			{streams.length === 0 ? (
				<Typo variant="D/Regular/Body-S" color="secondary">
					No results matching these filters.
				</Typo>
			) : (
				<GridTable dataTest="data-flow-details-table">
					<GridHead>
						<GridRow head className={styles.row}>
							<GridCell head>{''}</GridCell>
							<GridCell head>Hostname/Endpoint</GridCell>
							<GridCell head>Service</GridCell>
							<GridCell head>Direction</GridCell>
							<GridCell head>Data Types</GridCell>
						</GridRow>
					</GridHead>

					<GridBody className={styles.tableBody}>
						{streams.map((stream, index) => {
							const assetDisplayName = getAssetNameByType(stream.asset_type, stream.asset_name);

							function onRowClick() {
								history.push(
									`${PATHS.SAMPLE_V2}?id=${stream.endpoint_id}&direction=${stream.direction}`
								);
							}

							return (
								<GridRow
									key={index}
									className={styles.row}
									onClick={onRowClick}
									hover
									border
									data-test="data-flow-details-row"
								>
									<GridCell withoutBlur className={styles.icons}>
										{stream.is_encrypted && <Icon name="tlsRegular" dataTest="tls-icon" />}
										{stream.is_mesh_network && (
											<Icon name="MeshNetwork/Regular" dataTest="mesh-network-icon" />
										)}
									</GridCell>
									<GridCell className={styles.gapCell}>
										{stream.host}
										{stream.url}
										<Typo variant="D/Regular/Meta" color="secondary">
											{methodOrProtocol(stream)}
										</Typo>

										{stream.process_name && (
											<Typo variant="D/Regular/Meta" color="secondary">
												Process: {stream.process_name}
											</Typo>
										)}
									</GridCell>

									<GridCell className={styles.gapCell}>
										{assetDisplayName}

										<Typo variant="D/Regular/Meta" color="secondary">
											{stream.asset_k8s_types?.join(', ')}
										</Typo>

										<Typo variant="D/Regular/Meta" color="secondary">
											{stream.asset_namespace}
										</Typo>
									</GridCell>

									<GridCell className={styles.gapCell}>
										<Typo variant="D/Regular/Body-S" className={styles.direction}>
											{stream.direction}
										</Typo>
									</GridCell>

									<GridCell className={styles.gapCell}>
										<PiiTypeList data={stream.data_types} filter={activeDataTypes} />
									</GridCell>
								</GridRow>
							);
						})}

						<LoadMoreButton show={moreData} loading={moreDataLoading} request={loadMoreData} />
					</GridBody>
				</GridTable>
			)}
		</div>
	);
};
