import { createEffect } from 'effector';
import { createAsset, deleteAsset, getAssetById, getAssets, updateAsset } from './api';
import { AssetDetailJson, AssetsJson } from './dto';

/* API */

const getAssetsFx = createEffect<void, AssetsJson>();
const getAssetByIdFx = createEffect<AssetDetailJson['id'], AssetDetailJson>();
const createAssetFx = createEffect<AssetDetailJson, AssetDetailJson>();
const updateAssetFx = createEffect<AssetDetailJson, AssetDetailJson>();
const deleteAssetFx = createEffect<AssetDetailJson['id'], AssetDetailJson['id']>();
/* IMPLEMENTATION */

// Simple direct-to-api effects

getAssetsFx.use(getAssets);
getAssetByIdFx.use(getAssetById);
createAssetFx.use(createAsset);
updateAssetFx.use(updateAsset);
deleteAssetFx.use(deleteAsset);

export { getAssetsFx, getAssetByIdFx, createAssetFx, updateAssetFx, deleteAssetFx };
