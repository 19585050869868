import { DiscoveredDataTypesList } from './dto';

const discoveredDataTypesExample: DiscoveredDataTypesList = {
	total_data_fields: 2,
	data_types: [
		{
			data_type: 1,
			data_fields: {
				percent: 66.6,
				count: 120,
			},
			endpoints: 12,
		},
		{
			data_type: 2,
			data_fields: {
				percent: 33.4,
				count: 61,
			},
			endpoints: 9,
		},
	],
};

export { discoveredDataTypesExample };
