import { createEvent, createStore } from 'effector';
import dropSession from 'services/dropSession';

const initialState = JSON.parse(localStorage.getItem('FPVisibility') || 'true');

const fpVisibilityStore = createStore<boolean>(initialState);
const setFPVisibility = createEvent<boolean>();

const setFPVisibilityInternal = fpVisibilityStore.map((isVisible: boolean) => isVisible);

setFPVisibilityInternal.watch((isVisible: boolean) => {
	localStorage.setItem('FPVisibility', JSON.stringify(isVisible));
});

fpVisibilityStore.on(setFPVisibility, (state: boolean, isVisible: boolean) => isVisible);

dropSession.watch(() => setFPVisibility(true));

export { fpVisibilityStore, setFPVisibility };
