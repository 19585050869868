import {
	ClusterFile,
	ClusterDirectory,
	S3ClusterList,
	S3ClusterSublist,
	S3ClusterFileWithSamples,
} from './dto';

const s3ClusterFileExample: ClusterFile = {
	id: 1011,
	type: 'csv',
	name: 'string',
	path: 'string',
	real_name_sample: 'string',
	size: 123456789,
	objects_count: 123,
	is_analyzable: true,
	data_types: [1, 2],
	sensitivity: 'Low',
	last_scan_at: 1702900466754,
	cloud_console_url: 'https://console.aws.amazon.com/s3/buckets/...',
};

const s3ClusterFileExample2: ClusterFile = {
	id: 1012,
	type: 'other',
	name: 'string',
	path: 'string',
	real_name_sample: 'string',
	size: 123456789,
	objects_count: 123,
	is_analyzable: false,
	data_types: [1],
	sensitivity: 'N/A',
	last_scan_at: 0,
	cloud_console_url: 'https://console.aws.amazon.com/s3/buckets/...',
};

const s3ClusterDirectoryExample: ClusterDirectory = {
	id: 1010,
	type: 'directory',
	name: 'string',
	path: 'string',
	real_name_sample: 'string',
	size: 123456789,
	objects_count: 123,
	is_analyzable: true,
	data_types: [1, 2],
	sensitivity: 'Low',
	last_scan_at: 1702900466754,
	cloud_console_url: 'https://console.aws.amazon.com/s3/buckets/...',
	clusters: [s3ClusterFileExample],
	has_more_clusters: true,
};

const s3ClusterListExample: S3ClusterList = {
	clusters: [s3ClusterDirectoryExample, s3ClusterFileExample2],
	total: 2,
};

const s3ClusterSublistExample: S3ClusterSublist = {
	has_more_clusters: true,
	clusters: [s3ClusterFileExample],
};

const s3ClusterFileWithSamplesExample: S3ClusterFileWithSamples = {
	id: 123,
	type: 'csv',
	path: 'asd/[num]/qwe/',
	real_name_sample: 'asd/456/qwe/some-777.csv',
	name: 'some-[num].csv',
	size: 1234,
	objects_count: 123,
	estimated_rows: 123,
	owner: 'John',
	sensitivity: 'High',
	data_types: [1, 2, 5],
	last_scan_at: 1638267989332,
	cloud_console_url: 'https://console.aws.amazon.com/s3/buckets/...',
	samples: [
		{
			id: 124,
			name: 'some-232.csv',
			path: 'asd/456/qwe/',
			size: 1234,
			estimated_rows: 123,
			data_types: [1, 2, 5],
			created_at: 1638267989332,
			last_scan_at: 1638267989332,
			cloud_console_url: 'https://console.aws.amazon.com/s3/buckets/...',
			status: '',
		},
		{
			id: 125,
			name: 'some-4242.csv',
			path: 'asd/4242/qwe/',
			size: 4242,
			estimated_rows: 42,
			data_types: [4, 2],
			created_at: 1638267989332,
			last_scan_at: 1638267989332,
			cloud_console_url: 'https://console.aws.amazon.com/s3/buckets/4242...',
			status: '',
		},
		{
			id: 126,
			name: 'some-file-sample-with-very-long-name-4242-4242-2424-2424-4242-4242.csv',
			path: 'asd/4242/qwe/',
			size: 4242234543,
			estimated_rows: 11242,
			data_types: [],
			created_at: 1638267989332,
			last_scan_at: 1638267989332,
			cloud_console_url: 'https://console.aws.amazon.com/s3/buckets/4242...',
			status: 'general_fault',
		},
	],
};

export { s3ClusterListExample, s3ClusterSublistExample, s3ClusterFileWithSamplesExample };
