import { Redirect, Route, Switch } from 'react-router-dom';
import { PATHS } from 'services/router';
import { DatabaseItem } from 'views/Databases/DatabaseItem';
import { DatabaseSampleViewer } from 'views/Databases/DatabaseSampleViewer';
import { DatabasesTable } from 'views/Databases/DatabasesTable';
import { InstanceItem } from 'views/Kafka/InstanceItem';
import { InstancesTable } from 'views/Kafka/InstancesTable';
import { TopicItem } from 'views/Kafka/TopicItem';
import { MessageSchemaItem } from 'views/Kafka/TopicItem/MessageSchemas/MessageSchemaItem';
import { TopicSampleViewers } from 'views/Kafka/TopicItem/SampleViewers';
import { TopicsTable } from 'views/Kafka/TopicsTable';
import S3BucketsTable from 'views/SuperAssets/S3Buckets';
import { S3BucketClusterView } from 'views/SuperAssets/S3Buckets/S3BucketClusterView';
import { S3BucketItemViewWrapper } from 'views/SuperAssets/S3Buckets/S3BucketItemView';
import { SampleViewers } from 'views/SuperAssets/S3Buckets/SampleViewers';

function DataStorageRouter() {
	return (
		<Switch>
			<Route path={PATHS.S3_CLUSTER_ITEM} component={S3BucketClusterView} />
			<Route path={PATHS.S3_FILE_SAMPLE} component={SampleViewers} />
			<Route path={PATHS.S3_BUCKETS_ITEM} component={S3BucketItemViewWrapper} />
			<Route path={PATHS.S3_BUCKETS} component={S3BucketsTable} />

			<Route path={PATHS.KAFKA_MESSAGE_SCHEMA_ITEM} component={MessageSchemaItem} />
			<Route path={PATHS.KAFKA_TOPIC_FILE_SAMPLE} component={TopicSampleViewers} />
			<Route path={PATHS.KAFKA_INSTANCES_ITEM} component={InstanceItem} />
			<Route path={PATHS.KAFKA_INSTANCES} component={InstancesTable} />
			<Route path={PATHS.KAFKA_TOPICS_ITEM} component={TopicItem} />
			<Route path={PATHS.KAFKA_TOPICS} component={TopicsTable} />

			<Route path={PATHS.DATABASES_TABLE_SAMPLE} component={DatabaseSampleViewer} />
			<Route path={PATHS.DATABASES_ITEM} component={DatabaseItem} />
			<Route path={PATHS.DATABASES} component={DatabasesTable} />

			<Redirect to={PATHS.S3_BUCKETS} />
		</Switch>
	);
}

export { DataStorageRouter };
