import Typo from 'components/typography/Typo';
import { WebhookSettings } from 'models/integrations/webhook/dto';
import integrationStyles from '../index.module.pcss';

interface Props {
	status: WebhookSettings['status'];
}

function ConnectionStatus({ status }: Props) {
	switch (status) {
		case 'connected':
			return (
				<Typo
					variant="D/Medium/Body-S"
					className={integrationStyles.connected}
					data-test="webhook-status-connect-success"
				>
					Connected
				</Typo>
			);
		case 'connection_failed':
			return (
				<Typo
					variant="D/Medium/Body-S"
					className={integrationStyles.disconnected}
					data-test="webhook-status-connect-failed"
				>
					Connection failed
				</Typo>
			);
		case 'unknown':
		default:
			return null;
	}
}

export default ConnectionStatus;
