import { useStore } from 'effector-react';
import { useMemo, useRef } from 'react';
import Button from 'components/form/Button';
import Select from 'components/form/Select';
import { DropdownButtonProps } from 'components/form/Select/DropdownButton';
import selectStyles from 'components/form/Select/index.module.css';
import { SearchProps } from 'components/form/Select/Search.tsx';
import SearchWithManual from 'components/form/Select/SearchWithManual';
import Icon from 'components/Icon';
import { enqueueSnackbar } from 'components/Snackbar';
import Typo from 'components/typography/Typo';
import { GatewayJson } from 'models/gateways/dto';
import { s3RegionsList } from 'models/s3Regions/store';
import { SensorDARItem } from 'models/sensors/dar/dto.ts';
import { APIError } from 'services/api/httpRequest';
import { updateClusterGeoLocationManualRegionFx } from '../../../../models/clusterGeoLocations/effects.ts';
import { clusterGeoLocationsByTokenId } from '../../../../models/clusterGeoLocations/store.ts';
import styles from './index.module.css';

type Props = {
	sensor: GatewayJson | SensorDARItem;
	type: 'dim' | 'dar';
};

const OTHER_REGION = {
	id: '',
	name: 'Other region',
};

function DropdownButton(props: DropdownButtonProps) {
	const { onClick, open, children, dataTest } = props;

	return (
		<div className={styles.dropdownButtonWrapper}>
			<Button
				color="ghost"
				onClick={onClick}
				className={styles.dropdownButton}
				data-test={dataTest}
				classes={{ root: styles.dropdownButtonRoot }}
			>
				<span>{children}</span>
				<span className={open ? selectStyles.activeIcon : selectStyles.icon}>
					<Icon name="ChevronDown/Regular" />
				</span>
			</Button>
		</div>
	);
}

const GatewayRegion = (props: Props) => {
	const s3Regions = useStore(s3RegionsList);

	const clusterGeoLocationsMap = useStore(clusterGeoLocationsByTokenId);
	const clusterGeoLocation = clusterGeoLocationsMap[props.sensor.id] || {};

	const closeRef = useRef(() => {});
	function closeSelect() {
		closeRef.current();
	}

	const options = useMemo(
		() => [
			OTHER_REGION,
			...s3Regions.map(({ keyword, description }) => ({
				id: keyword,
				name: description,
			})),
		],
		[s3Regions]
	);
	const value = options.find(({ id }) => id === clusterGeoLocation.result_region);

	async function saveGatewayRegion(newRegion: string): Promise<null | string> {
		try {
			await updateClusterGeoLocationManualRegionFx({
				manualRegion: newRegion,
				tokenId: props.sensor.id,
			});
		} catch (error) {
			if (error instanceof APIError && error.response.status === 400) {
				const { message } = await error.response.json();
				return message;
			}

			enqueueSnackbar('Something went wrong');
			throw error;
		}

		return null;
	}

	const label = value?.name || clusterGeoLocation.result_region || OTHER_REGION.name;

	return (
		<div className={styles.RegionCell}>
			<Select
				hasSearch
				label={label}
				options={options}
				onChange={(region) => saveGatewayRegion(region.id)}
				value={value || OTHER_REGION}
				closeRef={closeRef}
				render={{
					dropdownButton: DropdownButton,
					search: (searchProps: SearchProps) => (
						<SearchWithManual
							{...searchProps}
							defaultValue={value ? '' : clusterGeoLocation.result_region}
							onEnter={(searchValue) => {
								closeSelect();
								saveGatewayRegion(searchValue).then(() => {
									enqueueSnackbar('Sensors region has been changed');
								});
							}}
						/>
					),
				}}
			/>
			{clusterGeoLocation.result_region_source === 'manual' && (
				<Typo color="secondary" variant="D/Regular/Body-S">
					(manual)
				</Typo>
			)}
		</div>
	);
};

export { GatewayRegion, OTHER_REGION };
