import { IHeadCell } from 'components/table/EnhancedTableHead';
import { EndpointsTableItem } from 'models/endpointsV2/dto';

const tableConfig: IHeadCell<EndpointsTableItem>[] = [
	{
		id: 'is_encrypted',
		numeric: false,
		label: '',
		sortable: false,
		filter: false,
	},
	{
		id: 'url',
		numeric: false,
		label: 'Endpoint',
		sortable: true,
		filter: true,
	},
	{
		id: 'host',
		numeric: false,
		label: 'Hostname',
		sortable: true,
		filter: true,
	},
	{
		id: 'namespace',
		numeric: false,
		label: 'Namespace',
		sortable: true,
		// filter: true,
	},
	{
		id: 'asset_name',
		numeric: false,
		label: 'Service',
		sortable: true,
		filter: true,
	},
	{
		id: 'sensitivity',
		numeric: false,
		label: 'Sensitivity',
		sortable: true,
	},
	{
		id: 'data_types',
		numeric: false,
		label: 'Data types',
		sortable: false,
	},
	{
		id: 'last_seen',
		numeric: false,
		label: 'Last checked',
		sortable: true,
		reversedSort: true,
	},
];

export default tableConfig;
