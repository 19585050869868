import Chip from 'components/Chip';
import { SampleJson } from 'models/samplesV2/dto';
import styles from './index.module.pcss';

const MAX_DISPLAYED_RESPONSE_CODES = 7;

export const ResponseWithCodes = ({
	responseCodes = [],
}: {
	responseCodes: SampleJson['response_codes'];
}) => {
	const sortedResponseCodes = responseCodes.sort((a, b) => a - b);

	return (
		<div>
			Response
			{responseCodes.length > 0 && (
				<>
					{sortedResponseCodes.slice(0, MAX_DISPLAYED_RESPONSE_CODES).map((code, index) => (
						<Chip
							key={index}
							className={styles.responseCodeChip}
							label={code}
							size="small"
							color="secondary"
							theme="neutral"
						/>
					))}
					{sortedResponseCodes.length > MAX_DISPLAYED_RESPONSE_CODES && (
						<Chip
							className={styles.responseCodeChip}
							label="..."
							size="small"
							color="secondary"
							theme="neutral"
							title={`and ${responseCodes.slice(MAX_DISPLAYED_RESPONSE_CODES)}`}
						/>
					)}
				</>
			)}
		</div>
	);
};
