import cn from 'classnames';
import commonStyles from 'assets/styles/index.module.pcss';
import { TSensitivityWithFP } from 'models/common/dto';
import Icon from '../Icon';
import styles from './index.module.pcss';

interface Props {
	sensitivityLevel: TSensitivityWithFP;
	changed: boolean;
	className?: string;
	custom?: boolean;
	size?: string;
}

function SensitivityCircle({
	sensitivityLevel,
	changed,
	custom,
	size = '',
	className,
	...rest
}: Props) {
	return custom ? (
		<Icon
			name={changed ? 'Tag/Regular' : 'Tag/Filled'}
			size={size === 'extraSmall' ? 12 : 16}
			className={cn(styles[sensitivityLevel], styles.customIcon, styles[size])}
		/>
	) : (
		<span
			{...rest}
			className={cn(
				styles[sensitivityLevel],
				commonStyles.skeletonHide,
				styles.container,
				{
					[styles.overwrite]: changed,
				},
				styles[size],
				className
			)}
		/>
	);
}

export default SensitivityCircle;
