import { isPseudoRag, Rag } from './listToTreeByAttributes';

function collapseLevels<Element, Level, Dimensions>(
	rag: Rag<Element, Level, Dimensions>,
	collapse: Level[]
) {
	if (isPseudoRag(rag)) return;

	let checkAgain: boolean;
	do {
		checkAgain = false;

		rag.children = rag.children.flatMap((child) => {
			const liftChildren = !isPseudoRag(child) && collapse.includes(child.level);

			checkAgain = checkAgain || liftChildren;

			return liftChildren ? child.children : child;
		});
	} while (checkAgain);

	rag.children.forEach((child) => collapseLevels(child, collapse));
}

export default collapseLevels;
