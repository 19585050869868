import { useCallback, useMemo } from 'react';
import Select from 'components/form/Select';
import DropdownButton from 'components/form/Select/DropdownButton';
import { EventParams } from 'models/eventsV2/model';
import { FilterPropsBase } from '../model';

type Value = EventParams['type'];

const options: { id: Value; name: string }[] = [
	{ id: 'new_data_types', name: 'New data type' },
	{ id: 'data_flow_changes', name: 'Data flow change' },
	{ id: 'policy_violations', name: 'Policy violation' },
	{ id: 'started_storing', name: 'Data storage change' },
	{ id: 'others', name: 'Other' },
];

type Props = FilterPropsBase & {
	value: Value;
	onChange: (newValue: Value) => void;
};

function EventTypeFilter({ defaultOpen, fixed, onChange, onClose, resetFilter, value }: Props) {
	const selected = useMemo(() => options.find((option) => !!value && option.id === value), [value]);

	const dropdownButton = useCallback(
		(dropdownProps) => <DropdownButton {...dropdownProps} fixed={fixed} onClose={resetFilter} />,
		[fixed, resetFilter]
	);

	return (
		<Select
			dataTest="select-event-type"
			defaultOpen={defaultOpen}
			label={{ primary: 'Event type', secondary: selected?.name ?? '' }}
			options={options}
			onChange={(data) => onChange(data!.id)}
			onClose={onClose}
			render={{ dropdownButton }}
			value={selected}
		/>
	);
}

export default EventTypeFilter;
