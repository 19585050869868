import { useStore } from 'effector-react';
import { useEffect, useMemo, useState } from 'react';
import { generatePath, useLocation } from 'react-router';
import { useHistory, useParams } from 'react-router-dom';
import Badge from 'components/Badge';
import { piiFilterStore } from 'components/PiiGlobalFilterV2/model';
import Preloader from 'components/Preloader';
import { TabLink, TabPanel, Tabs } from 'components/Tabs';
import Typo from 'components/typography/Typo';
import { dataCatalogSteps } from 'layouts/AuthorizedWithLeftMenu/Breadcrumbs';
import Header from 'layouts/AuthorizedWithLeftMenu/Header';
import { S3BucketItem } from 'models/s3Buckets/dto';
import { getS3BucketByIdFx, getS3BucketStatusFx } from 'models/s3Buckets/effects';
import { s3BucketsList, s3BucketsStatusStore } from 'models/s3Buckets/store';
import { toLocaleString } from 'services/numbers';
import { PATHS } from 'services/router';
import { FileTypesBar } from './FileTypesBar';
import styles from './index.module.css';
import { S3BucketConnections } from './S3BucketConnections';
import { S3BucketFiles } from './S3BucketFiles';
import { S3BucketSummary } from './S3BucketSummary';

function S3BucketError() {
	const params: {
		id: string;
	} = useParams();

	return (
		<>
			<Header
				breadcrumbProps={{
					steps: dataCatalogSteps,
					finalStep: 'Not found',
				}}
				titleBlock="Error has occurred while loading s3 bucket info"
			/>

			<Typo variant="D/Regular/Body-S" color="secondary">
				S3 bucket with id={params.id} not found or was deleted.
			</Typo>
		</>
	);
}

function S3BucketItemViewWrapper() {
	const buckets = useStore(s3BucketsList);
	const [isLoading, setIsLoading] = useState(true);
	const params: { id: string } = useParams();

	const bucket = buckets.find(({ id }) => id === Number(params.id));

	useEffect(() => {
		getS3BucketByIdFx(Number(params.id)).finally(() => setIsLoading(false));
	}, [params.id]);

	return (
		<Preloader isLoading={isLoading}>
			{bucket ? <S3BucketItemView bucket={bucket} /> : <S3BucketError />}
		</Preloader>
	);
}

const tabLabels = {
	summary: 'Summary',
	files: 'Files',
	connections: 'Connections',
};

type Props = {
	bucket: S3BucketItem;
};

function S3BucketItemView(props: Props) {
	const { bucket } = props;

	const location = useLocation();
	const history = useHistory();
	const params: {
		tab?: 'summary' | 'connections' | 'files';
	} = useParams();

	const s3BucketsStatus = useStore(s3BucketsStatusStore);
	const bucketStatus = s3BucketsStatus[bucket.id];

	const { nonEmpty } = useStore(piiFilterStore);

	const tabLinks = useMemo(
		() => ({
			summary: generatePath(PATHS.S3_BUCKETS_ITEM, {
				id: bucket.id,
				tab: 'summary',
			}),
			files: generatePath(PATHS.S3_BUCKETS_ITEM, {
				id: bucket.id,
				tab: 'files',
			}),
			connections: generatePath(PATHS.S3_BUCKETS_ITEM, {
				id: bucket.id,
				tab: 'connections',
			}),
		}),
		[bucket.id]
	);

	// Set default tab, if not specified.
	useEffect(function () {
		if (!params.tab) {
			const path = generatePath(PATHS.S3_BUCKETS_ITEM, {
				...params,
				tab: 'summary',
			});
			history.replace(path + history.location.search);
		}
	}, []);

	useEffect(() => {
		getS3BucketStatusFx({ id: bucket.id, nonEmpty });
	}, [location.pathname, bucket.id, nonEmpty]);

	return (
		<>
			{/* TABS HEADER */}
			<Tabs value={tabLinks[params.tab || 'summary']}>
				<TabLink value={tabLinks['summary']} label={tabLabels['summary']} />
				<TabLink
					value={tabLinks['connections']}
					label={
						<>
							{tabLabels['connections']}
							{bucketStatus && (
								<Badge
									badgeContent={
										bucketStatus.is_scanned ? toLocaleString(bucketStatus.asset_flows_count) : 'N/A'
									}
									data-test="s3-bucket-connections-count"
								/>
							)}
						</>
					}
				/>
				<TabLink
					value={tabLinks['files']}
					label={
						<>
							{tabLabels['files']}
							{bucketStatus && (
								<Badge
									badgeContent={
										bucketStatus.is_scanned ? toLocaleString(bucketStatus.objects_count) : 'N/A'
									}
									data-test="s3-bucket-objects-count"
								/>
							)}
						</>
					}
				/>
				<FileTypesBar progress={bucketStatus?.progress} className={styles.fileTypesBar} />
			</Tabs>

			{/* TABS CONTENT */}
			<TabPanel value={params.tab} index="summary">
				<S3BucketSummary bucket={bucket} />
			</TabPanel>
			<TabPanel value={params.tab} index="connections" className={styles.tabPanel}>
				<S3BucketConnections bucket={bucket} />
			</TabPanel>
			<TabPanel value={params.tab} index="files">
				<S3BucketFiles bucket={bucket} />
			</TabPanel>
		</>
	);
}

export { S3BucketItemViewWrapper };
