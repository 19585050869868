import { PolicyList, PolicyItem, PolicyItemWithoutRules } from './dto';

export const policyItemExample: PolicyItem = {
	id: 1,
	name: 'Policy Card for bank card data',
	description: 'Policy\nDescription',
	data_types: [1, 2],
	is_active: false,
	allow_external_transfer: false,
	allow_unencrypted_s3_buckets: true,
	allow_public_s3_buckets: true,
	allow_encrypted_network_only: true,
	allowed_external_assets: [667],
	rules: [
		[
			{
				type: 'namespace',
				operator: 'is',
				key: '',
				values: ['transact-controller', 'dev-fin-agent'],
			},
		],
		[
			{
				type: 'namespace',
				operator: 'is',
				key: '',
				values: ['fin-monitoring'],
			},
			{
				type: 'asset',
				operator: 'is',
				key: '',
				values: [
					'web-backend',
					'auth-service',
					'web-backend2',
					'auth-service2',
					'web-backend3',
					'auth-service3',
					'web-backend4',
					'auth-service4',
					'web-backend5',
					'auth-service5',
					'web-backend26',
					'auth-service26',
					'web-backend37',
					'auth-service37',
					'web-backend48',
					'auth-service48',
				],
			},
		],
	],
	created_at: 1640009456607,
	updated_at: 1646899054009,
};

const policy2 = {
	...policyItemExample,
	id: 2,
	name: 'Policy for driver licenses',
	is_active: true,
};

const policy3 = {
	...policyItemExample,
	id: 3,
	name: 'Longer Policy - for large enterprises',
	is_active: false,
};

const policiesWithoutRules: PolicyItemWithoutRules[] = [policyItemExample, policy2, policy3].map(
	(policyItem) => {
		// We don't need 'rules' and 'allowed_external_assets' variables, just excluding them.
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const { rules, allowed_external_assets, ...policyItemWithoutRules } = policyItem;

		return policyItemWithoutRules;
	}
);

export const policyListExample: PolicyList = {
	policies: policiesWithoutRules,
	total: 2,
};
