import { del, get, patch, post } from 'api/Api';
import checkResponse from 'services/api/checkResponse';
import { SensorDARItem, SensorDARList, SensorDARCreate } from './dto';
import { offlineSensorJsonExample, onlineSensorJsonExample, sensorsJsonExample } from './examples';

async function getSensorsDAR() {
	return get<SensorDARList>('v1/dar/token').then(checkResponse(sensorsJsonExample));
}

async function createSensorDAR(payload: SensorDARCreate) {
	return post<SensorDARItem>('v1/dar/token', payload).then(
		checkResponse(offlineSensorJsonExample, onlineSensorJsonExample)
	);
}

async function updateSensorDAR(payload: SensorDARItem) {
	return patch<SensorDARItem>(`v1/dar/token/${payload.id}`, { name: payload.name }).then(
		checkResponse(offlineSensorJsonExample, onlineSensorJsonExample)
	);
}

async function deleteSensorDAR(id: number) {
	return del(`v1/dar/token/${id}`).then(() => id);
}

export { getSensorsDAR, createSensorDAR, updateSensorDAR, deleteSensorDAR };
