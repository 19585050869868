import { ForwardedRef, MouseEvent, ReactNode } from 'react';
import Filter from 'components/Filter';

type DropdownButtonProps = {
	onClick: (event: MouseEvent<HTMLElement>) => void;
	onClose?: () => void;
	buttonRef?: ForwardedRef<HTMLButtonElement>;
	open: boolean;
	children: ReactNode;
	dataTest?: string;
	fixed?: boolean;
};

function DropdownButton(props: DropdownButtonProps) {
	const { fixed, onClick, open, children, dataTest, onClose, buttonRef } = props;

	return (
		<Filter
			kind={fixed ? 'primary-fixed' : 'primary-removable'}
			onClick={onClick}
			active={open}
			onClose={onClose}
			dataTest={dataTest}
			buttonRef={buttonRef}
		>
			{children}
		</Filter>
	);
}

export default DropdownButton;
export type { DropdownButtonProps };
