import cn from 'classnames';
import { useMemo } from 'react';
import { TSensitivity } from 'models/common/dto';
import styles from './index.module.css';
import K8sSvg from './k8s.svg';
import { ragTitleSize } from './utils/ragTitleSize';

type Props = {
	text: string;
	textOnCenter?: boolean;
	faded: boolean;
	position: {
		top: number;
		left: number;
		width: number;
		height: number;
	};
	hideK8Icon?: boolean;
	outerBorder?: boolean;
	innerBorder?: boolean;
	sensitivity?: TSensitivity;
};

function Platform(props: Props) {
	const { text, faded, position, textOnCenter, innerBorder, outerBorder, sensitivity } = props;

	const size = useMemo(
		() => ragTitleSize(position.width, position.height),
		[position.width, position.height]
	);

	const secondary = !sensitivity
		? 'none'
		: sensitivity === 'High'
		? 'red'
		: sensitivity === 'Medium'
		? 'yellow'
		: 'gray';

	return (
		<div
			style={position}
			className={cn(
				styles.platform,
				faded && styles.faded,
				textOnCenter && styles.textOnCenter,
				styles[`textbox${size}`],
				styles[`secondary-${secondary}`]
			)}
			title={text}
		>
			{textOnCenter && <div className={styles.textOnCenterText}>{text}</div>}
			{!textOnCenter && <span className={styles.platformText}>{text}</span>}

			{!props.hideK8Icon && (
				<span className={styles.k8sIcon}>
					<K8sSvg />
				</span>
			)}

			{innerBorder && <div className={styles.platformBorderInner} />}
			{outerBorder && (
				<div
					className={cn(
						styles.platformBorderOuter,
						innerBorder && styles.platformBorderOuterWithInner
					)}
				/>
			)}

			<div className={styles.platformBottom} />
			<div className={styles.platformRight} />
		</div>
	);
}

export default Platform;
