import { Dimensions } from './addDimensionPropertyToTree';
import { isPseudoRag, Rag } from './listToTreeByAttributes';

function calculateAbsoluteDimensions<Element, Level>(rag: Rag<Element, Level, Dimensions>) {
	if (isPseudoRag(rag)) {
		for (const cube of rag.data) {
			cube.dimensions.absoluteX = cube.dimensions.x + rag.dimensions.absoluteX;
			cube.dimensions.absoluteY = cube.dimensions.y + rag.dimensions.absoluteY;
		}
	} else {
		for (const childRag of rag.children) {
			childRag.dimensions.absoluteX = childRag.dimensions.x + rag.dimensions.absoluteX;
			childRag.dimensions.absoluteY = childRag.dimensions.y + rag.dimensions.absoluteY;
			calculateAbsoluteDimensions(childRag);
		}
	}
}

export default calculateAbsoluteDimensions;
export { isPseudoRag }; // TODO remove reexport
