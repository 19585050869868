import { MutableRefObject, useState } from 'react';
import TextField from 'components/form/TextField';
import styles from './index.module.css';

type Props = {
	newAssetRef: MutableRefObject<{ name: string; description: string }>;
};

function CreateAssetModal(props: Props) {
	const { newAssetRef } = props;

	const [name, setName] = useState('');
	const [description, setDescription] = useState('');

	newAssetRef.current = { name, description };

	return (
		<div className={styles.createAssetForm}>
			<TextField
				label="New custom external connection name"
				value={name}
				onChange={(e) => setName(e.target.value)}
				required
				data-test="new-asset-name-input"
			/>

			<TextField
				label="New connection description"
				value={description}
				onChange={(e) => setDescription(e.target.value)}
				multiline
				rows={4}
				data-test="new-asset-description-input"
			/>
		</div>
	);
}

export default CreateAssetModal;
