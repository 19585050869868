import cn from 'classnames';
import {
	OptionsObject,
	SnackbarKey,
	SnackbarMessage,
	SnackbarProvider,
	SnackbarProviderProps,
	useSnackbar,
} from 'notistack';
import { createRef, ReactNode } from 'react';
import Button from 'components/form/Button';
import Icon from 'components/Icon';
import styles from './index.module.pcss';

const ref = createRef<SnackbarProvider>();

function enqueueSnackbar(message: SnackbarMessage, options?: OptionsObject) {
	return ref.current?.enqueueSnackbar(message, {
		...options,
		action(key) {
			return <ActionButton customButton={options?.action} actionKey={key} />;
		},
	});
}

function ActionButton({
	customButton,
	actionKey,
}: {
	customButton?: ReactNode;
	actionKey: SnackbarKey;
}) {
	const { closeSnackbar } = useSnackbar();
	const onClick = () => closeSnackbar(actionKey);

	return (
		<Button
			color="transparent"
			onClick={onClick}
			className={cn(styles.closeButton, { [styles.customButton]: customButton })}
			data-test="snackbar-close-button"
		>
			{customButton ?? <Icon name="Dismiss/Regular" size={20} />}
		</Button>
	);
}

function Snackbar(props: SnackbarProviderProps) {
	return (
		<SnackbarProvider
			ref={ref}
			maxSnack={2}
			variant="info"
			classes={{
				variantInfo: cn(styles.variantInfo, styles.container),
			}}
			hideIconVariant
			anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
			autoHideDuration={5000}
			data-test="snackbar-container"
			{...props}
		>
			{props.children}
		</SnackbarProvider>
	);
}

export default Snackbar;
export { enqueueSnackbar };
