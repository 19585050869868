import { EnvVariables } from './env';
const BASE_URL = '/api';

const envDataEl = document.querySelector('#svrn-env');
if (!envDataEl || !envDataEl.textContent) {
	throw new Error('Can not find env variables data in html!');
}

const env: EnvVariables = JSON.parse(envDataEl.textContent);

const SENTRY = {
	ENABLED: env.SENTRY_ENABLED.includes('1'),
	DSN: env.SENTRY_DSN,
	ENVIRONMENT: env.SENTRY_ENVIRONMENT,
	RELEASE: env.SENTRY_RELEASE,
};

const GTM = {
	ENABLED: env.GTM_ENABLED.includes('1'),
	ID: env.GTM_ID,
};

const RELEASE = {
	REVISION: env.RELEASE_REVISION,
};

export { SENTRY, GTM, BASE_URL, RELEASE };
