import { BadgeProps, default as UIBadge } from '@material-ui/core/Badge';
import cn from 'classnames';
import { useMemo } from 'react';
import styles from './index.module.pcss';

function Badge({ className, badgeContent, ...props }: BadgeProps) {
	// Re-render on content change, to make Badge 'blink' when new data comes in.
	const content = useMemo(
		() => (
			<span key={Math.random()} className={styles.animate}>
				{badgeContent}
			</span>
		),
		[badgeContent]
	);

	return (
		<UIBadge
			classes={{
				badge: cn(styles.badge, className),
			}}
			max={Infinity}
			invisible={false}
			overlap="rectangular"
			badgeContent={content}
			{...props}
		/>
	);
}

export default Badge;
