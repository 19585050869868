import { useStore } from 'effector-react';
import { useCallback, useEffect, useMemo } from 'react';
import MultiSelect from 'components/form/MultiSelect';
import DropdownButton from 'components/form/Select/DropdownButton';
import { onceFx } from 'models/modelUtils/onceFx';
import { S3RegionItem } from 'models/s3Regions/dto';
import { getS3RegionsFx } from 'models/s3Regions/effects';
import { s3RegionsUsedList } from 'models/s3Regions/store';
import { FilterPropsBase } from '../model';

type Option = { name: S3RegionItem['description']; id: S3RegionItem['keyword'] };

type Props = FilterPropsBase & {
	value: S3RegionItem['keyword'][];
	onChange: (newValue: S3RegionItem['keyword'][]) => void;
};

function RegionFilter({ defaultOpen, fixed, onChange, onClose, resetFilter, value }: Props) {
	const regions = useStore(s3RegionsUsedList);

	useEffect(() => {
		onceFx(getS3RegionsFx);
	}, []);

	const groupedValues = useMemo(() => {
		return regions.map((region) => {
			return { id: region.keyword, name: region.description };
		});
	}, [regions]);

	const values = useMemo(() => {
		return groupedValues.filter((_value) => value.includes(_value.id));
	}, [value, groupedValues]);

	const handleChange = (options: Option[]) => {
		onChange(options.map((option) => option.id));
	};

	const dropdownButton = useCallback(
		(dropdownProps) => <DropdownButton {...dropdownProps} fixed={fixed} onClose={resetFilter} />,
		[fixed, resetFilter]
	);

	return (
		<MultiSelect
			defaultOpen={defaultOpen}
			label={{ primary: 'Regions', secondary: `${values.length || ''}` }}
			options={groupedValues}
			value={values}
			onChange={handleChange}
			onClose={onClose}
			render={{ dropdownButton }}
			hasSearch
			hasApplyButton
		/>
	);
}

export default RegionFilter;
