import cn from 'classnames';
import ExternalLink from 'components/typography/ExternalLink';
import Typo from 'components/typography/Typo';
import styles from './index.module.css';

interface Props {
	className?: string;
}

export function NeedHelpBlock({ className }: Props) {
	return (
		<div className={cn(className, styles.container)}>
			<Typo variant="D/Medium/H100-Header">Need help?</Typo>

			<Typo variant="D/Regular/Body-S">
				Read our{' '}
				<ExternalLink href="https://docs.soveren.io/en/stable/getting-started/quick-start/">
					quick start guide
				</ExternalLink>{' '}
				or contact us at <a href="mailto:support@soveren.io">support@soveren.io.</a>
			</Typo>
		</div>
	);
}
