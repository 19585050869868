import { useState, useEffect } from 'react';
import { APIError } from 'services/api/httpRequest';
import styles from './index.module.css';

type Props = {
	getSampleFn: (sampleId: number, signal: AbortSignal) => Promise<unknown>;
	sampleId: number;
};

let ac = new AbortController();

function SampleExistsIcon(props: Props) {
	const { getSampleFn, sampleId } = props;

	const [sampleExists, setSampleExists] = useState<boolean | null>(null);

	useEffect(() => {
		ac.abort();
		ac = new AbortController();

		setSampleExists(null);

		getSampleFn(sampleId, ac.signal)
			.then(() => {
				setSampleExists(true);
			})
			.catch((e) => {
				if (e instanceof APIError && e.response.status === 404) {
					setSampleExists(false);
				} else {
					throw e;
				}
			});
	}, [getSampleFn, sampleId]);

	const className =
		sampleExists === true ? styles.exists : sampleExists === false ? styles.existsNot : styles.base;

	return <span className={className} />;
}

export { SampleExistsIcon };
