import Button from 'components/form/Button';
import Icon from 'components/Icon';

export function ViewSampleButton({ path }: { path: string }) {
	return (
		<Button
			size="small"
			color="tertiary"
			endIcon={<Icon name="Open/Regular" size={20} />}
			href={path}
			target="_blank"
			data-test="event-details-sample-link"
		>
			View sample
		</Button>
	);
}
