import cn from 'classnames';
import { ReactNode } from 'react';
import styles from './index.module.pcss';

interface Props {
	children: ReactNode;
	className?: string;
	'data-test'?: string;
}

function GridBody({ children, className, 'data-test': dataTest }: Props) {
	return (
		<div className={cn(className, styles.body)} data-test={dataTest}>
			{children}
		</div>
	);
}

export default GridBody;
