import { useEffect, useState } from 'react';
import Button from 'components/form/Button';
import { enqueueSnackbar } from 'components/Snackbar';
import Tooltip from 'components/Tooltip';
import { testWebhookIntegration } from 'models/integrations/webhook/api';
import { TestWebhook } from 'models/integrations/webhook/dto';
import { APIError } from 'services/api/httpRequest';
import { TestMessage } from 'views/common/TestMessage';
import styles from './index.module.css';

const initialStatus: TestWebhook = {
	last_seen: 0,
	error_message: '',
};

interface Props {
	correctMode: boolean;
}

function WebhookTestStatus({ correctMode }: Props) {
	const [{ error_message, last_seen }, setStatus] = useState<TestWebhook>(initialStatus);
	const [isLoading, setLoading] = useState(false);

	useEffect(() => {
		if (correctMode) {
			setStatus(initialStatus);
		}
	}, [correctMode]);

	async function onTest() {
		setLoading(true);
		setStatus(initialStatus);

		try {
			await testWebhookIntegration().then((data) => {
				setStatus(data);
			});
		} catch (error) {
			if (
				error instanceof APIError &&
				(error.response.status === 401 || error.response.status === 400)
			) {
				const { message } = await error.response.json();
				enqueueSnackbar(message);
			} else {
				throw error;
			}
		} finally {
			setLoading(false);
		}
	}

	return (
		<div className={styles.container}>
			{correctMode ? (
				<Tooltip title="Save changes to send test event" placement="right">
					<Button
						color="tertiary"
						className={styles.button}
						disabled={correctMode}
						data-test="webhook-test-button-disabled"
					>
						Send test event
					</Button>
				</Tooltip>
			) : (
				<Button
					color="tertiary"
					className={styles.button}
					loading={isLoading}
					onClick={onTest}
					disabled={correctMode}
					data-test="webhook-test-button"
				>
					Send test event
				</Button>
			)}

			{!correctMode && last_seen !== 0 && (
				<TestMessage lastSeen={last_seen} errorMessage={error_message} className={styles.message} />
			)}
		</div>
	);
}

export default WebhookTestStatus;
