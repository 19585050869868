import { useState } from 'react';
import { validateIP } from 'services/validation';
import { TransformedRuleItem } from '..';
import RuleBadge from './RuleBadge';
import RuleInput from './RuleInput';

function validateUA(value: string) {
	return value.length > 0;
}

type Props = {
	type: 'ip' | 'userAgent';
	rule: TransformedRuleItem;
	onChange: (rule: TransformedRuleItem) => void;
	onCreateAfter: (rule: TransformedRuleItem) => void;
	onDelete: (rule: TransformedRuleItem) => void;
	onPasteMultiple: (rule: TransformedRuleItem, values: string[]) => void;
};

function RuleRowItem(props: Props) {
	const { type, rule, onChange, onCreateAfter, onDelete, onPasteMultiple } = props;

	const isValidFn = type === 'ip' ? validateIP : validateUA;

	const isValid = isValidFn(rule.value);

	const [editMode, setEditMode] = useState(!isValid);

	function handleChange(value: string) {
		onChange({ ...rule, value });
	}

	function handleConfirm() {
		if (!rule.value) {
			onDelete(rule);
		} else if (isValid) {
			setEditMode(false);
		}
	}

	function handleChangeAndConfirm(value: string) {
		onChange({ ...rule, value });

		if (isValidFn(value)) {
			setEditMode(false);
		}
	}

	function handleCreate() {
		if (isValid) {
			onCreateAfter(rule);
		}
	}

	function handleDelete() {
		onDelete(rule);
	}

	function handlePasteMultiple(values: string[]) {
		onPasteMultiple(rule, values);
	}

	function handleEnableEdit() {
		setEditMode(true);
	}

	const error = type === 'ip' && !isValid ? 'Invalid value' : null;

	return editMode ? (
		<RuleInput
			type={type}
			value={rule.value}
			onChange={handleChange}
			onConfirm={handleConfirm}
			onChangeAndConfirm={handleChangeAndConfirm}
			onCreate={handleCreate}
			onDelete={handleDelete}
			onPasteMultiple={handlePasteMultiple}
			error={error}
		/>
	) : (
		<RuleBadge value={rule.value} onEdit={handleEnableEdit} onDelete={handleDelete} />
	);
}

export default RuleRowItem;
