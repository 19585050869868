type ApiParams = Record<string, string | string[] | number | number[] | boolean>;

type JsonEncodable = object | string; // strings can be valid JSON values as well. May extend to null and number later, when need arises.

/**
 * Symmetric decoder of search string encoded by getURLSearchString function.
 *
 * @param str
 * @param arrayParams query params that must be treated as array (i.e. ?status=review&status=new)
 */
function parseURLSearchString(str: string, arrayParams: string[] = []) {
	const paramsSet = new URLSearchParams(str);
	const result: ApiParams = {};

	for (const param of paramsSet.keys()) {
		result[param] = arrayParams.includes(param) ? paramsSet.getAll(param) : paramsSet.get(param)!;
	}

	return result;
}

/**
 * This implementation for requests with multi value query params like ...?status=review&status=new
 */
function getURLSearchString(params?: ApiParams) {
	const paramsSet = new URLSearchParams();

	for (const name in params) {
		const value = params[name];

		if (Array.isArray(value)) {
			for (const valueItem of value) {
				paramsSet.append(name, valueItem.toString());
			}
		} else {
			paramsSet.append(name, value.toString());
		}
	}

	const result = paramsSet.toString();

	return result ? `?${result}` : '';
}

export type { ApiParams, JsonEncodable };
export { parseURLSearchString, getURLSearchString };
