import cn from 'classnames';
import { InputHTMLAttributes, MouseEvent, ReactNode, useCallback } from 'react';
import styles from './index.module.pcss';

interface Props extends Omit<InputHTMLAttributes<HTMLDivElement>, 'size'> {
	active?: boolean;
	border?: boolean;
	level?: 1 | 2 | 3;
	children: ReactNode;
	className?: string;
	head?: boolean;
	hover?: boolean;
	onClick?: (e: MouseEvent<HTMLElement>) => void;
	'data-test'?: string;
	size?: 'M' | 'S';
}

function GridRow(props: Props) {
	const {
		active,
		border,
		level = 1,
		children,
		className,
		head,
		hover,
		onClick,
		size = 'M',
		...rest
	} = props;

	const handleClick = useCallback(
		function handleClick(e: MouseEvent<HTMLElement>) {
			if (!onClick) return undefined;

			const selection = window.getSelection();

			if (selection?.type === 'Range') {
				e.stopPropagation();
				return;
			}

			return onClick(e);
		},
		[onClick]
	);

	return (
		<div
			onClick={handleClick}
			className={cn(styles.row, styles[size], {
				[styles.hover]: hover,
				[styles.rowActive]: active,
			})}
			{...rest}
		>
			<div
				className={cn(
					className,
					styles.wrapper,
					{ [styles.head]: head, [styles.border]: border, [styles.rowWithOffset]: level !== 1 },
					styles[`level-${level}`]
				)}
			>
				{children}
			</div>
		</div>
	);
}

export default GridRow;
