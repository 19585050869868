import Typo from 'components/typography/Typo';
import { s3BucketsSteps } from 'layouts/AuthorizedWithLeftMenu/Breadcrumbs';
import Header, { HeaderProps } from 'layouts/AuthorizedWithLeftMenu/Header';
import styles from '../SampleViewers/index.module.css';

interface WashedClusterProps {
	state: { name: string } | null;
	id: string;
}

function WashedCluster({ state, id }: WashedClusterProps) {
	let titleBlock: HeaderProps['titleBlock'] = `Data sample with ID ${id}`;

	if (state) {
		titleBlock = state.name;
	}

	return (
		<>
			<Header
				breadcrumbProps={{
					steps: [...s3BucketsSteps],
					finalStep: 'Cluster',
				}}
				titleBlock={titleBlock}
			/>

			<Typo variant="D/Regular/Body-S" color="secondary" className={styles.noSample}>
				No current cluster available, as there has been
				<br /> no traffic in the last 7 days.
			</Typo>
		</>
	);
}

export type { WashedClusterProps };
export { WashedCluster };
