import TagManager from 'react-gtm-module';
import { GTM } from 'consts';

function initGtm() {
	if (!GTM.ENABLED) {
		return;
	}

	TagManager.initialize({
		gtmId: GTM.ID,
	});
}

export { initGtm };
