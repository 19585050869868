import { RefObject } from 'react';
import { DataTypeItem } from 'models/dataTypes/dto';
import { DataTypeData } from '../index';

function connectStoreAndFormData(list: DataTypeItem[], value: DataTypeData) {
	return function findValueInUsage(paramName: keyof (DataTypeItem | DataTypeData)) {
		for (const item of list) {
			if (item[paramName] === value[paramName]) {
				return true;
			}
		}

		return false;
	};
}

const ERROR_TEXTS = {
	nameMinLength: 'Minimum Name length is 3',
	nameMaxLength: 'Maximum Name length is 255',
	nameValue:
		'Only latin letters, numbers, spaces and symbols -_ are allowed. You can only start with letters.',
	nameInUsage: 'Name is already in use',
	aliasInUsage: 'Keyword is already in use',
	emptyRule: 'Key and value are required',
};

const REGEXP_LIST = {
	// Name should start only with latin letter with any case.
	// Name can include digits, space, underscore and hyphen characters
	validName: /^[A-Za-z]*[A-Za-z][A-Za-z0-9 _-]*$/,
	// Replace all characters (spaces, hyphens anf other) to underscore
	findSpecialCharactersName: /\W+/g,
	// Replace double underscores to one
	trailingUnderscoreName: /__+/g,
	// Trim last underscore character
	findLastUnderscoreName: /_$/,
	nameAndAliasAreRequired: /Name/,
	sensitivityIsRequired: /sensitivity/,
	duplicateNameOrAlias: /One of parameters/,
};

function nameValidation(value: string) {
	return value.length ? REGEXP_LIST['validName'].test(value) : true;
}

function prepareAliasByName(name: string) {
	return name.length
		? name
				.trim()
				.replaceAll(REGEXP_LIST['findSpecialCharactersName'], '_')
				.replaceAll(REGEXP_LIST['trailingUnderscoreName'], '_')
				.replace(REGEXP_LIST['findLastUnderscoreName'], '')
				.toUpperCase()
		: '';
}
function findEmptyFieldsInTheRules(rules: DataTypeData['rules']) {
	for (const rule of rules) {
		if (!rule.key || !rule.value) {
			return true;
		}
	}

	return false;
}

function scrollToTop(ref: RefObject<HTMLDivElement>) {
	if (ref.current) {
		ref.current.scrollIntoView({ behavior: 'smooth' });
	}
}

export {
	connectStoreAndFormData,
	findEmptyFieldsInTheRules,
	ERROR_TEXTS,
	nameValidation,
	prepareAliasByName,
	REGEXP_LIST,
	scrollToTop,
};
