import Backdrop from '@material-ui/core/Backdrop';
import { default as UiDialog, ModalProps as UiModalProps } from '@material-ui/core/Modal';
import classnames from 'classnames';
import { memo, ReactNode } from 'react';
import ButtonIcon from 'components/ButtonIcon';
import Icon from 'components/Icon';
import styles from './index.module.css';

enum BaseModalTheme {
	danger = 'danger',
	hint = 'hint',
	success = 'success',
	normal = 'normal',
}

interface BaseModalProps extends Omit<UiModalProps, 'children' | 'title'> {
	children: ReactNode;
	title: string | ReactNode;
	onClose?: () => void;
	icon?: ReactNode;
	theme?: BaseModalTheme;
}

function getDefaultIcon(theme: BaseModalTheme) {
	switch (theme) {
		case BaseModalTheme.success:
			return <Icon name="check" size={24} />;
		case BaseModalTheme.hint:
			return <Icon name="warning" size={24} />;
		case BaseModalTheme.danger:
			return <Icon name="Danger/FilledRed" size={24} />;
		default:
			// no icon for normal
			return null;
	}
}

/**
 * Get transition duration(needs to be the same as modal css transition)
 */
function getTransitionDuration() {
	const style = getComputedStyle(document.documentElement);

	return parseInt(style.getPropertyValue('--transition-duration-standard'), 10);
}

function BaseModal({
	onClose,
	theme = BaseModalTheme.normal,
	icon = getDefaultIcon(theme),
	title,
	className,
	children,
	open,
	...props
}: BaseModalProps) {
	const timeout = getTransitionDuration();
	const iconClass = {
		[styles.iconAlert]: theme === BaseModalTheme.danger,
		[styles.iconHint]: theme === BaseModalTheme.hint,
		[styles.iconSuccess]: theme === BaseModalTheme.success,
	};

	return (
		<UiDialog
			open={open}
			onClose={onClose}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{ timeout }}
			{...props}
		>
			<div className={classnames(styles.container, className)} data-test="base-modal-form">
				{icon && <div className={classnames(styles.icon, iconClass)}>{icon}</div>}

				<div className={styles.main}>
					<h3 className={styles.header}>
						{title}

						<ButtonIcon
							className={styles.buttonClose}
							color="icon/primary"
							icon="Dismiss/Regular"
							onClick={onClose}
							dataTest="base-modal-close"
						/>
					</h3>
					{children}
				</div>
			</div>
		</UiDialog>
	);
}

export type { BaseModalProps };
export { BaseModalTheme };
export default memo(BaseModal);
