import { useStore } from 'effector-react';
import { useEffect, useMemo, useState } from 'react';
import FilterLine from 'components/FilterLine';
import FilterResult from 'components/FilterResult';
import NewGatewayButton from 'components/NewGatewayBlock/NewGatewayButton';
import NoContent from 'components/NoContent';
import { clearPiiFilter, piiFilterWithGroupsStore } from 'components/PiiGlobalFilterV2/model';
import Preloader from 'components/Preloader';
import ResetFilters from 'components/ResetFilters';
import EnhancedTableHead, { TOrder } from 'components/table/EnhancedTableHead';
import GridBody from 'components/table/GridBody';
import GridTable from 'components/table/GridTable';
import Typo from 'components/typography/Typo';
import { dataStoragesSteps } from 'layouts/AuthorizedWithLeftMenu/Breadcrumbs';
import Header from 'layouts/AuthorizedWithLeftMenu/Header';
import { getKafkaInstanceList } from 'models/kafkaInstances/api';
import { KafkaInstanceItem } from 'models/kafkaInstances/dto';
import { getComparator } from 'models/modelUtils/comparator';
import { PageParamsConfig, usePageParams } from 'services/pageParams';
import { UniversalSearch } from 'views/common/UniversalSearch';
import DataStorage from 'views/DataStorages';
import tableConfig from './config';
import styles from './index.module.css';
import { InstanceTableRow } from './InstanceTableRow';

const NOOP = () => {};

const pageConfig = {
	'data-types': {
		type: 'numberArray',
		persistence: 'session', // TODO implement 'global' in actuality
	},
	nonempty: {
		type: 'boolean',
		persistence: 'session', // TODO implement 'global' in actuality
	},
	search: {
		type: 'string',
		persistence: 'session',
	},
	sort: {
		type: 'sort',
		persistence: 'session',
	},
} satisfies PageParamsConfig;

function InstancesTable() {
	const [pageParams, setPageParams] = usePageParams(pageConfig, 'kafkaInstances');

	const piiFilterWithGroups = useStore(piiFilterWithGroupsStore);

	const [isLoading, setLoading] = useState(true);
	const [instances, setInstances] = useState<KafkaInstanceItem[]>([]);

	useEffect(() => {
		getKafkaInstanceList().then((res) => {
			setInstances(res.instances);
			setLoading(false);
		});
	}, []);

	useEffect(() => {
		setPageParams({
			...pageParams,
			'data-types': piiFilterWithGroups.dataTypes,
			nonempty: piiFilterWithGroups.nonEmpty,
		});
	}, [piiFilterWithGroups]);

	const sortedAndFiltered = useMemo(() => {
		let result = [...instances];

		if (pageParams.nonempty) {
			result = result.filter((instance) => instance.data_types.length > 0);
		}

		if (pageParams['data-types'].length > 0) {
			result = result.filter((instance) =>
				instance.data_types.some((dt) => pageParams['data-types'].includes(dt))
			);
		}

		if (pageParams.search.length > 0) {
			result = result.filter((instance) =>
				instance.name.toLocaleLowerCase().includes(pageParams.search.toLocaleLowerCase())
			);
		}

		const orderBy = pageParams.sort.value as keyof KafkaInstanceItem;
		const order = pageParams.sort.operator;

		return result.sort(getComparator(orderBy, order));
	}, [pageParams, instances]);

	function onSortUpdate(property: keyof KafkaInstanceItem) {
		const { sort } = pageParams;

		const operator: TOrder = sort.value === property && sort.operator === 'asc' ? 'desc' : 'asc';
		const newParams = { sort: { operator, value: property } };

		setPageParams({ ...pageParams, ...newParams });
	}

	function onUniversalSearchUpdate(search: string) {
		setPageParams({ ...pageParams, search });
	}

	function onResetFilters() {
		const newParams = { search: '' };

		setPageParams({ ...pageParams, ...newParams });
		clearPiiFilter();
	}

	const hasFilter = pageParams['data-types'].length > 0 || pageParams.search !== '';

	return (
		<DataStorage>
			<Header
				showSensitiveSwitcher
				breadcrumbProps={{
					steps: dataStoragesSteps,
					finalStep: 'Kafka clusters',
				}}
			/>

			<UniversalSearch value={pageParams.search} onChange={onUniversalSearchUpdate} />

			<FilterLine
				config={['dataTypes']}
				values={{
					dataTypes: pageParams['data-types'],
				}}
				onChange={NOOP}
			/>

			{hasFilter && (
				<FilterResult
					entityLabel="kafka cluster"
					loading={isLoading}
					total={instances.length}
					totalFiltered={sortedAndFiltered.length}
				/>
			)}

			<GridTable dataTest="kafka-clusters-table">
				<EnhancedTableHead
					config={tableConfig}
					order={pageParams.sort.operator}
					orderBy={pageParams.sort.value}
					onRequestSort={onSortUpdate}
					rowClassname={styles.rowContainer}
				/>

				<Preloader isLoading={isLoading}>
					<GridBody data-test="kafka-clusters-list">
						<>
							{sortedAndFiltered.length ? (
								sortedAndFiltered.map((instance) => (
									<InstanceTableRow
										instance={instance}
										search={pageParams.search}
										key={instance.id}
									/>
								))
							) : hasFilter ? (
								<ResetFilters onReset={onResetFilters} />
							) : (
								<NoContent type="kafkaInstance" className={styles.rowContainer}>
									<div className={styles.emptyContainer}>
										<Typo variant="D/Regular/Body-S" color="secondary">
											No available analytics or no Data-at-rest Sensors set up yet.
										</Typo>

										<NewGatewayButton isDAR />
									</div>
								</NoContent>
							)}
						</>
					</GridBody>
				</Preloader>
			</GridTable>
		</DataStorage>
	);
}

export { InstancesTable };
