import {
	KafkaTopicItem,
	KafkaTopicList,
	KafkaConsumerGroupsMemberItem,
	KafkaConsumerGroupsMemberList,
	KafkaTopicSampleItem,
	KafkaTopicSampleList,
	KafkaTopicSample,
	KafkaTopicStatus,
} from './dto';

const kafkaTopicItemExample: KafkaTopicItem = {
	id: 14,
	name: 'Prod kafka 1',
	owner: 'John Doe',
	description: 'Production queue for orders',
	instance_id: 14,
	partitions: 7,
	messages_count: 7003,
	last_seen: 1708944827384,
	data_types: [4],
};

const kafkaTopicListExample: KafkaTopicList = {
	topics: [kafkaTopicItemExample],
	total: 1,
};

const kafkaConsumerGroupsMemberItemExample: KafkaConsumerGroupsMemberItem = {
	kafka_client_id: 'aggregator@aggregator-84b46b7767-s6d8m (github.com/segmentio/kafka-go)',
	consumer_group_name: 'aws_third_party_stats',
	host: '/10.1.16.88',
};
const kafkaConsumerGroupsMemberItemExample2: KafkaConsumerGroupsMemberItem = {
	kafka_client_id: 'digger@digger-84b46b7767-s6d8m (github.com/segmentio/kafka-go)',
	consumer_group_name: 'aws_third_party_stats',
	host: '/10.1.16.89',
};

const kafkaConsumerGroupsMemberListExample: KafkaConsumerGroupsMemberList = {
	consumer_groups_members: [
		kafkaConsumerGroupsMemberItemExample,
		kafkaConsumerGroupsMemberItemExample2,
	],
	total: 2,
};

const kafkaTopicStatusExample: KafkaTopicStatus = {
	has_unsupported_content: false,
	consumer_groups_members: 12,
	data_samples: 19902,
	schemas: 123,
};

const kafkaTopicSampleItemExample: KafkaTopicSampleItem = {
	id: 1,
	offset: 194111,
	partition: 3,
	message_timestamp: 1638267989332,
	sensitivity: 'Low',
	data_types: [4],
	last_checked: 1638267989332,
};

const kafkaTopicSampleListExample: KafkaTopicSampleList = {
	total: 1,
	samples: [kafkaTopicSampleItemExample],
};

const kafkaTopicSampleItem: KafkaTopicSample = {
	id: 123,
	topic_id: 1,
	topic_name: 'Prod kafka 1',
	schema_id: 42,
	schema_name: 'Prod schema 2',
	offset: 123,
	partition: 123,
	message_timestamp: 1638267989332,
	type: 'json',
	samples: [
		{
			name: '',
			sample: '{ "test": "some string" }',
			data_fields: [
				{
					locator_path: '$.test',
					locator_column: 0,
					locator_line: 0,
					data_type: 12,
				},
			],
		},
	],
};

export {
	kafkaTopicItemExample,
	kafkaTopicListExample,
	kafkaConsumerGroupsMemberItemExample,
	kafkaConsumerGroupsMemberListExample,
	kafkaTopicStatusExample,
	kafkaTopicSampleItemExample,
	kafkaTopicSampleListExample,
	kafkaTopicSampleItem,
};
