import { AssetIPsJson, AssetUserAgentsJson, AssetRecipientsJson } from './dto';

const assetIPsJsonExample: AssetIPsJson = {
	total: 1,
	ips: [{ value: '192.168.0.1' }],
};

const assetUserAgentsJsonExample: AssetUserAgentsJson = {
	total: 1,
	user_agents: [
		{
			value:
				'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/109.0.0.0 Safari/537.36',
		},
	],
};

const assetRecipientsJsonExample: AssetRecipientsJson = {
	recipients: [
		{
			ip: '162.158.175.8',
			last_seen: 1692788826979,
			user_agent: 'curl/[ver]',
		},
		{
			ip: '34.91.194.24',
			last_seen: 1692711638223,
			user_agent: 'PostmanRuntime/[ver]',
		},
		{
			ip: '34.91.194.24',
			last_seen: 1692723728514,
			user_agent: 'python-requests/[ver]',
		},
		{
			ip: '18.203.147.135',
			last_seen: 1692865974702,
			user_agent: 'Retool/[ver] (+https://docs.tryretool.com/docs/apis)',
		},
	],
	total: 4,
};

export { assetIPsJsonExample, assetUserAgentsJsonExample, assetRecipientsJsonExample };
