import { useMemo } from 'react';
import { enqueueSnackbar } from 'components/Snackbar';
import Tooltip from 'components/Tooltip';
import Typo from 'components/typography/Typo';
import { SensorDARItem } from 'models/sensors/dar/dto';
import { updateSensorDARFx } from 'models/sensors/dar/effects';
import { APIError } from 'services/api/httpRequest';
import { EditableInput } from 'views/common/EditableInput';
import styles from './index.module.css';

const descriptions = {
	ok: 'Connected and working',
	error: "Proxy is down or can't connect",
};

const NOT_AVAILABLE = 'N/A';
const agentVersions = (versions: SensorDARItem['versions']) => (
	<div className={styles.agentVersions}>
		<Typo variant="D/Regular/Meta">Crawler: {versions.crawler || NOT_AVAILABLE}</Typo>
		<Typo variant="D/Regular/Meta">Detector: {versions.detectionTool || NOT_AVAILABLE}</Typo>
	</div>
);

type Props = {
	sensor: SensorDARItem;
};

function SensorName(props: Props) {
	const { sensor } = props;

	const description = sensor.text || descriptions[sensor.status];

	async function saveSensorName(newName: string): Promise<null | string> {
		if (!newName) return 'Name can not be empty';

		const payload = { ...sensor, name: newName };

		try {
			await updateSensorDARFx(payload);
		} catch (error) {
			if (error instanceof APIError && error.response.status === 400) {
				const { message } = await error.response.json();
				return message;
			}

			enqueueSnackbar('Something went wrong');
			throw error;
		}

		return null;
	}

	const agentVersionsNode = useMemo(() => agentVersions(sensor.versions), [sensor.versions]);

	return (
		<div className={styles.container}>
			<EditableInput value={sensor.name} onSave={saveSensorName} className={styles.editableInput} />

			{sensor.status && (
				<Typo variant="D/Regular/Meta" color="secondary">
					<Tooltip title={agentVersionsNode} placement="top" interactive={true}>
						{description}
					</Tooltip>
				</Typo>
			)}
		</div>
	);
}

export { SensorName };
