import cn from 'classnames';
import { generatePath } from 'react-router';
import { useHistory } from 'react-router-dom';
import GridCell from 'components/table/GridCell';
import GridRow from 'components/table/GridRow';
import Tooltip from 'components/Tooltip';
import Typo from 'components/typography/Typo';
import { TAssetsItem } from 'models/assetsExtended/dto';
import { PATHS } from 'services/router';
import { DASH_SIGN } from 'services/strings';
import styles from './index.module.pcss';
import PiiTypeWithTooltip from './PiiTypeWithTooltip';

function AssetRow({ id, name, namespace, k8s_types, data_types, sensitivity }: TAssetsItem) {
	const history = useHistory();

	function onRowClick() {
		const path = generatePath(PATHS.SERVICE_ITEM, { id });

		history.push(path);
	}

	return (
		<GridRow className={cn(styles.rowContainer, styles.assetsRow)} onClick={onRowClick} hover>
			<GridCell className={styles.cell}>
				<Tooltip title={name}>
					<Typo variant="D/Regular/Meta">{name}</Typo>
				</Tooltip>

				<Typo variant="D/Medium/Meta" color="secondary" className={styles.k8Type}>
					{k8s_types.join(', ')}
				</Typo>
			</GridCell>

			<GridCell className={styles.cell}>
				<Typo variant="D/Regular/Meta">{namespace || DASH_SIGN}</Typo>
			</GridCell>

			<GridCell className={cn(styles.cell, styles.sensitivityCell)}>
				<PiiTypeWithTooltip dataTypes={data_types} sensitivity={sensitivity} />
			</GridCell>
		</GridRow>
	);
}

export default AssetRow;
