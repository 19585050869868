import { createEvent, createStore } from 'effector';

type CubeImage = {
	id: string;
	image: HTMLImageElement;
};
type CubeImages = {
	[key: CubeImage['id']]: {
		image: CubeImage['image'];
		isLoaded: boolean;
	};
};

export const addImage = createEvent<CubeImage>();
const loadImage = createEvent<CubeImage>();
export const cubeImagesStore = createStore<CubeImages>({});

cubeImagesStore.on(addImage, (state, payload: CubeImage) => {
	const onLoad = () => {
		loadImage(payload);
		payload.image.removeEventListener('load', onLoad);
	};
	payload.image.addEventListener('load', onLoad);

	return {
		...state,
		[payload.id]: {
			image: payload.image,
			isLoaded: false,
		},
	};
});

cubeImagesStore.on(loadImage, (state, payload: CubeImage) => {
	return {
		...state,
		[payload.id]: {
			image: payload.image,
			isLoaded: true,
		},
	};
});
