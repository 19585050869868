import { DiscoveredDataTypesTableItem } from 'models/discoveredDataTypesV2/dto';

const emptyData: DiscoveredDataTypesTableItem[] = [
	{
		data_type: 6,
		data_fields: { count: 156, percent: 38.3 },
		custom: true,
		alias: 'LOCATION',
		endpoints: 10,
		display_name: 'Location',
	},
	{
		data_fields: { count: 90, percent: 22.1 },
		data_type: 3,
		display_name: 'Person',
		custom: false,
		alias: 'PERSON',
		endpoints: 10,
	},
	{
		data_fields: { count: 58, percent: 14.3 },
		data_type: 5,
		display_name: 'Phone',
		custom: false,
		alias: 'PHONE',
		endpoints: 10,
	},
	{
		data_fields: { count: 35, percent: 8.6 },
		data_type: 4,
		display_name: 'Email',
		custom: false,
		alias: 'EMAIL',
		endpoints: 9,
	},
	{
		data_fields: { count: 18, percent: 4.4 },
		data_type: 2,
		display_name: 'Gender',
		custom: false,
		alias: 'GENDER',
		endpoints: 9,
	},
	{
		data_fields: { count: 15, percent: 3.7 },
		data_type: 7,
		display_name: 'Card',
		custom: false,
		alias: 'CARD',
		endpoints: 5,
	},
	{
		data_fields: { count: 14, percent: 3.4 },
		data_type: 1,
		display_name: 'Birth date',
		custom: false,
		alias: 'BIRTH_DATE',
		endpoints: 7,
	},
	{
		data_fields: { count: 9, percent: 2.2 },
		data_type: 9,
		display_name: 'SSN',
		custom: false,
		alias: 'SSN',
		endpoints: 4,
	},
	{
		data_fields: { count: 8, percent: 2 },
		data_type: 10,
		display_name: 'IBAN',
		custom: false,
		alias: 'IBAN',
		endpoints: 6,
	},
	{
		data_fields: { count: 4, percent: 1 },
		data_type: 8,
		display_name: 'Driver license',
		custom: false,
		alias: 'DRIVER_LICENSE',
		endpoints: 3,
	},
];

export default emptyData;
