import { useStore } from 'effector-react';
import { useEffect, useMemo, useState } from 'react';
import { generatePath } from 'react-router';
import { useHistory, useParams } from 'react-router-dom';
import Badge from 'components/Badge';
import { ConfirmModal, getConfirmation } from 'components/ConfirmModal';
import Button from 'components/form/Button';
import { enqueueSnackbar } from 'components/Snackbar';
import { TabLink, TabPanel, Tabs } from 'components/Tabs';
import Typo from 'components/typography/Typo';
import Header from 'layouts/AuthorizedWithLeftMenu/Header';
import { getClientListFx } from 'models/clients/effects';
import { clientList } from 'models/clients/store';
import { getSSOSetting, updateSSOSettings } from 'models/sso/api';
import { isOIDC, TSSOSettings } from 'models/sso/dto';
import { APIError } from 'services/api/httpRequest';
import { PATHS } from 'services/router';
import ClientList from './ClientList';
import styles from './index.module.css';
import InviteMemberModal from './InviteMemberModal';
import Settings from './Settings';

function Team() {
	const [modalOpen, setModalOpen] = useState(false);
	const [canDisconnect, setCanDisconnect] = useState(false);

	const history = useHistory();
	const clients = useStore(clientList);
	const params: {
		tab?: 'list' | 'settings';
	} = useParams();

	const tabLinks = useMemo(
		() => ({
			list: generatePath(PATHS.TEAM, {
				tab: 'list',
			}),
			settings: generatePath(PATHS.TEAM, {
				tab: 'settings',
			}),
		}),
		[params.tab]
	);

	// Set default tab, if not specified.
	useEffect(
		function () {
			if (!params.tab) {
				history.replace(
					generatePath(PATHS.TEAM, {
						...params,
						tab: 'list',
					})
				);
			}
		},
		[params]
	);

	const tabLabels = {
		list: 'Members',
		settings: 'SSO settings',
	};

	function onConnectChange(settings: TSSOSettings) {
		setCanDisconnect(isOIDC(settings) ? settings.oidc.is_enabled : settings.adfs.is_enabled);
	}

	useEffect(() => {
		getClientListFx(); // To show members count
		getSSOSetting().then(onConnectChange);
	}, []);

	async function handleDisconnect() {
		const confirmModal = (
			<ConfirmModal
				title="Disconnect SSO"
				confirmProps={{
					children: 'Disconnect',
				}}
			>
				<Typo variant="D/Regular/Body-S">
					If you disconnect SSO, all users in your team will authenticate using personal login
					credentials only (SSO will no longer work).
				</Typo>
			</ConfirmModal>
		);

		const confirmed = await getConfirmation(confirmModal);
		if (!confirmed) return;

		try {
			const settings: TSSOSettings = await updateSSOSettings({
				oidc: {
					is_enabled: false,
					client_id: '',
					client_secret: '',
					issuer: '',
					email_domains: [],
					manage_users: false,
				},
			});

			enqueueSnackbar('SSO disconnected');
			onConnectChange(settings);
		} catch (error) {
			if (error instanceof APIError && error.response.status === 400) {
				const { message } = await error.response.json();
				enqueueSnackbar(message);
			} else {
				throw error;
			}
		}
	}

	return (
		<>
			<Header
				breadcrumbProps={{
					steps: [
						{
							path: generatePath(PATHS.TEAM, {
								tab: 'list',
							}),
							name: 'Team',
						},
					],
					finalStep: tabLabels[params.tab || 'list'],
				}}
				titleBlock={
					<>
						<Typo variant="D/Medium/H400-Page-Title">Team</Typo>

						<Button
							size="small"
							className={styles.button}
							onClick={() => setModalOpen(true)}
							data-test="team-invite-button"
						>
							Invite
						</Button>
					</>
				}
			/>

			<Tabs value={tabLinks[params.tab || 'list']}>
				<TabLink
					value={tabLinks['list']}
					replace
					label={
						<>
							Members
							<Badge badgeContent={clients.length} />
						</>
					}
				/>
				<TabLink value={tabLinks['settings']} replace label="SSO settings" />

				{params.tab === 'settings' && canDisconnect && (
					<Button
						size="small"
						color="tertiary"
						theme="danger"
						onClick={handleDisconnect}
						className={styles.disconnectButton}
						data-test="team-settings-sso-disconnect"
					>
						Disconnect
					</Button>
				)}
			</Tabs>

			<TabPanel value={params.tab} index="list" className={styles.tabPanel}>
				<ClientList />
			</TabPanel>

			<TabPanel value={params.tab} index="settings" className={styles.tabPanel}>
				<Settings onConnectChange={onConnectChange} />
			</TabPanel>

			<InviteMemberModal clients={clients} open={modalOpen} onClose={() => setModalOpen(false)} />
		</>
	);
}

export default Team;
