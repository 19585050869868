import deepmerge from 'lodash.merge';
import { v4 as uuidv4 } from 'uuid';
import { BASE_URL } from 'consts';
import { ApiParams, getURLSearchString } from 'services/api';

/*
	Added value:
		- url prefixing

		- trace id
			Backend uses x-trace-id for their jaeger metrics

		- normalizing 4xx and 5xx responses as errors
			We treat all 4xx / 5xx response codes as actual errors.
			Can handle them either explicitly per-request with .catch(), or
			handle known common cases in `commonErrorHandler.ts`

*/

class APIError extends Error {
	constructor(
		public response: Response,
		msg: string
	) {
		super(msg);
	}
}

async function httpRequest(url: string, params?: ApiParams, config?: RequestInit) {
	const configWithTrace = deepmerge({ headers: { 'x-trace-id': uuidv4() } }, config);

	const response = await fetch(`${BASE_URL}/${url}${getURLSearchString(params)}`, configWithTrace);

	if (!response.ok) {
		const msg = `${response.status} ${response.statusText} at ${
			response.headers.get('method') || 'GET'
		} ${response.url}`;
		throw new APIError(response, msg);
	}

	return response;
}

export default httpRequest;
export { APIError };
